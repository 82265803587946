import React from 'react';
import moment from 'moment';
import { Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { GetBarCode } from '../utils';
import RupeeFit from '../../assets/rupeeFit.png';
import Disclimer from '../../assets/disclimer.png';
import {
  getSplitDescription,
  truncatedNumberFloat,
  truncateString
} from '../../../../utils/index';
import Warranty from './warranty';
import fonts from '../../assets/Fonts';
import _ from 'lodash';

Font.register(fonts);

const styles = StyleSheet.create({
  card: {
    fontFamily: 'OpenSans',
    height: '210mm',
    width: '297mm'
  },
  qrwithname: {
    display: 'flex',
    flexDirection: 'row',
    height: '266px'
  },
  qrcode: {
    width: '232px',
    height: '232px'
  },
  qrcodeBlock: {
    width: '257px',
    height: '266px',
    padding: '6mm 4mm',
    borderRight: '1 solid #000000'
  },
  productnameWithDesc: {
    width: '585px',
    display: 'flex',
    flexDirection: 'column'
  },
  productname: {
    fontSize: '22.63pt',
    paddingLeft: '4mm',
    paddingTop: '7mm',
    height: '166px',
    borderBottom: '1 solid #000000',
    lineHeight: '0.4mm',
    paddingBottom: '7mm'
  },
  description: {
    padding: '3mm 4mm'
  },

  mrpText: {
    fontWeight: 'bold',
    color: 'black'
  },
  rupeeSmall: {
    width: '7.8mm',
    height: '10mm',
    top: 12.88
  },
  mrp: {
    width: '320px',
    borderLeft: '1 solid #000000',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: '9mm',
    fontSize: '28.83pt'
  },
  priceBlock: {
    borderTop: '1 solid #000000',
    borderBottom: '1 solid #000000',
    display: 'flex',
    flexDirection: 'row',
    height: '193px'
  },
  pricewithRupeeIcon: {
    display: 'flex',
    flexDirection: 'row',
    width: '528px'
  },
  rupeeicon: {
    fontSize: '28pt',
    paddingTop: '11mm',
    paddingLeft: '2mm'
  },
  price: {
    fontWeight: 'bold',
    fontSize: '135.1pt',
    textAlign: 'left',
    color: 'black',
    paddingLeft: '0mm',
    top: -16,
    lineHeight: 1
  },
  inclTax: {
    paddingTop: '48mm',
    fontSize: '34.9pt',
    fontWeight: 'bold',
    left: -240,
    width: '222px',
    display: 'flex',
    flexDirection: 'row'
  },

  //   Footer
  footer: {
    padding: '0mm 3mm '
  },
  dateDetails: {
    marginTop: -20,
    display: 'flex',
    flexDirection: 'row'
  },
  itemCode: {
    paddingTop: '15mm',
    fontSize: '12pt',
    opacity: '1',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  barcodeBlock: {
    width: '190mm',
    textAlign: 'right',
    justifyContent: 'center',
    alignItems: 'right',
    top: -8
  },
  warrantyText: {
    fontSize: '12.74pt',
    color: '#1A1818',
    opacity: '1'
  },
  deals: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  barcode: {
    width: '65mm',
    padding: '1mm 0mm 0mm 0mm'
  },
  // lineHeight: { paddingLeft: '4mm },
  disclimer: {
    width: '5.75mm',
    height: '5.75mm',
    left: 5
  },
  saveblock: {
    width: '320px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderLeft: '1 solid #000000'
  },
  savetext: {
    width: '320px',
    height: '50px',
    backgroundColor: '#818386',
    borderBottom: '1 solid #000000'
  },
  saveprice: {
    width: '320px',
    height: '144px',
    backgroundColor: '#1A1818',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const A4LandscapeH1 = ({ data, count, ...rest }) => {
  return (
    <Page size="A4" orientation="landscape" style={styles.page}>
      {count &&
        count.map(id => (
          <View key={id} style={{ display: 'flex' }}>
            {data?.map(item => {
              return (
                <View style={styles.card} key={item?.id}>
                  <View style={styles.qrwithname}>
                    <View style={styles.qrcodeBlock}>
                      <Image src={item.image} style={styles.qrcode} />
                    </View>
                    <View style={styles.productnameWithDesc}>
                      <View style={styles.productname}>
                        <Text>
                          {truncateString(item?.product?.masterData?.name, 130)}
                        </Text>
                      </View>
                      <View
                        style={{
                          fontSize: '14.64pt',
                          paddingLeft: '2mm',
                          lineHeight: '1.4',
                          display: 'flex',
                          flexDirection: 'row',
                          height: '99px'
                        }}
                      >
                        <View style={{ paddingTop: '7mm', width: '265px' }}>
                          {getSplitDescription(item.description, 3).map(
                            (line, index) => (
                              <Text key={index}>
                                + {truncateString(line, 30)}
                              </Text>
                            )
                          )}
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '40pt',
                            width: '320px',
                            height: '99px',
                            borderLeft: '1 solid #000000'
                          }}
                        >
                          <View style={styles.deals}></View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={styles.priceBlock}>
                    <View style={styles.pricewithRupeeIcon}>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '522px',
                          justifyContent: 'center'
                        }}
                      >
                        <Text style={styles.rupeeicon}>
                          <Image src={RupeeFit} />
                        </Text>
                        <Text style={styles.price}>
                          {truncatedNumberFloat(item?.product?.mop, 6)}
                        </Text>
                        <Text
                          style={{
                            fontSize: '17.8pt',
                            top: 26,
                            fontWeight: 'bold'
                          }}
                        >
                          *
                        </Text>
                      </View>
                      <View style={styles.inclTax}>
                        <Text
                          style={{
                            fontSize: '17.8pt',
                            marginTop: '-6px',
                            fontWeight: 'bold'
                          }}
                        >
                          *
                        </Text>
                        <Text>(Incl. Taxes)</Text>
                      </View>
                    </View>
                    <View style={styles.saveblock}>
                      <View style={styles.savetext}></View>
                      <View style={styles.saveprice}></View>
                    </View>
                  </View>
                  <View style={styles?.footer}>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: -6,
                          top: 1
                        }}
                      >
                        <Warranty warranty={item.warranty} styles={styles} />
                      </View>
                      <View
                        style={
                          item?.warranty
                            ? styles.barcodeBlock
                            : { ...styles.barcodeBlock, marginTop: '7mm' }
                        }
                      >
                        <View style={styles.barcode}>
                          {_.isEmpty(item.barcode) ? (
                            <></>
                          ) : (
                            <Image src={GetBarCode(item?.barcode)} />
                          )}
                        </View>
                      </View>
                    </View>
                    <View
                      style={
                        item?.warranty
                          ? styles.dateDetails
                          : { ...styles.dateDetails, top: 16 }
                      }
                    >
                      <View style={styles.itemCode}>
                        <Text>
                          {item.sku} | {item.class} | A |{' '}
                          {moment(item?.date).format('DD.MM.YYYY')} |{' '}
                          {moment().format('DD.MM.YYYY')}
                        </Text>
                        <View>
                          <Image style={styles.disclimer} src={Disclimer} />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              );
            })}
          </View>
        ))}
    </Page>
  );
};
export { A4LandscapeH1 };
