/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Tray } from './Tray/Tray';

export const SideTray = ({ templates, isTriggerEnabled, isActionEnabled }) => {
  return (
    <div
      style={{
        position: 'fixed',
        width: '340px',
        height: '100%',
        background: 'white',
        zIndex: '1000'
      }}
    >
      <Tray
        templates={templates.triggerTemplates.categories}
        isEnabled={isTriggerEnabled}
        placeholder="Cohorts"
      />
      <Tray
        templates={templates.actionTemplates.categories}
        isEnabled={isActionEnabled}
        placeholder="Actions"
      />
    </div>
  );
};
