/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { customerGroupContents } from '../../../constants/CustomerGroup/createCustomerGroup';
import customerGroupClass from '../index.module.scss';
import configService from '../../../services/customerGroup.service';
import { ParentLink } from '../../../utils/ParentLink';

const List = props => {
  const { business, status } = props;
  const { noGroup, typoGraphyVariant } = customerGroupContents;
  const [groupList, setGroupList] = useState([]);

  useEffect(() => {
    if (business.name !== '0') {
      configService
        .listCustomerGroupConfig(business?.name)
        .then(res =>
          res?.data.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          )
        )
        .then(res => setGroupList(res));
    }
  }, [business, status]);

  return (
    <div className={customerGroupClass.noGroupRoot}>
      {groupList?.length === 0 ? (
        <Typography variant={'h3'} className={customerGroupClass.noGroup}>
          {noGroup}
        </Typography>
      ) : (
        groupList?.map((item, idx) => {
          return (
            <div className={customerGroupClass.groupListContainer} key={idx}>
              <div className={customerGroupClass.groupList}>
                <Typography
                  variant={typoGraphyVariant}
                  className={customerGroupClass.listDescription}
                  style={{ fontWeight: 700 }}
                >
                  {item.name}
                </Typography>
                <Typography
                  variant={typoGraphyVariant}
                  className={customerGroupClass.listDescription}
                >
                  {item.description}
                </Typography>
                <div
                  className={customerGroupClass.submitButton}
                  style={{ marginTop: 10 }}
                >
                  <ParentLink
                    className={customerGroupClass.editButtonStyle}
                    to={`/edit-customer-group/${item.id}/?bu=${business.id}`}
                  >
                    Edit
                  </ParentLink>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default List;
