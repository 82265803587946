/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';

import Settings from './Settings/Settings';
import StepsCounter from './StepsCounter/StepsCounter';
import JourneyNameInput from './JourneyNameInput';
import classes from './Header.module.css';
// import YTIcon from '../../Icons/svg/Youtube.svg';
// import CloseIcon from '../../Icons/svg/Close.svg';
import Toggle from './Toggle';
import SaveIcon from '../../Icons/svg/Save.svg';
import CasaLogo from '../../Icons/svg/CasaLogo.svg';
import { Link } from 'react-router-dom';

const Header = ({
  journeyName,
  setJourneyName,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  businessUnit,
  setBusinessUnit,
  saved,
  onSave,
  onExecute,
  onStop,
  isRunning,
  count,
  isEdit,
  isExecuted,
  hiddenNode,
  setHiddenNode
}) => {
  const customImg = (src, name, onClick) => (
    <img src={src} alt={name} onClick={onClick} style={{ cursor: 'pointer' }} />
  );

  const saveJourney = ({ onSave, saved, disable }) => {
    return (
      <div
        style={
          disable
            ? { opacity: '0.4', cursor: 'not-allowed', pointerEvents: 'none' }
            : {}
        }
        className={classes.saveBtn}
        onClick={onSave}
      >
        {customImg(SaveIcon, '', () => null)}
        <span style={saved ? { color: 'green' } : {}}>SAVE</span>
      </div>
    );
  };

  // const [on, setOn] = React.useState(false);

  return (
    <div className={classes.container}>
      <div className={classes.headerActionsContainer}>
        {customImg(CasaLogo, 'Logo', () => null)}
        <JourneyNameInput
          setJourneyName={setJourneyName}
          journeyName={journeyName}
          saved={saved}
        />
      </div>
      <div className={classes.headerActionsContainer}>
        <Link
          className={classes.list}
          to="list"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>List Journeys</div>
        </Link>

        <StepsCounter count={count} />
        {/* <div>download as pdf part</div> */}
        <Settings
          journeyName={journeyName}
          setJourneyName={setJourneyName}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          businessUnit={businessUnit}
          setBusinessUnit={setBusinessUnit}
          disable={isEdit}
          disableEntire={isRunning || isExecuted}
          hiddenNode={hiddenNode}
          setHiddenNode={setHiddenNode}
        />
        {saveJourney({ onSave, saved, disable: isRunning || isExecuted })}
        <Toggle
          style={
            isExecuted && !isRunning
              ? { opacity: '0.4', cursor: 'not-allowed', pointerEvents: 'none' }
              : {}
          }
          on={isRunning}
          setOn={() => {
            if (!isRunning) {
              onExecute();
            } else {
              onStop();
            }
          }}
        />
      </div>
    </div>
  );
};

export default Header;
