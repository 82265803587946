/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import { startCase } from 'lodash';
import { resetPassword, saveUsers } from '../../../services/userlist.service';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  closeIconContainerStyles: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  formTitleStyles: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: 'unset',
    marginBottom: 10
  },
  field: {
    marginBottom: 20
  },
  applyBtn: {
    width: '50%'
  },
  cancelBtn: {
    width: '50%'
  },
  chip: {
    margin: 2,
    backgroundColor: '#F9F9F9'
  }
}));

const UserForm = props => {
  const {
    createUserFlag,
    setCreateUserFlag,
    showPassword,
    setShowPassword,
    showEditForm,
    setShowEditForm,
    editData,
    setEditData,
    seedUserRoles,
    seedTenantStores,
    getUserData,
    resetPasswordFlag,
    setResetPasswordFlag
  } = props;
  const [error, setError] = React.useState({
    emailError: '',
    phoneError: '',
    roleError: '',
    storeError: '',
    passwordError: '',
    confirmPasswordError: ''
  });
  const classes = useStyles();
  const newStoreData = [...editData.stores];

  const handleSubmit = () => {
    const newData = { ...editData };
    const removeData = newData['stores'].filter(item => item !== 'ALL');
    newData['stores'] = removeData.join('|');

    if (resetPasswordFlag) {
      resetPassword({
        email: newData.email,
        password: newData.password
      })
        .then(() => {
          getUserData();
          setCreateUserFlag(false);
          setEditData({ stores: [] });
        })
        .catch(err => console.error(err));
      return;
    }

    saveUsers([
      {
        email: newData.email,
        mobile: newData.mobile,
        role: newData.role,
        stores: newData.stores,
        password: newData.password,
        username: newData.name || ''
      }
    ])
      .then(() => {
        getUserData();
        setCreateUserFlag(false);
        setEditData({ stores: [] });
      })
      .catch(err => console.error(err));
  };

  const handleError = () => {
    //eslint-disable-next-line
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!editData?.email?.length) {
      setError({ ...error, emailError: 'Email Id is empty' });
      return true;
    }

    if (!emailRegex.test(editData?.email)) {
      setError({ ...error, emailError: 'Invalid email ID' });
      return true;
    }

    if (!editData?.password?.length && (createUserFlag || resetPasswordFlag)) {
      setError({ ...error, passwordError: 'Password cannot be empty' });
      return true;
    }

    if (
      resetPasswordFlag &&
      (editData?.confirmPassword !== editData?.password ||
        !editData?.confirmPassword?.length)
    ) {
      setError({
        ...error,
        confirmPasswordError: 'Please check the password field'
      });
      return true;
    }

    if (!editData?.mobile?.length) {
      setError({ ...error, phoneError: 'Phone number is empty' });
      return true;
    }

    if (editData?.mobile?.length !== 10) {
      setError({
        ...error,
        phoneError: 'Invalid phone number, Number of digits should be 10'
      });
      return true;
    }

    if (!editData?.role) {
      setError({
        ...error,
        roleError: 'Please Select a role'
      });
      return true;
    }

    // if (editData?.stores?.length < 1) {
    //   setError({ ...error, storeError: 'Select one or more stores' });
    //   return true;
    // }

    return false;
  };

  const handleClose = e => {
    setError({});
    setEditData({ stores: [] });
    setShowEditForm(false);
    setCreateUserFlag(false);
    setResetPasswordFlag(false);
    setShowPassword({
      actual: false,
      confirm: false
    });
  };

  const handleApply = () => {
    const errorFlag = handleError();
    if (!errorFlag) {
      handleSubmit();
      handleClose();
    }
  };

  const handleChipDelete = (e, value) => {
    return setEditData({
      ...editData,
      stores: newStoreData?.filter(store => store !== value)
    });
  };

  const renderTitle = () => {
    if (createUserFlag && !resetPasswordFlag) {
      return 'Create User';
    }

    if (!createUserFlag && !resetPasswordFlag) {
      return 'Edit User';
    }

    if (resetPasswordFlag) {
      return 'Reset Password';
    }
  };

  useEffect(() => {
    if (seedTenantStores.length > 0) {
      seedTenantStores.splice(0, 0, 'ALL');
    }
  }, [seedTenantStores]);

  return (
    <div>
      <Dialog open={showEditForm} aria-labelledby="form-dialog-title">
        <DialogTitle className={classes.closeIconContainerStyles}>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <div style={{ width: '65%', margin: '0px auto 20px auto' }}>
          <DialogTitle
            id="form-dialog-title"
            className={classes.formTitleStyles}
          >
            {renderTitle()}
          </DialogTitle>
          <DialogContent>
            <strong>*Email: </strong>
            <TextField
              className={classes.field}
              margin="dense"
              variant="outlined"
              type="text"
              fullWidth
              placeholder="Enter Email ID"
              error={error.emailError}
              helperText={error.emailError}
              value={editData?.email}
              onChange={e => {
                setEditData({
                  ...editData,
                  email: e.target.value.trim()
                });
                setError({ emailError: '' });
              }}
              disabled={resetPasswordFlag}
            />
            {(createUserFlag || resetPasswordFlag) && (
              <>
                <strong>*Password: </strong>
                <TextField
                  className={classes.field}
                  margin="dense"
                  variant="outlined"
                  type={showPassword.actual ? 'text' : 'password'}
                  fullWidth
                  placeholder="Enter Password"
                  error={error.passwordError}
                  helperText={error.passwordError}
                  value={editData?.password}
                  onChange={e => {
                    setEditData({
                      ...editData,
                      password: e.target.value.trim()
                    });

                    setError({ passwordError: '' });
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword({
                            ...showPassword,
                            actual: !showPassword.actual
                          });
                        }}
                      >
                        {showPassword.actual ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    )
                  }}
                />
              </>
            )}
            {resetPasswordFlag && (
              <>
                <strong>*Confirm Password: </strong>
                <TextField
                  className={classes.field}
                  margin="dense"
                  variant="outlined"
                  type={showPassword.confirm ? 'text' : 'password'}
                  fullWidth
                  placeholder="Enter Password"
                  error={error.confirmPasswordError}
                  helperText={error.confirmPasswordError}
                  value={editData?.confirmPassword}
                  onChange={e => {
                    setEditData({
                      ...editData,
                      confirmPassword: e.target.value.trim()
                    });
                    setError({ confirmPasswordError: '' });
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword({
                            ...showPassword,
                            confirm: !showPassword.confirm
                          });
                        }}
                      >
                        {showPassword.confirm ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    )
                  }}
                />
              </>
            )}
            {!resetPasswordFlag && (
              <>
                <strong>*Phone Number: </strong>
                <TextField
                  className={classes.field}
                  margin="dense"
                  variant="standard"
                  type="number"
                  placeholder="Enter mobile Number"
                  fullWidth
                  error={error.phoneError}
                  helperText={error.phoneError}
                  value={editData?.mobile}
                  onChange={e => {
                    setEditData({
                      ...editData,
                      mobile: e.target.value.trim()
                    });
                    setError({ phoneError: '' });
                  }}
                />
              </>
            )}
            <strong>Name: </strong>
            <TextField
              className={classes.field}
              margin="dense"
              variant="outlined"
              type="text"
              fullWidth
              placeholder="Enter User Name"
              value={editData?.username}
              onChange={e => {
                setEditData({
                  ...editData,
                  username: e.target.value.trim()
                });
              }}
              disabled={resetPasswordFlag}
            />
            {!resetPasswordFlag && (
              <>
                <strong>*Role: </strong>
                <TextField
                  className={classes.field}
                  select
                  placeholder="Select a role"
                  variant="outlined"
                  error={error.roleError}
                  helperText={error.roleError}
                  fullWidth
                  margin="dense"
                  value={editData?.role}
                  onChange={e => {
                    setEditData({ ...editData, role: e.target.value });
                    setError({ phoneError: '' });
                  }}
                >
                  {seedUserRoles.map((role, index) => (
                    <MenuItem key={index} value={role}>
                      {startCase(role)}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            )}
            {!resetPasswordFlag && (
              <>
                <strong>*Stores: </strong>
                <TextField
                  className={classes.field}
                  select
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  onChange={e => {
                    const allValueStatus = _.includes(e.target.value, 'ALL');
                    const editAllValueStatus = _.includes(
                      editData.stores,
                      'ALL'
                    );

                    if (allValueStatus)
                      !editAllValueStatus
                        ? setEditData({ ...editData, stores: seedTenantStores })
                        : setEditData({
                            ...editData,
                            stores: e.target.value
                          });
                    else
                      editAllValueStatus
                        ? setEditData({ ...editData, stores: [] })
                        : setEditData({
                            ...editData,
                            stores: e.target.value
                          });

                    setError({ storeError: '' });
                  }}
                  placeholder="select store"
                  error={error.storeError}
                  helperText={error.storeError}
                  SelectProps={{
                    multiple: true,
                    value: editData.stores
                  }}
                >
                  {seedTenantStores.map((store, index) => (
                    <MenuItem key={index + 1} value={store}>
                      {startCase(store)}
                    </MenuItem>
                  ))}
                </TextField>
                {editData.stores.map(value => (
                  <Chip
                    key={value}
                    label={value}
                    clickable
                    className={classes.chip}
                    onDelete={e => handleChipDelete(e, value)}
                  />
                ))}
              </>
            )}
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button
              onClick={handleClose}
              color="primary"
              variant="outlined"
              className={classes.cancelBtn}
            >
              Cancel
            </Button>
            <Button
              onClick={handleApply}
              color="primary"
              variant="contained"
              className={classes.applyBtn}
            >
              Apply
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default UserForm;
