/* eslint-disable @typescript-eslint/explicit-function-return-type */
import config from '../config/index';
import _ from 'lodash';

export async function publishJourney(data) {
  return await fetch(`${config.ruleServerUrl}/users/journey-engine`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json'
    },
    body: JSON.stringify({ data: data })
  })
    .then(r => {
      return r.json();
    })
    .catch(err => {
      console.log(err);
    });
}

export async function executeJourney(data) {
  return await fetch(
    `${config.ruleServerUrl}/users/journey-engine/${data.id}`,
    {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    }
  )
    .then(r => {
      return r.json();
    })
    .catch(err => {
      console.log(err);
    });
}
export async function stopJourney(id) {
  return await fetch(`${config.ruleServerUrl}/users/journey-engine/${id}`, {
    credentials: 'include',
    method: 'DELETE',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json'
    }
  })
    .then(r => {
      return r;
    })
    .catch(err => {
      console.log(err);
    });
}

export async function getJourney(filter) {
  const url = new URL(`${config.ruleServerUrl}/users/journey-engine`);
  const { page, limit, isRunning, startDate, endDate, searchText } = filter;
  const stringQueryParams = {
    page: page,
    limit: limit,
    isRunning: isRunning,
    startDate: startDate,
    endDate: endDate,
    q: searchText
  };
  const searchParams = new URLSearchParams(
    _.pickBy(stringQueryParams, _.identity)
  );

  url.search = searchParams.toString();
  return await fetch(url, {
    credentials: 'include',
    method: 'GET'
  }).then(r => r.json());
}

export async function getJourneyEngine(id) {
  return await fetch(`${config.ruleServerUrl}/users/journey-engine/${id}`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json'
    }
  })
    .then(r => {
      return r.json();
    })
    .catch(err => {
      console.log(err);
    });
}

export async function getJourneyReports(id) {
  return await fetch(`${config.ruleServerUrl}/users/journey-reports/${id}`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json'
    }
  })
    .then(r => {
      return r.json();
    })
    .catch(err => {
      console.log(err);
    });
}
