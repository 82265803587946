/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { CanvasWidget } from '@projectstorm/react-canvas-core';
// import * as SRD from '@projectstorm/react-diagrams';
import { DefaultDiagramState } from '@projectstorm/react-diagrams';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  saveJourneyConfig,
  updateJourneyConfig
} from '../../../services/journey-config.service';
import {
  executeJourney,
  stopJourney
} from '../../../services/journey-engine.service';
import { publishJourney } from '../../../services/journey-engine.service';
import { CanvasWidgetWrapper } from '../Canvas/CanvasWidgetWrapper';
import RuleTemplatesContext from '../context/ruleTemplatesContext';
import useInterval from '../customHooks/useInterval';
import bodyWidget from './BodyWidget.module.css';
import { hasLinks, hasValues } from './FlowValidator';
import { getCampaigns } from './JourneyCampaign';
import Zoom from '../components/Zoom/Zoom';
import Header from '../components/Header/Header';
import { SideTray } from '../components/SideTray/SideTray';
import { DragDropArea } from '../components/DragDropArea/DragDropArea';
import canvasStyle from '../Canvas/CanvasWidgetWrapper.module.css';
import NodeTypes from '../BaseNodeModel/NodeTypes';
import DetailsPopUp from '../components/DetailsPopup/DetailsPopup';

export const BodyWidget = ({
  app,
  templates,
  id,
  journeyDetails = {},
  isEdit = false,
  reports = {}
}) => {
  const [, updateState] = useState();
  const [isWaitingForAction, setIsWaitingForAction] = useState(false);
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const diagramEngine = app.getDiagramEngine();
  const state = diagramEngine.getStateMachine().getCurrentState();

  const [nodeDetails, setNodeDetails] = useState({
    ruleTemplates: [],
    timeConstraint: [],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setInputFields: () => {},
    specs: []
  });
  const [journeyConfigId, setJourneyConfigId] = useState();
  const [autoSaveDelay] = useState(null);
  const [, setDragOverContext] = useState(false);

  const [journeyName, setJourneyName] = useState('Untitled');
  const [journeyId, setJourneyId] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [businessUnit, setBusinessUnit] = useState();
  const [isSaved, setIsSaved] = useState(false);
  const [isRunning, setIsRunning] = useState();
  const [showPopUp, setShowPopUp] = useState();
  const [isExecuted, setIsExecuted] = useState();
  const [hiddenNode, setHiddenNode] = useState();

  const getNoOfNodes = model => model.getNodes().length;
  const getNoOfLinks = model => model.getLinks().length;
  const getModelState = (model = diagramEngine.getModel()) => [
    getNoOfNodes(model),
    getNoOfLinks(model)
  ];

  const setNodeId = () => {
    if (isRunning || isExecuted) {
      diagramEngine.getModel().setLocked(true);
      let i = 1;
      _.forEach(diagramEngine.getModel().getNodes(), node => {
        node.setNodeId(i);
        i++;
      });
    } else {
      diagramEngine.getModel().setLocked(false);
    }
  };

  const getLoadedOffers = journeyComponents => {
    return _.map(journeyComponents, journeyComponent => {
      const offerActions = _.filter(
        _.map(journeyComponent.actionNodes, actionNode => {
          if (actionNode.data.rewardType === 'Offer') {
            return {
              actionNode: actionNode,
              nodeId: journeyComponent.ruleNode.id
            };
          }
        }),
        offerAction => {
          if (offerAction) {
            return offerAction;
          }
        }
      );

      const offerCodeSpecs = _.map(offerActions, offerAction => {
        return {
          offerCodes: _.find(offerAction.actionNode.data.specs, spec => {
            if (spec.name === 'offerCodes') {
              return spec.value;
            }
          }),
          nodeId: offerAction.nodeId
        };
      });
      return _.map(offerCodeSpecs, offerCodeSpec => {
        return _.map(offerCodeSpec.offerCodes.value, offerCode => {
          return { offerId: offerCode, nodeId: offerCodeSpec.nodeId };
        });
      });
    });
  };
  const canShowDetails =
    !_.isEmpty(nodeDetails.node) && nodeDetails.node.showDetails;

  useEffect(() => {
    setNodeId();
  }, [isExecuted]);

  useEffect(() => {
    setNodeId();
  }, [isRunning]);

  useEffect(() => {
    setIsSaved(false);
  }, [diagramEngine.getModel().getNodes()]);

  useEffect(() => {
    if (!_.isEmpty(journeyDetails)) {
      setJourneyId(id);
      setJourneyConfigId(id);
      setJourneyName(journeyDetails.journeyName);
      setStartDate(journeyDetails.startDate);
      setEndDate(journeyDetails.endDate);
      setBusinessUnit(journeyDetails.businessUnit);
      setIsRunning(journeyDetails.isRunning);
      setIsExecuted(journeyDetails.isExecuted);
      setIsSaved(true);
    }
  }, [journeyDetails]);
  const [modelState, setModelState] = useState(getModelState());

  const endJourney = async () => {
    const result = await stopJourney(journeyId);
    if (result) {
      setIsRunning(false);
      alert('Journey Ended.');
    } else {
      alert('Something went wrong. Try again.');
    }
  };
  const autoSave = async (details = {}) => {
    const model = diagramEngine.getModel();
    const hasAtLeastOneNode = !_.isEmpty(model.layers[1].models);

    if (hasAtLeastOneNode) {
      if (_.isEmpty(journeyConfigId)) {
        const now = new Date();
        const tempJourneyName = `UNSAVED-${now.toLocaleDateString()}-${now.toLocaleTimeString()}`;
        // setJourneyName(tempJourneyName);
        const res = await saveJourneyConfig({
          data: JSON.stringify(model.serialize()),
          journeyName: tempJourneyName,
          isJourneyComplete: false,
          ...details
        });
        setJourneyConfigId(res.id);
        console.log(
          `Freshly Saved ${res.id} at ${new Date().toLocaleString()}`
        );
        console.log(res.id);
        return res.id;
      } else {
        await updateJourneyConfig(
          {
            data: JSON.stringify(model.serialize()),
            journeyName: journeyName,
            businessUnit: businessUnit,
            isJourneyComplete: false,
            ...details
          },
          journeyConfigId
        );
        console.log(
          `Saved ${journeyConfigId} at ${new Date().toLocaleString()}`
        );
        return journeyConfigId;
      }
    }
  };
  useInterval(() => {
    const currModelState = getModelState();
    // console.log('modelState, currModelState:', modelState, currModelState);
    if (!_.isEqual(modelState, currModelState)) {
      setModelState(() => currModelState);
      autoSave();
    }
  }, autoSaveDelay); // set delay to turn auto-save on (set delay as null for turning off)

  if (state instanceof DefaultDiagramState) {
    state.dragNewLink.config.allowLooseLinks = false;
  }

  const isJourneyValid = () => {
    if (
      _.isEmpty(journeyName) ||
      _.isEmpty(startDate) ||
      _.isEmpty(endDate) ||
      _.isEmpty(businessUnit)
    ) {
      alert('Enter all the journey details in settings');
      return false;
    }

    if (_.some([_.isEmpty(startDate), _.isEmpty(endDate)])) {
      alert('Enter start date and end date in settings');
      return false;
    }
    if (startDate > endDate) {
      alert('End Date cannot be before Start Date');
      return false;
    }
    return true;
  };
  async function publishSavedJourney(
    model,
    journeyName,
    journeyConfigId,
    showAlert
  ) {
    const journeyComponents = await getCampaigns(model, journeyConfigId);
    if (!_.isEmpty(hiddenNode)) {
      hiddenNode.journeyId = journeyConfigId;
      journeyComponents.push(hiddenNode);
    }
    const journeyPublishData = {
      className: 'Journey',
      journeyName: journeyName,
      businessUnit: businessUnit,
      id: journeyConfigId,
      startDate: startDate,
      endDate: endDate,
      journeyComponents: journeyComponents,
      loadedOffers: _.flattenDeep(getLoadedOffers(journeyComponents)),
      isRunning: false
    };
    const publishResult = await publishJourney(journeyPublishData);
    if (!_.isEmpty(publishResult.id)) {
      setModelState(getModelState());
      showAlert && alert('Journey save successful');
      setJourneyId(publishResult.id);
      setIsSaved(true);
    } else {
      showAlert && alert('Something went wrong');
    }
  }
  const saveModel = async (showAlert = true) => {
    if (!isJourneyValid()) {
      return;
    }
    if (_.isEmpty(journeyName)) {
      alert('Enter Journey Name in settings');
    }
    const id = await autoSave({ journeyName, isJourneyComplete: true });
    await publishSavedJourney(
      diagramEngine.getModel(),
      journeyName,
      id,
      showAlert
    );
  };
  const onZoomHandler = level => {
    if (diagramEngine && diagramEngine.getModel()) {
      diagramEngine.getModel().setZoomLevel(level);
      forceUpdate();
    }
  };

  const startJourney = async () => {
    await saveModel(false);
    // if (Date.parse(startDate) < Date.parse(new Date().toLocaleDateString())) {
    //   alert('Change the start date');
    //   return false;
    // }

    if (!hasLinks(diagramEngine.getModel())) {
      alert('Journey is not fully linked');
      return false;
    }
    if (!hasValues(diagramEngine.getModel())) {
      alert('One or More nodes is not Feeded with data');
      return false;
    }

    const data = {
      id: journeyId,
      startDate,
      endDate
    };
    const result = await executeJourney(data);
    if (result?.id) {
      setIsRunning(true);
      setIsExecuted(true);
      alert('Journey Started');
    } else {
      alert('Something went wrong. Try executing again.');
    }
  };

  return (
    <RuleTemplatesContext.Provider
      value={{
        nodeDetails,
        setNodeDetails,
        diagramEngine,
        setDragOverContext,
        isWaitingForAction,
        setIsWaitingForAction,
        setShowPopUp,
        isEdit,
        isLocked: isRunning || isExecuted,
        forceUpdate: () => forceUpdate(),
        reports: reports
      }}
    >
      <Header
        count={_.size(diagramEngine.getModel().getNodes())}
        journeyName={journeyName}
        setJourneyName={setJourneyName}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        onSave={saveModel}
        saved={isSaved}
        onExecute={startJourney}
        onStop={endJourney}
        isRunning={isRunning}
        businessUnit={businessUnit}
        setBusinessUnit={setBusinessUnit}
        isEdit={isEdit}
        isExecuted={isExecuted}
        hiddenNode={hiddenNode}
        setHiddenNode={setHiddenNode}
      />

      <div className={bodyWidget.container}>
        <SideTray
          templates={templates}
          isTriggerEnabled={_.isEmpty(diagramEngine.getModel().getNodes())}
          isActionEnabled={isWaitingForAction}
        />
        <div className={bodyWidget.layer}>
          <Zoom onZoomHandler={onZoomHandler} />
          <CanvasWidgetWrapper>
            {_.isEmpty(diagramEngine.getModel().getNodes()) && (
              <DragDropArea
                className={canvasStyle.DragDropArea}
                style={{ marginLeft: '400px', paddingTop: '40px' }}
                nextNode={NodeTypes.TRIGGER_NODE}
              />
            )}
            <CanvasWidget
              engine={diagramEngine}
              forceUpdate={() => forceUpdate()}
            />
          </CanvasWidgetWrapper>
        </div>
      </div>
      {canShowDetails && (
        <DetailsPopUp
          show={showPopUp}
          isReadOnly={isRunning || isExecuted}
          setShow={setShowPopUp}
          nodeDetails={nodeDetails}
          repaintCanvas={() => diagramEngine.repaintCanvas()}
        />
      )}
    </RuleTemplatesContext.Provider>
  );
};
