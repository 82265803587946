/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as React from 'react';
import { Box, Modal } from '@material-ui/core';
import './index.scss';
import Backdrop from '@material-ui/core/Backdrop';
import { ParentLink } from '../../utils/ParentLink';

const boxStyle = {
  position: 'absolute',
  top: '47%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  borderRadius: 4,
  bgcolor: 'background.paper',
  outline: 'none',
  boxShadow: 24
};

export default function ConfirmationModal() {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  React.useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 1000);
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1000
      }}
    >
      <Box sx={boxStyle} style={{ padding: '10px 12px' }}>
        <div className={'modalContainer'}>
          <h4 className="attentionTitle">Attention!</h4>
          <div>
            Please set your SMS channels before configuring the OTP Templates
          </div>
          <div style={{ marginTop: 20 }}>
            <ParentLink to={'/channels'} className={'takeMeButton'}>
              Take me there
            </ParentLink>

            <button onClick={handleClose} className={'okayButton'}>
              Stay here
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
