/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import './index.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import GeneralSetting from './GeneralSetting';
import ModuleSetup from './ModuleSetup';
import { settingContent } from '../../constants/generalSetting';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Banner from '../Assets/Setting_Config_banner.png';

const useStyles = makeStyles(() => ({
  rootSetting: {
    '& .MuiTypography-h2': {
      backgroundImage: `url(${Banner})`,
      color: '#333333',
      width: '100%',
      height: '162px !important',
      backgroundSize: 'cover',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '28px !important',
      fontWeight: '700 !important',
      fontFamily: 'Nunito',
      marginBottom: '0px !important'
    },
    '& .MuiTypography-h6': {
      fontWeight: 700,
      fontSize: 18,
      color: '#333333'
    },
    '& .MuiTypography-body1': {
      fontSize: 15,
      color: '#333333'
    }
  },
  panelContent: {
    marginLeft: 40
  }
}));

const Setting = () => {
  const { rootSetting, panelContent } = useStyles();

  const {
    title,
    generalTitle,
    generalParagraph,
    moduleTitle,
    moduleParagraph
  } = settingContent;

  const settingTabs = [
    { title: generalTitle, paragraph: generalParagraph },
    { title: moduleTitle, paragraph: moduleParagraph }
  ];

  return (
    <div className={rootSetting}>
      <Typography variant="h2">{title}</Typography>
      <Tabs>
        <TabList>
          {settingTabs.map((item, index) => {
            return (
              <Tab key={index}>
                <Typography variant="h6">{item.title}</Typography>
                <Typography variant="body1">{item.paragraph}</Typography>
              </Tab>
            );
          })}
        </TabList>
        <TabPanel>
          <div className={panelContent}>
            <GeneralSetting />
          </div>
        </TabPanel>
        <TabPanel>
          <div className={panelContent}>
            <ModuleSetup />
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Setting;
