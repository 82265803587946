import moment from 'moment';

const formatDate = (date: string, format = 'DD-MMM-YYYY (hh:mm A)'): string => {
  return moment(new Date(date)).format(format);
};

export const formatDateReport = (
  date: string,
  format = 'DD-MMM-YYYY (hh:mm A)'
): string => {
  console.log(date);
  return moment(date).format(format);
};
export default formatDate;
