import React from 'react';
import { ProductSection } from '../ProductSection';
// todo: remove
// eslint-disable-next-line import/no-cycle
import Bucket from '../Bucket';

// todo: remove ignores
const renderers = {
  // eslint-disable-next-line react/display-name
  JoinBucket: (bucket, setRootBucket, rootBucket) => (
    <Bucket
      key={bucket.bucketId}
      bucket={bucket}
      setRootBucket={setRootBucket}
      rootBucket={rootBucket}
    />
  ),
  // eslint-disable-next-line react/display-name
  ProductBucket: (bucket, setRootBucket, rootBucket) => (
    <ProductSection
      key={bucket.bucketId}
      setRootBucket={setRootBucket}
      bucket={bucket}
      rootBucket={rootBucket}
    />
  ),
  // eslint-disable-next-line react/display-name
  ProductFamilyBucket: (bucket, setRootBucket, rootBucket) => (
    <ProductSection
      key={bucket.bucketId}
      setRootBucket={setRootBucket}
      bucket={bucket}
      rootBucket={rootBucket}
    />
  ),
  // eslint-disable-next-line react/display-name
  Bucket: (bucket, setRootBucket, rootBucket) => (
    <ProductSection
      setRootBucket={setRootBucket}
      bucket={bucket}
      rootBucket={rootBucket}
    />
  )
};

export default renderers;
