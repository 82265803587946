export const kpiQuerySegmentOptions = [
  { key: 'RECENCY', name: 'Recency' },
  { key: 'FREQUENCY', name: 'Frequency' },
  { key: 'MONETARY', name: 'Monetary' },
  { key: 'CUSTOMER_TYPE', name: 'Customer Type' },
  { key: 'SALES', name: 'Sales' },
  { key: 'ACQUISITION', name: 'Acquisition' },
  // { key: 'LOYALTY_LIABILITY', name: 'Loyalty Liability' },
  { key: 'CROSS_CONCEPT', name: 'Cross Concept' },
  { key: 'AVG_RECENCY', name: 'Average Recency' },
  { key: 'AVG_FREQUENCY', name: 'Average Frequency' },
  { key: 'TIER', name: 'Tier' },
  { key: 'RFM_SEGMENT', name: 'RFM Segment' },
  {
    key: 'CUSTOMER_SPEND_ACTIVITY',
    name: 'Customer Spend Activity'
  },
  { key: 'RETENTION_RATE', name: 'Retention Rate' },
  { key: 'REPEATERS_RATE', name: 'Repeaters Rate' },
  { key: 'PRODUCT_FAMILY_GROUP', name: 'Product Family Group' },
  { key: 'AGE_GROUP', name: 'Age Group' }
];
