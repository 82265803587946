/* eslint-disable @typescript-eslint/explicit-function-return-type */
import _ from 'lodash';
import { BaseNodeModel } from '../BaseNodeModel/BaseNodeModel';
import NodeTypes from '../BaseNodeModel/NodeTypes';
import Specs from '../BaseNodeModel/SpecsModel';
import * as PortModels from '../BaseNodeModel/PortModels';

export class WaitNodeModel extends BaseNodeModel {
  constructor(options = {}) {
    super({
      type: NodeTypes.WAIT_NODE,
      ...options
    });
    this.nextNodes = [NodeTypes.DECISION_NODE];
    this.maximumLinks = 100;
    this.specs = [
      new Specs(
        'timeConstraint',
        'dropdown',
        'Time Constraint',
        ['instantaneously', 'wait for'],
        {
          instantaneously: [],
          'wait for': [
            new Specs('time to wait for', 'text', 'Time'),
            new Specs('units', 'dropdown', 'Units', [
              'mins',
              'hours',
              'days',
              'weeks'
            ])
          ]
        },
        'instantaneously'
      )
    ];
    this.buildPorts([new PortModels.InPort(), new PortModels.OutPort()]);
  }

  getLinksCount() {
    return _.size(this.ports[PortModels.PortTypes.OUT_PORT].links);
  }

  setInputFields(specs) {
    this.isDetailsSaved = true;
    this.specs = specs;
  }
}
