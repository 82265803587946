import React from 'react';
import moment from 'moment';
import { Page, View, Text, Image, StyleSheet, Font } from '@react-pdf/renderer';
import { GetBarCode } from '../utils';
import Rupee from '../../assets/rupee.png';
import RupeeFit from '../../assets/rupeeFit.png';
import Disclimer from '../../assets/disclimer.png';
import fonts from '../../assets/Fonts';
import { truncatedNumber, truncateString } from '../../../../utils';
import _ from 'lodash';

Font.register(fonts);

const styles = StyleSheet.create({
  card: {
    fontFamily: 'OpenSans',
    marginTop: '3mm',
    left: '18mm',
    height: '38mm',
    width: '175mm',
    flexDirection: 'row',
    border: '1 solid #000000',
    marginBottom: '3px'
  },
  qrcode: {
    borderRight: '1 solid #000000',
    height: '37.5mm',
    width: '36mm',
    padding: '2mm 1mm',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center'
    // margin:"0px 10px"
  },
  priceDetails: {
    flexDirection: 'row'
  },
  deals: {
    width: '33mm',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  mrpText: {
    fontSize: '12.58pt',
    color: 'black',
    width: '15mm',
    fontWeight: 'bold'
  },
  rupeeSmall: {
    width: '3mm',
    height: '3mm'
  },
  topSection: {
    height: '22.5mm'
  },
  mrp: { fontSize: '12', textAlign: 'center', lineHeight: '12px' },
  priceSection: {
    width: '94mm',
    borderRight: '1 solid #000000',
    padding: '1mm',
    height: '24mm'
  },
  amount: {
    flexDirection: 'row',
    display: 'flex'
  },
  rupeeIcon: {
    fontSize: '44pt',
    top: -10,
    left: -5,
    width: '8mm'
  },
  price: {
    fontWeight: 'bold',
    fontSize: '68.8pt',
    color: 'black',
    top: -19
  },
  inclTax: {
    fontSize: '3.98pt',
    top: 57,
    right: 15
  },
  descDetailsBlock: {
    height: '12.5mm',
    width: '138.5mm',
    flexDirection: 'row',
    borderTop: '1 solid #000000'
  },
  descSection: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRight: '1 solid #000000',
    width: '290px',
    height: '13mm'
  },
  desc: {
    fontSize: '7.4pt',
    margin: '1mm',
    color: '#1A1818',
    lineHeight: 1.1
  },
  itemCode: {
    fontSize: '7pt',
    marginLeft: '1mm',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#1A1818',
    top: -2
  },
  barcode: {
    top: 4,
    width: '37mm',
    padding: '1mm 1mm 0mm 0mm'
  },
  pageTitle: {
    position: 'absolute',
    fontSize: 7,
    top: 2,
    left: 50
  }
});

const IntegratedSelfPage = ({ title, data, count, ...rest }) => {
  return (
    <Page size="A4" style={styles.page} wrap={true}>
      {count &&
        count.map(id => (
          <View key={id}>
            {data?.map(item => (
              <View style={styles.card} key={item?.id}>
                <View style={styles.qrcode}>
                  <View
                    style={{ marginTop: '1mm', height: '34mm', width: '32mm' }}
                  >
                    <Image src={item.image} />
                  </View>
                </View>
                <View style={styles.topSection}>
                  <View style={styles.priceDetails}>
                    <View style={styles.priceSection}>
                      <View style={styles.amount}>
                        <Text style={styles.rupeeIcon}>
                          <Image src={Rupee} />
                        </Text>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: '40mm'
                          }}
                        >
                          <View>
                            <Text style={styles.price}>
                              {truncatedNumber(item?.product?.mop, 6)}
                            </Text>
                          </View>
                          <Text style={styles.inclTax}>*Incl. Tax</Text>
                        </View>
                      </View>
                    </View>
                    <View style={styles.deals}>
                      {truncatedNumber(item?.product?.mrp, 6) > 0 ? (
                        <Text style={styles.mrp}>
                          <Text style={styles.mrpText}>MRP: </Text>
                          <Image style={styles.rupeeSmall} src={RupeeFit} />
                          {truncatedNumber(item?.product?.mrp, 6)}
                        </Text>
                      ) : (
                        <Text />
                      )}
                    </View>
                  </View>
                  <View style={styles.descDetailsBlock}>
                    <View style={styles.descSection}>
                      <Text style={styles.desc}>
                        {truncateString(item?.product?.masterData?.name, 130)}
                      </Text>
                      <View style={styles.itemCode}>
                        <Text>
                          {item?.sku} | {item.class} | A |{' '}
                          {moment(item?.date).format('DD.MM.YYYY')} |{' '}
                          {moment().format('DD.MM.YYYY')}
                        </Text>
                        <Image
                          style={{
                            fontSize: '12mm',
                            height: '4mm',
                            paddingLeft: '1mm'
                          }}
                          src={Disclimer}
                        />
                      </View>
                    </View>
                    <View style={styles.barcode}>
                      {_.isEmpty(item.barcode) ? (
                        <></>
                      ) : (
                        <Image src={GetBarCode(item?.barcode)} />
                      )}
                    </View>
                  </View>
                </View>
              </View>
            ))}
          </View>
        ))}
    </Page>
  );
};

export { IntegratedSelfPage };
