import React, { useCallback, useRef, useState } from 'react';
import { getReportForOfferType } from '../../services/b2bOffersReport.service';
import _ from 'lodash';
import { Button } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';

const ReportCSVLink = ({ offerType }) => {
  const csvLink = useRef(null);
  const reportCSVHeaders = [
    { label: 'Name of flow', key: 'flowName' },
    { label: 'Phone number of customer', key: 'customerPhone' },
    { label: 'Signed up date', key: 'signedUpDate' },
    { label: 'Signed up time', key: 'signedUpTime' },
    { label: 'Activation Status', key: 'activationStatus' },
    { label: 'Offers Activated After Signup', key: 'offersActivated' }
  ];
  const [reportData, setReportData] = useState([]);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const makeReportData = useCallback(async () => {
    setDownloadLoading(true);
    const report = await getReportForOfferType(offerType);
    const reportCSVData = _.map(report, row => ({
      flowName: offerType,
      customerPhone: _.get(row, 'number'),
      signedUpDate: new Date(_.get(row, 'createdAt')).toDateString(),
      signedUpTime: new Date(_.get(row, 'createdAt')).toLocaleTimeString(),
      activationStatus: (!_.isEmpty(_.get(row, 'activatedOffers'))).toString(),
      offersActivated: _.join(_.get(row, 'activatedOffers'))
    }));
    setReportData(reportCSVData);
    csvLink.current.link.click();
    setDownloadLoading(false);
  }, [offerType]);

  const today = moment().format('DD-MM-YYYY');

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={makeReportData}>
        Download
        {downloadLoading && (
          <CircularProgress
            color="inherit"
            style={{ height: 20, width: 20, marginLeft: 3 }}
          />
        )}
      </Button>
      <CSVLink
        data={reportData}
        headers={reportCSVHeaders}
        filename={`${offerType}_${today}.csv`}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </div>
  );
};

export default ReportCSVLink;
