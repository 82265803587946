export const providerHeading = [
  {
    id: 0,
    label: 'Loyalty OTP'
  },
  {
    id: 1,
    label: 'Offerio OTP'
  },
  // {
  //   id: 3,
  //   label: 'FlipSell OTP'
  // },
  {
    id: 2,
    label: 'One Flow OTP'
  },
  {
    id: 4,
    label: 'Flipsell OTP'
  },
  {
    id: 3,
    label: 'Other OTP'
  }
];

export const otpInitialState = {
  templateId: '',
  template: ''
};
