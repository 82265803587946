import CasaField from '../models/CasaField';
import config from '../../../config';
import TenantField, { EntityType } from '../models/TenantField';
import { isNull, omitBy, pick } from 'lodash';

const { ruleServerUrl } = config;

export const getCasaFields = (): Promise<CasaField[]> => {
  return fetch(`${ruleServerUrl}/users/casa-fields`, {
    credentials: 'include'
  }).then(response => response.json());
};

export const getTenantFields = (
  businessUnitId: number,
  entityType: EntityType,
  offset: number,
  limit: number,
  search: string | null = null
): Promise<{ count: number; rows: TenantField[] }> => {
  const params = new URLSearchParams(
    omitBy({ businessUnitId, entityType, offset, limit, search }, isNull) as {}
  ).toString();

  return fetch(`${ruleServerUrl}/users/casa-fields/tenant-fields?${params}`, {
    credentials: 'include'
  }).then(response => response.json());
};

export const updateTenantFields = (
  businessUnitId: number,
  fields: TenantField[],
  entityType: EntityType
): Promise<any> => {
  const updatedField = fields.map(field => ({
    ...pick(field, ['displayLabel', 'show', 'casaFieldName', 'id']),
    entityType
  }));

  return fetch(
    `${ruleServerUrl}/users/casa-fields/tenant-fields?businessUnitId=${businessUnitId}`,
    {
      method: 'POST',
      body: JSON.stringify(updatedField),
      credentials: 'include',
      headers: {
        'Content-type': 'application/json'
      }
    }
  );
};
