import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { FlipbookLayoutTile } from '../../../models/FlipbookV2/FlipbookV2LayoutTile.model';
import { TileDataCollection } from '../../../models/FlipbookV2/TileData/TileDataCollection.model';
import ProductCard from './ProductCard';
import { LayoutType } from '../../../models/FlipbookV2/FlipbookV2Layout.model';
import _, { flatten, get, isEmpty, isUndefined, map } from 'lodash';
import CasaProductMaster from '../../../services/productMaster.service';
import { getStockItems } from '../../../services/ecomCart.service';
import { setProductsWithStockItems } from '../Utiles/FlipbookUtiles';
import { UserContext } from '../../../hooks/UserContext';
import { FlipbookCartContext } from '../Hooks/FlipbookCartContext';
import { BusinessUnitContext } from '../../OfferDefinition/BusinessUnitContext';
import { FlipbookContext } from '../Hooks/FlipbookContext';
import { FlipbookProductsContext } from '../Hooks/FlipbookProductsContext';

interface ProductCards {
  isCustomerView: boolean;
  flipbookLayoutTile: FlipbookLayoutTile;
  productHierarchy: any;
  layoutType: LayoutType;
}
export const ProductCards: FC<ProductCards> = ({
  isCustomerView,
  flipbookLayoutTile,
  productHierarchy,
  layoutType
}) => {
  const [allProducts, setAllProducts] = useState<any>();
  const [isStockLoaded, setIsStockLoaded] = useState<boolean>(false);
  const userInfo = useContext(UserContext);
  const flipbookCartContext = useContext(FlipbookCartContext);
  const flipbookContext = useContext(FlipbookContext);
  const flipbookToken = flipbookContext.flipbookContext?.flipbookToken || '';
  const businessUnitContext = useContext(BusinessUnitContext);
  const businessId = businessUnitContext.business.id;
  const selectedBusinessId = businessUnitContext.business.id;
  const storeCode = flipbookCartContext.storeCode;
  const tenantName =
    _.get(userInfo, 'userInfo.esKey') ||
    _.get(userInfo, 'userInfo.tenant.esKey');
  const isSplitShipment =
    businessUnitContext.business?.flipbookConfig?.isSplitShipment || false;
  const { setProducts, setSkus, flipbookPages } = useContext(
    FlipbookProductsContext
  );
  const updateProductDataWithStock = useCallback(
    (result: any, arrayOfSkus: any) => {
      if (isEmpty(result) || isUndefined(businessId)) {
        return;
      }
      getStockItems(tenantName, businessId, storeCode, arrayOfSkus).then(
        stockItems => {
          const updatedProducts = setProductsWithStockItems(
            result,
            stockItems,
            isSplitShipment
          );
          setIsStockLoaded(true);
          setAllProducts(updatedProducts);
        },
        err => {
          setIsStockLoaded(true);
          setAllProducts(
            setProductsWithStockItems(result, [], isSplitShipment)
          );
        }
      );
    },
    [businessId, isSplitShipment, storeCode, tenantName]
  );

  useEffect(() => {
    setIsStockLoaded(false);
  }, [storeCode]);
  useEffect(() => {
    if (!isUndefined(allProducts) || isUndefined(selectedBusinessId)) {
      return;
    }
    const tileData: any = flipbookLayoutTile?.tileData;
    const currentTileSKUs = map(tileData?.currentPageSKUS, 'sku');
    const flattenSkus = flatten(currentTileSKUs);
    if (isEmpty(flipbookPages) || businessId === 0) return;
    if (isCustomerView) {
      CasaProductMaster.listProductsBySkuAndToken(
        flipbookToken,
        flattenSkus
      ).then(res => {
        setProducts((prevProducts: any) => [
          ...prevProducts,
          ...flatten([res])
        ]);
        setSkus((prevSkus: any) => [...prevSkus, ...flattenSkus]);
        setIsStockLoaded(false);
        setAllProducts(res);
      });
    } else {
      CasaProductMaster.listProductsBySku(flattenSkus, selectedBusinessId).then(
        res => {
          setProducts((prevProducts: any) => [
            ...prevProducts,
            ...flatten([res])
          ]);
          setSkus((prevSkus: any) => [...prevSkus, ...flattenSkus]);
          setAllProducts(res);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allProducts,
    flipbookLayoutTile,
    flipbookToken,
    isCustomerView,
    selectedBusinessId,
    flipbookPages,
    businessId
  ]);

  useEffect(() => {
    const isSessionActivated = flipbookCartContext.isSessionActivated;
    if (!isSessionActivated || isStockLoaded || isUndefined(allProducts)) {
      return;
    }
    const tileData: any = flipbookLayoutTile?.tileData;
    const currentTileSKUs = map(tileData?.currentPageSKUS, 'sku');
    const flattenSkus = flatten(currentTileSKUs);
    updateProductDataWithStock(allProducts, flattenSkus);
  }, [
    allProducts,
    flipbookCartContext.isSessionActivated,
    flipbookLayoutTile,
    isStockLoaded,
    updateProductDataWithStock,
    storeCode
  ]);
  const productCard = () => {
    const tileData: any = flipbookLayoutTile?.tileData;
    const currentPageSKUS = tileData?.currentPageSKUS;
    return map(currentPageSKUS, skuWithPath => {
      const isPersonalised = get(tileData, 'isPersonalised', false);
      const tileDataCollection = new TileDataCollection(
        tileData?.title,
        tileData?.skus,
        tileData?.isShowTitleOnFlipbook,
        tileData?.productHierarchyTreeChecked,
        tileData?.productHierarchyTreeExpanded,
        tileData?.productsFilteredByFirstHierarchy,
        tileData?.productCardTemplate,
        [skuWithPath]
      );
      return (
        <div>
          <ProductCard
            isStockLoaded={isStockLoaded}
            isProductLoaded={!isUndefined(allProducts) && businessId !== 0}
            tileDataCollection={tileDataCollection}
            productHierarchy={productHierarchy}
            allProducts={allProducts}
            isPersonalised={isPersonalised}
          />
        </div>
      );
    });
  };
  return (
    <div className={'center-align'}>
      <div>
        <div
          className={`${
            layoutType === LayoutType.SINGLE
              ? 'product-card-container-4x4'
              : 'product-card-container-2x2'
          }`}
        >
          {productCard()}
        </div>
      </div>
    </div>
  );
};
