import _ from 'lodash';
import moment from 'moment';
import { createCart } from '../../../services/ecomCart.service';
import get from 'lodash/get';
import { CART_TOKEN } from './FlipbookConfig';
import { v1 as uuidv1 } from 'uuid';

const isMobileView = window.screen.width <= 600;

export const skeletonCardWidth = () => {
  return isMobileView ? 180 : '11.5rem';
};

export const skeletonCardHeight = () => {
  return isMobileView ? 240 : '11.5rem';
};

function updateFlipbookCart(ecomResponse: any) {
  let lineItems = _.filter(ecomResponse.included, { type: 'line_item' });
  const allCartVariants = _.filter(ecomResponse.included, {
    type: 'variant'
  });
  // TODO: below lineItems filter is added to handle spree 'line_item' related issue, currently handled it in client side. We need to check spree remove item API.
  const variantIds = _.map(allCartVariants, 'id');
  lineItems = _.filter(lineItems, item =>
    _.includes(variantIds, item.relationships.variant.data.id)
  );
  const cart = _.map(lineItems, item => {
    const ecomVariantId = _.get(item, 'relationships.variant.data.id');
    return {
      lineItemId: _.get(item, 'id'),
      quantity: _.get(item, 'attributes.quantity'),
      sku: _.get(
        _.find(allCartVariants, { id: ecomVariantId }),
        'attributes.sku'
      )
    };
  });
  return cart;
}

function getTotalCartAmount(cartItems: any, casaProducts: any) {
  const amountList = _.map(cartItems, item => {
    const mrp = _.get(_.find(casaProducts, { id: item.sku }), 'mrp');
    return item.quantity * mrp;
  });
  return _.sumBy(amountList);
}

function isEcomUserLoggedIn() {
  const ecomTokenExpiresIn: any = localStorage.getItem('tokenExpiresIn');
  const now = moment();
  if (!_.isEmpty(ecomTokenExpiresIn)) {
    return now < moment(ecomTokenExpiresIn);
  } else {
    return false;
  }
}
function getEcomUserToken() {
  return localStorage.getItem('token');
}

function getCasaEcomHeaders(): any {
  return isEcomUserLoggedIn()
    ? {
        authorization: `Bearer ${getEcomUserToken()}`
      }
    : {
        'X-Spree-Order-Token': getEcomUserToken()
      };
}

function createCartInEcom(
  tenantName: string,
  storeCode: string,
  checkoutType: string,
  businessId: number
) {
  return createCart(tenantName, storeCode, checkoutType, businessId).then(
    res => {
      localStorage.setItem('tokenType', CART_TOKEN);
      localStorage.setItem('token', _.get(res, 'data.attributes.token'));
      return { status: 'success' };
    },
    err => {
      console.error('error creating cart in Ecom - ', err);
      return { status: 'failure' };
    }
  );
}

function clearEcomLocalStoregeData() {
  localStorage.clear();
}

const setProductsWithDefaultStock = (
  allProducts: any,
  isStockEnabled: boolean
) => {
  return _.map(allProducts, product => {
    const inStock = isStockEnabled || false;
    const countOnHand = 0;
    const backorderable = false;
    return _.extend(product, { inStock, countOnHand, backorderable });
  });
};

const getFilteredStockItems = (stockItems: any, products: any) => {
  const skus = _.map(products, 'extendedData.sku');
  return _.filter(stockItems, item => _.includes(skus, item.sku));
};

function setProductsWithStockItems(
  allProducts: any,
  stockItems: any,
  isStockEnabled: boolean
) {
  const products = setProductsWithDefaultStock(allProducts, isStockEnabled);
  const filteredStockItems = getFilteredStockItems(stockItems, products);
  if (_.isEmpty(stockItems)) {
    return products;
  }
  const matchingProductsWithStock = _.map(filteredStockItems, item => {
    const { inStock, countOnHand, sku, backorderable } = item;
    const matchingProduct = _.find(
      products,
      product => product.extendedData.sku === sku
    );
    matchingProduct.setProperty('inStock', isStockEnabled || inStock);
    matchingProduct.setProperty('countOnHand', countOnHand);
    matchingProduct.setProperty('backorderable', backorderable);
    return matchingProduct;
  });
  const stockItemsSkus = _.map(filteredStockItems, 'sku');
  const othersProductsWithDefaultStock = _.filter(
    products,
    item => !_.includes(stockItemsSkus, item.extendedData.sku)
  );
  return _.concat(othersProductsWithDefaultStock, matchingProductsWithStock);
}

const getEmptyPagesId = (groupedProducts: any) => {
  const emptyPagesId: any = [];
  _.map(groupedProducts, group => {
    const layout = _.get(group, 'group.layout');
    if (
      _.isEmpty(_.get(layout, 'tileOne.backgroundImage')) &&
      _.isEmpty(_.get(layout, 'tileOne.products')) &&
      _.isEmpty(_.get(layout, 'tileTwo.backgroundImage')) &&
      _.isEmpty(_.get(layout, 'tileTwo.products')) &&
      _.isEmpty(_.get(layout, 'tileTwo.VideoData.URL')) &&
      _.isEmpty(_.get(layout, 'tileOne.VideoData.URL'))
    ) {
      emptyPagesId.push(_.get(group, 'group.groupId'));
    }
  });
  return emptyPagesId;
};

const getCartItemsAsText = (
  cartItems: any,
  allProducts: any,
  type: string,
  name?: string
) => {
  let result: any = '';
  if (!_.isEmpty(cartItems)) {
    const lineSeparator = '%0D%0A%0D%0A------------------------';
    const lineItems = _.join(
      _.map(cartItems, item => {
        const { quantity } = item;
        const product = _.find(allProducts, v => v.sku === item.sku);
        const { images, name, sku } = _.get(product, 'extendedData', {});
        return `%0D%0A%0D%0AProduct+Name%3A+%2A${name}%2A%0D%0AQty%3A+%2A${quantity}%2A%0D%0ASKU%3A+%2A${sku}%2A%0D%0AImage%3A+%2A${_.head(
          images
        )}%2A`;
      }),
      '%20'
    );
    const cartSummary = `%0D%0A%0D%0ATotal+Quantity%3A+_${_.sumBy(
      cartItems,
      'quantity'
    )}_%0D%0A%0D%0A`;
    const stringMessage = {
      mapPage: `Hi,%20I%20have%20added%20the%20following%20products%20to%20cart%20in%20digital%20catalog%20and%20want%20some%20help%20from%20you%20to%20complete%20the%20purchase.${lineSeparator}%20-%20${lineItems}${lineSeparator}${cartSummary}`,
      cart: `Hi%20I%20${name},%20have%20reserved%20the%20following%20products%20in%20digital%20catalog%20and%20want%20some%20help%20from%20you%20to%20complete%20the%20purchase.${lineSeparator}%20-%20${lineItems}${lineSeparator}${cartSummary}`
    };
    result = get(stringMessage, type, stringMessage.mapPage);
  } else {
    result =
      'Hi%2C+I+was+looking+at+your+digital+catalog+and+need+help.+Please+message+me+back.';
  }
  return result;
};

const getValidMobileNumber = (storeDetails: any) => {
  const mobileNumber = _.get(storeDetails, 'mobile', '');
  return !_.isEmpty(mobileNumber) && mobileNumber.length === 10
    ? `91${mobileNumber}`
    : mobileNumber;
};

function generateRandomId() {
  return uuidv1();
}
function getFlipbookTrackingId() {
  const trackingIdFromLS = localStorage.getItem('flipbookTrackingId');
  if (trackingIdFromLS != null) {
    return {
      trackingId: trackingIdFromLS,
      isLandingEventAvailable: false
    };
  }
  return {
    trackingId: generateRandomId(),
    isLandingEventAvailable: true
  };
}

function setFlipbookTrackingId(trackingId: string) {
  localStorage.setItem('flipbookTrackingId', trackingId);
}
export {
  updateFlipbookCart,
  getTotalCartAmount,
  isEcomUserLoggedIn,
  getEcomUserToken,
  getCasaEcomHeaders,
  createCartInEcom,
  clearEcomLocalStoregeData,
  setProductsWithStockItems,
  getEmptyPagesId,
  getCartItemsAsText,
  getValidMobileNumber,
  getFlipbookTrackingId,
  setFlipbookTrackingId
};
