import config from '../config/index';
import _ from 'lodash';
import { Customer } from '../models/Customer';

export function getCustomerByMobile(mobile: any): Promise<Customer> {
  return fetch(`${config.ruleServerUrl}/users/customers/mobile/${mobile}`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .then(customerInfo => _.extend(new Customer(), customerInfo));
}
