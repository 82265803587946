import get from 'lodash/get';
import {
  find,
  flatMap,
  groupBy,
  head,
  last,
  map,
  take,
  transform,
  values
} from 'lodash';

const getProductValuesFromProductData = (
  selectedProduct: any,
  productHierarchy: any
) => {
  return transform(productHierarchy, (result: any, value: any) => {
    const hierarchyJson = {
      [value]: get(selectedProduct, `extendedData.${value}`, '')
    };
    result.push(hierarchyJson);
  });
};
const getHierarchyAsArray = (productHierarchyValues: any) => {
  return transform(productHierarchyValues, (result: any, value: any) => {
    result.push(head(values(value)));
  });
};
function getAbsolutePath(product: any, productHierarchy: string[]) {
  const productHierarchyValues = getProductValuesFromProductData(
    product,
    productHierarchy
  );
  console.log("Get absolute path-=-")
  console.log(productHierarchyValues);
  return getHierarchyAsArray(productHierarchyValues).join('/');
}

function getSkusWithPath(products: any, productHierarchy: string[]) {
  return map(products, product => {
    return {
      path: getAbsolutePath(product, productHierarchy),
      sku: get(product, 'extendedData.sku', '')
    };
  });
}
function getHierarchyDataFromProduct(
  products: any,
  hierarchy: string,
  sku: string
) {
  const currentProduct = find(
    products,
    product => product.extendedData.sku === sku
  );
  return get(currentProduct, hierarchy, '');
}
export function buildSkusWithPath(products: any, productHierarchy: string[]) {
  const productsWithPath = getSkusWithPath(
    products,
    take(productHierarchy, productHierarchy.length - 1)
  );
  console.log(productsWithPath);
  const groupedProductsByPath = groupBy(productsWithPath, 'path');
  return map(groupedProductsByPath, (value, key) => {
    return {
      path: key,
      selectedProducts: map(value, productWithPath => {
        const hierarchyNodeValue = getHierarchyDataFromProduct(
          products,
          last(productHierarchy) || '',
          productWithPath.sku
        );
        return {
          label: hierarchyNodeValue,
          id: productWithPath.sku,
          value: hierarchyNodeValue
        };
      })
    };
  });
}
export function getSKUsFromProductsWithPath(productsWithPath: any) {
  return flatMap(
    map(productsWithPath, product => {
      return map(product.selectedProducts, 'id');
    })
  );
}
