import { takeRight } from 'lodash';
import config from '../../config';
import { ReportTypes } from '../../models/TenantReports';
import { AdditionalField } from '../../components/TenantReports/Shared/AdditionalFields/AdditionalFields';
import { BillReport } from '../../components/TenantReports/MemberReport/BillWise/Filter';

export const downloadReportFile = (url: string, fileName: string) => {
  const indexOfReportUrl = url.indexOf('/report') + 1;
  const absPath = url.slice(indexOfReportUrl);
  /*
   * splittedPaths will have each folder/endpoint as an element.
   * First element will always be REPORT
   * Second element will be TenantId, TenantName, BuID
   * Followed N elements/endpoints representing corresponding path of destiny file
   * */
  const splittedPaths = absPath.split('/');
  const fileEndpoints = takeRight(splittedPaths, splittedPaths.length - 2);
  const file = fileEndpoints.join('/');
  const directory = splittedPaths[1];
  const data = { file, directory };
  const searchParams = new URLSearchParams(Object.entries(data)).toString();

  return fetch(
    `${config.ruleServerUrl}/users/download-file/report?${searchParams}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }
  )
    .then(res => {
      if (res.status !== 200) {
        throw new Error('Something went wrong!');
      }
      return res.blob();
    })
    .then(blob => {
      const file = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = file;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    })
    .catch(e => {
      throw e;
    });
};

export const getReportFields = async (
  reportType: ReportTypes,
  businessId: number
) => {
  const reportFields = await fetch(
    `${config.ruleServerUrl}/users/reports/fields/${reportType}/${businessId}`,
    {
      credentials: 'include'
    }
  ).then(res => {
    console.log('res', res);
    if (res.status !== 200) throw new Error('Something went wrong!');

    return res.json();
  });

  return reportFields;
};

export const getProcessedFilters = (filters: BillReport) => {
  const { additionalFields = [] } = filters;
  type additionalFields = Record<string, string[]>;

  const reducer = (
    prev: additionalFields,
    c: AdditionalField
  ): additionalFields => {
    (prev[c.level] || (prev[c.level] = [])).push(c.fieldName);
    return prev;
  };

  const processedAdditionalFields = additionalFields.reduce(
    reducer,
    {} as additionalFields
  );

  return { additionalFields: processedAdditionalFields };
};
