import * as yup from 'yup';

export const migrateCusotmerToSchema = () => {
  const messages = {
    oldMobileNumber: ['Old mobile number is Required'],
    newMobileNumber: ['New mobile number is Required'],
    compareMobileNumber: ['Old and new mobile number cannot be the same']
  };
  const phoneRegExp = /^[5-9]{1}[0-9]{9}$/;
  return yup.object().shape({
    oldMobileNumber: yup
      .string()
      .trim()
      .matches(phoneRegExp, 'mobile number is not valid')
      .required(messages.oldMobileNumber[0]),

    newMobileNumber: yup
      .string()
      .trim()
      .matches(phoneRegExp, 'mobile number is not valid')
      .test('match', messages.compareMobileNumber[0], function() {
        return this.parent.oldMobileNumber !== this.parent.newMobileNumber;
      })
      .required(messages.newMobileNumber[0])
  });
};
