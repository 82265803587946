import config from '../config/index';

const formData = (key, data, currentStore, filterType) => {
  const files = Array.from(data);
  const formData = new FormData();
  files.forEach(file => {
    file = key !== 'csv' ? JSON.stringify(file) : file;
    formData.append(key, file);
  });
  formData.append('currentStore', currentStore);
  formData.append('filterType', filterType);
  return formData;
};
const getPrintLabels = (key, data, currentStore, filterType) => {
  return fetch(`${config.ruleServerUrl}/users/product-labels/filter`, {
    credentials: 'include',
    method: 'POST',
    body: formData(key, data, currentStore, filterType)
  })
    .then(async response => {
      const body = await response.json();
      return body;
    })
    .catch(err => {
      console.error(err);
    });
};

export default {
  getPrintLabels
};
