import React, { Context } from 'react';
import noop from 'lodash/noop';

type FlipbookProductsContextType = {
  skus: string[];
  setSkus: any;
  products: any[];
  setProducts: any;
  flipbookPages: any;
  setFlipbookPages: (flipbookPages: any) => void;
};

export const FlipbookProductsContext: Context<FlipbookProductsContextType> = React.createContext<
  FlipbookProductsContextType
>({
  skus: [],
  setSkus: noop,
  products: [],
  setProducts: noop,
  flipbookPages: [],
  setFlipbookPages: noop
});
