import _ from 'lodash';
import {
  PRODUCT_TEMPLATE_FIELD_DESCRIPTION,
  PRODUCT_TEMPLATE_FIELD_TITLE
} from '../PageGenerators/LayoutTile';
import moment from 'moment';

const getProductTemplateFieldByType = (
  productCardTemplate: any,
  type: string
) => {
  return _.get(
    _.find(_.get(productCardTemplate, 'productFields', []), { type: type }),
    'productFieldValue',
    ''
  );
};

export const getProductCardData = (productCardTemplate: any) => {
  return {
    productTileHierarchyTitle: getProductTemplateFieldByType(
      productCardTemplate,
      PRODUCT_TEMPLATE_FIELD_TITLE
    ),
    productTileHierarchyDescription: getProductTemplateFieldByType(
      productCardTemplate,
      PRODUCT_TEMPLATE_FIELD_DESCRIPTION
    )
  };
};

const isOfferDateValid = (data: any) => {
  const mopStartDate = _.get(data, 'mopStartDate', '');
  const mopEndDate = _.get(data, 'mopEndDate', '');
  return moment(moment()).isBetween(mopStartDate, mopEndDate);
};
export const isOfferValid = (data: any) => {
  return !_.isEmpty(data.mop) && isOfferDateValid(data);
};

const getPriceWithComma = (data: any) => {
  return parseInt(data).toLocaleString();
};
export const getSavePrice = (data: any) => {
  return isOfferValid(data) ? getPriceWithComma(data.mrp - data.mop) : '';
};
export const getSellingPrice = (data: any) => {
  return isOfferValid(data)
    ? getPriceWithComma(data.mop)
    : getPriceWithComma(data.mrp);
};
export const getActualPrice = (data: any) => {
  return isOfferValid(data) ? getPriceWithComma(data.mrp) : '';
};
export const getHeadImage = (data: any): string => {
  return _.get(data, 'extendedData.images', ['']) || '';
};
