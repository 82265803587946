import React, { FC } from 'react';
import { IconButton, Popover } from '@material-ui/core';
import AppsIcon from '@material-ui/icons/Apps';
import _ from 'lodash';
import Collections from '../Collections/Collections';
import { anchorOriginObj, getColor, transformOriginObj } from './utiles';
import { FlipbookLayout } from '../../../models/FlipbookV2/FlipbookV2Layout.model';

interface CollectionsPopoverProps {
  flipbookPages: FlipbookLayout[];
  goToPageNumber: (number: any) => void;
  isEditMode: boolean;
  isCustomerView: boolean;
  flipbookIdOrToken: string;
}

export const CollectionsPopover: FC<CollectionsPopoverProps> = ({
  flipbookPages,
  goToPageNumber,
  isEditMode,
  flipbookIdOrToken,
  isCustomerView
}) => {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const collectionPopoverId = isOpen ? 'simple-popover' : undefined;

  const onClickOpen = (value: any) => {
    setIsOpen(true);
    setAnchorEl(value);
  };

  const onClickCollections = (pageNumber: number) => {
    setIsOpen(false);
    goToPageNumber(pageNumber);
  };

  function tilePopoverComponent() {
    return (
      <div className={'flipbook-tiles-popover-container'}>
        <div className={'my-4'}>
          <h3 className={'flipbook-popover-text-header uppercase center'}>
            Collection
          </h3>
        </div>
        <div className={'flipbook-tiles-grid-container'}>
          {/*{getCollectionItems()}*/}
          {!_.isEmpty(flipbookPages) && (
            <Collections
              flipbookPages={flipbookPages}
              isEditMode={isEditMode}
              goToPageNumber={pageNumber => onClickCollections(pageNumber)}
              isCollectionSection={true}
              flipbookIdOrToken={flipbookIdOrToken}
              isCustomerView={isCustomerView}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={'flipbook-bar-icon'}>
        <IconButton
          style={{ color: getColor(isOpen) }}
          className={'outline-none'}
          aria-label="appsIcon"
          aria-describedby={collectionPopoverId}
          onClick={e => onClickOpen(e.currentTarget)}
        >
          <AppsIcon />
        </IconButton>
      </div>
      <Popover
        id={collectionPopoverId}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={e => setIsOpen(false)}
        anchorOrigin={anchorOriginObj}
        transformOrigin={transformOriginObj}
      >
        {tilePopoverComponent()}
      </Popover>
    </div>
  );
};

export default CollectionsPopover;
