/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';

const IFrameWrapper = ({ iframeURL }) => {
  const ref = React.useRef();

  const [height, setHeight] = React.useState('0px');

  const onLoad = () => {
    setHeight(ref.current.contentWindow.document.body.scrollHeight + 'px');
  };

  return iframeURL ? (
    <iframe
      ref={ref}
      onLoad={onLoad}
      id="e-bill"
      src={iframeURL}
      width="100%"
      height={height}
      scrolling="no"
      frameBorder="0"
      style={{
        overflow: 'auto'
      }}
      title="e-bill"
    ></iframe>
  ) : (
    <div />
  );
};

export default IFrameWrapper;
