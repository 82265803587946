import { get, isEmpty } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { ReportTypes } from '../../../../models/TenantReports';
import { getReportFields } from '../../../../services/TenantReports/common';
import SelectGrouped from '../SelectGrouped/SelectGrouped';

interface props {
  reportType: ReportTypes;
  businessId?: number;
  onChange: (p: any) => void;
}

export type AdditionalField = {
  reportName: string;
  fieldName: string;
  level: string;
};

export const AdditionalFieldSelector: FC<props> = ({
  reportType,
  businessId,
  onChange
}) => {
  const [additionalFields, setAdditionalFields] = useState<AdditionalField[]>(
    []
  );
  const [selectedAdditionalFields, setSelectedAdditionalFields] = useState<
    AdditionalField[]
  >([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    (async () => {
      if (!businessId) return;

      const reportFields = await getReportFields(reportType, businessId);
      let processed: AdditionalField[] = [];
      Object.entries(
        get(reportFields, 'additional', {}) as Record<string, AdditionalField[]>
      ).forEach(fields => {
        const [key, value] = fields;
        processed = processed.concat(
          (value || []).map(v => ({
            ...v,
            level: key
          }))
        );
      });

      setAdditionalFields(processed);
      setLoader(false);
    })();
  }, [reportType, businessId]);

  if (loader || !businessId || isEmpty(additionalFields)) return <></>;

  return (
    <SelectGrouped
      items={additionalFields}
      handleItemsChange={fields => {
        setSelectedAdditionalFields(fields);
        onChange(fields);
      }}
      selectedItems={selectedAdditionalFields}
      limitTags={1}
    />
  );
};
