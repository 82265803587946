import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as RefreshIcon } from '../../../src/components/Assets/images/icons/refresh.svg';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  filterBar: {
    background: '#F9F9F9',
    display: 'grid',
    justifyContent: 'space-between',
    padding: '15px 25px 5px',
    gridTemplateColumns: 'auto 2fr 1fr 2fr auto',
    gridGap: 20,
    gridAutoRows: 100
  },
  resetButton: {
    height: 50,
    background: '#FFF',
    borderRadius: 35,
    padding: '5px 20px',
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: 16,
    borderColor: '#DDD',
    boxShadow: '0px 3px 6px #00000029'
  },
  searchBar: {
    background: '#FFF',
    borderRadius: 35,
    paddingLeft: 14,
    height: 50
  },
  selectBar: {
    background: '#FFF',
    minHeight: 50,
    paddingTop: 0,
    paddingBottom: 0
  },
  selectBody: {
    '&:focus': {
      background: 'none'
    }
  },
  selectBarSelect: {
    paddingTop: 0,
    paddingBottom: 0
  },
  filterLabel: {
    fontWeight: 'bold',
    marginBottom: 5
  },
  hiddenFilterLabel: {
    marginBottom: 5,
    visibility: 'hidden'
  },
  justifyColumnStart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start'
  },
  justifyColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
});

export const OfferFilterBar = props => {
  const classes = useStyles(props);
  const {
    searchTerm,
    onSearchTermChange,
    selectedTags,
    onSelectedTagsChange,
    allTags,
    selectedStatus,
    onSelectedStatusChange,
    allStatuses,
    onResetClick
  } = props;
  return (
    <div>
      <div className={classes.filterBar}>
        <div className={classes.justifyColumnStart}>
          <span>Filter By:</span>
        </div>
        <div className={classes.justifyColumnStart}>
          <div className={classes.filterLabel}>Tags</div>
          <FormControl>
            <Autocomplete
              classes={{
                inputRoot: classes.selectBar
              }}
              multiple
              disableCloseOnSelect
              renderInput={params => (
                <TextField
                  {...params}
                  id={'tags-select'}
                  variant={'outlined'}
                  placeholder={'Filter by tags'}
                  size={'small'}
                />
              )}
              filterSelectedOptions
              options={allTags}
              getOptionLabel={option => option.name || ''}
              getOptionSelected={(option, value) => option.id === value.id}
              value={selectedTags}
              onChange={onSelectedTagsChange}
              limitTags={2}
            />
          </FormControl>
        </div>
        <div className={classes.justifyColumnStart}>
          <div className={classes.filterLabel}>Status</div>
          <Select
            value={selectedStatus}
            variant={'outlined'}
            className={classes.selectBar}
            classes={{
              select: classes.selectBody
            }}
            onChange={onSelectedStatusChange}
            margin={'dense'}
          >
            {_.map(allStatuses, status => (
              <MenuItem key={status.key} value={status.key}>
                {status.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classes.justifyColumnStart}>
          <div className={classes.filterLabel}>Search</div>
          <TextField
            variant={'outlined'}
            value={searchTerm}
            onChange={onSearchTermChange}
            type={'search'}
            placeholder={'Code, Name, Description'}
            InputProps={{
              classes: {
                root: classes.searchBar
              },
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className={classes.justifyColumnStart}>
          <div className={classes.hiddenFilterLabel}>Reset</div>
          <div>
            <Button
              variant={'outlined'}
              color={'primary'}
              onClick={onResetClick}
              classes={{
                root: classes.resetButton
              }}
            >
              <RefreshIcon style={{ marginRight: 8 }} /> Reset
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
