import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import React, { FC } from 'react';

import styles from './DateFilter.module.scss';

interface DateFilterProps {
  fromDateValue: Date;
  toDateValue: Date;
  onChange: (value: { [key: string]: Date }) => void;
}

const DateFilter: FC<DateFilterProps> = ({
  fromDateValue,
  toDateValue,
  onChange
}) => {
  const handleDateChange = (controlName: string) => (
    selectedDate: MaterialUiPickersDate
  ): void => {
    moment(selectedDate as Date).isValid() &&
      onChange({ [controlName]: selectedDate as Date });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        className={styles.formControl}
        variant="inline"
        margin="none"
        label="Campaign Start Date"
        name="startDate"
        value={fromDateValue}
        format="dd-MM-yyyy"
        onChange={handleDateChange('startDate')}
      />
      <KeyboardDatePicker
        className={styles.formControl}
        format="dd-MM-yyyy"
        margin="none"
        variant="inline"
        label="Campaign End Date"
        value={toDateValue}
        name="endDate"
        onChange={handleDateChange('endDate')}
      />
    </MuiPickersUtilsProvider>
  );
};
export default DateFilter;
