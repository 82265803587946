import FlipbookCoverPage from './PageGenerators/flipbookcoverPage';
import _, { isUndefined, takeRight } from 'lodash';
import AddLayout from './PageGenerators/AddLayout';
import FlipbookPageGradient from './Utiles/FlipbookPageGradient';
import MyCartPage from './PageGenerators/MyCartPage';
import FlipbookPageDimensions from './Utiles/FlipbookPageDimensions';
import { FlipbookPage } from './PageGenerators/common';
import FlipbookBackPage from './PageGenerators/FlipbookBackPage';
import React, { FC, useCallback, useContext } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignoreß
import $ from 'jquery';
import { BusinessUnitContext } from '../OfferDefinition/BusinessUnitContext';
import { FlipbookContext } from './Hooks/FlipbookContext';
import FlipbookPages from './Components/FlipbookPages/FlipbookPages';
import { FlipbookLayout } from '../../models/FlipbookV2/FlipbookV2Layout.model';
import { CircularProgress } from '@material-ui/core';
import { createBrowserHistory } from 'history';
import get from 'lodash/get';
import { FlipbookUrlHash } from './Utiles/FlipbookUrlHash';

interface FlipbookProps {
  flipbookPages: FlipbookLayout[] | undefined;
  flipbookEl: any;
  setPage: (page1: any) => void;
  page: any;
  setTotalPageCount: (totalPage: number) => void;
  isEditMode: boolean;
  goToPageNumber: (pageNumber: number) => void;
  innerPageCount: number;
  storeDetails: any;
  isCustomerView: boolean;
  flipbookIdOrToken: string;
  currentStore: any;
  setCurrentStore: (currentStore: any) => void;
  sidePanelAction: any;
  hasPermission: (permission: string) => boolean;
}

export const Flipbook: FC<FlipbookProps> = ({
  flipbookPages,
  flipbookEl,
  setPage,
  page,
  setTotalPageCount,
  isEditMode,
  goToPageNumber,
  innerPageCount,
  storeDetails,
  isCustomerView,
  flipbookIdOrToken,
  currentStore,
  setCurrentStore,
  sidePanelAction,
  hasPermission
}) => {
  const history = createBrowserHistory();
  const flipbookUrlHash = new FlipbookUrlHash(
    isCustomerView,
    isEditMode,
    flipbookIdOrToken,
    history
  );
  const { business } = useContext(BusinessUnitContext);
  const businessId = business.id;
  const getInitialPageNumber = useCallback(
    (totalPage: number) => {
      const { pathname, search } = window.location;
      const arrayOfPath = pathname.split('/');
      const lastTwoPath = takeRight(arrayOfPath, 2);
      const pageNumberFromEndpoint = get(lastTwoPath, '1', 1);
      if (get(lastTwoPath, '0', '') === 'page') {
        if (pageNumberFromEndpoint > 0 && pageNumberFromEndpoint <= totalPage) {
          return pageNumberFromEndpoint;
        }
      }
      const urlParams = new URLSearchParams(search);
      const paramsStore = urlParams.get('store') || null;
      flipbookUrlHash.setHistoryHash(1, businessId, paramsStore);
      return 1;
    },
    [flipbookUrlHash, businessId]
  );
  const options = {
    width: FlipbookPageDimensions.getFlipbookWidth(),
    height: FlipbookPageDimensions.getFlipbookHeight(),
    // autoCenter: true,
    display: FlipbookPageDimensions.getFlipbookPageDisplay(),
    acceleration: true,
    elevation: 0,
    inclination: 0,
    gradients: true,
    duration: 600,
    when: {
      turned: function(e: any, page1: any, view: any) {
        if (_.includes(view, 1)) {
          // Default or first time fetch
          $(flipbookEl.current).turn('peel', 'br');
        }
        setPage(page1);
      },
      turning: function(e: any, page1: any, view: any) {
        const { search } = window.location;
        const urlParams = new URLSearchParams(search);
        const paramsStore = urlParams.get('store') || null;
        flipbookUrlHash.setHistoryHash(page1, businessId, paramsStore);
      }
    }
  };

  const onFlipbookRefCreated = useCallback(() => {
    const isPageTurnDisabled = () => {
      return !_.isEmpty(_.get(sidePanelAction, 'key'));
    };

    if (flipbookEl.current) {
      //console.log('flipbookEl --->', flipbookEl.current);
      const totalPage = $(flipbookEl.current).turn('pages');
      setTotalPageCount(totalPage);
      if (isPageTurnDisabled()) {
        $(flipbookEl.current).bind('turning', function(event: any) {
          event.preventDefault();
        });
      }
      if (!$(flipbookEl.current).turn('is')) {
        $(flipbookEl.current).turn(Object.assign({}, options, { page }));
        const totalPage = $(flipbookEl.current).turn('pages');
        $(flipbookEl.current).turn('page', getInitialPageNumber(totalPage));
      }
      // $(flipbookEl.current).turn('page', page);
    }
  }, [
    flipbookEl,
    sidePanelAction,
    setTotalPageCount,
    options,
    page,
    getInitialPageNumber
  ]);
  const businessUnitContext = useContext(BusinessUnitContext);

  if (isUndefined(flipbookPages)) {
    return (
      <div style={{ height: 657 }}>
        <div className={'flipbook-loader'}>
          <CircularProgress
            color="inherit"
            variant="indeterminate"
            size={60}
            thickness={5}
          />
        </div>
      </div>
    );
  }
  return (
    <FlipbookContext.Consumer>
      {({ flipbookContext, setFlipbookContext }) => {
        return (
          <div
            className={'flipbook'}
            ref={x => {
              flipbookEl.current = x;
              onFlipbookRefCreated();
            }}
          >
            {
              <FlipbookCoverPage
                flipbookPages={flipbookPages}
                isEditMode={isEditMode}
                goToPageNumber={goToPageNumber}
                flipbookIdOrToken={flipbookIdOrToken}
                isCustomerView={isCustomerView}
              />
            }
            {
              <FlipbookPages
                isEditMode={isEditMode}
                isCustomerView={isCustomerView}
                flipbookPages={flipbookPages}
                hasPermission={hasPermission}
              />
            }
            {isEditMode && (
              <AddLayout
                pageNumber={innerPageCount}
                flipbookId={parseInt(flipbookIdOrToken)}
              />
            )}
            {isCustomerView && FlipbookPageGradient.isMobileView() && (
              <MyCartPage
                pageNumber={1}
                storeDetails={storeDetails}
                flipbookIdOrToken={flipbookIdOrToken}
                currentStore={currentStore}
              />
            )}
            {FlipbookPageDimensions.getFlipbookPageDisplay() === 'double' &&
              isEditMode &&
              innerPageCount % 2 === 0 && (
                <FlipbookPage
                  width={FlipbookPageDimensions.getFlipbookWidth()}
                  height={FlipbookPageDimensions.getFlipbookHeight()}
                >
                  {
                    <div className={'height-100-per'}>
                      <div
                        className={'page-effect'}
                        style={{
                          backgroundImage: FlipbookPageGradient.getGradientForPage(
                            3
                          )
                        }}
                      />
                    </div>
                  }
                </FlipbookPage>
              )}
            {FlipbookPageDimensions.getFlipbookPageDisplay() === 'double' &&
              !isEditMode &&
              innerPageCount % 2 !== 0 && (
                <FlipbookPage
                  width={FlipbookPageDimensions.getFlipbookWidth()}
                  height={FlipbookPageDimensions.getFlipbookHeight()}
                >
                  {
                    <div className={'height-100-per'}>
                      <div
                        className={'page-effect'}
                        style={{
                          backgroundImage: FlipbookPageGradient.getGradientForPage(
                            3
                          )
                        }}
                      />
                    </div>
                  }
                </FlipbookPage>
              )}
            {
              <FlipbookBackPage
                pageNumber={2}
                storeDetails={storeDetails}
                businessUnitDetails={_.get(businessUnitContext, 'business', {})}
                currentStore={currentStore}
                flipbookIdOrToken={flipbookIdOrToken}
              />
            }
          </div>
        );
      }}
    </FlipbookContext.Consumer>
  );
};
