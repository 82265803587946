import React, { Context } from 'react';
import _ from 'lodash';
import { Business } from '../../models/User.model';

type BusinessUnitContextType = {
  business: Business;
  setBusiness: (business: Business) => void;
};
export const BusinessUnitContext: Context<BusinessUnitContextType> = React.createContext<
  BusinessUnitContextType
>({
  business: new Business(),
  setBusiness: _.noop
});
