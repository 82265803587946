import {
  DependencyList,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

type QueryFn<T> = (data: {
  page: number;
  offset: number;
  filters?: any;
}) => Promise<T>;

interface UsePagination<T> {
  data: T | null;
  isRefetching: boolean;
  isLoading: boolean;
  error: string | null;
  page: number;
  offset: number;
  setPage: (page: number) => void;
  setOffset: (offset: number) => void;
  refetch: () => void;
}

const usePagination = <T>(
  queryFn: QueryFn<T>,
  deps: DependencyList = [],
  filters?: any,
  shouldWait = false
): UsePagination<T> => {
  const queryFnRef = useRef<QueryFn<T>>(queryFn);
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<null | string>(null);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(25);
  const [isRefetching, setIsRefetching] = useState(false);

  useEffect(() => {
    queryFnRef.current = queryFn;
  }, [queryFn]);

  const fetchApi = useCallback(
    async (setLoadingFn: (status: boolean) => void) => {
      try {
        if (shouldWait) {
          return;
        }
        setLoadingFn(true);
        const response = await queryFnRef.current({ page, offset, filters });
        setData(response);
      } catch (error) {
        setError('Something went wrong!');
      } finally {
        if (!shouldWait) {
          setLoadingFn(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, offset, page, shouldWait].concat(deps)
  );

  useEffect(() => {
    fetchApi(setIsLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchApi].concat(deps));

  const refetch = useCallback(() => {
    fetchApi(setIsRefetching);
  }, [fetchApi]);

  return {
    data,
    isLoading,
    isRefetching,
    error,
    page,
    offset,
    setPage,
    setOffset,
    refetch
  };
};

export default usePagination;
