import Papa from 'papaparse';

export const getCsvHeaders = file => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      step: function(results, parser) {
        if (results.errors.length) reject(results.errors);
        resolve(results.data);
        parser.abort();
      },
      delimiter: ',',
      preview: 0
    });
  });
};

export const isValidCsv = (requiredHeaders, file) => {
  return getCsvHeaders(file).then(csvHeaders => {
    const csvHeadersMap = csvHeaders.reduce((acc, val) => {
      acc[val] = true;
      return acc;
    }, {});
    const missingHeaders = [];

    for (const header of requiredHeaders) {
      if (!csvHeadersMap[header]) missingHeaders.push(header);
    }

    if (missingHeaders.length > 0)
      throw new Error(
        `"${missingHeaders.join('", "')}" are missing in ${file.name}`
      );

    return true;
  });
};
