import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  withStyles
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import InstagramIcon from '@material-ui/icons/Instagram';
import _ from 'lodash';
import React from 'react';
import config from '../../config';
import {
  SocialMediaChannels,
  SocialMediaPlatformActions
} from '../../constants';
import { getCurrentUser } from '../../services/user.service';
import { translateDateToDifferentFormat } from '../../utils';
import fbLogo from '../Assets/fb_logo_100.png';
import CustomCheckbox from '../OfferDefinition/CheckBox';
import OfferDefinitionStepper from '../OfferDefinition/Stepper';
import { primaryColor } from '../OfferDefinition/theme';
import Post from './Post';
import RewardDefinition from './RewardDefinition';
import styles from './styles';
import { JoinBucket } from '../../models/offer/OfferRule.model';
import { Business, User } from '../../models/User.model';
import { BusinessUnitContext } from '../OfferDefinition/BusinessUnitContext';
import renderers from './ProductSelection/renderer';
import SnackbarComponent from './SnackbarComponent';

const FB_URL = 'https://graph.facebook.com/v15.0';
class SocialMediaRewardDefinition extends React.Component {
  constructor(props) {
    super(props);
    const bucket = JoinBucket.newBucket().addProductBucket();
    this.state = {
      name: '',
      activeScreen: 0,
      activeStep: 0,
      channel: '',
      user: null,
      pages: null,
      selectedPage: null,
      posts: null,
      selectedPost: null,
      actionType: null,
      rewards: [],
      hasPageChanged: false,
      customMessageTemplate: {},
      hasCustomMessage: false,
      bucket: bucket,
      business: new Business(),
      currUser: new User(),
      errors: null,
      loading: false
    };
  }

  initFacebook = () => {
    // 761831721736241 -> app id -> metro
    window.FB.init({
      appId: '915596119986283',
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v18.0'
    });
  };
  handleBusinessChange = event => {
    const selectedBusiness = _.find(this.state.currUser.getBusinesses(), {
      id: event.target.value
    });
    console.log('selectedBusiness', selectedBusiness);
    this.setState({ business: selectedBusiness });
  };
  processUserInfo = async (userInfo, authResponseObj) => {
    if (_.get(userInfo, 'error')) {
      console.log('logout and then login after some time to continue ');
      // rate over limit
      return;
    }
    const { authResponse } = authResponseObj;
    const { accessToken } = authResponse;
    console.log('Good to see you, ' + userInfo.name + '.');

    const { tenant } = this.state.currUser;
    const payload = {
      accessToken,
      tenantId: tenant.id
    };

    fetch(`${config.ruleServerUrl}/users/social-media-tenant-token`, {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-type': 'application/json'
      }
    }).catch(err => console.log('err while saving token', err));
    this.setState({
      user: { ...userInfo, accessToken },
      isAuthorized: true,
      activeStep: 1
    });
  };

  processLoginResponse = response => {
    if (response.authResponse) {
      window.FB.api('/me', userInfo =>
        this.processUserInfo(userInfo, response)
      );
    } else {
      console.log('User cancelled login or did not fully authorize.');
    }
  };

  login = () => {
    this.initFacebook();
    const permissionScopes =
      'public_profile,email,pages_show_list,pages_manage_engagement,pages_read_user_content,pages_manage_metadata,pages_messaging,business_management';
    window.FB.login(response => this.processLoginResponse(response), {
      scope: permissionScopes
    });
    return false;
  };

  handleClick = () => {
    try {
      this.login();
    } catch (e) {
      console.log('Init');
      this.initFacebook().then(() => this.login());
    }
  };

  handleChange = event =>
    this.setState({
      customMessageTemplate: {
        ...this.state.customMessageTemplate,
        [event.target.name]: event.target.value
      }
    });

  logout = () => {
    this.initFacebook();
    window.FB.logout(() => {
      this.setState({
        isAuthorized: false,
        activeStep: 0,
        activeScreen: 0,
        pages: null,
        posts: null,
        user: null,
        channel: '',
        selectedPage: null,
        selectedPost: null
      });
    });
  };

  configureWebHookForPages = pages => {
    const WebHookPagesReqs = pages.map(page =>
      fetch(
        `${FB_URL}/${page.id}/subscribed_apps?access_token=${page.access_token}&subscribed_fields=feed`,
        {
          method: 'POST'
        }
      )
        .then(r => r.json())
        .then(r => console.log(`webhook configuration for ${page.id} `, r))
    );
    Promise.all(WebHookPagesReqs);
  };
  processPagesResponse = parsedResponse => {
    if (!_.get(parsedResponse, 'data')) {
      console.log('Error while fetching pages');
      return this.setState({
        pages: parsedResponse.data,
        activeStep: 0
      });
    }

    console.log('pages response - ', parsedResponse);
    this.configureWebHookForPages(parsedResponse.data);
    this.setState({
      pages: parsedResponse.data,
      activeStep: 1
    });
  };

  getPages = () => {
    const { user, pages } = this.state;
    console.log(user, pages);
    if (user && !pages) {
      fetch(`${FB_URL}/${user.id}/accounts?access_token=${user.accessToken}`)
        .then(response => response.json())
        .then(res => this.processPagesResponse(res));
    }
  };

  getPagesAccessToken = () =>
    this.state.pages?.find(page => page.id === this.state.selectedPage)
      ?.access_token;

  getValidPosts = posts => {
    const postsWithContent = _.filter(posts, post => post.message);
    const proccessedPosts = _.map(postsWithContent, post => ({
      ...post,
      time: translateDateToDifferentFormat(post['created_time'], 'hh:mm:ss a'),
      date: translateDateToDifferentFormat(post['created_time'], 'DD-MM-YYYY')
    }));
    return _.slice(proccessedPosts, 0, 5);
  };

  getPosts = () => {
    const { user, channel, selectedPage, posts, hasPageChanged } = this.state;
    if (channel === SocialMediaChannels.FACEBOOK) {
      if (selectedPage && (!posts || hasPageChanged)) {
        const pageAccessToken = this.getPagesAccessToken();
        console.log('pageAccessToken', pageAccessToken);
        fetch(
          `${FB_URL}/${selectedPage}/feed?access_token=${pageAccessToken ||
            user.accessToken}`
        )
          .then(response => response.json())
          .then(parsedResponse =>
            this.setState({
              posts: this.getValidPosts(parsedResponse.data),
              activeStep: 2,
              hasPageChanged: false
            })
          );
      }
    }

    if (channel === SocialMediaChannels.INSTAGRAM) {
      selectedPage &&
        (!posts || hasPageChanged) &&
        fetch(
          `${FB_URL}/${selectedPage}?fields=instagram_business_account&access_token=${user.accessToken}`
        )
          .then(response => response.json())
          .then(response => {
            return fetch(
              `${FB_URL}/${response['instagram_business_account'].id}/media?fields=permalink,caption,timestamp,like_count,comments_count&access_token=${user.accessToken}`
            )
              .then(res => res.json())
              .then(res =>
                this.setState({
                  posts: res.data.map(post => ({
                    ...post,
                    message: post.caption,
                    time: translateDateToDifferentFormat(
                      post['timestamp'],
                      'hh:mm:ss a'
                    ),
                    date: translateDateToDifferentFormat(
                      post['timestamp'],
                      'DD-MM-YYYY'
                    )
                  })),
                  hasPageChanged: false
                })
              );
          });
    }
  };

  componentDidMount = async () => {
    const user = await getCurrentUser();
    const tenantBusinesses = user.getBusinesses();
    this.setState({
      business:
        _.chain(tenantBusinesses)
          .orderBy(['id'])
          .first()
          .value() || new Business(),
      currUser: user
    });
  };

  handleError = () => {
    const { name, selectedPost, rewards, bucket, business } = this.state;
    let error;
    if (_.isEmpty(name)) {
      error = {
        message: 'Name is required',
        type: 'error'
      };
    }

    if (_.isEmpty(selectedPost)) {
      error = {
        message: 'post not selected!! plesae select a post!!',
        type: 'error'
      };
    }

    if (_.isEmpty(business) || _.get(business, 'id', null) === null) {
      error = {
        message: 'Business not selected!! plesae select a Business!!',
        type: 'error'
      };
    }

    if (!bucket.buckets.some(b => b.isDefined)) {
      error = {
        message:
          'If lead needs to be created for a Post Engagement, please select products',
        type: 'error'
      };
    }

    const isActionSet = _.some(
      rewards,
      reward => !_.isEmpty(reward.actionType)
    );
    if (!isActionSet) {
      error = { message: 'Actions Needed to be configured!!', type: 'error' };
    }
    this.setState({ errors: error });
    return error;
  };

  saveRewardDefinition = async () => {
    if (this.handleError()) {
      return;
    }
    const {
      name,
      channel,
      selectedPost,
      rewards,
      customMessageTemplate,
      bucket,
      currUser
    } = this.state;
    const { tenant } = currUser;
    console.log('processing rewards');
    const processedRewards = _.map(rewards, reward => {
      const { id, ...rewardData } = reward;
      return rewardData;
    });
    const channelConfig = {
      name: name,
      tenantId: tenant.id,
      channel: channel,
      postId: selectedPost,
      rewards: processedRewards
    };
    const channelLead = {
      mappingDefinition: {
        productMapping: _.get(bucket, 'buckets', []),
        messageTemplate: customMessageTemplate
      },
      tenantId: tenant.id,
      postId: selectedPost
    };
    const payload = { channelConfig, channelLead };
    this.setState({ loading: true });
    try {
      const rewardRes = await fetch(
        `${config.ruleServerUrl}/social-media/add-channel`,
        {
          credentials: 'include',
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            'Content-type': 'application/json'
          }
        }
      );
      if (rewardRes.status !== 201) {
        throw new Error(await rewardRes.json());
      }
      window.top.location.href =
        config.dashboardUrl + '/social-media-engagements';
    } catch (err) {
      console.log('error occured', err);
      this.setState({
        loading: false,
        errors: {
          message: 'Something went wrong! reward not saved',
          type: 'error'
        }
      });
    }
  };

  isActionsCovered() {
    const { rewards, channel } = this.state;
    const actions = Object.values(SocialMediaPlatformActions[channel] || []);
    const currActions = rewards.map(reward => reward.actionType);
    if (currActions.length >= actions.length) return true;
    const currActionSet = new Set(currActions);
    let isEq = true;
    actions.forEach(action => {
      if (!currActionSet.has(action)) {
        isEq = false;
      }
    });
    return isEq;
  }

  render() {
    const {
      name,
      activeScreen,
      activeStep,
      channel,
      pages,
      selectedPage,
      posts,
      selectedPost,
      hasPageChanged,
      hasCustomMessage,
      customMessageTemplate,
      isAuthorized,
      rewards,
      bucket,
      business,
      errors,
      loading
    } = this.state;
    const { classes } = this.props;
    const steps = ['Login', 'Select Page', 'Select Post', 'Engage Customer'];

    if (loading) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '1rem',
            minHeight: '505px'
          }}
        >
          <CircularProgress />
          <span>Loading...</span>
        </div>
      );
    }

    if (!selectedPage) {
      this.getPages();
    }

    if ((selectedPage && !selectedPost) || hasPageChanged) {
      this.getPosts();
    }

    return (
      <div style={{ margin: '0 10%' }}>
        <div style={{ display: 'flex' }}>
          <h3 style={{ margin: '30px 0px', flex: 10 }}>
            Configure Social Media Engagement
          </h3>
          {isAuthorized && (
            <button
              onClick={() => this.logout()}
              className={classes.logoutButton}
            >
              <span>
                <b>Logout</b>
              </span>
            </button>
          )}
        </div>
        <Paper>
          <OfferDefinitionStepper
            activeStep={activeStep}
            steps={steps}
            style={{ width: 600, margin: '0 auto' }}
          />
          <div
            style={{
              width: '90%',
              margin: '0 auto',
              position: 'relative'
            }}
          >
            {activeScreen === 0 && (
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 15
                  }}
                >
                  <div style={{ flex: 9 }} />
                </div>
                <div
                  hidden={isAuthorized}
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#F5F5F5',
                    marginTop: 20,
                    borderRadius: 5,
                    padding: 15
                  }}
                >
                  <p>Please login to proceed further.</p>
                  <p>
                    By logging in, you grant us permission to access your pages
                    and posts on the pages.
                  </p>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{ flex: 5 }}
                      onClick={() =>
                        this.setState({ channel: SocialMediaChannels.FACEBOOK })
                      }
                    >
                      <button
                        onClick={() => {
                          this.handleClick();
                        }}
                        className={classes.facebookButton}
                      >
                        <img
                          src={fbLogo}
                          alt="facebookLogo"
                          style={{ width: 20, marginBottom: 7, marginRight: 5 }}
                        />
                        <span>
                          <b>Login with Facebook</b>
                        </span>
                      </button>
                    </div>
                    <div style={{ flex: 1, paddingTop: 15 }}>(OR)</div>
                    <div
                      style={{ flex: 5 }}
                      onClick={() =>
                        this.setState({
                          channel: SocialMediaChannels.INSTAGRAM
                        })
                      }
                    >
                      <button
                        onClick={() => this.handleClick()}
                        className={classes.instagramButton}
                      >
                        <InstagramIcon style={{ marginRight: 5 }} />
                        <span>
                          <b>Login with Instagram</b>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                {pages && (
                  <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ flex: 2 }}>Name:</span>
                      <TextField
                        value={name}
                        onChange={event =>
                          this.setState({ name: event.target.value })
                        }
                        placeholder="Enter the Description"
                        style={{ width: '50%', marginLeft: 15, flex: 7 }}
                      />
                      <div style={{ flex: 9 }} />
                    </div>
                    <div style={{ marginTop: 15 }}>Select page:</div>
                    <RadioGroup
                      name="Page"
                      value={selectedPage}
                      onChange={event =>
                        this.setState({
                          selectedPage: event.target.value,
                          hasPageChanged: true
                        })
                      }
                    >
                      <span
                        style={{
                          marginLeft: 30,
                          backgroundColor: '#F5F5F5',
                          marginTop: 10,
                          borderRadius: 5,
                          paddingLeft: 10
                        }}
                      >
                        {pages &&
                          pages.map(page => (
                            <div
                              key={page.id}
                              style={{
                                margin: 10,
                                paddingLeft: 15
                              }}
                            >
                              <FormControlLabel
                                value={page.id}
                                control={
                                  <Radio
                                    classes={{
                                      root: classes.radio,
                                      checked: classes.checked
                                    }}
                                  />
                                }
                                label={<div>{page.name}</div>}
                              />
                            </div>
                          ))}
                      </span>
                    </RadioGroup>
                  </>
                )}
                {selectedPage && posts && (
                  <>
                    <div style={{ marginTop: 15 }}>Select post:</div>
                    <RadioGroup
                      name="Post"
                      value={selectedPost}
                      onChange={event =>
                        this.setState({ selectedPost: event.target.value })
                      }
                    >
                      <span
                        style={{
                          marginLeft: 30,
                          backgroundColor: '#EEEEEE',
                          marginTop: 10,
                          borderRadius: 5
                        }}
                      >
                        {posts
                          .filter(post => post.message)
                          .map(post => (
                            <div
                              key={post.id}
                              style={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: 5,
                                margin: 18,
                                padding: 15
                              }}
                            >
                              <FormControlLabel
                                value={post.id}
                                label={<Post post={post} />}
                                control={
                                  <Radio
                                    classes={{
                                      root: classes.radio,
                                      checked: classes.checked
                                    }}
                                  />
                                }
                              />
                            </div>
                          ))}
                      </span>
                    </RadioGroup>
                  </>
                )}
              </div>
            )}
            {activeScreen === 1 && (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '1rem'
                  }}
                >
                  <BusinessUnitContext.Provider value={{ business: business }}>
                    {renderers[bucket.buckets[0].className](
                      bucket.buckets[0],
                      updatedBucket => {
                        console.log('updatedBucket', updatedBucket);
                        this.setState({ bucket: updatedBucket });
                      },
                      bucket
                    )}
                  </BusinessUnitContext.Provider>
                  <div>
                    <FormControl
                      variant="outlined"
                      style={{
                        minWidth: '10rem',
                        marginTop: '-10px',
                        scale: '0.8'
                      }}
                    >
                      <InputLabel id="businessLabel">Business unit</InputLabel>
                      <Select
                        labelId="businessLabel"
                        value={this.state.business.id}
                        onChange={this.handleBusinessChange}
                      >
                        {_.map(this.state.currUser.getBusinesses(), b => (
                          <MenuItem value={b.id} key={b.id}>
                            {b.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div id="rewardDefinition">
                  {rewards.map((reward, index) => (
                    <RewardDefinition
                      channel={channel}
                      reward={reward}
                      key={index}
                      onChange={reward => {
                        rewards[index] = reward;
                        this.forceUpdate();
                      }}
                      remove={index => {
                        rewards.splice(index, 1);
                        this.forceUpdate();
                      }}
                    />
                  ))}
                </div>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    borderWidth: 2,
                    height: 40,
                    width: 175,
                    marginTop: 10,
                    flex: 1
                  }}
                  onClick={() => {
                    rewards.push({
                      id: rewards.length,
                      actionType: '',
                      rewardType: '',
                      value: 0
                    });
                    this.setState({ rewards: rewards });
                  }}
                  disabled={this.isActionsCovered()}
                >
                  <AddIcon />
                  <span style={{ paddingTop: 3 }}>Add Actions</span>
                </Button>
                <div style={{ alignItems: 'center', marginTop: 20 }}>
                  <span>
                    Would you like to set custom message template for
                    engagement?
                  </span>
                  <CustomCheckbox
                    onChange={event =>
                      this.setState({
                        hasCustomMessage: event.target.checked,
                        customMessageTemplate: { title: '', subtitle: '' }
                      })
                    }
                  />
                  {hasCustomMessage && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.4rem'
                      }}
                    >
                      <span style={{ marginBottom: '1rem', fontWeight: '600' }}>
                        Enter a message for the user:
                      </span>
                      {_.keys(customMessageTemplate).map(field => (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                          key={field}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'flex-end'
                            }}
                          >
                            <span style={{ minWidth: '30%' }}>
                              Enter {field} for the message:
                            </span>
                            <TextField
                              multiline
                              value={customMessageTemplate[field]}
                              name={field}
                              onChange={event => this.handleChange(event)}
                              style={{ width: '50%', marginLeft: '5px' }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </>
            )}
            <div>
              {activeScreen < 1 && (
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 7 }} />
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      !selectedPage || (activeStep < 3 && !selectedPost)
                    }
                    style={{
                      borderWidth: 2,
                      height: 40,
                      width: 150,
                      marginTop: 50,
                      marginBottom: 50,
                      flex: 1
                    }}
                    onClick={() => {
                      this.setState({
                        activeScreen: activeScreen + 1,
                        activeStep: activeStep + 1,
                        rewards:
                          rewards.length > 0
                            ? rewards
                            : [
                                {
                                  id: 0,
                                  actionType: '',
                                  rewardType: '',
                                  value: 0
                                }
                              ]
                      });
                    }}
                  >
                    <span className="nextButtonText">NEXT</span>
                    <ArrowForwardIosIcon style={{ color: '#FFFFFF' }} />
                  </Button>
                </div>
              )}
              <div
                style={{
                  display: 'flex'
                }}
              >
                {activeScreen !== 0 ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      borderWidth: 2,
                      height: 40,
                      width: 150,
                      marginTop: 50,
                      marginBottom: 50,
                      flex: 1
                    }}
                    onClick={() =>
                      this.setState({
                        activeScreen: activeScreen - 1,
                        activeStep: activeStep - 1
                      })
                    }
                  >
                    <ArrowBackIosIcon style={{ color: primaryColor }} />
                    <span className="previousButtonText">PREV</span>
                  </Button>
                ) : (
                  <div style={{ flex: 1 }} />
                )}
                {activeScreen === 1 && (
                  <>
                    <div style={{ flex: 5 }} />
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        borderWidth: 2,
                        height: 40,
                        width: 150,
                        marginTop: 50,
                        marginBottom: 50,
                        flex: 1
                      }}
                      onClick={() => this.saveRewardDefinition()}
                    >
                      <span className="nextButtonText">SAVE</span>
                    </Button>
                  </>
                )}
              </div>
            </div>
            <SnackbarComponent
              handleClose={() => this.setState({ errors: null })}
              message={_.get(errors, 'message')}
              open={!_.isEmpty(errors)}
              severity={_.get(errors, 'type')}
              style={{
                position: 'absolute'
              }}
            />
          </div>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(SocialMediaRewardDefinition);
