/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as SRD from '@projectstorm/react-diagrams';
import { RightAngleLinkFactory } from '@projectstorm/react-diagrams';
import { TriggerNodeFactory } from '../TriggerNode/TriggerNodeFactory';
import { TimedNodeFactory } from '../TimedNode/TimedNodeFactory';
import { DecisionNodeFactory } from '../DecisionNode/DecisionNodeFactory';
import { ActionNodeFactory } from '../ActionNode/ActionNodeFactory';
import { StopNodeFactory } from '../StopNode/StopNodeFactory';
import { WaitNodeFactory } from '../WaitNode/WaitNodeFactory';
import {
  AdvancedLinkFactory,
  SimplePortFactory
} from '../Links/AdvancedLinkFactory';
export class Application {
  activeModel;
  diagramEngine;

  constructor() {
    this.diagramEngine = SRD.default({
      registerDefaultDeleteItemsAction: false,
      registerDefaultZoomCanvasAction: false,
      allowlooselinks: false
    });
    this.diagramEngine
      .getNodeFactories()
      .registerFactory(new TriggerNodeFactory());
    this.newModel();
    this.diagramEngine
      .getNodeFactories()
      .registerFactory(new TimedNodeFactory());
    this.newModel();
    this.diagramEngine
      .getNodeFactories()
      .registerFactory(new DecisionNodeFactory());
    this.newModel();
    this.diagramEngine
      .getNodeFactories()
      .registerFactory(new ActionNodeFactory());
    this.newModel();
    this.diagramEngine
      .getNodeFactories()
      .registerFactory(new StopNodeFactory());
    this.newModel();
    this.diagramEngine
      .getNodeFactories()
      .registerFactory(new WaitNodeFactory());
    this.newModel();
    this.diagramEngine
      .getLinkFactories()
      .registerFactory(new AdvancedLinkFactory());
    this.diagramEngine
      .getLinkFactories()
      .registerFactory(new RightAngleLinkFactory());
    this.diagramEngine
      .getPortFactories()
      .registerFactory(new SimplePortFactory());
  }

  newModel() {
    this.activeModel = new SRD.DiagramModel();
    this.diagramEngine.setModel(this.activeModel);
  }

  getActiveDiagram() {
    return this.activeModel;
  }

  getDiagramEngine() {
    return this.diagramEngine;
  }
}
