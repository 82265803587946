class PortModel {
  constructor(type, name) {
    this.type = type;
    this.name = name;
  }
}

export const PortTypes = {
  IN_PORT: 'in',
  OUT_PORT: 'out'
};

export class InPort extends PortModel {
  constructor() {
    super(PortTypes.IN_PORT, PortTypes.IN_PORT);
  }
}

export class OutPort extends PortModel {
  constructor() {
    super(PortTypes.OUT_PORT, PortTypes.OUT_PORT);
  }
}
