import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop';
import _ from 'lodash';
import 'react-image-crop/dist/ReactCrop.css';
import './CustomImage.scss';
import { ImageTagger } from './ImageTagger';
import { EditorEventHandler } from './EditorEventHandler';
import { CircularProgress } from '@material-ui/core';
import { TaggerImagePanelContext } from '../../Hooks/TaggerImagePanelContext';
import {
  FlipbookLayoutTile,
  TileType
} from '../../../../models/FlipbookV2/FlipbookV2LayoutTile.model';
import {
  ImageType,
  TileDataImage
} from '../../../../models/FlipbookV2/TileData/TileDataImage.model';
import get from 'lodash/get';
import FlipbookLayoutTileServiceV2 from '../../../../services/Flipbook/flipbookV2LayoutTile.service';
import { FlipbookPagesContext } from '../../Hooks/FlipbookPagesContext';

interface EditComponentProps {
  flipbookLayoutTile: FlipbookLayoutTile;
  editPanelOccupiedKey: string;
  setEditPanelOccupiedKey: (state: string) => void;
}
const CROP: any = {
  unit: '%',
  x: 0,
  y: 0,
  height: 0,
  width: 0,
  name: '',
  actionItem: {},
  actionState: ''
};
export const EditComponent: FC<EditComponentProps> = ({
  flipbookLayoutTile,
  editPanelOccupiedKey,
  setEditPanelOccupiedKey
}) => {
  // const key = `${_.get(group, 'group.groupId', -1)}_${layoutTile}`;
  const [isLoading, setIsLoading] = useState(true);
  const [key, setKey] = useState('');
  const [image, setImage] = useState('');
  const [crop, setCrop] = useState(CROP);
  const [cropMetaData, setMetaData] = useState({});
  const [mapArea, setMapArea] = useState<any[]>([]);
  const [isCropDisabled, setIsCropDisabled] = useState(true);
  const [isEditDisabled, setIsEditDisabled] = useState(true);
  const [isKeepSelectionEnabled, setIsKeepSelectionEnabled] = useState(true);
  const [existingSelectionArea, setExistingSelectionArea] = useState({});
  const getImageFromTile = useCallback(() => {
    return _.get(flipbookLayoutTile, `tileData.imageData.URL`);
  }, [flipbookLayoutTile]);

  const flipbookPagesContext = useContext(FlipbookPagesContext);

  const getMapAreaFromTile = useCallback(() => {
    return _.get(flipbookLayoutTile, `tileData.imageData.mapArea`, []);
  }, [flipbookLayoutTile]);

  useEffect(() => {
    if (!_.isEmpty(flipbookLayoutTile) && _.isEmpty(image)) {
      const newImage = getImageFromTile();
      let newMapArea = getMapAreaFromTile();
      newMapArea = newMapArea || [];
      setMapArea(newMapArea);
      setImage(newImage);
      setIsLoading(false);
    }
  }, [image, flipbookLayoutTile, getImageFromTile, getMapAreaFromTile]);

  const onCropChange = (crop: any, percentCrop: any) => {
    const currentKey = `${_.get(flipbookLayoutTile, 'layoutId', -1)}_${get(
      flipbookLayoutTile,
      'tileType',
      TileType.TileOne
    )}`;
    setKey(currentKey);
    if (_.isNaN(percentCrop.width) && _.isNaN(percentCrop.height)) {
      return;
    }
    if (isEditPanelOccupied()) {
      return;
    }
    setEditPanelOccupiedKey(key);
    setCrop(percentCrop);
  };
  const onDeleteCrop = () => {
    setIsCropDisabled(true);
    setIsEditDisabled(true);
    updateGroup(mapArea);
    setEditPanelOccupiedKey('');
    setCrop(CROP);
  };
  const onCancelCrop = () => {
    setCrop(CROP);
    setMapArea(mapArea.concat(existingSelectionArea));
    setIsCropDisabled(true);
    setIsEditDisabled(true);
    setEditPanelOccupiedKey('');
    updateGroup(mapArea.concat(existingSelectionArea));
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const updateGroup = (mapAreaNew: any) => {
    const URL = get(flipbookLayoutTile, 'tileData.imageData.URL', '');
    const tileDataImage = new TileDataImage(ImageType.TAGGER, {
      URL: URL,
      mapArea: mapAreaNew
    });
    flipbookLayoutTile.tileData = tileDataImage;
    FlipbookLayoutTileServiceV2.updateFlipbookLayoutTile(
      flipbookLayoutTile
    ).then(r => {
      flipbookPagesContext.updateFlipbookPages();
    });
  };
  const addSubArea = (
    data: any,
    taggerImagePanelInfo: any,
    setTaggerImagePanelInfo: any
  ) => {
    let newArea = {},
      mapAreaNew: any = [];
    const { x, y, width, height, name, actionItem, actionState } = data;
    newArea = {
      width: `${width}%`,
      height: `${height}%`,
      left: `${x}%`,
      top: `${y}%`,
      name: name,
      actionItem: actionItem,
      actionState: actionState
    };
    mapAreaNew = _.filter([...mapArea, newArea], v => !_.isEmpty(v));
    setMapArea(mapAreaNew);
    updateGroup(mapAreaNew);
    setCrop(CROP);
    setTaggerImagePanelInfo({ ...taggerImagePanelInfo, key: '' });
    setEditPanelOccupiedKey('');
  };
  const getValueFromPercentageString = (value: string) => {
    return Number(value.slice(0, -1));
  };
  const onCompleteCrop = (
    taggerImagePanelInfo: any,
    setTaggerImagePanelInfo: any
  ) => {
    if (_.isEqual(crop, CROP)) {
      return;
    }
    if (isEditPanelOccupied()) {
      return;
    }
    crop.name = `${key}_${new Date().getTime()}`;
    setEditPanelOccupiedKey(key);
    setTaggerImagePanelInfo({
      ...taggerImagePanelInfo,
      message: { ...crop, ...cropMetaData },
      key
    });
  };
  const isCropOccupied = () => {
    return crop.width !== 0 && crop.height !== 0;
  };
  const isEditPanelOccupied = () => {
    if (_.isEmpty(editPanelOccupiedKey)) {
      return !_.isEmpty(editPanelOccupiedKey);
    } else {
      return !_.isEqual(editPanelOccupiedKey, key);
    }
  };
  const editSubArea = (
    index: number,
    taggerImagePanelInfo: any,
    setTaggerImagePanelInfo: any
  ) => {
    if (isEditDisabled || isEditPanelOccupied() || isCropOccupied()) {
      return;
    }
    setEditPanelOccupiedKey(key);
    setKey(key);
    const newArea = mapArea[index];
    setExistingSelectionArea(newArea);
    let newCrop = crop;
    newCrop = {
      unit: newCrop.unit,
      width: getValueFromPercentageString(newArea.width),
      height: getValueFromPercentageString(newArea.height),
      x: getValueFromPercentageString(newArea.left),
      y: getValueFromPercentageString(newArea.top),
      name: newArea.name,
      actionItem: newArea.actionItem,
      actionState: _.get(newArea, 'actionState')
    };
    const actionMetaData = {
      name: newArea.name,
      actionItem: newArea.actionItem,
      actionState: _.get(newArea, 'actionState')
    };
    mapArea.splice(index, 1);
    setIsCropDisabled(false);
    setMetaData(actionMetaData);
    setCrop(newCrop);
    setTaggerImagePanelInfo({
      ...taggerImagePanelInfo,
      message: newCrop,
      key: key
    });
  };
  const MapAdditionAddOn = () => {
    return <div className={'flipbook-map-add-on'}></div>;
  };
  if (isLoading) {
    return (
      <div className={'flipbook-loader'}>
        <CircularProgress
          color="inherit"
          variant="indeterminate"
          size={60}
          thickness={5}
        />
      </div>
    );
  }
  return (
    <TaggerImagePanelContext.Consumer>
      {({ taggerImagePanelInfo, setTaggerImagePanelInfo }) => {
        return (
          <EditorEventHandler
            isEditDisabled={isEditDisabled}
            setIsEditDisabled={setIsEditDisabled}
            isCropDisabled={isCropDisabled}
            setIsCropDisabled={setIsCropDisabled}
            setIsKeepSelectionEnabled={setIsKeepSelectionEnabled}
            addSubArea={selectionState =>
              addSubArea(
                selectionState,
                taggerImagePanelInfo,
                setTaggerImagePanelInfo
              )
            }
            onCancelCrop={onCancelCrop}
            onDeleteCrop={onDeleteCrop}
            customKey={key}
            setTaggerImagePanelInfo={setTaggerImagePanelInfo}
            taggerImagePanelInfo={{ ...taggerImagePanelInfo }}
          >
            <div className={'flipbook-map-box-edit-image'}>
              <ReactCrop
                src={image}
                crop={crop}
                ruleOfThirds
                onChange={onCropChange}
                keepSelection={isKeepSelectionEnabled}
                disabled={isCropDisabled}
                onComplete={() =>
                  onCompleteCrop(taggerImagePanelInfo, setTaggerImagePanelInfo)
                }
                renderSelectionAddon={MapAdditionAddOn}
              />
              <ImageTagger
                mapArea={mapArea}
                screenType={'Edit'}
                onClickArea={(area, index) => {
                  editSubArea(
                    index,
                    taggerImagePanelInfo,
                    setTaggerImagePanelInfo
                  );
                }}
              />
            </div>
          </EditorEventHandler>
        );
      }}
    </TaggerImagePanelContext.Consumer>
  );
};
