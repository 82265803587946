import React from 'react';
import { formatDateFromTimestamp } from '../../utils';

const emptyStyle = {};

interface DateTimeFormatProps {
  date: Date;
  format?: string;
  className?: string;
  style?: { [key: string]: string };
}

export const DateTimeFormat: React.FC<DateTimeFormatProps> = ({
  date,
  format,
  className = 'DD MMM YYYY, LT',
  style = emptyStyle
}) => {
  return (
    <span className={className} style={style}>
      {formatDateFromTimestamp(date, format)}
    </span>
  );
};

export const DateFormat: React.FC<DateTimeFormatProps> = ({
  date,
  format = 'DD MMM YYYY',
  className = '',
  style = emptyStyle
}) => {
  return (
    <DateTimeFormat
      date={date}
      format={format}
      className={className}
      style={style}
    />
  );
};

export const TimeFormat: React.FC<DateTimeFormatProps> = ({
  date,
  format = ' LT',
  className = '',
  style = emptyStyle
}) => {
  return (
    <DateTimeFormat
      date={date}
      format={format}
      className={className}
      style={style}
    />
  );
};

interface CommonDateTimeFormatProps {
  date: Date;
}

export const CommonDateTimeFormat: React.FC<CommonDateTimeFormatProps> = ({
  date
}) => {
  return (
    <>
      <DateFormat date={date} />
      <TimeFormat date={date} />
    </>
  );
};
