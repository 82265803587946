import React, { Context } from 'react';
import _ from 'lodash';

type UserContextType = {
  userInfo: {};
  setUserInfo: (business: any) => void;
};
export const UserContext: Context<UserContextType> = React.createContext<
  UserContextType
>({
  userInfo: {},
  setUserInfo: _.noop
});
