/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import OfferTabs from '../OfferDefinition/OfferTabs';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import { Offer } from '../../models/offer/Offer.model';
import PropTypes from 'prop-types';
import { ReadOnlyContext } from './ReadOnlyContext';
import offerService from '../../services/offer.service';
import _ from 'lodash';
import OfferUtils from '../../utils/OfferUtils';

const useStyles = makeStyles(theme => ({
  required: {
    '&:after': {
      content: `' *'`,
      color: theme.palette.primary.main
    }
  },
  stageDesc: {
    textAlign: 'center',
    font: '12px/16px Nunito',
    letterSpacing: '0',
    color: '#707070',
    opacity: 1,
    margin: 0
  },
  offerDescriptionSection: {
    marginTop: '10px',
    background: '#f5f5f5 0 0 no-repeat padding-box',
    borderRadius: '8px',
    opacity: 1
  },
  bottomSectionHeaders: {
    textAlign: 'left',
    font: 'Bold 16px Nunito',
    letterSpacing: '0',
    color: '#404040',
    opacity: 1,
    paddingTop: '5px',
    paddingLeft: '10px'
  },
  bottomSectionDesc: {
    textAlign: 'left',
    font: '13px/18px Nunito',
    letterSpacing: 0,
    color: '#767676',
    opacity: 1,
    margin: '10px 10px 10px 30px'
  },
  fieldLabel: {
    fontSize: '12px Nunito',
    fontWeight: 'bold',
    marginBottom: 5
  },
  mainButton: {
    height: 40,
    width: 150
  }
}));

export const OfferDefinitionStage = props => {
  const {
    offer,
    changeOfferField,
    onClickPrevious,
    onClickSave,
    onClickSaveDraft,
    rows
  } = props;
  const { code, name, description, offerDefinition, isDefined, status } = offer;
  const { readOnly } = useContext(ReadOnlyContext);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const classes = useStyles();
  const copyRows = [...rows];
  // const [offerValidity, setOfferValidity] = useState(validity);
  // const [offerValidityCheckbox, setOfferValidityCheckbox] = useState(true);
  const triggerSaveOption = async () => {
    onClickSave();
  };

  const triggerDraftOption = async () => {
    onClickSaveDraft();
  };

  useEffect(() => {
    if (!isDefined) {
      setIsSaveDisabled(true);
      return;
    }
    if (status === 1) {
      setIsSaveDisabled(false);
      return;
    }
    offerService.getOfferExists(offer.code).then(exists => {
      setIsSaveDisabled(exists);
    });
  }, [isDefined, status, code]);

  useEffect(() => {
    const lastBillData =
      copyRows.length > 1 && copyRows.splice(0, copyRows.length - 1);
    const checkdata = _.some(lastBillData, {
      to: ''
    });
    checkdata
      ? setIsSaveDisabled(true)
      : isDefined &&
        status === 0 &&
        offerService.getOfferExists(offer.code).then(exists => {
          exists ? setIsSaveDisabled(exists) : setIsSaveDisabled(false);
        });
    return;
  }, [copyRows, copyRows.length, isDefined]);

  return (
    <div>
      <p className={classes.stageDesc}>
        Define your offer by setting eligibility and discountcurrentDate,
        selectedDates
      </p>
      <div className={classes.offerDescriptionSection}>
        <div style={{ padding: 20, marginTop: 30 }}>
          <span className="offerName">
            {code}
            {name && ` - ${name}`}
          </span>
          <div className="offerDescription">{description}</div>
        </div>
      </div>
      <div style={{ marginTop: 30 }}>
        <OfferTabs
          definition={offerDefinition}
          setDefinition={value => changeOfferField('offerDefinition', value)}
          data={{
            discountOn: offer.discountOn,
            eligibility: OfferUtils.getOfferType(offer),
            loyaltyVendorInfo: offer.loyaltyVendorInfo,
            changeHandler: changeOfferField
          }}
        />
      </div>

      <div
        style={{
          marginTop: 50,
          display: 'flex',
          justifyContent: 'center',
          gap: 30
        }}
      >
        <Button
          className={classes.mainButton}
          variant={'outlined'}
          color={'primary'}
          onClick={onClickPrevious}
        >
          Previous
        </Button>

        {!readOnly && (
          <Button
            className={classes.mainButton}
            variant={'contained'}
            color={'primary'}
            onClick={triggerDraftOption}
            disabled={isSaveDisabled}
          >
            Draft
          </Button>
        )}

        <Button
          className={classes.mainButton}
          variant={'contained'}
          color={'primary'}
          onClick={triggerSaveOption}
          disabled={isSaveDisabled}
        >
          Publish
        </Button>
      </div>
    </div>
  );
};

OfferDefinitionStage.propTypes = {
  offer: PropTypes.instanceOf(Offer).isRequired,
  changeOfferField: PropTypes.func.isRequired,
  onClickPrevious: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired
};
