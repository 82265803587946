import * as yup from 'yup';

export const createCustomerGroupToSchema = () => {
  const messages = {
    name: ['Customer Group is Required']
  };

  return yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(messages.name[0])
  });
};
