/* eslint-disable @typescript-eslint/explicit-function-return-type */
import '../LoyaltyConfig/index.scss';

import { useFormik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  formGroupRedemption,
  redemptionInitialState
} from '../../constants/adhocPoints';
import { loyaltyList } from '../../constants/loyaltyConfig';
import useCurrentUser from '../../hooks/useCurrentUser';
import { Business } from '../../models/User.model';
import ButtonField from '../../reusable/ButtonField';
import { TextFieldComponent } from '../../reusable/TextFieldComponent';
import ConfigService from '../../services/config.service';
import { toastMessageAction } from '../../store/action';
import { ParentLink } from '../../utils/ParentLink';
import { redemptionOtpToSchema } from '../../validation/redemptionOtpToSchema';
import OTPImage from '../Assets/loylatyRedemption.png';
import SettingsBreadCrumbs from '../SettingsBreadCrumbs/SettingsBreadCrumbs';

const LoyaltyRedeemConfiguration = () => {
  const {
    permitRedeemption,
    minimumRedeemptionLimit,
    maximumRedeemptionLimit,
    submitButton,
    cancelButton
  } = formGroupRedemption;
  const [business, setBusiness] = useState(new Business());
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const [loyaltyData, setLoyaltyData] = useState({});
  //handle submit
  const onSubmit = data => {
    const workData = {
      ...loyaltyData,
      permitRedeemption: data.permitRedeemption,
      minimumRedeemptionLimit: data.minimumRedeemptionLimit,
      maximumRedeemptionLimit: data.maximumRedeemptionLimit
    };
    ConfigService.redemptionConfig(workData, 'loyalty', business?.id).then(
      () => {
        dispatch(
          toastMessageAction({
            severity: 'success',
            message: `Redemption Add successfully !`,
            messageStatus: true
          })
        );
      }
    );
  };

  //to handle submit formik
  const formik = useFormik({
    initialValues: redemptionInitialState,
    validationSchema: redemptionOtpToSchema,
    onSubmit
  });
  const {
    values,
    errors,
    handleChange,
    touched,
    resetForm,
    setValues
  } = formik;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsBuId = _.parseInt(urlParams.get('bu'));
    const paramsBusiness = _(user.getBusinesses()).find(
      b => b.id === paramsBuId
    );
    if (paramsBusiness) setBusiness(paramsBusiness);
  }, [user]);

  useEffect(() => {
    if (business.id !== 0) {
      ConfigService.getRedemptionConfig(business?.id).then(res => {
        setLoyaltyData({ ...res });
        setValues(res?.permitRedeemption >= 0 ? res : redemptionInitialState);
      });
    }
  }, [business, business.id, setValues]);

  const addUrlParams = link => {
    const urlParams = new URLSearchParams(window.location.search);
    return `${link}?bu=${urlParams.get('bu')}`;
  };

  return (
    <>
      <SettingsBreadCrumbs
        currentModuleName="Retensee Configuration"
        fromModuleName="Module Settings"
        isModule={true}
      />
      <div className={'retenseeContainer'}>
        {loyaltyList?.map((item, index) => {
          return (
            <ParentLink
              to={addUrlParams(item.path)}
              className={item.className ? item.className : 'heading'}
              key={index}
            >
              {item.title}
            </ParentLink>
          );
        })}
      </div>
      <div className={'adHocPointsContainer'}>
        <img src={OTPImage} alt="AdHocPoints" className="redemptionImage" />
        <h4 className="adHocPointsTitle">Set your Redemption Configuration</h4>
        <form onSubmit={formik.handleSubmit} className="formSubmitation">
          <TextFieldComponent
            label={permitRedeemption?.label}
            variant={permitRedeemption?.variant}
            onChange={handleChange}
            type={permitRedeemption?.type}
            name={permitRedeemption?.name}
            value={values.permitRedeemption}
            error={touched.permitRedeemption && errors.permitRedeemption}
            className={permitRedeemption?.className}
          />
          <TextFieldComponent
            label={minimumRedeemptionLimit?.label}
            variant={minimumRedeemptionLimit?.variant}
            onChange={handleChange}
            type={minimumRedeemptionLimit?.type}
            name={minimumRedeemptionLimit?.name}
            value={values.minimumRedeemptionLimit}
            error={
              touched.minimumRedeemptionLimit && errors.minimumRedeemptionLimit
            }
            className={minimumRedeemptionLimit?.className}
            placeholder={minimumRedeemptionLimit?.placeholder}
          />
          <TextFieldComponent
            label={maximumRedeemptionLimit?.label}
            variant={maximumRedeemptionLimit?.variant}
            onChange={handleChange}
            type={maximumRedeemptionLimit?.type}
            name={maximumRedeemptionLimit?.name}
            value={values.maximumRedeemptionLimit}
            error={
              touched.maximumRedeemptionLimit && errors.maximumRedeemptionLimit
            }
            className={maximumRedeemptionLimit?.className}
            placeholder={maximumRedeemptionLimit?.placeholder}
          />
          <div className={'submitButtonContainer'}>
            <ButtonField
              variant={cancelButton.variant}
              label={cancelButton.label}
              type={cancelButton.type}
              onClick={() => resetForm()}
              className={cancelButton.className}
            />

            <ButtonField
              variant={submitButton.variant}
              label={submitButton.label}
              type={submitButton.type}
              className={submitButton.className}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default LoyaltyRedeemConfiguration;
