import React from 'react';
import moment from 'moment';
import _, { range } from 'lodash';
import { Page, View, Text, Image, StyleSheet, Font } from '@react-pdf/renderer';
import RupeeIcon from '../../assets/peghook-rupee.png';
import Disclimer from '../../assets/disclimer.png';
import fonts from '../../assets/Fonts';
import { truncatedNumber, truncateString } from '../../../../utils/index';

Font.register(fonts);

const styles = StyleSheet.create({
  card: {
    height: '38mm',
    width: '73mm',
    border: '1 solid #000000',
    margin: '1mm 11mm'
  },
  inlineCard: { display: 'flex', flexDirection: 'row' },
  qrPriceBlock: {
    display: 'flex',
    flexDirection: 'row'
  },
  qrcode: {
    width: '25mm',
    height: '25mm'
  },
  priceBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '20mm'
  },
  rupeeicon: {
    fontSize: '31.8pt',
    top: -15,
    left: -11,
    width: '8px'
  },
  price: {
    fontFamily: 'OpenSans',
    fontSize: '68.8pt',
    top: 9,
    fontWeight: 'bold',
    color: 'black'
  },
  inclTax: {
    fontSize: '5.98pt',
    right: 20,
    top: 88
  },
  productName: { fontSize: '7pt', position: 'absolute', padding: '0 1mm' },
  itemCode: {
    fontSize: '5pt',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '9mm',
    paddingLeft: '1mm'
  },
  discBlock: {
    right: '1mm',
    bottom: '0mm',
    position: 'absolute'
  },
  disclimer: {
    width: '3.75mm',
    height: '3.75mm'
  }
});
const LabelView = ({ item }) => (
  <View style={styles?.card}>
    <View style={styles?.qrPriceBlock}>
      <View style={styles?.qrcode}>
        <Image src={item.image} />
      </View>
      <View style={styles?.priceBlock}>
        <Text style={styles?.rupeeicon}>
          <Image src={RupeeIcon} />
        </Text>
        <View style={{ display: 'flex', flexDirection: 'row', height: '40mm' }}>
          <View style={styles?.price}>
            <Text>{truncatedNumber(item?.product?.mop, 3)}</Text>
          </View>
          <View style={styles?.inclTax}>
            <Text>*Incl. Tax</Text>
          </View>
        </View>
      </View>
    </View>
    <View>
      <Text style={styles.productName}>
        {truncateString(item?.product?.masterData?.name, 130)}
      </Text>
      <View style={styles.itemCode}>
        <Text>
          {item?.sku} | {item.class} | A |{' '}
          {moment(item?.date).format('DD.MM.YYYY')} |{' '}
          {moment().format('DD.MM.YYYY')}
        </Text>
        <View style={styles.discBlock}>
          <Image style={styles.disclimer} src={Disclimer} />
        </View>
      </View>
    </View>
  </View>
);
const getPegHookLayout = (data, rowSets) => {
  return data?.map((labelData, i) => {
    return rowSets?.map(rowCount => (
      <View style={styles.inlineCard} key={i}>
        {rowCount.map(id => (
          <LabelView item={labelData} key={id} />
        ))}
      </View>
    ));
  });
};
const PegHook = ({ data, count, ...rest }) => {
  const labelsPerRow = 3;
  const rowsPerSku = count.length / labelsPerRow;
  const rowSets = range(rowsPerSku).map(ind =>
    count.slice(labelsPerRow * ind, labelsPerRow * (ind + 1))
  );
  const splattedArray = _.chunk(data, 10);
  return (
    <Page size="A4" orientation="landscape" style={{ padding: '5mm' }}>
      <View>
        {count &&
          _.map(splattedArray, (chunkedData, key) => {
            const chunkedRowArray = _.chunk(chunkedData, 5);
            const firstHalf = getPegHookLayout(chunkedRowArray[0], rowSets);
            const secondHalf = getPegHookLayout(chunkedRowArray[1], rowSets);
            return (
              <View
                style={{ flexDirection: 'row', flexWrap: 'wrap' }}
                key={key}
              >
                <View>{firstHalf}</View>
                <View>{secondHalf}</View>
              </View>
            );
          })}
      </View>
    </Page>
  );
};
export { PegHook };
