import React, { FC, useEffect, useState } from 'react';
import usePagination from '../../../shared/hooks/usePagination';
import { LiabilitySummaryReportTypes } from '../../../models/TenantReports/LiabilitySummaryReportDownload';
import { getLiabilityReportReportDownload } from '../../../services/TenantReports/LiabilitySummaryReport.service';
import LLSReport from './LiabilitySummaryReportFilter';
import LLSReportDownloadTable from './LiabilitySummaryReportTable';
import { get, head, isEqual } from 'lodash';
import useCurrentUser from '../../../hooks/useCurrentUser';

export interface LLSRefreshFilters {
  type: LiabilitySummaryReportTypes;
  businessUnitId: number;
}

const filtersInitialState: LLSRefreshFilters = {
  type: LiabilitySummaryReportTypes.OVERALL,
  businessUnitId: -1
};
const LiabilitySummaryReport: FC = () => {
  const [refreshView, setRefreshView] = useState<string>('');
  const [isDependencyLoading, setIsDependencyLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<LLSRefreshFilters>(
    filtersInitialState
  );
  const user = useCurrentUser();
  useEffect(() => {
    if (isEqual(filters.businessUnitId, -1) && !isEqual(user.id, 0)) {
      const business = user.getBusinesses();
      const headBusiness = head(business);
      const defaultBusinessId: number = get(headBusiness, 'id', 0);
      setFilters({
        ...filters,
        businessUnitId: defaultBusinessId
      });
      setIsDependencyLoading(false);
    }
  }, [user, filters]);

  const { data, isLoading, page, offset, setPage, setOffset } = usePagination(
    getLiabilityReportReportDownload,
    [refreshView],
    filters,
    isDependencyLoading
  );

  return (
    <div>
      <LLSReport
        setRefreshView={setRefreshView}
        refreshFilters={filters}
        setRefreshFilters={setFilters}
      />
      <LLSReportDownloadTable
        reportType={filters.type}
        reports={data || []}
        isLoading={isLoading}
        page={page}
        offset={offset}
        setPage={setPage}
        setOffset={setOffset}
      />
    </div>
  );
};

export default LiabilitySummaryReport;
