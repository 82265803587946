import { Checkbox, withStyles } from '@material-ui/core';

const checkBoxStyles = theme => ({
  root: {
    '&$checked': {
      color: '#F2755F'
    }
  },
  checked: {}
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

export default CustomCheckbox;
