import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

function handleClick(event, history) {
  event.preventDefault();
  history.push('create');
}

export default function JourneyBreadcrumbs() {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div
      style={{ border: '1px solid #E8E8E8', padding: '10px' }}
      className={classes.root}
    >
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link
          color="inherit"
          href="/journey/create"
          onClick={event => handleClick(event, history)}
        >
          Journey Builder
        </Link>
        <Typography color="textPrimary">Journey Builder List</Typography>
      </Breadcrumbs>
    </div>
  );
}
