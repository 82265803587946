import config from '../../config/index';
import moment from 'moment';
import KPIReportDownloadInterface from '../../models/TenantReports/KPIReportDownload';
import { get, lowerCase, reduce } from 'lodash';
import { KPI_REPORT_TYPE } from '../../components/TenantReports/KPIReport/KPIReportFilter';

export const getProcessingKPIReports = () => {
  return fetch(
    `${config.ruleServerUrl}/users/reports/processingRecords/kpi_report`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
};

export const getKpiReport = (filters: any, type: string) => {
  if (type === KPI_REPORT_TYPE.ANNUAL) {
    filters.reportFilterDate = `${moment(
      filters.reportFilterDate
    ).year()}-03-31`;
    filters.startDate = `${moment(filters.reportFilterDate)
      .subtract(4, 'year')
      .add(1, 'day')
      .format('YYYY-MM-DD')}`;
  } else if (type === KPI_REPORT_TYPE.DEFAULT) {
    filters.reportFilterDate = `${moment(filters.reportFilterDate)
      .endOf('month')
      .format('YYYY-MM-DD')}`;
    filters.startDate = `${moment(filters.reportFilterDate)
      .subtract(13, 'months')
      .startOf('month')
      .format('YYYY-MM-DD')}`;
  } else if (type === KPI_REPORT_TYPE.QUARTERLY) {
    filters.reportFilterDate = `${moment(filters.reportFilterDate)
      .endOf('quarter')
      .format('YYYY-MM-DD')}`;
    filters.startDate = `${moment(filters.reportFilterDate)
      .subtract(4, 'quarter')
      .startOf('quarter')
      .format('YYYY-MM-DD')}`;
  }
  type = lowerCase(type);
  let query = `${config.ruleServerUrl}/users/reports/download/kpi_report/${
    filters.businessId
  }?date=${moment(filters.reportFilterDate)
    .endOf('month')
    .format('YYYY-MM-DD')}&startDate=${moment(filters.startDate)
    .startOf('month')
    .format('YYYY-MM-DD')}&type=${type}&${reduce(
    get(filters, 'querySegment'),
    (prev, curr) => {
      return (prev += `&querySegment=${curr}`);
    },
    ''
  )}`;
  filters?.businesses?.map((b: string) => (query += `&business=${b}`));
  return fetch(query, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  }).then(r => r.json());
};

export const getKpiReportDownload = ({
  page,
  offset,
  filters
}: {
  page: number;
  offset: number;
  filters?: any;
}): Promise<KPIReportDownloadInterface[]> => {
  const columnValue = lowerCase(filters);
  return fetch(
    `${config.ruleServerUrl}/users/reports/view/downloads/kpi_report?offset=${offset}&page=${page}&columnName=queryType&columnValue=${columnValue}`,
    { credentials: 'include' }
  ).then(res => res.json());
};
