import React from 'react';
import { Dialog, InputAdornment } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  dialogTitle: {
    textAlign: 'center'
  },
  dialogHintParagraph: {
    color: '#A8A8A8',
    marginBottom: 0
  },
  dialogPaper: {
    padding: '50px 25px',
    alignItems: 'center',
    height: '100%',
    maxHeight: '500px',
    justifyContent: 'space-between'
  },
  closeButton: {
    position: 'absolute',
    top: '8px',
    right: '8px'
  }
});

export const ChangeDateDialog = props => {
  const {
    open,
    onClose,
    numSelectedOffers,
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
    onSubmit
  } = props;

  const classes = useStyles();
  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper
      }}
      fullWidth
      open={open}
      onClose={onClose}
    >
      <div className={classes.dialogTitle}>
        <h4>Change Offer Date</h4>
        <p className={classes.dialogHintParagraph}>
          Dates will be changed for the {numSelectedOffers} selected offer
          {numSelectedOffers > 1 ? 's' : ''}
        </p>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div style={{ display: 'flex', gap: '20px' }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontWeight: 'bold', marginBottom: 5 }}>
              Start Date
            </div>
            <DateTimePicker
              autoOk
              disablePast
              style={{ width: '250px' }}
              format={'MMM do yyyy hh:mm a'}
              variant={'inline'}
              value={startDate}
              onChange={onStartDateChange}
              inputVariant={'outlined'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <DateRangeOutlinedIcon color={'primary'} />
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontWeight: 'bold', marginBottom: 5 }}>End Date</div>
            <DateTimePicker
              autoOk
              disablePast
              style={{ width: '250px' }}
              format={'MMM do yyyy hh:mm a'}
              variant={'inline'}
              value={endDate}
              onChange={onEndDateChange}
              inputVariant={'outlined'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <DateRangeOutlinedIcon color={'primary'} />
                  </InputAdornment>
                )
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
      </div>
      <Button color={'primary'} variant={'contained'} onClick={onSubmit}>
        Submit
      </Button>
    </Dialog>
  );
};

ChangeDateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  numSelectedOffers: PropTypes.number.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  onStartDateChange: PropTypes.func.isRequired,
  onEndDateChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};
