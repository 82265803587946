import React from 'react';
import { Button } from '.';

const FooterActions = ({ addToPrint, loading }) => {
  return (
    <div className="text-center l-py3">
      <Button className="buttonCancel" text="Cancel" />
      <span className="l-mx2">&nbsp;</span>
      <Button
        className="buttonPrimary"
        text="Add to print"
        onClick={addToPrint}
        loading={loading}
      />
    </div>
  );
};

export { FooterActions };
