/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import _ from 'lodash';
import React from 'react';
import SectionField from '../../models/SectionField';
import utils from '../../utils/utils';

import classes from './StripedTable.module.css';

interface StrippedTableProps {
  headings: string[];
  data: any[];
  configFields: SectionField[];
  keyGetter?: (idx: number, row: any) => string;
  rowTransformer?: (field: SectionField, row: any) => string;
  children?: React.ReactNode;
}

const StripedTable: React.FC<StrippedTableProps> = ({
  headings,
  data,
  configFields,
  keyGetter = (idx, _) => (idx + 1).toString(),
  rowTransformer = (field, row) => utils.getAndTransformValue(field, row),
  children = null
}) => {
  function renderRows(idx: number, row: any) {
    return (
      <tr key={idx}>
        <td>{keyGetter(idx, row)}</td>
        {_.map(configFields, field => (
          <td>{rowTransformer(field, row)}</td>
        ))}
      </tr>
    );
  }

  function renderColumnNames(headings: string[]) {
    return (
      <tr>
        {_.map(headings, heading => (
          <th>{heading}</th>
        ))}
      </tr>
    );
  }

  return (
    <table className={classes.table}>
      <thead>{renderColumnNames(headings)}</thead>
      <tbody>
        {data.map((d: any, idx: number) => renderRows(idx, d))}
        {children}
      </tbody>
    </table>
  );
};
export default StripedTable;
