import React, { Context } from 'react';

type ReadOnlyContextType = {
  readOnly: boolean;
  cloneOffer: boolean;
};

export const ReadOnlyContext: Context<ReadOnlyContextType> = React.createContext<
  ReadOnlyContextType
>({
  readOnly: false,
  cloneOffer: false
});
