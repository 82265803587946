export const referralInitialState = {
  referredUsersPoints: '',
  referrerPoints: '',
  expireInDays: ''
};

const labels = {
  referredUsersPoints: 'Referee Adhoc Points',
  referrerPoints: 'Referrer Adhoc Points',
  expireInDays: 'Expiry of Adhoc Points'
};
export const formGroupRedemption = {
  referredUsersPoints: {
    label: labels.referredUsersPoints,
    variant: 'outlined',
    type: 'number',
    className: 'textFieldStyle',
    name: 'referredUsersPoints',
    placeholder: 'Enter Adhoc Points for Referral Person'
  },
  referrerPoints: {
    label: labels.referrerPoints,
    variant: 'outlined',
    type: 'number',
    className: 'textFieldStyle',
    name: 'referrerPoints',
    placeholder: 'Enter Adhoc Points for Referrer Person'
  },
  expireInDays: {
    label: labels.expireInDays,
    variant: 'outlined',
    type: 'number',
    className: 'textFieldStyle',
    name: 'expireInDays',
    placeholder: 'Enter the Expiry days of Adhoc Points'
  },
  submitButton: {
    variant: 'contained',
    label: 'Save',
    type: 'sumbit',
    className: 'saveButton'
  },
  cancelButton: {
    variant: 'contained',
    label: 'Clear',
    type: 'button',
    className: 'clearButton'
  }
};
