import * as yup from 'yup';

export const referralToSchema = () => {
  const messages = {
    referredUsersPoints: ['Only positive number', 'Decimal points not allowed'],
    referrerPoints: ['Only positive number', 'Decimal points not allowed'],
    expireInDays: [
      'Expire Days is Required',
      'Only positive number',
      'Decimal points not allowed'
    ]
  };

  return yup.object().shape({
    referredUsersPoints: yup
      .number()
      .min(0, messages.referredUsersPoints[0])
      .integer(messages.referredUsersPoints[1]),
    referrerPoints: yup
      .number()
      .min(0, messages.referrerPoints[0])
      .integer(messages.referredUsersPoints[1]),
    expireInDays: yup
      .number()
      .integer(messages.expireInDays[2])
      .required(messages.expireInDays[0])
      .min(1, messages.expireInDays[1])
  });
};
