import React, { FC, useCallback, useContext, useEffect } from 'react';
import _, {
  clone,
  flatten,
  head,
  isEmpty,
  isUndefined,
  map,
  without
} from 'lodash';
import { getProductDetailsById } from '../Utiles/GroupedSelectedProducts';
import defaultProductImage from '../../Assets/default_product.png';
import './Collections.css';
import {
  FlipbookLayout,
  PageType
} from '../../../models/FlipbookV2/FlipbookV2Layout.model';
import {
  FlipbookLayoutTile,
  TileDataType
} from '../../../models/FlipbookV2/FlipbookV2LayoutTile.model';
import get from 'lodash/get';
import { FlipbookCollections } from '../../../models/FlipbookV2/flipbookV2Collections.model';
import CasaProductMaster from '../../../services/productMaster.service';
import { BusinessUnitContext } from '../../OfferDefinition/BusinessUnitContext';
import { FlipbookProductsContext } from '../Hooks/FlipbookProductsContext';

interface CollectionsProps {
  flipbookPages: FlipbookLayout[];
  isEditMode: boolean;
  goToPageNumber: (number: number) => void;
  isCollectionSection: boolean;
  isCustomerView: boolean;
  flipbookIdOrToken: string;
}

export const Collections: FC<CollectionsProps> = ({
  flipbookPages,
  isEditMode,
  goToPageNumber,
  isCollectionSection = false,
  flipbookIdOrToken,
  isCustomerView
}) => {
  const [collectionData, setCollectionData] = React.useState<
    FlipbookCollections[]
  >();
  const [allProducts, setAllProducts] = React.useState<any>();
  const businessUnitContext = useContext(BusinessUnitContext);
  const businessId = businessUnitContext.business.id;
  const {
    setProducts,
    setSkus,
    flipbookPages: contextFlipbookPages
  } = useContext(FlipbookProductsContext);

  const isAnyTileHasCollection = useCallback((tiles: FlipbookLayoutTile[]) => {
    const isTileHasCollection = map(tiles, (tile: FlipbookLayoutTile) => {
      return tile.tileDataType === TileDataType.COLLECTION;
    });
    return isTileHasCollection.includes(true);
  }, []);

  const getPagesWithCollection = useCallback(() => {
    const pagesWithCollections = map(
      flipbookPages,
      (page: FlipbookLayout, key: number) => {
        if (page?.flipbookLayoutTile && page?.pageType === PageType.MAIN) {
          return isAnyTileHasCollection(page?.flipbookLayoutTile)
            ? { page: page, index: key }
            : undefined;
        }
        return undefined;
      }
    );
    return without(pagesWithCollections, undefined);
  }, [flipbookPages, isAnyTileHasCollection]);

  const getHeadProduct = useCallback((tiles: FlipbookLayoutTile[]) => {
    const firstProduct = map(tiles, (tile: FlipbookLayoutTile) => {
      if (tile.tileDataType === TileDataType.COLLECTION) {
        const arrayOfSKUs: string[] = get(tile, 'tileData.skus', []);
        return head(arrayOfSKUs);
      }
    });
    return head(firstProduct) || '';
  }, []);
  const setAllProductsFromCollection = useCallback(
    (listOfSkus: string[]) => {
      if (
        isCustomerView &&
        !isEmpty(contextFlipbookPages) &&
        businessId !== 0
      ) {
        CasaProductMaster.listProductsBySkuAndToken(
          flipbookIdOrToken,
          listOfSkus
        ).then(res => {
          setProducts((prevProducts: any) =>
            clone([...prevProducts, ...flatten([res])])
          );
          setSkus((prevSkus: any) => [...prevSkus, ...listOfSkus]);
          setAllProducts(res);
        });
      } else {
        !isUndefined(businessId) &&
          businessId !== 0 &&
          CasaProductMaster.listProductsBySku(listOfSkus, businessId).then(
            res => {
              setProducts((prevProducts: any) => [
                ...prevProducts,
                ...flatten([res])
              ]);
              setSkus((prevSkus: any) => [...prevSkus, ...listOfSkus]);
              setAllProducts(res);
            }
          );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [businessId, flipbookIdOrToken, isCustomerView, contextFlipbookPages]
  );
  const setFlipbookCollections = useCallback(() => {
    const listOfPagesWithCollections = getPagesWithCollection();
    const flipbookCollections: FlipbookCollections[] = [];
    const listOfSkus: string[] = [];
    listOfPagesWithCollections.forEach(page => {
      if (page?.page.flipbookLayoutTile) {
        const headProduct: string = getHeadProduct(
          page.page.flipbookLayoutTile
        );
        flipbookCollections.push(
          new FlipbookCollections(page.index, headProduct)
        );
        listOfSkus.push(headProduct);
      }
    });
    setAllProductsFromCollection(listOfSkus);
    setCollectionData(flipbookCollections);
  }, [getHeadProduct, getPagesWithCollection, setAllProductsFromCollection]);

  useEffect(() => {
    if (!isUndefined(collectionData)) {
      return;
    }
    setFlipbookCollections();
  }, [collectionData, setFlipbookCollections]);

  const goToSpecificIndexOnFlipbookPages = (index: number) => {
    /*
     * Index starts from 0, where 0 means 2nd Page in Flipbook.
     * Zero'th page is none, First Page is Cover
     */
    const pageNumber = index + 2;
    goToPageNumber(pageNumber);
  };
  function getIndividualItem(flipbookCollection: FlipbookCollections) {
    const currentProduct = getProductDetailsById(
      flipbookCollection.sku,
      allProducts
    );
    let currentImage: any = _.head(
      _.get(currentProduct, 'extendedData.images', '')
    );
    currentImage = _.isEmpty(currentImage) ? defaultProductImage : currentImage;
    return (
      <td
        className={`${
          isCollectionSection
            ? 'flipbook-collection-container-cover'
            : 'flipbook-collection-container-cover-page'
        }`}
      >
        <p className={'m-1 center flipbook-collection-image-title'}></p>
        <img
          className={'flipbook-collection-image-cover'}
          src={currentImage}
          alt={'Current-Product'}
          onClick={() =>
            goToSpecificIndexOnFlipbookPages(flipbookCollection.index)
          }
        />
      </td>
    );
  }

  function getCollectionItems() {
    if (!_.isEmpty(collectionData)) {
      return _.map(collectionData, product => {
        return getIndividualItem(product);
      });
    }
  }

  return <>{getCollectionItems()}</>;
};

export default Collections;
