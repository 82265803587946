import React from 'react';
import { get } from 'lodash';
import constants from '../../shared/constants';
import classes from './SectionSwitcher.module.scss';

interface Props {
  activeStep: number;
}
const SectionSwitcher: React.FC<Props> = ({ activeStep }) => {
  function renderComponents(sectionComponents: React.FC[]) {
    return (
      <>
        {sectionComponents.map((Component, index) => (
          <Component key={index} />
        ))}
      </>
    );
  }

  function renderSection() {
    let type = get(constants.defaultSections, activeStep);
    const sectionComponents = get(constants.stepperTypeComponentMap, type);
    return renderComponents(sectionComponents);
  }

  return <div className={classes.container}>{renderSection()}</div>;
};

export default SectionSwitcher;
