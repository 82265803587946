import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PageTitle, Button, SelectItem, FooterActions } from './components';
import './index.scss';
import labelService from '../../services/label.service';
import _ from 'lodash';
import casaProductMaster from '../../services/productMaster.service';
import { Business } from '../../models/User.model';
import useCurrentUser from '../../hooks/useCurrentUser';
import { getUserStores } from '../../services/store.service';
import { CircularProgress } from '@material-ui/core';
const CreateLabel = () => {
  const history = useHistory();
  const initialState = {
    2: { csv: null },
    3: { sku: { fieldValue: [], version: '' } },
    1: { sku: { fieldValue: [], version: '' } },
    4: {
      fields: {
        fieldName: 'date',
        fieldValue: null,
        version: ''
      }
    }
  };
  const [fileError, setFileError] = useState(false);
  const [isLoadingLabels, setIsLoadingLabels] = useState(false);
  const [state, setState] = useState(initialState);
  const [allProducts, setAllProducts] = React.useState([]);
  const [LabelError, setLabelError] = useState('');
  const [availableStores, setAvailableStores] = useState([]);
  const [currentStore, setCurrentStore] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const getKey = () => {
    return _.head(_.keys(initialState[state?.selectedOption?.id]));
  };
  const getValue = () => {
    return _.without(_.values(state[state?.selectedOption?.id]), null);
  };
  const isEmpty = () => {
    const selectedOption = state?.selectedOption?.id;
    return selectedOption === 2
      ? _.isEmpty(getValue())
      : _.isEqual(
          _.get(_.values(getValue()), '0.fieldValue'),
          _.values(initialState[state?.selectedOption?.id])[0].fieldValue
        );
  };
  const [businessUnitContext, setBusinessUnitContext] = useState(
    new Business()
  );
  const user = useCurrentUser();

  useEffect(() => {
    if (_.isEmpty(currentStore)) {
      const defaultStore = _.get(_.head(availableStores), 'store');
      setCurrentStore(defaultStore);
    }
  }, [currentStore, availableStores]);

  useEffect(() => {
    setBusinessUnitContext(
      _(user.getBusinesses())
        .orderBy(['id'])
        .first() || new Business()
    );
  }, [user, setBusinessUnitContext]);
  useEffect(() => {
    if (_.get(businessUnitContext, 'id') !== 0) {
      getUserStores(businessUnitContext).then(storeDetails => {
        setAvailableStores(storeDetails);
        setIsLoading(false);
      });
    }
  }, [businessUnitContext]);

  const getProductHierarchy = useCallback(() => {
    let productHierarchy = _.get(businessUnitContext, 'productHierarchy', []);
    productHierarchy = _.take(productHierarchy, productHierarchy.length);
    return _.map(productHierarchy, value => {
      return value;
    });
  }, [businessUnitContext]);
  useEffect(() => {
    casaProductMaster.listProducts(businessUnitContext).then(allProducts => {
      setAllProducts(allProducts);
    });
  }, [businessUnitContext]);
  const printLabels = (body, selectedOption) => {
    localStorage.setItem('selected-option-label', selectedOption);
    history.push({
      pathname: '/print-label',
      search: `?payload=${_.get(body, 'data.payloadId', -1)}`
    });
  };
  const addToPrint = () => {
    if (isEmpty()) {
      setFileError(true);
    } else {
      setIsLoadingLabels(true);
      labelService
        .getPrintLabels(
          getKey(),
          getValue(),
          currentStore,
          state.selectedOption.name
        )
        .then(response => {
          setIsLoadingLabels(false);
          if (_.isEqual(response.status, 'failure')) {
            setLabelError(response.errors[0].message);
            return;
          }
          printLabels(response, state?.selectedOption?.label);
        });
    }
  };

  const updateState = (key, value) => {
    setState({ ...state, [key]: value });
    setFileError(false);
  };
  const updateSelection = (key, value) => {
    setState({ ...initialState, [key]: value });
  };

  const getCreateLabelContent = () => {
    return (
      <>
        <PageTitle
          title="CREATE LABEL"
          withStore={true}
          setCurrentStore={value => setCurrentStore(value)}
          storeDetails={availableStores}
          currentStore={currentStore}
        />
        <SelectItem
          selectedOption={state?.selectedOption}
          setSelectedOption={updateSelection}
          state={state}
          updateState={updateState}
          fileError={fileError}
          allProducts={allProducts}
          productsHierarchy={getProductHierarchy()}
        />
        <Button />
        <div className="text-center">
          {fileError ? (
            state?.selectedOption?.id ? (
              <span className="error-text">{state?.selectedOption?.error}</span>
            ) : (
              <span className="error-text">Please choose one of option.</span>
            )
          ) : LabelError ? (
            <span className="error-text">*{LabelError}</span>
          ) : (
            ''
          )}
        </div>
        <FooterActions addToPrint={addToPrint} loading={isLoadingLabels} />
      </>
    );
  };
  const getLoader = () => {
    return (
      <div
        style={{
          color: '#F2755F',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress color="inherit" />
      </div>
    );
  };
  return <main>{isLoading ? getLoader() : getCreateLabelContent()}</main>;
};
export default CreateLabel;
