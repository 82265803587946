/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { PortWidget } from '@projectstorm/react-diagrams';
import _ from 'lodash';
import styleClass from './BaseNodeWidget.module.css';
import { Node } from '../components/Node/Node';
import { SuggestionsTray } from '../components/SuggestionsTray/SuggestionsTray';
import { getTemplatesByCategoryId } from '../../../services/templateConfigs.service';
import NodeTypes from '../BaseNodeModel/NodeTypes';
import * as TrayIcons from '../Icons/svg/TrayIcons';
import AddNode from '../Icons/svg/AddNode';
import CloseAddNode from '../Icons/svg/CloseAddNode';
import DecisionsTray from '../components/DecisionsTray/DecisionsTray';
import RuleTemplatesContext from '../context/ruleTemplatesContext';
import { UserTracking } from '../components/UserTracking/UserTracking';

const BaseNodeWidget = props => {
  const { node, setInputFields } = props;
  const [inPorts, outPorts] = props.getPorts(node.ports);
  const [userCount, setUserCount] = useState(null);
  const { setIsWaitingForAction, setShowPopUp, reports, isLocked } = useContext(
    RuleTemplatesContext
  );
  const isLinked = () => {
    return _.isEmpty(node?.ports?.out?.links);
  };
  const getUserCount = () => {
    if (reports?.nodeCrossCount) {
      return reports.nodeCrossCount[node.nodeId];
    }
    return null;
  };

  useEffect(() => {
    setUserCount(getUserCount());
  }, [reports, node.nodeId]);

  const showDetails = async () => {
    if (node.type === NodeTypes.DECISION_NODE) {
      const clonedNode = _.clone(node);
      const actionNodes = clonedNode.getParentActionNodes();
      const responseIds = _.flatMap(
        actionNodes,
        actionNode => actionNode.responses
      );
      const responses = {};
      for await (const option of responseIds) {
        await getTemplatesByCategoryId(option).then(response => {
          responses[option.split('_').join(' ')] = response;
        });
      }
      props.showDetails(node, setInputFields, responses);
    } else {
      props.showDetails(
        node,
        setInputFields,
        await getTemplatesByCategoryId(node.templateCategoryId)
      );
    }

    setShowPopUp(true);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    showDetails();
  }, []);

  const [showSuggestion, setShowSuggestion] = useState(
    node.nextNodes.length === 1
  );
  return (
    <div className={styleClass.container}>
      {_.map(inPorts, p => {
        return (
          <PortWidget
            className={styleClass.inPort}
            key={p.options.name}
            engine={props.engine}
            port={p}
          ></PortWidget>
        );
      })}
      {!isLocked ? (
        <div
          className={styleClass.remove}
          onClick={e => {
            props.removeNode(e, node);
          }}
        >
          X
        </div>
      ) : (
        <></>
      )}
      <Node
        isBorder={true}
        isDetailsSaved={node.isDetailsSaved}
        selected={node.isSelected() ? true : false}
        onClick={() => {
          node.showDetails = true;
          showDetails();
        }}
        name={node.name}
        IconComponent={TrayIcons[node.icon]}
      />
      {node.type === NodeTypes.DECISION_NODE ? (
        <DecisionsTray
          templateName={node.templateName}
          count={getUserCount()}
        />
      ) : (
        <UserTracking count={userCount} />
      )}
      {_.map(outPorts, p => {
        return (
          <PortWidget
            className={styleClass.outPort}
            key={p.options.name}
            engine={props.engine}
            port={p}
          ></PortWidget>
        );
      })}
      {isLinked() && !_.isEmpty(node.nextNodes) && (
        <>
          {!showSuggestion && (
            <AddNode
              className={styleClass.addNode}
              onClick={() => {
                setShowSuggestion(true);
              }}
            />
          )}
          {showSuggestion && (
            <div>
              <CloseAddNode
                className={styleClass.closeAddNode}
                onClick={() => {
                  setIsWaitingForAction(false);
                  setShowSuggestion(false);
                }}
              />
              <SuggestionsTray currentNode={node} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BaseNodeWidget;
