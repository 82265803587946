import React from 'react';
import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import RupeeFit from '../../assets/rupeeFit.png';
import Warranty from './warranty';
import { GetBarCode } from '../utils';
import Disclimer from '../../assets/disclimer.png';
import moment from 'moment';
import {
  getSplitDescription,
  truncatedNumber,
  truncateString
} from '../../../../utils/index';
import _ from 'lodash';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'row'
  },
  card: {
    fontFamily: 'OpenSans',
    margin: '3px',
    width: '145.3mm',
    height: '206.2mm',
    border: '1 solid #000000'
  },
  qrSection: {
    height: 178.5,
    borderBottom: '1 solid #000000',
    display: 'flex',
    flexDirection: 'row'
  },
  descriptionSection: {
    height: 134.48,
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid #000000'
  },
  amountSection: {
    height: 128.44,
    borderBottom: '1 solid #000000',
    width: '100%'
  },
  qrcode: {
    width: '59mm',
    height: '59mm',
    margin: '1mm 2mm'
  },
  about: {
    // borderLeft: '1 solid #000000',
    fontSize: '14pt',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 241.24,
    wordWrap: 'break-word',
    textAlign: 'justify',
    paddingRight: '9mm'
  },
  pricewithRupeeIcon: {
    display: 'flex',
    flexDirection: 'row'
    // justifyContent:'center'
    // width: '200mm'
  },
  rupeeicon: {
    fontSize: '17pt',
    width: '15mm',
    paddingTop: '8mm',
    paddingLeft: '5mm'
  },
  mopSection: {
    height: '60mm',
    display: 'flex',
    flexDirection: 'row'
  },
  price: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold',
    fontSize: '93pt',
    color: 'black',
    top: -8
  },
  inclTax: {
    fontSize: '8pt',
    top: 95,
    right: 30
  },
  rupeeSmall: {
    width: '5mm',
    height: '5mm'
  },

  //footer

  footer: {
    padding: '1mm '
  },
  warrantyText: {
    fontSize: '8pt',
    color: '#1A1818',
    opacity: '1'
  },
  dateDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    top: 9
  },
  itemCode: {
    top: -18,
    paddingTop: '10mm',
    fontSize: '8pt',
    opacity: '1',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  barcodeBlock: {
    marginRight: '2mm',
    justifyContent: 'center'
  },
  barcode: {
    top: -12,
    width: '40mm',
    padding: '1mm 1mm 0mm 0mm'
  },
  lineHeight: { paddingLeft: '4mm', height: '10mm' },
  disclimer: {
    width: '3.75mm',
    height: '3.75mm',
    left: 20
  }
});

const A5Landscape = ({ title = '', data, count, ...rest }) => {
  return (
    <Page style={styles.page} orientation="landscape">
      {count &&
        count.map(id => (
          <View
            key={id}
            style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
          >
            {data?.map(item => (
              <View style={styles.card} key={item?.id}>
                <View style={styles.qrSection}>
                  <View style={styles?.qrcode}>
                    <Image src={item.image} />
                  </View>
                  <View style={styles.about}>
                    <Text>
                      {truncateString(item?.product?.masterData?.name, 130)}
                    </Text>
                    {/*<Text>
                      {truncateString(
                        item?.description ||
                          item?.product?.masterData?.description ||
                          '',
                        130
                      )}
                    </Text>*/}
                  </View>
                </View>
                <View style={styles.descriptionSection}>
                  <View
                    style={{
                      width: '65%',
                      fontSize: '13pt',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      paddingLeft: '3mm'
                    }}
                  >
                    {/* placeholder content here */}
                    <View
                      style={{
                        paddingTop: '2mm',
                        fontSize: '10pt',
                        lineHeight: 2
                      }}
                    >
                      {getSplitDescription(item.description, 6).map(
                        (line, index) => (
                          <Text key={index}>+ {line}</Text>
                        )
                      )}
                    </View>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                      margin: '12px 20px'
                    }}
                  >
                    {truncatedNumber(item?.product?.mrp, 6) > 0 ? (
                      <Text style={{ display: 'flex' }}>
                        <Text style={{ fontWeight: 'bold' }}>MRP: </Text>
                        <Image style={styles?.rupeeSmall} src={RupeeFit} />
                        <Text>{truncatedNumber(item?.product?.mrp, 6)}</Text>
                      </Text>
                    ) : (
                      <Text />
                    )}
                  </View>
                </View>
                <View style={styles.amountSection}>
                  <View style={styles.pricewithRupeeIcon}>
                    <View style={styles.rupeeicon}>
                      <Image src={RupeeFit} />
                    </View>
                    <View style={styles.mopSection}>
                      <Text style={styles.price}>
                        {truncatedNumber(item?.product?.mop, 6)}
                      </Text>
                      <Text style={styles.inclTax}>*Incl. Tax</Text>
                    </View>
                  </View>
                </View>
                <View style={styles?.footer}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '30mm'
                    }}
                  >
                    <Warranty warranty={item.warranty} styles={styles} />
                  </View>
                  <View
                    style={
                      item?.warranty
                        ? styles?.dateDetails
                        : { ...styles?.dateDetails, top: 110 }
                    }
                  >
                    <View style={styles.itemCode}>
                      <Text style={{ paddingLeft: '8px' }}>
                        {`${item?.sku} | ${item.class} | A | ${moment(
                          item?.date
                        ).format('DD.MM.YYYY')} | ${moment().format(
                          'DD.MM.YYYY'
                        )}`}
                      </Text>
                      <View style={{ left: -15 }}>
                        <Image style={styles.disclimer} src={Disclimer} />
                      </View>
                    </View>
                    <View style={styles?.barcodeBlock}>
                      <View style={styles.barcode}>
                        {_.isEmpty(item.barcode) ? (
                          <></>
                        ) : (
                          <Image src={GetBarCode(item?.barcode)} />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            ))}
          </View>
        ))}
    </Page>
  );
};
export { A5Landscape };
