/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { BaseNodeModel } from '../BaseNodeModel/BaseNodeModel';
import NodeTypes from '../BaseNodeModel/NodeTypes';
import * as PortTypes from '../BaseNodeModel/PortModels';
import _ from 'lodash';

export class TriggerNodeModel extends BaseNodeModel {
  constructor(options = {}) {
    super({
      ...options,
      type: NodeTypes.TRIGGER_NODE
    });
    this.templateId = '';
    this.templateCategoryId = options.templateCategoryId;
    this.nextNodes = [NodeTypes.ACTION_NODE];
    this.buildPorts([new PortTypes.OutPort()]);
  }

  serialize() {
    return {
      ...super.serialize(),
      templateCategoryId: this.templateCategoryId,
      templateId: this.templateId
    };
  }

  deserialize(ob, engine) {
    super.deserialize(ob, engine);
    const data = ob.data;
    this.templateCategoryId = data.templateCategoryId;
    this.templateId = data.templateId;
  }

  setInputFields(templateId, specs) {
    this.isDetailsSaved = !_.isEmpty(specs) && this.isAllDetailsFilled(specs);
    this.templateId = [templateId];
    this.specs = specs;
  }
}
