import { FC } from 'react';
import React from 'react';
import { EditComponent } from './EditComponent';
import { ViewComponent } from './ViewComponent';
import { FlipbookLayoutTile } from '../../../../models/FlipbookV2/FlipbookV2LayoutTile.model';

interface CustomImageContainerProps {
  flipbookLayoutTile: FlipbookLayoutTile;
  isEditMode: boolean;
  editPanelOccupiedKey: string;
  setEditPanelOccupiedKey: (state: string) => void;
}
export const CustomImageContainer: FC<CustomImageContainerProps> = ({
  flipbookLayoutTile,
  isEditMode,
  editPanelOccupiedKey,
  setEditPanelOccupiedKey
}) => {
  return isEditMode ? (
    <EditComponent
      editPanelOccupiedKey={editPanelOccupiedKey}
      setEditPanelOccupiedKey={setEditPanelOccupiedKey}
      flipbookLayoutTile={flipbookLayoutTile}
    />
  ) : (
    <ViewComponent flipbookLayoutTile={flipbookLayoutTile} />
  );
};
