/* eslint-disable @typescript-eslint/explicit-function-return-type */
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import {
  Button,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select
} from '@material-ui/core';
import useCurrentUser from '../../../hooks/useCurrentUser';
import {
  capitalize,
  compact,
  find,
  first,
  get,
  map,
  random,
  reduce
} from 'lodash';
import moment from 'moment';
import { RefreshOutlined } from '@material-ui/icons';
import React, { FC, useEffect, useState } from 'react';
import { storeKPIGenerateReport } from '../../../services/TenantReports/StoreKPIReport.service';
import { Business } from '../../../models/User.model';
import {
  getStoreTagOptionsByStoreOptions,
  getStoresWithAnyTags
} from '../../../services/store.service';
import { getCurrentUser } from '../../../services/user.service';

export const KPI_REPORT_TYPE = {
  ANNUAL: 'Annual',
  QUARTERLY: 'Quarterly',
  DEFAULT: 'Default'
};

const monthCountOptions = [3, 6, 12];

export interface StoreKPIReportInterface {
  endDate: string;
  monthCount: number;
  columnName: string;
  business: Business;
  filters: any[];
}

const StoreKPIReportFilter: FC<any> = ({ setJobId }) => {
  const user = useCurrentUser();
  const businesses = user.getBusinesses();

  const StoreKPIReportInitialState: StoreKPIReportInterface = {
    endDate: moment()
      .subtract(1, 'month')
      .endOf('month')
      .format('YYYY-MM-DD'),
    columnName: 'type',
    monthCount: first(monthCountOptions) || 3,
    business: find(
      businesses,
      b => b.id === user.getDefaultBusinessId()
    ) as Business,
    filters: []
  };

  const [filters, setFilters] = useState<StoreKPIReportInterface>(
    StoreKPIReportInitialState
  );
  const [getStores, setGetStores] = useState<any[]>([]);
  const [storeOption, setStoreOption] = useState<any[]>([]);
  const [storeTagsOption, setStoreTagsOption] = useState<any[]>([]);
  const [tenantStore, setTenantStore] = useState<any[]>([]);
  const [selectedTagsValues, setSelectedTagsValues] = useState<string[]>([]);
  const [selectedStoreValue, setSelectedStoreValue] = useState<string>('');

  const handleChange = (value: any, name: string) => {
    console.log(value, name);
    setFilters({ ...filters, [name]: value });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsBuId = +urlParams.get('bu')! || 0;
    const currentBusiness = businesses.find(
      b => b.id === paramsBuId
    ) as Business;
    setFilters(filters => ({ ...filters, business: currentBusiness }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    getCurrentUser().then(res => {
      return setGetStores([...res.tenant_stores]);
    });
  }, []);

  const changeTenantStoreFormat = () => {
    const options = getStores.map((store: any) => {
      return {
        id: store.id,
        label: capitalize(store.storeNameAlias || store.store),
        value: store.store,
        tags: compact(
          map(store.tags, tag => {
            return { value: tag.value, type: tag.type };
          })
        )
      };
    });
    return options;
  };

  useEffect(() => {
    const data: any[] = changeTenantStoreFormat();
    setTenantStore([...data]);
    const getStore = getStores.map((store: any) => {
      return {
        id: store.id,
        label: store.store,
        value: store.store
      };
    });

    setStoreOption([...getStore]);
    const getOptions = getStoreTagOptionsByStoreOptions(data);
    setStoreTagsOption([...getOptions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getStores]);

  const handleTagSelect = (storeTags: any) => {
    if (storeTags && storeTags.length > 0) {
      const data = getStoresWithAnyTags(tenantStore, storeTags);
      setStoreOption(data || []);
    } else {
      const data = changeTenantStoreFormat();
      setStoreOption(data || []);
    }
    console.log(storeTags);
    setSelectedTagsValues(previousFilterUser =>
      previousFilterUser === storeTags ? null : storeTags || []
    );
  };

  useEffect(() => {
    if (selectedTagsValues && selectedTagsValues.length > 0) {
      const filteredStores = map(
        getStoresWithAnyTags(
          getStores,
          selectedTagsValues.map(sv => {
            return { value: sv };
          })
        ),
        (store: any) => {
          return {
            id: store.id,
            label: capitalize(store.storeNameAlias || store.store),
            value: store.store,
            tags: compact(
              map(store.tags, tag => {
                return { value: tag.value, type: tag.type };
              })
            )
          };
        }
      );
      setStoreOption(filteredStores);
    } else {
      const unFilteredStores = changeTenantStoreFormat();
      setStoreOption(unFilteredStores);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTagsValues]);

  useEffect(() => {
    setSelectedStoreValue('');
  }, [storeOption]);

  const handleGenerateReport = (): void => {
    storeKPIGenerateReport(
      filters.monthCount,
      filters.endDate,
      filters.business,
      selectedStoreValue
    ).then(res => {
      const jobId = get(res, 'jobId', '');
      setJobId(jobId);
    });
  };

  return (
    <form
      style={{ margin: 20, display: 'flex', justifyContent: 'space-between' }}
      onSubmit={e => {
        e.preventDefault();
        handleGenerateReport();
      }}
    >
      <div style={{ display: 'flex', padding: '2px' }}>
        <FormControl style={{ minWidth: 180, marginRight: '10px' }}>
          <InputLabel id="kpi-report-select-helper-label">
            Select Months
          </InputLabel>
          <Select
            value={filters.monthCount}
            label={'Select Months'}
            onChange={e => handleChange(e.target.value, 'monthCount')}
          >
            {map(monthCountOptions, mc => {
              return (
                <MenuItem
                  value={mc}
                  key={mc}
                  style={{ textTransform: 'capitalize' }}
                >
                  {mc}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl style={{ minWidth: 180, marginRight: '10px' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              views={['year', 'month']}
              label="Select to Date"
              value={filters.endDate}
              onChange={e => handleChange(e, 'endDate')}
              variant="inline"
              format="MMM yyyy"
              maxDate={moment()
                .subtract(1, 'month')
                .endOf('month')
                .format('YYYY-MM-DD')}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
        <FormControl style={{ width: 220, marginRight: '10px' }}>
          <InputLabel id="store-kpi-report-filter-by-store-tags-label">
            Filter Stores by Tags
          </InputLabel>
          <Select
            multiple
            value={selectedTagsValues}
            label={'Filter Stores by Tags'}
            id={'Filter Stores by Tags'}
            onChange={e => handleTagSelect(e.target.value)}
            renderValue={() => selectedTagsValues?.join(', ')}
          >
            {reduce(
              storeTagsOption,
              (listItems: any[], sto, key) => [
                ...listItems,
                <ListSubheader
                  style={{ backgroundColor: 'white' }}
                  color="primary"
                  key={key}
                >
                  {sto.label}
                </ListSubheader>,
                ...map(sto.options, (o, ok) => (
                  <MenuItem
                    style={{
                      backgroundColor: selectedTagsValues.includes(o.value)
                        ? 'rgb(242, 117, 95)'
                        : 'white'
                    }}
                    key={`${key}-${ok}`}
                    value={o.value}
                  >
                    {capitalize(o.value)}
                  </MenuItem>
                ))
              ],
              []
            )}
          </Select>
        </FormControl>
        <FormControl style={{ minWidth: 180, marginRight: '10px' }}>
          <InputLabel id="store-kpi-report-store-label">
            Select Store
          </InputLabel>
          <Select
            required
            value={selectedStoreValue}
            placeholder="Select Store"
            label="Select Store"
            id="Select Store"
            onChange={e => setSelectedStoreValue(e.target.value as string)}
          >
            {map(storeOption, (s, k) => {
              return (
                <MenuItem key={k} value={s.value}>
                  {s.value}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disableElevation
        >
          Generate Report
        </Button>
      </div>
      <Button onClick={() => setJobId(`${random(10000)}`)} color="primary">
        <RefreshOutlined
          style={{ marginRight: '5px' }}
          color="primary"
          fontSize="medium"
        />
        Refresh
      </Button>
    </form>
  );
};

export default StoreKPIReportFilter;
