export enum ReportTypes {
  STORE_HEALTH_DASHBOARD = 'store_health_dashboard',
  KPI_REPORT = 'kpi_report',
  TRANSACTION = 'transaction',
  FRAUD_REPORT = 'fraud_report',
  MEMBER_BILL_REPORT = 'member_bill_report',
  MEMBER_LOYALTY_REPORT = 'member_loyalty_report',
  CAMPAIGN_REPORT = 'campaign_report',
  STORE_STATUS_ACTIVITY = 'store_status_activity'
}
