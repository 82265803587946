import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Submited from './Submited';
import ClaimCard from './ClaimCard';
import _ from 'lodash';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  tabs: {
    borderBottom: '2px solid rgb(0 0 0 / 35%)'
  },
  button: {
    outline: 'none !important'
  },
  tabColor: {
    backgroundColor: '#F8A035'
  },
  textColor: {
    color: 'black !important'
  }
}));

export default function CustomTab(props) {
  const { kamClaims, fetchClaimData, setKamClaim, claimRef } = props;
  const { claimData } = kamClaims;
  const classes = useStyles();
  const [value, setValue] = useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const borderColor = status => {
    if (status === 'APPROVED') {
      return 'rgb(126, 248, 183)';
    } else if (status === 'REJECTED') {
      return 'rgb(255, 147, 147)';
    } else {
      return '#FFEF96';
    }
  };
  const expenseFilter = [
    { id: 1, name: 'All' },
    { id: 2, name: 'Approved' },
    { id: 3, name: 'Rejected' }
  ];

  const filterApproval = e => {
    if (e.target.value === 'Approved') {
      setKamClaim({
        ...kamClaims,
        filterStatus: e.target.value,
        claimData: claimRef.filter(
          item => _.get(item, 'expense.approvalStatus') === 'APPROVED'
        )
      });
    } else if (e.target.value === 'Rejected') {
      setKamClaim({
        ...kamClaims,
        filterStatus: e.target.value,
        claimData: claimRef.filter(
          item => _.get(item, 'expense.approvalStatus') === 'REJECTED'
        )
      });
    } else {
      setKamClaim({
        ...kamClaims,
        filterStatus: e.target.value,
        claimData: claimRef
      });
    }
  };
  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        TabIndicatorProps={{ style: { background: '#F8A035', color: 'black' } }}
        aria-label="full width tabs example"
        classes={{ flexContainer: classes.tabs, selected: classes.textColor }}
      >
        <Tab
          classes={{
            root: classes.button,
            selected: classes.textColor
          }}
          label="EXPENSES YET TO SUBMIT"
          {...a11yProps(0)}
        />
        <Tab
          classes={{
            root: classes.button,
            selected: classes.textColor
          }}
          label="SUBMITED LIST"
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Submited
          data={claimData?.filter(info => info.status === 'UNCLAIMED')}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: 20
          }}
        >
          <div style={{ display: 'flex' }}>
            <span style={{ fontWeight: 'bold', marginRight: 10 }}>STATUS:</span>
            <select
              value={kamClaims.filterStatus}
              onChange={e => filterApproval(e)}
            >
              {expenseFilter?.map(item => (
                <option key={item.id}>{item.name}</option>
              ))}
            </select>
          </div>
        </div>
        {(claimData.length &&
          claimData
            ?.filter(info => info.status === 'CLAIMED')
            ?.map((claims, i) => (
              <div style={{ padding: 20 }} key={i}>
                <ClaimCard
                  claims={claims}
                  kamClaims={kamClaims}
                  fetchClaimData={fetchClaimData}
                  borderColor={borderColor(
                    _.get(claims, 'expense.approvalStatus')
                  )}
                />
              </div>
            ))) ||
          'No Record found'}
      </TabPanel>
    </div>
  );
}
