/* eslint-disable @typescript-eslint/explicit-function-return-type */
import config from '../config';

function createCustomerGroup(data, business, apiRoutes) {
  const url = new URL(
    `${config.ruleServerUrl}/users/${apiRoutes}?business=${business}`
  );
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

const formData = fileData => {
  const formData = new FormData();
  for (const key in fileData) {
    formData.append(key, fileData[key]);
  }

  return formData;
};

function bulkCreateCustomerGroup(data, business, apiRoutes) {
  const url = new URL(
    `${config.ruleServerUrl}/users/${apiRoutes}?business=${business}`
  );
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: formData(data)
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function listCustomerGroupDetails(business, apiRoutes) {
  const url = new URL(
    `${config.ruleServerUrl}/users/${apiRoutes}?business=${business}`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function getCustomerGroupByIdDetails(business, id, apiRoutes) {
  const url = new URL(
    `${config.ruleServerUrl}/users/${apiRoutes}/${id}?business=${business}`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function getAssignedCustomerGroupDetails(business, id, apiRoutes, page, size) {
  const url = new URL(
    `${config.ruleServerUrl}/users/${apiRoutes}?customerGroupId=${id}&business=${business}&page=${page}&size=${size}`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function updateCustomerGroupByIdDetails(data, business, id, apiRoutes) {
  const url = new URL(
    `${config.ruleServerUrl}/users/${apiRoutes}/${id}?business=${business}`
  );
  return fetch(url, {
    credentials: 'include',
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function removeCustomerGroupDetails(business, id, apiRoutes) {
  const url = new URL(
    `${config.ruleServerUrl}/users/${apiRoutes}/${id}?business=${business}`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function removeCustomerDetails(data, business, apiRoutes) {
  const url = new URL(
    `${config.ruleServerUrl}/users/${apiRoutes}?business=${business}`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function searchAssignedCustomerDetails(
  customerGroupId,
  searchData,
  business,
  apiRoutes,
  page,
  size
) {
  const url = new URL(
    `${config.ruleServerUrl}/users/${apiRoutes}?customerGroupId=${customerGroupId}&mobileNumber=${searchData}&business=${business}&page=${page}&size=${size}`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

const createCustomerGroupConfig = (data, business) => {
  return createCustomerGroup(data, business, 'customer-groups');
};

const createAssignedGroupConfig = (data, business) => {
  return createCustomerGroup(data, business, 'assigned-customer-groups');
};
const listCustomerGroupConfig = business => {
  return listCustomerGroupDetails(business, 'customer-groups');
};
const getCustomerGroupById = (business, id) => {
  return getCustomerGroupByIdDetails(business, id, 'customer-groups');
};
const bulkCreateCustomersConfig = (data, businessId) => {
  return bulkCreateCustomerGroup(
    data,
    businessId,
    'assigned-customer-groups/batch'
  );
};
const updateCustomerGroupById = (data, business, id) => {
  return updateCustomerGroupByIdDetails(data, business, id, 'customer-groups');
};
const getAssignedCustomerGroup = (business, id, page, size) => {
  return getAssignedCustomerGroupDetails(
    business,
    id,
    'assigned-customer-groups',
    page,
    size
  );
};

const removeCustomerGroupConfig = (business, id) => {
  return removeCustomerGroupDetails(business, id, 'customer-groups');
};

const searchAssignedCustomerConfig = (
  customerGroupId,
  searchData,
  business,
  page,
  limit
) => {
  return searchAssignedCustomerDetails(
    customerGroupId,
    searchData,
    business,
    'assigned-customer-groups',
    page,
    limit
  );
};

const removeBulkCustomerConfig = (data, business) => {
  return removeCustomerDetails(
    data,
    business,
    'assigned-customer-groups/batch/delete'
  );
};
export default {
  createCustomerGroupConfig,
  bulkCreateCustomersConfig,
  listCustomerGroupConfig,
  createAssignedGroupConfig,
  getCustomerGroupById,
  updateCustomerGroupById,
  getAssignedCustomerGroup,
  removeCustomerGroupConfig,
  removeBulkCustomerConfig,
  searchAssignedCustomerConfig
};
