import { TableFooter, TablePagination, TableRow } from '@material-ui/core';
import React, { FC } from 'react';

interface CampaignTableFooterProps {
  page: number;
  rowsPerPage: number;
  count: number;
  colSpan: number;
  onPageChange: (pageNumber: number) => void;
  onRowPerPageChange: (rowsPerPage: number) => void;
}

const CampaignTableFooter: FC<CampaignTableFooterProps> = ({
  count,
  page,
  rowsPerPage,
  colSpan,
  onPageChange,
  onRowPerPageChange
}) => {
  const handlePageChange = (_: unknown, newPage: number): void => {
    onPageChange(newPage);
  };

  const handleRowPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    onRowPerPageChange(+event.target.value);
    onPageChange(0);
  };

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          count={count}
          rowsPerPageOptions={[25, 50, 100]}
          page={page}
          rowsPerPage={rowsPerPage}
          colSpan={colSpan}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowPerPageChange}
        />
      </TableRow>
    </TableFooter>
  );
};
export default CampaignTableFooter;
