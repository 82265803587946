import React, { useRef } from 'react';
import Button from '@material-ui/core/Button';
import { CSVLink } from 'react-csv';
import DescriptionIcon from '@material-ui/icons/Description';

export const DownloadTemplate = () => {
  const data = [];
  const headers = ['email', 'name', 'mobile', 'role', 'stores', 'password'];

  const csvLink = useRef(null);

  const handleClick = () => {
    csvLink.current.link.click();
  };

  return (
    <>
      <Button variant={'contained'} onClick={handleClick} color={'primary'}>
        <DescriptionIcon style={{ marginRight: 10 }} />
        Download Template
      </Button>
      <CSVLink
        enclosingCharacter={``}
        data={data}
        headers={headers}
        filename={`User_create_template.csv`}
        style={{ display: 'none' }}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </>
  );
};
