/* eslint-disable @typescript-eslint/explicit-function-return-type */
import config from '../config';
function saveTenentStoreDetails(businessUnitId, data) {
  const url = new URL(
    `${config.ruleServerUrl}/users/store/businessUnit/${businessUnitId}/upsert`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json'
    }
  }).then(r => r.json());
}

function saveProductDetails(data) {
  const url = new URL(
    `${config.ruleServerUrl}/users/module-settings-pim/categories`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({
      ...data
    }),
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function updateTenentStoreDetails(data) {
  const url = new URL(`${config.ruleServerUrl}/users/tenant/tenant-stores`);

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function getTenentStore(offset, rowsPerPage, businessUnitId) {
  const url = new URL(
    `${config.ruleServerUrl}/users/store/businessUnit/${businessUnitId}/get?offset=${offset}&limit=${rowsPerPage}`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function filterSearchDetails(querySearch, businessUnitId) {
  const url = new URL(
    // `${config.ruleServerUrl}/users/tenant/paginated-stores/${offset}/${rowsPerPage}?${querySearch}`
    `${config.ruleServerUrl}/users/store/businessUnit/${businessUnitId}/get?${querySearch}`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function exportCSVDetails(businessUnitId) {
  const url = new URL(
    `${config.ruleServerUrl}/users/store/businessUnit/${businessUnitId}/download`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.blob())
    .catch(err => {
      return {};
    });
}

function getStoreTags(businessUnitId) {
  const url = new URL(
    `${config.ruleServerUrl}/users/store/businessUnit/${businessUnitId}/tags`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function uploadBulkStoreTags(businessUnitId, data) {
  return fetch(
    `${config.ruleServerUrl}/users/store/businessUnit/${businessUnitId}/fileimport`,
    {
      credentials: 'include',
      method: 'POST',
      body: data
    }
  ).then(r => r.json());
}

function getStoreTemplate(businessUnitId) {
  const url = new URL(
    `${config.ruleServerUrl}/users/store/businessUnit/${businessUnitId}/download-template`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.blob())
    .catch(err => {
      return {};
    });
}

const getTenentStoreConfig = (offset, rowsPerPage, businessUnitId) => {
  return getTenentStore(offset, rowsPerPage, businessUnitId);
};

const saveTenentStoreConfig = (businessUnitId, data) => {
  return saveTenentStoreDetails(businessUnitId, data);
};

const updateTenentStoreConfig = data => {
  return updateTenentStoreDetails(data);
};

const saveProductConfig = data => {
  return saveProductDetails(data);
};
const filterSearchConfig = (querySearch, businessUnitId) => {
  return filterSearchDetails(querySearch, businessUnitId);
};
const exportCSVConfig = businessUnitId => {
  return exportCSVDetails(businessUnitId);
};

const storeTagsOption = businessUnitId => {
  return getStoreTags(businessUnitId);
};

const bulkStoreTagsConfig = (businessUnitId, data) => {
  return uploadBulkStoreTags(businessUnitId, data);
};

const getStoreTemplateConfig = businessUnitId => {
  return getStoreTemplate(businessUnitId);
};

export default {
  getTenentStoreConfig,
  saveTenentStoreConfig,
  updateTenentStoreConfig,
  saveProductConfig,
  filterSearchConfig,
  exportCSVConfig,
  storeTagsOption,
  bulkStoreTagsConfig,
  getStoreTemplateConfig
};
