import React from 'react';
import classes from './TrayTab.module.css';
export const TrayTab = ({ Icon, title, onClick, style }) => {
  return (
    <div className={classes.container} onClick={onClick} style={style}>
      <Icon className={classes.icon} />
      <span className={classes.label}>
        {title.split(/(?=[A-Z])/).join(' ')}
      </span>
    </div>
  );
};
