import { IconButton, TableBody, TableCell, TableRow } from '@material-ui/core';
import GetApp from '@material-ui/icons/GetApp';
import React, { FC } from 'react';

import CampaignReport from '../../../../../../models/campaign/CampaignReport';
import styles from './CampaignReportTableBody.module.scss';
import config from '../../../../../../config';
import formatDate from '../../../../../../shared/utils/formatDate';
import Chip from '../../../../../../shared/components/Chip/Chip';

interface CampaignReportTableBodyProps {
  reports: CampaignReport[];
}

const CampaignReportTableBody: FC<CampaignReportTableBodyProps> = ({
  reports
}) => {
  const getDownloadReportUrl = (url: string | null): string => {
    if (!url) return '/';
    const indexOfReportUrl = url.indexOf('/report') + 1;

    return `${config.ruleServerUrl}/download-file/${url.slice(
      indexOfReportUrl
    )}`;
  };

  return (
    <TableBody className={styles.campaignReportTableBody}>
      {reports.map(report => (
        <TableRow key={report.id}>
          <TableCell> {formatDate(report.createdAt)} </TableCell>
          <TableCell> {formatDate(report.startTime)} </TableCell>
          <TableCell>
            {report.endTime === null
              ? 'In Progress'
              : formatDate(report.endTime)}{' '}
          </TableCell>
          <TableCell>
            <Chip
              label={report.status}
              color={
                report.status === 'COMPLETE' || report.status === 'COMPLETED'
                  ? 'success'
                  : report.status === 'FAILED'
                  ? 'error'
                  : 'primary-light'
              }
            />
          </TableCell>
          <TableCell>
            <IconButton
              disabled={
                report.status !== 'COMPLETE' && report.status !== 'COMPLETED'
              }
              component="a"
              href={getDownloadReportUrl(report.errorFileLocation)}
              download
            >
              <GetApp />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
export default CampaignReportTableBody;
