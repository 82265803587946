import _ from 'lodash';

export function getDefaultOptions() {
  return [
    {
      label: 'Select All',
      value: 'Select All'
    },
    {
      label: 'Clear All',
      value: 'Clear All'
    }
  ];
}

export function isOptionValueAvailable(options: any, valueType: string) {
  return !_.isEmpty(_.find(options, { value: valueType }));
}
