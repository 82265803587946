/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import _ from 'lodash';

import useFocus from '../../customHooks/useFocus';
import classes from './Search.module.css';

const Search = ({ placeholder, onSearch, width = 'max-content' }) => {
  const [query, setQuery] = React.useState('');
  const [focusRef, setFocusRef] = useFocus();

  const onSearchHandler = event => {
    const searchText = event.target.value;
    onSearch(searchText);
    setQuery(searchText);
  };

  const searchIcon = () => (
    <svg width="30px" height="30px" viewBox="0 0 30 30">
      <path
        fill="grey"
        d="M13 3C7.489 3 3 7.489 3 13s4.489 10 10 10a9.947 9.947 0 006.322-2.264l5.971 5.971a1 1 0 101.414-1.414l-5.97-5.97A9.947 9.947 0 0023 13c0-5.511-4.489-10-10-10zm0 2c4.43 0 8 3.57 8 8s-3.57 8-8 8-8-3.57-8-8 3.57-8 8-8z"
      />
    </svg>
  );

  return (
    <div
      style={{ width: width }}
      className={classes.container}
      onClick={setFocusRef}
    >
      {searchIcon()}
      <input
        ref={focusRef}
        type="text"
        placeholder={`Search ${_.capitalize(placeholder)}`}
        value={query}
        onChange={onSearchHandler}
      />
    </div>
  );
};

export default Search;
