import { ALLOWED_VERSION } from '../../constants';

export const getMaxVersion = () => {
  let versions = [];
  for (const version in ALLOWED_VERSION) {
    if (ALLOWED_VERSION[version].length >= versions.length) {
      versions = ALLOWED_VERSION[version];
    }
  }
  return versions;
};
