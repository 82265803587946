import React from 'react';
import { styled, StepLabel, Stepper, Step } from '@material-ui/core';
import { values } from 'lodash';
import constants from '../../shared/constants';

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  '&:hover': {
    cursor: 'pointer'
  }
}));
interface SectionStepperProps {
  activeStep: number;
  setStep: (idx: number) => void;
}
const SectionStepper: React.FC<SectionStepperProps> = ({
  activeStep,
  setStep
}) => {
  const steps = values(constants.stepperFields);
  return (
    <div>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label} onClick={() => setStep(index)}>
            <StyledStepLabel>{label}</StyledStepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default SectionStepper;
