import React, { useState, useEffect, FC } from 'react';
import { useParams } from 'react-router';
import offerService from '../../services/offer.service';
import { Offer } from '../../models/offer/Offer.model';
import { OfferDetail } from '../../components/OfferDetail';
import { ReadOnlyContext } from '../../components/OfferDetail/ReadOnlyContext';
import { ParentNavigate } from '../../utils/ParentNavigate';

export const CloneOfferRoute: FC = () => {
  const { offerCode } = useParams();
  const [offer, setOffer] = useState<Offer>(new Offer());
  const [triggerRedirect, setTriggerRedirect] = useState<boolean>(false);

  useEffect(() => {
    offerService
      .getOffer(offerCode)
      .then(offerObject => {
        setOffer(offerObject);
      })
      .catch(e => {
        if (e.message === 'NOT_FOUND') setTriggerRedirect(true);
      });
  }, [offerCode]);

  return (
    <div>
      <ReadOnlyContext.Provider
        value={{
          readOnly: false,
          cloneOffer: true
        }}
      >
        <OfferDetail offer={offer} />
        {triggerRedirect && <ParentNavigate to={'/offers'} />}
      </ReadOnlyContext.Provider>
    </div>
  );
};
