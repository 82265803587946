/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const initialStateReducer = {
  toastMessageState: {
    severity: '',
    message: '',
    messageStatus: false
  }
};

export const toastMessageStateReducer = (
  state = initialStateReducer,
  action
) => {
  const { type, payload } = action;
  const copyState = JSON.parse(JSON.stringify(state));

  switch (type) {
    case 'TOASTMESSAGEACTION': {
      return { ...copyState, toastMessageState: payload };
    }

    default:
      return state;
  }
};
