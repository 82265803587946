import React from 'react';
function AddNode(props) {
  return (
    <svg width="60px" height="60px" viewBox="0 0 50 50" {...props}>
      <defs>
        <filter
          id="prefix__a"
          x={0}
          y={0}
          width={50}
          height={50}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={3} result="blur" />
          <feFlood floodOpacity={0.161} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g data-name="Component 165 \u2013 153">
        <g filter="url(#prefix__a)">
          <g data-name="Path 373" fill="#4191df">
            <path d="M25 37.5c-4.14 0-8.033-1.612-10.96-4.54A15.399 15.399 0 019.5 22c0-4.14 1.612-8.033 4.54-10.96A15.399 15.399 0 0125 6.5c4.14 0 8.033 1.612 10.96 4.54A15.399 15.399 0 0140.5 22c0 4.14-1.612 8.033-4.54 10.96A15.399 15.399 0 0125 37.5z" />
            <path
              d="M25 7c-4.007 0-7.773 1.56-10.607 4.393A14.902 14.902 0 0010 22c0 4.007 1.56 7.773 4.393 10.607A14.902 14.902 0 0025 37c4.007 0 7.773-1.56 10.607-4.393A14.902 14.902 0 0040 22c0-4.007-1.56-7.773-4.393-10.607A14.902 14.902 0 0025 7m0-1c8.837 0 16 7.163 16 16s-7.163 16-16 16S9 30.837 9 22 16.163 6 25 6z"
              fill="#e3e4e4"
            />
          </g>
        </g>
        <path
          data-name="Icon map-zoom-in"
          d="M31.062 20.758h-4.7v-4.696h-2.604v4.7h-4.696v2.604h4.7v4.7h2.604v-4.7h4.7z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default AddNode;
