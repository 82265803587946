export const roleList = [
  { id: 101, name: 'admin' },
  { id: 102, name: 'store_manager' },
  { id: 103, name: 'customer_associate' },
  { id: 104, name: 'bose_store_manager' },
  { id: 105, name: 'area_manager' },
  { id: 106, name: 'partner_admin' }
];

export const featureList = [
  { id: 201, feature: 'Feature1', accessible: [101, 104, 106] },
  { id: 202, feature: 'Feature2', accessible: [102, 103] },
  { id: 203, feature: 'Feature3', accessible: [101, 102, 103] },
  { id: 204, feature: 'Feature4', accessible: [102, 104, 105] },
  { id: 205, feature: 'Feature5', accessible: [101, 104] },
  { id: 206, feature: 'Feature6', accessible: [101, 104, 103, 105, 106] },
  { id: 207, feature: 'Feature7', accessible: [104, 101, 102] },
  { id: 208, feature: 'Feature8', accessible: [103, 102, 106] }
];

export const businessUnitSelecttems = ['Business Unit', 'Store1', 'Store2'];

export const totalColumns = 8;
export const manualHeaderId = 888;
