import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import offerService from '../../../../../services/offer.service';
import classes from './OfferDetails.module.css';
import inputRow from '../../NodeDetails/InputRow/InputRow.module.css';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import MultiSelect from '../../MultiSelect/MultiSelect';

export const OfferDetails = (detail, setDetail) => {
  const [offers, setOffers] = useState([]);
  const [inputValue, setInputValue] = useState(detail.value.join(','));
  const [validOffers, setValidOffers] = useState([]);
  const [invalidOffers, setInvalidOffers] = useState([]);
  const [inputType, setInputType] = useState('text');
  useEffect(() => {
    async function getOffers() {
      setOffers(await offerService.retrieveOffers());
    }
    getOffers();
  }, []);
  const getOptions = () => {
    return _.map(offers, offer => offer.code);
  };

  const showValidOffers = () => {
    return (
      <div className={classes.showValidOffers}>
        <div>{validOffers.length} offer(s) valid</div>
        {_.map(validOffers, offer => (
          <div>
            <label className={classes.label} key={offer}>
              {offer}
            </label>
            <br />
          </div>
        ))}
      </div>
    );
  };

  const showInvalidOffers = () => {
    return (
      <div className={classes.showInvalidOffers}>
        <div>{invalidOffers.length} offer(s) invalid</div>
        {_.map(invalidOffers, offer => (
          <div>
            <label className={classes.label} key={offer}>
              {offer}
            </label>
            <br />
          </div>
        ))}
      </div>
    );
  };

  const verifyOffers = values => {
    const options = getOptions();
    const validCodes = _.filter(
      values.split(','),
      offerCode => _.includes(options, offerCode) && !_.isEmpty(offerCode)
    );
    const invalidCodes = _.filter(
      values.split(','),
      offerCode => !_.includes(options, offerCode) && !_.isEmpty(offerCode)
    );
    setValidOffers(validCodes);
    setInvalidOffers(invalidCodes);
    setDetail(validCodes);
  };
  return (
    <>
      <FormLabel component="legend">Input Type</FormLabel>
      <RadioGroup
        aria-label="Input Type"
        name="Input Type"
        row
        value={inputType}
        onChange={e => {
          setInputType(e.target.value);
        }}
      >
        <FormControlLabel value="text" control={<Radio />} label="Text" />
        <FormControlLabel value="select" control={<Radio />} label="Select" />
      </RadioGroup>
      {inputType === 'text' ? (
        <>
          <div className={inputRow.container}>
            <span className={inputRow.title}>
              {detail.label || detail.name}
            </span>
            <input
              className={inputRow.customInput}
              type={detail.type}
              value={inputValue}
              onChange={event => {
                setInputValue(event.target.value);
                verifyOffers(event.target.value);
              }}
            />
          </div>
          {!_.isEmpty(validOffers) && showValidOffers()}
          {!_.isEmpty(invalidOffers) && showInvalidOffers()}
        </>
      ) : (
        <MultiSelect
          placeholder="Offer Code"
          values={detail.value}
          onChange={value => {
            setValidOffers(value);
            setDetail(value);
          }}
          options={getOptions()}
          width="600px"
          isSearchEnabled={true}
        />
      )}
    </>
  );
};
