import { useEffect, useState } from 'react';
import { getCurrentUser } from '../../../../../services/user.service';
import AutoSelect from '../../NodeDetails/InputRow/AutoSelect';

import _ from 'lodash';
export const CategoryDropdown = ({ detail, setDetail }) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    async function getCategories() {
      getCurrentUser().then(res => {
        const business = _.head(_.head(res.tenant.brands)?.businesses);

        setOptions(
          _.map(business.lmsConfig?.productSelection?.options, option => {
            return option.value;
          })
        );
      });
    }

    getCategories();
  }, []);
  const onChange = value => {
    setDetail(value);
  };
  detail.options = options;
  return AutoSelect({ detail, onSelect: onChange });
};
