/* eslint-disable @typescript-eslint/explicit-function-return-type */
import config from '../config/index';

export function getTemplatesByCategoryId(categoryId) {
  return fetch(
    `${config.ruleServerUrl}/users/template-configs?category=${categoryId}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  )
    .then(r => r.json())
    .catch(err => {
      console.log(err);
    });
}
export async function getTemplate(templateId) {
  return await fetch(
    `${config.ruleServerUrl}/users/template-configs/${templateId}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json'
      }
    }
  )
    .then(r => {
      return r.json();
    })
    .catch(err => {
      console.log(err);
    });
}
export async function saveTemplateAsRule(templateId, specs) {
  return await fetch(
    `${config.ruleServerUrl}/users/template-configs/${templateId}/create-rule`,
    {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json'
      },
      body: JSON.stringify({ specs })
    }
  )
    .then(r => {
      return r.json();
    })
    .catch(err => {
      console.log(err);
    });
}
export async function saveCampaign(campaign) {
  campaign = JSON.stringify(campaign);
  return await fetch(`${config.ruleServerUrl}/users/campaigns`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json'
    },
    body: JSON.stringify({ campaign })
  })
    .then(r => {
      return r.json();
    })
    .catch(err => {
      console.log(err);
    });
}
