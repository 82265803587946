import React from 'react';
import * as classes from './DetailsPopup.module.css';
import { DetailsPanel } from '../NodeDetails/DetailsPanel';
import Modal from '@material-ui/core/Modal';
import detailsInput from '../NodeDetails/DetailInput/DetailInput.module.css';
import _ from 'lodash';

const DetailsPopup = ({
  show,
  setShow,
  nodeDetails,
  repaintCanvas,
  isReadOnly
}) => {
  return (
    <Modal open={show}>
      <div className={classes.popUp}>
        <div className={classes.heading}>{nodeDetails.node.options.name}</div>
        {console.log('NODEDETAILS.NODE.TYPE', nodeDetails.node.type)}
        {_.isEmpty(nodeDetails.ruleTemplates) &&
        _.isEqual(nodeDetails.node.type, 'TriggerNode') ? (
          <div className={classes.errorMsg}>
            <div className={classes.errorText}>
              No templates found for this Cohort
            </div>
            <div className={detailsInput.buttonsContainer}>
              <div className={detailsInput.save} onClick={() => setShow(false)}>
                close
              </div>
              <div
              // style={
              //   canSave(specs) ? {} : { cursor: 'not-allowed', opacity: '0.4' }
              // }
              >
                <div
                  className={detailsInput.save}
                  // style={canSave(specs) ? {} : { pointerEvents: 'none' }}
                  style={{ pointerEvents: 'none', opacity: '0.5' }}
                >
                  Save Details
                </div>
              </div>
            </div>
          </div>
        ) : (
          <DetailsPanel
            isReadOnly={isReadOnly}
            nodeDetails={nodeDetails}
            repaintCanvas={repaintCanvas}
            setShow={setShow}
          />
        )}
      </div>
    </Modal>
  );
};

export default DetailsPopup;
