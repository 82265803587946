import React, { useContext, useEffect, useState } from 'react';
import { DateTimeFormat } from '../DateTimeFormat';
import { CartWrapper } from '../../models/CartWrapper';
import { CartBucket } from '../CartBucket';
import Button from '@material-ui/core/Button';
import update from 'immutability-helper';
import leftArrow from '../../components/Assets/Offers/leftArrow.svg';
import { OfferExpiry } from '../OfferExpiry';
import { OfferCartBuilderContext } from './OfferCartBuilderContext';
import _ from 'lodash';
import { Customer } from '../../models/Customer';
import { CircularProgress } from '@material-ui/core';
import MetroBrandsLogo from '../Assets/Offers/MetroBrandsLogo.png';

const emptyCart = new CartWrapper();

const styles: { [key: string]: { [k: string]: string } } = {
  offerFieldLabel: {
    color: '#A8A8A8',
    fontWeight: 'bold',
    fontSize: '12px',
    lineSpacing: '16px'
  },
  offerFieldValue: {
    color: '#767676',
    fontWeight: 'bold',
    fontSize: '14px',
    lineSpacing: '19px',
    textTransform: 'uppercase',
    letterSpacing: '0px'
  },
  descriptionText: {
    color: '#404040',
    fontWeight: '600',
    fontSize: '16px',
    lineSpacing: '22px',
    letterSpacing: '0px'
  },
  dateMain: {
    color: '#767676',
    fontSize: '13px',
    lineSpacing: '18px',
    fontWeight: 'bold'
  },
  dateNormal: {
    color: '#767676',
    fontSize: '13px',
    lineSpacing: '18px'
  },
  oddCardStyle: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 6px 10px #003F8808',
    border: '1px solid #DEDEDE',
    borderRadius: '8px'
  },
  evenCardStyle: {
    background: '#F8F8F8 0% 0% no-repeat padding-box;',
    border: '1px solid #DEDEDE',
    borderRadius: '8px'
  }
};

interface OfferCartBuilderProps {
  onCartSave: (c: CartWrapper) => Promise<void>;
  onBackClick: () => void;
  customerInfo: Customer;
  storeCode: string;
}

const getAddressInfoUpdate = (
  customerInfo: Customer,
  storeCode: string,
  customerMobile?: string
) => {
  return {
    address: {
      $set: _.get(customerInfo, 'address', {})
    },
    phone: {
      $set: customerMobile
    },
    name: {
      $set: _.get(customerInfo, 'name', '')
    },
    email: {
      $set: _.get(customerInfo, 'contact.email', '')
    },
    storeCode: {
      $set: storeCode
    },
    source: {
      $set: storeCode
    }
  };
};
const noOpPromise = () => Promise.resolve();

const defaultCustomer = new Customer();

export const OfferCartBuilder: React.FC<OfferCartBuilderProps> = ({
  onCartSave = noOpPromise,
  onBackClick = _.noop,
  customerInfo = defaultCustomer,
  storeCode
}) => {
  const { offer, customerMobile } = useContext(OfferCartBuilderContext);
  const [cart, setCart] = useState<CartWrapper>(emptyCart);
  const [isSaving, setIsSaving] = useState(false);
  useEffect(() => {
    const addressInfoUpdate = getAddressInfoUpdate(
      customerInfo,
      storeCode,
      customerMobile
    );
    setCart(
      update(emptyCart, {
        cartInfo: {
          customerMobile: {
            $set: customerMobile
          }
        },
        addressInfo: {
          billingAddress: addressInfoUpdate,
          shippingAddress: addressInfoUpdate
        }
      })
    );
  }, [offer, customerInfo, customerMobile, storeCode]);
  const saveCart = () => {
    setIsSaving(true);
    onCartSave(cart).then(
      () => setIsSaving(false),
      () => setIsSaving(false)
    );
  };
  return offer.isDefined ? (
    <div style={{ backgroundColor: '#F8F8F8' }} className={'p-2'}>
      <div className={'d-flex justify-content-center align-items-center'}>
        <div
          className={'col-1 d-flex justify-content-center align-items-center'}
        >
          <img
            src={leftArrow}
            className={'hoverable zoomOnHover'}
            alt={'back'}
            onClick={onBackClick}
          />
        </div>
        <div className={'col-11'}>
          <div
            className={'py-2'}
            style={{ color: '#404040', fontWeight: 'bold', fontSize: '20px' }}
          >
            {offer.name}
          </div>
        </div>
      </div>
      <div className={'d-flex justify-content-center align-items-center'}>
        <div className={'col-11 offset-1'}>
          <div className={'d-flex flex-wrap mb-1'}>
            <div
              className={'col-12 col-md-2  p-2 d-flex justify-content-center'}
              style={{
                backgroundColor: 'white',
                border: '1px solid #DEDEDE',
                borderRadius: '4px'
              }}
            >
              <img
                src={offer.imageUrl || MetroBrandsLogo}
                alt={'offer'}
                className={'img-fluid my-auto'}
                style={{ height: '100px', objectFit: 'contain' }}
              />
            </div>
            <div
              className={
                'col-12 col-md-10 pl-0 pl-md-3 mt-2 mt-md-0 d-flex flex-column align-items-start justify-content-around'
              }
            >
              <div className={'d-flex pl-0 align-items-center w-100'}>
                <span
                  className={'col-4 col-md-2  px-0'}
                  style={styles.offerFieldLabel}
                >
                  OFFER CODE:
                </span>
                <span
                  className={'mxn4 text-break'}
                  style={styles.offerFieldValue}
                >
                  {offer.code}
                </span>
              </div>
              <div className={'d-flex pl-0 align-items-center  w-100'}>
                <span
                  className={'col-4 col-md-2 px-0'}
                  style={styles.offerFieldLabel}
                >
                  VALID FROM:
                </span>
                <span className={'mxn4'}>
                  <DateTimeFormat
                    date={offer.startDate!}
                    format={'DD MMM'}
                    style={styles.dateMain}
                  />
                  <DateTimeFormat
                    date={offer.startDate!}
                    format={' YYYY, LT'}
                    style={styles.dateNormal}
                  />
                </span>
              </div>
              <div className={'d-flex pl-0 align-items-center  w-100'}>
                <span
                  className={'col-4 col-md-2 px-0'}
                  style={styles.offerFieldLabel}
                >
                  VALID TILL:
                </span>
                <span className={'mxn4'}>
                  <DateTimeFormat
                    date={offer.endDate!}
                    format={'DD MMM'}
                    style={styles.dateMain}
                  />
                  <DateTimeFormat
                    date={offer.endDate!}
                    format={' YYYY, LT'}
                    style={styles.dateNormal}
                  />
                </span>
              </div>
              <div className={'d-flex  w-100 px-0'}>
                <OfferExpiry
                  offer={offer}
                  className={'col-4 d-flex justify-content-start px-0'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'d-flex justify-content-center align-items-center'}>
        <div
          className={
            'col-1 d-none d-md-flex justify-content-center align-items-center'
          }
        ></div>
        <div className={'col-12 col-md-11'}>
          <div className={'py-2'}>
            <div style={styles.offerFieldLabel}>DESCRIPTION:</div>
            <div style={styles.descriptionText}>{offer.description}</div>
          </div>
        </div>
      </div>

      <CartBucket
        cartWrapper={cart}
        offer={offer}
        cardStyles={{
          oddCard: styles.oddCardStyle,
          evenCard: styles.evenCardStyle
        }}
        offerBucket={offer.offerDefinition.bucket}
        isOdd={true}
        setCartWrapper={setCart}
      />
      <div className={'d-flex justify-content-center align-items-center py-3'}>
        <Button
          variant="contained"
          color="primary"
          onClick={saveCart}
          disabled={!cart.isFilled(offer) || isSaving}
        >
          Save Cart
          {isSaving && (
            <div
              className={
                'pl-2 d-flex justify-content-center align-items-center'
              }
            >
              <CircularProgress style={{ height: 20, width: 20 }} />
            </div>
          )}
        </Button>
      </div>
    </div>
  ) : (
    <div></div>
  );
};
