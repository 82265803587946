import JsBarcode from 'jsbarcode';

const GetBarCode = value => {
  const canvas = document.createElement('canvas');
  try {
    JsBarcode(canvas)
      .EAN13(value, {
        height: 40,
        fontSize: 14,
        font: 'OpenSans',
        marginTop: 2,
        marginLeft: 0,
        textMargin: 0,
        marginRight: 0
      })
      .render();
    const barcode = canvas.toDataURL();
    return barcode;
  } catch (err) {
    return '//:0';
  }
};

export { GetBarCode };
