import React, { useRef } from 'react';
import _, { startCase } from 'lodash';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { CSVLink } from 'react-csv';
import moment from 'moment';

export const ExportCSV = props => {
  const { users } = props;

  const csvLink = useRef(null);

  const headers = [
    { key: 'email', label: 'Email' },
    { key: 'phoneNumber', label: 'Phone Number' },
    { key: 'role', label: 'role' },
    { key: 'isActive', label: 'Is Active' },
    { key: 'stores', label: 'Stores' }
  ];

  const data = _.map(users, user => ({
    email: user.email,
    phoneNumber: user.mobile,
    role: startCase(user.role),
    isActive: user.isActive ? 'Active' : 'Inactive',
    stores: user.stores.join(' | ')
  }));

  const handleClick = () => {
    csvLink.current.link.click();
  };

  const timestamp = moment().format('YYYY_MM_DD_HHmm');

  return (
    <div>
      <Button
        disabled={users.length === 0}
        variant={'contained'}
        onClick={handleClick}
        color={'primary'}
      >
        <ArrowDownwardIcon style={{ marginRight: 10 }} />
        Export CSV
      </Button>
      <CSVLink
        enclosingCharacter={``}
        data={data}
        headers={headers}
        filename={`usersExport_${timestamp}.csv`}
        style={{ display: 'none' }}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </div>
  );
};
