import React, { useContext, useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button
} from '@material-ui/core';
import SelectProductHierarchy from './Components/SelectProductHierarchy';
import SelectProductSkus from './Components/SelectProductSkus';
import { BusinessUnitContext } from '../../BusinessUnitContext';
import _ from 'lodash';
import { OptionType } from './index';
import {
  FamilyValidator,
  ProductValidator,
  FieldCondition,
  Operator
} from '../../../../models/offer/OfferRule.model';

const FamilySelectProducts = (props: {
  readOnly: boolean;
  productFieldOptions: any;
  familyCondition: FamilyValidator;
  updateValidator: any;
  isDialogOpen: boolean;
  setDialogOpen: any;
}) => {
  let {
    readOnly,
    productFieldOptions,
    familyCondition,
    updateValidator,
    isDialogOpen,
    setDialogOpen
  } = props;
  const businessUnitContext = useContext(BusinessUnitContext);
  // const [skusList, setSkusList] = useState([]);
  const [validator, setValidator] = useState(familyCondition);
  let validatorType = validator.code();

  const [
    selectedProductFilterOptions,
    setSelectedProductFilterOptions
  ] = useState<{
    [key: string]: OptionType[];
  }>({});

  useEffect(
    function() {
      setValidator(familyCondition);
    },
    [familyCondition]
  );

  const productFilter = businessUnitContext.business.productFilter;

  function toggleSelectionType(event: any) {
    setSelectedProductFilterOptions({});
    // setSkusList([]);
    setValidator(
      FamilyValidator.convertValidator(validator, event.target.value)
    );
  }

  return (
    <Dialog
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isDialogOpen}
      maxWidth="lg"
      scroll="paper"
    >
      <DialogTitle id="alert-dialog-slide-title" className={'text-center'}>
        Select Products
      </DialogTitle>
      <DialogContent
        style={{ width: `${1000}px`, minWidth: 300, minHeight: 300 }}
      >
        <RadioGroup
          aria-label="selection type"
          name="selectionType"
          value={validatorType}
          onChange={toggleSelectionType}
        >
          <FormControlLabel
            value="Any"
            control={<Radio disabled={readOnly} />}
            label="Any Item"
          />
          <FormControlLabel
            value="All"
            control={<Radio disabled={readOnly} />}
            label="All Items"
          />
          <FormControlLabel
            value="DistinctSkus"
            control={<Radio disabled={readOnly} />}
            label="Any Distinct Item"
          />
          <FormControlLabel
            value="ProductHierarchy"
            control={<Radio disabled={readOnly} />}
            label="Select product(s) by hierarchy"
          />
          <SelectProductHierarchy
            isExclusion={false}
            selectedExclusionProductFilterOptions={{}}
            setSelectedExclusionProductFilterOptions={{}}
            productFilter={productFilter}
            selectedProductFilterOptions={selectedProductFilterOptions}
            setSelectedProductFilterOptions={setSelectedProductFilterOptions}
            isHierarchyBased={validatorType === 'ProductHierarchy'}
            productFieldOptions={productFieldOptions}
          />
          <FormControlLabel
            value="ProductSkus"
            control={<Radio disabled={readOnly} />}
            label="Select product(s) using sku"
          />
          <SelectProductSkus
            isExclusion={false}
            setSelectedProductFilterOptions={setSelectedProductFilterOptions}
            setSelectedExclusionProductFilterOptions={{}}
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions className={'mb-3'}>
        <Button
          onClick={() => {
            setDialogOpen(false);
          }}
          color="primary"
        >
          Cancel
        </Button>
        <div className={'mr-3'}>&nbsp;</div>
        <div className={'pr-3'}>
          <Button
            onClick={() => {
              let updatedValidator = _.clone(validator);
              if (updatedValidator instanceof ProductValidator) {
                const fieldConditions = _(selectedProductFilterOptions)
                  .entries()
                  .filter(([fieldName, fieldNameOptions]) =>
                    _.some(fieldNameOptions)
                  )
                  .map(([fieldName, fieldNameOptions]) => {
                    return new FieldCondition(
                      fieldName,
                      _.map(fieldNameOptions, 'value'),
                      Operator.isInOperator
                    );
                  })
                  .value();
                let updateValidator = updatedValidator as ProductValidator;
                updateValidator.conditions = fieldConditions;
              }

              updateValidator(updatedValidator);
              setDialogOpen(false);
            }}
            variant="contained"
            color="primary"
            className={'mr-3'}
          >
            Add
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default FamilySelectProducts;
