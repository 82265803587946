import React, { FunctionComponent } from 'react';
import BucketComponent from './Bucket';
import { JoinBucket } from '../../../models/offer/OfferRule.model';

type QueryBuilderProps = {
  bucket: JoinBucket;
  setRootBucket: (bucket: JoinBucket) => void;
};

const QueryBuilder: FunctionComponent<QueryBuilderProps> = ({
  bucket,
  setRootBucket
}) => {
  // todo: wip
  return (
    <BucketComponent
      bucket={bucket}
      rootBucket={bucket}
      isParent
      setRootBucket={setRootBucket}
    />
  );
};

export default QueryBuilder;
