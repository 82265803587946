import React, { FC } from 'react';

const People: FC = () => {
  return (
    <svg
      width="86"
      height="86"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.1" cx="42.836" cy="42.836" r="42.836" fill="#FD9B00" />
      <path
        d="M43 42.0555C44.3076 42.0555 45.5858 41.6677 46.673 40.9413C47.7602 40.2149 48.6075 39.1824 49.1079 37.9743C49.6083 36.7663 49.7392 35.437 49.4841 34.1546C49.229 32.8722 48.5994 31.6942 47.6748 30.7696C46.7502 29.845 45.5722 29.2154 44.2898 28.9603C43.0074 28.7052 41.6781 28.8361 40.4701 29.3365C39.262 29.8369 38.2295 30.6842 37.5031 31.7714C36.7767 32.8586 36.3889 34.1368 36.3889 35.4444C36.3889 37.1977 37.0854 38.8793 38.3253 40.1191C39.5651 41.359 41.2467 42.0555 43 42.0555ZM43 30.7221C43.934 30.7221 44.847 30.9991 45.6236 31.518C46.4001 32.0369 47.0054 32.7744 47.3628 33.6372C47.7202 34.5001 47.8137 35.4496 47.6315 36.3656C47.4493 37.2816 46.9996 38.1231 46.3391 38.7835C45.6787 39.4439 44.8373 39.8936 43.9213 40.0759C43.0053 40.2581 42.0558 40.1645 41.1929 39.8071C40.33 39.4497 39.5925 38.8445 39.0736 38.0679C38.5548 37.2913 38.2778 36.3783 38.2778 35.4444C38.2778 34.192 38.7753 32.9908 39.6609 32.1053C40.5465 31.2197 41.7476 30.7221 43 30.7221Z"
        fill="#FFB648"
      />
      <path
        d="M54.7772 49.016C53.2626 47.4152 51.4374 46.1401 49.4132 45.2686C47.3891 44.3972 45.2084 43.9478 43.0047 43.9478C40.8009 43.9478 38.6203 44.3972 36.5961 45.2686C34.5719 46.1401 32.7468 47.4152 31.2322 49.016C30.9038 49.3667 30.7214 49.8294 30.7222 50.3099V55.2777C30.7222 55.7786 30.9212 56.2591 31.2754 56.6133C31.6296 56.9676 32.1101 57.1666 32.6111 57.1666H53.3888C53.8898 57.1666 54.3702 56.9676 54.7245 56.6133C55.0787 56.2591 55.2777 55.7786 55.2777 55.2777V50.3099C55.281 49.8307 55.1021 49.3682 54.7772 49.016ZM53.3888 55.2777H32.6111V50.3004C33.9496 48.891 35.5608 47.7687 37.3468 47.0018C39.1329 46.2348 41.0562 45.8393 42.9999 45.8393C44.9437 45.8393 46.867 46.2348 48.6531 47.0018C50.4391 47.7687 52.0503 48.891 53.3888 50.3004V55.2777Z"
        fill="#FFB648"
      />
    </svg>
  );
};
export default People;
