import { TableCell, TableHead, TableRow } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';

import styles from './TableHeader.module.scss';

interface TableHeaderProps {
  headers: string[];
  textAlign?: 'start' | 'center' | 'end';
  className?: string;
}

const TableHeader: FC<TableHeaderProps> = ({
  headers,
  textAlign = 'start',
  className
}) => {
  return (
    <TableHead className={className} id={styles.tableHeader}>
      <TableRow className={styles.tableHeaderRow}>
        {headers.map(header => (
          <TableCell
            key={header}
            className={clsx(styles.tableHeaderCell, `text-${textAlign}`)}
          >
            {header}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
export default TableHeader;
