import React from 'react';
import moment from 'moment';
import { range } from 'lodash';
import { Page, View, Text, Image, StyleSheet, Font } from '@react-pdf/renderer';
import RupeeFit from '../../assets/rupeeFit.png';
import Disclimer from '../../assets/disclimer.png';
import fonts from '../../assets/Fonts';
import { truncatedNumber, truncateString } from '../../../../utils';
import _ from 'lodash';
Font.register(fonts);

const styles = StyleSheet.create({
  card: {
    fontFamily: 'OpenSans',
    height: '38mm',
    width: '93mm',
    border: '1 solid #000000',
    margin: '1mm 17mm'
  },
  inlineCard: { display: 'flex', flexDirection: 'row' },
  qrPriceBlock: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid #000000',
    height: '27mm'
  },
  qrcode: {
    width: '25mm',
    height: '25mm',
    margin: '1mm 2mm'
  },
  priceBlock: {
    display: 'flex',
    padding: '1mm',
    width: '68mm',
    flexDirection: 'row',
    borderLeft: '1 solid #000000'
    // left:-10
  },
  rupeeicon: {
    fontSize: '20pt',
    width: '8mm',
    paddingTop: '1mm',
    paddingLeft: '2mm',
    left: -2
  },
  price: {
    fontWeight: 'bold',
    fontSize: '65.8pt',
    textAlign: 'left',
    color: 'black',
    paddingLeft: '3mm',
    top: -20,
    lineHeight: 1
  },
  inclTax: {
    top: -10,
    width: '50%',
    textAlign: 'right',
    right: 30,
    fontSize: '5.98pt'
  },
  mrp: {
    fontSize: '8.43pt',
    top: -10,
    width: '50%',
    left: 11
    // fontWeight: 'bold'
  },
  mrpText: {
    fontWeight: 'bold',
    color: 'black'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 27
  },
  itemDescription: {
    fontFamily: 'OpenSans',
    fontSize: '6pt',
    padding: '1mm',
    lineHeight: 1.2
  },
  itemCode: {
    fontSize: '5pt',
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '1mm'
  },
  discBlock: {
    right: '1mm',
    bottom: '-2mm',
    position: 'absolute'
  },
  disclimer: {
    width: '3.75mm',
    height: '3.75mm',
    top: -6
  },
  rupeeSmall: {
    width: '2.5mm',
    height: '2.5mm'
  }
});
const LabelView = ({ item }) => (
  <View style={styles?.card}>
    <View style={styles?.qrPriceBlock}>
      <View style={styles?.qrcode}>
        <Image src={item.image} />
      </View>
      <View style={styles?.priceBlock}>
        <Text style={styles?.rupeeicon}>
          <Image src={RupeeFit} />
        </Text>
        <View style={{ left: -8, width: '100%' }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              position: 'relative'
            }}
          >
            <Text style={styles?.price}>
              {truncatedNumber(item?.product?.mop, 4)}
            </Text>
          </View>
          {truncatedNumber(item?.product?.mrp, 6) > 0 ? (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                position: 'relative',
                width: '100%'
              }}
            >
              <Text style={styles?.mrp}>
                <Text style={{ fontWeight: 'bold' }}>MRP: </Text>
                <Image style={styles.rupeeSmall} src={RupeeFit} />
                {truncatedNumber(item?.product?.mrp, 5)}
              </Text>
              <Text style={styles?.inclTax}>*Incl. Tax</Text>
            </View>
          ) : (
            <Text />
          )}
        </View>
      </View>
    </View>
    <View style={styles.itemContainer}>
      <Text style={styles.itemDescription}>
        {truncateString(item?.product?.masterData?.name, 130)}
      </Text>
      <View style={styles.itemCode}>
        <Text>
          {item?.sku} | {item.class} | A |{' '}
          {moment(item?.date).format('DD.MM.YYYY')} |{' '}
          {moment().format('DD.MM.YYYY')}
        </Text>
        <View style={styles.discBlock}>
          <Image style={styles.disclimer} src={Disclimer} />
        </View>
      </View>
    </View>
  </View>
);
const WireBasketFourDigit = ({ data, count, ...rest }) => {
  const labelsPerRow = 2;
  const rowsPerSku = count.length / labelsPerRow;
  const rowSets = range(rowsPerSku).map(ind =>
    count.slice(labelsPerRow * ind, labelsPerRow * (ind + 1))
  );
  const splattedArray = _.chunk(data, 10);
  return (
    <Page orientation="landscape" style={{ padding: '5mm' }}>
      <View>
        {count &&
          _.map(splattedArray, (chunkedData, key) => {
            const chunkedRowArray = _.chunk(chunkedData, 5);
            const firstHalf = chunkedRowArray[0]?.map((labelData, i) =>
              rowSets?.map(rowCount => (
                <View key={i}>
                  <View style={styles.inlineCard}>
                    {rowCount.map(id => (
                      <LabelView item={labelData} key={id} />
                    ))}
                  </View>
                </View>
              ))
            );
            const secondHalf = chunkedRowArray[1]?.map((labelData, i) =>
              rowSets?.map(rowCount => (
                <View key={i}>
                  <View style={styles.inlineCard}>
                    {rowCount.map(id => (
                      <LabelView item={labelData} key={id} />
                    ))}
                  </View>
                </View>
              ))
            );
            return (
              <View
                style={{ flexDirection: 'row', flexWrap: 'wrap' }}
                key={key}
              >
                <View>{firstHalf}</View>
                <View>{secondHalf}</View>
              </View>
            );
          })}
      </View>
    </Page>
  );
};
export { WireBasketFourDigit };
