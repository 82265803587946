import { FC, useCallback, useEffect, useState } from 'react';
import React from 'react';
import _ from 'lodash';
import { ImageContainer } from './ImageContainer';
import { CustomImageContainer } from './CustomImage/CustomImageContainer';
import { FlipbookLayoutTile } from '../../../models/FlipbookV2/FlipbookV2LayoutTile.model';
import { ImageType } from '../../../models/FlipbookV2/TileData/TileDataImage.model';

interface ImageLayoutProps {
  flipbookLayoutTile: FlipbookLayoutTile;
  isEditMode: boolean;
  editPanelOccupiedKey: string;
  setEditPanelOccupiedKey: (state: string) => void;
}

export const ImageLayout: FC<ImageLayoutProps> = ({
  flipbookLayoutTile,
  isEditMode,
  editPanelOccupiedKey,
  setEditPanelOccupiedKey
}) => {
  const [image, setImage] = useState('');
  const [imageType, setImageType] = useState('');

  const getImageFromTile = useCallback(() => {
    return _.get(flipbookLayoutTile, `tileData.imageData.URL`);
  }, [flipbookLayoutTile]);

  const getImageTypeFromTile = useCallback(() => {
    const temp = _.get(flipbookLayoutTile, `tileData.imageType`);
    return _.isEmpty(temp) ? ImageType.PLAIN : temp;
  }, [flipbookLayoutTile]);

  useEffect(() => {
    if (
      !_.isEmpty(flipbookLayoutTile) &&
      _.isEmpty(image) &&
      _.isEmpty(imageType)
    ) {
      const newImage = getImageFromTile();
      const newImageType = getImageTypeFromTile();
      setImageType(newImageType);
      setImage(newImage);
    }
  }, [
    image,
    flipbookLayoutTile,
    getImageFromTile,
    imageType,
    getImageTypeFromTile
  ]);

  function isTileHaveImage() {
    return _.isEmpty(image);
  }

  function getComponentBasedOnImageType() {
    switch (imageType) {
      case ImageType.TAGGER:
        return (
          <CustomImageContainer
            flipbookLayoutTile={flipbookLayoutTile}
            isEditMode={isEditMode}
            editPanelOccupiedKey={editPanelOccupiedKey}
            setEditPanelOccupiedKey={setEditPanelOccupiedKey}
          />
        );
      case ImageType.PLAIN:
        return <ImageContainer image={image} />;
      default:
        return <></>;
    }
  }

  if (isTileHaveImage()) {
    return <></>;
  }

  return getComponentBasedOnImageType();
};
