const DEFAULT_PAGE_WIDTH = 468;
const DEFAULT_PAGE_HEIGHT = 657;

const DEFAULT_PAGE_WIDTH_FOR_MOBILE = 300;
const DEFAULT_PAGE_HEIGHT_FOR_MOBILE = 460;

const getFlipbookPageDisplay = () => {
  return window.screen.width < 600 ? 'single' : 'double';
};

const getFlipbookWidth = () => {
  const screenSize = window.screen.width;
  if (getFlipbookPageDisplay() && screenSize > 600) {
    return DEFAULT_PAGE_WIDTH * 2;
  } else {
    return DEFAULT_PAGE_WIDTH_FOR_MOBILE;
  }
};

const getFlipbookHeight = () => {
  const screenSize = window.screen.width;
  if (getFlipbookPageDisplay() && screenSize > 600 && screenSize < 1500) {
    return 630;
  }
  if (getFlipbookPageDisplay() && screenSize >= 1500) {
    return DEFAULT_PAGE_HEIGHT;
  } else {
    return DEFAULT_PAGE_HEIGHT_FOR_MOBILE;
  }
};

export default { getFlipbookWidth, getFlipbookHeight, getFlipbookPageDisplay };
