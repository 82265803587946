const NodeTypes = {
  TRIGGER_NODE: 'TriggerNode',
  TIMED_NODE: 'TimedNode',
  ACTION_NODE: 'ActionNode',
  DECISION_NODE: 'DecisionNode',
  WAIT_NODE: 'WaitNode',
  STOP_NODE: 'StopNode'
};

export default NodeTypes;
