import React from 'react';
import RoleAssignStyles from '../RoleAssign.module.scss';
import Tooltip from '@material-ui/core/Tooltip';
import TableSwitch from '../Component/TableSwitch';
import { makeStyles } from '@material-ui/styles';
import featureRoleImage from '../../Assets/images/FeatureRole.png';
import { manualHeaderId } from '../constant';

const textStyles = makeStyles(theme => ({
  customTooltip: {
    fontSize: '14px'
  }
}));

function RoleAssignTable(props) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    emptyList,
    permissionData,
    value,
    changeSwitchState
  } = props;

  const classes = textStyles();

  return (
    <>
      <table {...getTableProps()} className={RoleAssignStyles.tableClass}>
        <thead>
          {headerGroups.map(headerGroup => (
            <>
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <>
                    {column.id === manualHeaderId && (
                      <th
                        {...column.getHeaderProps()}
                        className={
                          emptyList.emptyListData.length > 0
                            ? RoleAssignStyles.customSmallHeaderCell
                            : RoleAssignStyles.customHeaderCell
                        }
                      >
                        <>
                          <img src={featureRoleImage} alt="" />
                        </>
                      </th>
                    )}
                    {column.id !== manualHeaderId && (
                      <td
                        {...column.getHeaderProps()}
                        className={RoleAssignStyles.headerRole}
                      >
                        <Tooltip
                          title="It is the feature"
                          arrow
                          placement="top"
                          classes={{
                            tooltip: classes.customTooltip
                          }}
                        >
                          <div className={RoleAssignStyles.headerRoleList}>
                            {column.render('Header')}
                          </div>
                        </Tooltip>
                      </td>
                    )}
                  </>
                ))}

                {emptyList.emptyListData.length > 0 &&
                  emptyList.emptyListData.map((value, index) => (
                    <td key={index} className={RoleAssignStyles.emptyCell}></td>
                  ))}
              </tr>
            </>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, i) => {
                  return (
                    <>
                      {cell.column.id === manualHeaderId && (
                        <td
                          {...cell.getCellProps()}
                          key={i}
                          className={RoleAssignStyles.featureList}
                        >
                          <Tooltip
                            title={cell.value}
                            arrow
                            placement="right-end"
                            classes={{
                              tooltip: classes.customTooltip
                            }}
                          >
                            <div className={RoleAssignStyles.feature}>
                              {cell.render('Cell')}
                            </div>
                          </Tooltip>
                        </td>
                      )}

                      {cell.column.id !== manualHeaderId && (
                        <td
                          {...cell.getCellProps()}
                          key={i}
                          className={RoleAssignStyles.switchCell}
                        >
                          <>
                            {permissionData.permissionDataList.length > 0 && (
                              <TableSwitch
                                role={cell.row.original.name}
                                feature={cell.column.Header}
                                currentData={permissionData.permissionDataList}
                                changeSwitchState={changeSwitchState}
                                currentModule={value.currentModule}
                              />
                            )}
                          </>
                        </td>
                      )}
                    </>
                  );
                })}
                {emptyList.emptyListData.length > 0 &&
                  emptyList.emptyListData.map((value, index) => (
                    <td key={index} className={RoleAssignStyles.emptyCell}></td>
                  ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default RoleAssignTable;
