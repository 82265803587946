import React, { useEffect } from 'react';
import 'react-table-v6/react-table.css';
import '../../../styles/Table.scss';
import ReactTable from 'react-table-v6';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import TotalClaims from '../components/TotalClaims';

const CheckClaims = props => {
  const { columns, kamInfo, setKam, kam } = props;
  const history = useHistory();
  const parsed = queryString.parse(history.location.search);
  const fetchClaims = () => {
    const data = kamInfo.filter(list => list.userName === parsed.kam);
    setKam({ ...kam, individualKam: data });
  };
  useEffect(fetchClaims, [kamInfo]);
  return (
    <div style={{ width: '100%', minHeight: '100vh', background: '#c8c8c830' }}>
      <div style={{ width: '95%', margin: 'auto 35px' }}>
        <div style={{ padding: '20px 0px', fontSize: 18 }}>
          <strong>
            <span
              style={{ color: 'rgb(44,118,247)', cursor: 'pointer' }}
              onClick={() => history.goBack()}
            >
              Expenses Management
            </span>{' '}
            / {parsed.kam}
          </strong>
        </div>
      </div>
      <ReactTable
        data={kam.individualKam}
        minRows={0}
        showPagination={false}
        NoDataComponent={() => null}
        columns={columns}
      />
      <div style={{ width: '95%', margin: 'auto 35px' }}>
        {/* <Paper style={{ padding: 20 }} elevation={3}>
          <div style={{ fontSize: 18, padding: 20 }}>
            <strong>KAM LIMITS PER WEEK</strong>
          </div>
          <div style={{ padding: '0px 20px', display: 'flex' }}>
            {kamLimit.map((limit, i) => (
              <div key={i} style={{ width: '25%' }}>
                <strong style={{ fontSize: 16 }}>{limit.name}</strong>
                <div>{limit.price}</div>
              </div>
            ))}
          </div>
        </Paper> */}
        <TotalClaims kam={kam} history={history} />
      </div>
    </div>
  );
};

export default CheckClaims;
