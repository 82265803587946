interface CalculateRoiParam {
  revenue: number;
  targeted: number;
  messageCost: number;
}

const calculateRoi = ({
  revenue,
  targeted,
  messageCost
}: CalculateRoiParam): number => {
  const campaignExpense = targeted * messageCost;
  return revenue / campaignExpense || 0;
};

export default calculateRoi;
