function getInformationFromInstagramPostId(postId, accessToken) {
  return fetch(
    `https://graph.facebook.com/v15.0/${postId}?fields=caption,timestamp,permalink&access_token=${accessToken}`
  )
    .then(r => r.json())
    .then(r => ({ ...r, message: r.caption, permalinkUrl: r.permalink }));
}

export default {
  getInformationFromInstagramPostId
};
