import config from '../config/index';

const campaignConfig: any = {
  cromapromo: {
    dev: 'AXAUpNXwdqd1nfDjIume',
    cromaqa: 'AXGhJFqFwl0MfCF2GZ3K',
    cromaprod: 'AXKnYGCKALEZxL-zkQVJ'
  }
};

export function sendSMS(mobile: string, tenant: string): Promise<any> {
  const campaignId = campaignConfig[`${tenant}`][`${config.envName}`];
  const body = {
    campaignId,
    userId: mobile,
    tenantId: tenant
  };
  return fetch(`${config.ruleServerUrl}/public-shorten/campaign-url`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-type': 'application/json'
    }
  }).then(r => r.json());
}
