import React, { Context } from 'react';

type CampaignFilterContext = {
  startDate: string;
  endDate: string;
  buId: number;
};
export const CampaignFilterContext: Context<CampaignFilterContext> = React.createContext<
  CampaignFilterContext
>({
  startDate: '',
  endDate: '',
  buId: -1
});
