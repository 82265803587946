/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import classes from './UserTracking.module.css';

function ExistingCustomer(props) {
  return (
    <svg width="20px" height="20px" viewBox="0 0 10 16.57" {...props}>
      <path
        d="M5.776 3.069a1.5 1.5 0 01-1.1-.451 1.48 1.48 0 01-.451-1.083 1.475 1.475 0 01.45-1.084 1.569 1.569 0 012.2 0 1.474 1.474 0 01.451 1.083 1.479 1.479 0 01-.451 1.083 1.5 1.5 0 01-1.099.452zM2.924 5.7L.758 16.57h1.624L3.79 10.4l1.588 1.552v4.618h1.553v-5.776L5.307 9.242l.469-2.311A5.347 5.347 0 0010 8.844V7.328A3.724 3.724 0 018.069 6.8a3.655 3.655 0 01-1.354-1.349L5.92 4.224a1.512 1.512 0 00-1.3-.758 1.831 1.831 0 00-.307.036 1.831 1.831 0 01-.307.036L0 5.235v3.61h1.552v-2.6L2.924 5.7z"
        fill="#666"
      />
    </svg>
  );
}

export const UserTracking = ({ style, count }) => {
  return count > 0 ? (
    <div className={classes.container} style={style}>
      <ExistingCustomer />
      <div className={classes.text}>{count.toLocaleString()}</div>
    </div>
  ) : (
    <></>
  );
};
