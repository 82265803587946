import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import SectionProps from '../../../models/SectionProps';
import classes from './TenantInfo.module.css';
import utils from '../../../utils/utils';

const TenantInfo: React.FC<SectionProps> = ({ data, billData }) => {
  // console.log('tenant info data', data);
  return (
    <div className={classes.container}>
      <div className={classes.fields}>
        {data.fields.map((field, idx) => {
          return (
            <div key={`${field.label}-${idx}`}>
              {field.label && (
                <span className={classes.label}>{field.label}</span>
              )}
              <span className={classes.field}>
                {ReactHtmlParser(utils.getAndTransformValue(field, billData))}
              </span>
            </div>
          );
        })}
      </div>
      {data.html && <div>{ReactHtmlParser(data.html)}</div>}
    </div>
  );
};

export default TenantInfo;
