/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';

const StepsCounter = ({ count }) => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        color: 'black',
        border: '1px solid #E4E4E4',
        borderRadius: '10%/50%',
        width: 'max-content',
        padding: '5px 15px'
      }}
    >
      {count} steps added
    </div>
  );
};

export default StepsCounter;
