/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import classes from './TextArea.module.css';

export const TextArea = ({ detail, setDetail }) => {
  const [inputValue, setInputValue] = useState(detail.value);

  useEffect(() => {
    setInputValue(detail.value);
  });
  return (
    <div key={detail.name} className={classes.container}>
      <span className={classes.title}>{detail.label || detail.name}</span>
      <textarea
        className={classes.customInput}
        type={detail.type}
        value={inputValue}
        onChange={event => {
          setDetail(event.target.value);
          setInputValue(event.target.value);
        }}
      />
    </div>
  );
};
