import React from 'react';
import { makeStyles } from '@material-ui/core';
import ErrorSKUTable from './ErrorSKUTable';

const styles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    height: '15rem',
    overflow: 'auto'
  }
});

const ErrorSKUList = props => {
  const { errors } = props;
  const classes = styles();
  const chunkSkuList = (array, size) => {
    if (array.length <= size) {
      return [array];
    }
    return [array.slice(0, size), ...chunkSkuList(array.slice(size), size)];
  };

  const getChunkSize = () => {
    if (errors?.length % 3 === 2) {
      const size = errors?.length - 2;
      return size;
    } else {
      const size = errors?.length - 1;
      return size;
    }
  };
  const renderSKUList = () => {
    if (errors?.length <= 3) {
      return <ErrorSKUTable data={errors} />;
    } else if (errors?.length <= 6) {
      const data = chunkSkuList(errors, 3);
      return data?.map((sku, i) => <ErrorSKUTable data={sku} key={i} />);
    } else {
      const chunkSize =
        errors?.length % 3 !== 0 ? getChunkSize() : errors?.length;
      const data = chunkSkuList(errors, chunkSize / 3);
      if (data.length > 3) {
        const lastElement = data[data.length - 1];
        lastElement.map((ele, i) => {
          return data[i].push(ele);
        });
        data.splice(data.length - 1, 1);
      }
      return data?.map((sku, i) => <ErrorSKUTable data={sku} key={i} />);
    }
  };
  return <div className={classes.root}>{renderSKUList()}</div>;
};

export default ErrorSKUList;
