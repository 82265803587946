/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';

import { getStoreHealthReportDownload } from '../../../services/TenantReports/StoreHealthDashboard.service';
import Filter from './Filter';
import StoreHealthReportDownloadTable from './StoreHealthTable';
import moment from 'moment';

export const filterInitialState = {
  startDate: moment()
    .subtract(1, 'month')
    .format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  businessUnitName: ''
};

const StoreHealthDashboard = () => {
  const [filters, setFilters] = useState(filterInitialState);
  const [jobId, setJobId] = useState(0);
  const [reportPage, setReportPage] = useState(0);
  const [reportOffset, setReportOffset] = useState(25);
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [generatedReport, setGeneratedReport] = useState([]);

  useEffect(() => {
    setIsReportLoading(true);
    getStoreHealthReportDownload({
      page: reportPage,
      offset: reportOffset
    })
      .then(data => {
        setGeneratedReport(data);
        setIsReportLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsReportLoading(false);
      });
  }, [reportPage, reportOffset, jobId]);

  return (
    <div style={{ padding: '2rem' }}>
      <div>
        <h4>Store Health Report</h4>
      </div>
      <Filter
        filters={filters}
        setFilters={setFilters}
        setJobId={setJobId}
        isReportLoading={isReportLoading}
        setIsReportLoading={setIsReportLoading}
      />
      <StoreHealthReportDownloadTable
        page={reportPage}
        setPage={setReportPage}
        isLoading={isReportLoading}
        reports={generatedReport}
        offset={reportOffset}
        setOffset={setReportOffset}
      />
    </div>
  );
};

export default StoreHealthDashboard;
