import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

export default function TabLabel(props) {
  const { label, source, isActive } = props;
  return (
    <div className="tabs zoomOnHover">
      <img src={source} style={{ paddingRight: 10 }} alt={source} />
      <div className={isActive ? 'text' : 'textInActive'}>{label}</div>
    </div>
  );
}

TabLabel.propTypes = {
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  source: PropTypes.string.isRequired
};
