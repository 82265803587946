import { Breadcrumbs, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React from 'react';

import config from '../../config';
import styles from './SettingBreadCrumbs.module.scss';

const SettingsBreadCrumbs = ({
  fromModuleName,
  currentModuleName,
  isModule
}) => {
  const src = new URL(
    `${config.dashboard}/settings${isModule ? `/modules` : ''}`
  );
  const params = new URLSearchParams(window.location.search);
  params.forEach(
    (value, key) =>
      key === 'bu' &&
      !src.searchParams.has(key) &&
      src.searchParams.append(key, value)
  );
  src.searchParams.append('ref', 'v1');
  return (
    <div id={styles.settingsBreadcrumbs}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <a
          color="inherit"
          href={src.href}
          target="_parent"
          className={styles.link}
        >
          {fromModuleName}
        </a>
        <Typography color="textPrimary" className={styles.link}>
          {currentModuleName || ''}
        </Typography>
      </Breadcrumbs>
    </div>
  );
};
export default SettingsBreadCrumbs;
