/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useRef, useEffect } from 'react';
import RoleAssignBanner from '../Assets/images/role_assign_image.png';
import RoleAssignStyles from './RoleAssign.module.scss';
import BreadCrumbs from './Component/BreadCrumbs';
import RoleActionButtons from './Component/RoleActionButtons';
import SelectCategory from './Component/SelectCategory';
import ExportActionButton from './Component/ExportActionButton';
import RoleAssignTable from './Table/RoleAssignTable';
import { businessUnitSelecttems } from './constant/index';
import { useTable } from 'react-table';
import Popup from './Component/Popup';
import {
  getUserRoles,
  updateSwitchToggle
} from '../../services/roleAssign.service';
import { useBeforeunload } from 'react-beforeunload';
import { totalColumns } from './constant';
import { manualHeaderId } from './constant';

function RoleAssign2() {
  const [showPopUp, setShowPopUp] = React.useState(false);
  const [csvData, setCsvData] = React.useState([]);
  const [value, setValue] = React.useState({
    moduleList: [],
    currentModule: ''
  });
  const [featureAccess, setFeatureAccess] = React.useState({
    saveButtonAccess: true,
    otherAccess: false
  });
  const [roleColumnName, setRoleColumnName] = React.useState({
    roleName: []
  });
  const [featureListItem, setFeatureListItem] = React.useState({ feature: [] });
  const [permissionData, setPermissionData] = React.useState({
    permissionDataList: []
  });

  const [emptyList, setEmptyList] = React.useState({ emptyListData: [] });
  const [newRole, setNewRole] = React.useState({
    role: '',
    error: ''
  });

  const makeSort = data => {
    data.sort((a, b) => {
      return a.id - b.id;
    });

    return data;
  };

  const updateHeader = (data, currentModule) => {
    const permissionFeature = data[0].permissions[currentModule];
    const feature = [
      ...permissionFeature.enabled,
      ...permissionFeature.disabled
    ];

    const featureList = feature.map((value, index) => {
      const id = index + 1;
      return { id: id, Header: value };
    });

    const HeaderFeatureList = [
      { id: manualHeaderId, Header: 'Feature Roles', accessor: 'name' },
      ...featureList
    ];

    setFeatureListItem({
      feature: [...HeaderFeatureList]
    });

    return HeaderFeatureList.length;
  };

  const updatePermissionData = res => {
    const permissionList = res.map(value => {
      return {
        id: value.id,
        roleName: value.name,
        permissions: value.permissions
      };
    });

    const clonedPermissionList = JSON.parse(JSON.stringify(permissionList));

    setPermissionData({
      permissionDataList: clonedPermissionList
    });
  };

  useBeforeunload(event => {
    if (!featureAccess.saveButtonAccess) {
      event.preventDefault();
    }
  });

  useEffect(() => {
    getUserRoles().then(res => {
      // moduleList array contains the list of module list for the select component
      const moduleList = Object.keys(res[0].permissions);
      const currentModule = moduleList[0];

      const sortedRes = makeSort([...res]);
      updateHeader(res, currentModule);
      updatePermissionData(sortedRes);
      setValue({
        moduleList: [...moduleList],
        currentModule: currentModule
      });
    });
  }, []);

  useEffect(() => {
    if (value.currentModule) {
      const roleName = permissionData.permissionDataList.map(value => {
        return { name: value.roleName };
      });

      const headerLength = updateHeader(
        permissionData.permissionDataList,
        value.currentModule
      );

      // condition to render the empty cell in the table
      const empty = [];
      if (headerLength < totalColumns) {
        for (let i = headerLength; i < totalColumns; i++) {
          empty.push({ Header: 'empty' });
        }
      }

      setEmptyList({
        emptyListData: empty
      });

      setRoleColumnName({
        roleName: [...roleName]
      });
    }
  }, [value.currentModule]);

  const csvLink = useRef();
  const columns = useMemo(() => featureListItem.feature, [
    featureListItem.feature
  ]);
  const data = useMemo(() => roleColumnName.roleName, [
    roleColumnName.roleName
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  });

  const visiblePopUp = () => {
    setShowPopUp(true);
  };

  const hidePopUp = () => {
    setShowPopUp(false);
  };

  // function to download csv file
  const exportCsv = async () => {
    const featureList = featureListItem.feature.filter(value => {
      return value.id !== manualHeaderId;
    });

    const csvFeatureList = featureList.map(value => {
      return value.Header;
    });

    const csvHeader = ['Feature / Role', ...csvFeatureList];
    const csvBody = roleColumnName.roleName.map((role, index) => {
      const status = csvFeatureList.map(feature => {
        return permissionData.permissionDataList[index].permissions[
          value.currentModule
        ].enabled.includes(feature)
          ? 'Active'
          : 'Inactive';
      });

      return [role.name, ...status];
    });

    const data = [...[csvHeader], ...csvBody];
    await setCsvData(data);
    csvLink.current.link.click();
  };

  const changeCurrentModule = e => {
    setValue({
      ...value,
      currentModule: e.target.value
    });
  };

  const changeSwitchState = (role, feature) => {
    const clonedData = permissionData.permissionDataList.map(value => {
      return value;
    });

    const userIndex = clonedData.findIndex(value => {
      return value.roleName === role;
    });

    const permissionList =
      clonedData[userIndex].permissions[value.currentModule];
    const switchState = permissionList.enabled.includes(feature);
    const deleteFeature = switchState ? 'enabled' : 'disabled';
    const addFeature = switchState ? 'disabled' : 'enabled';
    const index = permissionList[deleteFeature].findIndex(value => {
      return value === feature;
    });
    permissionList[deleteFeature].splice(index, 1);
    permissionList[addFeature].push(feature);

    setFeatureAccess({
      saveButtonAccess: false,
      otherAccess: true
    });

    setPermissionData({
      permissionDataList: [...clonedData]
    });
  };

  const handleChange = newRoleName => {
    setNewRole({
      error: '',
      role: newRoleName
    });
  };

  const validation = () => {
    const newRoleName = newRole.role;

    if (newRoleName === '') {
      setNewRole({
        role: '',
        error: 'Role Name cannot be empty'
      });
      return false;
    }

    const checkRole = roleColumnName.roleName.some(value => {
      return value.name === newRoleName && value.id !== 888;
    });

    if (checkRole) {
      setNewRole({
        role: '',
        error: 'Role Name is already available'
      });
      return false;
    }

    return true;
  };

  const savePermissions = () => {
    updateSwitchToggle(permissionData.permissionDataList).then(() => {
      getUserRoles().then(res => {
        const sortedRes = makeSort([...res]);
        updatePermissionData(sortedRes);
        setFeatureAccess({
          saveButtonAccess: true,
          otherAccess: false
        });
      });
    });
  };

  const settingPermissionListValue = permissionList => {
    const permissions = permissionList[0].permissions;
    value.moduleList.forEach(value => {
      permissions[value].disabled = [
        ...permissions[value].enabled,
        ...permissions[value].disabled
      ];
      permissions[value].enabled = [];
    });

    return [{ roleName: newRole.role, permissions: permissions }];
  };

  const createNewRole = () => {
    const isValid = validation();
    if (isValid) {
      const permissionListValue = settingPermissionListValue(
        permissionData.permissionDataList
      );

      updateSwitchToggle(permissionListValue).then(() => {
        getUserRoles().then(res => {
          const sortedRes = makeSort([...res]);

          const roleName = sortedRes.map(value => {
            return { name: value.name };
          });
          updatePermissionData(sortedRes);
          hidePopUp();

          setRoleColumnName({
            roleName: [...roleName]
          });
        });
      });
    }
  };

  return (
    <>
      <div>
        <img
          src={RoleAssignBanner}
          alt=""
          className={RoleAssignStyles.roleAssignBanner}
        />
        <div className={RoleAssignStyles.roleBannerText}>Role Management</div>
      </div>
      <div className={RoleAssignStyles.roleBreadcrumbs}>
        <BreadCrumbs />
      </div>
      <div className={RoleAssignStyles.mainContainer}>
        <div className={RoleAssignStyles.roleActionButtonContainer}>
          <RoleActionButtons visiblePopUp={visiblePopUp} />
          <SelectCategory
            type="mainCategory"
            items={businessUnitSelecttems}
            selectedValue="Business Unit"
          />
        </div>
        <div className={RoleAssignStyles.roleAssignTableContainer}>
          <div className={RoleAssignStyles.roleAssignTableButton}>
            <SelectCategory
              type="subCategory"
              items={value.moduleList}
              changeCurrentModule={changeCurrentModule}
              selectedValue={value.currentModule}
              access={featureAccess.otherAccess}
            />
            <div style={{ display: 'flex' }}>
              <ExportActionButton
                featureAccess={featureAccess}
                savePermissions={savePermissions}
                exportCsv={exportCsv}
                csvData={csvData}
                csvLink={csvLink}
                value={value}
              />
            </div>
          </div>
          <div className={RoleAssignStyles.tableContainer}>
            {permissionData.permissionDataList.length > 0 && (
              <RoleAssignTable
                getTableProps={getTableProps}
                getTableBodyProps={getTableBodyProps}
                headerGroups={headerGroups}
                rows={rows}
                prepareRow={prepareRow}
                emptyList={emptyList}
                permissionData={permissionData}
                value={value}
                changeSwitchState={changeSwitchState}
              />
            )}
          </div>
        </div>
      </div>

      {showPopUp && (
        <Popup
          hidePopUp={hidePopUp}
          popUp={showPopUp}
          currentModule={value.currentModule}
          data={newRole.role}
          error={newRole.error}
          handleChange={handleChange}
          createNewRole={createNewRole}
        />
      )}
    </>
  );
}

export default RoleAssign2;
