/* eslint-disable @typescript-eslint/explicit-function-return-type */
import _ from 'lodash';
const onEdit = {
  menuName: name => name.replace(/[^a-zA-Z\s]/g, '')
};

export function onEditAllowed(key, value, defaultValue) {
  if (_.has(onEdit, key) && value.length <= 20) {
    return onEdit[key](value.toString());
  }
  return defaultValue;
}
