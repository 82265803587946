import React from 'react';
import { FormControl, Select, MenuItem } from '@material-ui/core';

const SelectField = props => {
  const { data } = props;
  return (
    <FormControl fullWidth variant="outlined">
      <Select
        style={{ height: '48px' }}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {data.map((list, index) => (
          <MenuItem value={list} key={index}>
            {list}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectField;
