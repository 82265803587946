import config from '../../config/index';
import moment from 'moment';
import LiabilitySummaryReportDownloadInterface from '../../models/TenantReports/LiabilitySummaryReportDownload';
import { takeRight } from 'lodash';

export const getLiabilitySummaryReport = (filtersData: any) => {
  return fetch(
    `${config.ruleServerUrl}/users/reports/download/liability_summary_report/${
      filtersData.businessUnitId
    }?startDate=${moment(filtersData.startDate).format(
      'YYYY-MM-DD'
    )}&endDate=${moment(filtersData.endDate).format('YYYY-MM-DD')}&type=${
      filtersData.type
    }
      `,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
};

export const getLiabilityReportReportDownload = ({
  page,
  offset,
  filters
}: {
  page: number;
  offset: number;
  filters?: any;
}): Promise<LiabilitySummaryReportDownloadInterface[]> => {
  const body = {
    pagination: {
      offset: offset,
      page: page
    },
    filters
  };
  console.log(filters);
  return fetch(
    `${config.ruleServerUrl}/users/reports/view/downloads/liability_summary_report`,
    {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json'
      }
    }
  ).then(res => res.json());
};

export const downloadLiabilityReportFile = (url: string, fileName: string) => {
  const indexOfReportUrl = url.indexOf('/report') + 1;
  const absPath = url.slice(indexOfReportUrl);
  /*
   * splittedPaths will have each folder/endpoint as an element.
   * First element will always be REPORT
   * Second element will be TenantId, TenantName, BuID
   * Followed N elements/endpoints representing corresponding path of destiny file
   * */
  const splittedPaths = absPath.split('/');
  const fileEndpoints = takeRight(splittedPaths, splittedPaths.length - 2);
  const file = fileEndpoints.join('/');
  const directory = splittedPaths[1];
  const data = { file, directory };
  const searchParams = new URLSearchParams(Object.entries(data)).toString();

  return fetch(
    `${config.ruleServerUrl}/users/download-file/report?${searchParams}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }
  )
    .then(res => {
      if (res.status !== 200) {
        throw new Error('Something went wrong!');
      }
      return res.blob();
    })
    .then(blob => {
      const file = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = file;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    })
    .catch(e => {
      throw e;
    });
};
