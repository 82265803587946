import React, { FC, useState } from 'react';
import usePagination from '../../../shared/hooks/usePagination';
import RFMReportFilter from './RFMReportFilter';
import RFMReportDownloadTable from './RFMReportDownloadTable';
import { getRfmReportDownload } from '../../../services/TenantReports/RfmReport.service';

const RFMReport: FC = () => {
  const [refreshView, setRefreshView] = useState<string>('');
  const columnValue = 'Default';
  const { data, isLoading, page, offset, setPage, setOffset } = usePagination(
    getRfmReportDownload,
    [refreshView],
    columnValue
  );
  return (
    <div>
      <RFMReportFilter setRefreshView={setRefreshView} />
      <RFMReportDownloadTable
        reports={data || []}
        isLoading={isLoading}
        page={page}
        offset={offset}
        setPage={setPage}
        setOffset={setOffset}
      />
    </div>
  );
};

export default RFMReport;
