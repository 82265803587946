import _, { find, get, has, isEmpty, map, noop } from 'lodash';
import React, { Context, createContext, useEffect } from 'react';

import { TenantConfigTypes } from '../../../constants/tenantConfigTypes';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { Business } from '../../../models/User.model';
import ConfigService from '../../../services/config.service';
import Loader from '../../Campaign/shared/components/Loader/Loader';
import SectionField from '../../DynamicInvoiceGenerator/models/SectionField';
import InitialSectionState from '../modals/InititalSectionState';
import { getProcessedConfigs, getProcessedTenantInfoConfig } from '../shared/config.service';
import constants, { DEFAULT_SECTION_TYPE } from '../shared/constants';
import { getInvoiceConfigs, upsertRequestHandler } from '../shared/invoice.service';
import baseConfigs, { AdditionalConfigFields, SectionConfig } from '../utils/configs';
import sectionUtils from '../utils/sectionUtils';

import type Section from "../../DynamicInvoiceGenerator/models/Section";
type setConfigFnType = (type: string, fields: InitialSectionState) => void;
type getConfigType = (type: string) => Section & { additionalFields: SectionField[] };

type InvoiceContextI = {
  getConfig: getConfigType;
  setConfig: setConfigFnType;
  saveConfig: () => void;
  getPreviewConfig: () => any;
  handleBusinessChange: (e: any) => void;
  getBusinesses: () => any;
  getSelectedBusiness: () => any;
  loading: boolean;
  error: string;
  updateHeader: (type: string, val: string) => void;
};



const initialState = {
  getConfig: (t: string): Section & { additionalFields: SectionField[] } => {
    const sectionConfig = get(baseConfigs.sectionConfig, DEFAULT_SECTION_TYPE)
    const additionalFields = get(baseConfigs.additionalConfigFields, DEFAULT_SECTION_TYPE)
    return { ...sectionConfig, additionalFields }
  },
  setConfig: (t: string, fields: InitialSectionState) => { },
  saveConfig: noop,
  getPreviewConfig: noop,
  handleBusinessChange: noop,
  getBusinesses: () => undefined,
  getSelectedBusiness: () => undefined,
  loading: true,
  error: "",
  updateHeader: noop
};

export const InvoiceContext: Context<InvoiceContextI> = createContext(initialState);
export const InvoiceProvider = InvoiceContext.Provider;

const InvoiceContextProvider: React.FC = ({ children }) => {
  const user = useCurrentUser()

  const [configs, setConfigs] = React.useState<SectionConfig>({});
  const [extraFields, setExtraFields] = React.useState<AdditionalConfigFields>({});
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string>("");

  const [selectedBusiness, setSelectedBusiness] = React.useState<
    Business | undefined
  >(new Business());

  const handleBusinessChange = (event: any): void => {
    setLoading(true);
    const selectedBusiness = find(user.getBusinesses(), {
      id: event.target.value
    });
    setSelectedBusiness(selectedBusiness);
  };

  const getBusinesses = () => user.getBusinesses()
  const getSelectedBusiness = () => selectedBusiness

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsBuId = _.parseInt(urlParams.get('bu') as any);
    const paramsBusiness = user.getBusinesses().find(
      b => b.id === paramsBuId
    );
    if (paramsBusiness) setSelectedBusiness(paramsBusiness);
  }, [user]);

  useEffect(() => {
    if (selectedBusiness && has(selectedBusiness, "id")) {
      try {
        (async (): Promise<void> => {
          const [tenantConfigs, additionalConfig] = await getInvoiceConfigs(selectedBusiness.id)
          setLoading(() => {
            setConfigs(tenantConfigs)
            setExtraFields(additionalConfig)
            return false
          });
        })()
      } catch (err) {
        setError((err as Error).message || "Something went wrong")
      }
    }
    // eslint-disable-next-line
  }, [selectedBusiness, loading])

  function getConfig(type: string) {
    const sectionConfig = get(configs, type)
    const additionalFields = get(extraFields, type)
    return { ...sectionConfig, additionalFields };
  }

  const setConfig: setConfigFnType = (type, inputFields) => {
    const alteredFields = sectionUtils.transformFields(inputFields);
    let additionalFields = get(baseConfigs.additionalConfigFields, type, [])
    additionalFields = map(additionalFields, field => {
      const { getter } = field;
      return {
        ...field,
        ...inputFields[getter],
      }
    })

    setConfigs((prev) => ({
      ...prev,
      [type]: { ...prev[type], fields: alteredFields },
    }));

    setExtraFields((prev) => ({
      ...prev,
      [type]: additionalFields,
    }));
    console.log("CONTEXT AFTER CHANGE", configs[type], extraFields[type])
  };

  const updateHeader = (type: string, headerValue: string) => {
    if(isEmpty(headerValue))
      return alert("Please Enter a valid header");
    
    setConfigs((prev) => ({
      ...prev,
      [type]: { ...prev[type], heading: headerValue },
    }))
    console.log("CONTEXT AFTER CHANGE", configs[type], type, headerValue);
  }

  const saveConfig = async () => {
    try {
      await upsertRequestHandler(get(user, "tenant.esKey"))
      const processedConfigs = await getProcessedTenantInfoConfig(configs)
      const finalConfigs = getProcessedConfigs(processedConfigs, extraFields);
      const businessUnitId = get(selectedBusiness, "id");
      await ConfigService.saveConfigDetails(TenantConfigTypes.InvoiceGenerator, { businessUnitId }, finalConfigs);
      alert("config Updated!!")
    } catch (error) {
      alert("Error While Saving!!")
    }
  };

  const getPreviewConfig = () => {
    const invoiceConfig = getProcessedConfigs(configs, extraFields);
    const bill = constants.standardBill
    const tenant = get(user, "tenant.esKey");
    return { bill, invoiceConfig, tenant }
  }

  const isConfigLoaded = !isEmpty(configs)
  if (loading || !isConfigLoaded)
    return <Loader />

  return (
    <InvoiceProvider
      value={{
        getConfig,
        setConfig,
        saveConfig,
        getPreviewConfig,
        handleBusinessChange,
        getBusinesses,
        getSelectedBusiness,
        loading,
        error,
        updateHeader
      }}
    >
      {children}
    </InvoiceProvider>
  );
};

export default InvoiceContextProvider;
