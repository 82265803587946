import React from 'react';
import moment from 'moment';
import { Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import fonts from '../../assets/Fonts';
import Warranty from './warranty';
import { GetBarCode } from '../utils';
import Disclimer from '../../assets/disclimer.png';
import RupeeFit from '../../assets/rupeeFit.png';
import {
  getSplitDescription,
  truncatedNumberFloat,
  truncateString
} from '../../../../utils';
import _ from 'lodash';
import RupeeWhite from '../../assets/rupeeWhite.png';
import { getSavePrice } from '../utils/PriceCalculation';
import cross from '../../assets/cross.png';

Font.register(fonts);

const styles = StyleSheet.create({
  container: {
    fontFamily: 'OpenSans',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  card: {
    margin: '2mm 1mm',
    height: '144.5mm',
    width: '102.66mm',
    border: '1 solid #000000',
    display: 'flex'
  },
  qrwithname: {
    display: 'flex',
    flexDirection: 'row',
    height: '130px',
    borderBottom: '1 solid #000000'
  },
  qrcode: {
    width: '128px',
    height: '48.8mm',
    padding: '2mm 1mm'
  },
  brandName: {
    fontWeight: 'bold'
  },
  productname: {
    fontSize: '10.76pt',
    color: 'black',
    width: '163px',
    padding: '5mm 3mm 0 3mm',
    borderLeft: '1 solid #000000',
    display: 'flex',
    flexDirection: 'column'
  },
  descriptionblock: {
    fontWeight: 'normal',
    height: '70px',
    width: '102mm',
    display: 'flex',
    fontSize: '8.78pt',
    flexDirection: 'row',
    borderBottom: '1 solid #000000',
    justifyContent: 'space-between'
  },
  description: {
    padding: '3mm 2mm',
    width: '143px'
  },
  saveblock: {
    width: '143px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderLeft: '1 solid #000000'
  },
  deals: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  mrpText: {
    fontSize: '18.98pt',
    color: 'black',
    fontWeight: 'bold'
  },
  mrp: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  rupeeSmall: {
    width: '3.8mm',
    height: '4.58mm',
    top: 7.28
  },
  lineHeight: { height: '5.5mm' },
  priceBlock: {
    borderBottom: '1 solid #000000',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '98px'
  },
  rupeeicon: {
    fontSize: '14.3pt',
    width: '4mm',
    paddingTop: '5mm'
  },
  price: {
    fontSize: '68.1',
    textAlign: 'left',
    fontWeight: 'bold',
    color: 'black',
    paddingLeft: '1mm',
    top: -10,
    lineHeight: 1
  },
  inclTax: {
    fontSize: '13.6pt',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  // Footer
  footer: {
    padding: '2mm'
  },
  warrantyWithBarcode: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  warrantyText: {
    fontSize: '6.64pt',
    color: '#1A1818',
    opacity: '1'
  },
  itemDetails: {
    fontSize: '5.84pt',
    top: 8
  },
  itemCode: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  disclimerIcon: {
    width: '3.75mm',
    height: '3.75mm',
    bottom: 1,
    left: 5
  },
  warrantyContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  warranty: {
    height: '14.7mm',
    width: '14.7mm',
    margin: '1mm'
  },
  warrantyDuriation: {
    position: 'absolute',
    height: '9.5mm',
    width: '9.5mm',
    fontWeight: 'bold',
    margin: '3mm',
    backgroundColor: '#fff',
    borderRadius: '50',
    alignItems: 'center',
    justifyContent: 'center'
  },
  warrantyValue: { fontSize: '19.12pt', left: -2 },
  warrantyYear: { fontSize: '4.64pt', top: -5, left: -1 },
  barcode: {
    width: '44.7mm',
    height: '14.2mm',
    padding: '1mm 0mm 0mm 0mm',
    left: 2,
    top: 6
  },
  savetext: {
    width: '142px',
    height: '24px',
    backgroundColor: '#818386',
    borderBottom: '1 solid #000000'
  },
  saveprice: {
    width: '142px',
    height: '46px',
    backgroundColor: '#1A1818',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  }
});

const getA6Layout = (count, data) => {
  return count.map(id => (
    <View key={id}>
      {data?.map(item => (
        <View style={styles.card} key={item?.id}>
          <View style={styles.qrwithname}>
            <View style={styles.qrcode}>
              <Image
                src={item.image}
                style={{ width: '113px', height: '113px', margin: '4 px' }}
              />
            </View>
            <View style={styles.productname}>
              <Text>
                {truncateString(item?.product?.masterData?.name, 130)}
              </Text>
            </View>
          </View>
          <View
            style={{
              borderBottom: '1 solid #000000',
              height: '27px',
              width: '291px',
              left: -3
            }}
          >
            <View style={styles.deals}>
              <Text style={styles.mrpText}>{`${item?.PriceTag}: `}</Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <Image style={styles?.rupeeSmall} src={RupeeFit} />
                <View>
                  <View
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <Image
                      src={cross}
                      style={{
                        width: '110%',
                        height: '80%',
                        top: 1
                      }}
                    />
                  </View>
                  <Text style={{ fontSize: '18.98pt' }}>
                    {truncatedNumberFloat(item?.product?.mrp, 6)}
                  </Text>
                </View>
              </View>
              <Text
                style={{
                  fontSize: '10pt'
                }}
              >
                *
              </Text>
            </View>
          </View>
          <View style={styles.priceBlock}>
            <Text style={styles.rupeeicon}>
              <Image src={RupeeFit} />
            </Text>
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={styles.price}>
                  {truncatedNumberFloat(item?.product?.mop, 6)}
                </Text>
                <Text
                  style={{
                    fontSize: '12.8pt',
                    top: 7,
                    fontWeight: 'bold'
                  }}
                >
                  *
                </Text>
              </View>
              <View style={styles.inclTax}>
                <Text
                  style={{
                    fontSize: '12.8pt',
                    top: 1,
                    fontWeight: 'bold'
                  }}
                >
                  *
                </Text>
                <Text style={{ fontSize: '20pt' }}>(Incl. Taxes)</Text>
              </View>
            </View>
          </View>
          <View style={styles.descriptionblock}>
            <View style={styles.description}>
              <View style={{ fontSize: '7.4pt', lineHeight: 2 }}>
                {getSplitDescription(item.description, 4).map((line, index) => (
                  <Text key={index}>+ {truncateString(line, 32)}</Text>
                ))}
              </View>
            </View>
            <View style={styles.saveblock}>
              <View style={styles.savetext}>
                <Text
                  style={{
                    color: 'white',
                    fontSize: '16pt',
                    textAlign: 'center',
                    fontWeight: 'bold'
                  }}
                >
                  SAVE
                </Text>
              </View>
              <View style={styles.saveprice}>
                {getSavePrice(item?.product?.mrp, item?.product?.mop) === 0 ? (
                  <Text></Text>
                ) : (
                  <>
                    <Image
                      src={RupeeWhite}
                      style={{
                        top: 5.5,
                        width: '10px',
                        height: '24px'
                      }}
                    />
                    <Text
                      style={{
                        fontSize: '33pt',
                        textAlign: 'left',
                        height: '46px',
                        color: 'white',
                        fontWeight: 'bold'
                      }}
                    >
                      {getSavePrice(item?.product?.mrp, item?.product?.mop)}
                    </Text>
                  </>
                )}
              </View>
            </View>
          </View>
          <View style={styles?.footer}>
            <View style={styles.warrantyWithBarcode}>
              <View style={styles.warrantyContainer}>
                <Warranty warranty={item.warranty} styles={styles} />
              </View>
              <View>
                <View style={styles.barcode}>
                  {_.isEmpty(item.barcode) ? (
                    <></>
                  ) : (
                    <Image src={GetBarCode(item?.barcode)} />
                  )}
                </View>
              </View>
            </View>
            <View
              style={
                item?.warranty
                  ? styles?.itemDetails
                  : { ...styles?.itemDetails, top: 24 }
              }
            >
              <View style={styles.itemCode}>
                <Text>
                  {`${item?.sku}  |  ${item.class}  |  A  |  ${moment(
                    item?.date
                  ).format('DD.MM.YYYY')}  |  ${moment().format('DD.MM.YYYY')}`}
                </Text>
                <Image style={styles.disclimerIcon} src={Disclimer} />
              </View>
            </View>
          </View>
        </View>
      ))}
    </View>
  ));
};

const A6PortraitV2 = ({ data, count, ...rest }) => {
  const splattedArray = _.chunk(data, 4);
  return (
    <Page>
      <View style={styles.container}>
        {count &&
          _.map(splattedArray, (chunkedData, key) => {
            const chunkedRowArray = _.chunk(chunkedData, 2);
            const firstHalf = getA6Layout(count, chunkedRowArray[0]);
            const secondHalf = getA6Layout(count, chunkedRowArray[1]);
            return (
              <View
                style={{ flexDirection: 'row', flexWrap: 'wrap' }}
                key={key}
              >
                <View>{firstHalf}</View>
                <View>{secondHalf}</View>
              </View>
            );
          })}
      </View>
    </Page>
  );
};
export { A6PortraitV2 };
