/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import classes from './Node.module.css';

const SavedIcon = () => {
  return (
    <Tooltip title="All details have beed saved">
      <div
        style={{
          background: '#fff',
          marginLeft: '20px'
        }}
      >
        <svg height="30px" viewBox="0 0 20 20" width="1em">
          <path
            d="M5.9 8.1L4.5 9.5 9 14 19 4l-1.4-1.4L9 11.2 5.9 8.1zM18 10c0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8c.8 0 1.5.1 2.2.3L13.8.7C12.6.3 11.3 0 10 0 4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10h-2z"
            fill="green"
            fillRule="evenodd"
          />
        </svg>
      </div>
    </Tooltip>
  );
};

const NotSavedIcon = () => {
  return (
    <Tooltip title="Please provide all the details needed">
      <div
        style={{
          background: '#fff',
          marginLeft: '20px'
        }}
      >
        <svg
          viewBox="0 0 50 50"
          style={{
            enableBackground: 'new 0 0 50 50'
          }}
          xmlSpace="preserve"
          width="1.2em"
          height="1.2em"
        >
          <circle
            style={{
              fill: '#efce4a'
            }}
            cx={25}
            cy={25}
            r={25}
          />
          <path
            style={{
              fill: 'none',
              stroke: '#fff',
              strokeWidth: 2,
              strokeLinecap: 'round',
              strokeMiterlimit: 10
            }}
            d="M25 10v22M25 37v2"
          />
        </svg>
      </div>
    </Tooltip>
  );
};

export const Node = ({
  name,
  IconComponent,
  selected = false,
  onClick = {},
  isBorder = false,
  isDetailsSaved
}) => {
  return (
    <div
      onClick={onClick}
      className={classes.node}
      style={selected ? {} : { cursor: 'pointer' }}
    >
      <IconComponent isBorder={isBorder} selected={selected} />
      <div className={classes.nodeText}>{name}</div>
      {isDetailsSaved ? <SavedIcon /> : <NotSavedIcon />}
    </div>
  );
};
