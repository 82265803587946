import React, { FC } from 'react';
import ReactPlayer from 'react-player';
import './VideoLayout.css';

interface VideoLayoutProps {
  VideoData: any;
  classNames: string;
}

export const VideoLayout: FC<VideoLayoutProps> = ({
  VideoData,
  classNames
}) => {
  return (
    <div className={`flipbook-video-layout-container elevate ${classNames}`}>
      <div className={'player-overlay'}></div>
      <ReactPlayer
        url={VideoData?.URL}
        key={`${VideoData?.URL}_${VideoData?.isControlsEnabled}_${VideoData?.isMuted}_${VideoData?.isAutoPlay}`}
        controls={VideoData?.isControlsEnabled}
        muted={VideoData?.isMuted}
        playing={VideoData?.isAutoPlay}
        width={'100%'}
        height={'100%'}
      />
    </div>
  );
};
