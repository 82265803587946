/* eslint-disable @typescript-eslint/explicit-function-return-type */
import NodeTypes from '../BaseNodeModel/NodeTypes';
import { TriggerNodeModel } from '../TriggerNode/TriggerNodeModel';
import { TimedNodeModel } from '../TimedNode/TimedNodeModel';
import { ActionNodeModel } from '../ActionNode/ActionNodeModel';
import { DecisionNodeModel } from '../DecisionNode/DecisionNodeModel';
import { StopNodeModel } from '../StopNode/StopNodeModel';
import { WaitNodeModel } from '../WaitNode/WaitNodeModel';

const typeToNodeMapper = {
  [NodeTypes.TRIGGER_NODE]: TriggerNodeModel,
  [NodeTypes.TIMED_NODE]: TimedNodeModel,
  [NodeTypes.DECISION_NODE]: DecisionNodeModel,
  [NodeTypes.STOP_NODE]: StopNodeModel,
  [NodeTypes.WAIT_NODE]: WaitNodeModel,
  [NodeTypes.ACTION_NODE]: ActionNodeModel
};

const createNode = data => {
  return new typeToNodeMapper[data.type](data);
};

export const onDropNode = (event, diagramEngine, forceUpdate) => {
  const point = diagramEngine.getRelativeMousePoint(event);
  const data = JSON.parse(event.dataTransfer.getData('transfer-to-node'));
  const node = createNode(data);
  node.setPosition(point);
  diagramEngine.getModel().addAll(node);
  forceUpdate();
  return node;
};
