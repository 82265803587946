import React from 'react';
import PropTypes from 'prop-types';
import TabLabel from '../TabLabel';
import shoppingCart from '../../../Assets/Offers/shoppingCart.svg';
import shoppingCartDark from '../../../Assets/Offers/shoppingCartDark.svg';
import storeFront from '../../../Assets/Offers/storeFront.svg';
import storeFrontDark from '../../../Assets/Offers/storeFrontDark.svg';
import billValue from '../../../Assets/Offers/billValue.svg';
import billValueDark from '../../../Assets/Offers/billValueDark.svg';

export default function TabSection(props) {
  const { activeTab, setActiveTab } = props;
  return (
    <div className="tabLabels">
      <div>
        <button
          className={
            activeTab === 0
              ? 'tabLabel hoverable'
              : 'tabLabelInactive hoverable'
          }
          onClick={() => setActiveTab(0)}
          style={{ borderTopLeftRadius: 10 }}
        >
          <TabLabel
            label="Bill Value"
            source={activeTab === 0 ? billValue : billValueDark}
            isActive={activeTab === 0}
          />
        </button>
      </div>
      <div>
        <button
          className={
            activeTab === 1
              ? 'tabLabel hoverable'
              : 'tabLabelInactive hoverable'
          }
          onClick={() => setActiveTab(1)}
        >
          <TabLabel
            label="Product In Cart"
            source={activeTab === 1 ? shoppingCart : shoppingCartDark}
            isActive={activeTab === 1}
          />
        </button>
      </div>
      <div>
        <button
          className={
            activeTab === 2
              ? 'tabLabel hoverable'
              : 'tabLabelInactive hoverable'
          }
          onClick={() => setActiveTab(2)}
          style={{ borderBottomLeftRadius: 10 }}
        >
          <TabLabel
            label="Stores"
            source={activeTab === 2 ? storeFront : storeFrontDark}
            isActive={activeTab === 2}
          />
        </button>
      </div>
    </div>
  );
}

TabSection.propTypes = {
  activeTab: PropTypes.number.isRequired,
  setActiveTab: PropTypes.func.isRequired
};
