/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';

const Divider = () => {
  return (
    <hr
      style={{
        gridArea: 'divider',
        border: '2px solid #ddd'
      }}
    />
  );
};

export default Divider;
