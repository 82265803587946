import React, { FC } from 'react';
import { Box, LinearProgress, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './LinearProgressWithLabel.module.scss';

interface LinearProgressWithLabelProps {
  total: number;
  currentValue: number;
}
const LinearProgressWithLabel: FC<LinearProgressWithLabelProps> = props => {
  const invalidValues = [0, null, undefined];
  const getCurrentProgress = () => {
    if (invalidValues.includes(props.total)) {
      return 100;
    }
    return (props.currentValue / props.total) * 100;
  };
  const isProgressValueValid = () => {
    if (invalidValues.includes(props.total)) {
      return false;
    }
    return props.currentValue <= props.total;
  };

  const getInvalidProgressBar = () => {
    return (
      <Box
        id={styles.linearProgressWithLabel}
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
        style={{ cursor: 'not-allowed', opacity: '50%' }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress
            className={styles.customProgressBar}
            variant="determinate"
            value={0}
            {...props}
          />
        </Box>
      </Box>
    );
  };
  const getValidProgressBar = () => {
    return (
      <Tooltip
        title={
          <h6
            style={{ paddingTop: '5px' }}
          >{`Progress ${props.currentValue}/${props.total}`}</h6>
        }
      >
        <Box
          id={styles.linearProgressWithLabel}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress
              className={styles.customProgressBar}
              variant="determinate"
              value={getCurrentProgress()}
              {...props}
            />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2">{`${Math.round(
              getCurrentProgress()
            )}%`}</Typography>
          </Box>
        </Box>
      </Tooltip>
    );
  };

  return isProgressValueValid()
    ? getValidProgressBar()
    : getInvalidProgressBar();
};

export default LinearProgressWithLabel;
