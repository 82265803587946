import React from 'react';
import { CartBucketProps } from './CartBucketProps';
import { JoinBucket, ProductBucket } from '../../models/offer/OfferRule.model';
import { CartJoinBucket } from './CartJoinBucket';
import { CartProductBucket } from './CartProductBucket';

const joinBucketRef = JoinBucket.newBucket();
const productBucketRef = ProductBucket.newBucket();

export const renderCartOfferBucket = (props: CartBucketProps) => {
  switch (props.offerBucket.className) {
    case joinBucketRef.className:
      return <CartJoinBucket {...props} />;
    case productBucketRef.className:
      return <CartProductBucket {...props} />;
    default:
      return null;
  }
};
export const CartBucket: React.FC<CartBucketProps> = ({
  cartWrapper,
  offer,
  offerBucket,
  isOdd = false,
  cardStyles,
  className = '',
  setCartWrapper
}) => {
  return renderCartOfferBucket({
    cartWrapper,
    offer,
    offerBucket,
    isOdd,
    cardStyles,
    className,
    setCartWrapper
  });
};
