import React, { FC } from 'react';
import SingleLayout from '../../SharedComponents/LayoutType/SingleLayout';
import DoubleLayout from '../../SharedComponents/LayoutType/DoubleLayout';
import {
  FlipbookLayout,
  LayoutType
} from '../../../../../models/FlipbookV2/FlipbookV2Layout.model';

interface DesktopViewProps {
  editPanelOccupiedKey: string;
  setEditPanelOccupiedKey: (key: string) => void;
  page: FlipbookLayout;
  isEditMode: boolean;
  handleModalOpen: (groupId: string) => void;
  isCustomerView: boolean;
  hasPermission: (permission: string) => boolean;
}

const DesktopView: FC<DesktopViewProps> = ({
  editPanelOccupiedKey,
  setEditPanelOccupiedKey,
  page,
  isEditMode,
  handleModalOpen,
  isCustomerView,
  hasPermission
}) => {
  return (
    <>
      {page.layoutType === LayoutType.SINGLE && (
        <SingleLayout
          editPanelOccupiedKey={editPanelOccupiedKey}
          setEditPanelOccupiedKey={setEditPanelOccupiedKey}
          page={page}
          isEditMode={isEditMode}
          handleModalOpen={handleModalOpen}
          isCustomerView={isCustomerView}
          hasPermission={hasPermission}
        />
      )}
      {page.layoutType === LayoutType.DOUBLE && (
        <DoubleLayout
          editPanelOccupiedKey={editPanelOccupiedKey}
          setEditPanelOccupiedKey={setEditPanelOccupiedKey}
          page={page}
          isEditMode={isEditMode}
          isCustomerView={isCustomerView}
          handleModalOpen={handleModalOpen}
          hasPermission={hasPermission}
        />
      )}
    </>
  );
};

export default DesktopView;
