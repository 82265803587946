import React from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText
} from '@material-ui/core';

const SelectField = props => {
  return (
    <FormControl
      fullWidth
      classes={{ root: props?.root }}
      variant="filled"
      error={props.error?.length ? true : false}
    >
      <InputLabel>{props.label || ''}</InputLabel>
      <Select value={props.value} onChange={props.onChange}>
        <MenuItem value="">Select</MenuItem>
        {props.data &&
          props.data.map((data, i) => (
            <MenuItem key={i} value={data.name}>
              {data.name}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{props.error}</FormHelperText>
    </FormControl>
  );
};

export default SelectField;
