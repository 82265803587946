/* eslint-disable @typescript-eslint/explicit-function-return-type */
import DateFnsUtils from '@date-io/date-fns';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { get } from 'lodash';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';

import useCurrentUser from '../../../hooks/useCurrentUser';
import { getFraudReport } from '../../../services/TenantReports/FraudReport.service';
import {
  AdditionalField,
  AdditionalFieldSelector
} from '../Shared/AdditionalFields/AdditionalFields';
import { ReportTypes } from '../../../models/TenantReports';

interface FraduReport {
  startDate: string;
  endDate: string;
  billValue: number;
  billCount: number;
  points: number;
  businessId?: number;
  additionalFields?: AdditionalField[];
}

const fraduReportInitialState: FraduReport = {
  startDate: moment()
    .subtract(1, 'day')
    .format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  billValue: 14999,
  billCount: 2,
  points: 299,
  businessId: undefined
};

interface ReportActionsProps {
  setRefreshView: (id: string) => void;
}

const FraudReport: FC<ReportActionsProps> = ({ setRefreshView }) => {
  const [filters, setFilters] = useState<FraduReport>(fraduReportInitialState);
  const user = useCurrentUser();
  const handleChange = (e: any) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleDateChange = (date: any, name: string) => {
    setFilters({ ...filters, [name]: date });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsBuId = +urlParams.get('bu')! || 0;

    setFilters(filters => ({ ...filters, businessId: paramsBuId }));
  }, [user]);

  const updateFilters = (additionalFields: AdditionalField[]) =>
    setFilters({ ...filters, additionalFields });

  const handleGenerateReport = (): void => {
    getFraudReport(filters).then(res => {
      const jobId = get(res, 'jobId', '');
      setRefreshView(jobId);
    });
  };
  return (
    <div style={{ margin: '30px 0', display: 'flex', gap: '1rem' }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          label="Start Date"
          value={filters.startDate}
          onChange={e => handleDateChange(e, 'startDate')}
          variant="inline"
          format="yyyy-MM-dd"
        />
        <KeyboardDatePicker
          label="End Date"
          value={filters.endDate}
          onChange={e => handleDateChange(e, 'endDate')}
          variant="inline"
          format="yyyy-MM-dd"
        />
      </MuiPickersUtilsProvider>
      <TextField
        label="Bill Value (>)"
        name="billValue"
        type={'number'}
        defaultValue="14999"
        onChange={handleChange}
      />
      <TextField
        label="Bill Count (>)"
        name="billCount"
        type={'number'}
        defaultValue="2"
        onChange={handleChange}
      />
      <TextField
        label="Points Spend (>)"
        name="points"
        type={'number'}
        defaultValue="299"
        onChange={handleChange}
      />
      <AdditionalFieldSelector
        reportType={ReportTypes.FRAUD_REPORT}
        businessId={filters.businessId}
        onChange={updateFilters}
      />
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={handleGenerateReport}
      >
        Generate Report
      </Button>
    </div>
  );
};

export default FraudReport;
