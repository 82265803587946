import React, { useEffect, useState } from 'react';
import 'react-table-v6/react-table.css';
import '../../styles/Table.scss';
import ColumnData from './components/ColumnData';
import Panel from './pages/Panel';
import CheckClaims from './pages/CheckClaims';
import _ from 'lodash';
import config from '../../config';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import moment from 'moment';

const KamExpense = props => {
  const { history } = props;
  const [page, setPage] = useState('');
  const [kamInfo, setKamInfo] = useState([]);
  const [kam, setKam] = useState({
    toDate: null,
    fromDate: null,
    kamData: [],
    kamList: [],
    typeFilter: '',
    actionFilter: '',
    individualKam: []
  });

  const getKamList = kam => {
    const kamlist = [];
    _.map(kam, list => [...kamlist, list.userName]);
    return kamlist;
  };

  const tableData = (fromDate, toDate) => {
    if (fromDate !== null && toDate !== null) {
      const querySearchParams = qs.parse(history.location.search);
      const id = querySearchParams.id;
      fetch(
        `${config.casaBeatUrl}/dalmia/managers/${id}/reportees/claim-reports?fromDate=${fromDate}&toDate=${toDate}`,
        {
          method: 'GET',
          headers: {
            authorization: `Bearer ${querySearchParams.token}`,
            'Content-Type': 'application/json'
          }
        }
      )
        .then(async response => {
          const kam = await response.json();
          const kamList = getKamList(kam.data);
          setKam({
            ...kam,
            toDate: toDate,
            fromDate: fromDate,
            kamData: kam.data,
            kamList: kamList
          });
          setKamInfo(kam.data);
        })
        .catch(err => {
          console.error(err);
        });
    }
  };

  const fetchKamData = () => {
    const toDate = moment()
      .add(1, 'day')
      .toDate();
    const fromDate = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
    const formattedFromDate = `${fromDate.getFullYear()}-${fromDate.getMonth() +
      1}-${fromDate.getDate()}`;
    const formattedToDate = `${toDate.getFullYear()}-${toDate.getMonth() +
      1}-${toDate.getDate()}`;
    const path = _.get(history, 'location.pathname').split('/');
    setPage(path[2]);
    tableData(formattedFromDate, formattedToDate);
  };

  useEffect(fetchKamData, [_.get(history, 'location.pathname')]);
  const fetchTableData = () => {
    tableData(kam.fromDate, kam.toDate);
  };
  useEffect(fetchTableData, [kam.toDate, kam.fromDate]);

  const handleDate = (key, value) => {
    if (value !== null) {
      const formattedDate = `${value.getFullYear()}-${value.getMonth() +
        1}-${value.getDate()}`;
      setKam(prevState => ({ ...prevState, [key]: formattedDate }));
    }
  };
  const formatDate = dateStr => {
    if (dateStr !== null) {
      const date = new Date(dateStr);
      return date;
    }
    return null;
  };

  const renderKam = page => {
    switch (page) {
      case 'panel':
        return (
          <Panel
            handleDate={handleDate}
            formatDate={formatDate}
            columns={ColumnData(history, kam)}
            tableData={kam.kamData || []}
            setKam={setKam}
            kam={kam}
            kamInfo={kamInfo}
            page={page}
          />
        );
      case 'claims':
        return (
          <CheckClaims
            kam={kam}
            setKam={setKam}
            kamInfo={kamInfo}
            columns={ColumnData(history, kam)}
          />
        );
      default:
        return null;
    }
  };

  return renderKam(page);
};

export default withRouter(KamExpense);
