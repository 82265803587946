import { Box, Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { debounce, map } from 'lodash';
import React, { FC } from 'react';

import CampaignFiltersType from '../../../../../models/campaign/CampaignFilters';
import Select from '../../../shared/components/Select/Select';
import styles from './CampaignFilters.module.scss';
import DateFilter from './DateFilter/DateFilter';
import LoadingButton from '../../../shared/components/LoadingButton/LoadingButton';
import { toast } from 'react-toastify';
import useMutation from '../../../../../shared/hooks/useMutation';
import { generateOverallCampaignReport } from '../../../../../services/Campaign/campaign-report.service';
import { useHistory } from 'react-router-dom';

const campaignTypeFilter = [
  { label: 'Live', value: 'live' },
  { label: 'Batch', value: 'batch' }
];

const sortOptions = [
  {
    label: 'Ascending',
    value: 'asc'
  },
  {
    label: 'Descending',
    value: 'desc'
  }
];

interface CampaignFilterProps {
  filters: CampaignFiltersType;
  campaignAvailableStatus: string[];
  onFilterChange: <T>(filter: { [key: string]: T }) => void;
}

const CampaignFilters: FC<CampaignFilterProps> = ({
  filters,
  campaignAvailableStatus,
  onFilterChange
}) => {
  const {
    mutate: onGenerateReport,
    isLoading: isGeneratingReport
  } = useMutation(
    generateOverallCampaignReport.bind(
      null,
      filters.buId.toString(),
      filters.startDate.toString(),
      filters.endDate.toString(),
      filters.search,
      filters.campaignStatus,
      filters.campaignType
    )
  );

  const getCampaignAvailableStatus = () => {
    return map(campaignAvailableStatus, cs => {
      return {
        label: cs,
        value: cs
      };
    });
  };
  const debouncedSearch = debounce((searchTerm: string) => {
    onFilterChange({ search: searchTerm });
  }, 750);

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    debouncedSearch(event.target.value);
  };
  const { location } = useHistory();

  const handleGenerateReport = (): void => {
    onGenerateReport()
      .then(() =>
        toast.success('Your report generation is started successfully.')
      )
      .catch(() => toast.error('Something went wrong.'));
  };

  const redirectToOverallReports = () => {
    if (window.self !== window.top) {
      window.parent.location.href = location.pathname + '/overall-reports';
    } else {
      window.location.href = location.pathname + '/overall-reports';
    }
  };

  const {
    search,
    campaignType,
    campaignStatus,
    startDate,
    endDate,
    sort
  } = filters;

  return (
    <div>
      <Box id={styles.campaignFilters}>
        <TextField
          className={styles.searchFormControl}
          placeholder="Search with Campaign"
          variant="outlined"
          defaultValue={search}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: <SearchIcon color="disabled" />
          }}
        />
        <DateFilter
          fromDateValue={startDate}
          toDateValue={endDate}
          onChange={onFilterChange}
        />
        <Select
          label="Campaign Status"
          name="campaignStatus"
          value={campaignStatus}
          options={getCampaignAvailableStatus()}
          onChange={onFilterChange}
          empty={{ label: 'All', value: 'all' }}
        />
        <Select
          label="Campaign Type"
          name="campaignType"
          value={campaignType}
          options={campaignTypeFilter}
          onChange={onFilterChange}
          empty={{ label: 'All', value: 'all' }}
        />
        <Select
          label="Sort By"
          name="sort"
          value={sort}
          options={sortOptions}
          onChange={onFilterChange}
          empty={{ label: 'None', value: '' }}
        />
      </Box>
      <Box id={styles.campaignDownloads}>
        <div className={styles.downloadPromptText}>
          Generate & Download Report for the Applied Filter
        </div>
        <div className={styles.campaignDownloadsCTA}>
          <LoadingButton
            variant="contained"
            color="primary"
            size="medium"
            disableElevation
            loading={isGeneratingReport}
            onClick={handleGenerateReport}
          >
            Generate Report
          </LoadingButton>
          <Button
            variant="outlined"
            color="default"
            size="medium"
            disableElevation
            onClick={redirectToOverallReports}
            className={styles.outlinedViewButton}
          >
            View Reports
          </Button>
        </div>
      </Box>
    </div>
  );
};
export default CampaignFilters;
