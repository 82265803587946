/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import * as SRD from '@projectstorm/react-diagrams';
import { useParams } from 'react-router-dom';
import {
  getJourneyEngine,
  getJourneyReports
} from '../../../services/journey-engine.service';
import { getJourneyConfig } from '../../../services/journey-config.service';
import { BodyWidget } from '../BodyWidget/BodyWidget';
import AppContext from '../context/AppContext';
import { getJourneyTemplate } from '../../../services/journey-template.service';

export default function JourneyEdit() {
  const { id } = useParams();

  const [journeyDetails, setJourneyDetails] = useState();
  const [reports, setReports] = useState({});
  const [journeyTemplate, setJourneyTemplate] = useState();
  const app = React.useContext(AppContext);
  const engine = app.getDiagramEngine();

  useEffect(() => {
    async function getConfigs() {
      const config = await getJourneyConfig(id);
      const diagramModel = new SRD.DiagramModel();
      diagramModel.deserializeModel(JSON.parse(config.source.data), engine);
      engine.setModel(diagramModel);
    }

    async function getTemplate() {
      setJourneyTemplate(JSON.parse(await getJourneyTemplate()));
    }

    async function getReports() {
      setReports(await getJourneyReports(id));
    }

    getJourneyEngine(id).then(res => {
      setJourneyDetails(_.omit(res.source, 'data'));
    });

    getReports();
    getConfigs();
    getTemplate();
  }, []);

  return !_.isEmpty(journeyDetails) && !_.isEmpty(journeyTemplate) ? (
    <div>
      <BodyWidget
        id={id}
        app={app}
        templates={journeyTemplate}
        journeyDetails={journeyDetails}
        isEdit={true}
        reports={reports}
      />
      ;
    </div>
  ) : (
    <></>
  );
}
