/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { WaitNodeModel } from './WaitNodeModel';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import NodeWidgetHOC from '../NodeWidgetHOC/NodeWidgetHOC';
import WaitNodeWidget from './WaitNodeWidget';
import NodeTypes from '../BaseNodeModel/NodeTypes';

export class WaitNodeFactory extends AbstractReactFactory {
  constructor() {
    super(NodeTypes.WAIT_NODE);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  generateModel(event) {
    return new WaitNodeModel();
  }

  generateReactWidget(event) {
    const setInputFields = ({ specs }) => event.model.setInputFields(specs);

    return (
      <NodeWidgetHOC node={event.model}>
        <WaitNodeWidget
          engine={this.engine}
          node={event.model}
          setInputFields={setInputFields}
        />
      </NodeWidgetHOC>
    );
  }
}
