export enum LiabilitySummaryReportTypes {
  STORE = 'store',
  CUSTOMER = 'customer',
  OVERALL = 'overall',
  BILL = 'bill',
  SKU = 'sku',
  EXPIRY = 'expiry'
}
export enum FileTypes {
  ZIP = 'zip',
  CSV = 'csv'
}

export default interface LiabilitySummaryReportDownloadInterface {
  id: number;
  tenantId: number;
  storeId: number;
  fileVersion: string;
  startTime: string;
  endTime: string | null;
  status: 'COMPLETED' | 'PENDING' | 'FAILED' | 'STARTED';
  total: number;
  batchCount: number;
  processedRecords: number;
  createdAt: string;
  updatedAt: string;
  errorFileLocation: string;
  data: {
    startDate: string;
    endDate: string;
    type: LiabilitySummaryReportTypes;
    fileType?: FileTypes;
    businessUnitId: number;
  };
}
