import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContentText } from '@material-ui/core';
import { ReactComponent as FailureIcon } from '../../Assets/images/failure_icon.svg';
import { ReactComponent as SuccessIcon } from '../../Assets/images/success_icon.svg';

const useStyles = makeStyles(theme => ({
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  closeIconContainerStyles: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const AlertModal = props => {
  const { state, setState } = props;

  const classes = useStyles();

  const handleClose = () => {
    setState({ flag: false });
  };

  return (
    <div>
      <Dialog open={state.flag} aria-labelledby="form-dialog-title">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle className={classes.closeIconContainerStyles}>
            {state.messageTitle}
          </DialogTitle>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div>
          <DialogContent>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '10px'
              }}
            >
              {state?.successFlag ? (
                <SuccessIcon />
              ) : (
                <FailureIcon height="50px" width="50px" />
              )}
            </div>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="text-center"
            >
              {state.message}
            </DialogContentText>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default AlertModal;
