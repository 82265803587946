/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import _ from 'lodash';
import React, { useState, useEffect } from 'react';

import useUpdateEffect from '../../../customHooks/useUpdateEffect';
import Option from '../../MultiSelect/Option';
import Search from '../../Search/Search';
import multiSelect from '../../MultiSelect/MultiSelect.module.css';
import inputRow from '../../NodeDetails/InputRow/InputRow.module.css';

const DecisionsInput = ({
  placeholder,
  values,
  onChange,
  options,
  width = '638px',
  height = '50px',
  isSearchEnabled = false
}) => {
  const getHashSet = values => {
    const hashSet = {};
    _.forEach(values, value => {
      hashSet[value] = true;
    });
    return hashSet;
  };

  // const [searchText, setSearchText] = useState('');
  // const [filteredOptions, setFilteredOptions] = useState(options);
  const [selectedHashSet, setSelectedHashSet] = useState(getHashSet(values));
  const [selectedAll, setSelectedAll] = useState(false);
  const [show, setShow] = useState(false);
  useEffect(() => {
    setSelectedHashSet(getHashSet(values));
  }, []);

  // useLayoutEffect(() => {
  //   setFilteredOptions(options);
  // }, [options]);

  const onSelectHandler = option => {
    const copyHashSet = _.cloneDeep(selectedHashSet);
    copyHashSet[option] = !copyHashSet[option];
    setSelectedHashSet(copyHashSet);
    setSelectedAll(false);
  };

  const onSelectAllHandler = () => {
    setSelectedAll(!selectedAll);
    const copyHashSet = _.cloneDeep(selectedHashSet);
    if (!selectedAll) {
      _.map(Object.keys(options), label =>
        _.forEach(options[label], option => (copyHashSet[option.name] = true))
      );
    } else {
      _.map(Object.keys(options), label =>
        _.forEach(options[label], option => {
          copyHashSet[option.name] = false;
        })
      );
    }
    setSelectedHashSet(copyHashSet);
  };

  useUpdateEffect(() => {
    onChange(
      _.filter(Object.keys(selectedHashSet), option => selectedHashSet[option])
    );
  }, [selectedHashSet]);

  // useEffect(() => {
  //   setFilteredOptions(
  //     _.filter(options, option => _.includes(option, searchText))
  //   );
  // }, [searchText]);
  const optionsDropDown = () => {
    return (
      <div>
        <Option selected={selectedAll} onClick={onSelectAllHandler}>
          Select All
        </Option>
        {_.map(Object.keys(options), label => (
          <>
            <span
              style={{
                textTransform: 'capitalize',
                marginLeft: '20px',
                fontWeight: 'bolder'
              }}
            >
              {label}
            </span>
            {_.map(options[label], option => (
              <Option
                key={option.name}
                selected={!!selectedHashSet[option.name]}
                onClick={() => onSelectHandler(option.name)}
              >
                {option.name}
              </Option>
            ))}
          </>
        ))}
      </div>
    );
  };

  // const filterOffers = value => {
  //   setSearchText(value);
  // };
  const searchOptions = () => {
    return (
      <div className={multiSelect.dropdownSearch}>
        {isSearchEnabled && <Search width="360px" onSearch={null} />}
        {optionsDropDown()}
      </div>
    );
  };

  return selectedHashSet ? (
    <div style={{ width: width }}>
      <div className={inputRow.container}>
        <span className={inputRow.title}>Decisions</span>
        <div
          onClick={() => setShow(!show)}
          className={multiSelect.customDropDownArrow}
          style={{
            width: '400px',
            border: '2px solid #e3e3e3'
          }}
        >
          {placeholder}
        </div>
      </div>
      {show && searchOptions()}
    </div>
  ) : (
    <div />
  );
};

export default DecisionsInput;
