/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { primaryColor } from '../OfferDefinition/theme';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Select } from '@material-ui/core';
import _ from 'lodash';
const Pagination = props => {
  const {
    pageChangeHandler,
    pageNum,
    maxPageCount,
    rowsPerPage,
    storeData,
    setPageNum,
    setRowsPerPage
  } = props;
  return (
    <div className="paginationContainer">
      <div className="paginationList">
        <button
          onClick={() => pageChangeHandler(-1)}
          disabled={pageNum === 1}
          className="previousPageStyle"
        >
          <ArrowBackIosIcon
            style={
              pageNum === 1
                ? { color: '#a8a8a8', fontSize: 17 }
                : { color: primaryColor, fontSize: 17 }
            }
          />
          <span
            className="previousButtonText"
            style={
              pageNum === 1 ? { color: '#a8a8a8' } : { color: primaryColor }
            }
          >
            PREV
          </span>
        </button>
        <span className="pageNumber">{`${pageNum} of ${maxPageCount}`}</span>

        <button
          onClick={() => pageChangeHandler(1)}
          disabled={pageNum === maxPageCount}
          className="nextPageStyle"
        >
          <span
            className="nextButtonText"
            style={
              rowsPerPage > _.size(storeData)
                ? { color: '#a8a8a8' }
                : { color: primaryColor }
            }
          >
            Next
          </span>
          <ArrowForwardIosIcon
            style={
              rowsPerPage > _.size(storeData)
                ? { color: '#a8a8a8', fontSize: 17 }
                : { color: primaryColor, fontSize: 17 }
            }
          />
        </button>
      </div>
      <div>
        <span className="showPerPage">Show per page</span>
        <Select
          defaultValue=""
          value={rowsPerPage}
          onChange={evt => {
            setRowsPerPage(evt.target.value);
            setPageNum(1);
          }}
          style={{ padding: 3 }}
        >
          {_.map([10, 25, 50, 100], el => (
            <option value={el} key={el}>
              {el}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default Pagination;
