import { useEffect, useState } from 'react';
import { getNotificationContexts } from '../../../../../services/notification.service';
import CustomSelect from '../../NodeDetails/InputRow/CustomSelect';
import _ from 'lodash';
export const NotificationContext = ({ detail, setDetail }) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    async function getNotificationContext() {
      setOptions(
        _.map(
          await getNotificationContexts(),
          notificationContext => notificationContext.context_string
        )
      );
    }

    getNotificationContext();
  }, []);
  const onChange = value => {
    setDetail(value);
  };

  detail.options = options;
  return CustomSelect({ detail, onSelect: onChange });
};
