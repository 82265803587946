import { isEmpty } from 'lodash';
import React from 'react';

import SectionProps from '../../../models/SectionProps';
import utils from '../../../utils/utils';
import DetailsTable from '../../DetailsTable/DetailsTable';
import Heading from '../../Heading/Heading';
import classes from './PaymentInfo.module.css';

const PaymentInfo: React.FC<SectionProps> = ({ billData, data }) => {
  const userPaymentsField = utils.transformPaymentSplitsToFields(billData);
  const canShowPayments = !isEmpty(userPaymentsField);

  return canShowPayments ? (
    <div className={classes.container}>
      <Heading heading={data.heading || 'Payment Modes'} />
      <DetailsTable
        fields={userPaymentsField}
        data={{}}
        isKeyBold={true}
        isValueBold={false}
        alternateAlign={false}
        separator={'-'}
      />
    </div>
  ) : (
    <div />
  );
};

export default PaymentInfo;
