import * as userModels from './User.model';
import * as cartModels from './CartWrapper';
import * as offerModels from './offer/Offer.model';
import * as offerRuleModels from './offer/OfferRule.model';

export const allModels = {
  ...userModels,
  ...cartModels,
  ...offerModels,
  ...offerRuleModels
};
