import React, { FC, useContext, useState } from 'react';
import SelectWithAutoComplete from '../SelectWithAutoComplete/SelectWithAutoComplete';
import _, { filter, first, groupBy, includes, uniqWith } from 'lodash';
import './SearchProducts.css';
import { FlipbookProductsContext } from '../Hooks/FlipbookProductsContext';
import { LayoutType } from '../../../models/FlipbookV2/FlipbookV2Layout.model';
import { TileDataType } from '../../../models/FlipbookV2/FlipbookV2LayoutTile.model';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignoreß
import $ from 'jquery';

interface SearchProductsProps {
  allProducts: any;
  flipbookEl: any;
  setPopUpOpen: (open: boolean) => void;
}

export const SearchProducts: FC<SearchProductsProps> = ({
  flipbookEl,
  setPopUpOpen
}) => {
  const [selectedProduct, setSelectedProduct] = useState([]);
  const { products, flipbookPages } = useContext(FlipbookProductsContext);

  const allProducts = uniqWith(
    _.map(
      groupBy(products, p => p.extendedData.sku),
      group => _.merge({}, ...group)
    )
  );

  const options = _.map(allProducts, p => {
    const name = _.get(p, 'extendedData.name', '');
    return {
      label: name,
      value: name,
      productFieldAccessor: 'name'
    };
  });

  const goToPageNumber = (pageNumber: number) => {
    $(flipbookEl.current).turn('page', pageNumber);
  };

  const getFlipbookPageNumber = (sku: string) => {
    for (const [index, layout] of flipbookPages.entries()) {
      if (layout.layoutType === LayoutType.SINGLE) {
        const tile = layout.flipbookLayoutTile[0];
        if (
          tile.tileDataType === TileDataType.COLLECTION &&
          tile.tileData.currentPageSKUS.find((cps: any) =>
            cps.sku.includes(sku)
          )
        ) {
          return index + 2;
        }
      } else {
        const tiles = layout.flipbookLayoutTile;
        for (const tile of tiles) {
          if (
            tile.tileDataType === TileDataType.COLLECTION &&
            tile.tileData.skus.includes(sku)
          ) {
            return index + 2;
          }
        }
      }
    }
  };

  function onChangeSelectedProducts(product: any) {
    const productData = first(
      filter(allProducts, p =>
        includes(_.get(product, 'value'), p.extendedData.name)
      )
    );
    setSelectedProduct(product);
    const productPageNumber = getFlipbookPageNumber(
      productData.extendedData.sku
    );
    console.log('going to page -> ', productPageNumber);
    goToPageNumber(productPageNumber);
    setPopUpOpen(false);
  }

  return (
    <div className={'search-product-container'}>
      <div className={'search-box'}>
        <SelectWithAutoComplete
          onChange={e => e && onChangeSelectedProducts(e)}
          options={options}
          values={selectedProduct}
          label={'Products'}
          size={'auto'}
          isMultiple={false}
        />
      </div>
    </div>
  );
};

export default SearchProducts;
