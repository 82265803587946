import React from 'react';
import PropTypes from 'prop-types';
import personDark from '../../../../Assets/Offers/personDark.svg';
import './index.css';
import QueryBuilder from '../../../QueryBuilder';

export default function ProductsTab(props) {
  const { bucket, setDefinition } = props;
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={personDark} alt={'person'} />
        <div style={{ display: 'block', marginTop: 20 }}>
          <div className="checkboxLabel">
            Set eligibility based on products in cart
          </div>
          <div className="checkboxDescription">
            Customer should buy a (logical combination of) specific set of
            products to be eligible for the offer.
          </div>
        </div>
      </div>
      <div style={{ margin: 35 }}>
        <QueryBuilder bucket={bucket} setRootBucket={setDefinition} />
      </div>
    </div>
  );
}

ProductsTab.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  bucket: PropTypes.object.isRequired,
  setDefinition: PropTypes.func.isRequired
};
