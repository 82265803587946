import config from '../config';

function uploadImage(data) {
  return fetch(`${config.ruleServerUrl}/users/data/images/upload`, {
    credentials: 'include',
    method: 'POST',
    body: data
  }).then(r => r.json());
}

export default { uploadImage };
