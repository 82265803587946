class Specs {
  constructor(name, type, label, options = [], children = [], value = '') {
    this.name = name;
    this.type = type;
    this.label = label;
    this.options = options;
    this.children = children;
    this.value = value;
  }
}

export default Specs;
