import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core';
import React, { FC } from 'react';
import LiabilitySummaryReportDownload, {
  LiabilitySummaryReportTypes
} from '../../../models/TenantReports/LiabilitySummaryReportDownload';
import TableBodyLoader from '../../../shared/components/TableBodyLoader/TableBodyLoader';
import TableFooter from '../../../shared/components/TableFooter/TableFooter';
import TableHeader from '../../../shared/components/TableHeader/TableHeader';
import formatDate from '../../../shared/utils/formatDate';
import formatDateWithoutTS from '../../../shared/utils/formatDateWithoutTS';
import Chip from '../../../shared/components/Chip/Chip';
import { GetApp } from '@material-ui/icons';
import { get, isNull } from 'lodash';
import LinearProgressWithLabel from '../Shared/LinearProgressWithLabel';
import zipDownloadIcon from '../Assets/zip-download.png';
import { downloadLiabilityReportFile } from '../../../services/TenantReports/LiabilitySummaryReport.service';
import { toast } from 'react-toastify';
import moment from 'moment';

interface LLSReportDownloadTableProps {
  reports: LiabilitySummaryReportDownload[];
  reportType: LiabilitySummaryReportTypes;
  isLoading: boolean;
  page: number;
  offset: number;
  setPage: (page: number) => void;
  setOffset: (offset: number) => void;
}

const headings = [
  'S.No',
  'Date Triggered',
  'Start Date',
  'End Date',
  'Progress',
  'Status',
  'Download'
];

const LLSReportDownloadTable: FC<LLSReportDownloadTableProps> = props => {
  const {
    reportType,
    reports,
    isLoading,
    page,
    offset,
    setPage,
    setOffset
  } = props;

  const getFileNameForDownload = (report: LiabilitySummaryReportDownload) => {
    const fileType = isCSVFile(report) ? 'csv' : 'zip';
    return `Liability_${reportType}_${moment().valueOf()}.${fileType}`;
  };
  const getDownloadReportUrl = async (
    report: LiabilitySummaryReportDownload
  ): Promise<any> => {
    const url = report.errorFileLocation;
    try {
      if (isNull(url)) {
        return;
      }
      const fileName = getFileNameForDownload(report);
      await downloadLiabilityReportFile(url, fileName);
    } catch (e) {
      toast.error('Something went wrong!!!');
    }
  };
  const isCSVFile = (report: LiabilitySummaryReportDownload) => {
    const fileType = get(report, 'data.fileType', 'csv');
    return fileType === 'csv';
  };
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table>
        <TableHeader headings={headings} align="center" />
        {isLoading ? (
          <TableBodyLoader colSpan={headings.length} rowsPerPage={offset} />
        ) : (
          <TableBody>
            {reports.map((report, index) => (
              <TableRow key={report.id}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">
                  {' '}
                  {formatDate(report.createdAt)}{' '}
                </TableCell>
                <TableCell align="center">
                  {' '}
                  {formatDateWithoutTS(report.data.startDate)}{' '}
                </TableCell>
                <TableCell align="center">
                  {' '}
                  {formatDateWithoutTS(report.data.endDate)}{' '}
                </TableCell>
                <TableCell align="center">
                  <LinearProgressWithLabel
                    total={report.total}
                    currentValue={report.processedRecords}
                  />
                </TableCell>
                <TableCell align="center">
                  <Chip
                    label={report.status}
                    color={
                      report.status === 'COMPLETED'
                        ? 'success'
                        : report.status === 'FAILED'
                        ? 'error'
                        : 'primary-light'
                    }
                  />
                </TableCell>
                <TableCell align="center">
                  {isCSVFile(report) ? (
                    <IconButton
                      disabled={report.status !== 'COMPLETED'}
                      component="a"
                      onClick={() => getDownloadReportUrl(report)}
                      download
                    >
                      <GetApp />
                    </IconButton>
                  ) : (
                    <Button
                      onClick={() => getDownloadReportUrl(report)}
                      disabled={report.status !== 'COMPLETED'}
                    >
                      <img
                        src={zipDownloadIcon}
                        alt={'download'}
                        style={{
                          width: '3rem',
                          opacity: `${
                            report.status !== 'COMPLETED' ? '50%' : '100%'
                          }`
                        }}
                      />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
        <TableFooter
          colSpan={headings.length}
          count={-1}
          page={page}
          rowsPerPage={offset}
          onPageChange={setPage}
          onRowPerPageChange={setOffset}
        />
      </Table>
    </TableContainer>
  );
};

export default LLSReportDownloadTable;
