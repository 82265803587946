import React, { FC, useContext, useEffect } from 'react';
import './CheckoutForm.css';
import Button from '@material-ui/core/Button';
import {
  createPaymentOrder,
  paymentSuccess
} from '../../../services/ecomCart.service';
import _, { get } from 'lodash';
import {
  clearEcomLocalStoregeData,
  createCartInEcom
} from '../Utiles/FlipbookUtiles';
import successfulPurchase from '../../Assets/images/cart-saved.png';
import paymentWarning from '../../Assets/images/Notify.svg';
import { OrderSummary } from './OrderSummary';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { AVAILABLE_CHECKOUTS } from '../Utiles/FlipbookConfig';
import { BusinessUnitContext } from '../../OfferDefinition/BusinessUnitContext';

const ORDER_SUCCESS = 'success';
const ORDER_FAILURE = 'failure';
const ORDER_PENDING = 'pending';

const ORDER_STATES = ['complete'];

interface PaymentProps {
  tenantName: string;
  userDetails: any;
  address: any;
  setCartItems: (data: any) => void;
  setShowNextOrFinish: (data: boolean) => void;
  cartItems: any;
  allProducts: any;
  setActiveStep: (data: number) => void;
  storeDetails: any;
  handleProductPopUpClose: any;
  storeCode: any;
  setOrderPaymentResponse: (res: any) => void;
  setShowNavigationButton: (state: boolean) => void;
}

export const Payment: FC<PaymentProps> = ({
  tenantName,
  userDetails,
  address,
  setCartItems,
  setShowNextOrFinish,
  cartItems,
  allProducts,
  setActiveStep,
  storeDetails,
  handleProductPopUpClose,
  storeCode,
  setOrderPaymentResponse,
  setShowNavigationButton
}) => {
  const [orderStatus, setOrderStatus] = React.useState('');
  const [orderDetails, setOrderDetails] = React.useState('');
  const [paymentMode, setPaymentMode] = React.useState('');
  const { business } = useContext(BusinessUnitContext);
  const businessId = business.id;
  const businessName = business.name;
  const paymentOptions = ['RazorPay'];
  useEffect(() => {
    setShowNextOrFinish(false);
  }, [setShowNextOrFinish]);
  useEffect(() => {
    if (_.isEmpty(paymentMode)) {
      setPaymentMode(_.get(paymentOptions, 0, ''));
    }
  }, [paymentMode, paymentOptions]);
  function loadScript(src: any) {
    return new Promise(resolve => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const getAddress = () => {
    return _.join(
      _.values(
        _.omit(address, ['firstname', 'lastname', 'phone', 'country_iso'])
      )
    );
  };

  async function displayRazorpay() {
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }
    // creating a new order
    createPaymentOrder(tenantName, businessId).then(result => {
      if (!result) {
        alert('Server error. Are you online?');
        return;
      }
      //Todo: Commented this section - BT integration is WIP, uncomment after BT side blockers are fixed
      /*if (result?.status === 'failed') {
        setActiveStep(-1);
        setOrderPaymentResponse(result);
        setShowNavigationButton(false);
        return;
      }*/
      const { amount, id, currency } = result.data;
      const options = {
        key: result.keyId,
        amount: amount.toString(),
        currency: currency,
        name: _.startCase(tenantName),
        description: '',
        image: {},
        // eslint-disable-next-line @typescript-eslint/camelcase
        order_id: id,
        handler: async function(response: any) {
          const data = {
            // eslint-disable-next-line @typescript-eslint/camelcase
            order_creation_id: id,
            // eslint-disable-next-line @typescript-eslint/camelcase
            razorpay_payment_id: response.razorpay_payment_id,
            // eslint-disable-next-line @typescript-eslint/camelcase
            razorpay_order_id: response.razorpay_order_id,
            // eslint-disable-next-line @typescript-eslint/camelcase
            razorpay_signature: response.razorpay_signature
          };
          // payment success call back
          paymentSuccess(tenantName, data, businessId, businessName).then(
            res => {
              handleOrderState(res);
            },
            err => {
              handleOrderFailure();
            }
          );
        },
        prefill: {
          name: `${address.firstname} ${address.lastname}`,
          email: userDetails.email,
          contact: userDetails.mobile
        },
        notes: {
          address: getAddress()
        },
        theme: {
          color: '#f2755f'
        },
        readonly: {
          contact: true,
          name: true
        }
      };
      const paymentObject = new (window as any).Razorpay(options);
      paymentObject.open();
    });

    // Getting the order details back
  }

  function onClickPay() {
    displayRazorpay();
  }

  function handleOrderState(paymentResponse: any) {
    const { order } = paymentResponse.data;
    setOrderDetails(order);
    if (
      _.includes(ORDER_STATES, order.state) &&
      order.payment_state === 'paid'
    ) {
      setOrderStatus(ORDER_SUCCESS);
      setActiveStep(3);
    } else {
      setOrderStatus(ORDER_PENDING);
    }
    resetCart();
  }

  function handleOrderFailure() {
    setOrderStatus(ORDER_FAILURE);
    resetCart();
  }

  function resetCart() {
    // Note: When refactoring Available checkouts, we'll get prompt when we click this.
    const checkoutType = get(
      AVAILABLE_CHECKOUTS,
      'FlipbookCheckout.key',
      'FlipbookCheckout'
    );
    clearEcomLocalStoregeData();
    createCartInEcom(tenantName, storeCode, checkoutType, businessId);
    setCartItems([]);
  }
  const getUserDetailsAsKeyValue = (key: string, value: any) => {
    return (
      <div>
        <div className={'shipment-user-information-header'}>{key}</div>
        <div style={{ color: '#666666', fontSize: '14px', textAlign: 'left' }}>
          {value}
        </div>
      </div>
    );
  };
  function getUserInfo() {
    return getUserDetailsAsKeyValue('Mail Address', userDetails.email);
  }
  const handleRedirectValueChange = (event: any) => {
    setPaymentMode(event.target.value);
  };
  function getPaymentOptionsAsRadioButtons(paymentOptions: any) {
    return _.map(paymentOptions, options => {
      return (
        <FormControlLabel
          value={options}
          control={<Radio color="primary" size={'small'} />}
          label={options}
          className={'radio-button-label'}
        />
      );
    });
  }
  function getPayingOptions() {
    return (
      <div className={'my-4'}>
        <div className={'shipment-user-information-header'}>
          {'Payment Through'}
        </div>
        <div className={'ml-2'}>
          <RadioGroup
            aria-label="redirect"
            name="redirect"
            value={paymentMode}
            onChange={handleRedirectValueChange}
          >
            {getPaymentOptionsAsRadioButtons(paymentOptions)}
          </RadioGroup>
        </div>
      </div>
    );
  }

  const getStoreContactNumber = () => {
    return _.get(_.head(storeDetails), 'mobile', '');
  };

  return (
    <div>
      <div>
        {_.isEmpty(orderStatus) && (
          <div className={'order-summary-container'}>
            <div className={'payment-mode'}>
              <table>
                <tbody>{getUserInfo()}</tbody>
              </table>
              {getPayingOptions()}
            </div>
            <div className={'line-separator'}></div>
            <OrderSummary cartItems={cartItems} allProducts={allProducts} />
          </div>
        )}
        {orderStatus === ORDER_SUCCESS && (
          <div>
            <img
              className={'successful-purchase-img'}
              src={successfulPurchase}
              alt={'payment done'}
            />
            <p className={'successful-purchase-text'}>
              {`Hey `}{' '}
              <span style={{ fontWeight: 'bold' }}>
                {_.get(address, 'firstname', '')}{' '}
                {_.get(address, 'lastname', '')}
              </span>{' '}
              {`, Thank You For Your Order - `}
              <span
                style={{
                  fontWeight: 'bold',
                  backgroundColor: 'rgb(255, 244, 229)',
                  padding: '.3rem'
                }}
              >
                {_.get(orderDetails, 'number', '')}
              </span>
            </p>
            <Button
              className={'m-4 payment-page-btn'}
              variant="contained"
              color="primary"
              onClick={() => window.location.reload()}
            >
              {'Finish'}
            </Button>
          </div>
        )}
        {orderStatus === ORDER_PENDING && (
          <div>
            <img
              className={'successful-purchase-img'}
              src={paymentWarning}
              alt={'payment warning'}
            />
            <p className={'successful-purchase-text'}>
              {`We received your order request - `}
              <span
                style={{
                  fontWeight: 'bold',
                  backgroundColor: 'rgb(255, 244, 229)',
                  padding: '.3rem'
                }}
              >
                {_.get(orderDetails, 'number', '')}
              </span>
            </p>
            <p className={'successful-purchase-text'}>
              {'We couldn’t process it due to a technical issue.'}
            </p>
            <p className={'successful-purchase-text'}>
              {'To complete your purchase,  contact us at '}
              <a href={`tel:${getStoreContactNumber()}`}>
                <span>{getStoreContactNumber()}</span>
              </a>
            </p>
            <Button
              className={'m-4 payment-page-btn'}
              variant="contained"
              color="primary"
              onClick={handleProductPopUpClose}
            >
              {'Close'}
            </Button>
          </div>
        )}
        {orderStatus === ORDER_FAILURE && (
          <div>
            <img
              className={'successful-purchase-img'}
              src={paymentWarning}
              alt={'payment warning'}
            />
            <p className={'successful-purchase-text'}>
              {`Something went wrong, Please contact us at`}{' '}
              <a href={`tel:${getStoreContactNumber()}`}>
                <span>{getStoreContactNumber()}</span>
              </a>
            </p>
            <Button
              className={'m-4 payment-page-btn'}
              variant="contained"
              color="primary"
              onClick={handleProductPopUpClose}
            >
              {'Close'}
            </Button>
          </div>
        )}
      </div>
      {_.isEmpty(orderStatus) && (
        <Button
          className={'m-4 payment-page-btn'}
          variant="contained"
          color="primary"
          onClick={onClickPay}
        >
          {'Proceed To Pay'}
        </Button>
      )}
    </div>
  );
};

export default Payment;
