import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

export default function SnackbarComponent(props: {
  open: boolean;
  severity: 'success' | 'info' | 'warning' | 'error';
  message: string;
  handleClose: () => void;
}) {
  const { open, severity = 'success', message = '', ...rest } = props;
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={props.handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      {...rest}
    >
      <Alert onClose={props.handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}
