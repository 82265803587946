import _ from 'lodash';
import React, { FC } from 'react';
import DetailsTableProps from '../../models/DetailsTableProps';

import { SectionFieldParams } from '../../models/SectionField';
import utils from '../../utils/utils';
import classes from './DetailsTable.module.css';

const DetailsTable: FC<DetailsTableProps> = ({
  fields,
  data,
  isKeyBold = true,
  isValueBold = false,
  width = 'max-content',
  style = {},
  keyAlign = 'left',
  valueAlign = 'left',
  cellWidth = {},
  separator = ''
}) => {
  function renderRows() {
    return _.map(fields, renderRow);
  }

  function renderRow(field: SectionFieldParams, idx: number) {
    return (
      <tr key={`${field.label}-${idx}`}>
        <td
          style={{
            fontWeight: isKeyBold ? 700 : 100,
            textAlign: keyAlign,
            textTransform: isKeyBold ? 'capitalize' : 'none',
            ...cellWidth
          }}
          className={classes.key}
        >
          {field.label}
        </td>
        {separator && <td>{separator}</td>}
        <td
          style={{
            fontWeight: isValueBold ? 700 : 100,
            textAlign: valueAlign,
            ...cellWidth
          }}
          className={classes.value}
        >
          {utils.getAndTransformValue(field, data)}
        </td>
      </tr>
    );
  }
  return (
    <div className={classes.container} style={{ width: width, ...style }}>
      <table className={classes.detailsTable}>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
};

export default DetailsTable;
