import React, { FC } from 'react';

const Conversion: FC = () => {
  return (
    <svg
      width="86"
      height="86"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.1" cx="42.836" cy="42.836" r="42.836" fill="#2F49D1" />
      <path
        d="M51.5 54.3334V40.1667"
        stroke="#2F49D1"
        strokeWidth="2.85573"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.9998 54.3332V31.6665"
        stroke="#2F49D1"
        strokeWidth="2.85573"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.5 54.3333V45.8333"
        stroke="#2F49D1"
        strokeWidth="2.85573"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default Conversion;
