/* eslint-disable @typescript-eslint/explicit-function-return-type */
import _ from 'lodash';
import NodeTypes from '../BaseNodeModel/NodeTypes';
function hasValues(model) {
  return _.isEmpty(
    _.find(model.activeNodeLayer.models, node => {
      return !node.isDetailsSaved;
    })
  );
}
function hasLinks(model) {
  return _.isEmpty(
    _.find(model.activeNodeLayer.models, node => {
      if (node.type === NodeTypes.STOP_NODE) {
        return false;
      } else {
        return _.find(node.ports, port => {
          return _.isEmpty(port.links);
        });
      }
    })
  );
}

export { hasValues, hasLinks };
