import React from 'react';
import Search from './Search';
import Reset from './Reset';
import * as classes from './JourneyFilters.module.css';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

export default function JourneyFilters({
  isRunning,
  setIsRunning,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  searchText,
  setSearchText
}) {
  return (
    <div className={classes.container}>
      <div>Filter By:</div>
      <div className={classes.dateFilter}>
        <div className={classes.date}>
          <TextField
            id="date"
            label="Journey Start Date"
            type="date"
            defaultValue={startDate}
            onChange={e => setStartDate(e.target.value)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div className={classes.date}>
          <TextField
            id="date"
            label="Journey End Date"
            type="date"
            defaultValue={endDate}
            onChange={e => setEndDate(e.target.value)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
      </div>
      <div>
        <div>Journey Status</div>
        <Select
          value={isRunning}
          onChange={e => {
            setIsRunning(e.target.value);
          }}
        >
          <MenuItem value="ALL">All</MenuItem>
          <MenuItem value="RUNNING">Running</MenuItem>
          <MenuItem value="STOPPED">Not Running</MenuItem>
        </Select>
      </div>
      <Search
        query={searchText}
        onSearchHandler={e => {
          setSearchText(e.target.value);
        }}
      />
      <Reset
        onReset={() => {
          setEndDate('');
          setStartDate('');
          setIsRunning('ALL');
          setSearchText('');
        }}
      />
    </div>
  );
}
