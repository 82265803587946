/* eslint-disable @typescript-eslint/explicit-function-return-type */
import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { get, map, random } from 'lodash';
import moment from 'moment';
import React, { FC, useEffect } from 'react';

import { GroupingColumn } from '.';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { createOfferReport } from '../../../services/TenantReports/OfferReport.service';
import { OfferReportFilter } from './FilterType';
import { RefreshOutlined } from '@material-ui/icons';

interface ReportActionsProps {
  filters: OfferReportFilter;
  setFilters: React.Dispatch<React.SetStateAction<OfferReportFilter>>;
  setRefreshView: (id: string) => void;
}

const OfferReport: FC<ReportActionsProps> = ({
  filters,
  setFilters,
  setRefreshView
}) => {
  const user = useCurrentUser();

  const handleDateChange = (date: any, name: string) => {
    setFilters({ ...filters, [name]: date });
  };

  const handleColumnValueChange = (value: any) => {
    setFilters({ ...filters, segment: value });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsBuId = +urlParams.get('bu')! || 0;

    setFilters(filters => ({ ...filters, businessId: paramsBuId }));
  }, [user, setFilters]);

  const handleGenerateReport = (): void => {
    createOfferReport(filters).then((res: any) => {
      const jobId = get(res, 'jobId', '');
      setRefreshView(jobId);
    });
  };
  const currentDate = moment(new Date()).toDate();
  return (
    <div
      style={{
        margin: '30px 0',
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            label="From Date"
            maxDate={filters.endDate}
            value={filters.startDate}
            onChange={e => handleDateChange(e, 'startDate')}
            variant="inline"
            format="yyyy-MM-dd"
          />
          <KeyboardDatePicker
            label="To Date"
            maxDate={currentDate}
            minDate={filters.startDate}
            value={filters.endDate}
            onChange={e => handleDateChange(e, 'endDate')}
            variant="inline"
            format="yyyy-MM-dd"
            style={{ marginLeft: '20px' }}
          />
        </MuiPickersUtilsProvider>
        <FormControl style={{ minWidth: 180, marginLeft: '20px' }}>
          <InputLabel id="segment-column-select-helper-label">
            Segment By
          </InputLabel>
          <Select
            labelId="select-segment-column-label"
            id="select-segment-column"
            value={filters.segment}
            label="Segment By"
            onChange={e => handleColumnValueChange(e.target.value)}
          >
            {map(GroupingColumn, (value, key) => {
              return (
                <MenuItem
                  value={value}
                  key={key}
                  style={{ textTransform: 'capitalize' }}
                >
                  {key}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <Box sx={{ display: 'flex' }}>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleGenerateReport}
        >
          Generate Report
        </Button>
        <Button
          onClick={() => setRefreshView(`${random(10000)}`)}
          color="primary"
        >
          <RefreshOutlined
            style={{ marginRight: '5px' }}
            color="primary"
            fontSize="medium"
          />
          Refresh
        </Button>
      </Box>
    </div>
  );
};

export default OfferReport;
