import React from 'react';
import { Button } from '@material-ui/core';

interface ButtonProps {
  label?: string;
  className?: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  color?: 'default' | 'inherit' | 'primary' | 'secondary';
  startIcon?: React.ReactNode;
  variant?: 'text' | 'outlined' | 'contained';
  styles?: React.CSSProperties;
  disabled?: boolean;
}

const ButtonField: React.FC<ButtonProps> = ({
  label,
  className,
  onClick,
  type = 'button',
  color = 'default',
  startIcon,
  variant = 'contained',
  styles,
  disabled = false
}) => {
  return (
    <>
      <Button
        variant={variant}
        className={className}
        onClick={onClick}
        type={type}
        color={color}
        startIcon={startIcon}
        style={{ ...styles, textTransform: 'capitalize' }}
        disabled={disabled}
      >
        {label}
      </Button>
    </>
  );
};

export default ButtonField;
