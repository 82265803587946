import config from '../config';

export const getTenantUsersList = () => {
  return fetch(
    `${config.ruleServerUrl}/users/peers/with-permissions-in-my-stores?permissions`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  )
    .then(resp => resp.json())
    .catch(err => console.error(err));
};

export const getUserRoles = () => {
  return fetch(`${config.ruleServerUrl}/users/roles/currentroleconfig`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(resp => resp.json())
    .catch(err => console.error(err));
};

export const getTenantStores = () => {
  return fetch(`${config.ruleServerUrl}/users`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(resp => resp.json())
    .catch(err => console.error(err));
};

export const saveUsers = data => {
  return fetch(`${config.ruleServerUrl}/users/roles/save-users`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(resp => resp.json())
    .catch(err => console.error(err));
};

export const updateUserStatus = data => {
  return fetch(`${config.ruleServerUrl}/users/roles/update-user-status`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(resp => resp.json())
    .catch(err => console.error(err));
};

export const resetPassword = data => {
  return fetch(`${config.ruleServerUrl}/users/user/reset-password`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(resp => resp.json())
    .catch(err => console.error(err));
};
