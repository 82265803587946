import React from 'react';
import moment from 'moment';
import { Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { GetBarCode } from '../utils';
import RupeeFit from '../../assets/rupeeFit.png';
import Disclimer from '../../assets/disclimer.png';
import Warranty from './warranty';
import fonts from '../../assets/Fonts';
import {
  getSplitDescription,
  truncatedNumber,
  truncateString
} from '../../../../utils';
import _ from 'lodash';

Font.register(fonts);

const styles = StyleSheet.create({
  container: {
    fontFamily: 'OpenSans',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  card: {
    margin: '1mm',
    width: '144.7mm',
    height: '102.66mm',
    border: '1 solid #000000',
    display: 'flex',
    marginBottom: 5
  },
  qrwithname: {
    display: 'flex',
    flexDirection: 'row',
    height: '48.8mm'
  },
  qrcode: {
    width: '48.8mm',
    height: '48.8mm',
    padding: '1mm',
    borderRight: '1 solid #000000'
  },
  productnameWithDesc: {
    fontSize: '10.76pt',
    width: '95.9mm',
    padding: '3mm'
  },
  productname: {
    fontWeight: 'bold'
  },
  productDescription: {
    fontSize: '9.78pt',
    paddingTop: '1mm',
    lineHeight: 1.6
  },
  // discList: { height: '6mm' },
  priceBlock: {
    paddingLeft: '2mm',
    borderTop: '1 solid #000000',
    borderBottom: '1 solid #000000',
    display: 'flex',
    flexDirection: 'row',
    height: '31.57mm'
  },
  pricewithRupeeIcon: {
    display: 'flex',
    flexDirection: 'row',
    width: '108mm',
    paddingTop: '1mm'
  },
  rupeeicon: {
    fontSize: '20.3pt',
    width: '4mm',
    paddingTop: '1mm'
  },
  price: {
    fontWeight: 'bold',
    fontSize: '80.1pt',
    textAlign: 'left',
    color: 'black',
    paddingLeft: '3mm',
    top: -22,
    lineHeight: 1
  },
  inclTax: {
    paddingTop: '23mm',
    left: -26,
    fontSize: '4.6pt'
  },
  deals: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingBottom: '4mm',
    paddingRight: '3mm',
    fontSize: '12.69pt',
    paddingLeft: '2mm'
  },
  mrpText: {
    fontWeight: 'bold',
    color: 'black'
  },
  rupeeSmall: {
    width: '3.5mm',
    height: '3.5mm'
  },

  //   Footer
  footer: {
    padding: '0mm',
    height: '27.52mm'
  },
  warrantyWithBarcode: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  warranty: {
    height: '14.7mm',
    width: '14.7mm',
    margin: '1mm',
    marginLeft: '2mm'
  },
  warrantyDuriation: {
    position: 'absolute',
    height: '9.5mm',
    width: '9.5mm',
    fontWeight: 'bold',
    margin: '3mm',
    backgroundColor: '#fff',
    borderRadius: '50',
    alignItems: 'center',
    justifyContent: 'center'
  },
  warrantyValue: { fontSize: '15.12pt', left: -2 },
  warrantyYear: { fontSize: '4.64pt', top: -5, left: -1 },
  warrantyText: {
    fontSize: '6.64pt',
    color: '#1A1818',
    opacity: '1'
  },
  dateDetails: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '2mm',
    top: 1
  },
  itemCode: {
    fontSize: '6.84pt',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  barcodeBlock: {
    width: '103mm',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'right',
    right: 0
  },
  barcode: {
    width: '34.7mm',
    height: '13.2mm',
    padding: '1mm 1mm 0mm 0mm'
  },

  disclimer: {
    width: '3.75mm',
    height: '3.75mm',
    left: 5
  }
});
const getA6Layout = (count, data) => {
  return count.map(id => (
    <View key={id}>
      {data?.map(item => (
        <View style={styles.card} key={item?.id}>
          <View style={styles.qrwithname}>
            <View style={styles.qrcode}>
              <Image src={item.image} />
            </View>
            <View style={styles.productnameWithDesc}>
              <View style={styles.productname}>
                <Text>
                  {truncateString(item?.product?.masterData?.name, 130)}
                </Text>
              </View>
              <View style={styles.productDescription}>
                {/*<Text style={styles.discList}>
                          {truncateString(
                            item?.description ||
                              item?.product?.masterData?.description ||
                              '',
                            130
                          )}
                        </Text>*/}
                {/* placeholder here */}
                <View style={{ paddingTop: '3mm' }}>
                  {getSplitDescription(item.description, 3).map(
                    (line, index) => (
                      <Text key={index}>+ {line}</Text>
                    )
                  )}
                </View>
              </View>
            </View>
          </View>
          <View style={styles.priceBlock}>
            <View style={styles.pricewithRupeeIcon}>
              <Text style={styles.rupeeicon}>
                <Image src={RupeeFit} />
              </Text>
              <Text style={styles.price}>
                {truncatedNumber(item?.product?.mop, 6)}
              </Text>
              <Text style={styles.inclTax}>*Incl. Tax</Text>
            </View>
            <View style={styles.deals}>
              {truncatedNumber(item?.product?.mrp, 6) > 0 ? (
                <Text>
                  <Text style={styles.mrpText}>MRP: </Text>
                  <Image style={styles?.rupeeSmall} src={RupeeFit} />
                  {truncatedNumber(item?.product?.mrp, 6)}
                </Text>
              ) : (
                <Text />
              )}
            </View>
          </View>
          <View style={styles?.footer}>
            <View style={styles.warrantyWithBarcode}>
              <Warranty warranty={item.warranty} styles={styles} />
              <View style={styles.barcodeBlock}>
                <View style={styles.barcode}>
                  {_.isEmpty(item.barcode) ? (
                    <></>
                  ) : (
                    <Image src={GetBarCode(item?.barcode)} />
                  )}
                </View>
              </View>
            </View>
            <View
              style={
                item?.warranty
                  ? styles?.dateDetails
                  : { ...styles.dateDetails, top: 10 }
              }
            >
              <View style={styles.itemCode}>
                <Text>
                  {`${item?.sku}  |  ${item.class}  |  A  | ${moment(
                    item?.date
                  ).format('DD.MM.YYYY')}  |  ${moment().format('DD.MM.YYYY')}`}
                </Text>
                <Image style={styles.disclimer} src={Disclimer} />
              </View>
            </View>
          </View>
        </View>
      ))}
    </View>
  ));
};
const A6Landscape = ({ data, count, ...rest }) => {
  const splattedArray = _.chunk(data, 4);
  return (
    <Page orientation="landscape">
      <View style={styles.container}>
        {count &&
          _.map(splattedArray, (chunkedData, key) => {
            const chunkedRowArray = _.chunk(chunkedData, 2);
            const firstHalf = getA6Layout(count, chunkedRowArray[0]);
            const secondHalf = getA6Layout(count, chunkedRowArray[1]);
            return (
              <View
                style={{ flexDirection: 'row', flexWrap: 'wrap' }}
                key={key}
              >
                <View>{firstHalf}</View>
                <View>{secondHalf}</View>
              </View>
            );
          })}
      </View>
    </Page>
  );
};
export { A6Landscape };
