import React, { useEffect, useState } from 'react';
import { CustomerOfferCard } from '../CustomerOfferCard';
import { Offer } from '../../models/offer/Offer.model';
import _ from 'lodash';
import { CustomerOfferTile } from '../CustomerOfferTile';
import offerService from '../../services/offer.service';

type DisplayMode = 'card' | 'tile';

interface CustomerOffersListProps {
  customerMobile: string;
  currentOffers: Offer[];
  onOfferClick: (customerMobile: string, offerCode: string) => void;
  styles?: { [name: string]: string };
  className?: string;
  displayMode: DisplayMode;
  businessUnitId: number;
}

const defaultStyles = {};

export const CustomerOffersList: React.FC<CustomerOffersListProps> = props => {
  const {
    customerMobile,
    onOfferClick,
    currentOffers = [],
    styles = defaultStyles,
    className = '',
    displayMode = 'card',
    businessUnitId = -1
  } = props;

  const [specificOffers, setSpecificOffers] = useState<Offer[]>([]);
  const [genericOffers, setGenericOffers] = useState<Offer[]>([]);
  const [offerDefaultImage, setOfferDefaultImage] = useState<string | null>(
    null
  );

  useEffect(() => {
    const [specific, generic] = _.partition(
      currentOffers,
      o => o.isCustomerSpecific
    );
    setSpecificOffers(_.orderBy(specific, ['daysLeft'], ['asc']));
    setGenericOffers(_.orderBy(generic, ['daysLeft'], ['asc']));
  }, [currentOffers]);

  useEffect(() => {
    if (businessUnitId === -1) return;
    offerService
      .getOfferDefaultUrls(businessUnitId)
      .then(res => setOfferDefaultImage(res.offerShowImage));
  }, [businessUnitId]);

  return (
    <div style={styles} className={className}>
      {_.some(specificOffers) && (
        <div className={'container-fluid'}>
          <div className={'d-flex flex-wrap justify-content-center h5 mt-2'}>
            Only for you
          </div>
          <div className={'row flex-wrap justify-content-around mt-2'}>
            {specificOffers.map(o =>
              displayMode === 'card' ? (
                <CustomerOfferCard
                  className={'mt-3'}
                  offer={o}
                  key={o.code}
                  businessUnitId={businessUnitId}
                  onClick={offer => onOfferClick(customerMobile, offer.code!)}
                  offerDefaultImage={offerDefaultImage}
                />
              ) : (
                <CustomerOfferTile
                  offer={o}
                  key={o.code}
                  className={'col-12 col-sm-5 col-md-5 col-lg-3 m-lg-1'}
                  onClick={offer => onOfferClick(customerMobile, offer.code!)}
                  businessUnitId={businessUnitId}
                  offerDefaultImage={offerDefaultImage}
                />
              )
            )}
          </div>
        </div>
      )}
      {_.some(genericOffers) && (
        <div className={'container-fluid'}>
          <div
            className={'d-flex flex-wrap justify-content-center h5 mt-2 pt-2'}
            style={{ fontWeight: 700, fontSize: 22 }}
          >
            Seasonal offers
          </div>
          <div className={'row flex-wrap justify-content-center'}>
            {genericOffers.map(o =>
              displayMode === 'card' ? (
                <CustomerOfferCard
                  className={'mt-3'}
                  offer={o}
                  key={o.code}
                  businessUnitId={businessUnitId}
                  offerDefaultImage={offerDefaultImage}
                  onClick={offer => onOfferClick(customerMobile, offer.code!)}
                />
              ) : (
                <CustomerOfferTile
                  offer={o}
                  key={o.code}
                  className={'col-12 col-sm-8 col-md-5 col-lg-4 m-3'}
                  onClick={offer => onOfferClick(customerMobile, offer.code!)}
                  businessUnitId={businessUnitId}
                  offerDefaultImage={offerDefaultImage}
                />
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};
