import React from 'react';
import { makeStyles, Paper } from '@material-ui/core';

const styles = makeStyles({
  root: {
    width: '95%',
    height: 'auto',
    margin: 'auto',
    marginBottom: 20
  },
  card: {
    borderLeft: '5px solid #FC8B00',
    height: '100%',
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between'
  }
});
const Submited = props => {
  const { data } = props;
  const classes = styles();
  const submitedList = data?.map(item => [
    ...[],
    { name: 'Travelled Date', value: item.travelledDate },
    { name: 'Travelled KM for the day', value: item.distanceTravelled }
  ]);
  return (
    <>
      {(submitedList.length && (
        <Paper classes={{ root: classes.root }} elevation={3}>
          <div className={classes.card}>
            {submitedList?.map(list =>
              list?.map((item, i) => (
                <div key={i}>
                  <div style={{ fontWeight: 'bold' }}>{item?.name}</div>
                  <div>{item?.value}</div>
                </div>
              ))
            )}
          </div>
        </Paper>
      )) ||
        'No Record found'}
    </>
  );
};

export default Submited;
