import React from 'react';
import * as classes from './JourneyActions.module.css';

function DownloadIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      className="prefix__feather prefix__feather-download"
      width="1em"
      height="1em"
      {...props}
    >
      <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M7 10l5 5 5-5M12 15V3" />
    </svg>
  );
}

export default function JourneyActions({ totalJourneys, saveAsPdf }) {
  return (
    <div className={classes.container}>
      <div className={classes.text}>Total Journeys : {totalJourneys}</div>
      <div className={classes.actionContainer}>
        <div className={classes.buttonContainer}>
          <div className={classes.button} onClick={saveAsPdf}>
            {DownloadIcon()} Download
          </div>
        </div>
      </div>
    </div>
  );
}
