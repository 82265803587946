import React, { FC, useEffect, useState } from 'react';
import _, { isEmpty } from 'lodash';
import ErrorAccordion from '../Error/ErrorAccordion';
import { getUiFilterPayloadById } from '../../../../services/uiFilter.service';
import { LABELPRINTING_ERROR } from '../utils/Constants';

interface ErrorListProps {
  payloadError: any;
  isErrorProductsVisible: boolean;
  setIsErrorProductsVisible: () => {};
}
const INVALID_ID = ['', null, undefined, -1, 0];
export const ErrorList: FC<ErrorListProps> = ({
  payloadError,
  isErrorProductsVisible,
  setIsErrorProductsVisible
}) => {
  const [errors, setErrors] = useState([]);
  const [offset, setOffSet] = useState(0);
  const [count, setCount] = useState(25);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  useEffect(() => {
    if (!INVALID_ID.includes(payloadError.id))
      getUiFilterPayloadById(
        payloadError.id,
        LABELPRINTING_ERROR,
        offset,
        count
      ).then(result => {
        const skuErrors = _.get(result, 'data.payload', []);
        setErrors(skuErrors);
      });
  }, [count, offset, payloadError.id]);
  return (
    <>
      {!isEmpty(errors) && (
        <ErrorAccordion
          errors={errors}
          offset={offset}
          setCount={setCount}
          setOffSet={setOffSet}
          count={count}
          payloadData={payloadError}
          currentPageNumber={currentPageNumber}
          setCurrentPageNumber={setCurrentPageNumber}
          isErrorProductsVisible={isErrorProductsVisible}
          setIsErrorProductsVisible={setIsErrorProductsVisible}
        />
      )}
    </>
  );
};
