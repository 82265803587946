enum FieldTypes {
  DATE = 'date',
  CURRENCY = 'currency',
  IMAGE = 'image',
  LINK = 'link',
  TEXT = 'text',
  HTML_CONTENT = 'html_content',
  COMPUTED = 'computed'
}
export default FieldTypes;
