import React, { useState, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import CustomTab from './CustomTab';
import DateField from './DateField';
import _ from 'lodash';
import config from '../../../config';
import qs from 'query-string';

// const styles = makeStyles({
//   root: {
//     width: 90,
//     marginLeft: 20
//   },
//   weekTag: {
//     width: 200,
//     marginLeft: 20
//   }
// });

const TotalClaims = props => {
  const { kam, history } = props;
  const [claimRef, setClaimRef] = useState([]);
  const [kamClaims, setKamClaim] = useState({
    toDate: null,
    fromDate: null,
    claimData: [],
    filterStatus: ''
  });
  const fetchClaimData = (fromDate, toDate) => {
    if (
      typeof _.get(kam, 'individualKam') !== 'undefined' &&
      fromDate !== null &&
      toDate !== null &&
      _.get(kam, 'individualKam').length
    )
      fetch(
        `${
          config.casaBeatUrl
        }/dalmia/claims?fromDate=${fromDate}&toDate=${toDate}&userId=${_.get(
          kam,
          'individualKam[0].userId'
        )}`
      )
        .then(async response => {
          const kam = await response.json();
          setKamClaim({
            ...kamClaims,
            toDate: toDate,
            fromDate: fromDate,
            claimData: kam.claims
          });
          setClaimRef(kam.claims);
        })
        .catch(err => {
          console.error(err);
        });
  };

  const setClaimsData = () => {
    const query = qs.parse(history?.location.search);
    fetchClaimData(query.fromDate, query.toDate);
  };

  useEffect(setClaimsData, [kam]);

  const claimInfo = () => {
    fetchClaimData(kamClaims.fromDate, kamClaims.toDate);
  };

  useEffect(claimInfo, [kamClaims.fromDate, kamClaims.toDate]);

  const handleDate = (key, value) => {
    if (value !== null) {
      const formattedDate = `${value.getFullYear()}-${value.getMonth() +
        1}-${value.getDate()}`;
      setKamClaim(prevState => ({ ...prevState, [key]: formattedDate }));
    }
  };
  const formatDate = dateStr => {
    if (dateStr !== null) {
      const date = new Date(dateStr);
      return date;
    }
    return null;
  };
  // const classes = styles();
  return (
    <div>
      <Paper style={{ margin: '30px 0px' }} elevation={3}>
        <div
          style={{
            padding: 20,
            marginTop: 30,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <h5>
            <strong>
              TOTAL CLAIMS{' '}
              {_.get(kam, 'individualKam[0].totalDaysClaimed') ||
                0 + _.get(kam, 'individualKam[0].totalDaysUnclaimed') ||
                0}
            </strong>{' '}
            (Submitted {_.get(kam, 'individualKam[0].totalDaysClaimed') || 0} /
            not submitted{' '}
            {_.get(kam, 'individualKam[0].totalDaysUnclaimed') || 0})
          </h5>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <span>
              <strong>DATE RANGE</strong>
            </span>
            <div
              style={{
                width: 450,
                display: 'flex',
                justifyContent: 'space-around'
              }}
            >
              <div style={{ width: '200px' }}>
                <DateField
                  label={'From'}
                  value={formatDate(kamClaims?.fromDate)}
                  onChange={date => handleDate('fromDate', date)}
                />
              </div>
              <div style={{ width: '200px' }}>
                <DateField
                  label={'To'}
                  value={formatDate(kamClaims?.toDate)}
                  onChange={date => handleDate('toDate', date)}
                />
              </div>
            </div>
          </div>
        </div>
        <CustomTab
          kamClaims={kamClaims}
          fetchClaimData={fetchClaimData}
          claimRef={claimRef}
          setKamClaim={setKamClaim}
        />
      </Paper>
    </div>
  );
};

export default TotalClaims;
