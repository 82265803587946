/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import useFocus from '../../customHooks/useFocus';
import Tooltip from '@material-ui/core/Tooltip';

import classes from './Header.module.css';

const JourneyNameInput = ({ journeyName, setJourneyName, saved }) => {
  const [focusRef, setFocusRef] = useFocus();

  return (
    <Tooltip
      title={<h6 style={{ paddingTop: '5px' }}>Set Title in Settings</h6>}
    >
      <div className={classes.inputContainer} onClick={setFocusRef}>
        <span
          ref={focusRef}
          className={classes.nameInput}
          type="text"
          suppressContentEditableWarning={true}
          onChange={evt => {
            setJourneyName(evt.target.value);
          }}
        >
          {journeyName}
        </span>
        {!saved && <span style={{ fontWeight: 'bold' }}>*</span>}
      </div>
    </Tooltip>
  );
};

export default JourneyNameInput;
