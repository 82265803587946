import { TileData } from './TileData.model';

export enum ProductCardTemplateType {
  Title = 'title',
  SubTitle = 'subTitle'
}

export interface ProductCardTemplate {
  title: string;
  subTitle: string;
}
interface CurrentPageSkusTemplate {
  path: string;
  sku: string[];
}
export class TileDataCollection extends TileData {
  constructor(
    title: string,
    skus: string[],
    isShowTitleOnFlipbook: boolean,
    productHierarchyTreeChecked: string[],
    productHierarchyTreeExpanded: string[],
    productsFilteredByFirstHierarchy: string[],
    productCardTemplate: ProductCardTemplate,
    currentPageSKUS: CurrentPageSkusTemplate[]
  ) {
    super();
    this.title = title;
    this.skus = skus;
    this.isShowTitleOnFlipbook = isShowTitleOnFlipbook;
    this.productHierarchyTreeChecked = productHierarchyTreeChecked;
    this.productHierarchyTreeExpanded = productHierarchyTreeExpanded;
    this.productsFilteredByFirstHierarchy = productsFilteredByFirstHierarchy;
    this.productCardTemplate = productCardTemplate;
    this.currentPageSKUS = currentPageSKUS;
  }

  public title: string;
  public skus: string[];
  public isShowTitleOnFlipbook: boolean;
  public productHierarchyTreeChecked: string[];
  public productHierarchyTreeExpanded: string[];
  public productsFilteredByFirstHierarchy: string[];
  public productCardTemplate: ProductCardTemplate;
  public currentPageSKUS?: CurrentPageSkusTemplate[];
}
