import { useEffect, useState } from 'react';
import { getEmailTemplates } from '../../../../../services/notification.service';
import _ from 'lodash';
import CustomSelect from '../../NodeDetails/InputRow/CustomSelect';
export const EmailTemplates = ({ detail, setDetail }) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    async function getEmailTemplate() {
      setOptions(
        _.map(await getEmailTemplates(), emailTemplate => emailTemplate.name)
      );
    }

    getEmailTemplate();
  }, []);
  const onChange = value => {
    setDetail(value);
  };

  detail.options = options;
  return CustomSelect({ detail, onSelect: onChange });
};
