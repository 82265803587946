/* eslint-disable @typescript-eslint/explicit-function-return-type */
import config from '../config';

const invoiceDetails = async billToken => {
  return await fetch(
    `${config.ruleServerUrl}/invoice-details?token=${billToken}`,
    {
      method: 'GET'
    }
  )
    .then(response => response.json())
    .catch(err => err);
};

export default invoiceDetails;
