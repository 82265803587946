import { IconButton, TableBody, TableCell, TableRow } from '@material-ui/core';
import GetApp from '@material-ui/icons/GetApp';
import React, { FC } from 'react';

import { CampaignOverallReport } from '../../../../../../models/campaign/CampaignReport';
import styles from './CampaignReportTableBody.module.scss';
import config from '../../../../../../config';
import formatDate, {
  formatDateReport
} from '../../../../../../shared/utils/formatDate';
import Chip from '../../../../../../shared/components/Chip/Chip';
import { get, isArray, without } from 'lodash';
import isEmpty from 'lodash/isEmpty';

interface CampaignReportTableBodyProps {
  reports: CampaignOverallReport[];
}

const CampaignReportTableBody: FC<CampaignReportTableBodyProps> = ({
  reports
}) => {
  const getDownloadReportUrl = (url: string | null): string => {
    if (!url) return '/';
    let indexOfReportUrl = -1;
    indexOfReportUrl = url.indexOf('/report') + 1;

    return `${config.ruleServerUrl}/download-file/${url.slice(
      indexOfReportUrl
    )}`;
  };

  const getReportFiltersPath = (type: string) => {
    switch (type) {
      case 'name':
        return 'data.filters.name';
      case 'startDate':
        return 'data.filters.date.startDate';
      case 'endDate':
        return 'data.filters.date.endDate';
      case 'type':
        return 'data.filters.attributes.type';
      case 'status':
        return 'data.filters.attributes.status';
    }
  };

  function getReportFilter(
    report: CampaignOverallReport,
    type: string,
    defaultValue: string
  ) {
    const path: any = getReportFiltersPath(type);
    let data = get(report, path, defaultValue);
    if (isEmpty(data)) {
      data = defaultValue;
    } else if (isArray(data) && isEmpty(without(data, null))) {
      data = defaultValue;
    }
    return data;
  }

  return (
    <TableBody className={styles.campaignReportTableBody}>
      {reports.map(report => (
        <TableRow key={report.id}>
          <TableCell> {formatDate(report.createdAt)} </TableCell>
          <TableCell style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
            {getReportFilter(report, 'name', '-')}
          </TableCell>
          <TableCell style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
            {getReportFilter(report, 'type', 'all')}
          </TableCell>
          <TableCell style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
            {getReportFilter(report, 'status', 'all')}
          </TableCell>
          <TableCell>
            {formatDateReport(getReportFilter(report, 'startDate', ''))}
          </TableCell>
          <TableCell>
            {formatDateReport(getReportFilter(report, 'endDate', ''))}
          </TableCell>
          <TableCell>
            <Chip
              label={report.status}
              color={
                report.status === 'COMPLETE' || report.status === 'COMPLETED'
                  ? 'success'
                  : report.status === 'FAILED'
                  ? 'error'
                  : 'primary-light'
              }
            />
          </TableCell>
          <TableCell>
            <IconButton
              disabled={
                report.status !== 'COMPLETE' && report.status !== 'COMPLETED'
              }
              component="a"
              href={getDownloadReportUrl(report.errorFileLocation)}
              download
            >
              <GetApp />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
export default CampaignReportTableBody;
