import React, { FC, useContext, useEffect, useState } from 'react';
import {
  Button,
  FormControlLabel,
  IconButton,
  Popover,
  Radio,
  RadioGroup,
  withStyles
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import _, { isUndefined, map } from 'lodash';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import makeStyles from '@material-ui/core/styles/makeStyles';
import UploadImage from '../UploadImage/UploadImage';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import { anchorOriginObj, getColor, transformOriginObj } from './utiles';
import { BusinessUnitContext } from '../../OfferDefinition/BusinessUnitContext';
import {
  AVAILABLE_CHECKOUTS,
  DEFAULT_FLIPBOOK_CONFIG
} from '../Utiles/FlipbookConfig';
import {
  DeviceType,
  FlipbookConfigData,
  FlipbookContext,
  FlipbookMode
} from '../Hooks/FlipbookContext';
import {
  CheckoutOption,
  Themes
} from '../../../models/FlipbookV2/flipbookV2.model';
import FlipbookV2Service from '../../../services/Flipbook/flipbookV2.service';
import { FlipbookPagesContext } from '../Hooks/FlipbookPagesContext';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ProductHierarchyNew } from '../../../models/User.model';

const defaultFlipbookData: FlipbookConfigData = {
  id: 0,
  checkoutOption: CheckoutOption.FlipbookCheckout,
  description: '',
  deviceType: DeviceType.DESKTOP,
  evenBackgroundUrl: '',
  flipbookMode: FlipbookMode.PREVIEW,
  groupingHierarchy: [],
  isActive: true,
  isEmptyPagesHided: false,
  isUserStoreSelectionAvailable: false,
  name: '',
  oddBackgroundUrl: '',
  shortCode: '',
  tenantBrandBusinessId: 0,
  tenantId: 0,
  tenantStoreId: '',
  theme: Themes.LIGHT,
  coverImageUrl: ''
};

interface SettingsPopoverProps {
  productHierarchy: ProductHierarchyNew[];
}
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const useStyles = makeStyles({
  root: {
    color: '#F2755F',
    '&$checked': {
      color: '#F2755F'
    }
  }
});

export const SettingsPopover: FC<SettingsPopoverProps> = ({
  productHierarchy
}) => {
  const [isSettingsPopoverOpen, setIsSettingsPopoverOpen] = useState(false);
  const [flipbookData, setFlipbookData] = useState<FlipbookConfigData>(
    defaultFlipbookData
  );
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [applyState, setApplyState] = React.useState(true);
  const businessUnitContext = useContext(BusinessUnitContext);
  const flipbookContext = useContext(FlipbookContext);
  const flipbookPagesContext = useContext(FlipbookPagesContext);
  const [tenantFlipbookConfig, setTenantFlipbookConfig] = useState(
    DEFAULT_FLIPBOOK_CONFIG
  );
  const [state, setState] = React.useState<FlipbookConfigData>(
    defaultFlipbookData
  );
  useEffect(() => {
    const flipbookConfig = _.get(
      businessUnitContext,
      'business.flipbookConfig',
      DEFAULT_FLIPBOOK_CONFIG
    );
    setTenantFlipbookConfig(flipbookConfig);
  }, [businessUnitContext]);
  useEffect(() => {
    const data = flipbookContext.flipbookContext;
    if (isUndefined(data)) {
      return;
    }
    setFlipbookData(data);
    setState(data);
  }, [flipbookData, flipbookContext.flipbookContext]);

  if (isUndefined(flipbookData) || isUndefined(state)) {
    return <></>;
  }
  const AntSwitch = withStyles(theme => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex'
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#000000',
          borderColor: '#000000'
        }
      }
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none'
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white
    },
    checked: {}
  }))(Switch);
  const getAvailableCheckoutFlow = () => {
    return (
      tenantFlipbookConfig &&
      _.map(tenantFlipbookConfig.availableCheckouts, availableCheckout => {
        const label = _.get(
          AVAILABLE_CHECKOUTS,
          `${availableCheckout}.name`,
          ''
        );
        return (
          <FormControlLabel
            value={availableCheckout}
            control={<Radio color="primary" size={'small'} />}
            label={label}
            className={'radio-button-label'}
          />
        );
      })
    );
  };
  const handleThemeChange = (event: any) => {
    setState({
      ...state,
      theme: event.target.checked ? Themes.DARK : Themes.LIGHT
    });
    setApplyState(false);
  };

  function handleSettingsPopoverClose() {
    setState({
      ...state,
      theme: flipbookData.theme,
      oddBackgroundUrl: flipbookData?.oddBackgroundUrl,
      evenBackgroundUrl: flipbookData?.evenBackgroundUrl,
      groupingHierarchy: flipbookData?.groupingHierarchy,
      isUserStoreSelectionAvailable:
        flipbookData?.isUserStoreSelectionAvailable,
      checkoutOption: flipbookData?.checkoutOption,
      isEmptyPagesHided: flipbookData?.isEmptyPagesHided
    });
    setApplyState(true);
    setIsSettingsPopoverOpen(false);
  }

  const onClickSettingsApply = () => {
    const updatedData = {
      ...flipbookData,
      ...{
        theme: state?.theme,
        oddBackgroundUrl: state?.oddBackgroundUrl,
        evenBackgroundUrl: state?.evenBackgroundUrl,
        groupingHierarchy: state?.groupingHierarchy,
        isUserStoreSelectionAvailable: state?.isUserStoreSelectionAvailable,
        checkoutOption: state?.checkoutOption,
        isEmptyPagesHided: state?.isEmptyPagesHided
      }
    };
    setApplyState(true);
    setAnchorEl(null);
    setIsSettingsPopoverOpen(false);
    FlipbookV2Service.updateFlipbook(updatedData, flipbookData.id).then(res => {
      flipbookContext.updateFlipbook();
      flipbookPagesContext.updateFlipbookPages();
    });
  };

  function getSortedHierarchy(currentSelectedHierarchy: any) {
    let isFirstHierarchyFound = false;
    return _.transform(productHierarchy, function(result: any, value: any) {
      if (_.find(currentSelectedHierarchy, value) || isFirstHierarchyFound) {
        result.push(value);
        isFirstHierarchyFound = true;
      }
    });
  }

  function onChangeSelectedGroupingHierarchy(currentSelectedHierarchy: any) {
    setState({
      ...state,
      groupingHierarchy: map(
        getSortedHierarchy(currentSelectedHierarchy),
        'casaFieldName'
      )
    });

    setApplyState(false);
  }

  const handleRedirectValueChange = (event: any) => {
    setState({ ...state, checkoutOption: event.target.value });
    setApplyState(false);
  };
  const handleEmptyPageChange = (event: any) => {
    setState({ ...state, isEmptyPagesHided: event.target.checked });
    setApplyState(false);
  };
  const handleUserStoreSelectionChange = (event: any) => {
    setState({
      ...state,
      isUserStoreSelectionAvailable: event.target.checked
    });
    setApplyState(false);
  };

  function onClickBackgroundImageUpload(e: any, type: any) {
    let oddPage = state.oddBackgroundUrl;
    let evenPage = state.evenBackgroundUrl;
    if (type === 'backgroundImageOdd') {
      oddPage = _.get(e, type);
    } else if (type === 'backgroundImageEven') {
      evenPage = _.get(e, type);
    }
    setState({
      ...state,
      oddBackgroundUrl: oddPage,
      evenBackgroundUrl: evenPage
    });
    setApplyState(false);
  }

  function settingsPopoverComponent() {
    return (
      <div className={'flipbook-settings-popover-container'}>
        <div className={'my-4'}>
          <h3 className={'flipbook-popover-text-header uppercase'}>Settings</h3>
        </div>
        <div className={'my-4'}>
          <h3 className={'flipbook-popover-text-sub-header'}>Cover theme</h3>
          <div className={'flex-align-center ml-2'}>
            <div>
              <p
                className={`settings-switch-lable ${
                  state.theme === Themes.LIGHT ? 'active' : ''
                }`}
              >
                Light
              </p>
            </div>
            <div className={'settings-switch-button'}>
              <AntSwitch
                checked={state.theme === Themes.DARK}
                onChange={handleThemeChange}
                name="isDarkTheme"
              />
            </div>
            <div>
              <p
                className={`settings-switch-lable ${
                  state.theme === Themes.DARK ? 'active' : ''
                }`}
              >
                Dark
              </p>
            </div>
          </div>
        </div>
        <div className={'my-4'}>
          <h3 className={'flipbook-popover-text-sub-header'}>
            Hide Empty Pages
          </h3>
          <div className={'flex-align-center ml-2'}>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.isEmptyPagesHided}
                    onChange={(e: any) => handleEmptyPageChange(e)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    color="primary"
                  />
                }
                label="Hide"
              />
            </div>
          </div>
        </div>
        <div className={'my-4'}>
          <h3 className={'flipbook-popover-text-sub-header'}>
            Activate User Store Selection
          </h3>
          <div className={'flex-align-center ml-2'}>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.isUserStoreSelectionAvailable}
                    onChange={handleUserStoreSelectionChange}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    color="primary"
                  />
                }
                label="Activate"
              />
            </div>
          </div>
        </div>
        <div className={'my-4 grouping-dropdown'}>
          <h3 className={'flipbook-popover-text-sub-header'}>
            Grouping Hierarchy
          </h3>
          <div className={'flex-align-center ml-2'}>
            <Autocomplete
              multiple
              limitTags={1}
              options={productHierarchy}
              value={
                !!productHierarchy &&
                !!state.groupingHierarchy &&
                (map(state.groupingHierarchy, gh =>
                  productHierarchy.find(ph => ph.casaFieldName === gh)
                ) as ProductHierarchyNew[])
              }
              onChange={(event, newValue) =>
                onChangeSelectedGroupingHierarchy(newValue)
              }
              disableCloseOnSelect
              getOptionLabel={(option: ProductHierarchyNew) =>
                option?.displayName
              }
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={
                      <CheckBoxIcon fontSize="small" className={classes.root} />
                    }
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.displayName}
                </React.Fragment>
              )}
              style={{ width: 500 }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select product"
                />
              )}
            />
          </div>
        </div>
        <div className={'my-4'}>
          <h3 className={'flipbook-popover-text-sub-header'}>
            {'Direct my customer to buy products from'}
          </h3>
          <div className={'ml-2'}>
            <RadioGroup
              aria-label="redirect"
              name="redirect"
              value={state.checkoutOption}
              onChange={handleRedirectValueChange}
            >
              {getAvailableCheckoutFlow()}
            </RadioGroup>
          </div>
        </div>
        <div className={'my-4'}>
          <h3 className={'flipbook-popover-text-sub-header mb-0'}>
            Upload Background image
          </h3>
          <div>
            <i className={'help-text'}>To Know more Click Help Icon</i>
          </div>
          <div className={'bg-img-upload-container  ml-2'}>
            <div
              className={'bg-img-upload-section'}
              style={{
                backgroundImage: `url(${state.evenBackgroundUrl})`
              }}
            >
              <UploadImage
                isActive={!_.isEmpty(state.evenBackgroundUrl)}
                imageUploadType={'backgroundImageEven'}
                setImageUploadData={e =>
                  onClickBackgroundImageUpload(e, 'backgroundImageEven')
                }
                iconStyle={{}}
              />
            </div>
            <div
              className={'bg-img-upload-section'}
              style={{
                backgroundImage: `url(${state.oddBackgroundUrl})`
              }}
            >
              <UploadImage
                isActive={!_.isEmpty(state.oddBackgroundUrl)}
                imageUploadType={'backgroundImageOdd'}
                setImageUploadData={e =>
                  onClickBackgroundImageUpload(e, 'backgroundImageOdd')
                }
                iconStyle={{}}
              />
            </div>
          </div>
        </div>
        <div className={'my-4 text-right'}>
          <Button
            variant="contained"
            disabled={applyState}
            color="primary"
            style={{ fontSize: 12, padding: 2 }}
            onClick={onClickSettingsApply}
          >
            Apply
          </Button>
        </div>
      </div>
    );
  }

  function handleSettingsPopoverOpen(value: any) {
    setAnchorEl(value);
    setIsSettingsPopoverOpen(true);
  }

  return (
    <div>
      <div className={'flipbook-bar-icon'}>
        <IconButton
          className={'outline-none'}
          style={{ color: getColor(isSettingsPopoverOpen) }}
          aria-label="delete"
          aria-describedby={anchorEl}
          onClick={e => handleSettingsPopoverOpen(e.currentTarget)}
        >
          <SettingsIcon />
        </IconButton>
      </div>
      <Popover
        id={anchorEl}
        open={isSettingsPopoverOpen}
        anchorEl={anchorEl}
        onClose={e => handleSettingsPopoverClose()}
        anchorOrigin={anchorOriginObj}
        transformOrigin={transformOriginObj}
      >
        {settingsPopoverComponent()}
      </Popover>
    </div>
  );
};

export default SettingsPopover;
