import React, { FC, useEffect, useState } from 'react';
import SKUTable from './SKUTable';
import { getUiFilterPayloadById } from '../../../../services/uiFilter.service';
import { CircularProgress, TextField } from '@material-ui/core';
import _ from 'lodash';
import { GoToNext, GoToPrevious } from '../HelperComponents/Button';
import { Autocomplete } from '@material-ui/lab';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { CSVLink } from 'react-csv';
import {
  AVAILABLE_BATCH_COUNT,
  LABELPRINTING_DATA
} from './../utils/Constants';
interface SkuListProps {
  payloadData: any;
  offset: number;
  setOffSet: any;
  count: number;
  setCount: any;
  currentPageNumber: number;
  setCurrentPageNumber: any;
  skuDetails: any;
  setSkuDetails: any;
}
const INVALID_ID = ['', null, undefined, -1, 0];
export const SkuList: FC<SkuListProps> = ({
  payloadData,
  offset,
  setCount,
  setOffSet,
  count,
  currentPageNumber,
  setCurrentPageNumber,
  skuDetails,
  setSkuDetails
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [csvData, setCsvData] = useState<any>([]);
  const CSV_HEADERS = ['SKU', 'Format', 'Layout', 'Version'];
  useEffect(() => {
    if (!INVALID_ID.includes(payloadData.id))
      getUiFilterPayloadById(
        payloadData.id,
        LABELPRINTING_DATA,
        offset,
        count
      ).then(result => {
        const skuData = _.get(result, 'data.payload.LabelSkuList', []);
        if (skuData.length === 0) {
          setCurrentPageNumber(0);
        }
        setSkuDetails(skuData);
        const CSV_DATA = _.map(skuData, (ele: any) => {
          return [ele.sku, ele.layout, ele.orientation, ele.version];
        });
        setCsvData(CSV_DATA);
        setIsLoading(false);
      });
  }, [
    count,
    offset,
    payloadData.id,
    setSkuDetails,
    payloadData,
    setCurrentPageNumber
  ]);
  const chunkSkuList: any = (array: any, size: number) => {
    if (array.length <= size) {
      return [array];
    }
    return [array.slice(0, size), ...chunkSkuList(array.slice(size), size)];
  };

  const getChunkSize = () => {
    if (skuDetails?.length % 3 === 2) {
      const size = skuDetails?.length - 2;
      return size;
    } else {
      const size = skuDetails?.length - 1;
      return size;
    }
  };

  const renderSKUList = () => {
    if (skuDetails?.length <= 6) {
      return <SKUTable data={skuDetails} />;
    } else {
      const chunkSize =
        skuDetails?.length % 2 !== 0 ? getChunkSize() : skuDetails?.length;
      const data = chunkSkuList(skuDetails, chunkSize / 2);
      if (data.length > 2) {
        const lastElement = data[data.length - 1];
        lastElement.map((ele: any, i: number) => {
          return data[i].push(ele);
        });
        data.splice(data.length - 1, 1);
      }
      return data?.map((sku: any, i: number) => (
        <SKUTable data={sku} key={i} />
      ));
    }
  };
  const getBatchForType = (pageNumber: number, newOffSet: number) => {
    const totalPages = Math.ceil(payloadData.length / count);
    if (pageNumber < 1 || pageNumber > totalPages) {
      return;
    }
    setIsLoading(true);
    setCurrentPageNumber(pageNumber);
    setOffSet(newOffSet);
  };
  return (
    <div>
      <div
        style={{
          height: '200px',
          marginTop: 30,
          padding: '0px 50px',
          display: 'flex',
          justifyContent: 'space-around',
          overflow: 'auto',
          width: '98%'
        }}
      >
        {isLoading ? (
          <div
            style={{
              color: '#F2755F',
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        ) : skuDetails.length > 0 ? (
          renderSKUList()
        ) : (
          'No sku available to render'
        )}
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '10%', margin: 'auto' }}>
          <CSVLink
            data={csvData}
            headers={CSV_HEADERS}
            filename={`template_sku.csv`}
            style={{
              width: 220,
              color: 'black',
              backgroundColor: '#ffffff',
              textAlign: 'right'
            }}
            className="hidden float-right"
          >
            <img src={require('../../assets/down-arrow.svg')} alt={'img'} />
            &nbsp; Download CSV
          </CSVLink>
        </div>
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            margin: '1rem',
            width: '60%'
          }}
        >
          <span
            onClick={() =>
              getBatchForType(currentPageNumber - 1, offset - count)
            }
            style={{
              cursor: `${currentPageNumber > 1 ? 'pointer' : 'not-allowed'}`
            }}
          >
            <GoToPrevious />
          </span>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {`${currentPageNumber}/${Math.ceil(payloadData.length / count)}`}
          </div>
          <span
            onClick={() =>
              getBatchForType(currentPageNumber + 1, offset + count)
            }
            style={{
              cursor: `${
                currentPageNumber < Math.ceil(payloadData.length / count)
                  ? 'pointer'
                  : 'not-allowed'
              }`
            }}
          >
            <GoToNext />
          </span>
        </div>
        <div
          style={{
            textAlign: 'left',
            margin: 'auto',
            fontSize: '14px'
          }}
        >
          <Autocomplete
            id="combo-box-demo"
            options={AVAILABLE_BATCH_COUNT}
            size={'small'}
            getOptionLabel={option => String(option)}
            style={{ width: 120, margin: '5px 0px' }}
            disableClearable={true}
            defaultValue={count}
            renderInput={params => (
              <TextField
                {...params}
                label="Count"
                variant="outlined"
                size={'small'}
              />
            )}
            onChange={(event, value) => {
              setCurrentPageNumber(1);
              setOffSet(0);
              setCount(Number(value));
            }}
          />
        </div>
      </div>
    </div>
  );
};
