import React from 'react';
import { Button, Paper, TextField } from '@material-ui/core';
import './index.css';
import { getCurrentUser } from '../../services/user.service';
import ReferralService from '../../services/referral.service';

class CromaRseReferral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      inviteeNumber: ''
    };
  }

  componentDidMount = async () => {
    this.setState({ user: await getCurrentUser() });
  };

  getLink = async () => {
    const { user, inviteeNumber } = this.state;
    const { id, tenantId } = user || {};
    const data = { userId: id, tenantId, inviteeNumber };

    this.setState({ link: await ReferralService.getLinkForUser(data) });
  };

  render() {
    const { inviteeNumber } = this.state;

    return (
      <div style={{ margin: '0 10%' }}>
        <h3 style={{ margin: '30px 0px' }}>Send Invite</h3>
        <Paper>
          <div style={{ width: '90%', margin: '0 auto', padding: 30 }}>
            <div>
              <img
                src="https://rules.casa.croma.com/public-html/cromapromo/refer_a_friend_image.jpg"
                alt="logo"
                style={{ width: '100%', marginBottom: 30 }}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>Enter the number to invite:</div>
              <TextField
                value={inviteeNumber}
                type="number"
                onChange={event => {
                  if (event.target.value.length <= 10)
                    this.setState({ inviteeNumber: event.target.value });
                }}
                inputProps={{ style: { textAlign: 'center' } }}
                style={{ width: '50%', marginLeft: 15, flex: 1 }}
              />
              <div style={{ flex: 1 }} />
            </div>
          </div>
          <div style={{ textAlign: 'center', paddingBottom: 50 }}>
            <Button
              variant="contained"
              color="primary"
              className="pageButton"
              onClick={() => this.getLink()}
            >
              <span className="buttonText">Send Link</span>
            </Button>
          </div>
        </Paper>
      </div>
    );
  }
}

export default CromaRseReferral;
