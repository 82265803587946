import React, { FC, useContext, useEffect } from 'react';
import { isEcomUserLoggedIn } from '../Utiles/FlipbookUtiles';
import { Drawer, IconButton, Menu } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import LoginForm from '../CheckoutForm/LoginForm';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import './FlipbookAppBar.css';
import ProfileIcon from '../../Assets/Profile.svg';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { FlipbookCartContext } from '../Hooks/FlipbookCartContext';
import { UserContext } from '../../../hooks/UserContext';
import { toast } from 'react-toastify';
import { FlipbookPagesContext } from '../Hooks/FlipbookPagesContext';

interface LoginComponentProps {
  currentStore: any;
  setCurrentStore: (store: any) => void;
}

export const LoginComponent: FC<LoginComponentProps> = ({
  currentStore,
  setCurrentStore
}) => {
  const flipbookCartContext = useContext(FlipbookCartContext);
  const { isUserLoggedIn, setIsUserLoggedIn } = flipbookCartContext;
  const setCartItems = flipbookCartContext.setCartItems;
  const userInfo = _.get(useContext(UserContext), 'userInfo');
  const [mobileNumber, setMobileNumber] = React.useState('');
  const flipbookPagesContext = useContext(FlipbookPagesContext);
  const [isLoginFormOpen, setIsLoginFormOpen] = React.useState<boolean>(false);
  const [isMobileScreen, setIsMobileScreen] = React.useState<boolean>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isEmpty(isMobileScreen)) {
      const isMobileView = window.screen.width <= 600;
      setIsMobileScreen(isMobileView);
    }
  }, [isMobileScreen]);

  useEffect(() => {
    if (isEmpty(isUserLoggedIn)) {
      setIsUserLoggedIn(isEcomUserLoggedIn());
    }
    // eslint-disable-next-line
  }, [isUserLoggedIn]);

  const handleModalClose = () => {
    setIsLoginFormOpen(false);
  };

  const onSuccessLogin = () => {
    setIsUserLoggedIn(true);
    setIsLoginFormOpen(false);
    flipbookPagesContext.updateFlipbookPages();
  };

  const getLoginPopUp = () => {
    return (
      <div>
        <LoginForm
          setCartItems={setCartItems}
          tenantName={_.get(userInfo, 'esKey', '')}
          setSkipLoginStep={e => setIsLoginFormOpen(false)}
          setMobileNumber={setMobileNumber}
          mobileNumber={mobileNumber}
          setShowNavigationBtn={_.noop}
          setIsNextEnabled={() => onSuccessLogin()}
          storeCode={_.get(currentStore, 'store', '')}
        />
      </div>
    );
  };
  const onClickLogout = () => {
    localStorage.clear();
    setIsUserLoggedIn(false);
    setCartItems([]);
    setCurrentStore(undefined);
    flipbookCartContext.setIsSessionActivated(false);
    flipbookPagesContext.updateFlipbookPages();
  };
  const onClickSignIn = () => {
    if (isEmpty(_.get(currentStore, 'store', ''))) {
      toast.error('Please choose a store to Log In');
      return;
    }
    setIsLoginFormOpen(true);
  };

  return (
    <div className={'login-component'}>
      {isMobileScreen ? (
        <React.Fragment key={'bottom'}>
          <Drawer
            anchor={'bottom'}
            open={isLoginFormOpen}
            onClose={() => handleModalClose()}
          >
            {getLoginPopUp()}
          </Drawer>
        </React.Fragment>
      ) : (
        <Modal
          open={isLoginFormOpen}
          aria-labelledby="simple-modal-title"
          onClose={() => handleModalClose()}
          aria-describedby="simple-modal-description"
        >
          <div className={'login-form-model'}>
            <div className={'login-form-model-container'}>
              {getLoginPopUp()}
            </div>
          </div>
        </Modal>
      )}
      <div>
        <IconButton
          id="fade-button"
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <img alt={'Profile'} src={ProfileIcon} />
        </IconButton>
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button'
          }}
          anchorEl={anchorEl}
          open={open}
          style={{ top: '50px', left: '0px' }} // Adjust based on your needs
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          {!isUserLoggedIn ? (
            <MenuItem onClick={() => onClickSignIn()}>Sign In</MenuItem>
          ) : (
            <MenuItem onClick={() => onClickLogout()}>Logout</MenuItem>
          )}
        </Menu>
      </div>
    </div>
  );
};
