import { TileData } from './TileData.model';

export class TileDataVideo extends TileData {
  public URL: string;
  public isMuted: boolean;
  public isAutoPlay: boolean;
  public isControlsEnabled: boolean;

  constructor(
    URL: string,
    isMuted: boolean,
    isAutoPlay: boolean,
    isControlsEnabled: boolean
  ) {
    super();
    this.URL = URL;
    this.isMuted = isMuted;
    this.isAutoPlay = isAutoPlay;
    this.isControlsEnabled = isControlsEnabled;
  }
}
