import React, { FC, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { Typography } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface KPILoaderDialogProps {
  shouldOpenLoader: boolean;
  setShouldOpenLoader: (val: boolean) => void;
  type: string;
  name: string;
}

export const KPILoaderDialog: FC<KPILoaderDialogProps> = ({
  shouldOpenLoader,
  setShouldOpenLoader,
  type,
  name
}) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(shouldOpenLoader);
  }, [shouldOpenLoader]);

  const handleClose = () => {
    setOpen(false);
    setShouldOpenLoader(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: '1.4rem'
            }}
          >
            {name}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <img
            src={require(type === 'LOADER'
              ? './Assets/DataLoadingAnimation.gif'
              : './Assets/ErrorMultipleDownload.gif')}
            alt="Data Loading Animation"
            style={{ width: '100%', height: 'auto', maxWidth: '100%' }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleClose}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
