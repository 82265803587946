/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { BaseNodeModel } from '../BaseNodeModel/BaseNodeModel';
import NodeTypes from '../BaseNodeModel/NodeTypes';
import Specs from '../BaseNodeModel/SpecsModel';
import * as PortModels from '../BaseNodeModel/PortModels';

export class StopNodeModel extends BaseNodeModel {
  constructor(options = {}) {
    super({
      type: NodeTypes.STOP_NODE,
      ...options
    });
    this.specs = [
      new Specs(
        'Will this be considered as positive',
        'dropdown',
        'Will this be considered as positive',
        ['Yes', 'No']
      )
    ];
    this.buildPorts([new PortModels.InPort(), new PortModels.OutPort()]);
  }

  setInputFields(specs) {
    this.isDetailsSaved = this.isAllDetailsFilled(specs);
    this.isPositive = specs[0].value === 'Yes';
  }
  serialize() {
    return {
      ...super.serialize(),
      isPositive: this.isPositive
    };
  }

  deserialize(ob, engine) {
    super.deserialize(ob, engine);
    this.isPositive = ob.data.isPositive;
  }
}
