import {
  FlipbookLayoutTile,
  TileDataType
} from '../../../../models/FlipbookV2/FlipbookV2LayoutTile.model';
import isEmpty from 'lodash/isEmpty';
import { TileData } from '../../../../models/FlipbookV2/TileData/TileData.model';
import get from 'lodash/get';

enum PathOfValue {
  'currentPageSKUS' = 'currentPageSKUS',
  'imageData.URL' = 'imageData.URL',
  'URL' = 'URL'
}
export const isTileHasValue = (
  flipbookLayoutTile: FlipbookLayoutTile | undefined
) => {
  if (flipbookLayoutTile === undefined) {
    return false;
  }
  switch (flipbookLayoutTile.tileDataType) {
    case TileDataType.COLLECTION:
      return isTileDataHasValue(
        flipbookLayoutTile.tileData,
        PathOfValue.currentPageSKUS
      );
    case TileDataType.PERSONALISED:
      return isTileDataHasValue(
        flipbookLayoutTile.tileData,
        PathOfValue.currentPageSKUS
      );
    case TileDataType.IMAGE:
      return isTileDataHasValue(
        flipbookLayoutTile.tileData,
        PathOfValue['imageData.URL']
      );
    case TileDataType.VIDEO:
      return isTileDataHasValue(flipbookLayoutTile.tileData, PathOfValue.URL);
    default:
      return false;
  }
};

const isTileDataHasValue = (tileData: TileData, pathOfValue: PathOfValue) => {
  const value = get(tileData, pathOfValue);
  return !isEmpty(value);
};
