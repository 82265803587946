import config from '../config';

function saveLiveReportDetails(businessUnit, data) {
  const url = new URL(
    `${config.ruleServerUrl}/users/tenant/tenant-brand-business/${businessUnit}/reportsConfig`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

function getLiveReportDetails(businessUnit) {
  const url = new URL(
    `${config.ruleServerUrl}/users/tenant/tenant-brand-business/${businessUnit}/reportsConfig`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
}

const saveLiveReportConfig = (businessUnit, data) => {
  return saveLiveReportDetails(businessUnit, data);
};

const getLiveReportConfig = businessUnit => {
  return getLiveReportDetails(businessUnit);
};

export default {
  saveLiveReportConfig,
  getLiveReportConfig
};
