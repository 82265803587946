export enum MemberReportTypes {
  ENROLL = 'enroll',
  TRANSACTION = 'transaction'
}

export default interface MemberWiseReportDownload {
  id: number;
  tenantId: number;
  storeId: number;
  fileVersion: string;
  startTime: string;
  endTime: string | null;
  status: 'COMPLETED' | 'PENDING' | 'FAILED' | 'STARTED';
  total: number;
  batchCount: number;
  processedRecords: number;
  createdAt: string;
  updatedAt: string;
  errorFileLocation: string;
  data: {
    date: {
      startDate: string;
      endDate: string;
    };
    endDate: string;
    startDate: string;
    page: number;
    offset: number;
    storeId: number;
    tenantId: number;
    isPaginated: boolean;
    tenantEsKey: string;
    businessUnitId: string;
  };
}
