import { Box, Button, ButtonProps, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';

import styles from './LoadingButton.module.scss';

interface LoadingButtonProps extends ButtonProps {
  loading: boolean;
}

const LoadingButton: FC<LoadingButtonProps> = ({
  children,
  disabled,
  loading,
  className,
  id,
  ...rest
}) => {
  return (
    <Button
      {...rest}
      id={clsx(styles.loadingButton, id)}
      className={clsx({ [styles.loading]: loading }, className)}
      disabled={loading || disabled}
    >
      {children}
      {loading && (
        <Box className={styles.loaderContainer}>
          <CircularProgress className={styles.progess} size={16} />
        </Box>
      )}
    </Button>
  );
};

export default LoadingButton;
