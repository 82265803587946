import React, { useEffect, useRef, useState, useContext } from 'react';
import { Offer } from '../../models/offer/Offer.model';
import PropTypes from 'prop-types';
import { OfferDescriptionStage } from './OfferDescriptionStage';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import _ from 'lodash';
import { OfferDefinitionStage } from './OfferDefinitionStage';
import { Business } from '../../models/User.model';
import useCurrentUser from '../../hooks/useCurrentUser';
import { BusinessUnitContext } from '../OfferDefinition/BusinessUnitContext';
import makeStyles from '@material-ui/core/styles/makeStyles';
import offerService from '../../services/offer.service';
import OfferUtils from '../../utils/OfferUtils';
import Backdrop from '@material-ui/core/Backdrop';
import { CircularProgress, createStyles } from '@material-ui/core';
import { AfterSaveDialog } from './AfterSaveDialog';
import RowCriteriaContext from './RowCriteriaContext';
import { ReadOnlyContext } from './ReadOnlyContext';
// import { OfferType } from '../../constants';

const useStyles = makeStyles(theme => ({
  stepLabelContainer: {
    fontWeight: 'bold',
    textTransform: 'uppercase'
  }
}));

const useBackdropStyles = makeStyles(theme =>
  createStyles({
    root: {
      color: '#fff',
      position: 'absolute',
      zIndex: theme.zIndex.drawer - 1,
      backgroundColor: 'rgba(222, 222, 222, 0.5)',
      opacity: 0.5
    }
  })
);

export const OfferDetail = props => {
  const { readOnly, cloneOffer } = useContext(ReadOnlyContext);
  const [offer, setOffer] = useState(new Offer());
  const [activeStep, setActiveStep] = useState(0);
  const [showAfterSaveModal, setShowAfterSaveModal] = useState(false);
  const [isSaveSuccess, setIsSaveSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [business, setBusiness] = useState(new Business());
  const [rows, setRows] = useState([
    { from: '', to: '', value: '', maxDiscount: '', key: 0 }
  ]);
  const [selectedOfferCategory, setSelectedOfferCategory] = useState(null);
  const classes = useStyles();
  const backdropClasses = useBackdropStyles();

  const user = useCurrentUser();

  const steps = ['Offer Description', 'Offer Definition'];

  const changeOfferField = (fieldName, value) => {
    setOffer(prevOffer => prevOffer.copyWith({ [fieldName]: value }));
  };

  const triggerOfferSave = async () => {
    let stopped = false;
    try {
      const offerJson = OfferUtils.validateSaveOfferJson(offer);
      if (offerJson.eligibility === 'BILL_VALUE') {
        const rowLength = rows.length - 1;
        const values = [...rows];
        const discountType =
          offerJson.offerDefinition.billDiscount.discountType;
        const isValid = OfferUtils.validateConditionsAndSetErrors(
          0,
          rowLength,
          values,
          discountType
        );
        if (!isValid) {
          setRows(values);
          stopped = true;
          return;
        }
        OfferUtils.updateRowCriteriaInBillAggregateCondition(
          offerJson,
          rows,
          discountType
        );
      }
      setIsLoading(true);
      await offerService.saveOffer(offerJson);
      setIsSaveSuccess(true);
    } catch (e) {
      setIsSaveSuccess(false);
    } finally {
      if (!stopped) {
        setIsLoading(false);
        setShowAfterSaveModal(true);
      }
    }
  };

  const onClickSaveDraft = async () => {
    const offerObj = offer;
    offerObj.status = 1;
    setOffer(offerObj);
    await triggerOfferSave();
  };

  const onClickSave = async () => {
    const offerObj = offer;
    offerObj.status = 0;
    setOffer(offerObj);
    await triggerOfferSave();
  };
  useEffect(() => {
    setOffer(props.offer);
    if (readOnly || props.offer.status === 1 || cloneOffer) {
      const rowData = OfferUtils.getRowCriteria(props.offer);
      rowData.length > 0 && setRows(rowData);
    }
  }, [cloneOffer, props.offer, readOnly]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsBuId = _.parseInt(urlParams.get('bu'));
    const paramsBusiness = _(user.getBusinesses()).find(
      b => b.id === paramsBuId
    );
    if (paramsBusiness) setBusiness(paramsBusiness);
  }, [user]);

  const topRef = useRef(null);

  useEffect(() => {
    if (topRef) {
      topRef.current.scrollIntoView();
    }
  }, [activeStep]);
  return (
    <RowCriteriaContext.Provider value={{ rows, setRows }}>
      <div
        style={{ padding: 50, position: 'relative', background: '#F9F9F9' }}
        ref={topRef}
      >
        <BusinessUnitContext.Provider value={{ business: business }}>
          <Paper style={{ padding: 50 }}>
            <Stepper activeStep={activeStep} alternativeLabel color={'primary'}>
              {_.map(steps, step => (
                <Step key={step}>
                  <StepLabel>
                    <span className={classes.stepLabelContainer}>{step}</span>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 && (
              <OfferDescriptionStage
                offer={offer}
                changeOfferField={changeOfferField}
                onClickNext={() => setActiveStep(prevStep => prevStep + 1)}
                selectedOfferCategory={selectedOfferCategory}
                onSelectOfferCategory={setSelectedOfferCategory}
              />
            )}
            {activeStep === 1 && (
              <OfferDefinitionStage
                offer={offer}
                setOffer={setOffer}
                changeOfferField={changeOfferField}
                onClickPrevious={() => setActiveStep(prevStep => prevStep - 1)}
                onClickSave={onClickSave}
                onClickSaveDraft={onClickSaveDraft}
                rows={rows}
              />
            )}
          </Paper>
        </BusinessUnitContext.Provider>
        <Backdrop
          open={isLoading}
          style={{ position: 'absolute' }}
          className={backdropClasses.root}
        >
          <CircularProgress />
        </Backdrop>
        {showAfterSaveModal && (
          <AfterSaveDialog
            isSaveSuccess={isSaveSuccess}
            isDraftSave={offer.status === 1}
            redirectCountdown={10}
          />
        )}
      </div>
    </RowCriteriaContext.Provider>
  );
};

OfferDetail.propTypes = {
  offer: PropTypes.instanceOf(Offer).isRequired
};

OfferDetail.defaultProps = {
  offer: new Offer()
};
