/* eslint-disable @typescript-eslint/explicit-function-return-type */
import config from '../config/index';

const journeyConfigURL = `${config.ruleServerUrl}/users/journey-configs`;
export async function saveJourneyConfig(data) {
  return await fetch(journeyConfigURL, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(r => {
      return r.json();
    })
    .catch(err => {
      console.log(err);
    });
}

export async function updateJourneyConfig(data, id) {
  return await fetch(`${journeyConfigURL}/${id}`, {
    credentials: 'include',
    method: 'PUT',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(r => {
      return r.json();
    })
    .catch(err => {
      console.log(err);
    });
}

export async function getJourney() {
  return await fetch(journeyConfigURL, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json'
    }
  })
    .then(r => {
      return r.json();
    })
    .catch(err => {
      console.log(err);
    });
}

export async function getJourneyConfig(id) {
  return await fetch(`${journeyConfigURL}/${id}`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json'
    }
  })
    .then(r => {
      return r.json();
    })
    .catch(err => {
      console.log(err);
    });
}
