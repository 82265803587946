import React, { Context } from 'react';
import { Offer } from '../../models/offer/Offer.model';
import { Product } from '../../models/CartWrapper';
import _ from 'lodash';

const emptyList: Product[] = [];

export class BucketProducts {
  public bucketProducts: { [bucketId: number]: Product[] } = {};

  public getProducts(bucketId: number): Product[] {
    return this.bucketProducts[bucketId] || emptyList;
  }

  public setProducts(
    bucketId: number,
    products: Product[] = emptyList
  ): BucketProducts {
    return _.extend(new BucketProducts(), {
      bucketProducts: { ...this.bucketProducts, [bucketId]: products }
    });
  }
}

type OfferCartBuilderContextType = {
  offer: Offer;
  customerMobile?: string;
  bucketProducts: BucketProducts;
};

export const OfferCartBuilderContext: Context<OfferCartBuilderContextType> = React.createContext<
  OfferCartBuilderContextType
>({
  offer: new Offer(),
  bucketProducts: new BucketProducts()
});
