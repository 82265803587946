import React from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';
import '../../styles/RadioButton.scss';

const RadioButton = ({
  checked = false,
  position = 'end',
  label,
  onCheck,
  content = ''
}) => (
  <>
    <FormControlLabel
      checked={checked}
      value={position}
      control={<Radio className="radio" color="primary" />}
      label={label}
      onClick={onCheck}
    />
    <div style={{ textAlign: 'center' }}>{content}</div>
  </>
);

export { RadioButton };
