module.exports = {
  dashboardUrl: 'https://dashboard.qa.casaretail.ai',
  ruleServerUrl: 'https://kapi.qa.casaretail.ai',
  casaWebAppUrl: 'https://web.qa.casaretail.ai',
  casaEcomUrl: 'https://ecom.qa.casaretail.ai',
  casaBeatUrl: 'https://beat-api.qa.casaretail.ai',
  pwaUrl: 'https://shoppersdelight.qa.casaretail.ai',
  ruleUiUrl: 'https://rules.qa.casaretail.ai',
  commerceConnect: 'http://commerce-connect.qa.casaretail.ai',
  turnedOffFeatures: [],
  keycloakConfig: {
    url: `https://auth.qa.casaretail.ai/auth`,
    realm: `casa`,
    clientId: `casa_web_app`
  }
};
