/* eslint-disable @typescript-eslint/explicit-function-return-type */

import config from '../config/index';
export async function savePwaConfig(data, businessUnitId) {
  return await fetch(
    `${config.ruleServerUrl}/users/pwa-config?businessUnitId=${businessUnitId}`,
    {
      credentials: 'include',
      method: 'POST',
      body: data
    }
  )
    .then(r => {
      return r.json();
    })
    .catch(err => {
      console.log(err);
    });
}

export async function getPwaConfig(businessUnitId) {
  const url = new URL(
    `${config.ruleServerUrl}/users/pwa-config?businessUnitId=${businessUnitId}`
  );
  return await fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return err;
    });
}

export async function getvalues() {
  const url = new URL(`${config.ruleServerUrl}/users/pwa-config/options`);

  return await fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return err;
    });
}

function getFileServerUrl(fileName, tenant) {
  return `${config.ruleUiUrl}/public-html/${tenant}/${fileName}`;
}

export async function saveFile(html, tenant) {
  const formData = new FormData();
  formData.append('uploadFile', html);

  const url = new URL(`${config.ruleServerUrl}/users/data/fileLoad`);
  let fileUrl = '';

  await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Origin: 'https://rules.casaqa.ajira.tech'
    },
    body: formData
  })
    .then(() => {
      fileUrl = getFileServerUrl(html.name, tenant);
    })
    .catch(error => {
      console.error(error);
    });
  return fileUrl;
}
