import React, { FC, useState } from 'react';
import CampaignFilter from './Filter';
import CampaignDownloadTable from './List';
import usePagination from '../../../shared/hooks/usePagination';
import { getCampaignReportDownload } from '../../../services/TenantReports/CampaignReport.service';

const CampaignTenantReport: FC = () => {
  const [refreshView, setRefreshView] = useState<string>('');
  const {
    data,
    isLoading,
    page,
    offset,
    setPage,
    setOffset
  } = usePagination(getCampaignReportDownload, [refreshView]);
  return (
    <div style={{ padding: '2rem' }}>
      <div>
        <h4>Campaign Report</h4>
      </div>
      <CampaignFilter setRefreshView={setRefreshView} />
      <CampaignDownloadTable
        reports={data || []}
        isLoading={isLoading}
        page={page}
        offset={offset}
        setPage={setPage}
        setOffset={setOffset}
      />
    </div>
  );
};

export default CampaignTenantReport;
