/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Breadcrumbs, Typography } from '@material-ui/core';
import MaterialCloseIcon from '@material-ui/icons/Close';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React, { useState } from 'react';

import ButtonField from '../../reusable/ButtonField';
import { TextFieldComponent } from '../../reusable/TextFieldComponent';
import TenentStoreService from '../../services/tenentStore.service';
import { ParentLink } from '../../utils/ParentLink';
import AddButton from '../Assets/images/icons/add_icon_round.svg';
import CloseIcon from '../Assets/images/icons/cancel_icon.svg';
import WarningIcon from '../Assets/warningVector.png';
import storeClass from '../StoreManagement/index.module.scss';

const ProductManagement = () => {
  const [inputList, setInputList] = useState([{ level: '' }]);
  // const [business, setBusiness] = useState(new Business());
  // const user = useCurrentUser();
  const [warningMessage, setWarningMessage] = useState(true);
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { level: '' }]);
  };

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleSubmit = () => {
    const data = {
      categories: inputList.map(item => {
        return item.level;
      })
    };
    TenentStoreService.saveProductConfig(data).then(res => {
      console.log(res, 'res');
    });
  };

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const paramsBuId = _.parseInt(urlParams.get('bu'));
  //   const paramsBusiness = _(user.getBusinesses()).find(
  //     b => b.id === paramsBuId
  //   );
  //   if (paramsBusiness) setBusiness(paramsBusiness);
  // }, [user]);

  return (
    <div className="App">
      <div className={storeClass.storeImage}>
        <Typography variant={'h4'} className={storeClass.storeTitle}>
          Product Management
        </Typography>
      </div>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label={storeClass.breadcrumb}
        className={storeClass.breadcrumbTitle}
      >
        <ParentLink
          color="inherit"
          to={'/general-setting'}
          className={storeClass.breadcrumbLink}
        >
          General Settings
        </ParentLink>
        <Typography
          color="textPrimary"
          style={{ fontSize: '16px', fontFamily: 'Nunito' }}
        >
          Product Management
        </Typography>
      </Breadcrumbs>
      {warningMessage && (
        <div className={storeClass.warningContainer}>
          <div
            className={storeClass.materialCloseIcon}
            onClick={() => setWarningMessage(false)}
          >
            <MaterialCloseIcon style={{ cursor: 'pointer', fontSize: 22 }} />
          </div>
          <img
            src={WarningIcon}
            alt={'WarningIcon'}
            style={{ width: '30px', height: '30px', marginBottom: 20 }}
          />
          <div style={{ marginLeft: 15 }}>
            <h6 style={{ fontWeight: 700, marginBottom: 4 }}>
              Double Check the Spelling!!!
            </h6>
            <div>
              <span>
                The product hierarchy needs to be fixed exactly as needed and
                with proper name. If you wish to change anything in the
                hierarchy, continue editing.
              </span>
              <span style={{ color: '#B5342A', fontWeight: 600 }}>
                &nbsp;Once saved, the hierarchy cannot be changed from the UI.
              </span>
            </div>
          </div>
        </div>
      )}
      <div className={storeClass.productTitle}>
        Enter your product hierarchy
      </div>
      {inputList.map((x, i) => {
        return (
          <div className={storeClass.inputListContainer} key={i}>
            <TextFieldComponent
              name="level"
              label={`Level ${i + 1}`}
              value={x.level}
              onChange={e => handleInputChange(e, i)}
              style={{
                width: '300px',
                marginTop: 25,
                position: 'relative',
                left: `${100 * i}px`
              }}
            />
            {/* review  */}
            <span
              style={{
                marginTop: '-45px',
                marginLeft: `${13 * (i - 1)}px`,
                position: 'relative',
                left: `${88 * i}px`,
                cursor: 'pointer'
              }}
            >
              {inputList.length - 1 === i && inputList.length - 1 > 0 && (
                <img
                  src={CloseIcon}
                  alt="closeIcon"
                  onClick={handleRemoveClick}
                  style={{ height: '20px' }}
                />
              )}
            </span>
            <span
              style={{
                marginLeft: '30px',
                marginTop: 25,
                position: 'relative',
                left: `${80 * i}px`,
                cursor: 'pointer'
              }}
            >
              {inputList.length - 1 === i && (
                <img
                  src={AddButton}
                  alt="addIcon"
                  onClick={handleAddClick}
                  style={{ height: '52px' }}
                />
              )}
            </span>
          </div>
        );
      })}
      <div className="submitButton">
        <ButtonField
          variant="contained"
          label={'Save'}
          type="submit"
          // review
          style={{
            background: '#F07663',
            color: 'white',
            margin: '20px 30px'
          }}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default ProductManagement;
