import * as yup from 'yup';

export const redemptionOtpToSchema = () => {
  return yup.object().shape({
    permitRedeemption: yup.number().min(1),
    minimumRedeemptionLimit: yup.number().min(1),
    maximumRedeemptionLimit: yup.number().min(1)
  });
};

export const otpConfigToSchema = () => {
  const messages = {
    templateId: ['Template Id is Required']
  };

  return yup.object().shape({
    templateId: yup
      .string()
      .trim()
      .required(messages.templateId[0])
  });
};

export const otpBuLevelConfigToSchema = () => {
  const messages = {
    emailEnabled: ['emailEnabled is Required']
  };

  return yup.object().shape({
    emailEnabled: yup.boolean().required(messages.emailEnabled[0])
  });
};
