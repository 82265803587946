// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import SectionProps from '../../../models/SectionProps';
import Heading from '../../Heading/Heading';
import StripedTable from '../../StrippedTable/StripedTable';
import classes from './OrderList.module.css';

const OrderList: React.FC<SectionProps> = ({ data, billData }) => {
  // console.log('Inside order info', data, billData);
  const [products, setProducts] = useState([]);
  const [headings, setHeadings] = useState<string[]>([]);

  useEffect(() => {
    setProducts(_.get(billData, 'bill.products', []));
    setHeadings(_.map(_.get(data, 'fields', []), field => field.label));
  }, [billData, data]);

  return (
    <div className={classes.container}>
      {!_.isEmpty(products) && (
        <Heading heading={data.heading || 'ORDER DETAILS'} />
      )}

      <StripedTable
        headings={['Sr. No.', ...headings]}
        data={_.get(billData, 'bill.products', [])}
        configFields={data.fields}
      />
    </div>
  );
};

export default OrderList;
