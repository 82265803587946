/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import { styled } from '@material-ui/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import config from '../../config';
import useCurrentUser from '../../hooks/useCurrentUser';
import { Business } from '../../models/User.model';
import {
  getLoyaltySignUpValues,
  saveLoyaltySignUp
} from '../../services/pwaLoyaltySignUp.service';
import LoyaltySignup from '../Assets/images/Loyalty_Signup.svg';
import { LoginSwitch } from './PWAConfig';
import pwaConfigStyles from './PWAConfig.module.css';

const DEFAULT_FORM_CONFIG = {
  name: {
    name: 'name',
    placeHolder: 'Fieldname ex "NAME"',
    type: 'text',
    label: '',
    cardStyle: pwaConfigStyles.cardOff,
    switchValue: false,
    viewEdit: false,
    mandatory: false,
    readOnly: true
  },
  mobile: {
    name: 'mobile',
    placeHolder: 'Fieldname ex "MOBILE NUMBER"',
    type: 'number',
    label: 'mobile',
    cardStyle: pwaConfigStyles.cardOn,
    switchValue: true,
    viewEdit: true,
    mandatory: true,
    readOnly: false
  },
  email: {
    name: 'email',
    placeHolder: 'Fieldname ex "E-MAIL"',
    type: 'email',
    label: '',
    cardStyle: pwaConfigStyles.cardOff,
    switchValue: false,
    viewEdit: false,
    mandatory: false,
    readOnly: true
  },
  gender: {
    name: 'gender',
    placeHolder: 'Fieldname ex "GENDER"',
    type: 'select',
    label: '',
    cardStyle: pwaConfigStyles.cardOff,
    switchValue: false,
    viewEdit: false,
    mandatory: false,
    readOnly: true
  },
  birthday: {
    name: 'birthday',
    placeHolder: 'Fieldname ex "BirthDay"',
    type: 'date',
    label: '',
    cardStyle: pwaConfigStyles.cardOff,
    switchValue: false,
    viewEdit: false,
    mandatory: false,
    readOnly: true
  },
  anniversary: {
    name: 'anniversary',
    placeHolder: 'Fieldname ex "Anniversary"',
    type: 'date',
    label: '',
    cardStyle: pwaConfigStyles.cardOff,
    switchValue: false,
    viewEdit: false,
    mandatory: false,
    readOnly: true
  }
};
const Text = styled(props => <TextField {...props} />)(() => ({
  '& .MuiInputBase-input': {
    color: '#515151'
  },
  '& .MuiFilledInput-inputMarginDense': {
    paddingBottom: 15
  },
  '& .MuiFilledInput-root': {
    backgroundColor: '#f8f9fa'
  }
}));

const LoyaltySignUp = () => {
  const user = useCurrentUser();
  const [isSaving, setIsSaving] = useState(false);
  const [business, setBusiness] = useState(new Business());
  const [formConfig, setFormConfig] = useState(DEFAULT_FORM_CONFIG);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsBuId = _.parseInt(urlParams.get('bu'));
    const paramsBusiness = _(user.getBusinesses()).find(
      b => b.id === paramsBuId
    );
    if (paramsBusiness) setBusiness(paramsBusiness);
  }, [user]);

  const initializeLoyaltyFormData = () => {
    const businesId = _.get(business, 'id');
    businesId &&
      getLoyaltySignUpValues(businesId).then(res => {
        const updatedFormConfig = _.cloneDeep(DEFAULT_FORM_CONFIG);
        _.forEach(res, fieldConfig => {
          updatedFormConfig[fieldConfig.name] = {
            ...updatedFormConfig[fieldConfig.name],
            ...fieldConfig,
            cardStyle: pwaConfigStyles.cardOn,
            switchValue: true,
            viewEdit: true,
            readOnly: false
          };
        });
        console.log('updatedFormConfig', updatedFormConfig);
        setFormConfig(updatedFormConfig);
      });
  };
  useEffect(() => {
    initializeLoyaltyFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);

  const handleEdit = (val, obj, title) => {
    setFormConfig(prevState => {
      return {
        ...prevState,
        [title]: { ...obj, label: val, readOnly: false }
      };
    });
  };

  const handleSwitch = (event, param1, configFieldKey) => {
    const { checked } = event.target;
    if (checked) {
      setFormConfig(prevState => {
        return {
          ...prevState,
          [configFieldKey]: {
            ...param1,
            switchValue: checked,
            viewEdit: checked,
            cardStyle: pwaConfigStyles.cardOn
          }
        };
      });
    } else {
      setFormConfig(prevState => {
        return {
          ...prevState,
          [configFieldKey]: {
            ...param1,
            switchValue: checked,
            viewEdit: checked,
            cardStyle: pwaConfigStyles.cardOff,
            readOnly: true
          }
        };
      });
    }
  };

  const handleChange = (e, title, obj) => {
    const { value } = e.target;
    setFormConfig(prevState => {
      return {
        ...prevState,
        [title]: { ...obj, label: value }
      };
    });
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    console.log('formConfig', formConfig);
    const updatedConfigFields = [];
    _.forEach(formConfig, value => {
      const label = _.isEmpty(value.label)
        ? DEFAULT_FORM_CONFIG[value.name].name
        : value.label;
      if (value.switchValue)
        updatedConfigFields.push({
          name: value.name,
          label: label,
          mandatory: value.mandatory,
          fieldtype: value.type
        });
    });
    await saveLoyaltySignUp(updatedConfigFields, business.id);
    setIsSaving(false);

    const src = new URL(config.dashboardUrl + '/personify');
    const params = new URLSearchParams(window.location.search);
    params.forEach(
      (value, key) =>
        key === 'bu' &&
        !src.searchParams.has(key) &&
        src.searchParams.append(key, value)
    );
    src.searchParams.append('ref', 'v1');
    window.parent.postMessage(
      {
        navigateTo: `${src.pathname}${src.search}`
      },
      config.dashboardUrl
    );
  };

  if (!_.get(business, 'id')) {
    return null;
  }
  const CustomColorCheckbox = withStyles({
    root: {
      '&$checked': {
        color: '#F26E5F'
      }
    },
    checked: {}
  })(props => <Checkbox color="default" {...props} />);
  return (
    <>
      <center>
        <div>
          <img
            src={LoyaltySignup}
            alt=""
            className={pwaConfigStyles.pwaBanner}
          />
        </div>
        <Grid style={{ padding: '3% 10%' }}>
          {_.values(formConfig).map((fieldConfig, idx) => {
            const {
              name,
              placeHolder,
              label,
              readOnly,
              cardStyle,
              switchValue,
              viewEdit,
              mandatory
            } = fieldConfig;
            return (
              <Card className={cardStyle} key={idx}>
                <CardContent>
                  <div className={pwaConfigStyles.display}>
                    <Typography>{`${idx + 1}. ${_.capitalize(
                      name
                    )}`}</Typography>
                    <div>
                      {name === 'mobile' ? (
                        <Tooltip title=" It's Required field">
                          <IconButton>
                            <LoginSwitch
                              checked={switchValue}
                              name="nameSwitch"
                              disabled={name === 'mobile' && true}
                              onChange={e =>
                                handleSwitch(e, fieldConfig, fieldConfig.name)
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <LoginSwitch
                          checked={switchValue}
                          name="nameSwitch"
                          onChange={e =>
                            handleSwitch(e, fieldConfig, fieldConfig.name)
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className={pwaConfigStyles.margin}>
                    <Text
                      fullWidth
                      checked={switchValue}
                      name={name}
                      placeholder={placeHolder}
                      variant="filled"
                      size="small"
                      value={label}
                      onChange={e => handleChange(e, name, fieldConfig)}
                      InputProps={{
                        readOnly: readOnly,
                        endAdornment: (
                          <Tooltip
                            title="Tap this icon to edit the default display name"
                            placement="right"
                          >
                            <IconButton
                              disabled={name === 'mobile' && true}
                              onClick={() =>
                                handleEdit(
                                  fieldConfig.name,
                                  fieldConfig,
                                  fieldConfig.name
                                )
                              }
                            >
                              {viewEdit && (
                                <Edit
                                  style={{
                                    fill: readOnly ? 'currentcolor' : '#F07663'
                                  }}
                                />
                              )}
                            </IconButton>
                          </Tooltip>
                        )
                      }}
                    />
                  </div>
                  {viewEdit && (
                    <div className={pwaConfigStyles.margin}>
                      <FormGroup style={{ flexDirection: 'row' }}>
                        <FormControlLabel
                          control={<CustomColorCheckbox />}
                          label="Make it a Mandatory Field"
                          value={mandatory}
                          checked={mandatory}
                          disabled={name === 'mobile' && true}
                          onChange={e => {
                            setFormConfig(prevState => {
                              return {
                                ...prevState,
                                [fieldConfig.name]: {
                                  ...fieldConfig,
                                  mandatory: e.target.checked
                                }
                              };
                            });
                          }}
                        />
                      </FormGroup>
                    </div>
                  )}
                </CardContent>
              </Card>
            );
          })}
        </Grid>
        <div style={{ marginBottom: 20 }}>
          {isSaving ? (
            <div className={pwaConfigStyles.loader}></div>
          ) : (
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              SAVE
            </Button>
          )}
        </div>
      </center>
    </>
  );
};

export default LoyaltySignUp;
