import { Box, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { FC, useState } from 'react';
import styles from './CampaignReportHeader.module.scss';

const CampaignReportHeader: FC<{}> = () => {
  const [showAlert, setShowAlert] = useState(true);
  return (
    <Box component="header" className={styles.campaignReportHeader}>
      <Typography
        variant="h4"
        component="h1"
        color="primary"
        className={styles.campaignReportTitle}
      >
        Download your reports
      </Typography>
      {showAlert && (
        <Alert
          className={styles.campaignReportAlert}
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={setShowAlert.bind(null, false)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle className={styles.campaignReportAlertTitle}>
            Please note
          </AlertTitle>
          Reports later than six months from current date would not be
          available.
        </Alert>
      )}
    </Box>
  );
};
export default CampaignReportHeader;
