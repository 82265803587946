import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import React from 'react';
const DateFilter = ({ fields, updateState, disabled }) => {
  const handleValueChanges = (fieldName, fieldValue) => {
    fields[fieldName] = fieldValue;
    fields = { fields: fields };
    updateState(4, fields);
  };

  return (
    <>
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        style={{ alignItems: 'center' }}
      >
        <KeyboardDatePicker
          disableToolbar
          disabled={disabled}
          variant="inline"
          format="dd/MM/yyyy"
          placeholder={'DD/MM/YYYY'}
          margin="normal"
          id="date-picker-inline"
          value={fields.fieldValue}
          onChange={date => {
            handleValueChanges('fieldValue', date);
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          style={{ width: '80%', alignItems: 'center' }}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};
export { DateFilter };
