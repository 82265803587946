/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import _ from 'lodash';
import React, { useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import classes from './InputRow.module.css';

const AutoSelect = ({ detail, onSelect }) => {
  useEffect(() => {
    if (_.isEmpty(detail.value)) {
      onSelect(detail.options[0]);
    }
  }, [detail.options]);

  return (
    <div key={detail.name} className={classes.container}>
      <span className={classes.title}>{detail.label || detail.name}</span>
      <Autocomplete
        freeSolo
        className={classes.customInput}
        style={{
          padding: '0px'
        }}
        value={detail.value}
        disableClearable
        options={detail.options}
        onChange={(e, val) => onSelect(val)}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: {
                padding: '0px',
                margin: '5px 0px 0px 5px'
              }
            }}
          />
        )}
      />
    </div>
  );
};

export default AutoSelect;
