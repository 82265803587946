import React, { useEffect, useState } from 'react';
import Form from './Form';
import customerGroupClass from '../index.module.scss';
import { Typography } from '@material-ui/core';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { Business } from '../../../models/User.model';
import _ from 'lodash';
import EditCustomerGroupList from './List';
import { useParams } from 'react-router-dom';
import configService from '../../../services/customerGroup.service';
import ButtonField from '../../../reusable/ButtonField';

const EditCustomerGroup = () => {
  const [business, setBusiness] = useState(new Business());
  const [status, setStatus] = useState(false);
  const user = useCurrentUser();
  const { customerGroupId } = useParams();
  const [customerGroupData, setCustomerGroupData] = useState({});

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsBuId = _.parseInt(urlParams.get('bu'));
    const paramsBusiness = _(user.getBusinesses()).find(
      b => b.id === paramsBuId
    );
    if (paramsBusiness) setBusiness(paramsBusiness);
  }, [user]);

  useEffect(() => {
    if (business.name !== '0') {
      configService
        .getCustomerGroupById(business.name, customerGroupId)
        .then(({ data }) => {
          setCustomerGroupData({ ...data });
        });
    }
  }, [business.name, customerGroupId]);

  const handleRemoveGroup = () => {
    configService
      .removeCustomerGroupConfig(business.name, customerGroupId)
      .then(() => {
        window.top.location.href = `${window?.location?.ancestorOrigins[0]}/create-customer-group`;
      });
  };
  return (
    <>
      <div className={customerGroupClass.groupRoot}>
        <ButtonField
          label={'Remove Group'}
          type="button"
          className={customerGroupClass.removeGroupButton}
          style={{ background: '#F07663', color: 'white' }}
          onClick={handleRemoveGroup}
        />
      </div>
      <Typography
        variant={'h4'}
        className={customerGroupClass.customerGroupName}
      >
        {customerGroupData?.name || ''}
      </Typography>

      <div className={customerGroupClass.container}>
        <div className={customerGroupClass.editCustomerContainer}>
          <Form business={business} status={status} setStatus={setStatus} />
          <EditCustomerGroupList
            business={business}
            status={status}
            setStatus={setStatus}
          />
        </div>
      </div>
    </>
  );
};

export default EditCustomerGroup;
