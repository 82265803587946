import React, { useContext, useEffect, useState } from 'react';
import { CartBucketProps, CssStyles } from '../CartBucketProps';
import { Product } from '../../../models/CartWrapper';
import CreateIcon from '@material-ui/icons/Create';
import { OfferCartBuilderContext } from '../../OfferCartBuilder/OfferCartBuilderContext';
import Button from '@material-ui/core/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Switch,
  withStyles
} from '@material-ui/core';
import _ from 'lodash';
import { OfferProductCard } from '../../OfferProductCard';
import {
  Bucket,
  Discount,
  DiscountType,
  ProductBucket
} from '../../../models/offer/OfferRule.model';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { OfferProductsGroupCard } from '../../OfferProductsGroupCard';
import NumberFormat from 'react-number-format';

interface OptionType {
  label: string;
  value: string;
  obj: Product;
}

const selectProductStyle: CssStyles = {
  background: '#FAFAFA 0% 0% no-repeat padding-box',
  boxShadow: '0px 2px 6px #0000001F',
  border: '1px solid #DEDEDE',
  borderRadius: '4px'
};
const selectProductTextStyle: CssStyles = {
  color: '#767676',
  fontSize: '14px',
  fontWeight: 'bold',
  textAlign: 'center'
};

const dialogTitleStyle: CssStyles = {
  color: '#404040',
  fontWeight: 'bold',
  fontSize: '20px'
};
const SelectProductButton = withStyles({
  root: selectProductStyle,
  label: selectProductTextStyle
})(Button);

const SearchField = withStyles({
  root: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #DEDEDE',
    borderRadius: '4px'
  }
})(Input);

const SortField = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(2)
    }
  },
  input: {
    padding: '10px 26px 10px 12px'
  }
}))(Input);

const PriceSort = withStyles(theme => ({
  root: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #DEDEDE',
    borderRadius: '4px',
    boxShadow: '0px 1px 4px #0000001F'
  }
}))(Select);

class GroupedProducts {
  public name = '';
  public products: Product[] = [];

  constructor(name = '', products: Product[] = []) {
    this.products = products;
    this.name = name;
  }

  public get product(): Product {
    return _.head(this.products)!;
  }

  public get isDefined(): boolean {
    return _.some(this.products);
  }
}

function humanizeDiscount(discount: Discount) {
  if (discount.value > 1) {
    return discount.discountType === DiscountType.percent.value ? (
      ` at ${discount.value} % off `
    ) : (
      <span>
        <NumberFormat
          prefix={'₹'}
          thousandSeparator={true}
          displayType={'text'}
          value={discount.value}
          thousandsGroupStyle={'lakh'}
        />{' '}
        off
      </span>
    );
  } else {
    return '';
  }
}

function humanizeBucket(bucket: Bucket): string {
  const bucketCondition = _.get(bucket, 'condition.conditions[0]');
  if (bucketCondition) {
    const bucketConditionValues = _.get(bucketCondition, 'value');
    return _.get(bucketCondition, 'fieldAccessor') === 'sku'
      ? 'from selection'
      : _.join(bucketConditionValues, ' ');
  } else {
    return '';
  }
}

const defaultSelectedGroupProducts = new GroupedProducts();

export const CartProductBucket: React.FC<CartBucketProps> = props => {
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedGroupProducts, setSelectedGroupProducts] = useState<
    GroupedProducts
  >(defaultSelectedGroupProducts);
  const [groupedProducts, setGroupedProducts] = useState<GroupedProducts[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [sortOrder, setSortOrder] = useState<string>('hl');
  const [filterText, setFilterText] = useState<string>('');
  const [selectedProduct, setSelectedProduct] = useState<{
    [key: number]: Product;
  }>({});
  const [isGrouped, setIsGrouped] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const cartContext = useContext(OfferCartBuilderContext);
  useEffect(() => {
    const products = cartContext.bucketProducts.getProducts(
      props.offerBucket.bucketId
    );
    setProducts(products);
    setFilteredProducts(products);
    const gp = _(_.groupBy(products, p => p.series))
      .entries()
      .map(([k, v]) => new GroupedProducts(k, v))
      .value();
    setGroupedProducts(gp);
  }, [
    cartContext.bucketProducts,
    props.offer.code,
    props.offerBucket.bucketId
  ]);
  useEffect(() => {
    if (!isDialogOpen) {
      setSelectedGroupProducts(defaultSelectedGroupProducts);
      setIsGrouped(true);
    }
  }, [isDialogOpen]);
  useEffect(() => {
    const searchKeys = _(_.split(_.toLower(filterText), /\W+/))
      .compact()
      .value();
    const filtered = _.some(searchKeys)
      ? _.filter(products, p => {
          const productNameFields = _(_.split(p.name, /\W+/))
            .compact()
            .value();
          const isMatch = _.every(searchKeys, sk =>
            _.includes(_.toLower(p.name), sk)
          );
          console.log(productNameFields, p.name, searchKeys, isMatch);
          return isMatch;
        })
      : products;
    const sortedValues = _.orderBy(
      filtered,
      [
        p => p.getDiscountedPrice((props.offerBucket as ProductBucket).discount)
      ],
      [sortOrder === 'hl' ? 'desc' : 'asc']
    );
    const gp = _(_.groupBy(sortedValues, p => p.series))
      .entries()
      .map(([k, v]) => new GroupedProducts(k, v))
      .value();
    setGroupedProducts(gp);
    setFilteredProducts(sortedValues);
  }, [products, filterText, sortOrder, props.offerBucket]);
  return (
    <div className={'d-flex justify-content-center align-items-center col-12'}>
      <div className={'col-12 justify-content-center  flex-column d-flex'}>
        <span
          style={selectProductTextStyle}
          className={'d-flex justify-content-start mb-3'}
        >
          Buy {humanizeBucket(props.offerBucket)}{' '}
          {humanizeDiscount((props.offerBucket as ProductBucket).discount)}
        </span>
        <SelectProductButton
          variant="contained"
          size="large"
          fullWidth
          onClick={() => setIsDialogOpen(true)}
        >
          {selectedProduct[props.offerBucket.bucketId] ? (
            <>
              <CreateIcon className={'mx-4'} />
              <span className={'text-truncate'}>
                {selectedProduct[props.offerBucket.bucketId].name}
              </span>
            </>
          ) : (
            'Select Product'
          )}
        </SelectProductButton>
      </div>
      <Dialog open={isDialogOpen} maxWidth={'xl'} fullWidth={true}>
        <DialogTitle style={dialogTitleStyle}>
          <div className={'row align-items-center'}>
            <span className={'col-9 mx-1 mx-md-0 d-none d-sm-inline'}>
              Eligible Products
            </span>
          </div>
          {!selectedGroupProducts.isDefined && (
            <div className={'row align-items-center'}>
              <span
                className={
                  'col-12 col-md-6 mr-0 d-flex align-items-center pr-0'
                }
              >
                <SearchField
                  disableUnderline={true}
                  value={filterText}
                  fullWidth={true}
                  onChange={event =>
                    setFilterText(event.target.value as string)
                  }
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon opacity={0.3} />
                    </InputAdornment>
                  }
                />
              </span>
              <span
                className={
                  'col-12 col-md-6 mt-2 mt-md-0 justify-content-end d-flex align-items-center flex-wrap px-0'
                }
              >
                <span className={'col-12 col-md-5 px-0'}>
                  <Grid
                    container
                    component="label"
                    style={{
                      cursor: 'pointer',
                      marginBottom: '0px',
                      color: '#767676',
                      fontSize: '14px',
                      fontWeight: 'bold'
                    }}
                    justify={'flex-end'}
                    alignItems={'center'}
                  >
                    <Grid item>All</Grid>
                    <Grid item>
                      <Switch
                        checked={isGrouped}
                        onChange={() => setIsGrouped(!isGrouped)}
                      />
                    </Grid>
                    <Grid item>By series</Grid>
                  </Grid>
                </span>
                <span
                  className={
                    'col-12 col-md-7 pr-0 mr-0 d-flex px-0 d-flex justify-content-end pr-lg-3'
                  }
                >
                  <span
                    style={{ color: '#767676', fontSize: '13px' }}
                    className={'d-none d-md-flex align-items-center mr-2'}
                  >
                    Sort:
                  </span>
                  <FormControl>
                    <PriceSort
                      disableUnderline={true}
                      fullWidth={true}
                      onChange={event =>
                        setSortOrder(event.target.value as string)
                      }
                      input={<SortField value={sortOrder} />}
                      style={{
                        color: '#767676',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        background: '#FAFAFA 0% 0% no-repeat padding-box'
                      }}
                    >
                      <MenuItem
                        value={'hl'}
                        className={'px-3'}
                        style={{
                          color: '#767676',
                          fontSize: '14px',
                          fontWeight: 'bold',
                          background: '#FAFAFA 0% 0% no-repeat padding-box'
                        }}
                      >
                        Price: High to low
                      </MenuItem>
                      <MenuItem
                        className={'px-3'}
                        value={'lh'}
                        style={{
                          color: '#767676',
                          fontWeight: 'bold',
                          fontSize: '14px',
                          background: '#FAFAFA 0% 0% no-repeat padding-box'
                        }}
                      >
                        Price: Low to high
                      </MenuItem>
                    </PriceSort>
                  </FormControl>
                </span>
              </span>
            </div>
          )}
        </DialogTitle>
        {!selectedGroupProducts.isDefined && (
          <DialogContent dividers={true} className={'d-flex flex-wrap'}>
            {isGrouped
              ? groupedProducts.map((gp, i) => (
                  <OfferProductCard
                    isFinalSelect={false}
                    key={`${gp.product.id}_${gp.name}`}
                    product={gp.product}
                    bucket={props.offerBucket as ProductBucket}
                    onClick={() => setSelectedGroupProducts(gp)}
                  />
                ))
              : filteredProducts.map(p => (
                  <OfferProductCard
                    isFinalSelect={true}
                    key={p.id}
                    bucket={props.offerBucket as ProductBucket}
                    product={p}
                    onClick={(p, b) => {
                      const updatedCart = props.cartWrapper.upsertOfferProduct(
                        props.offer,
                        props.offerBucket,
                        p
                      );
                      props.setCartWrapper(updatedCart);
                      setSelectedProduct({
                        ...selectedProduct,
                        [props.offerBucket.bucketId]: p
                      });
                      setFilterText('');
                      setIsDialogOpen(false);
                    }}
                  />
                ))}
          </DialogContent>
        )}

        {selectedGroupProducts.isDefined && (
          <DialogContent dividers={true} className={'d-flex flex-wrap'}>
            <OfferProductsGroupCard
              products={selectedGroupProducts.products}
              bucket={props.offerBucket as ProductBucket}
              onClick={(p, b) => {
                const updatedCart = props.cartWrapper.upsertOfferProduct(
                  props.offer,
                  props.offerBucket,
                  p
                );
                props.setCartWrapper(updatedCart);
                setSelectedProduct({
                  ...selectedProduct,
                  [props.offerBucket.bucketId]: p
                });
                setFilterText('');
                setIsDialogOpen(false);
              }}
            />
          </DialogContent>
        )}
        <DialogActions className={'d-flex justify-content-center'}>
          <Button onClick={() => setIsDialogOpen(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
