/* eslint-disable @typescript-eslint/camelcase */
export const permissionsDescription = {
  unblock_loyalty_points:
    'This features give the user the ability to unblock loyalty points for any customer through Customer 360 or through Marketers PWA',
  'view-customer-otp':
    'This feature helps the Admin user to view the last sent otp to the customer. This can be viewed in Marketers PWA and in Customer 360',
  'resend-customer-otp':
    'This feature helps the Admin user to view the last sent otp to the customer. This can be viewed in Marketers PWA and in Customer 360',
  'manage-marketers-dashboard':
    'This feature gives the ability for the user to use the Marketers dashboard',
  analytics_view:
    'This feature gives the ability for the user to view the dashboard with its graphs',
  'manage-all-users':
    'This feature gives the ability for the user to create and manage users in a particular business',
  'manage-store-users':
    'This feature gives the ability for the user to create and manage users in a particular store',
  user_create:
    'This feature gives the ability for the user to create a user who will be potentially be using the CASA system',
  'can-delete-stores':
    'This feature gives the ability for the user to delete a particular store (soft delete)',
  campaign_manage:
    'This feature gives the ability for the user to create and manage users in a particular business',
  'manage-all-stores':
    'This feature gives the ability for the user to create and manage stores in a particular business',
  lead_create: 'This feature gives the ability for the user to create a Lead',
  lead_manage: 'This feature gives the ability for the user to manage a Lead',
  lead_reassign:
    'This feature gives the ability for the user to reassign a Lead',
  lead_csv_download:
    'This feature gives the ability for the user to download the leads based on seleccted filters into  a CSV format',
  'LMS_Lane_missed-follow-up':
    'This feature gives the ability for the user to view leads in Missed followup',
  LMS_Lane_lead:
    'This feature gives the ability for the user to view all leads based on associated stores',
  'LMS_Lane_today-follow-up':
    'This feature gives the ability for the user to view leads in Todays followup',
  LMS_Lane_closed:
    'This feature gives the ability for the user to view leads in Closed lane',
  'LMS_Lane_demo-to-sale':
    'This feature gives the ability for the user to view leads in demo to sale lane',
  loyalty: 'This feature gives access to use the complete loyalty module',
  'Offers-management':
    'This feature gives access to use the complete Offer module',
  social_media_rewards:
    'This feature gives access to use the Social media rewards module',
  'commerce-connect':
    'This feature gives access to use the Commerce Connect module end to end',
  'manage-shoppersDelight-pwa':
    "This feature gives access to the user to configure the Customer's PWA based on their business need through the configuration screen",
  'loyalty-liability-report':
    'This feature gives access to the user to filter, generate and download in CSV the Loyalty liability report',
  'transaction-report':
    'This feature gives access to the user to filter, generate and download in CSV the transaction report',
  'fraud-report':
    'This feature gives access to the user to filter, generate and download in CSV the fraud report',
  'member-loyalty-report':
    'This feature gives access to the user to filter, generate and download in CSV the member loyalty report',
  'member-bill-report':
    'This feature gives access to the user to filter, generate and download in CSV the member bill report',
  'offer-promo-tenant-report':
    'This feature gives access to the user to filter, generate and download in CSV the offer promo report',
  'offer-tenant-report':
    'This feature gives access to the user to filter, generate and download in CSV the offer ROI repory',
  'invoice-creator':
    'This feature gives access to the user to configure the invoice the way the business wnats through the configuration screen',
  'invoice-resend':
    'This feature gives access to the user to resend the invoice from Customer 360 to a Customer'
};
