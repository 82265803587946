import React from 'react';
import moment from 'moment';
import { Page, View, Text, Image, StyleSheet, Font } from '@react-pdf/renderer';
import { GetBarCode } from '../utils';
import Rupee from '../../assets/rupee.png';
import Disclimer from '../../assets/disclimer.png';
import fonts from '../../assets/Fonts';
import { truncatedNumberFloat, truncateString } from '../../../../utils';
import _ from 'lodash';

Font.register(fonts);

const styles = StyleSheet.create({
  card: {
    fontFamily: 'OpenSans',
    marginTop: '3mm',
    left: '18mm',
    height: '109px',
    width: '496px',
    flexDirection: 'row',
    border: '1 solid #000000',
    marginBottom: '3px'
  },
  qrcode: {
    borderRight: '1 solid #000000',
    height: '108px',
    width: '94px',
    padding: '2mm 1mm',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center'
    // margin:"0px 10px"
  },
  priceDetails: {
    flexDirection: 'row'
  },
  deals: {
    width: '129px',
    flexDirection: 'column',
    height: '24px',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  mrpText: {
    fontSize: '19pt',
    height: '26px',
    color: 'black',
    fontWeight: 'bold'
  },
  rupeeSmall: {
    width: '3.4mm',
    height: '4.88mm',
    top: 4
  },
  topSection: {
    height: '67px'
  },
  mrp: {
    display: 'flex',
    flexDirection: 'row',
    height: '8mm',
    top: -2,
    justifyContent: 'center'
  },
  priceSection: {
    width: '273px',
    borderRight: '1 solid #000000',
    padding: '1mm',
    height: '67px'
  },
  amount: {
    flexDirection: 'row',
    display: 'flex',
    top: 10
  },
  rupeeIcon: {
    fontSize: '28pt',
    top: -18,
    left: 2,
    width: '8mm'
  },
  price: {
    fontWeight: 'bold',
    fontSize: '49.5pt',
    color: 'black',
    top: -26
  },
  inclTax: {
    fontWeight: 'bold',
    fontSize: '8pt',
    top: 20,
    right: 0,
    left: -1,
    display: 'flex',
    flexDirection: 'row'
  },
  descDetailsBlock: {
    height: '41px',
    width: '401px',
    flexDirection: 'row',
    borderTop: '1 solid #000000'
  },
  descSection: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRight: '1 solid #000000',
    width: '297px',
    height: '41px',
    top: -1
  },
  desc: {
    fontSize: '7.4pt',
    margin: '5px 4px',
    color: '#1A1818',
    lineHeight: 1.1
  },
  itemCode: {
    fontSize: '7pt',
    marginLeft: '1mm',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#1A1818',
    top: -3
  },
  barcode: {
    top: 4,
    width: '37mm',
    padding: '1mm 1mm 0mm 0mm'
  },
  pageTitle: {
    position: 'absolute',
    fontSize: 7,
    top: 2,
    left: 50
  }
});

const IntegratedSelfPageH1 = ({ title, data, count, ...rest }) => {
  return (
    <Page size="A4" style={styles.page} wrap={true}>
      {count &&
        count.map(id => (
          <View key={id}>
            {data?.map(item => (
              <View style={styles.card} key={item?.id}>
                <View style={styles.qrcode}>
                  <View
                    style={{ margin: '2mm 0mm', height: '85px', width: '85px' }}
                  >
                    <Image src={item.image} />
                  </View>
                </View>
                <View style={styles.topSection}>
                  <View style={styles.priceDetails}>
                    <View style={styles.priceSection}>
                      <View
                        style={{
                          borderBottom: '1 solid #000000',
                          height: '21px',
                          width: '273px',
                          left: -3
                        }}
                      >
                        <View style={styles.mrp}></View>
                      </View>
                      <View style={styles.amount}>
                        <Text style={styles.rupeeIcon}>
                          <Image src={Rupee} />
                        </Text>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: '34mm'
                          }}
                        >
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              height: '34mm'
                            }}
                          >
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '34mm'
                              }}
                            >
                              <Text style={styles.price}>
                                {truncatedNumberFloat(item?.product?.mop, 6)}
                              </Text>
                              <Text
                                style={{
                                  fontSize: '9pt',
                                  top: -8,
                                  fontWeight: 'bold'
                                }}
                              >
                                *
                              </Text>
                            </View>
                            <View style={styles.inclTax}>
                              <Text
                                style={{
                                  fontSize: '9pt',
                                  fontWeight: 'bold'
                                }}
                              >
                                *
                              </Text>
                              <Text style={{ fontSize: '9.5pt' }}>
                                (Incl. Taxes)
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View style={styles.deals}>
                      <View
                        style={{
                          borderBottom: '1 solid #00000',
                          width: '128px',
                          height: '24px',
                          left: -1,
                          backgroundColor: '#8c8c8c',
                          textAlign: 'center'
                        }}
                      ></View>
                      <View
                        style={{
                          textAlign: 'center',
                          width: '128px',
                          height: '46px'
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: '#1c1c1c',
                            width: '128px',
                            left: -1,
                            height: '34px',
                            top: 4,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center'
                          }}
                        ></View>
                      </View>
                    </View>
                  </View>
                  <View style={styles.descDetailsBlock}>
                    <View style={styles.descSection}>
                      <Text style={styles.desc}>
                        {truncateString(item?.product?.masterData?.name, 130)}
                      </Text>
                      <View style={styles.itemCode}>
                        <Text>
                          {item?.sku} | {item.class} | A |{' '}
                          {moment(item?.date).format('DD.MM.YYYY')} |{' '}
                          {moment().format('DD.MM.YYYY')}
                        </Text>
                        <Image
                          style={{
                            fontSize: '10mm',
                            height: '4mm',
                            paddingLeft: '1mm',
                            marginRight: '4px',
                            marginBottom: '1px'
                          }}
                          src={Disclimer}
                        />
                      </View>
                    </View>
                    <View style={styles.barcode}>
                      {_.isEmpty(item.barcode) ? (
                        <></>
                      ) : (
                        <Image src={GetBarCode(item?.barcode)} />
                      )}
                    </View>
                  </View>
                </View>
              </View>
            ))}
          </View>
        ))}
    </Page>
  );
};

export { IntegratedSelfPageH1 };
