import React from 'react';
import { makeStyles, Container } from '@material-ui/core';
import {
  PAGETILE,
  LAYOUT,
  ALLOWED_VERSION,
  LATEST_VERSION
} from '../../constants';
import { transformLayoutBasedOnPrice } from '../../../../utils';
import _ from 'lodash';
const styles = makeStyles({
  root: {
    background: '#F9F9F9'
  },
  label: {
    display: 'flex',
    marginTop: 70
  },
  container: {
    overflow: 'auto',
    width: '90%',
    paddingBottom: 40
  },
  cards: {
    marginLeft: 10,
    marginRight: 10,
    maxWidth: '100%'
  }
});
const Preview = props => {
  let { skuDetails } = props;
  const maskVersionsOfLayout = (array, layout) => {
    const allowedVersions = ALLOWED_VERSION[layout];
    const defaultVersion = LATEST_VERSION[layout];
    return _.map(array, element => {
      if (!allowedVersions.includes(element.version)) {
        element.version = defaultVersion;
      }
      return element;
    });
  };
  const maskVersionsOfSkuDetails = skuDetails => {
    skuDetails = _.groupBy(skuDetails, 'layout');
    skuDetails = _.map(skuDetails, (value, key) => {
      return maskVersionsOfLayout(value, key);
    });
    return _.flatMap(skuDetails);
  };
  const classes = styles();
  function groupByConditions(array, f) {
    const groups = {};
    array.forEach(function(o) {
      const group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function(group) {
      return groups[group];
    });
  }
  skuDetails = maskVersionsOfSkuDetails(skuDetails);
  skuDetails = groupByConditions(skuDetails, function(detail) {
    if (['A4', 'A5', 'A6'].includes(detail.layout)) {
      return [detail.layout, detail.orientation, detail.version];
    }
    return [detail.layout, detail.version];
  });
  skuDetails = transformLayoutBasedOnPrice(skuDetails);
  const getLayoutName = (layout, count) => {
    return (
      <>
        {PAGETILE[layout]}
        <span className="bold nowrap">{` ( ${count} )`}</span>
      </>
    );
  };
  const layoutImage = {
    [LAYOUT.PEGHOOK]: {
      NA: {
        V1: require('../../assets/peghook.png'),
        V2: require('../../assets/peghook-v2.png'),
        H1: require('../../assets/peghook-H1.png')
      }
    },
    [LAYOUT.WIREBASKET]: {
      NA: {
        V1: require('../../assets/wirebasket.png'),
        V2: require('../../assets/wirebasket-v2.png'),
        H1: require('../../assets/wirebasket-H1.png')
      }
    },
    [LAYOUT.PEGHOOKFOURDIGIT]: {
      NA: { V1: require('../../assets/peghook4point.png') }
    },
    [LAYOUT.WIREBASKETFOURDIGIT]: {
      NA: { V1: require('../../assets/wirebasket4point.png') }
    },
    [LAYOUT.INTEGRATEDSHELF]: {
      NA: {
        V1: require('../../assets/integrated-self.png'),
        V2: require('../../assets/integratedshelfv2.png'),
        H1: require('../../assets/integratedShelf-H1.png')
      }
    },
    [LAYOUT.A6]: {
      PORTRAIT: {
        V1: require('../../assets/a6-p.png'),
        V2: require('../../assets/A6-portrait-v2.png'),
        H1: require('../../assets/A6-Portrait-H1.png')
      },
      LANDSCAPE: {
        V1: require('../../assets/a6-l.png'),
        V2: require('../../assets/A6-landscape-v2.png'),
        V3: require('../../assets/A6-landscape-v3.svg'),
        H1: require('../../assets/A6-Landscape-H1.png')
      },
      NA: {
        V1: require('../../assets/a6-p.png'),
        V2: require('../../assets/A6-portrait-v2.png')
      }
    },
    [LAYOUT.A5]: {
      PORTRAIT: {
        V1: require('../../assets/a5-p.png'),
        V2: require('../../assets/A5-portrait-v2.png'),
        H1: require('../../assets/A5-Portrait-H1.png')
      },
      LANDSCAPE: {
        V1: require('../../assets/a5-l.png'),
        V2: require('../../assets/A5-landscape-v2.png'),
        H1: require('../../assets/A5-Landscape-H1.png')
      },
      NA: {
        V1: require('../../assets/a5-p.png'),
        V2: require('../../assets/A5-portrait-v2.png')
      }
    },
    [LAYOUT.A4]: {
      PORTRAIT: {
        V1: require('../../assets/a4-p.png'),
        V2: require('../../assets/A4-portrait-v2.png'),
        V3: require('../../assets/A4-portrait-v3.svg'),
        H1: require('../../assets/A4-Landscape-H1.png')
      },
      LANDSCAPE: {
        V1: require('../../assets/a4-l.png'),
        V2: require('../../assets/A4-landscape-v2.png'),
        H1: require('../../assets/A4-Landscape-H1.png')
      },
      NA: {
        V1: require('../../assets/a4-p.png'),
        V2: require('../../assets/A4-portrait-v2.png')
      }
    }
  };
  const getLayoutImages = (layout, orientation, version) => {
    const currentLayout = _.get(layoutImage, layout, {});
    let image = _.get(currentLayout, orientation, null);
    image = _.isEmpty(image) ? _.get(currentLayout, 'NA', null) : image;
    image = _.get(image, version);
    return (
      <div style={{ width: '15rem', height: '15rem' }}>
        <img
          src={image}
          style={{
            margin: '20px 5px 0px 0px',
            float: 'left',
            maxWidth: '100%',
            maxHeight: '100%'
          }}
          alt={layout}
        />
      </div>
    );
  };
  function getLabelPreviews() {
    return _.map(skuDetails, value => {
      const labelData = _.head(value);
      return {
        name: getLayoutName(labelData.layout, value.length),
        layout: labelData.layout,
        version: labelData.version,
        orientation: labelData.orientation,
        image: getLayoutImages(
          labelData.layout,
          labelData.orientation,
          labelData.version
        )
      };
    });
  }
  const renderPreviewLabels = () => {
    return (
      <div className={classes.label}>
        {getLabelPreviews().map((label, index) => (
          <>
            {label?.name && (
              <span key={index} className="l-pr4">
                <div className={classes.cards}>
                  <h5>
                    {label.name} {`| ${label.version}`}
                    {['A4', 'A5', 'A6'].includes(label.layout)
                      ? `| ${label.orientation}`
                      : ''}
                  </h5>
                  <div>{label.image}</div>
                </div>
              </span>
            )}
          </>
        ))}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className="center l-pt3" style={{ height: '100%' }}>
        <div style={{ fontWeight: 'bold', fontSize: 24 }}>PREVIEW</div>
        <Container classes={{ root: classes.container }}>
          {renderPreviewLabels()}
        </Container>
      </div>
    </div>
  );
};

export default Preview;
