import React from 'react';
import { CartBucketProps } from '../CartBucketProps';
import { JoinBucket } from '../../../models/offer/OfferRule.model';
import { CartBucket } from '../index';

const joinKeyStyle: { [key: string]: string } = {
  fontSize: '18px',
  lineSpacing: '24px',
  color: '#767676',
  fontWeight: 'bold'
};

export const CartJoinBucket: React.FC<CartBucketProps> = props => {
  const joinBucket = props.offerBucket as JoinBucket;
  return (
    <div
      className={
        'd-flex flex-column justify-content-center align-items-center mx-2 mx-md-4 py-4 my-2'
      }
      style={props.isOdd ? props.cardStyles.oddCard : props.cardStyles.evenCard}
    >
      {joinBucket.buckets.map((b, index) => {
        return (
          <div
            key={b.bucketId}
            className={
              'd-flex flex-column justify-content-center align-items-center col-12 mb-2'
            }
          >
            <CartBucket
              {...{ ...props, offerBucket: b, isOdd: !props.isOdd }}
            />
            {joinBucket.buckets.length > 1 &&
              index !== joinBucket.buckets.length - 1 && (
                <div
                  className={
                    'd-flex justify-content-center align-items-center col-12 mt-3'
                  }
                  style={joinKeyStyle}
                >
                  {joinBucket.joinType}
                </div>
              )}
          </div>
        );
      })}
    </div>
  );
};
