/* eslint-disable @typescript-eslint/explicit-function-return-type */
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { get, map } from 'lodash';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';

import useCurrentUser from '../../../../hooks/useCurrentUser';
import { ReportTypes } from '../../../../models/TenantReports';
import { MemberReportTypes } from '../../../../models/TenantReports/MemberWiseReport';
import { getMemberBillReport } from '../../../../services/TenantReports/MemberBillReport.service';
import {
  AdditionalField,
  AdditionalFieldSelector
} from '../../Shared/AdditionalFields/AdditionalFields';

export interface BillReport {
  startDate: string;
  endDate: string;
  businessId?: number;
  additionalFields?: AdditionalField[];
  type: string;
}

const billInitialState: BillReport = {
  startDate: moment()
    .subtract(1, 'day')
    .format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  businessId: undefined,
  type: 'enroll'
};

export const reportTypeLabel = {
  enroll: 'Enrollment wise',
  transaction: 'Transaction wise'
};

interface ReportActionsProps {
  setRefreshView: (id: string) => void;
}

const MemberBillReport: FC<ReportActionsProps> = ({ setRefreshView }) => {
  const [filters, setFilters] = useState<BillReport>(billInitialState);
  const user = useCurrentUser();

  const handleDateChange = (date: any, name: string) => {
    setFilters({ ...filters, [name]: date });
  };

  const getReportOptions = () => {
    return Object.values(MemberReportTypes);
  };

  const handleReportTypeChange = (value: any) => {
    setFilters({ ...filters, type: value });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsBuId = +urlParams.get('bu')! || 0;

    setFilters(filters => ({ ...filters, businessId: paramsBuId }));
  }, [user]);

  const handleGenerateReport = (): void => {
    getMemberBillReport(filters).then((res: any) => {
      const jobId = get(res, 'jobId', '');
      setRefreshView(jobId);
    });
  };

  const updateFilters = (additionalFields: AdditionalField[]) =>
    setFilters({ ...filters, additionalFields });

  return (
    <div
      style={{
        margin: '30px 0px',
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <div>
        <FormControl style={{ minWidth: 180, marginRight: '10px' }}>
          <InputLabel id="lls-report-select-helper-label">
            Report Type
          </InputLabel>
          <Select
            labelId="select-lls-report-type-label"
            id="select-lls-report-type"
            value={filters.type}
            label="Report Type"
            onChange={e => handleReportTypeChange(e.target.value)}
          >
            {map(getReportOptions(), (value, key) => {
              return (
                <MenuItem
                  value={value}
                  key={key}
                  style={{ textTransform: 'capitalize' }}
                >
                  {reportTypeLabel[value]}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            label="Date"
            value={filters.startDate}
            onChange={e => handleDateChange(e, 'startDate')}
            variant="inline"
            format="yyyy-MM-dd"
          />
          <KeyboardDatePicker
            label="End Date"
            value={filters.endDate}
            onChange={e => handleDateChange(e, 'endDate')}
            variant="inline"
            format="yyyy-MM-dd"
            style={{ marginLeft: '20px' }}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '1rem',
          marginLeft: 'auto'
        }}
      >
        <AdditionalFieldSelector
          reportType={ReportTypes.MEMBER_BILL_REPORT}
          onChange={updateFilters}
          businessId={filters.businessId}
        />
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleGenerateReport}
        >
          Generate Report
        </Button>
      </div>
    </div>
  );
};

export default MemberBillReport;
