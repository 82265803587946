import React, { FC, useContext, useEffect } from 'react';
import { getAddresses } from '../../../services/ecomCart.service';
import _ from 'lodash';
import './CheckoutForm.css';
import EmptyImg from '../../Assets/images/empty.svg';
import { BusinessUnitContext } from '../../OfferDefinition/BusinessUnitContext';

interface AddressListingProps {
  mobileNumber: string;
  setIsNextEnabled: (d: any) => void;
  setSkipAddressCreation: (d: boolean) => void;
  setAddressData: (d: any) => void;
}

export const AddressListing: FC<AddressListingProps> = ({
  setIsNextEnabled,
  setSkipAddressCreation,
  setAddressData
}) => {
  const [addresses, setAddresses]: any = React.useState([]);
  const [selectedAddressId, setSelectedAddressId]: any = React.useState();
  const { business } = useContext(BusinessUnitContext);
  const businessId = business.id;

  useEffect(() => {
    getAddresses(businessId).then(r => {
      setAddresses(_.get(r, 'data'));
    });
  }, [businessId]);

  const onClickAddressCart = (addressId: number) => {
    const addressData = _.get(
      _.find(addresses, { id: addressId }),
      'attributes'
    );
    const updatedAddress = _.omit(
      {
        ...addressData,
        // eslint-disable-next-line @typescript-eslint/camelcase
        state_id: addressData.state_id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        country_id: addressData.country_id
      },
      [
        'company',
        'country_name',
        'country_iso3',
        'state_code',
        'state_name',
        'country_iso'
      ]
    );
    setSelectedAddressId(addressId);
    setIsNextEnabled(true);
    setAddressData(updatedAddress);
    setSkipAddressCreation(true);
  };

  return (
    <div>
      {_.isEmpty(addresses) && (
        <div>
          <img
            className={'adddess-empty-img'}
            src={EmptyImg}
            alt={'address empty'}
          />
          <p className={'address-empty-text'}>Oops! No Address available</p>
        </div>
      )}
      {!_.isEmpty(addresses) && (
        <div className={'address-listing-container'}>
          {_.map(addresses, (address, i) => {
            const currentId = _.get(address, 'id', '');
            return (
              <div
                key={i}
                className={`address-listing-card ${
                  selectedAddressId === currentId ? 'active' : ''
                }`}
                onClick={() => onClickAddressCart(currentId)}
              >
                <p className={'address-line-item address-line-item-name'}>
                  {_.get(address, 'attributes.firstname', '')}{' '}
                  {_.get(address, 'attributes.lastname', '')}
                </p>
                <p className={'address-line-item'}>
                  {_.get(address, 'attributes.address1', '')}
                </p>
                <p className={'address-line-item'}>
                  {_.get(address, 'attributes.address2', '')}
                </p>
                <p className={'address-line-item'}>
                  {_.get(address, 'attributes.city', '')}
                  {' - '}
                  {_.get(address, 'attributes.zipcode', '')}
                </p>
                <p className={'address-line-item'}>
                  {_.get(address, 'attributes.state_name', '')}
                </p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AddressListing;
