import config from '../config/index';
import _ from 'lodash';

function getOfferTags(tenantBrandBusinessId) {
  return fetch(
    `${config.ruleServerUrl}/users/offer-tags/tenantBrandBusiness/${tenantBrandBusinessId}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  )
    .then(r => r.json())
    .then(r => {
      if (_.some(r)) {
        return r;
      } else {
        return Promise.reject(
          `no offer tags for tenantBrandBusinessId ${tenantBrandBusinessId}`
        );
      }
    });
}

function createOfferTag(tenantBrandBusinessId, offerTagName) {
  return fetch(`${config.ruleServerUrl}/users/offer-tags/`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({
      tenantBrandBusinessId,
      offerTagName
    }),
    headers: {
      'content-type': 'application/json'
    }
  }).then(r => r.json());
}

export default { getOfferTags, createOfferTag };
