import { Paper, Table, TableContainer } from '@material-ui/core';
import React, { FC } from 'react';

import Campaign from '../../../../../models/campaign/Campaign';
import TableBodyLoader from '../../../shared/components/TableBodyLoader/TableBodyLoader';
import TableHeader from '../../../shared/components/TableHeader/TableHeader';
import styles from './CampaignTable.module.scss';
import CampaignTableBody from './CampaignTableBody/CampaignTableBody';
import CampaignTableFooter from './CampaignTableFooter/CampaignTableFooter';

interface CampaignTableProps {
  campaigns: Campaign[];
  isLoading: boolean;
  count: number;
  page: number;
  offset: number;
  setPage: (page: number) => void;
  setOffset: (offset: number) => void;
}

const campaignHeaders = [
  'Campaign Name',
  'Start Date',
  'End Date',
  'Campaign Status',
  'View Reports',
  'Conversion'
];

const CampaignTable: FC<CampaignTableProps> = props => {
  const {
    campaigns,
    count,
    isLoading,
    page,
    offset,
    setPage,
    setOffset
  } = props;

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      id={styles.campaignTableContainer}
    >
      <Table className={styles.campaignTable}>
        <TableHeader
          headers={campaignHeaders}
          className={styles.campaignTableHeader}
          textAlign="center"
        />
        {isLoading ? (
          <TableBodyLoader
            rowsPerPage={offset}
            colSpan={campaignHeaders.length}
            height={32}
          />
        ) : (
          <CampaignTableBody
            colSpan={campaignHeaders.length}
            campaigns={campaigns}
          />
        )}
        <CampaignTableFooter
          colSpan={campaignHeaders.length}
          count={count}
          rowsPerPage={offset}
          page={page}
          onPageChange={setPage}
          onRowPerPageChange={setOffset}
        />
      </Table>
    </TableContainer>
  );
};
export default CampaignTable;
