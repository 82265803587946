/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useRef } from 'react';

const useFocus = () => {
  const focusRef = useRef(null);
  const setFocusRef = () => {
    focusRef.current && focusRef.current.focus();
  };

  return [focusRef, setFocusRef];
};

export default useFocus;
