import { withStyles } from '@material-ui/styles';
import { StepConnector } from '@material-ui/core';

const Connector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
    borderWidth: 1
  },
  active: {
    '& $line': {
      borderColor: '#FACCC3'
    }
  },
  completed: {
    '& $line': {
      borderColor: '#FACCC3'
    }
  },
  line: {
    marginLeft: '-12.5%',
    borderColor: '#FACCC3',
    borderTopWidth: 3,
    borderRadius: 1,
    width: '125%'
  }
})(StepConnector);

export default Connector;
