import React from 'react';
import { Offer } from '../../models/offer/Offer.model';
import _ from 'lodash';
import durationIcon from '../Assets/Offers/durationDark.svg';
import rightArrow from '../Assets/Offers/rightArrow.svg';
import { DateTimeFormat } from '../DateTimeFormat';
import { OfferExpiry } from '../OfferExpiry';

interface CustomerOfferCardProps {
  offer: Offer;
  className?: string;
  onClick?: (offer: Offer) => void;
  businessUnitId: number;
  offerDefaultImage: string | null;
}

export const CustomerOfferCard: React.FC<CustomerOfferCardProps> = ({
  offer,
  className = '',
  onClick = o => _.noop(),
  offerDefaultImage
}) => {
  const getOfferImageUrl = () => {
    return offer.imageUrl || offerDefaultImage;
  };

  return (
    <div
      className={`border d-flex flex-wrap ${className}`}
      style={{
        borderColor: '#DEDEDE',
        borderRadius: '8px',
        backgroundColor: 'white',
        boxShadow: '0px 1px 3px #00000014'
      }}
      onClick={() => onClick(offer)}
    >
      <div className={'d-flex flex-wrap col-12 px-0'} style={{ height: '8em' }}>
        <div
          className={
            'col-3 col-md-2 d-flex justify-content-center align-items-center p-1'
          }
          style={{ borderRight: '1px solid #DEDEDE' }}
        >
          {getOfferImageUrl() && (
            <img
              className="img-fluid my-auto"
              src={getOfferImageUrl()!}
              alt={'offer'}
              style={{ maxHeight: '8em' }}
            />
          )}
        </div>
        <div
          className={
            'col-9 col-md-10 d-flex flex-stretch flex-column px-3 px-md-0'
          }
        >
          <div
            className={'d-flex justify-content-center align-items-center'}
            style={{ flexGrow: 1 }}
          >
            <div
              className={
                'col-12 col-md-10 d-flex flex-column justify-content-around'
              }
              style={{ height: '100%' }}
            >
              <div
                className="headerText ml-2"
                style={{
                  color: '#404040',
                  fontWeight: 'bold',
                  fontSize: '15px',
                  overflowWrap: 'break-word'
                }}
              >
                {offer.name}
              </div>
              <div
                className="normalText ml-2"
                style={{
                  color: '#767676',
                  overflow: 'hidden',
                  maxHeight: '3em'
                }}
              >
                {offer.description}
              </div>
              <div className="normalText ml-2">
                <span
                  className="mr-2 h4 d-none d-md-inline"
                  style={{ color: '#A8A8A8', fontSize: '12px' }}
                >
                  OFFER CODE:
                </span>{' '}
                <span style={{ color: '#767676', fontSize: '13px' }}>
                  {offer.code}
                </span>
              </div>
            </div>
            <div
              className={
                'd-flex col-2 pr-4 justify-content-end align-items-center hoverable'
              }
              onClick={() => onClick(offer)}
            >
              <img
                src={rightArrow}
                className={'zoomOnHover'}
                alt={'fill details'}
              />
            </div>
          </div>
          <div
            className={'d-none d-md-flex justify-content-center py-1'}
            style={{ borderTop: '1px solid #DEDEDE' }}
          >
            <div className="col-8">
              <img src={durationIcon} className={'ml-2'} alt={'duration'} />
              <span className="pl-2">
                <DateTimeFormat
                  date={offer.startDate!}
                  format={'DD MMM'}
                  className={'font-weight-bold'}
                  style={{ color: '#767676', fontSize: '13px' }}
                />
                <DateTimeFormat
                  date={offer.startDate!}
                  format={' YYYY, LT'}
                  style={{ color: '#707070', fontSize: '13px' }}
                />
              </span>
              <span className={'mx-2'}>-</span>
              <span>
                <DateTimeFormat
                  date={offer.endDate!}
                  format={'DD MMM'}
                  className={'font-weight-bold'}
                  style={{ color: '#767676', fontSize: '13px' }}
                />
                <DateTimeFormat
                  date={offer.endDate!}
                  format={' YYYY, LT'}
                  style={{ color: '#707070', fontSize: '13px' }}
                />
              </span>
            </div>
            <OfferExpiry
              offer={offer}
              className={
                'col-12 col-md-4 d-flex justify-content-center justify-content-md-end pr-md-4 align-items-center'
              }
            />
          </div>
        </div>
      </div>
      <div className={'col-12 d-flex d-md-none px-0'}>
        <div
          className={'justify-content-center py-1  px-0 w-100'}
          style={{ borderTop: '1px solid #DEDEDE' }}
        >
          <div className="col-12 d-flex px-0 justify-content-center">
            <img
              src={durationIcon}
              className={'ml-2 d-none d-md-inline'}
              alt={'duration'}
            />
            <span className={'px-0'}>
              <DateTimeFormat
                date={offer.startDate!}
                format={'DD MMM'}
                className={'font-weight-bold'}
                style={{ color: '#767676', fontSize: '12px' }}
              />
              <DateTimeFormat
                date={offer.startDate!}
                format={' YYYY, LT'}
                style={{ color: '#707070', fontSize: '12px' }}
              />
            </span>
            <span className={'mx-1'}>-</span>
            <span className={'px-0'}>
              <DateTimeFormat
                date={offer.endDate!}
                format={'DD MMM'}
                className={'font-weight-bold'}
                style={{ color: '#767676', fontSize: '12px' }}
              />
              <DateTimeFormat
                date={offer.endDate!}
                format={' YYYY, LT'}
                style={{ color: '#707070', fontSize: '12px' }}
              />
            </span>
          </div>
          <OfferExpiry
            offer={offer}
            className={
              'col-12 d-flex justify-content-center justify-content-md-end pr-md-4 align-items-center'
            }
          />
        </div>
      </div>
    </div>
  );
};
