/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect } from 'react';
import _ from 'lodash';
import { OfferDetails } from '../../DetailInputComponents/OfferDetails/OfferDetails';
import CustomInput from '../InputRow/CustomInput';
import CustomSelect from '../InputRow/CustomSelect';
import { NotificationContext } from '../../DetailInputComponents/NotificationContext/NotificationContext';
import { EmailTemplates } from '../../DetailInputComponents/EmailTemplates/EmailTemplates';
import { FormTemplates } from '../../DetailInputComponents/FormTemplates/FormTemplates';
import { TextArea } from '../../DetailInputComponents/TextArea/TextArea';
import { CategoryDropdown } from '../../DetailInputComponents/CategoryDropdown/CategoryDropdown';

export default function DetailInput({ detail, setDetail, setChildren }) {
  detail.label = !_.isEmpty(detail.templatePlaceholder)
    ? detail.templatePlaceholder.slice(1)
    : detail.label || detail.name;

  const firstOption = _.get(detail, 'options', [null])[0];
  const [currOption, setCurrOption] = React.useState(firstOption);

  useEffect(() => {
    _.isEmpty(detail.value)
      ? setCurrOption(firstOption)
      : setCurrOption(detail.value);
  }, [detail]);

  const onSelect = value => {
    setCurrOption(value);
    setDetail(value);
  };

  const nameToComponentMapper = name => {
    switch (name) {
      case 'category':
        return CategoryDropdown({ detail, setDetail });
      case 'productCategory':
        return CategoryDropdown({ detail, setDetail });
      default:
        return null;
    }
  };

  const typeToComponentMapper = type => {
    switch (type) {
      case 'dropdown':
        return CustomSelect({ detail, onSelect });
      case 'offer':
        return OfferDetails(detail, setDetail);
      case 'notificationContext':
        return NotificationContext({ detail, setDetail });
      case 'emailTemplates':
        return EmailTemplates({ detail, setDetail });
      case 'forms':
        return FormTemplates({ detail, setDetail });
      case 'textarea':
        return TextArea({ detail, setDetail });
      default:
        return CustomInput({ detail, setDetail });
    }
  };

  return !_.includes(['nodeId', 'journeyId', 'date'], detail.label) ? (
    <>
      {nameToComponentMapper(detail.name) || typeToComponentMapper(detail.type)}
      {!_.isEmpty(detail.children) &&
        _.map(detail.children[currOption], (d, idx) => (
          <DetailInput
            key={d.name}
            detail={d}
            setDetail={value => {
              const childrenClone = _.cloneDeep(detail.children);
              childrenClone[currOption][idx].value = value;
              setChildren(childrenClone);
            }}
            setChildren={children => {
              detail.children[currOption][idx].children = children;
            }}
          />
        ))}
    </>
  ) : (
    <></>
  );
}
