/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import Option from './Option';
import styles from './MasterCard.module.css';
import _ from 'lodash';

export default function MasterCard({ title, options = [], add, remove }) {
  const showTitle = () => {
    return (
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
      </div>
    );
  };

  const listItems = () => {
    return (
      <div>
        <div className={styles.count}>
          {title} <b>{options.length}</b>
        </div>
        <div className={styles.listItemContainer}>
          {options.map(option => (
            <Option
              key={option.id}
              name={option.option}
              listMode={true}
              action={() => remove(option.id)}
            />
          ))}
        </div>
      </div>
    );
  };

  const displayMaster = () => {
    return (
      <div className={styles.master}>
        <Option
          title={title}
          action={data => {
            if (
              !_.includes(
                _.map(options, o => _.toLower(o.option)),
                _.toLower(data)
              )
            ) {
              add({
                dropDownCategory: title,
                option: data
              });
            }
          }}
        />
        {listItems()}
      </div>
    );
  };

  return (
    <div className={styles.cardContainer}>
      {showTitle()}
      {displayMaster()}
    </div>
  );
}
