import React, { FC } from 'react';
import HomeIcon from '@material-ui/icons/Home';
import FlipbookPageGradient from './Utiles/FlipbookPageGradient';
import { Badge } from '@material-ui/core';
import _ from 'lodash';
import cartIcon from '../Assets/images/cart-icon.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignoreß
import $ from 'jquery';

const GoToPrevious = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="35"
    viewBox="0 0 24 24"
  >
    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

const GoToNext = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="35"
    viewBox="0 0 24 24"
  >
    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);
interface FlipbookNavigationBarProps {
  page: number;
  totalPageCount: number;
  flipbookEl: any;
  isCustomerView: boolean;
  cartItems: any;
}
export const FlipbookNavigationBar: FC<FlipbookNavigationBarProps> = ({
  page,
  totalPageCount,
  flipbookEl,
  cartItems,
  isCustomerView
}) => {
  const goToPageNumber = (pageNumber: number) => {
    $(flipbookEl.current).turn('page', pageNumber);
  };

  const canGoToPrevious = page > 1;
  const canGoToNext = page < totalPageCount;

  const goToNext = () => {
    $(flipbookEl.current).turn('next');
  };
  const goToPrevious = () => {
    $(flipbookEl.current).turn('previous');
  };
  const goToCart = () => {
    $(flipbookEl.current).turn('page', totalPageCount - 1);
  };
  return (
    <div className={'flipbookPageNav'}>
      <div className={'flipbook-nav-btn-container'}>
        <div
          className={'m-1 flipbook-nav-btn'}
          onClick={() => {
            goToPageNumber(1);
          }}
        >
          <HomeIcon />
        </div>
        <div
          onClick={goToPrevious}
          className={'mx-1 px-2 pr-3 flipbook-nav-btn'}
          style={{
            fill: canGoToPrevious ? 'rgb(57,57,57)' : 'rgb(191,191,191)',
            color: canGoToPrevious ? 'rgb(57,57,57)' : 'rgb(191,191,191)'
          }}
        >
          <GoToPrevious />
          <p className={'m-0'}>{'prev'}</p>
        </div>
        {/*            <div className={'mx-1 px-2 pr-3 flipbook-nav-btn'}>
              {getCurrentPageNumberComponent(page - 1)}
            </div>*/}
        <div
          onClick={goToNext}
          className={'mx-1 px-2 pl-3 flipbook-nav-btn'}
          style={{
            fill: canGoToNext ? 'rgb(57,57,57)' : 'rgb(191,191,191)',
            color: canGoToNext ? 'rgb(57,57,57)' : 'rgb(191,191,191)'
          }}
        >
          <p className={'m-0'}>{'next'}</p>
          <GoToNext />
        </div>
      </div>
      {isCustomerView && FlipbookPageGradient.isMobileView() && (
        <div className={'flipbook-cart-button'}>
          <Badge badgeContent={_.sumBy(cartItems, 'quantity')} color="primary">
            <img
              className={'cart-icon'}
              src={cartIcon}
              alt="cart_icon"
              onClick={goToCart}
            />
          </Badge>
        </div>
      )}
    </div>
  );
};
