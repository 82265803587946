import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import DateField from './DateField';
import CustomAutoComplete from './CustomAutoComplete';
import SelectField from './SelectField';
import _ from 'lodash';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
const KamFilter = props => {
  const classes = useStyles();
  const { handleDate, kam, formatDate, setKam, kamInfo } = props;
  const customerType = [
    { id: 1, name: 'RESELLER' },
    { id: 2, name: 'CONTRACTOR' }
  ];
  const actionType = [
    { id: 1, name: 'No action' },
    { id: 2, name: 'Action needed' }
  ];
  return (
    <div>
      <Grid container spacing={2}>
        <Grid classes={{ root: classes.root }} item xs={2}>
          <DateField
            label={'From'}
            value={formatDate(kam?.fromDate)}
            onChange={date => handleDate('fromDate', date)}
          />
        </Grid>
        <Grid classes={{ root: classes.root }} item xs={2}>
          <DateField
            label={'To'}
            value={formatDate(kam?.toDate)}
            onChange={date => handleDate('toDate', date)}
          />
        </Grid>
        <Grid classes={{ root: classes.root }} item xs={3}>
          <CustomAutoComplete
            label="KAM List"
            value={kam?.kamList}
            options={_.map(kamInfo, option => option.userName)}
            onChange={(event, newValue) => {
              if (!newValue.length)
                setKam({
                  ...kam,
                  kamList: newValue,
                  kamData: kamInfo,
                  actionFilter: '',
                  typeFilter: ''
                });
              else {
                setKam({
                  ...kam,
                  kamList: newValue,
                  actionFilter: '',
                  typeFilter: '',
                  kamData: kamInfo.filter(kam =>
                    newValue.includes(kam.userName)
                  )
                });
              }
            }}
          />
        </Grid>
        {/* {console.log(kam?.typeFilter)} */}
        <Grid classes={{ root: classes.root }} item xs={2}>
          <SelectField
            data={customerType}
            label="Customer Type"
            value={kam?.typeFilter}
            onChange={e => {
              if (e.target.value === '')
                setKam({
                  ...kam,
                  typeFilter: e.target.value,
                  kamData: kamInfo,
                  kamList: [],
                  actionFilter: ''
                });
              else {
                setKam({
                  ...kam,
                  typeFilter: e.target.value,
                  kamData: kamInfo.filter(
                    kam => e.target.value === kam.kamUserType
                  ),
                  kamList: [],
                  actionFilter: ''
                });
              }
            }}
          />
        </Grid>
        <Grid classes={{ root: classes.root }} item xs={3}>
          <SelectField
            data={actionType}
            label="Action Type"
            value={kam?.actionFilter}
            onChange={e => {
              if (e.target.value === '')
                setKam({
                  ...kam,
                  actionFilter: e.target.value,
                  kamData: kamInfo,
                  kamList: [],
                  typeFilter: ''
                });
              else {
                setKam({
                  ...kam,
                  actionFilter: e.target.value,
                  kamData: kamInfo.filter(kam => {
                    if (e.target.value === 'No action') {
                      return !kam.isActionNeeded;
                    } else {
                      return kam.isActionNeeded;
                    }
                  }),
                  kamList: [],
                  typeFilter: ''
                });
              }
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default KamFilter;
