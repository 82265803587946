import React, { FC, useCallback, useContext, useEffect } from 'react';
import './ProductCard.css';
import './ProductCardPopUp.css';
import _, { find, map } from 'lodash';
import { getProductHierarchyFromProductData } from '../Utiles/GroupedSelectedProducts';
import Button from '@material-ui/core/Button';
import {
  removeCartLineItem,
  updateCart
} from '../../../services/ecomCart.service';
import { EcomUpdateCart } from '../../../models/flipbook/ecomUpdateCart.model';
import { updateFlipbookCart } from '../Utiles/FlipbookUtiles';
import { withStyles } from '@material-ui/core/styles';
import { Collapse, Tooltip } from '@material-ui/core';
import cancelIcon from '../../Assets/images/icons/cancel_icon.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { AVAILABLE_CHECKOUTS } from '../Utiles/FlipbookConfig';
import { TileDataCollection } from '../../../models/FlipbookV2/TileData/TileDataCollection.model';
import { FlipbookContext } from '../Hooks/FlipbookContext';
import get from 'lodash/get';
import CircularProgress from '@material-ui/core/CircularProgress';
import isEmpty from 'lodash/isEmpty';
import { BusinessUnitContext } from '../../OfferDefinition/BusinessUnitContext';
import { FlipbookCartContext } from '../Hooks/FlipbookCartContext';
import moment from "moment";
import { FlipbookEventContext } from "../Hooks/FlipbookEventContext";

interface ProductCardPopUpProps {
  tileDataCollection: TileDataCollection;
  data: any;
  setSelectedProduct: (selectedProduct: any) => void;
  productHierarchy: any;
  allProducts: any;
  cartItems: [];
  setCartItems: any;
  checkoutFlow: string;
  handleProductPopUpClose: any;
  isStockLoaded: boolean;
  // addToCartEnable: boolean;
}

export const ProductCardPopUp: FC<ProductCardPopUpProps> = ({
  tileDataCollection,
  data,
  setSelectedProduct,
  productHierarchy: productHierarchyCF,
  allProducts,
  cartItems,
  setCartItems,
  checkoutFlow,
  handleProductPopUpClose,
  isStockLoaded
  // , addToCartEnable
}) => {
  const businessUnitContext = useContext(BusinessUnitContext);
  const { postProductClickEvent } = useContext(FlipbookEventContext);
  const productHierarchyNew = _.get(
    businessUnitContext,
    'business.productHierarchyNew',
    []
  );
  const productHierarchy = map(productHierarchyNew, 'displayName');
  const businessId = businessUnitContext.business.id;
  const businessName = businessUnitContext.business.name;
  const getProductHierarchyDisplayName = (productHierarchy: any) =>
    map(
      productHierarchy,
      ph =>
        find(productHierarchyNew, phn => phn?.casaFieldName === ph)?.displayName
    );
  const productHierarchyDisplayName = getProductHierarchyDisplayName(
    productHierarchyCF
  );
  const { sellingPrice, savePrice, actualPrice, inStock, backorderable } = data;
  const [filterObj, setFilterObj]: any = React.useState({});
  const flipbookContext = useContext(FlipbookContext);
  const isCustomerView = flipbookContext?.flipbookContext?.isCustomerView;
  const groupingHierarchyCF = get(
    flipbookContext,
    'flipbookContext.groupingHierarchy',
    []
  );
  const groupingHierarchy = map(groupingHierarchyCF, gh => {
    return find(productHierarchyNew, phn => phn?.casaFieldName === gh)
      ?.displayName;
  });
  const filteredProducts = useCallback(
    (updatedFilter: any) => {
      const currentCardProducts = get(
        tileDataCollection,
        'currentPageSKUS.0.sku',
        []
      );
      const filteredProductsByTile = _.filter(allProducts, v =>
        currentCardProducts.includes(v.id)
      );
      return _.filter(_.flatMap(filteredProductsByTile, 'extendedData'), {
        ...updatedFilter
      });
    },
    [allProducts, tileDataCollection]
  );
  const getImages = useCallback(() => {
    return _.get(_.find(filteredProducts(filterObj)), 'images', []);
  }, [filterObj, filteredProducts]);

  const isMobileView = window.screen.width <= 600;
  const [productDetailsShowMore, setProductDetailsShowMore] = React.useState(
    false
  );
  const [selectedThumnail, setSelectedThumnail]: any = React.useState(
    _.head(getImages())
  );
  const flipbookCartContext = useContext(FlipbookCartContext);
  const storeCode = flipbookCartContext.storeCode;

  // const [quantity, setQuantity]: any = React.useState(1);
  const [
    groupedProductVariantList,
    setGroupedProductVariantList
  ]: any = React.useState([]);
  const token = localStorage.getItem('token') || '';
  const ProductNameToolTip = withStyles(theme => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    }
  }))(Tooltip);

  useEffect(() => {
    if (_.isEmpty(filterObj)) {
      const filter = _.get(data, 'selectedProduct.extendedData');
      setFilterObj(filter);
    }
    setSelectedThumnail(_.head(getImages()));
  }, [data, filterObj, getImages]);

  if (_.isEmpty(groupedProductVariantList) && !_.isEmpty(groupingHierarchy)) {
    setGroupingVariants(getGroupingHierarchyWithLastNode(), '');
  }
  function getGroupingHierarchyWithLastNode() {
    // TODO: need to refactor this method, removing last node addition in variants chip
    // return _.concat(groupingHierarchy, _.last(productHierarchy));
    return groupingHierarchy;
  }
  function isChildrenNode(
    mainHierarchyName: string,
    compareHierarchyName: string
  ) {
    if (_.isEmpty(mainHierarchyName) || _.isEmpty(compareHierarchyName)) {
      return true;
    } else {
      return (
        _.findIndex(
          getGroupingHierarchyWithLastNode(),
          h => h === mainHierarchyName
        ) <
        _.findIndex(
          getGroupingHierarchyWithLastNode(),
          h => h === compareHierarchyName
        )
      );
    }
  }

  function getDefaultFilterObj() {
    return _.reduce(
      getHierarchyExceptGrouped(),
      function(acc, current) {
        return _.assign(acc, current);
      },
      {}
    );
  }

  function getCartItemVariantOrDefault(
    hierarchyName: any,
    allVariants: any,
    isOnChangeEvent: boolean
  ) {
    if (isOnChangeEvent) {
      return _.head(allVariants);
    }
    const cartItemProduct = _.find(filteredProducts(getDefaultFilterObj()), v =>
      _.find(cartItems, { id: v.id })
    );
    return _.get(cartItemProduct, hierarchyName, _.head(allVariants));
  }

  // function isCartItem() {
  //   return !_.isEmpty(
  //     _.find(filteredProducts(getDefaultFilterObj()), v =>
  //       _.find(cartItems, { id: v.id })
  //     )
  //   );
  // }

  function setGroupingVariants(
    groupingHierarchies: any,
    currentHierarchy: string
  ) {
    let filterObj = getDefaultFilterObj();
    const updatedGroupedProductVariantList = _.map(
      groupingHierarchies,
      (hierarchy, i) => {
        /*const hierarchyName =
          _.get(hierarchy, 'groupedHierarchyName', '') ||
          _.get(hierarchy, 'value', '');*/
        const hierarchyName = _.get(
          hierarchy,
          'groupedHierarchyName',
          hierarchy
        );
        const allVariants = _.map(
          _.unionBy(filteredProducts(filterObj), hierarchyName),
          v => _.get(v, hierarchyName)
        );
        const selectedVariant = isChildrenNode(currentHierarchy, hierarchyName)
          ? getCartItemVariantOrDefault(
              hierarchyName,
              allVariants,
              !_.isEmpty(currentHierarchy)
            )
          : _.get(hierarchy, 'selectedValue');
        filterObj = {
          ...filterObj,
          ...{ [hierarchyName]: selectedVariant }
        };
        return {
          groupedHierarchyName: hierarchyName,
          selectedValue: selectedVariant,
          variants: allVariants
        };
      }
    );
    setGroupedProductVariantList(updatedGroupedProductVariantList);
    setCurrentVariant(filterObj);
    setFilterObj(filterObj);
  }
  function setCurrentVariant(updatedFilter: any) {
    const sku = getSku(updatedFilter);
    const currentVariant = _.find(allProducts, v => {
      return v.extendedData.sku === sku;
    });
    const payload = {
      productSku: get(currentVariant, 'extendedData.sku'),
      pageContext: 'product-card',
      searchTerm: '',
      discountAmount: data.savePrice,
      price: data.actualPrice,
      netAmount: data.sellingPrice,
      elementType: 'image',
      elementValue: get(currentVariant, 'extendedData.sku'),
      interactionTime: moment().toString()
    };
    postProductClickEvent(payload);
    setSelectedProduct(currentVariant);
  }
  function handleVariantChanges(
    hierarchyName: string,
    selectedVariant: string
  ) {
    const updatedDefaultList = _.map(groupedProductVariantList, v => {
      v.selectedValue =
        v.groupedHierarchyName === hierarchyName
          ? selectedVariant
          : v.selectedValue;
      return v;
    });
    setGroupingVariants(updatedDefaultList, hierarchyName);
  }

  const getLoadingComponent = () => {
    return (
      <div
        style={{
          color: '#F2755F',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          width: '17rem'
        }}
      >
        <CircularProgress
          color={'inherit'}
          variant={'indeterminate'}
          size={30}
          thickness={5}
        />
      </div>
    );
  };

  const getPriceComponent = () => {
    return (
      <div className={'product-card-popup-row product-att'}>
        <div className={'product-font-size'}>{'Price'}</div>
        <div className={'product-card-popup-row product-card-popup-value'}>
          <span className={'product-price'}>
            {actualPrice && (
              <s>
                <div className={'product-font product-card-popup-mop '}>
                  ₹ {actualPrice}
                </div>
              </s>
            )}
            <span className={'product-card-popup-base-mrp'}>
              {'₹ '} {sellingPrice}{' '}
            </span>
            {savePrice && (
              <span className={'product-font product-tile-save-price'}>
                {` Save ₹ `}
                {savePrice}
              </span>
            )}
          </span>
        </div>
      </div>
    );
  };
  const getProductDetailsAsKeyValue = (key: string, value: any) => {
    // return (
    //   <tr key={key} className={'product-font-size product-card-popup-row'}>
    //     <td className={'product-card-popup-row product-card-popup-key'}>
    //       {key}
    //     </td>
    //     <ProductNameToolTip title={value} aria-label="productTileTLName">
    //       <td
    //         className={
    //           'product-card-popup-row product-font-size product-card-popup-value'
    //         }
    //       >
    //         {value}
    //       </td>
    //     </ProductNameToolTip>
    //   </tr>
    // );
    return (
      <div className={'product-att'}>
        <div className={'product-font-size'}>{_.startCase(key)}</div>
        <div className={'product-card-popup-value'}>{value}</div>
      </div>
    );
  };

  const getShrinkedName = (centerName: string) => {
    let concatString = _.get(centerName.match(/.{1,10}/g), '0', '');
    concatString =
      concatString.length === centerName.length
        ? concatString
        : concatString.concat('...');
    return {
      shrink: concatString,
      actualString: centerName
    };
  };
  function getHierarchyExceptGrouped() {
    const selectedProductHierarchy = getProductHierarchyFromProductData(
      data.selectedProduct,
      productHierarchyDisplayName
    );
    const firstHierarchyInGrouping = _.head(groupingHierarchy);
    let index = _.findIndex(
      selectedProductHierarchy,
      `${firstHierarchyInGrouping}`
    );
    index = index === -1 ? productHierarchy.length : index;
    return _.take(selectedProductHierarchy, index);
  }
  const getProductDetailsComponent = () => {
    return _.map(getHierarchyExceptGrouped(), product => {
      let key: any = _.head(_.keys(product));
      key = getShrinkedName(key);
      const value: any = _.head(_.values(product));
      // value = getShrinkedName(value);
      return getProductDetailsAsKeyValue(key.actualString, value);
    });
  };
  function getSku(updatedFilter: any) {
    return _.get(_.find(filteredProducts(updatedFilter)), 'sku', '');
  }
  const onClickAddToCart = () => {
    const sku = getSku(filterObj);
    const quantity = 1;
    const updatedCartItem = {
      sku: sku,
      quantity: quantity
    };
    updateCartInfoInEcom(updatedCartItem, token);
  };

  function updateCartInfoInEcom(cart: EcomUpdateCart, cartToken: string) {
    updateCart(cart, businessId, businessName, storeCode).then(
      res => {
        if (!_.has(res, 'error')) {
          updateFlipbookCartInfo(res);
        }
      },
      err => err
    );
  }

  function updateFlipbookCartInfo(ecomResponse: any) {
    setCartItems(updateFlipbookCart(ecomResponse));
  }
  function redirectionURL() {
    return _.get(_.head(filteredProducts(filterObj)), 'redirectionUrl', '');
  }
  function isRedirectionUrlAvailable() {
    const url = redirectionURL();
    return !isEmpty(url);
  }
  function onClickRedirectToEcom() {
    if (isRedirectionUrlAvailable()) {
      window.open(redirectionURL());
    }
  }

  function getSelectedCartItem() {
    const selectedVariant = _.find(
      _.flatMap(allProducts, 'extendedData'),
      filterObj
    );
    return _.find(cartItems, { sku: _.get(selectedVariant, 'id', '') });
  }

  function isAddToCartActive() {
    return _.isEmpty(getSelectedCartItem());
  }

  const removeCartItem = () => {
    const lineItemId = _.get(getSelectedCartItem(), 'lineItemId');
    removeCartLineItem(lineItemId, businessId, businessName).then(
      res => {
        if (!_.has(res, 'error')) {
          setCartItems(updateFlipbookCart(res));
        }
      },
      err => err
    );
  };

  // const inStockStatus = () => {
  //   return (
  //     <div className={'in-stock-status stock-status-background'}>in stock</div>
  //   );
  // };
  //
  // const comingSoonStatus = () => {
  //   return (
  //     <div className={'coming-soon-status stock-status-background'}>
  //       coming soon
  //     </div>
  //   );
  // };
  const isShowMoreFadedAvailable = () => {
    return !productDetailsShowMore && getHierarchyExceptGrouped().length > 3;
  };

  const getCollapsHeight = () => {
    const productInfoLength = getHierarchyExceptGrouped().length;
    switch (productInfoLength) {
      case 0:
        return 50;
      case 1:
        return 100;
      case 2:
        return 160;
      default:
        return 215;
    }
  };

  return (
    <div className={'product-card-popup-container'}>
      <div className={'popup-header-section popup-no-header'}>
        <div className={'popup-header-section-title'}>
          <span className={'popup-header-section-title-wrap'}>
            {_.get(data, 'selectedProduct.extendedData.name', '')}
          </span>
        </div>
        {!inStock && !backorderable && (
          <div className={'header-stock-status-container'}>
            <p className={'header-stock-status'}>{'Coming Soon'}</p>
          </div>
        )}
        <img
          className={'popup-close-section'}
          src={cancelIcon}
          alt={'close'}
          onClick={handleProductPopUpClose}
        />
      </div>
      <div className={'product-specification'}>
        <div className={'product-card-popup-body'}>
          <div className={'product-card-popup-image'}>
            <div style={{ padding: '1rem' }}>
              <div
                className={
                  isCustomerView
                    ? `${
                        inStock || backorderable ? '' : 'out-of-stock'
                      } product-badge promoted`
                    : ''
                }
                data-awards={'Coming Soon'}
              >
                <LazyLoadImage
                  alt={selectedThumnail}
                  src={selectedThumnail}
                  effect={'blur'}
                  className={'product-card-popup-image-container'}
                />
              </div>
            </div>
            <div
              className={`product-thumbnail-img-container ${
                _.get(data, 'productImage', []).length <= 3
                  ? 'thumbnail-img-container-align-center'
                  : 'thumbnail-img-container-align-initial'
              }`}
            >
              {_.map(_.get(data, 'productImage', []), imgUrl => {
                return (
                  <LazyLoadImage
                    alt={imgUrl}
                    src={imgUrl}
                    effect={'blur'}
                    className={`product-thumbnail ${
                      imgUrl === selectedThumnail ? 'active' : ''
                    }`}
                    onClick={() => setSelectedThumnail(imgUrl)}
                  />
                );
              })}
            </div>
            <div className={'action-container'}>
              {/*<div className={'stock-status'}>*/}
              {/*  {inStock ? inStockStatus() : comingSoonStatus()}*/}
              {/*</div>*/}
              {/*{addToCartEnable &&*/}
              {!isMobileView && (
                <>
                  {checkoutFlow !== AVAILABLE_CHECKOUTS.Ecommerce.key && (
                    <div className={'add-to-cart'}>
                      {isAddToCartActive() ? (
                        <div className={'add-to-cart-btn'}>
                          {!isStockLoaded &&
                            isCustomerView &&
                            getLoadingComponent()}
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              fontSize: 11,
                              width: '100%',
                              opacity: `${!isStockLoaded ? 0.2 : 1}`
                            }}
                            onClick={() => onClickAddToCart()}
                            disabled={
                              !(inStock || backorderable) || !isStockLoaded
                            }
                          >
                            {_.get(
                              AVAILABLE_CHECKOUTS,
                              `${checkoutFlow}.actions`,
                              'Add to cart'
                            )}
                          </Button>
                        </div>
                      ) : (
                        <div className={'remove-from-cart-btn'}>
                          <Button
                            variant="contained"
                            style={{ fontSize: 11, width: '100%' }}
                            onClick={() => removeCartItem()}
                          >
                            {_.get(
                              AVAILABLE_CHECKOUTS,
                              `${checkoutFlow}.reverseActions`,
                              ''
                            )}
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                  {checkoutFlow === AVAILABLE_CHECKOUTS.Ecommerce.key && (
                    <div className={'my-4'}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!isRedirectionUrlAvailable()}
                        style={{ fontSize: 12, width: '100%' }}
                        onClick={() => onClickRedirectToEcom()}
                      >
                        Pay at eCom
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className={'product-info'}>
            <div className={'product-card-popup-info product-card-border-top'}>
              {/*<div className={'product-card-pop-up-title'}>*/}
              {/*  {'Product Details'}*/}
              {/*</div>*/}
              <div className={'product-specification-container'}>
                <Collapse
                  in={productDetailsShowMore}
                  collapsedHeight={getCollapsHeight()}
                >
                  <div
                    className={`product-details-table ${
                      isShowMoreFadedAvailable() ? 'collapse-fade-out' : ''
                    }`}
                  >
                    {getPriceComponent()}
                    {getProductDetailsComponent()}
                  </div>
                </Collapse>
                {getHierarchyExceptGrouped().length > 3 && (
                  <tr>
                    <th colSpan={3}>
                      <span
                        className={'product-card-show-more'}
                        onClick={() => {
                          setProductDetailsShowMore(
                            (prevState: boolean) => !prevState
                          );
                        }}
                      >
                        {productDetailsShowMore ? 'Show less' : 'Show more'}
                      </span>
                    </th>
                  </tr>
                )}
                {!_.isEmpty(groupedProductVariantList) && (
                  <div
                    className={'product-card-footer product-card-border-top'}
                  >
                    {/*<div className={'product-card-pop-up-title'}>*/}
                    {/*  {'Select Variant'}*/}
                    {/*</div>*/}
                    <table className={'px-4 py-2 product-card-variant-listing'}>
                      <tbody>
                        {_.map(groupedProductVariantList, (l, i) => {
                          return (
                            <div key={i}>
                              <div
                                className={'product-font-size'}
                                style={{ width: '30%' }}
                              >
                                {_.startCase(l.groupedHierarchyName)}
                              </div>
                              <div
                                style={{
                                  display: 'inline-flex',
                                  alignItems: 'center',
                                  overflowX: 'auto',
                                  width: '100%'
                                }}
                              >
                                <div
                                  className={
                                    'product-card-variant-chip-selection'
                                  }
                                >
                                  {_.map(l.variants, (variant, i) => {
                                    return (
                                      <ProductNameToolTip
                                        title={variant}
                                        aria-label="productTileTLName"
                                      >
                                        <div
                                          key={i}
                                          className={`variant-btn ${
                                            l.selectedValue === variant
                                              ? 'active'
                                              : ''
                                          }`}
                                          onClick={() =>
                                            handleVariantChanges(
                                              l.groupedHierarchyName,
                                              variant
                                            )
                                          }
                                        >
                                          {variant}
                                        </div>
                                      </ProductNameToolTip>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        {/*<table>*/}
                        {/*  <tbody></tbody>*/}
                        {/*</table>*/}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*addToCartEnable && checkoutFlow !== AVAILABLE_CHECKOUTS.Ecommerce*/}
      {isMobileView && (
        <div className={'product-card-cta-footer'}>
          {checkoutFlow !== AVAILABLE_CHECKOUTS.Ecommerce.key && (
            <div className={'add-to-cart'}>
              {isAddToCartActive() ? (
                <div className={'add-to-cart-btn'}>
                  <Button
                    variant={'contained'}
                    color="primary"
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: 'unset'
                    }}
                    onClick={() => onClickAddToCart()}
                    disabled={!(inStock || backorderable)}
                  >
                    {_.get(
                      AVAILABLE_CHECKOUTS,
                      `${checkoutFlow}.actions`,
                      'Add to cart'
                    )}
                  </Button>
                </div>
              ) : (
                <div className={'remove-from-cart-btn'}>
                  <Button
                    variant="contained"
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: 'unset'
                    }}
                    onClick={() => removeCartItem()}
                  >
                    {_.get(
                      AVAILABLE_CHECKOUTS,
                      `${checkoutFlow}.reverseActions`,
                      'Add to cart'
                    )}
                  </Button>
                </div>
              )}
            </div>
          )}
          {checkoutFlow === AVAILABLE_CHECKOUTS.Ecommerce.key && (
            <div className={'remove-from-cart-btn'}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: 'unset'
                }}
                onClick={() => onClickRedirectToEcom()}
              >
                Pay at eCom
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default ProductCardPopUp;
