/* eslint-disable @typescript-eslint/explicit-function-return-type */

import config from '../config/index';

export async function saveLoyaltySignUp(data, businessUnitId) {
  return await fetch(
    `${config.ruleServerUrl}/users/pwa-config/loyalty-signup?businessUnitId=${businessUnitId}`,
    {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(data)
    }
  )
    .then(r => {
      return r.json();
    })
    .catch(err => {
      console.log(err);
    });
}

export async function getLoyaltySignUpValues(businessUnitId) {
  const url = new URL(
    `${config.ruleServerUrl}/users/pwa-config/loyalty-signup?businessUnitId=${businessUnitId}`
  );

  return await fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return err;
    });
}
