/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import * as journeyListItem from './JourneyListItem.module.css';
import { useHistory } from 'react-router-dom';
import { getJourneyReports } from '../../../../../services/journey-engine.service';

function TrackIcon(props) {
  return (
    <svg
      style={{ marginLeft: '20px' }}
      width="20px"
      height="20px"
      fill="#F07663"
      viewBox="0 0 512 512"
      {...props}
    >
      <path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm-28.9 143.6l75.5 72.4H120c-13.3 0-24 10.7-24 24v16c0 13.3 10.7 24 24 24h182.6l-75.5 72.4c-9.7 9.3-9.9 24.8-.4 34.3l11 10.9c9.4 9.4 24.6 9.4 33.9 0L404.3 273c9.4-9.4 9.4-24.6 0-33.9L271.6 106.3c-9.4-9.4-24.6-9.4-33.9 0l-11 10.9c-9.5 9.6-9.3 25.1.4 34.4z" />
    </svg>
  );
}

const Status = ({ status }) =>
  status ? (
    <div className={journeyListItem.status}>
      <div
        style={{ backgroundColor: 'green' }}
        className={journeyListItem.indicator}
      ></div>
      <div>Running</div>
    </div>
  ) : (
    <div className={journeyListItem.status}>
      <div
        style={{ backgroundColor: 'red' }}
        className={journeyListItem.indicator}
      ></div>
      <div>Not Running</div>
    </div>
  );

export default function JourneyListItem({ journey, key, selected }) {
  const [reports, setReports] = useState({});
  useEffect(() => {
    async function getReports() {
      setReports(await getJourneyReports(journey.id));
    }

    getReports();
  }, []);
  const history = useHistory();
  return (
    <div className={journeyListItem.container}>
      <div
        className={[journeyListItem.item, journeyListItem.journeyName].join(
          ' '
        )}
        style={{ cursor: 'pointer', minWidth: '200px', maxWidth: '200px' }}
        onClick={() => {
          history.push(`edit/${journey.id}`);
        }}
      >
        {journey.journeyName}
      </div>
      <div className={journeyListItem.item}>{journey.startDate}</div>
      <div className={journeyListItem.item}>{journey.endDate}</div>
      <div className={journeyListItem.item}>{reports?.completed || '-/-'}</div>
      <div className={journeyListItem.item}>{reports?.started || '-/-'}</div>
      <div className={journeyListItem.item}>
        {((reports?.successful / reports?.started) * 100).toFixed(2) || '-/-'}
      </div>
      <div
        className={journeyListItem.item}
        style={{ minWidth: '200px', maxWidth: '200px' }}
      >
        {journey.createdBy}
      </div>
      <div
        className={journeyListItem.item}
        style={{
          minWidth: '200px',
          maxWidth: '200px'
        }}
      >
        <Status status={journey.isRunning} />
      </div>
      <div
        style={{ color: '#F07663' }}
        className={journeyListItem.item}
        onClick={() => {
          history.push(`edit/${journey.id}`);
        }}
      >
        TRACK
        <TrackIcon />
      </div>
    </div>
  );
}
