import config from '../config';

const configUrl = new URL(`${config.ruleServerUrl}/users/tenant-configs`);
export async function getTenantConfig(tenant, configType) {
  const stringQueryParams = { tenant, type: configType };
  configUrl.search = new URLSearchParams(stringQueryParams);
  return await fetch(configUrl, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .then(r => {
      return r;
    })
    .catch(err => {
      console.error(err);
      return {};
    });
}

export async function saveTenantConfig(config, configType, tenant) {
  const configData = { config, configType, tenant };
  return await fetch(configUrl, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json'
    },
    body: JSON.stringify(configData)
  })
    .then(r => r.json())
    .catch(err => {
      console.log(err);
    });
}
