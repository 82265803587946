import React from 'react';
import { startCase } from 'lodash';

const SelectFilter = props => {
  const { column, filterOptions } = props;
  const { filterValue, setFilter, id } = column;

  const renderFilterOptions = () => {
    if (id === 'isActive') {
      return filterOptions.map((option, i) => (
        <option key={i} value={option.value}>
          {option.name}
        </option>
      ));
    }

    return (
      <>
        <option value="">none</option>
        {filterOptions.map((option, i) => (
          <option key={i} value={option}>
            {startCase(option)}
          </option>
        ))}
      </>
    );
  };

  return (
    <div>
      <select
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
      >
        {renderFilterOptions()}
      </select>
    </div>
  );
};

export default SelectFilter;
