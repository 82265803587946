import React, { FC, useState } from 'react';
import BillFilter from './Filter';
import BillDownloadTable from './List';
import usePagination from '../../../../shared/hooks/usePagination';
import { getMemberBillReportDownload } from '../../../../services/TenantReports/MemberBillReport.service';

const MemberLoyaltyReport: FC = () => {
  const [refreshView, setRefreshView] = useState<string>('');
  const {
    data,
    isLoading,
    page,
    offset,
    setPage,
    setOffset
  } = usePagination(getMemberBillReportDownload, [refreshView]);
  return (
    <div style={{ padding: '2rem' }}>
      <div>
        <h4>Enrollment Report</h4>
      </div>
      <BillFilter setRefreshView={setRefreshView} />
      <BillDownloadTable
        reports={data || []}
        isLoading={isLoading}
        page={page}
        offset={offset}
        setPage={setPage}
        setOffset={setOffset}
      />
    </div>
  );
};

export default MemberLoyaltyReport;
