import React, { FC } from 'react';
import { Box, TableBody, TableCell, TableRow } from '@material-ui/core';
import CampaignVariantDetails from '../../../../../../models/campaign/CampaignVariantDetails';
import { Table, TableContainer } from '@material-ui/core';
import TableHeader from '../../../../shared/components/TableHeader/TableHeader';
import _ from 'lodash';
import styles from './CampaignVariantCard.module.scss';

import targetedIcon from '../../../../../Assets/campaign/targeted.svg';
import deliveredIcon from '../../../../../Assets/campaign/delivered.svg';
import sentIcon from '../../../../../Assets/campaign/sent.svg';
import clickedIcon from '../../../../../Assets/campaign/clicked.svg';

interface CampaignVariantCardProps {
  variant: CampaignVariantDetails;
}

const VariantFields = {
  CAMPAIGN_NAME: 'Campaign Name',
  VARIANT_NAME: 'Variant Name',
  VARIANT_PERCENTAGE: 'Variant Percentage',
  TARGETED: 'Targeted',
  SENT: 'Sent',
  DELIVERED: 'Delivered',
  CLICKED: 'Clicked',
  PURCHASERS_COUNT: 'Purchasers Count',
  PURCHASERS_COUNT_DELIVERED: 'Purchasers Count % With Delivered',
  PURCHASERS_COUNT_TARGETED: 'Purchasers Count % With Targeted',
  PURCHASER_TOTAL_SALES: 'Purchaser Total Sales',
  SUCCESS_METRIC_COUNT: 'Success Metric Count',
  SUCCESS_METRIC_SALES_VALUE: 'Success Metric Sales Value',
  SUCCESS_METRIC_DELIVERED: 'Success Metric % With Delivered',
  SUCCESS_METRIC_TARGETED: 'Success Metric % With Targeted'
};

const CampaignVariantCard: FC<CampaignVariantCardProps> = ({ variant }) => {
  const headers = [
    'Name',
    'Customer Count',
    'Total Sales',
    'Targeted %',
    'Delivered %'
  ];

  const purchaserValues = [
    'Purchasers',
    _.get(variant, VariantFields.PURCHASERS_COUNT, ''),
    _.get(variant, VariantFields.PURCHASER_TOTAL_SALES, ''),
    _.get(variant, VariantFields.PURCHASERS_COUNT_TARGETED, ''),
    _.get(variant, VariantFields.PURCHASERS_COUNT_DELIVERED, '')
  ];

  const successMetricValues = [
    'Success Metric',
    _.get(variant, VariantFields.SUCCESS_METRIC_COUNT, ''),
    _.get(variant, VariantFields.SUCCESS_METRIC_SALES_VALUE, ''),
    _.get(variant, VariantFields.SUCCESS_METRIC_TARGETED, ''),
    _.get(variant, VariantFields.SUCCESS_METRIC_DELIVERED, '')
  ];

  const renderStatus = (
    text: string,
    icon: string,
    accessor: string
  ): JSX.Element => {
    return (
      <Box className={styles.statusCount}>
        <img alt={text} src={icon} />
        <span>{text}: </span> {_.get(variant, accessor, 0)}
      </Box>
    );
  };

  const renderRow = (values: string[]): JSX.Element => {
    return (
      <TableRow>
        {_.map(values, value => (
          <TableCell className={styles.cell} align="center">
            {value}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const renderVariantOverview = (): JSX.Element => {
    const variantPercentage = _.round(
      parseFloat(_.get(variant, VariantFields.VARIANT_PERCENTAGE, '0')),
      0
    );
    return (
      <Box className={styles.variantInfo}>
        <Box className={styles.name}>
          Variation {_.get(variant, VariantFields.VARIANT_NAME, 'DEFAULT')} (
          {variantPercentage}
          %)
        </Box>
        <Box className={styles.statuses}>
          {renderStatus('Targeted', targetedIcon, VariantFields.TARGETED)}
          {renderStatus('Sent', sentIcon, VariantFields.SENT)}
          {renderStatus('Delivered', deliveredIcon, VariantFields.DELIVERED)}
          {renderStatus('Clicked', clickedIcon, VariantFields.CLICKED)}
        </Box>
      </Box>
    );
  };

  const renderVariantAnalytics = (): JSX.Element => {
    return (
      <TableContainer component={Box}>
        <Table>
          <TableHeader
            textAlign="center"
            headers={headers}
            className={styles.header}
          />
          <TableBody>
            {renderRow(purchaserValues)}
            {renderRow(successMetricValues)}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box className={styles.variantCard}>
      {renderVariantOverview()}
      {renderVariantAnalytics()}
    </Box>
  );
};

export default CampaignVariantCard;
