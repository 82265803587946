/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import '../LoyaltyConfig/index.scss';

import { useFormik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { referralConfiguration } from '../../constants/loyaltyConfig';
import {
  formGroupRedemption,
  referralInitialState
} from '../../constants/referralPoints';
import useCurrentUser from '../../hooks/useCurrentUser';
import { Business } from '../../models/User.model';
import ButtonField from '../../reusable/ButtonField';
import { TextFieldComponent } from '../../reusable/TextFieldComponent';
import ConfigService from '../../services/referral.service';
import { toastMessageAction } from '../../store/action';
import { ParentLink } from '../../utils/ParentLink';
import { referralToSchema } from '../../validation/referralConfiguration';
import OTPImage from '../Assets/referralconfiguration.svg';
import SettingsBreadCrumbs from '../SettingsBreadCrumbs/SettingsBreadCrumbs';

const ReferralConfiguration = () => {
  const {
    referredUsersPoints,
    referrerPoints,
    expireInDays,
    submitButton,
    cancelButton
  } = formGroupRedemption;
  const [business, setBusiness] = useState(new Business());
  const dispatch = useDispatch();
  const user = useCurrentUser();
  //handle submit
  const onSubmit = data => {
    Object.keys(data).forEach(key => {
      if (data[key] === '') {
        delete data[key];
      }
    });
    ConfigService.referralFriendConfig(business?.id, data).then(() => {
      dispatch(
        toastMessageAction({
          severity: 'success',
          message: `Referral points add successfully !`,
          messageStatus: true
        })
      );
    });
  };

  //to handle submit formik
  const formik = useFormik({
    initialValues: referralInitialState,
    validationSchema: referralToSchema,
    onSubmit
  });
  const {
    values,
    errors,
    handleChange,
    touched,
    resetForm,
    setValues
  } = formik;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsBuId = _.parseInt(urlParams.get('bu'));
    const paramsBusiness = _(user.getBusinesses()).find(
      b => b.id === paramsBuId
    );
    if (paramsBusiness) {
      setBusiness(paramsBusiness);
      if (business.id !== 0)
        setValues(business?.referralLoyaltyConfig || referralInitialState);
    }
  }, [user, business.id]);

  const addUrlParams = link => {
    const urlParams = new URLSearchParams(window.location.search);
    return `${link}?bu=${urlParams.get('bu')}`;
  };

  return (
    <>
      <SettingsBreadCrumbs
        currentModuleName="Retensee Configuration"
        fromModuleName="Module Settings"
        isModule={true}
      />
      <div className={'retenseeContainer'}>
        {referralConfiguration?.map((item, index) => {
          return (
            <ParentLink
              to={addUrlParams(item.path)}
              className={item.className ? item.className : 'heading'}
              key={index}
            >
              {item.title}
            </ParentLink>
          );
        })}
      </div>
      <div className={'adHocPointsContainer'}>
        <img
          src={OTPImage}
          alt="referral-configuration"
          className="redemptionImage"
        />
        <h4 className="adHocPointsTitle">Set your Referral Configuration</h4>
        <form onSubmit={formik.handleSubmit} className="formSubmitation">
          <TextFieldComponent
            label={referredUsersPoints?.label}
            variant={referredUsersPoints?.variant}
            onChange={handleChange}
            type={referredUsersPoints?.type}
            name={referredUsersPoints?.name}
            value={values.referredUsersPoints}
            error={touched.referredUsersPoints && errors.referredUsersPoints}
            className={referredUsersPoints?.className}
            placeholder={referredUsersPoints?.placeholder}
          />
          <TextFieldComponent
            label={referrerPoints?.label}
            variant={referrerPoints?.variant}
            onChange={handleChange}
            type={referrerPoints?.type}
            name={referrerPoints?.name}
            value={values.referrerPoints}
            error={touched.referrerPoints && errors.referrerPoints}
            className={referrerPoints?.className}
            placeholder={referrerPoints?.placeholder}
          />
          <TextFieldComponent
            label={expireInDays?.label}
            variant={expireInDays?.variant}
            onChange={handleChange}
            type={expireInDays?.type}
            name={expireInDays?.name}
            value={values.expireInDays}
            error={touched.expireInDays && errors.expireInDays}
            className={expireInDays?.className}
            placeholder={expireInDays?.placeholder}
          />
          <div className={'submitButtonContainer'}>
            <ButtonField
              variant={cancelButton.variant}
              label={cancelButton.label}
              type={cancelButton.type}
              onClick={() => resetForm()}
              className={cancelButton.className}
            />

            <ButtonField
              variant={submitButton.variant}
              label={submitButton.label}
              type={submitButton.type}
              className={submitButton.className}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default ReferralConfiguration;
