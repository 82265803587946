import React, { FC, useState } from 'react';
import UtilService from '../../../services/util.service';
import _, { capitalize } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
// import AddCircle from '@material-ui/icons/AddCircle';
import './UploadImage.css';
import { CircularProgress } from '@material-ui/core';
import imgAddIcon from '../../Assets/images/img_add_icon.png';

interface UploadImageProps {
  imageUploadType: string;
  setImageUploadData: (data: any) => void;
  isActive: boolean;
  iconStyle: any;
  label?: string;
}

export const UploadImage: FC<UploadImageProps> = ({
  imageUploadType,
  setImageUploadData,
  isActive = false,
  iconStyle = {},
  label = ''
}) => {
  // const imageUploadEl = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  return (
    <div key={imageUploadType}>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id={imageUploadType}
        type="file"
        // ref={imageUploadEl}
        onChange={async e => {
          setLoading(true);
          const files = Array.from(e.target.files!!);
          const formData = new FormData();
          files.forEach(file => {
            formData.append('image', file);
          });
          const response = await UtilService.uploadImage(formData);
          const publicImageUrls = _.get(response, 'publicImageUrls', []);
          setTimeout(() => {
            setLoading(false);
            setImageUploadData({ [imageUploadType]: _.head(publicImageUrls) });
          }, 600);
        }}
      />
      <div>
        {loading ? (
          <div style={{ color: '#F2755F' }} className={'m-1 mx-2'}>
            <CircularProgress
              color="inherit"
              style={{ height: 20, width: 20 }}
            />
          </div>
        ) : (
          <label htmlFor={imageUploadType}>
            <span style={{ cursor: 'pointer' }}>{capitalize(label)}</span>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              style={iconStyle}
            >
              <img
                style={{ opacity: isActive ? 0.8 : 1 }}
                src={imgAddIcon}
                alt={'imge add'}
              />
              {/*<AddCircle style={{ opacity: isActive ? 0.6 : 1 }} />*/}
            </IconButton>
          </label>
        )}
      </div>
    </div>
  );
};

export default UploadImage;
