/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { getCurrentUser } from '../services/user.service';
import { User } from '../models/User.model';
import _ from 'lodash';

export default function useCurrentUser() {
  const [user, setUser] = useState<User>(new User());
  useEffect(() => {
    getCurrentUser().then(fetchedUser => {
      if (!_.isEqual(user, fetchedUser)) {
        setUser(fetchedUser);
      }
    });
  }, []);
  return user;
}
