/* eslint-disable @typescript-eslint/explicit-function-return-type */
import config from '../config/index';

const lmsDropdownsURL = `${config.ruleServerUrl}/users/lms-dropdowns`;

export async function getLMSDropDowns() {
  return await fetch(lmsDropdownsURL, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json'
    }
  })
    .then(r => {
      return r.json();
    })
    .catch(err => {
      console.log(err);
    });
}

export async function addLMSDropDown(data) {
  return await fetch(lmsDropdownsURL + '/add', {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(r => {
      return r.json();
    })
    .catch(err => {
      console.log(err);
    });
}
export async function removeLMSDropDown(id) {
  return await fetch(lmsDropdownsURL + '/remove/' + id, {
    credentials: 'include',
    method: 'DELETE',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json'
    }
  })
    .then(r => {
      return r.json();
    })
    .catch(err => {
      console.log(err);
    });
}
