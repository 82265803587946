import React, { useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty, values } from 'lodash';

import { SectionType } from '../../../../DynamicInvoiceGenerator/InvoiceGenerator';
import { InvoiceContext } from '../../../context/InvoiceContext';
import { Heading } from '../../../shared/Heading';
import useDebounce from '../../../shared/use-debounce';
import configs from '../../../utils/configs';
import sectionUtils from '../../../utils/sectionUtils';
import AntSwitch from '../../Switch/AntSwitch';
import classes from './HsnSummary.module.scss';

const getHsnConfigFields = () => {
  const hsnFields = sectionUtils.getInitialValuesByIndex(
    configs.sectionConfig[type].fields
  );
  return hsnFields;
};
const type = SectionType.HSN_SUMMARY;
const HsnSummary: React.FC = () => {
  const invoiceContext = useContext(InvoiceContext);
  const { fields } = invoiceContext.getConfig(type);
  const initialState = sectionUtils.getInitialValuesByIndex(fields);
  const [inputFields, setInputFields] = useState(initialState);
  const debouncedFields = useDebounce(inputFields, 500);
  const baseHsnConfig = useMemo(getHsnConfigFields, [debouncedFields]);
  const isHsnConfig = !isEmpty(inputFields);
  const [showHsn, setShowHsn] = useState(isHsnConfig);

  const handleHsnToggle: React.ChangeEventHandler<HTMLInputElement> = e => {
    setShowHsn(e.target.checked);
    if (e.target.checked) return setInputFields(baseHsnConfig);
    return setInputFields({});
  };

  useEffect(() => {
    invoiceContext.setConfig(type, inputFields);
    // eslint-disable-next-line
  }, [debouncedFields, showHsn]);

  function renderSummayTable() {
    const headLabels = sectionUtils.getRequiredLabels(type);
    return (
      <table className={classes.hsntable}>
        <thead>
          {values(headLabels).map(head => (
            <th>{head}</th>
          ))}
        </thead>
      </table>
    );
  }

  return (
    <div className={classes.container}>
      <Heading title="Hsn Summary" />
      <div className={classes.switch}>
        <AntSwitch
          checked={showHsn}
          onChange={handleHsnToggle}
          name={'hsnToggle'}
        />
        <p>Show HSN Summary in the invoice</p>
      </div>
      {showHsn && renderSummayTable()}
    </div>
  );
};
export default HsnSummary;
