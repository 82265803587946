import cancelIcon from '../../../Assets/images/icons/cancel_icon.svg';
import SelectWithAutoComplete from '../../../Flipbook/SelectWithAutoComplete/SelectWithAutoComplete';
import CheckboxTree from 'react-checkbox-tree';
import customCheckedIcon from '../../../Assets/images/check_box_icon.svg';
import unchecked from '../../../Assets/images/unchecked_icon.svg';
import addIcon from '../../../Assets/images/add_icon.svg';
import minimizeIcon from '../../../Assets/images/minimize_icon.svg';
import _ from 'lodash';
import { Button, CircularProgress } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';

interface ProductHierarchyProps {
  allProducts: any;
  productHierarchy: any;
  selectedProductsByPath: any;
  setSelectedProductsByPath: any;
  setIsAddCollectionOpen: any;
  productsFilteredByFirstHierarchy: any;
  setProductsFilteredByFirstHierarchy: any;
  productHierarchyTreeExpanded: any;
  setProductHierarchyTreeExpanded: any;
  productHierarchyTreeChecked: any;
  setProductHierarchyTreeChecked: any;
  skus: any;
  updateState: any;
}
const PRODUCT_SELECTION_DONE = 'done';
const PRODUCT_SELECTION_CLOSE = 'close';

const ProductsHierarchyModal: FC<ProductHierarchyProps> = ({
  allProducts,
  productHierarchy,
  selectedProductsByPath,
  setSelectedProductsByPath,
  setIsAddCollectionOpen,
  productsFilteredByFirstHierarchy,
  setProductsFilteredByFirstHierarchy,
  productHierarchyTreeExpanded,
  setProductHierarchyTreeExpanded,
  productHierarchyTreeChecked,
  setProductHierarchyTreeChecked,
  skus,
  updateState
}) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!_.isEmpty(allProducts)) {
      setIsLoading(false);
    }
  }, [allProducts]);
  const getFirstProductHierarchy = () => {
    return _.head(productHierarchy);
  };
  const getSkusOfSelectedProducts = (selectedProducts: any) => {
    return _.map(selectedProducts, product => {
      return _.get(product, 'sku', '');
    });
  };
  const handlePickProductByHierarchyModalClose = (type: string) => {
    const arrayOfProducts = _.map(
      productHierarchyTreeChecked,
      currentHierarchy => {
        return getProductOptionsByAbsolutePath(currentHierarchy);
      }
    );
    const mergedProducts = _.flatten(arrayOfProducts);
    skus = {
      sku: {
        fieldValue: getSkusOfSelectedProducts(mergedProducts),
        version: skus.version
      }
    };
    updateState(1, skus);
    setSelectedProductsByPath(mergedProducts);
    setIsAddCollectionOpen(false);
  };

  function getAllFirstHierarchyProductOptions() {
    const firstHierarchy: any = getFirstProductHierarchy();
    const firstLevelUnionProducts = _.unionBy(
      _.map(allProducts, v => v.extendedData),
      firstHierarchy
    );
    return _.map(firstLevelUnionProducts, v => {
      return {
        label: _.get(v, firstHierarchy, ''),
        value: _.get(v, firstHierarchy, '')
      };
    });
  }
  function getDefaultOptions() {
    return [
      {
        label: 'Select All',
        value: 'Select All'
      },
      {
        label: 'Clear All',
        value: 'Clear All'
      }
    ];
  }
  function isOptionValueAvailable(options: any, valueType: string) {
    return !_.isEmpty(_.find(options, { value: valueType }));
  }
  function getAllLastHierarchyProductOptionsByAbsolutePath(
    absolutePath: string
  ) {
    const checkedProductHierarchy = _.take(
      productHierarchy,
      productHierarchy.length - 1
    );
    const lastHierarchy: any = _.last(productHierarchy);
    const mapHierarchyWithAbsolutePath = _.map(
      checkedProductHierarchy,
      (v: string, i: number) => {
        return { [v]: _.split(absolutePath, '/')[i] };
      }
    );
    const filterConditionObj = _.reduce(
      mapHierarchyWithAbsolutePath,
      function(acc, current) {
        return _.assign(acc, current);
      },
      {}
    );
    const filteredProductsByAbsolutePath = _.filter(
      _.map(allProducts, v => v.extendedData),
      filterConditionObj
    );
    return _.map(filteredProductsByAbsolutePath, v => {
      return {
        label: _.get(v, lastHierarchy, ''),
        sku: v.sku,
        value: _.get(v, lastHierarchy, '')
      };
    });
  }
  function getProductOptionsByAbsolutePath(current: string) {
    return getAllLastHierarchyProductOptionsByAbsolutePath(current);
  }
  const setRecursiveHierarchyNodes: any = (
    hierarchyLevel: number,
    products: any,
    path: string
  ) => {
    if (hierarchyLevel === productHierarchy.length - 1) {
      return null;
    } else {
      return _.map(
        _.groupBy(products, `extendedData.${productHierarchy[hierarchyLevel]}`),
        (groupedProducts, node) => {
          const splitBy =
            hierarchyLevel === productHierarchy.length - 2 ? '' : '/';
          const absolutePath = path.concat(node, splitBy);
          return {
            label: node,
            value: absolutePath,
            children: setRecursiveHierarchyNodes(
              hierarchyLevel + 1,
              groupedProducts,
              absolutePath
            )
          };
        }
      );
    }
  };
  const onCheckedTreeNode = (checked: any) => {
    setProductHierarchyTreeChecked(checked);
  };
  const onExpandedTreeNode = (expanded: any) => {
    setProductHierarchyTreeExpanded(expanded);
  };
  function getProductsByHierarchyNodes() {
    const filteredProducts = _.filter(allProducts, p => {
      return !_.isEmpty(
        _.find(productsFilteredByFirstHierarchy, {
          value: _.get(p, `extendedData.${getFirstProductHierarchy()}`, '')
        })
      );
    });
    return setRecursiveHierarchyNodes(0, filteredProducts, '');
  }
  const onChangeFirstHierarchySelect = (currentSelected: any) => {
    if (isOptionValueAvailable(currentSelected, 'Select All')) {
      currentSelected = getAllFirstHierarchyProductOptions();
    } else if (
      _.isEmpty(currentSelected) ||
      isOptionValueAvailable(currentSelected, 'Clear All')
    ) {
      setProductHierarchyTreeChecked([]);
      setSelectedProductsByPath([]);
      setProductHierarchyTreeExpanded([]);
      currentSelected = [];
    }
    setProductsFilteredByFirstHierarchy(currentSelected);
  };

  function getUnionProductsFilteredByFirstHierarchy() {
    return _.concat(getDefaultOptions(), getAllFirstHierarchyProductOptions());
  }
  const getLoader = () => {
    return (
      <div
        style={{
          color: '#F2755F',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress color="inherit" />
      </div>
    );
  };
  const getProductHierarchyContent = () => {
    return (
      <>
        <div className={'popup-header-section'}>
          <h2 className={'mail-header'}>{'Add your collections'}</h2>
          <img
            className={'popup-close-section'}
            src={cancelIcon}
            alt={'close'}
            onClick={() =>
              handlePickProductByHierarchyModalClose(PRODUCT_SELECTION_CLOSE)
            }
          />
        </div>
        <div className={'product-hierarchy-selection-container'}>
          <div
            className={'product-hierarchy-tree-container'}
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <p
              className={'product-hierarchy-main-header'}
            >{`${getFirstProductHierarchy()}`}</p>
            <div>
              <div className={'px-5 py-2 product-select'}>
                <SelectWithAutoComplete
                  options={getUnionProductsFilteredByFirstHierarchy()}
                  values={productsFilteredByFirstHierarchy}
                  onChange={v => onChangeFirstHierarchySelect(v)}
                  label={getFirstProductHierarchy()}
                  size={'auto'}
                />
              </div>
            </div>
            <div className={'product-hierarchy-tree mx-4 px-4'}>
              <CheckboxTree
                nodes={getProductsByHierarchyNodes()}
                checked={productHierarchyTreeChecked}
                expanded={productHierarchyTreeExpanded}
                onCheck={checked => onCheckedTreeNode(checked)}
                onExpand={expanded => onExpandedTreeNode(expanded)}
                iconsClass="fa5"
                showNodeIcon={false}
                icons={{
                  check: (
                    <img
                      src={customCheckedIcon}
                      style={{ width: 12 }}
                      alt={'checkedIcon'}
                    />
                  ),
                  uncheck: (
                    <img
                      src={unchecked}
                      style={{ width: 12 }}
                      alt={'unchecked'}
                    />
                  ),
                  halfCheck: (
                    <img
                      src={customCheckedIcon}
                      alt={'halfCheckedIcon'}
                      style={{ opacity: 0.5, width: 12 }}
                    />
                  ),
                  expandClose: (
                    <img src={addIcon} style={{ width: 12 }} alt={'addIcon'} />
                  ),
                  expandOpen: (
                    <img
                      src={minimizeIcon}
                      style={{ width: 12 }}
                      alt={'minimizeIcon'}
                    />
                  )
                }}
              />
            </div>
          </div>
        </div>
        <div className={'text-center m-1'}>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              handlePickProductByHierarchyModalClose(PRODUCT_SELECTION_DONE)
            }
            style={{ padding: '.5rem 3rem' }}
          >
            {'done'}
          </Button>
        </div>
      </>
    );
  };
  return (
    <div className={'choose-products-hierarchy-modal-container text-center'}>
      {isLoading ? getLoader() : getProductHierarchyContent()}
    </div>
  );
};

export { ProductsHierarchyModal };
