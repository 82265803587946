export abstract class WithClassName {
  public readonly className: string;

  // taking in className in constructor as `this.constructor['name']` is lost to mangling during production build
  constructor(className: string) {
    this.className = className;
    if (className === undefined) {
      throw new Error('className from derived class constructor');
    }
  }
}
