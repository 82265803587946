import React from 'react';
import { Typography } from '@material-ui/core';
import classes from './style.module.scss';

interface HeadingProps {
  title: string;
  titleWeight?: string;
  subtitle?: string;
  styles?: {};
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

export const Heading: React.FC<HeadingProps> = ({
  title,
  subtitle,
  styles,
  titleWeight = 'normal',
  onClick = () => {}
}) => {
  return (
    <div
      style={{
        ...styles
      }}
      onClick={onClick}
    >
      <Typography
        variant="h6"
        className={classes.heading}
        style={{ fontWeight: titleWeight }}
      >
        {title}
      </Typography>
      <p>{subtitle}</p>
    </div>
  );
};
