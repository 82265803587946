import * as React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { IconButton } from '@material-ui/core';
import { styled } from '@material-ui/styles';

const StyledAppTextField = styled(TextField)({
  '& .MuiInputLabel-outlined': {
    // color: '#A3A3A3 !important',
    marginRight: 15
  },
  '& .MuiFormLabel-filled': {
    // color: '#f3755f !important',
    fontSize: '16px !important'
  },
  '& .MuiOutlinedInput-input': {
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 100px #fff inset'
      // WebkitTextFillColor: '#A3A3A3'
    }
    // color: '#464343'
  },
  '& input::placeholder': {
    fontSize: '13px'
  },
  '& .MuiInputBase-input': {
    padding: '15.5px 14px'
  }
});

export const TextFieldComponent = props => {
  const {
    id,
    label,
    name,
    onChange,
    className,
    value,
    type,
    error,
    showEyeIcon,
    accept,
    variant,
    style,
    placeholder,
    disabled
  } = props;

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <StyledAppTextField
      id={id}
      type={showEyeIcon ? (values.showPassword ? 'text' : 'password') : type}
      onKeyDown={e =>
        type === 'number' &&
        ['e', 'E', '+', '-'].includes(e.key) &&
        e.preventDefault()
      }
      label={label}
      variant={variant ? variant : 'outlined'}
      value={value}
      fullWidth
      name={name}
      onChange={onChange}
      accept={accept}
      style={style}
      className={className}
      error={error && error !== ''}
      disabled={disabled}
      placeholder={placeholder}
      helperText={error}
      InputProps={{
        endAdornment: showEyeIcon && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};
