import _ from 'lodash';

export const getProductDetailsById = (id: string, allProducts: any) => {
  return _.find(allProducts, { id: id });
};
export const getProductHierarchyFromProductData = (
  selectedProduct: any,
  productHierarchy: any
) => {
  return _.transform(productHierarchy, (result: any, value: any) => {
    const hierarchyJson = {
      [value]: _.get(selectedProduct, `extendedData.${value}`, '')
    };
    result.push(hierarchyJson);
  });
};
export const getHierarchyAsArray = (productHierarchy: any) => {
  return _.transform(productHierarchy, (result: any, value: any) => {
    result.push(_.head(_.values(value)));
  });
};
export const getAbsolutePath = (
  product: any,
  allProducts: any,
  productHierarchy: any
) => {
  const selectedProducts = getProductDetailsById(product.id, allProducts);
  const hierarchyObject = getProductHierarchyFromProductData(
    selectedProducts,
    productHierarchy
  );
  return getHierarchyAsArray(hierarchyObject).join('/');
};
