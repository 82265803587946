import { TableFooter, TablePagination, TableRow } from '@material-ui/core';
import React, { FC } from 'react';

interface CampaignReportTableFooterProps {
  count: number;
  page: number;
  rowsPerPage: number;
  colSpan: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (row: number) => void;
}

const CampaignReportTableFooter: FC<CampaignReportTableFooterProps> = ({
  count,
  page,
  rowsPerPage,
  colSpan,
  onPageChange,
  onRowsPerPageChange
}) => {
  const handlePageChange = (_: unknown, newPage: number): void => {
    onPageChange(newPage);
  };

  const handleRowsPerPageChange = ({
    target
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    onRowsPerPageChange(+target.value);
    onPageChange(0);
  };

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          colSpan={colSpan}
          rowsPerPageOptions={[25, 50, 100]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </TableRow>
    </TableFooter>
  );
};
export default CampaignReportTableFooter;
