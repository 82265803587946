import * as React from 'react';

import TextField from '@material-ui/core/TextField';
import { Heading } from '../../shared/Heading';
import classes from './EditableHeader.module.scss';
import { Tooltip } from '@material-ui/core';
import { isEmpty } from 'lodash';
import EditIcon from '@material-ui/icons/Edit';

type Props = {
  content: string;
  handleChange: (e: string) => void;
};

export const EditableHeader: React.FC<Props> = ({ content, handleChange }) => {
  const [value, setValue] = React.useState(content);
  const [isFocused, setIsFocused] = React.useState(false);

  return (
    <div className={classes.container}>
      {!isFocused ? (
        <Tooltip title="Click to Edit" placement="right-end">
          <div className={classes.containerDefault}>
            <Heading title={content} />
            <EditIcon
              onClick={() => setIsFocused(true)}
              style={{
                color: '#F26E5F'
              }}
            />
          </div>
        </Tooltip>
      ) : (
        <TextField
          autoFocus
          value={value}
          onChange={event => {
            if (isEmpty(event.target.value))
              return alert("Don't leave it empty");

            setValue(event.target.value);
          }}
          onBlur={() =>
            setIsFocused(() => {
              handleChange(value);
              return false;
            })
          }
          className={classes.input}
        />
      )}
    </div>
  );
};
