/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import classes from './TrayItem.module.css';

export const TrayItem = ({ name, model, IconComponent, enabled }) => {
  const onDragStartHandler = event => {
    event.dataTransfer.setData('transfer-to-node', JSON.stringify(model));
  };

  return (
    <div
      className={classes.container}
      draggable={true}
      onDragStart={onDragStartHandler}
      style={
        enabled
          ? {}
          : {
              pointerEvents: 'none',
              opacity: '0.4'
            }
      }
    >
      <IconComponent />
      <div className={classes.itemText}>{name}</div>
    </div>
  );
};
