const LABELPRINTING = 'LabelPrinting';
const LABELPRINTING_DATA = 'LabelPrintingData';
const LABELPRINTING_ERROR = 'LabelPrintingError';
const LABELPRINTING_WARNING = 'LabelPrintingWarning';
const LABELPRINTING_EDIT = 'LabelPrintingDataEdit';
const AVAILABLE_BATCH_COUNT = [15, 25, 50, 125, 250, 500];
export {
  LABELPRINTING,
  LABELPRINTING_DATA,
  LABELPRINTING_ERROR,
  LABELPRINTING_WARNING,
  AVAILABLE_BATCH_COUNT,
  LABELPRINTING_EDIT
};
