import React from 'react';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import PropTypes from 'prop-types';
import Connector from './StepperConnector';
import StepIcon from './StepperIcon';
import './index.css';

export default function OfferDefinitionStepper(props) {
  const steps = props.steps || ['Description', 'Definition'];
  const { activeStep } = props;
  return (
    <div className="stepper" style={props.style || {}}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<Connector />}
      >
        {steps.map((stepLabel, index) => (
          <Step key={stepLabel}>
            <StepLabel StepIconComponent={StepIcon}>
              <div
                className={
                  activeStep === index ? 'selectedStepLabel' : 'stepLabel'
                }
              >
                {stepLabel}
              </div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

OfferDefinitionStepper.propTypes = {
  activeStep: PropTypes.number.isRequired
};
