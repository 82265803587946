import React from 'react';
import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import RupeeFit from '../../assets/rupeeFit.png';
import { GetBarCode } from '../utils';
import Disclimer from '../../assets/disclimer.png';
import moment from 'moment';
import Warranty from './warranty';
import {
  getSplitDescription,
  truncatedNumberFloat,
  truncateString
} from '../../../../utils/index';
import _ from 'lodash';

const styles = StyleSheet.create({
  card: {
    fontFamily: 'OpenSans',
    margin: '3px',
    height: '145.3mm',
    width: '206.2mm',
    border: '1 solid #000000',
    marginBottom: '2mm',
    marginLeft: '2mm'
  },
  qrSection: {
    height: '185px',
    width: '583px',
    borderBottom: '1 solid #000000',
    display: 'flex',
    flexDirection: 'row'
  },
  descriptionSection: {
    height: 134.48,
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid #000000'
  },
  amountSection: {
    height: '134px',
    width: '583px',
    borderBottom: '1 solid #000000',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  qrcodeBlock: {
    width: '177px',
    height: '185px',
    borderRight: '1 solid #000000'
  },
  qrcode: {
    width: '162px',
    height: '162px',
    margin: '4mm 2mm'
  },
  about: {
    // borderLeft: '1 solid #000000',
    fontSize: '14pt',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    width: '408px',
    height: '185px',
    wordWrap: 'break-word'
  },
  pricewithRupeeIcon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '362px',
    height: '134px',
    borderRight: '1 solid #000000'
  },
  rupeeicon: {
    fontSize: '30pt',
    width: '7mm',
    paddingTop: '8mm'
  },
  price: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold',
    fontSize: '90pt',
    // textAlign: 'left',
    color: 'black',
    // paddingLeft: '10mm',
    top: -8,
    display: 'flex',
    flexDirection: 'column'
    // lineHeight: 1
  },
  rupeeSmall: {
    width: '5.8mm',
    height: '7mm',
    top: 7.88
  },
  warrantyWithBarcode: {
    width: '100%',
    marginLeft: '3mm',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  warranty: {
    height: '18.7mm',
    width: '18.7mm',
    margin: '1mm',
    marginRight: 10,
    marginLeft: 0
  },
  warrantyDuriation: {
    position: 'absolute',
    height: '10.5mm',
    width: '10.5mm',
    fontWeight: 'bold',
    margin: '4mm',
    backgroundColor: '#fff',
    borderRadius: '50',
    alignItems: 'center',
    justifyContent: 'center'
  },
  warrantyValue: { fontSize: '20.12pt', left: 0, top: 1 },
  warrantyYear: { fontSize: '5.64pt', top: -5, left: 1 },
  warrantyText: {
    fontSize: '9.64pt',
    color: '#1A1818',
    opacity: '1'
  },

  footer: {
    padding: '2mm 1mm'
  },
  dateDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 9,
    marginTop: -2
  },
  itemCode: {
    top: -15,
    paddingTop: '10mm',
    fontSize: '8pt',
    opacity: '1',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  barcodeBlock: {
    // width: '190mm',
    textAlign: 'right',
    justifyContent: 'center',
    alignItems: 'right',
    paddingBottom: '8mm',
    paddingRight: '5mm',
    top: 10
  },
  barcode: {
    top: 2,
    width: '45mm'
    // padding: '1mm 1mm 0mm 0mm'
  },
  lineHeight: { paddingLeft: '4mm', height: '10mm' },
  disclimer: {
    width: '3.75mm',
    height: '3.75mm',
    left: 4
  },
  inclTax: {
    fontSize: '19.6pt',
    fontWeight: 'bold',
    marginRight: '1mm',
    display: 'flex',
    flexDirection: 'row'
  },
  deals: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  mopSection: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1
  },
  savetext: {
    width: '220px',
    height: '35px',
    backgroundColor: '#818386',
    borderBottom: '1 solid #000000'
  },
  saveblock: {
    width: '220px',
    height: '135px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderLeft: '1 solid #000000'
  },
  saveprice: {
    width: '220px',
    height: '100px',
    backgroundColor: '#1A1818',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  name: {
    width: '406px',
    height: '116px',
    borderBottom: '1 solid #000000',
    padding: '4mm'
  }
});

const A5LandscapeH1 = ({ title = '', data, count, ...rest }) => {
  return (
    <Page size="A4" orientation="portrait">
      {count &&
        count.map(id =>
          data?.map(item => (
            <View style={styles.card} key={item?.id}>
              <View style={styles.qrSection}>
                <View style={styles?.qrcodeBlock}>
                  <Image style={styles.qrcode} src={item.image} />
                </View>
                <View style={styles.about}>
                  <View style={styles.name}>
                    <Text>
                      {truncateString(item?.product?.masterData?.name, 130)}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '408px',
                      fontSize: '10pt',
                      lineHeight: 1.6,
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <View
                      style={{
                        width: '184px',
                        paddingTop: '3mm',
                        paddingLeft: '2mm'
                      }}
                    >
                      {getSplitDescription(item.description, 3).map(
                        (line, index) => (
                          <Text key={index}>+ {truncateString(line, 30)}</Text>
                        )
                      )}
                    </View>

                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '26pt',
                        width: '223px',
                        height: '69px',
                        borderLeft: '1 solid #000000'
                      }}
                    >
                      <View style={styles.deals}></View>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.amountSection}>
                <View style={styles.pricewithRupeeIcon}>
                  <View style={styles.rupeeicon}>
                    <Image src={RupeeFit} />
                  </View>
                  <View style={styles.mopSection}>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <Text style={styles.price}>
                        {truncatedNumberFloat(item?.product?.mop, 6)}
                      </Text>
                      <Text
                        style={{
                          fontSize: '14.8pt',
                          top: 20,
                          fontWeight: 'bold'
                        }}
                      >
                        *
                      </Text>
                    </View>
                    <View style={{ alignItems: 'flex-end', display: 'flex' }}>
                      <View style={styles.inclTax}>
                        <Text
                          style={{
                            fontSize: '14.8pt',
                            top: 4,
                            fontWeight: 'bold'
                          }}
                        >
                          *
                        </Text>
                        <Text style={{ fontSize: '27pt' }}>(Incl. Taxes)</Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={styles.saveblock}>
                  <View style={styles.savetext}></View>
                  <View style={styles.saveprice}></View>
                </View>
              </View>
              <View style={styles?.footer}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <View style={styles.warrantyWithBarcode}>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <Warranty warranty={item.warranty} styles={styles} />
                    </View>
                    <View style={styles.barcodeBlock}>
                      <View style={styles.barcode}>
                        {_.isEmpty(item.barcode) ? (
                          <></>
                        ) : (
                          <Image src={GetBarCode(item?.barcode)} />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
                <View>
                  <View
                    style={
                      item?.warranty
                        ? styles?.dateDetails
                        : { ...styles?.dateDetails, top: 0 }
                    }
                  >
                    <View style={styles.itemCode}>
                      <Text>
                        {`${item?.sku}  |  ${item.class}  |  A  | ${moment(
                          item?.date
                        ).format('DD.MM.YYYY')}  |  ${moment().format(
                          'DD.MM.YYYY'
                        )}`}
                      </Text>
                      <View>
                        <Image style={styles.disclimer} src={Disclimer} />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          ))
        )}
      {/* {title} */}
    </Page>
  );
};
export { A5LandscapeH1 };
