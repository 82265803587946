import React from 'react';

class ShortText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFullText: false
    };
  }

  render() {
    const { text } = this.props;
    const { showFullText } = this.state;
    const shortText =
      !showFullText && text.length > 200 ? text.substr(0, 200) : text;

    return (
      <span>
        {shortText}
        {!showFullText && text.length > 200 && (
          <span
            style={{ color: 'blue' }}
            onClick={() => this.setState({ showFullText: true })}
          >
            &ensp;...show more
          </span>
        )}
      </span>
    );
  }
}

export default ShortText;
