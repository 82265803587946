import config from '../config';

const getBodyPayload = (id, type, offset, count, remove) => {
  return {
    payloadId: id,
    type: type,
    offset: offset,
    count: count,
    remove: remove
  };
};
export function getUiFilterPayloadById(id, type, offset, count, remove) {
  return fetch(`${config.ruleServerUrl}/users/ui-filters/label-printing`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify(getBodyPayload(id, type, offset, count, remove))
  }).then(r => r.json());
}
