/* eslint-disable @typescript-eslint/explicit-function-return-type */
import DateFnsUtils from '@date-io/date-fns';
import { Button } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { get } from 'lodash';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';

import useCurrentUser from '../../../hooks/useCurrentUser';
import { ReportTypes } from '../../../models/TenantReports';
import { getTransactionWiseReport } from '../../../services/TenantReports/TransactionwiseReport.service';
import {
  AdditionalField,
  AdditionalFieldSelector
} from '../Shared/AdditionalFields/AdditionalFields';

interface BillReport {
  startDate: string;
  endDate: string;
  businessId?: number;
  additionalFields?: AdditionalField[];
}

const billInitialState: BillReport = {
  startDate: moment()
    .subtract(1, 'month')
    .subtract(1, 'day')
    .format('YYYY-MM-DD'),
  endDate: moment()
    .subtract(1, 'day')
    .format('YYYY-MM-DD'),
  businessId: undefined
};

interface ReportActionsProps {
  setRefreshView: (id: string) => void;
}

const TransactionReport: FC<ReportActionsProps> = ({ setRefreshView }) => {
  const [filters, setFilters] = useState<BillReport>(billInitialState);
  const user = useCurrentUser();

  const handleDateChange = (date: any, name: string) => {
    setFilters({ ...filters, [name]: date });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsBuId = +urlParams.get('bu')! || 0;

    setFilters(filters => ({ ...filters, businessId: paramsBuId }));
  }, [user]);

  const handleGenerateReport = (): void => {
    getTransactionWiseReport(filters).then((res: any) => {
      const jobId = get(res, 'jobId', '');
      setRefreshView(jobId);
    });
  };
  const maxDate = moment(new Date())
    .subtract('days', 1)
    .toDate();

  const updateFilters = (additionalFields: AdditionalField[]) =>
    setFilters({ ...filters, additionalFields });

  const currentDate = moment(new Date()).toDate();
  return (
    <div
      style={{
        margin: '30px 0',
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            label="Date"
            maxDate={maxDate}
            value={filters.startDate}
            onChange={e => handleDateChange(e, 'startDate')}
            variant="inline"
            format="yyyy-MM-dd"
          />
          <KeyboardDatePicker
            label="End Date"
            maxDate={currentDate}
            value={filters.endDate}
            onChange={e => handleDateChange(e, 'endDate')}
            variant="inline"
            format="yyyy-MM-dd"
            style={{ marginLeft: '20px' }}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '1rem',
          marginLeft: 'auto'
        }}
      >
        <AdditionalFieldSelector
          reportType={ReportTypes.TRANSACTION}
          businessId={filters.businessId}
          onChange={updateFilters}
        />
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleGenerateReport}
        >
          Generate Report
        </Button>
      </div>
    </div>
  );
};

export default TransactionReport;
