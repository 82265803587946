const shadowMaxColor = `rgba(179, 179, 179, 50%)`;
const shadowMaxColorOther = `rgba(14, 13, 13, 50%)`;
const shadowTopLeftMaxColor = `rgba(180, 180, 180, 0.5)`;
const shadowMinColor = `rgba(230, 230, 230, 0)`;
const leftPageSlantGradient = `linear-gradient(-60deg, ${shadowMinColor} 90%, ${shadowTopLeftMaxColor} 100%)`;
// const leftPageMidGradient = `linear-gradient(-90deg, ${shadowMaxColor} 0%, ${shadowMinColor} 3%)`
const leftPageMidGradient = `linear-gradient(to left, rgba(64, 64, 64, 0.5) 0%, rgba(250, 250, 250, 0.05) 2.3%, rgba(255, 255, 255, 0) 2.8%, rgba(250, 250, 250, 0.05) 3.4%, rgba(135, 135, 135, 0.1) 5.4%, rgba(200, 200, 200, 0) 10%)`;
// const rightPageMidGradient = `linear-gradient(90deg, ${shadowMaxColor} 0%, ${shadowMinColor} 3%)`
const rightPageMidGradient = `linear-gradient(to right, ${shadowMaxColor} 0%, ${shadowMinColor} 20%)`;
const coverPageRightGradient = `linear-gradient(to right, ${shadowMaxColorOther} 0%, ${shadowMinColor} 2%)`;
const leftPageGradient = [leftPageSlantGradient, leftPageMidGradient].join(
  ', '
);
const rightPageGradient = [rightPageMidGradient].join(', ');
const oddBackgroundImageURL = (backgroundImageURL: any) => {
  return typeof backgroundImageURL === 'string'
    ? backgroundImageURL
    : backgroundImageURL?.odd;
};

const evenBackgroundImageURL = (backgroundImageURL: any) => {
  return typeof backgroundImageURL === 'string'
    ? backgroundImageURL
    : backgroundImageURL?.even;
};

const getBackgroundForPage = (page: number, backgroundImageURL: any) =>
  page % 2 === 0
    ? evenBackgroundImageURL(backgroundImageURL)
    : oddBackgroundImageURL(backgroundImageURL);

const isMobileView = () => {
  return window.screen.width < 600;
};

const getGradientForPage = (page: number) => {
  if (page === 1 || isMobileView()) {
    return coverPageRightGradient;
  } else {
    return page % 2 === 0 ? leftPageGradient : rightPageGradient;
  }
};

export default { getBackgroundForPage, getGradientForPage, isMobileView };
