import {
  Box,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import DoubleArrow from '@material-ui/icons/Height';
import React, { FC, useContext, useState } from 'react';
import { toast } from 'react-toastify';

import {
  getCampaignDefinition,
  getCampaignDetail,
  getCampaignSmsStatus
} from '../../../../../../services/Campaign/campaign-details.service';
import { generateReport } from '../../../../../../services/Campaign/campaign-report.service';
import useFetch from '../../../../../../shared/hooks/useFetch';
import useMutation from '../../../../../../shared/hooks/useMutation';
import CampaignCard from '../../../../shared/components/CampaignCard/CampaignCard';
import LoadingButton from '../../../../shared/components/LoadingButton/LoadingButton';
import calculateRoi from '../../../../shared/utils/calculateRoi';
import formatNumber from '../../../../shared/utils/formatNumber';
import CalculateRoiForm from '../CalculateRoiForm/CalculateRoiForm';
import styles from './CampaignDetail.module.scss';
import { get, isEmpty, keys, map } from 'lodash';
import { CampaignReportType } from '../../../../shared/utils/contants';
import { CampaignFilterContext } from '../../Context/CampaignFilterContext';
import CampaignVariantModal from '../../CampaignVariantModal/CampaignVariantModal';
import { Skeleton } from '@material-ui/lab';

interface CampaignDetailProps {
  campaignId: string;
  reportType: string;
  setReportType: (reportType: any) => void;
  setMessageCost: (messageCost: number) => void;
  messageCost: number;
}

const CampaignDetail: FC<CampaignDetailProps> = ({
  campaignId,
  reportType,
  setReportType,
  setMessageCost,
  messageCost
}) => {
  const [returnOfInvestment, setReturnOfInvestment] = useState(0);
  const campaignFilterContext = useContext(CampaignFilterContext);
  const { data } = useFetch(() =>
    getCampaignDetail(campaignId, campaignFilterContext.buId.toString())
  );
  /*const data = {
    variantSurveys: [
      {
        count: '2',
        survey_id: 40,
        campaign_variant_id: 'bd5dc7ff-91c5-49af-93e4-818693757510',
        tag: 'A',
        campaign_form_name: 'Feedback Form'
      },
      {
        count: '145',
        survey_id: 40,
        campaign_variant_id: 'bd5dc7ff-91c5-49af-93e4-818693757510',
        tag: 'B',
        campaign_form_name: 'Feedback Form'
      }
    ],
    targeted: 0,
    revenue: 0,
    converted: 0
  };*/
  const { data: campaign } = useFetch(() => getCampaignDefinition(campaignId));
  const { data: smsStatus } = useFetch(() => getCampaignSmsStatus(campaignId));
  const { mutate: onGenerateReport, isLoading: isGenerating } = useMutation(
    generateReport.bind(
      null,
      campaignId,
      campaignFilterContext.buId.toString(),
      reportType,
      messageCost
    )
  );
  const { targeted = 0, converted = 0, revenue = 0 } = data || {};
  const [openVariantModel, setOpenVariantModel] = useState(false);

  const handleCalculateRoi = (messageCost: number): void => {
    setReturnOfInvestment(calculateRoi({ messageCost, revenue, targeted }));
  };

  const handleChangeReportType = (reportType: any) => {
    setReportType(reportType);
  };
  const handleGenerateReport = (): void => {
    onGenerateReport()
      .then(() =>
        toast.success('Your report generation is started successfully.')
      )
      .catch(() => toast.error('Something went wrong.'));
  };

  const getOptions = () => {
    return {
      default: CampaignReportType.DEFAULT,
      variant: CampaignReportType.VARIANT,
      variantTier: CampaignReportType.VARIANT_TIER,
      variantRfm: CampaignReportType.VARIANT_RFM,
      campaignTier: CampaignReportType.CAMPAIGN_TIER,
      campaignRfm: CampaignReportType.CAMPAIGN_RFM
    };
  };

  const smsSentPercentage =
    ((smsStatus?.sentCount || 0) / (smsStatus?.targetCount || 100)) * 100 || 0;

  const campaignDetails = [
    {
      label: 'Campaign live status',
      value: (
        <span
          className={styles.progressContainer}
          title={`${smsSentPercentage}%`}
        >
          <span
            className={styles.progress}
            style={{
              width: `${smsSentPercentage}%`
            }}
          ></span>
        </span>
      )
    },
    {
      label: 'No of people targeted',
      value: formatNumber(targeted)
    },
    {
      label: 'No of people converted',
      value: formatNumber(converted)
    },
    {
      label: 'Conversion percentage',
      value: `${+formatNumber((converted / targeted) * 100) || 0}%`
    },
    { label: 'Campaign revenue', value: formatNumber(revenue) }
  ];

  const variantModal = (): JSX.Element => {
    return (
      <CampaignVariantModal
        open={openVariantModel}
        setOpen={setOpenVariantModel}
        campaignId={campaignId}
      />
    );
  };

  const isDisabled = (key: string) => {
    const disableOptionAvailable = {
      variantTier: CampaignReportType.VARIANT_TIER,
      variantRfm: CampaignReportType.VARIANT_RFM,
      campaignTier: CampaignReportType.CAMPAIGN_TIER,
      campaignRfm: CampaignReportType.CAMPAIGN_RFM
    };
    return (
      keys(disableOptionAvailable).includes(key) &&
      !get(campaign, 'trackConversion', true)
    );
  };

  const getSurveyLoaderComponent = () => {
    return (
      <TableContainer
        component={Paper}
        elevation={0}
        className={styles.surveyDetailContainer}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Skeleton width={60} height={20} />
              </TableCell>
              <TableCell>
                <Skeleton width={60} height={20} />
              </TableCell>
              <TableCell>
                <Skeleton width={60} height={20} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Skeleton width={40} height={20} />
              </TableCell>
              <TableCell>
                <Skeleton width={40} height={20} />
              </TableCell>
              <TableCell>
                <Skeleton width={40} height={20} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton width={40} height={20} />
              </TableCell>
              <TableCell>
                <Skeleton width={40} height={20} />
              </TableCell>
              <TableCell>
                <Skeleton width={40} height={20} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  const getSurveyComponent = () => {
    if (data === null) {
      return getSurveyLoaderComponent();
    }
    if (isEmpty(data.variantSurveys)) {
      return <></>;
    }
    return (
      <TableContainer
        component={Paper}
        elevation={0}
        className={styles.surveyDetailContainer}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography className={styles.surveyDetailHeader}>
                  {'Variant Tag'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.surveyDetailHeader}>
                  {'Survey Name'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={styles.surveyDetailHeader}>
                  {'Responded'}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.variantSurveys.map(vs => (
                <TableRow>
                  <TableCell>
                    <span
                      style={{
                        fontWeight: '800 !important',
                        fontSize: '18px',
                        color: '#767676'
                      }}
                    >
                      {vs['tag']}
                    </span>
                  </TableCell>
                  <TableCell>
                    <Typography>{vs['campaign_form_name']}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{vs['count']}</Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box>
      <Box id={styles.campaignDetailContainer}>
        <Box className={styles.campaignTableRowCards}>
          <Box className={styles.campaignDetail}>
            {campaignDetails.map(campaign => (
              <CampaignCard
                key={campaign.label}
                {...campaign}
                valueTypographyVariant="h6"
              />
            ))}
            {targeted ? (
              <Link
                className={styles.link}
                onClick={(): void => setOpenVariantModel(true)}
              >
                Click here to view in detail
              </Link>
            ) : (
              <div />
            )}
          </Box>
          <Box className={styles.calculateRoiForm}>
            <CalculateRoiForm
              onCalculate={handleCalculateRoi}
              setMessageCost={setMessageCost}
            />
          </Box>
          <Box className={styles.campaignDetailIcon}>
            <DoubleArrow color="disabled" className={styles.doubleArrowIcon} />
          </Box>
          <CampaignCard
            label="Return of Investment"
            value={formatNumber(returnOfInvestment)}
            valueTypographyVariant="h5"
          />
        </Box>
        {getSurveyComponent()}
        {/*<Box className={styles.surveyDetailContainer}>
          <Box className={styles.surveyDetails}>
            <Box
              className={`${styles.surveyDetailRow} ${styles.surveyDetailHeader}`}
            >
              <Typography>{'Variant Tag'}</Typography>
              <Typography>{'Survey Name'}</Typography>
              <Typography>{'Responded'}</Typography>
            </Box>
            {data &&
              data.variantSurveys.map(vs => (
                <Box className={styles.surveyDetailRow}>
                  <Typography>{vs['tag']}</Typography>
                  <Typography>{vs['campaign_form_name']}</Typography>
                  <Typography>{vs['count']}</Typography>
                </Box>
              ))}
          </Box>
        </Box>*/}
        <Box className={styles.ctaContainer}>
          <LoadingButton
            className={styles.reportGenerateBtn}
            variant="contained"
            color="primary"
            size="small"
            disableElevation
            loading={isGenerating}
            onClick={handleGenerateReport}
          >
            Generate Report
          </LoadingButton>
          <Select
            labelId="select-campaign-report-type-label"
            id="select-campaign-report-type"
            value={reportType}
            label="Report Type"
            onChange={e => handleChangeReportType(e.target.value)}
          >
            {map(getOptions(), (value, key) => {
              return (
                <MenuItem
                  value={value}
                  key={key}
                  disabled={isDisabled(key)}
                  style={{ textTransform: 'capitalize' }}
                >
                  {value.replace('_', ' ')}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </Box>
      {variantModal()}
    </Box>
  );
};
export default CampaignDetail;
