import React, { useContext, FunctionComponent } from 'react';
import { ReadOnlyContext } from '../../OfferDetail/ReadOnlyContext';
import { Button, createTheme, MuiThemeProvider } from '@material-ui/core';
import { JoinBucket } from '../../../models/offer/OfferRule.model';
import renderers from './renderer';

const theme = createTheme({
  palette: {
    primary: {
      light: '#F58974',
      main: '#F58974',
      dark: '#F58974',
      contrastText: '#FFF'
    }
  },
  overrides: {
    MuiSelect: {
      root: {
        '&:focus': {
          color: 'white',
          backgroundColor: '#F58974'
        },
        textAlign: 'center',
        font: '13px Nunito',
        letterSpacing: 0,
        opacity: 1,
        color: 'white',
        backgroundColor: '#F58974',
        padding: 10,
        borderRadius: 5
      },
      icon: {
        color: '#fff',
        marginRight: 5
      },
      select: {
        '&:focus': {
          color: 'white',
          backgroundColor: '#F58974',
          borderRadius: 5
        },
        backgroundColor: '#F58974',
        borderRadius: 5,
        minWidth: 60
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        padding: 5,
        paddingRight: 20,
        paddingLeft: 20
      }
    }
  }
});

type BucketComponentProps = {
  bucket: JoinBucket;
  rootBucket: JoinBucket;
  setRootBucket: (root: JoinBucket) => void;
  isParent: boolean;
};

const BucketComponent: FunctionComponent<BucketComponentProps> = props => {
  const { bucket, setRootBucket, isParent, rootBucket } = props;

  const { readOnly } = useContext(ReadOnlyContext);
  // @ts-ignore
  return (
    <MuiThemeProvider theme={theme}>
      <div
        style={
          !isParent
            ? {
                padding: 20,
                borderRadius: 10,
                border: '1px solid #DEDEDE'
              }
            : {}
        }
      >
        <div>
          <Button
            color="primary"
            variant="outlined"
            style={{ marginRight: 10 }}
            onClick={() => {
              setRootBucket(rootBucket.replace(bucket.addProductBucket()));
            }}
            disabled={readOnly}
          >
            + Add Slot
          </Button>
          <Button
            color="primary"
            variant="outlined"
            // todo: enable later
            className={'d-none'}
            onClick={() => {
              setRootBucket(rootBucket.replace(bucket.addJoinBucket()));
            }}
          >
            + Add Group Of Slots
          </Button>
        </div>
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 0,
            // margin: 30,
            marginTop: 25,
            width: '92%'
          }}
        >
          {bucket.buckets.map(childBucket => {
            // todo: remove WIP
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            return renderers[childBucket.className](
              childBucket,
              setRootBucket,
              rootBucket
            );
          })}
        </div>
      </div>
    </MuiThemeProvider>
  );
};

export default BucketComponent;
