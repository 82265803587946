/* eslint-disable @typescript-eslint/explicit-function-return-type */
import config from '../config';

const getLinkForUser = data => {
  return fetch(`${config.ruleServerUrl}/rse-referrals/get-link`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json'
    }
  }).then(r => r.json());
};

async function referralFriendConfigDetails(businessUnit, data) {
  const url = new URL(
    `${config.ruleServerUrl}/users/referral/businessUnit/${businessUnit}/save-config`
  );

  try {
    const r = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({
        ...data
      }),
      headers: {
        'Content-type': 'application/json'
      }
    });
    return await r.json();
  } catch (err) {
    return {};
  }
}

const referralFriendConfig = (businessUnit, data) => {
  return referralFriendConfigDetails(businessUnit, data);
};

export default {
  getLinkForUser,
  referralFriendConfig
};
