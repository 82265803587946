import config from '../../config';

function getProductRecommendation(businessUnitId: number) {
  return fetch(
    `${config.apiServerUrl}/users/business-configs/${businessUnitId}/ProductRecommendation`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(res => {
    return res.json();
  });
}

export default {
  getProductRecommendation
}