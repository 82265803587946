import React, { FC, useContext, useEffect, useState } from 'react';
import './SidePanel.css';
import { SelectionAccordion } from './SelectionAccordion';
import _ from 'lodash';
import { TaggerImagePanelContext } from '../../Hooks/TaggerImagePanelContext';
import createSegmentIcon from '../../../Assets/images/create_segmnent_icon.svg';
import selectSegmentIcon from '../../../Assets/images/select_segment_icon.svg';
import { ActionState } from '../../../../models/FlipbookV2/TileData/TileDataImage.model';

export const TOOL_EDIT_SECTION = 'tool_edit_section';
export const TOOL_NEW_SECTION = 'tool_new_section';
export const TOOL_SAVE_SECTION = 'tool_save_section';
export const TOOL_CANCLE_SECTION = 'tool_cancle_section';
export const TOOL_DELETE_SECTION = 'tool_delete_section';

const AvailableTools = [
  { operation: TOOL_NEW_SECTION, label: 'Create Segment' },
  { operation: TOOL_EDIT_SECTION, label: 'Select Segment' }
];

interface SelectionState {
  unit: string;
  x: number;
  y: number;
  height: number;
  width: number;
  actionState: any;
  actionItem: string;
  name: any;
}
const InitialSelectionState = {
  unit: '%',
  x: 0,
  y: 0,
  height: 0,
  width: 0,
  actionState: ActionState.URL,
  actionItem: '',
  name: 'Selection 1'
};

interface SidePanelProps {
  sidePanelAction: (
    actionType: string,
    message: any,
    key: string,
    actionState: string
  ) => void;
}

export const EditorPanel: FC<SidePanelProps> = ({ sidePanelAction }) => {
  const [selectionState, setSelectionState] = useState<SelectionState>(
    InitialSelectionState || {}
  );
  const [selectedTool, setSelectedTool] = useState('');
  const [key, setKey] = useState('');
  const [isShowAccordion, setIsShowAccordion] = useState(false);
  const taggerImagePanelContext = useContext(TaggerImagePanelContext);
  const taggerImagePanelInfo = _.get(
    taggerImagePanelContext,
    'taggerImagePanelInfo',
    {}
  );

  useEffect(() => {
    const message = _.get(taggerImagePanelInfo, 'message', {});
    const actionState = _.get(taggerImagePanelInfo, 'actionState', '');
    const receivedKey = _.get(taggerImagePanelInfo, 'key', {});
    if (!_.isEmpty(message)) {
      setSelectionState(message);
      setKey(receivedKey);
    }
    if (_.isEqual(actionState, 'complete')) {
      setKey(receivedKey);
    }
  }, [taggerImagePanelInfo]);

  useEffect(() => {
    const isSelectionAvailable = () => {
      return selectionState.width !== 0 && selectionState.height !== 0;
    };

    if (isSelectionAvailable()) {
      if (
        selectedTool === TOOL_NEW_SECTION ||
        selectedTool === TOOL_EDIT_SECTION
      ) {
        setIsShowAccordion(true);
      } else {
        setIsShowAccordion(false);
      }
    } else {
      setIsShowAccordion(false);
    }
  }, [selectedTool, selectionState.height, selectionState.width]);

  const dispatchEvent = (operation: string, message: any) => {
    const actionState = 'pending';
    sidePanelAction(operation, message, key, actionState);
  };
  const toolSelection = (actionType: any) => {
    dispatchEvent(actionType, InitialSelectionState);
    if (selectedTool === actionType) {
      setSelectedTool('');
      return;
    }
    setSelectedTool(actionType);
  };
  const saveSelection = () => {
    dispatchEvent(TOOL_SAVE_SECTION, selectionState);
    setSelectionState(InitialSelectionState);
  };
  const cancelSelection = () => {
    setSelectionState(InitialSelectionState);
    setSelectedTool('');
    dispatchEvent(TOOL_CANCLE_SECTION, selectionState);
  };
  const deleteSelection = () => {
    setSelectionState(InitialSelectionState);
    setSelectedTool('');
    dispatchEvent(TOOL_DELETE_SECTION, selectionState);
  };
  return (
    <div className={'flipbook-side-panel'}>
      <div className={'flipbook-side-panel-header'}>
        <div className={'flipbook-side-panel-header-text'}>Edit Image</div>
      </div>
      <div className={'flipbook-side-panel-body'}>
        <div className={'flipbook-side-panel-tools'}>
          {_.map(AvailableTools, (tool, index) => {
            return (
              <div
                className={`side-panel-variant-btn ${
                  tool.operation === selectedTool ? 'active' : ''
                }`}
                key={index}
                onClick={() => {
                  toolSelection(tool.operation);
                }}
              >
                <div className="text-center">
                  <img
                    className={'side-panel-flipbookIcon'}
                    src={`${
                      tool.operation === TOOL_NEW_SECTION
                        ? createSegmentIcon
                        : selectSegmentIcon
                    }`}
                    alt=""
                  />
                </div>
                {tool.label}
              </div>
            );
          })}
        </div>
        {isShowAccordion && (
          <div className={'flipbook-side-panel-crop-container'}>
            <SelectionAccordion
              selectionState={selectionState}
              saveSelection={saveSelection}
              cancelSelection={cancelSelection}
              deleteSelection={deleteSelection}
              setSelectionState={setSelectionState}
              selectedTool={selectedTool}
            />
          </div>
        )}
      </div>
    </div>
  );
};
