import React from 'react';
import * as classes from './JourneyFilters.module.css';
import useFocus from '../../../customHooks/useFocus';

const searchIcon = () => (
  <svg
    width="25px"
    height="25px"
    style={{ marginLeft: '20px' }}
    viewBox="0 0 30 30"
  >
    <path
      fill="grey"
      d="M13 3C7.489 3 3 7.489 3 13s4.489 10 10 10a9.947 9.947 0 006.322-2.264l5.971 5.971a1 1 0 101.414-1.414l-5.97-5.97A9.947 9.947 0 0023 13c0-5.511-4.489-10-10-10zm0 2c4.43 0 8 3.57 8 8s-3.57 8-8 8-8-3.57-8-8 3.57-8 8-8z"
    />
  </svg>
);
export default function Search({ query, onSearchHandler }) {
  const [focusRef, setFocusRef] = useFocus();
  return (
    <div className={classes.search} onClick={setFocusRef}>
      <input
        className={classes.searchInput}
        ref={focusRef}
        type="text"
        placeholder="search"
        value={query}
        onChange={onSearchHandler}
      />
      {searchIcon()}
    </div>
  );
}
