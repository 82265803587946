import React from 'react';
import moment from 'moment';
import { Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { GetBarCode } from '../utils';
import RupeeFit from '../../assets/rupeeFit.png';
import Disclimer from '../../assets/disclimer.png';
import Warranty from './warranty';
import fonts from '../../assets/Fonts';
import {
  getSplitDescription,
  truncatedNumberFloat,
  truncateString
} from '../../../../utils';
import _ from 'lodash';

Font.register(fonts);

const styles = StyleSheet.create({
  container: {
    fontFamily: 'OpenSans',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  card: {
    margin: '1mm',
    width: '144.7mm',
    height: '102.66mm',
    border: '1 solid #000000',
    display: 'flex',
    marginBottom: 5
  },
  qrwithname: {
    display: 'flex',
    flexDirection: 'row',
    height: '124px'
  },
  qrcode: {
    width: '130px',
    height: '124px',
    padding: '1mm',
    borderRight: '1 solid #000000'
  },
  productnameWithDesc: {
    fontSize: '10.76pt',
    width: '280px',
    display: 'flex',
    flexDirection: 'column'
  },
  productname: {
    fontWeight: 'bold',
    height: '81px',
    width: '280px',
    padding: '10px',
    borderBottom: '1 solid #000000'
  },
  productDescriptionBlock: {
    display: 'flex',
    flexDirection: 'row'
  },
  productDescription: {
    fontSize: '6.78pt',
    paddingTop: '1mm',
    paddingLeft: '1mm',
    lineHeight: 1.6,
    width: '130px'
  },
  mrpDealBlock: {
    width: '147px',
    borderLeft: '1 solid #000000',
    height: '44px'
  },
  // discList: { height: '6mm' },
  priceBlock: {
    paddingLeft: '2mm',
    borderTop: '1 solid #000000',
    borderBottom: '1 solid #000000',
    display: 'flex',
    flexDirection: 'row',
    height: '94px'
  },
  pricewithRupeeIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '254.38px',
    height: '94px',
    paddingTop: '1mm'
  },
  rupeeicon: {
    fontSize: '17.3pt',
    width: '1mm',
    paddingTop: '2mm',
    marginRight: '1mm'
  },
  price: {
    fontWeight: 'bold',
    fontSize: '65.1pt',
    textAlign: 'left',
    color: 'black',
    paddingLeft: '3mm',
    top: -16,
    lineHeight: 1
  },
  inclTax: {
    fontSize: '17.6pt',
    fontWeight: 'bold',
    marginRight: '1mm',
    top: -10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  deals: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '18.69pt',
    height: '42px'
  },
  mrpText: {
    fontWeight: 'bold',
    color: 'black'
  },
  rupeeSmall: {
    width: '3.8mm',
    height: '4.58mm',
    top: 7.28
  },

  //   Footer
  footer: {
    padding: '0mm',
    height: '27.52mm'
  },
  warrantyWithBarcode: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  warranty: {
    height: '14.7mm',
    width: '14.7mm',
    margin: '2mm 1mm 1mm 1mm',
    marginLeft: '2mm'
  },
  warrantyDuriation: {
    position: 'absolute',
    height: '9.5mm',
    width: '9.5mm',
    fontWeight: 'bold',
    margin: '3mm',
    backgroundColor: '#fff',
    borderRadius: '50',
    alignItems: 'center',
    justifyContent: 'center'
  },
  warrantyValue: { fontSize: '15.12pt', left: -2 },
  warrantyYear: { fontSize: '4.64pt', top: -5, left: -1 },
  warrantyText: {
    fontSize: '6.64pt',
    color: '#1A1818',
    opacity: '1'
  },
  dateDetails: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '2mm',
    top: 8
  },
  itemCode: {
    fontSize: '6.84pt',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  barcodeBlock: {
    width: '103mm',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'right',
    right: 0
  },
  barcode: {
    width: '42.7mm',
    height: '16.2mm',
    padding: '2mm 1mm 0mm 0mm'
  },

  disclimer: {
    width: '3.75mm',
    height: '3.75mm',
    left: 5
  },
  saveblock: {
    width: '148px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderLeft: '1 solid #000000'
  },
  savetext: {
    width: '148px',
    height: '24px',
    backgroundColor: '#818386',
    borderBottom: '1 solid #000000'
  },
  saveprice: {
    width: '148px',
    height: '70px',
    backgroundColor: '#1A1818',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
const getA6Layout = (count, data) => {
  return count.map(id => (
    <View key={id}>
      {data?.map(item => (
        <View style={styles.card} key={item?.id}>
          <View style={styles.qrwithname}>
            <View style={styles.qrcode}>
              <Image src={item.image} />
            </View>
            <View style={styles.productnameWithDesc}>
              <View style={styles.productname}>
                <Text>
                  {truncateString(item?.product?.masterData?.name, 130)}
                </Text>
              </View>
              <View style={styles.productDescriptionBlock}>
                <View style={styles.productDescription}>
                  {getSplitDescription(item.description, 3).map(
                    (line, index) => (
                      <Text key={index} style={{ marginTop: '1px' }}>
                        + {truncateString(line, 30)}
                      </Text>
                    )
                  )}
                </View>
                <View style={styles.mrpDealBlock}>
                  <View style={styles.deals}></View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.priceBlock}>
            <View style={styles.pricewithRupeeIcon}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={styles.rupeeicon}>
                  <Image src={RupeeFit} />
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={styles.price}>
                      {truncatedNumberFloat(item?.product?.mop, 6)}
                    </Text>
                    <Text style={{ fontSize: '14pt', fontWeight: 'bold' }}>
                      *
                    </Text>
                  </View>

                  <View style={styles.inclTax}>
                    <Text style={{ fontSize: '14pt', fontWeight: 'bold' }}>
                      *
                    </Text>
                    <Text>(Incl. Taxes)</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.saveblock}>
              <View style={styles.savetext}></View>
              <View style={styles.saveprice}></View>
            </View>
          </View>
          <View style={styles?.footer}>
            <View style={styles.warrantyWithBarcode}>
              <Warranty warranty={item.warranty} styles={styles} />
              <View style={styles.barcodeBlock}>
                <View style={styles.barcode}>
                  {_.isEmpty(item.barcode) ? (
                    <></>
                  ) : (
                    <Image src={GetBarCode(item?.barcode)} />
                  )}
                </View>
              </View>
            </View>
            <View
              style={
                item?.warranty
                  ? styles?.dateDetails
                  : { ...styles.dateDetails, top: 15 }
              }
            >
              <View style={styles.itemCode}>
                <Text>
                  {`${item?.sku}  |  ${item.class}  |  A  | ${moment(
                    item?.date
                  ).format('DD.MM.YYYY')}  |  ${moment().format('DD.MM.YYYY')}`}
                </Text>
                <Image style={styles.disclimer} src={Disclimer} />
              </View>
            </View>
          </View>
        </View>
      ))}
    </View>
  ));
};
const A6LandscapeH1 = ({ data, count, ...rest }) => {
  const splattedArray = _.chunk(data, 4);
  return (
    <Page orientation="landscape">
      <View style={styles.container}>
        {count &&
          _.map(splattedArray, (chunkedData, key) => {
            const chunkedRowArray = _.chunk(chunkedData, 2);
            const firstHalf = getA6Layout(count, chunkedRowArray[0]);
            const secondHalf = getA6Layout(count, chunkedRowArray[1]);
            return (
              <View
                style={{ flexDirection: 'row', flexWrap: 'wrap' }}
                key={key}
              >
                <View>{firstHalf}</View>
                <View>{secondHalf}</View>
              </View>
            );
          })}
      </View>
    </Page>
  );
};
export { A6LandscapeH1 };
