import config from '../config/index';
import { Business } from '../models/User.model';

function getTierConfigs(business: Business) {
  if (business && business.id) {
    return fetch(
      `${config.ruleServerUrl}/users/loyalty/businessUnit/${business.id}/tier/configs`,
      {
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
      }
    ).then(r => r.json());
  } else {
    return Promise.reject('tier business unit missing');
  }
}

export default {
  getTierConfigs
};
