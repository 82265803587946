import { CircularProgress } from '@material-ui/core';
import React, { FC } from 'react';

import styles from './Loader.module.scss';

const Loader: FC = () => {
  return (
    <div id={styles.loaderContainer}>
      <CircularProgress size={60} thickness={5} />
    </div>
  );
};
export default Loader;
