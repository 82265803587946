/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styles from './MasterCard.module.css';
import DeleteIcon from './Delete';

export default function Option({
  name = '',
  title = '',
  listMode = false,
  action = () => null
}) {
  const [option, setOption] = useState('');

  useEffect(() => {
    setOption(name);
  }, []);

  const newItem = () => {
    return (
      <div className={styles.newItem}>
        <input
          placeholder={`Enter ${title} Name`}
          type="text"
          value={option}
          onChange={evt => setOption(evt.target.value)}
        />
        <button
          disabled={!option}
          onClick={() => {
            action(option);
            setOption('');
          }}
        >
          ADD
        </button>
      </div>
    );
  };

  const listItem = () => {
    return (
      <div className={styles.listItem}>
        <div>{name}</div>
        <DeleteIcon onClick={action} />
      </div>
    );
  };

  return listMode ? listItem() : newItem();
}
