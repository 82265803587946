/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  Checkbox,
  FormControlLabel,
  Popover,
  IconButton
} from '@material-ui/core';
import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import storeHealth from '../components/TenantReports/StoreHealthDashboard/index.module.scss';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import config from '../config';
import { GetApp } from '@material-ui/icons';

export const filterInitialState = {
  startDate: moment()
    .subtract(1, 'day')
    .format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD')
};

export const ColumnHiding = props => {
  const { allColumns, handleClose, handleOpen, hideColumn, anchorEl } = props;

  return (
    <span>
      <MenuIcon onClick={handleOpen} className={storeHealth.menuIconStyle} />
      <Popover
        open={hideColumn}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <>
          <Grid
            container
            spacing={3}
            className={storeHealth.columnHidingContainer}
            style={{ width: '400px' }}
          >
            {allColumns.map((column, idx) => (
              <>
                {column.id !== 'columnHiding' && idx !== 0 && (
                  <Grid item xs={6}>
                    <div key={column.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...column.getToggleHiddenProps()}
                            color="primary"
                          />
                        }
                        label={column.Header || column.id}
                      />
                    </div>
                  </Grid>
                )}
              </>
            ))}
          </Grid>
        </>
      </Popover>
    </span>
  );
};

export const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
  { title: 'The Good, the Bad and the Ugly', year: 1966 },
  { title: 'Fight Club', year: 1999 },
  { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
  { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
  { title: 'Forrest Gump', year: 1994 }
];
export const StoreHealthViewData = () => {
  const getDownloadReportUrl = url => {
    console.log(url, 'url', url.length);
    if (url.length === 0) {
      return '';
    }
    const indexOfReportUrl = url.indexOf('/report') + 1;

    return `${config.ruleServerUrl}/download-file/${url.slice(
      indexOfReportUrl
    )}`;
  };
  return [
    {
      Header: 'Date Triggered',
      accessor: 'createdAt',
      Cell: ({ row }) => {
        return (
          <span>{moment(row.original.createdAt).format('YYYY-MM-DD')}</span>
        );
      }
    },

    {
      Header: 'Start Date',
      accessor: 'startDate',
      Cell: ({ row }) => {
        return <span>{row.original.data.startDate}</span>;
      }
    },
    {
      Header: 'End Date',
      accessor: 'endDate',
      Cell: ({ row }) => {
        return <span>{row.original.data.endDate}</span>;
      }
    },
    {
      Header: 'Status',
      accessor: 'status'
    },
    {
      Header: 'Download',
      accessor: 'errorFileLocation',
      Cell: ({ row }) => {
        return (
          <IconButton
            disabled={row.original.status !== 'COMPLETED'}
            component="a"
            href={getDownloadReportUrl(row.original.errorFileLocation)}
            download
          >
            <GetApp />
          </IconButton>
        );
      }
    }
  ];
};
export const StoreHealthColumnData = () => {
  return [
    {
      accessor: 'storeCode',
      Header: 'Store'
    },

    {
      accessor: 'city',
      Header: 'City'
    },
    {
      accessor: 'oneTimers',
      Header: 'One Timers'
    },
    {
      accessor: 'repeatBuyers',
      Header: 'Repeat Buyers'
    },
    {
      accessor: 'atRisk',
      Header: 'At Risk'
    },
    {
      accessor: 'droppedOff',
      Header: 'Dropped Off'
    },
    {
      accessor: 'total_customers',
      Header: 'Total Customers'
    },
    {
      accessor: 'total_sales',
      Header: 'Total Sales'
    },
    {
      accessor: 'ATV',
      Header: 'ATV'
    }
  ];
};

export const mockData = [
  {
    store: 'Klein-Hettinger',
    brand: 'Conn and Sons',
    city: 'Melaka',
    tier1: 41,
    tier2: 87,
    tier3: 68,
    tier4: 79,
    DominantNPS: 'Champlin-Walker',
    abv: 51
  },
  {
    store: 'Connelly-Adams',
    brand: 'Bechtelar, Mraz and Von',
    city: 'Ankou',
    tier1: 26,
    tier2: 8,
    tier3: 68,
    tier4: 63,
    DominantNPS: 'Bednar, Sauer and Braun',
    abv: 19
  },
  {
    store: 'Littel, Rath and Berge',
    brand: 'Quitzon, Streich and Beatty',
    city: 'Quankou',
    tier1: 31,
    tier2: 64,
    tier3: 83,
    tier4: 63,
    DominantNPS: 'Hilpert-Quigley',
    abv: 8
  },
  {
    store: 'Mante, Kulas and Grimes',
    brand: 'Hayes, Hauck and Grady',
    city: 'Alagoinhas',
    tier1: 40,
    tier2: 19,
    tier3: 65,
    tier4: 96,
    DominantNPS: 'Lubowitz-Haley',
    abv: 86
  },
  {
    store: 'Sipes, Senger and Hahn',
    brand: 'Davis Group',
    city: 'Caramanta',
    tier1: 3,
    tier2: 58,
    tier3: 59,
    tier4: 90,
    DominantNPS: 'Denesik, Moen and Miller',
    abv: 40
  },
  {
    store: 'Klein and Sons',
    brand: "Hills-O'Conner",
    city: 'Baqiao',
    tier1: 90,
    tier2: 67,
    tier3: 92,
    tier4: 55,
    DominantNPS: 'Nitzsche, Ziemann and Krajcik',
    abv: 20
  },
  {
    store: 'Grimes-Baumbach',
    brand: 'Heathcote LLC',
    city: 'Ribeirão das Neves',
    tier1: 37,
    tier2: 98,
    tier3: 68,
    tier4: 28,
    DominantNPS: 'Pfeffer Group',
    abv: 91
  },
  {
    store: 'Aufderhar, Ruecker and Schmeler',
    brand: 'Feil LLC',
    city: 'Drobin',
    tier1: 99,
    tier2: 63,
    tier3: 35,
    tier4: 31,
    DominantNPS: 'Kassulke-Kohler',
    abv: 93
  },
  {
    store: 'West Inc',
    brand: 'Boyle-Cole',
    city: 'Quchomo',
    tier1: 69,
    tier2: 81,
    tier3: 47,
    tier4: 91,
    DominantNPS: 'Emmerich-Rodriguez',
    abv: 62
  },
  {
    store: 'Fay, Steuber and Altenwerth',
    brand: 'Kirlin, Spencer and Lowe',
    city: 'Colorado Springs',
    tier1: 82,
    tier2: 22,
    tier3: 75,
    tier4: 66,
    DominantNPS: 'Cummings, Batz and Beer',
    abv: 27
  },
  {
    store: 'Abbott-Zboncak',
    brand: 'Rau-Carroll',
    city: 'Barbacena',
    tier1: 24,
    tier2: 18,
    tier3: 63,
    tier4: 14,
    DominantNPS: 'West-Tremblay',
    abv: 42
  },
  {
    store: 'Hagenes-Wiegand',
    brand: 'Skiles and Sons',
    city: 'Xiaohe',
    tier1: 70,
    tier2: 65,
    tier3: 41,
    tier4: 18,
    DominantNPS: 'Hudson-Ferry',
    abv: 34
  },
  {
    store: 'Kling, Hackett and Stokes',
    brand: 'Gibson, Hirthe and Beatty',
    city: 'São Joaquim da Barra',
    tier1: 60,
    tier2: 30,
    tier3: 3,
    tier4: 19,
    DominantNPS: 'Schinner LLC',
    abv: 86
  },
  {
    store: 'Gusikowski-Stanton',
    brand: 'Larson, Balistreri and Conroy',
    city: 'Kovilj',
    tier1: 55,
    tier2: 64,
    tier3: 7,
    tier4: 50,
    DominantNPS: 'Harris, Davis and Ondricka',
    abv: 94
  },
  {
    store: 'Lubowitz, Runolfsson and Smitham',
    brand: 'Schultz-Heaney',
    city: 'Klembivka',
    tier1: 4,
    tier2: 64,
    tier3: 21,
    tier4: 19,
    DominantNPS: 'Thiel, Walker and Braun',
    abv: 44
  },
  {
    store: 'Heidenreich Group',
    brand: 'Pollich, Schulist and Orn',
    city: 'Qal‘ah-ye Fārsī',
    tier1: 64,
    tier2: 42,
    tier3: 94,
    tier4: 68,
    DominantNPS: 'Marvin-Gerlach',
    abv: 83
  },
  {
    store: 'Rath-Armstrong',
    brand: 'Leuschke, Kassulke and Smitham',
    city: 'Naranjos',
    tier1: 82,
    tier2: 50,
    tier3: 91,
    tier4: 18,
    DominantNPS: 'Maggio-Daniel',
    abv: 28
  },
  {
    store: 'Bahringer, Connelly and Adams',
    brand: 'Gottlieb-Kuhn',
    city: 'Slobozia',
    tier1: 26,
    tier2: 100,
    tier3: 5,
    tier4: 82,
    DominantNPS: 'Carter and Sons',
    abv: 1
  },
  {
    store: 'Bailey and Sons',
    brand: 'Bosco-Buckridge',
    city: 'Gwio Kura',
    tier1: 62,
    tier2: 44,
    tier3: 72,
    tier4: 35,
    DominantNPS: 'Runolfsdottir and Sons',
    abv: 11
  },
  {
    store: 'Wyman, Howe and Carter',
    brand: 'Dach Group',
    city: 'Cibulakan',
    tier1: 40,
    tier2: 32,
    tier3: 9,
    tier4: 1,
    DominantNPS: 'Schuster-Lueilwitz',
    abv: 54
  },
  {
    store: 'Gottlieb and Sons',
    brand: 'Ward-Daugherty',
    city: 'Las Tunas',
    tier1: 31,
    tier2: 69,
    tier3: 82,
    tier4: 69,
    DominantNPS: 'Ondricka, Blanda and Romaguera',
    abv: 52
  },
  {
    store: 'Blanda and Sons',
    brand: 'Bruen Group',
    city: 'Siaton',
    tier1: 23,
    tier2: 18,
    tier3: 56,
    tier4: 45,
    DominantNPS: 'Hane, Wintheiser and Jakubowski',
    abv: 31
  },
  {
    store: 'Walker-Wehner',
    brand: 'Mitchell, Quitzon and White',
    city: 'Rukem',
    tier1: 42,
    tier2: 26,
    tier3: 100,
    tier4: 58,
    DominantNPS: 'Blick, Spinka and Legros',
    abv: 57
  },
  {
    store: 'Ebert-Hahn',
    brand: 'Kessler, Borer and Wyman',
    city: 'Xinjiang',
    tier1: 20,
    tier2: 91,
    tier3: 9,
    tier4: 22,
    DominantNPS: 'Koss, Dicki and Torp',
    abv: 36
  },
  {
    store: 'Bergnaum-Roob',
    brand: 'Cummerata, Kozey and Williamson',
    city: 'Seara',
    tier1: 7,
    tier2: 39,
    tier3: 62,
    tier4: 33,
    DominantNPS: 'Glover-Boyer',
    abv: 75
  },
  {
    store: 'Swaniawski-Marks',
    brand: 'Bauch, Willms and Shanahan',
    city: 'Dobroměřice',
    tier1: 1,
    tier2: 4,
    tier3: 29,
    tier4: 57,
    DominantNPS: 'Treutel, Ankunding and Heathcote',
    abv: 37
  },
  {
    store: 'Maggio-Renner',
    brand: 'Spencer Inc',
    city: 'Lembur Tengah',
    tier1: 73,
    tier2: 64,
    tier3: 89,
    tier4: 70,
    DominantNPS: 'Hessel-Robel',
    abv: 25
  },
  {
    store: 'Konopelski Inc',
    brand: 'West and Sons',
    city: 'Vojnić',
    tier1: 47,
    tier2: 50,
    tier3: 61,
    tier4: 11,
    DominantNPS: 'Hirthe Group',
    abv: 86
  },
  {
    store: 'Klein LLC',
    brand: 'Witting LLC',
    city: 'Tsementnozavodskiy',
    tier1: 3,
    tier2: 54,
    tier3: 70,
    tier4: 63,
    DominantNPS: 'Treutel-Runte',
    abv: 41
  },
  {
    store: 'Schuppe Inc',
    brand: 'Oberbrunner Group',
    city: 'Lorut',
    tier1: 78,
    tier2: 24,
    tier3: 85,
    tier4: 69,
    DominantNPS: 'Barton-Conroy',
    abv: 78
  },
  {
    store: 'Stroman LLC',
    brand: 'Satterfield and Sons',
    city: 'Barão de Cocais',
    tier1: 53,
    tier2: 57,
    tier3: 5,
    tier4: 44,
    DominantNPS: 'Wintheiser-Sporer',
    abv: 88
  },
  {
    store: 'Cruickshank-Grimes',
    brand: 'Wolff, Erdman and Weber',
    city: 'Kon Tum',
    tier1: 89,
    tier2: 87,
    tier3: 43,
    tier4: 30,
    DominantNPS: 'Graham-Herman',
    abv: 64
  },
  {
    store: 'Murray-Waters',
    brand: 'Weimann-Keebler',
    city: 'Voinjama',
    tier1: 70,
    tier2: 8,
    tier3: 41,
    tier4: 88,
    DominantNPS: 'Kuvalis Group',
    abv: 18
  },
  {
    store: "DuBuque, Bode and O'Conner",
    brand: 'Walker LLC',
    city: 'Chengbei',
    tier1: 77,
    tier2: 8,
    tier3: 66,
    tier4: 62,
    DominantNPS: 'Hagenes-Wintheiser',
    abv: 71
  },
  {
    store: 'Conroy Inc',
    brand: 'Ortiz LLC',
    city: 'Bardīyah',
    tier1: 67,
    tier2: 96,
    tier3: 82,
    tier4: 48,
    DominantNPS: 'Rau Inc',
    abv: 82
  },
  {
    store: 'Wolf Inc',
    brand: 'Ward-Weissnat',
    city: 'Candelaria',
    tier1: 47,
    tier2: 42,
    tier3: 46,
    tier4: 42,
    DominantNPS: 'Jakubowski-Beatty',
    abv: 85
  },
  {
    store: 'Boyer Inc',
    brand: 'Kovacek, Legros and Walter',
    city: 'Mabalacat',
    tier1: 42,
    tier2: 6,
    tier3: 6,
    tier4: 94,
    DominantNPS: 'Becker Inc',
    abv: 15
  },
  {
    store: 'Osinski, Gulgowski and Fay',
    brand: 'Rau, Kemmer and Anderson',
    city: 'New Agutaya',
    tier1: 74,
    tier2: 25,
    tier3: 22,
    tier4: 96,
    DominantNPS: 'Botsford Group',
    abv: 48
  },
  {
    store: 'Cummings, Thompson and Hahn',
    brand: 'Brakus, Koepp and Hansen',
    city: 'Nikol’skoye',
    tier1: 19,
    tier2: 48,
    tier3: 71,
    tier4: 69,
    DominantNPS: 'Anderson LLC',
    abv: 11
  },
  {
    store: 'Littel-Hoeger',
    brand: 'Moen LLC',
    city: 'Krajanpagowan',
    tier1: 76,
    tier2: 51,
    tier3: 35,
    tier4: 88,
    DominantNPS: 'Wolf LLC',
    abv: 85
  },
  {
    store: 'Ledner Group',
    brand: 'Armstrong, Blick and Kulas',
    city: 'Trieste',
    tier1: 71,
    tier2: 52,
    tier3: 74,
    tier4: 3,
    DominantNPS: 'Kautzer Group',
    abv: 81
  },
  {
    store: 'West-Collier',
    brand: "Schinner, O'Kon and Harber",
    city: 'Nantes',
    tier1: 66,
    tier2: 74,
    tier3: 77,
    tier4: 17,
    DominantNPS: 'Stanton, Towne and Jacobi',
    abv: 11
  },
  {
    store: 'Bergnaum, Schmidt and Hoeger',
    brand: 'Lakin and Sons',
    city: 'Canggou',
    tier1: 25,
    tier2: 63,
    tier3: 87,
    tier4: 57,
    DominantNPS: 'Howell Inc',
    abv: 9
  },
  {
    store: 'Dickens, Effertz and Walter',
    brand: 'Hessel, Mayer and Hahn',
    city: 'Bao’an',
    tier1: 26,
    tier2: 90,
    tier3: 75,
    tier4: 70,
    DominantNPS: 'Kreiger Inc',
    abv: 85
  },
  {
    store: 'McCullough, Hirthe and Gleichner',
    brand: 'Marks Group',
    city: 'Tagog',
    tier1: 72,
    tier2: 53,
    tier3: 73,
    tier4: 18,
    DominantNPS: 'Crooks-Stiedemann',
    abv: 80
  },
  {
    store: 'Bayer-Feeney',
    brand: 'Frami, Medhurst and Schuster',
    city: 'Timba Lauk',
    tier1: 42,
    tier2: 73,
    tier3: 55,
    tier4: 32,
    DominantNPS: 'Haag, Schoen and Lynch',
    abv: 50
  },
  {
    store: 'Christiansen, McCullough and Luettgen',
    brand: 'Dicki LLC',
    city: 'Hadāli',
    tier1: 46,
    tier2: 25,
    tier3: 29,
    tier4: 68,
    DominantNPS: 'Rath, Streich and Gaylord',
    abv: 62
  },
  {
    store: 'Lesch-Hintz',
    brand: 'Hartmann-Lynch',
    city: 'Athens',
    tier1: 51,
    tier2: 38,
    tier3: 92,
    tier4: 62,
    DominantNPS: 'Schowalter Inc',
    abv: 47
  },
  {
    store: 'Heidenreich, Prohaska and Bergstrom',
    brand: 'Hammes-Rau',
    city: 'Chochkan',
    tier1: 52,
    tier2: 98,
    tier3: 19,
    tier4: 6,
    DominantNPS: 'Thompson-Parisian',
    abv: 74
  },
  {
    store: 'Jacobson-Zboncak',
    brand: 'Stehr-Ankunding',
    city: 'Jiangkou',
    tier1: 64,
    tier2: 32,
    tier3: 84,
    tier4: 15,
    DominantNPS: 'Kassulke, Rau and Lowe',
    abv: 10
  },
  {
    store: 'Lubowitz, Bernhard and Williamson',
    brand: 'Cummings, Erdman and Dibbert',
    city: 'Ljungby',
    tier1: 57,
    tier2: 56,
    tier3: 78,
    tier4: 50,
    DominantNPS: 'Upton-Ebert',
    abv: 4
  },
  {
    store: 'Gottlieb-Wilkinson',
    brand: 'Prosacco and Sons',
    city: 'Wujiao',
    tier1: 47,
    tier2: 8,
    tier3: 41,
    tier4: 19,
    DominantNPS: 'Schaefer, Kutch and Emard',
    abv: 75
  },
  {
    store: 'Hilll, Sporer and Labadie',
    brand: 'Beahan-Daugherty',
    city: 'Dalian',
    tier1: 3,
    tier2: 8,
    tier3: 95,
    tier4: 77,
    DominantNPS: 'Schultz, Purdy and Block',
    abv: 2
  },
  {
    store: 'Ondricka, Langosh and Mertz',
    brand: 'Bogisich and Sons',
    city: 'Custóias',
    tier1: 87,
    tier2: 20,
    tier3: 63,
    tier4: 38,
    DominantNPS: 'Schaden-Goyette',
    abv: 53
  },
  {
    store: 'Hamill LLC',
    brand: 'Zboncak, Hammes and Greenholt',
    city: 'Leskovec pri Krškem',
    tier1: 41,
    tier2: 17,
    tier3: 69,
    tier4: 42,
    DominantNPS: 'Romaguera LLC',
    abv: 11
  },
  {
    store: 'Rowe, Lemke and Greenfelder',
    brand: 'Schiller, Jacobs and Bogisich',
    city: 'Anzhero-Sudzhensk',
    tier1: 10,
    tier2: 96,
    tier3: 10,
    tier4: 83,
    DominantNPS: 'Anderson, Collins and Romaguera',
    abv: 67
  },
  {
    store: 'Grady-Nicolas',
    brand: 'Graham Group',
    city: 'Lions Bay',
    tier1: 63,
    tier2: 38,
    tier3: 81,
    tier4: 89,
    DominantNPS: "O'Hara, Feil and Mertz",
    abv: 87
  },
  {
    store: 'Green-Osinski',
    brand: 'Mann, Pollich and Kiehn',
    city: 'Hucun',
    tier1: 11,
    tier2: 46,
    tier3: 46,
    tier4: 44,
    DominantNPS: 'Rogahn Inc',
    abv: 61
  },
  {
    store: 'Abernathy, Vandervort and Bosco',
    brand: 'Ryan and Sons',
    city: 'Tunasbaru',
    tier1: 88,
    tier2: 43,
    tier3: 1,
    tier4: 25,
    DominantNPS: 'Ferry LLC',
    abv: 11
  },
  {
    store: "Kessler, D'Amore and Gorczany",
    brand: 'Fahey, Ruecker and Schroeder',
    city: 'Osieczna',
    tier1: 30,
    tier2: 5,
    tier3: 61,
    tier4: 15,
    DominantNPS: 'Hartmann, Kreiger and Buckridge',
    abv: 25
  },
  {
    store: 'Hamill Inc',
    brand: 'McClure-Morar',
    city: 'Naikoten Dua',
    tier1: 95,
    tier2: 61,
    tier3: 5,
    tier4: 76,
    DominantNPS: "D'Amore, Konopelski and McClure",
    abv: 83
  },
  {
    store: 'Dickinson and Sons',
    brand: 'Carter and Sons',
    city: 'Krajan',
    tier1: 47,
    tier2: 44,
    tier3: 61,
    tier4: 25,
    DominantNPS: 'Corkery, Emard and Rice',
    abv: 62
  },
  {
    store: 'Kozey, Emard and Bartoletti',
    brand: 'Aufderhar-Beatty',
    city: 'Varadero',
    tier1: 49,
    tier2: 79,
    tier3: 14,
    tier4: 69,
    DominantNPS: 'Rowe-Jacobi',
    abv: 58
  },
  {
    store: 'Cummerata, Anderson and Schiller',
    brand: 'Jakubowski, Murray and Green',
    city: 'Dehui',
    tier1: 85,
    tier2: 35,
    tier3: 32,
    tier4: 4,
    DominantNPS: "Kiehn, Gleichner and O'Connell",
    abv: 79
  },
  {
    store: 'Bradtke-Beer',
    brand: 'Mayert-Yost',
    city: 'Fenshui',
    tier1: 31,
    tier2: 2,
    tier3: 10,
    tier4: 41,
    DominantNPS: 'Lynch Group',
    abv: 68
  },
  {
    store: 'Mante, Fadel and Funk',
    brand: 'Buckridge-Frami',
    city: 'Rizal',
    tier1: 19,
    tier2: 25,
    tier3: 59,
    tier4: 6,
    DominantNPS: 'Carroll-Stiedemann',
    abv: 78
  },
  {
    store: 'Bauch Group',
    brand: 'Doyle, Dicki and Spinka',
    city: 'Pavlodar',
    tier1: 46,
    tier2: 12,
    tier3: 24,
    tier4: 60,
    DominantNPS: 'Blanda, Dooley and Fahey',
    abv: 78
  },
  {
    store: 'Wolf-Gaylord',
    brand: 'Bayer, Bode and Lebsack',
    city: 'San Jerónimo',
    tier1: 63,
    tier2: 34,
    tier3: 44,
    tier4: 3,
    DominantNPS: 'Heller Inc',
    abv: 84
  },
  {
    store: 'Wintheiser Inc',
    brand: 'Kshlerin-Erdman',
    city: 'Stará Ves nad Ondřejnicí',
    tier1: 3,
    tier2: 31,
    tier3: 50,
    tier4: 7,
    DominantNPS: 'Kozey, Jacobson and Grimes',
    abv: 59
  },
  {
    store: 'Osinski, Pollich and Lueilwitz',
    brand: 'Grimes-Medhurst',
    city: 'Shinkafe',
    tier1: 14,
    tier2: 63,
    tier3: 59,
    tier4: 31,
    DominantNPS: 'Botsford-Dibbert',
    abv: 96
  },
  {
    store: 'Schaden-Stoltenberg',
    brand: 'Ledner, Block and Reichel',
    city: 'Cidade Velha',
    tier1: 7,
    tier2: 20,
    tier3: 72,
    tier4: 79,
    DominantNPS: 'Raynor, Murazik and Morissette',
    abv: 58
  },
  {
    store: 'Schneider Inc',
    brand: 'Denesik, Bins and Heller',
    city: 'La Quebrada',
    tier1: 40,
    tier2: 92,
    tier3: 83,
    tier4: 50,
    DominantNPS: 'Ferry, Brakus and Lubowitz',
    abv: 52
  },
  {
    store: 'White-Feil',
    brand: 'Harris Inc',
    city: 'Paarl',
    tier1: 28,
    tier2: 17,
    tier3: 12,
    tier4: 71,
    DominantNPS: 'Gutmann-Hodkiewicz',
    abv: 77
  },
  {
    store: 'Fisher and Sons',
    brand: 'Greenholt-Metz',
    city: 'Alenquer',
    tier1: 24,
    tier2: 66,
    tier3: 23,
    tier4: 15,
    DominantNPS: 'Tillman and Sons',
    abv: 83
  },
  {
    store: 'McDermott LLC',
    brand: 'Bartell-Block',
    city: 'Bagnolet',
    tier1: 97,
    tier2: 47,
    tier3: 71,
    tier4: 64,
    DominantNPS: 'Hansen Inc',
    abv: 7
  },
  {
    store: 'Effertz Group',
    brand: 'Mosciski, Luettgen and Greenfelder',
    city: 'Mtinko',
    tier1: 16,
    tier2: 30,
    tier3: 27,
    tier4: 83,
    DominantNPS: 'Nikolaus and Sons',
    abv: 95
  },
  {
    store: 'Walter LLC',
    brand: 'Senger LLC',
    city: 'Musalerr',
    tier1: 26,
    tier2: 91,
    tier3: 9,
    tier4: 88,
    DominantNPS: 'Hermann, Mraz and Trantow',
    abv: 11
  },
  {
    store: 'Russel-Gutkowski',
    brand: 'Blick, Lebsack and Mohr',
    city: 'Chybie',
    tier1: 67,
    tier2: 10,
    tier3: 1,
    tier4: 85,
    DominantNPS: 'Ullrich, Watsica and Miller',
    abv: 75
  },
  {
    store: 'Huel-Nader',
    brand: "O'Reilly Group",
    city: 'Bulu',
    tier1: 97,
    tier2: 86,
    tier3: 27,
    tier4: 11,
    DominantNPS: 'Mohr, Schamberger and Cummerata',
    abv: 53
  },
  {
    store: 'Schuppe-Hamill',
    brand: 'Ferry, Goldner and Hegmann',
    city: 'Smečno',
    tier1: 99,
    tier2: 29,
    tier3: 75,
    tier4: 73,
    DominantNPS: 'Batz-Casper',
    abv: 19
  },
  {
    store: 'Gibson, Kuphal and Hauck',
    brand: 'Renner-Purdy',
    city: 'Kuz’minskiye Otverzhki',
    tier1: 4,
    tier2: 11,
    tier3: 81,
    tier4: 1,
    DominantNPS: 'Langworth LLC',
    abv: 15
  },
  {
    store: 'Schmidt, Schinner and Leannon',
    brand: 'Dach LLC',
    city: 'Tierp',
    tier1: 4,
    tier2: 53,
    tier3: 2,
    tier4: 100,
    DominantNPS: 'Glover, Kuphal and Lemke',
    abv: 78
  },
  {
    store: 'Hessel and Sons',
    brand: 'Williamson, Ondricka and Volkman',
    city: 'Limeiras',
    tier1: 51,
    tier2: 68,
    tier3: 100,
    tier4: 9,
    DominantNPS: 'Stehr Group',
    abv: 45
  },
  {
    store: 'Schmeler-Watsica',
    brand: 'Effertz, Pollich and Lowe',
    city: 'Penedono',
    tier1: 16,
    tier2: 18,
    tier3: 68,
    tier4: 25,
    DominantNPS: 'Stokes, Yost and Schroeder',
    abv: 7
  },
  {
    store: 'Schulist, Jones and Flatley',
    brand: 'Steuber-Kihn',
    city: 'Beizhakou',
    tier1: 45,
    tier2: 94,
    tier3: 95,
    tier4: 43,
    DominantNPS: 'Armstrong-Bruen',
    abv: 8
  },
  {
    store: 'Gibson Inc',
    brand: 'Bauch, Bechtelar and Braun',
    city: 'Cañasgordas',
    tier1: 21,
    tier2: 93,
    tier3: 15,
    tier4: 57,
    DominantNPS: 'Kulas, Smitham and Kirlin',
    abv: 21
  },
  {
    store: 'Turner Inc',
    brand: 'Ernser, Gutkowski and Kulas',
    city: 'Balidian',
    tier1: 4,
    tier2: 68,
    tier3: 58,
    tier4: 42,
    DominantNPS: 'Franecki Group',
    abv: 56
  },
  {
    store: 'Wilderman Group',
    brand: 'Pfannerstill-Kirlin',
    city: 'Paratinga',
    tier1: 3,
    tier2: 39,
    tier3: 96,
    tier4: 89,
    DominantNPS: 'Aufderhar, Rempel and Hegmann',
    abv: 92
  },
  {
    store: 'Grant Inc',
    brand: 'Wyman, Huels and Ziemann',
    city: 'Massy',
    tier1: 85,
    tier2: 20,
    tier3: 58,
    tier4: 62,
    DominantNPS: 'Lubowitz Group',
    abv: 85
  },
  {
    store: 'Herzog LLC',
    brand: 'Quitzon, Koepp and Kihn',
    city: 'Göteborg',
    tier1: 57,
    tier2: 99,
    tier3: 68,
    tier4: 70,
    DominantNPS: 'Prosacco Group',
    abv: 82
  },
  {
    store: 'Cremin LLC',
    brand: 'Adams-Klocko',
    city: 'Omaha',
    tier1: 54,
    tier2: 53,
    tier3: 75,
    tier4: 30,
    DominantNPS: 'Funk-Simonis',
    abv: 16
  },
  {
    store: 'Rodriguez-Glover',
    brand: 'Bartell Group',
    city: 'Gjøvik',
    tier1: 56,
    tier2: 81,
    tier3: 95,
    tier4: 52,
    DominantNPS: 'Carter-Kreiger',
    abv: 18
  },
  {
    store: 'Rogahn-Purdy',
    brand: 'Bogisich Group',
    city: 'Ronda',
    tier1: 72,
    tier2: 3,
    tier3: 96,
    tier4: 53,
    DominantNPS: 'Pfeffer LLC',
    abv: 24
  },
  {
    store: 'Ryan Group',
    brand: 'Green, Grady and Gleichner',
    city: 'Panjerrejo',
    tier1: 60,
    tier2: 100,
    tier3: 91,
    tier4: 41,
    DominantNPS: 'Crist and Sons',
    abv: 100
  },
  {
    store: 'Jones Group',
    brand: 'Buckridge, Lesch and Farrell',
    city: 'Bobigny',
    tier1: 91,
    tier2: 3,
    tier3: 55,
    tier4: 84,
    DominantNPS: 'Goodwin LLC',
    abv: 4
  },
  {
    store: 'Reichert, Lemke and Nitzsche',
    brand: 'Feest LLC',
    city: 'La Foa',
    tier1: 13,
    tier2: 64,
    tier3: 55,
    tier4: 91,
    DominantNPS: 'Schimmel and Sons',
    abv: 87
  },
  {
    store: "O'Keefe and Sons",
    brand: 'Blick, Cole and Pagac',
    city: 'Rabāţ-e Sangī-ye Pā’īn',
    tier1: 14,
    tier2: 73,
    tier3: 92,
    tier4: 35,
    DominantNPS: 'Jacobi-Witting',
    abv: 21
  },
  {
    store: 'Gleichner Group',
    brand: 'Wilderman, Hintz and Hansen',
    city: 'Engel’s',
    tier1: 1,
    tier2: 38,
    tier3: 4,
    tier4: 92,
    DominantNPS: 'Renner, Gislason and Haley',
    abv: 75
  },
  {
    store: 'Purdy, Kuhn and Kilback',
    brand: 'Prohaska-Moen',
    city: 'Södra Sandby',
    tier1: 72,
    tier2: 87,
    tier3: 63,
    tier4: 49,
    DominantNPS: 'McClure, Jenkins and Nienow',
    abv: 77
  },
  {
    store: 'Barrows-Dooley',
    brand: 'Predovic Group',
    city: 'Xinmin',
    tier1: 47,
    tier2: 84,
    tier3: 58,
    tier4: 63,
    DominantNPS: 'Altenwerth, Gleichner and Pouros',
    abv: 66
  }
];
