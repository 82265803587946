/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { getJourney } from '../../../../services/journey-engine.service';
import HeaderImage from '../components/Header/HeaderImage';
import JourneyListItem from '../components/JourneyListItem/JourneyListItem';
import HeadRow from '../components/HeadRow/HeadRow';
import JourneyFilters from '../components/JourneyFilters/JourneyFilters';
import * as journeyList from './JourneyList.module.css';
import _ from 'lodash';
import JourneyActions from '../components/JourneyActions/JourneyActions';
import JourneyBreadcrumbs from '../components/JourneyBreadcrumbs/JourneyBreadcrumbs';
import JourneyCreate from '../components/JourneyCreate/JourneyCreate';
import { PaginationBar } from '../../../PaginationBar/PaginationBar';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

export default function JourneyList() {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const totalPages = Math.ceil(totalCount / limit);
  const [journeyEngines, setJourneyEngines] = useState([]);
  const [isRunning, setIsRunning] = useState('ALL');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [searchText, setSearchText] = useState();

  const getFilter = () => {
    return {
      page: page * limit,
      limit: limit,
      isRunning: isRunning,
      startDate: startDate,
      endDate: endDate,
      searchText: searchText
    };
  };

  const printRef = React.useRef();

  const saveAsPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('journeys.pdf');
  };

  const limitOptions = [10, 25, 50, 100];

  const handleOnClickNextTable = event => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const handleOnClickPreviousTable = event => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleOnLimitChange = event => {
    setLimit(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    async function getJourneyEngines() {
      const { totalHits, matches } = await getJourney(getFilter());
      setTotalCount(totalHits);
      setJourneyEngines(matches);
    }
    getJourneyEngines();
  }, [page, limit, isRunning, startDate, endDate, searchText]);

  return (
    <div>
      <HeaderImage />
      <JourneyBreadcrumbs />
      <JourneyCreate />
      <div className={journeyList.journeyContainer}>
        <JourneyActions totalJourneys={totalCount} saveAsPdf={saveAsPdf} />
        <JourneyFilters
          isRunning={isRunning}
          setIsRunning={setIsRunning}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          searchText={searchText}
          setSearchText={setSearchText}
        />
        {!_.isEmpty(journeyEngines) ? (
          <div ref={printRef}>
            <HeadRow />
            {_.map(journeyEngines, j => (
              <JourneyListItem journey={j.source} key={j.id} />
            ))}
          </div>
        ) : (
          <div className={journeyList.nda}>No Data Available</div>
        )}
      </div>
      <PaginationBar
        currentPage={page}
        totalPages={totalPages}
        handleOnClickNext={handleOnClickNextTable}
        handleOnClickPrevious={handleOnClickPreviousTable}
        rowsPerPage={limit}
        rowsPerPageOptions={limitOptions}
        handleOnChangeRowsPerPage={handleOnLimitChange}
      />
    </div>
  );
}
