/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import classes from './InputRow.module.css';

const CustomInput = ({ detail, setDetail }) => {
  if (detail.type === 'date') {
    detail.type = 'text';
  }

  const [inputValue, setInputValue] = useState(detail.value);

  useEffect(() => {
    setInputValue(detail.value);
  });
  return (
    <div key={detail.name} className={classes.container}>
      <span className={classes.title}>{detail.label || detail.name}</span>
      <input
        className={classes.customInput}
        type={detail.type}
        value={inputValue}
        onChange={event => {
          setDetail(event.target.value);
          setInputValue(event.target.value);
        }}
      />
    </div>
  );
};

export default CustomInput;
