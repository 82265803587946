import React from 'react';
import moment from 'moment';
import { Page, View, Image, StyleSheet, Text, Font } from '@react-pdf/renderer';
import Warranty from './warranty';
import { GetBarCode } from '../utils';
import RupeeFit from '../../assets/rupeeFit.png';
import Disclimer from '../../assets/disclimer.png';
import fonts from '../../assets/Fonts';
import {
  truncatedNumberFloat,
  getSplitDescription,
  truncateString,
  roundOffEffectivePrice
} from '../../../../utils/index';
import _, { lowerCase } from 'lodash';
import { UNITS } from '../../constants';

Font.register(fonts);

const styles = StyleSheet.create({
  card: {
    fontFamily: 'OpenSans',
    height: '297mm',
    width: '210mm'
  },
  qrwithname: {
    display: 'flex',
    flexDirection: 'row',
    height: '84.8mm',
    borderBottom: '1 solid #000000'
  },
  qrcode: {
    width: '80.8mm',
    height: '80.8mm',
    padding: '2mm 1mm'
  },
  qrcodeBlock: {
    width: '84.8mm',
    height: '84.8mm',
    padding: '2mm 1mm',
    borderRight: '1 solid #000000'
  },
  productname: {
    // fontFamily: 'OpenSans',
    // fontWeight: 'normal',
    fontSize: '22.6pt',
    color: 'black',
    width: '120mm',
    height: '90.8mm',
    margin: '4mm 4mm'
  },
  descriptionblock: {
    height: '145px',
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid #000000'
  },
  description: {
    width: '301px',
    fontSize: '15.96pt',
    padding: '6mm 5mm',
    lineHeight: '5rem'
  },
  deals: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  mrpText: {
    fontWeight: 'bold',
    color: 'black'
  },
  rupeeSmall: {
    width: '7.4mm',
    height: '10mm',
    top: 11.88
  },
  mrp: { fontSize: '28.83pt', lineHeight: '12px' },
  midBlock: {
    borderTop: '1 solid #000000',
    borderBottom: '1 solid #000000'
  },
  priceBlock: {
    width: '210mm',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '192px'
  },
  rupeeEffectivePrice: {
    width: '8mm'
  },
  rupeeicon: {
    fontSize: '36pt',
    width: '10mm',
    top: 20,
    paddingLeft: '2mm'
  },
  price: {
    fontFamily: 'OpenSans',
    fontSize: '130pt',
    textAlign: 'left',
    fontWeight: 'bold',
    color: 'black',
    paddingLeft: '8mm',
    top: -22,
    lineHeight: 1
  },
  inclTax: {
    fontSize: '28.9pt',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },

  warrantyText: {
    fontSize: '12.74pt',
    color: '#1A1818',
    opacity: '1'
  },
  barcodeBlock: {
    paddingTop: '6mm',
    width: '70mm',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'right'
  },
  barcode: {
    width: '57mm'
  },
  // Footer
  footer: {
    padding: '4mm 4mm '
  },
  dateDetails: {
    display: 'flex',
    flexDirection: 'row',
    top: 10
  },
  itemCode: {
    paddingTop: '13mm',
    fontSize: '13pt',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  lineHeight: { height: '10mm' },
  savetext: {
    width: '301px',
    height: '50px',
    backgroundColor: '#818386',
    borderBottom: '1 solid #000000'
  },

  saveblock: {
    width: '301px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderLeft: '1 solid #000000'
  },
  saveprice: {
    width: '301px',
    height: '95px',
    backgroundColor: '#1A1818',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  disclimer: {
    width: '4.75mm',
    height: '4.75mm',
    top: -4,
    left: 8
  }
});

const A4PortraitV3 = ({ data, count, ...rest }) => {
  return (
    <Page size="A4" style={styles.page}>
      {count &&
        count?.map(id => (
          <View key={id}>
            {data?.map(item => {
              return (
                <View style={styles.card} key={item?.id}>
                  <View style={styles.qrwithname}>
                    <View style={styles.qrcodeBlock}>
                      <Image src={item.image} style={styles.qrcode} />
                    </View>
                    <View style={styles.productname}>
                      <Text>
                        {truncateString(item?.product?.masterData?.name, 130)}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.midBlock}>
                    <View style={styles.priceBlock}>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          left: -4
                        }}
                      >
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                          <Text style={styles.rupeeicon}>
                            <Image src={RupeeFit} />
                          </Text>
                          <View
                            style={{ display: 'flex', flexDirection: 'row' }}
                          >
                            <Text style={styles.price}>
                              {truncatedNumberFloat(item?.product?.mop, 6)}
                            </Text>
                            <Text
                              style={{
                                fontSize: '18.8pt',
                                top: 20,
                                fontWeight: 'bold'
                              }}
                            >
                              *
                            </Text>
                          </View>
                        </View>
                        <View style={styles.inclTax}>
                          <Text
                            style={{
                              fontSize: '18.8pt',
                              fontWeight: 'bold',
                              marginTop: '0px',
                              top: -12
                            }}
                          >
                            *
                          </Text>
                          <Text style={{ fontSize: '36pt', top: -10 }}>
                            (Incl. Taxes)
                          </Text>
                        </View>
                      </View>
                    </View>
                    {item?.perUnitRate && (
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '61px',
                          fontSize: '38pt',
                          fontWeight: 'bold'
                        }}
                      >
                        <Text>{UNITS[lowerCase(item?.unit)]}</Text>
                        <Image
                          src={RupeeFit}
                          style={styles?.rupeeEffectivePrice}
                        />
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                          <Text>
                            {roundOffEffectivePrice(item?.perUnitRate)}
                          </Text>
                          <Text style={{ paddingTop: '2mm', fontSize: '26pt' }}>
                            *
                          </Text>
                        </View>
                      </View>
                    )}
                  </View>
                  <View style={styles.descriptionblock}>
                    <View style={styles.description}>
                      <View>
                        {getSplitDescription(item.description, 4).map(
                          (line, index) => (
                            <Text key={index} style={{ paddingTop: '2mm' }}>
                              + {truncateString(line, 30)}
                            </Text>
                          )
                        )}
                      </View>
                    </View>
                  </View>
                  <View style={styles?.footer}>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '40mm'
                      }}
                    >
                      <Warranty warranty={item.warranty} styles={styles} />
                    </View>
                    <View
                      style={
                        item?.warranty
                          ? styles?.dateDetails
                          : { ...styles?.dateDetails, top: 125 }
                      }
                    >
                      <View style={styles.itemCode}>
                        <Text>
                          {`${item?.sku}  |  ${item.class}  |  A  |  ${moment(
                            item?.date
                          ).format('DD.MM.YYYY')}  |  ${moment().format(
                            'DD.MM.YYYY'
                          )}`}
                        </Text>
                        <View style={{ paddingTop: '3mm' }}>
                          <Image style={styles.disclimer} src={Disclimer} />
                        </View>
                      </View>

                      <View style={styles.barcodeBlock}>
                        <View style={styles.barcode}>
                          {_.isEmpty(item.barcode) ? (
                            <></>
                          ) : (
                            <Image src={GetBarCode(item?.barcode)} />
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              );
            })}
          </View>
        ))}
    </Page>
  );
};
export { A4PortraitV3 };
