import React, { useState, useEffect } from 'react';
import { range } from 'lodash';
import { CircularProgress } from '@material-ui/core';
import {
  ALLOWED_VERSION,
  LATEST_VERSION,
  LAYOUT,
  PAGETILE
} from '../constants';
import { Text, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import {
  PegHook,
  PegHookV2,
  PegHookFourDigit,
  WireBasket,
  A6Portrait,
  A6PortraitV2,
  A6LandscapeV2,
  A6Landscape,
  A4Portrait,
  A4PortraitV2,
  A4LandscapeV2,
  A4Landscape,
  IntegratedSelf,
  A5Portrait,
  A5PortraitV2,
  A5Landscape,
  A5LandscapeV2,
  WireBasketFourDigit,
  WireBasketV2
} from './layouts';
import { getQRs } from '../../../services/qr.service';
import _ from 'lodash';
import { transformLayoutBasedOnPrice } from '../../../utils';
import { serializePrice } from './utils/PriceCalculation';
import queryString from 'query-string';

// Create styles
const styles = StyleSheet.create({
  card: {
    top: '5px',
    margin: '6px 50px',
    height: '38mm',
    width: '175mm',
    flexDirection: 'row',
    border: '1 solid #1A1818'
  },
  qrcode: {
    borderRight: '1 solid #1A1818',
    height: '34.9mm',
    width: '34.9mm',
    justifyContent: 'center',
    textAlign: 'center'
  },
  priceDetails: {
    flexDirection: 'row'
  },
  deals: {
    width: '33mm'
  },
  hippoDeals: {
    height: '17mm',
    width: '17mm'
  },
  mrpText: {
    fontSize: '12.58pt',
    fontWeight: 'bold',
    color: 'black'
  },
  mrp: { fontSize: '12', textAlign: 'center', lineHeight: '12px' },
  priceSection: {
    width: '250px',
    borderRight: '1 solid #1A1818'
  },
  amount: {
    flexDirection: 'row'
  },
  rupee: {
    fontSize: '33.9pt',
    width: '20px'
  },
  price: {
    fontSize: '68.8pt',
    fontWeight: 'bold',
    color: 'black',
    top: 0
  },
  descsection: {
    width: '389px',
    flexDirection: 'row',
    borderTop: '1 solid #1A1818'
  },
  inclTax: {
    fontSize: '12',
    textAlign: 'right'
  },
  descSection: {
    borderRight: '1 solid #1A1818',
    width: '290px'
  },
  desc: { fontSize: '7', padding: '2' },
  details: { fontSize: '7', padding: '2' },
  barcode: {
    width: '150px'
  },
  pageTitle: {
    position: 'absolute',
    fontSize: 7,
    top: 2,
    left: 50
  }
});
const sampleData = [
  {
    id: 7281,
    productId: 78994,
    layout: 'A4',
    orientation: 'LANDSCAPE',
    sku: '1000000027',
    description: '',
    barcode: '8718696722053',
    warranty: null,
    qrText: 'https://dalmia-ecomm.casaqa.ajira.tech/p/35958',
    class: '110106',
    date: '2021-05-13',
    version: 'V2',
    createdAt: '2021-03-01T09:45:01.728Z',
    updatedAt: '2021-05-13T04:55:43.723Z',
    rp: null,
    product: {
      price: '2900.0',
      casaId: 78994,
      id: '1000000027',
      tenantId: 194,
      tenantBrandBusinessId: 160,
      isActive: true,
      isCasaPublished: false,
      isWebPublished: false,
      masterData: {
        id: '1000000027',
        mop: '2000',
        mrp: '2900.0',
        sku: '1000000027',
        name:
          'PHILIPS 5W SQUARE ASTRA MAX PLUS LED - NATURAL WHITE METAL DOWNLIGHTER',
        images: [
          'https://commerce-connect.casaqa.ajira.tech/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaXNLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--8e1304e690a11f946470e5c3f687109dbbb3c132/1000000027_1.jpg'
        ],
        category: 'ELECTRICAL AND LIGHTING (ELT)',
        isActive: true,
        description:
          'PHILIPS 5W SQUARE ASTRA MAX PLUS LED - NATURAL WHITE METAL DOWNLIGHTER',
        productName:
          'PHILIPS 5W SQUARE ASTRA MAX PLUS LED - NATURAL WHITE METAL DOWNLIGHTER',
        subCategory: 'LIGHTING',
        redirectionUrl: '',
        parentProductId: 15927
      },
      extendedData: {
        id: '1000000027',
        mop: '2000',
        mrp: '2900.0',
        sku: '1000000027',
        name:
          'PHILIPS 5W SQUARE ASTRA MAX PLUS LED - NATURAL WHITE METAL DOWNLIGHTER',
        images: [
          'https://commerce-connect.casaqa.ajira.tech/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaXNLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--8e1304e690a11f946470e5c3f687109dbbb3c132/1000000027_1.jpg'
        ],
        category: 'ELECTRICAL AND LIGHTING (ELT)',
        isActive: true,
        description:
          'PHILIPS 5W SQUARE ASTRA MAX PLUS LED - NATURAL WHITE METAL DOWNLIGHTER',
        productName:
          'PHILIPS 5W SQUARE ASTRA MAX PLUS LED - NATURAL WHITE METAL DOWNLIGHTER',
        subCategory: 'LIGHTING',
        redirectionUrl: '',
        parentProductId: 15927
      },
      createdAt: '2021-03-01',
      updatedAt: '2021-05-13',
      mop: '2000',
      mrp: '2900.0',
      mopStartDate: null,
      mopEndDate: null
    }
  },
  {
    id: 9158,
    productId: 80883,
    layout: 'A6',
    orientation: 'LANDSCAPE',
    sku: '1000000031',
    description:
      'Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products',
    barcode: '8718696722138',
    warranty: '12 Years',
    qrText: 'https://dalmia-ecomm.casaqa.ajira.tech/p/38673',
    class: '110106',
    date: '2021-02-08',
    version: 'V2',
    createdAt: '2021-03-01T09:45:04.884Z',
    updatedAt: '2021-05-03T03:58:21.260Z',
    rp: null,
    product: {
      price: '356000',
      casaId: 80883,
      id: '1000000031',
      tenantId: 194,
      tenantBrandBusinessId: 160,
      isActive: true,
      isCasaPublished: false,
      isWebPublished: false,
      masterData: {
        id: '1000000031',
        mop: 222222,
        mrp: 356000,
        sku: '1000000031',
        name: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        images: [
          'https://commerce-connect.casaqa.ajira.tech/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lJIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0847c1b42c8d0095e5b6f0bd79327b1cbcbab792/6000007854_1.jpg'
        ],
        category: 'HARDWARE, KITCHEN AND TOOLS (HKT)',
        isActive: true,
        description: 'LIVSMART ALUMINIUM HANDLE - C2 224MM C2AH-224-AS',
        productName: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        subCategory: 'FURNITURE FITTINGS',
        redirectionUrl: '',
        parentProductId: 4106
      },
      extendedData: {
        id: '1000000031',
        mop: 222222,
        mrp: 356000,
        sku: '1000000031',
        name: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        images: [
          'https://commerce-connect.casaqa.ajira.tech/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lJIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0847c1b42c8d0095e5b6f0bd79327b1cbcbab792/6000007854_1.jpg'
        ],
        category: 'HARDWARE, KITCHEN AND TOOLS (HKT)',
        isActive: true,
        description: 'LIVSMART ALUMINIUM HANDLE - C2 224MM C2AH-224-AS',
        productName: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        subCategory: 'FURNITURE FITTINGS',
        redirectionUrl: '',
        parentProductId: 4106
      },
      createdAt: '2021-03-01',
      updatedAt: '2021-05-06',
      mop: '222222',
      mrp: '356000',
      mopStartDate: null,
      mopEndDate: null
    }
  },
  {
    id: 9253,
    productId: 80976,
    layout: 'A4',
    orientation: 'PORTRAIT',
    sku: '1000000026',
    description:
      'Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products',
    barcode: '8718696722039',
    warranty: '12 Years',
    qrText: 'https://dalmia-ecomm.casaqa.ajira.tech/p/38783',
    class: '110106',
    date: '2021-02-08',
    version: 'V2',
    createdAt: '2021-03-01T09:45:04.987Z',
    updatedAt: '2021-05-03T03:58:34.564Z',
    rp: null,
    product: {
      price: '356000',
      casaId: 80976,
      id: '1000000026',
      tenantId: 194,
      tenantBrandBusinessId: 160,
      isActive: true,
      isCasaPublished: false,
      isWebPublished: false,
      masterData: {
        id: '1000000026',
        mop: 222222,
        mrp: 356000,
        sku: '1000000026',
        name: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        images: [
          'https://commerce-connect.casaqa.ajira.tech/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lJIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0847c1b42c8d0095e5b6f0bd79327b1cbcbab792/6000007854_1.jpg'
        ],
        category: 'HARDWARE, KITCHEN AND TOOLS (HKT)',
        isActive: true,
        description: 'LIVSMART ALUMINIUM HANDLE - C2 224MM C2AH-224-AS',
        productName: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        subCategory: 'FURNITURE FITTINGS',
        redirectionUrl: '',
        parentProductId: 4106
      },
      extendedData: {
        id: '1000000026',
        mop: 222222,
        mrp: 356000,
        sku: '1000000026',
        name: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        images: [
          'https://commerce-connect.casaqa.ajira.tech/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lJIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0847c1b42c8d0095e5b6f0bd79327b1cbcbab792/6000007854_1.jpg'
        ],
        category: 'HARDWARE, KITCHEN AND TOOLS (HKT)',
        isActive: true,
        description: 'LIVSMART ALUMINIUM HANDLE - C2 224MM C2AH-224-AS',
        productName: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        subCategory: 'FURNITURE FITTINGS',
        redirectionUrl: '',
        parentProductId: 4106
      },
      createdAt: '2021-03-01',
      updatedAt: '2021-05-06',
      mop: '222222',
      mrp: '356000',
      mopStartDate: null,
      mopEndDate: null
    }
  },
  {
    id: 8772,
    productId: 80491,
    layout: 'WIREBASKET',
    orientation: 'NA',
    sku: '1000000024',
    description:
      'Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products',
    barcode: '8718696168950',
    warranty: '12 Years',
    qrText: 'https://dalmia-ecomm.casaqa.ajira.tech/p/38135',
    class: '110106',
    date: '2021-02-08',
    version: 'V2',
    createdAt: '2021-03-01T09:45:04.214Z',
    updatedAt: '2021-05-03T03:57:35.665Z',
    rp: null,
    product: {
      price: '356000',
      casaId: 80491,
      id: '1000000024',
      tenantId: 194,
      tenantBrandBusinessId: 160,
      isActive: true,
      isCasaPublished: false,
      isWebPublished: false,
      masterData: {
        id: '1000000024',
        mop: 222222,
        mrp: 356000,
        sku: '1000000024',
        name: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        images: [
          'https://commerce-connect.casaqa.ajira.tech/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lJIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0847c1b42c8d0095e5b6f0bd79327b1cbcbab792/6000007854_1.jpg'
        ],
        category: 'HARDWARE, KITCHEN AND TOOLS (HKT)',
        isActive: true,
        description: 'LIVSMART ALUMINIUM HANDLE - C2 224MM C2AH-224-AS',
        productName: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        subCategory: 'FURNITURE FITTINGS',
        redirectionUrl: '',
        parentProductId: 4106
      },
      extendedData: {
        id: '1000000024',
        mop: 222222,
        mrp: 356000,
        sku: '1000000024',
        name: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        images: [
          'https://commerce-connect.casaqa.ajira.tech/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lJIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0847c1b42c8d0095e5b6f0bd79327b1cbcbab792/6000007854_1.jpg'
        ],
        category: 'HARDWARE, KITCHEN AND TOOLS (HKT)',
        isActive: true,
        description: 'LIVSMART ALUMINIUM HANDLE - C2 224MM C2AH-224-AS',
        productName: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        subCategory: 'FURNITURE FITTINGS',
        redirectionUrl: '',
        parentProductId: 4106
      },
      createdAt: '2021-03-01',
      updatedAt: '2021-05-06',
      mop: '222222',
      mrp: '356000',
      mopStartDate: null,
      mopEndDate: null
    }
  },
  {
    id: 3650,
    productId: 75362,
    layout: 'A5',
    orientation: 'PORTRAIT',
    sku: '1000000028',
    description:
      'Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products',
    barcode: '8718696722077',
    warranty: '12 Years',
    qrText: 'https://dalmia-ecomm.casaqa.ajira.tech/p/31589',
    class: '110106',
    date: '2021-02-08',
    version: 'V2',
    createdAt: '2021-03-01T09:44:55.515Z',
    updatedAt: '2021-05-03T03:48:05.255Z',
    rp: null,
    product: {
      price: '356000',
      casaId: 75362,
      id: '1000000028',
      tenantId: 194,
      tenantBrandBusinessId: 160,
      isActive: true,
      isCasaPublished: false,
      isWebPublished: false,
      masterData: {
        id: '1000000028',
        mop: 222222,
        mrp: 356000,
        sku: '1000000028',
        name: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        images: [
          'https://commerce-connect.casaqa.ajira.tech/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lJIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0847c1b42c8d0095e5b6f0bd79327b1cbcbab792/6000007854_1.jpg'
        ],
        category: 'HARDWARE, KITCHEN AND TOOLS (HKT)',
        isActive: true,
        description: 'LIVSMART ALUMINIUM HANDLE - C2 224MM C2AH-224-AS',
        productName: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        subCategory: 'FURNITURE FITTINGS',
        redirectionUrl: '',
        parentProductId: 4106
      },
      extendedData: {
        id: '1000000028',
        mop: 222222,
        mrp: 356000,
        sku: '1000000028',
        name: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        images: [
          'https://commerce-connect.casaqa.ajira.tech/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lJIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0847c1b42c8d0095e5b6f0bd79327b1cbcbab792/6000007854_1.jpg'
        ],
        category: 'HARDWARE, KITCHEN AND TOOLS (HKT)',
        isActive: true,
        description: 'LIVSMART ALUMINIUM HANDLE - C2 224MM C2AH-224-AS',
        productName: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        subCategory: 'FURNITURE FITTINGS',
        redirectionUrl: '',
        parentProductId: 4106
      },
      createdAt: '2021-03-01',
      updatedAt: '2021-05-06',
      mop: '222222',
      mrp: '356000',
      mopStartDate: null,
      mopEndDate: null
    }
  },
  {
    id: 8442,
    productId: 80161,
    layout: 'A6',
    orientation: 'PORTRAIT',
    sku: '1000000030',
    description:
      'Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products',
    barcode: '8718696722114',
    warranty: '12 Years',
    qrText: 'https://dalmia-ecomm.casaqa.ajira.tech/p/37661',
    class: '110106',
    date: '2021-02-08',
    version: 'V2',
    createdAt: '2021-03-01T09:45:03.691Z',
    updatedAt: '2021-05-03T03:57:00.669Z',
    rp: null,
    product: {
      price: '356000',
      casaId: 80161,
      id: '1000000030',
      tenantId: 194,
      tenantBrandBusinessId: 160,
      isActive: true,
      isCasaPublished: false,
      isWebPublished: false,
      masterData: {
        id: '1000000030',
        mop: 222222,
        mrp: 356000,
        sku: '1000000030',
        name: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        images: [
          'https://commerce-connect.casaqa.ajira.tech/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lJIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0847c1b42c8d0095e5b6f0bd79327b1cbcbab792/6000007854_1.jpg'
        ],
        category: 'HARDWARE, KITCHEN AND TOOLS (HKT)',
        isActive: true,
        description: 'LIVSMART ALUMINIUM HANDLE - C2 224MM C2AH-224-AS',
        productName: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        subCategory: 'FURNITURE FITTINGS',
        redirectionUrl: '',
        parentProductId: 4106
      },
      extendedData: {
        id: '1000000030',
        mop: 222222,
        mrp: 356000,
        sku: '1000000030',
        name: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        images: [
          'https://commerce-connect.casaqa.ajira.tech/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lJIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0847c1b42c8d0095e5b6f0bd79327b1cbcbab792/6000007854_1.jpg'
        ],
        category: 'HARDWARE, KITCHEN AND TOOLS (HKT)',
        isActive: true,
        description: 'LIVSMART ALUMINIUM HANDLE - C2 224MM C2AH-224-AS',
        productName: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        subCategory: 'FURNITURE FITTINGS',
        redirectionUrl: '',
        parentProductId: 4106
      },
      createdAt: '2021-03-01',
      updatedAt: '2021-05-06',
      mop: '222222',
      mrp: '356000',
      mopStartDate: null,
      mopEndDate: null
    }
  },
  {
    id: 796,
    productId: 72515,
    layout: 'PEGHOOK',
    orientation: 'NA',
    sku: '1000000023',
    description:
      'Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products',
    barcode: '8718696168943',
    warranty: '12 Years',
    qrText: 'https://dalmia-ecomm.casaqa.ajira.tech/p/27788',
    class: '110106',
    date: '2021-04-01',
    version: 'V2',
    createdAt: '2021-03-01T09:44:50.424Z',
    updatedAt: '2021-05-03T03:42:58.408Z',
    rp: null,
    product: {
      price: '356000',
      casaId: 72515,
      id: '1000000023',
      tenantId: 194,
      tenantBrandBusinessId: 160,
      isActive: true,
      isCasaPublished: false,
      isWebPublished: false,
      masterData: {
        id: '1000000023',
        mop: 222222,
        mrp: 356000,
        sku: '1000000023',
        name: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        images: [
          'https://commerce-connect.casaqa.ajira.tech/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lJIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0847c1b42c8d0095e5b6f0bd79327b1cbcbab792/6000007854_1.jpg'
        ],
        category: 'HARDWARE, KITCHEN AND TOOLS (HKT)',
        isActive: true,
        description: 'LIVSMART ALUMINIUM HANDLE - C2 224MM C2AH-224-AS',
        productName: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        subCategory: 'FURNITURE FITTINGS',
        redirectionUrl: '',
        parentProductId: 4106
      },
      extendedData: {
        id: '1000000023',
        mop: 222222,
        mrp: 356000,
        sku: '1000000023',
        name: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        images: [
          'https://commerce-connect.casaqa.ajira.tech/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lJIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0847c1b42c8d0095e5b6f0bd79327b1cbcbab792/6000007854_1.jpg'
        ],
        category: 'HARDWARE, KITCHEN AND TOOLS (HKT)',
        isActive: true,
        description: 'LIVSMART ALUMINIUM HANDLE - C2 224MM C2AH-224-AS',
        productName: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        subCategory: 'FURNITURE FITTINGS',
        redirectionUrl: '',
        parentProductId: 4106
      },
      createdAt: '2021-03-01',
      updatedAt: '2021-05-06',
      mop: '222222',
      mrp: '356000',
      mopStartDate: null,
      mopEndDate: null
    }
  },
  {
    id: 1376,
    productId: 73095,
    layout: 'INTEGRATEDSHELF',
    orientation: 'NA',
    sku: '1000000025',
    description:
      'Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products',
    barcode: '8718696168936',
    warranty: '12 Years',
    qrText: 'https://dalmia-ecomm.casaqa.ajira.tech/p/24783',
    class: '110106',
    date: '2021-02-08',
    version: 'V2',
    createdAt: '2021-03-01T09:44:51.585Z',
    updatedAt: '2021-05-03T03:43:57.557Z',
    rp: null,
    product: {
      price: '356000',
      casaId: 73095,
      id: '1000000025',
      tenantId: 194,
      tenantBrandBusinessId: 160,
      isActive: true,
      isCasaPublished: false,
      isWebPublished: false,
      masterData: {
        id: '1000000025',
        mop: 222222,
        mrp: 356000,
        sku: '1000000025',
        name: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        images: [
          'https://commerce-connect.casaqa.ajira.tech/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lJIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0847c1b42c8d0095e5b6f0bd79327b1cbcbab792/6000007854_1.jpg'
        ],
        category: 'HARDWARE, KITCHEN AND TOOLS (HKT)',
        isActive: true,
        description: 'LIVSMART ALUMINIUM HANDLE - C2 224MM C2AH-224-AS',
        productName: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        subCategory: 'FURNITURE FITTINGS',
        redirectionUrl: '',
        parentProductId: 4106
      },
      extendedData: {
        id: '1000000025',
        mop: 222222,
        mrp: 356000,
        sku: '1000000025',
        name: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        images: [
          'https://commerce-connect.casaqa.ajira.tech/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lJIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0847c1b42c8d0095e5b6f0bd79327b1cbcbab792/6000007854_1.jpg'
        ],
        category: 'HARDWARE, KITCHEN AND TOOLS (HKT)',
        isActive: true,
        description: 'LIVSMART ALUMINIUM HANDLE - C2 224MM C2AH-224-AS',
        productName: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        subCategory: 'FURNITURE FITTINGS',
        redirectionUrl: '',
        parentProductId: 4106
      },
      createdAt: '2021-03-01',
      updatedAt: '2021-05-06',
      mop: '222222',
      mrp: '356000',
      mopStartDate: null,
      mopEndDate: null
    }
  },
  {
    id: 2917,
    productId: 74642,
    layout: 'A5',
    orientation: 'LANDSCAPE',
    sku: '1000000029',
    description:
      'Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products|Dalmia has a wide range of products',
    barcode: '8718696722091',
    warranty: '12 Years',
    qrText: 'https://dalmia-ecomm.casaqa.ajira.tech/p/29763',
    class: '110106',
    date: '2021-02-08',
    version: 'V2',
    createdAt: '2021-03-01T09:44:54.266Z',
    updatedAt: '2021-05-03T03:46:51.654Z',
    rp: null,
    product: {
      price: '356000',
      casaId: 74642,
      id: '1000000029',
      tenantId: 194,
      tenantBrandBusinessId: 160,
      isActive: true,
      isCasaPublished: false,
      isWebPublished: false,
      masterData: {
        id: '1000000029',
        mop: 222222,
        mrp: 356000,
        sku: '1000000029',
        name: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        images: [
          'https://commerce-connect.casaqa.ajira.tech/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lJIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0847c1b42c8d0095e5b6f0bd79327b1cbcbab792/6000007854_1.jpg'
        ],
        category: 'HARDWARE, KITCHEN AND TOOLS (HKT)',
        isActive: true,
        description: 'LIVSMART ALUMINIUM HANDLE - C2 224MM C2AH-224-AS',
        productName: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        subCategory: 'FURNITURE FITTINGS',
        redirectionUrl: '',
        parentProductId: 4106
      },
      extendedData: {
        id: '1000000029',
        mop: 222222,
        mrp: 356000,
        sku: '1000000029',
        name: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        images: [
          'https://commerce-connect.casaqa.ajira.tech/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb1lJIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0847c1b42c8d0095e5b6f0bd79327b1cbcbab792/6000007854_1.jpg'
        ],
        category: 'HARDWARE, KITCHEN AND TOOLS (HKT)',
        isActive: true,
        description: 'LIVSMART ALUMINIUM HANDLE - C2 224MM C2AH-224-AS',
        productName: 'LIVSMART ALMINUM HDL-C2 224MM C2AH-224AS',
        subCategory: 'FURNITURE FITTINGS',
        redirectionUrl: '',
        parentProductId: 4106
      },
      createdAt: '2021-03-01',
      updatedAt: '2021-05-06',
      mop: '222222',
      mrp: '356000',
      mopStartDate: null,
      mopEndDate: null
    }
  }
];

const PdfDocumentSample = props => {
  const [count] = useState(localStorage.getItem('count') || 1);
  const rangedCount = range(count);
  const [filteredData, setFilteredData] = useState();
  const query = queryString.parse(window.location.search);
  const maskVersionsOfLayout = (array, layout) => {
    const allowedVersions = ALLOWED_VERSION[layout];
    const defaultVersion = LATEST_VERSION[layout];
    return _.map(array, element => {
      if (!allowedVersions.includes(element.version)) {
        element.version = defaultVersion;
      }
      return element;
    });
  };
  useEffect(() => {
    let data = sampleData;
    const start = query.start || '';
    const end = query.end || '';
    data = _.slice(data, parseInt(start), parseInt(end));
    getQRs(data.map(lab => lab.qrText)).then(result => {
      const urlHash = result.reduce((rv, cv) => {
        rv[cv.text] = cv.url;
        return rv;
      }, {});
      let dataWithImages = data.map(label => {
        return {
          ...label,
          image: urlHash[label.qrText]
        };
      });
      dataWithImages = transformLayoutBasedOnPrice(dataWithImages);
      setFilteredData(_.groupBy(dataWithImages, 'layout'));
    });
  }, [query.end, query.start]);

  const getPageTitle = layout => {
    return <Text style={styles.pageTitle}>{PAGETILE[layout]}</Text>;
  };

  if (!filteredData)
    return (
      <div
        style={{
          color: '#F2755F',
          width: '100%',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress color="inherit" />
        <div style={{ paddingTop: '2rem', fontSize: '24px' }}>
          Your labels are getting rendered, please wait...
        </div>
      </div>
    );
  const groupByVersions = (filteredData, layout) => {
    filteredData = maskVersionsOfLayout(filteredData, layout);
    return _.mapValues(_.groupBy(filteredData, 'version'), versions =>
      versions.map(version => _.omit(version, 'version'))
    );
  };
  const getLayoutFromPicker = (labelData, layout, version, format) => {
    const layoutPicker = {
      [LAYOUT.INTEGRATEDSHELF]: {
        NA: {
          V1: (
            <IntegratedSelf
              title={getPageTitle(LAYOUT.INTEGRATEDSHELF)}
              data={labelData}
              version={'V1'}
              count={count}
            />
          ),
          V2: (
            <IntegratedSelf
              title={getPageTitle(LAYOUT.INTEGRATEDSHELF)}
              data={labelData}
              version={'V2'}
              count={count}
            />
          )
        }
      },
      [LAYOUT.PEGHOOK]: {
        NA: {
          V1: <PegHook data={labelData} count={rangedCount} />,
          V2: <PegHookV2 data={labelData} count={rangedCount} />
        }
      },
      [LAYOUT.PEGHOOKFOURDIGIT]: {
        NA: {
          V1: <PegHookFourDigit data={labelData} count={rangedCount} />
        }
      },
      [LAYOUT.WIREBASKETFOURDIGIT]: {
        NA: {
          V1: <WireBasketFourDigit data={labelData} count={rangedCount} />
        }
      },
      [LAYOUT.WIREBASKET]: {
        NA: {
          V1: <WireBasket data={labelData} count={rangedCount} />,
          V2: <WireBasketV2 data={labelData} count={rangedCount} />
        }
      },
      [LAYOUT.A5]: {
        LANDSCAPE: {
          V1: (
            <A5Landscape
              title={getPageTitle(LAYOUT.A5)}
              data={labelData.filter(item => item.orientation === 'LANDSCAPE')}
              count={rangedCount}
            />
          ),
          V2: (
            <A5LandscapeV2
              title={getPageTitle(LAYOUT.A5)}
              data={labelData.filter(item => item.orientation === 'LANDSCAPE')}
              count={rangedCount}
            />
          )
        },
        PORTRAIT: {
          V1: (
            <A5Portrait
              title={getPageTitle(LAYOUT.A5)}
              data={labelData.filter(item => item.orientation === 'PORTRAIT')}
              count={rangedCount}
            />
          ),
          V2: (
            <A5PortraitV2
              title={getPageTitle(LAYOUT.A5)}
              data={labelData.filter(item => item.orientation === 'PORTRAIT')}
              count={rangedCount}
            />
          )
        }
      },
      [LAYOUT.A6]: {
        LANDSCAPE: {
          V1: (
            <A6Landscape
              data={labelData.filter(item => item.orientation === 'LANDSCAPE')}
              count={rangedCount}
            />
          ),
          V2: (
            <A6LandscapeV2
              data={labelData.filter(item => item.orientation === 'LANDSCAPE')}
              count={rangedCount}
            />
          )
        },
        PORTRAIT: {
          V1: (
            <A6Portrait
              data={labelData.filter(item => item.orientation === 'PORTRAIT')}
              count={rangedCount}
            />
          ),
          V2: (
            <A6PortraitV2
              data={labelData.filter(item => item.orientation === 'PORTRAIT')}
              count={rangedCount}
            />
          )
        }
      },
      [LAYOUT.A4]: {
        LANDSCAPE: {
          V1: (
            <A4Landscape
              data={labelData.filter(item => item.orientation === 'LANDSCAPE')}
              count={rangedCount}
            />
          ),
          V2: (
            <A4LandscapeV2
              data={labelData.filter(item => item.orientation === 'LANDSCAPE')}
              count={rangedCount}
            />
          )
        },
        PORTRAIT: {
          V1: (
            <A4Portrait
              data={labelData.filter(item => item.orientation === 'PORTRAIT')}
              count={rangedCount}
            />
          ),
          V2: (
            <A4PortraitV2
              data={labelData.filter(item => item.orientation === 'PORTRAIT')}
              count={rangedCount}
            />
          )
        }
      }
    };
    return layoutPicker[layout][format][version];
  };
  const getVersionSpecificLayout = (filteredData, layout, format) => {
    filteredData = groupByVersions(filteredData, layout);
    return _.map(filteredData, (value, key) => {
      value = serializePrice(value);
      return getLayoutFromPicker(value, layout, key, format);
    });
  };
  return (
    <PDFViewer width="100%" style={{ height: '100vh' }}>
      <Document>
        {_.map(filteredData, (value, key) => {
          if (['A4', 'A5', 'A6'].includes(key)) {
            value = _.groupBy(value, 'orientation');
            return _.map(value, (labelProduct, orientation) => {
              return getVersionSpecificLayout(labelProduct, key, orientation);
            });
          } else {
            return getVersionSpecificLayout(value, key, 'NA');
          }
        })}
      </Document>
    </PDFViewer>
  );
};
export default PdfDocumentSample;
