import React from 'react';
import { TextField } from '@material-ui/core';
import classes from './InputField.module.scss';

type InputFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
  color?: 'primary' | 'secondary';
};

const getInputType = (type: string) => {
  const textInputs = ['text', 'number', 'currency', 'link', 'computed'];
  const multiLineInputs = ['text area', 'html_content'];
  const isMultiLine = multiLineInputs.includes(type);
  return textInputs.includes(type) ? 'text' : isMultiLine ? 'multi' : 'file';
};

const InputField: React.FC<InputFieldProps> = ({
  size: _,
  label,
  type,
  fullWidth = true,
  ...props
}) => {
  const { name, style = {}, disabled = false } = props;
  return (
    <div className={classes.container}>
      <label htmlFor={name}>{label}</label>
      <TextField
        variant="standard"
        id={name}
        type={getInputType(type as string)}
        className={classes.input}
        multiline={getInputType(type as string) === 'multi'}
        fullWidth={fullWidth}
        style={{
          ...style,
          color: disabled ? '#F26E5F' : 'inherit',
          backgroundColor: '#fff'
        }}
        {...props}
      />
    </div>
  );
};
export default InputField;
