import React, { FC, useCallback, useEffect } from 'react';
import _ from 'lodash';
import {
  TOOL_CANCLE_SECTION,
  TOOL_DELETE_SECTION,
  TOOL_EDIT_SECTION,
  TOOL_NEW_SECTION,
  TOOL_SAVE_SECTION
} from '../../Editors/SidePanel/EditorPanel';

interface EditorEventHandlerProps {
  setIsCropDisabled: (state: boolean) => void;
  isCropDisabled: boolean;
  isEditDisabled: boolean;
  setIsKeepSelectionEnabled: (state: boolean) => void;
  setIsEditDisabled: (state: boolean) => void;
  addSubArea: (selectionState: any) => void;
  onCancelCrop: () => void;
  onDeleteCrop: () => void;
  customKey: string;
  setTaggerImagePanelInfo: (info: any) => void;
  taggerImagePanelInfo: any;
}
export const EditorEventHandler: FC<EditorEventHandlerProps> = ({
  setIsCropDisabled,
  setIsEditDisabled,
  isEditDisabled,
  isCropDisabled,
  setIsKeepSelectionEnabled,
  addSubArea,
  onCancelCrop,
  onDeleteCrop,
  customKey,
  children,
  setTaggerImagePanelInfo,
  taggerImagePanelInfo
}) => {
  const performToolOperations = useCallback(
    (actionType: string) => {
      switch (actionType) {
        case TOOL_NEW_SECTION:
          setIsCropDisabled(false);
          setIsKeepSelectionEnabled(false);
          break;
        case TOOL_EDIT_SECTION:
          setIsEditDisabled(!isEditDisabled);
          setIsKeepSelectionEnabled(true);
          break;
      }
    },
    [
      isEditDisabled,
      setIsCropDisabled,
      setIsEditDisabled,
      setIsKeepSelectionEnabled
    ]
  );
  useEffect(() => {
    const resetTaggerImagePanelInfo = () => {
      setTaggerImagePanelInfo({
        ...taggerImagePanelInfo,
        actionState: 'complete',
        message: {},
        actionType: '',
        key: ''
      });
    };
    if (_.get(taggerImagePanelInfo, 'actionState') === 'pending') {
      if (_.isEqual(customKey, taggerImagePanelInfo.key)) {
        switch (taggerImagePanelInfo.actionType) {
          case TOOL_SAVE_SECTION:
            addSubArea(taggerImagePanelInfo.message);
            break;
          case TOOL_CANCLE_SECTION:
            onCancelCrop();
            break;
          case TOOL_DELETE_SECTION:
            onDeleteCrop();
            break;
        }
      }
      if (
        _.isEmpty(taggerImagePanelInfo.key) ||
        _.isEqual(customKey, taggerImagePanelInfo.key)
      ) {
        switch (taggerImagePanelInfo.actionType) {
          case TOOL_EDIT_SECTION:
            performToolOperations(taggerImagePanelInfo.actionType);
            break;
          case TOOL_NEW_SECTION:
            performToolOperations(taggerImagePanelInfo.actionType);
            break;
        }
      }
      resetTaggerImagePanelInfo();
    }
  }, [
    addSubArea,
    customKey,
    onCancelCrop,
    onDeleteCrop,
    performToolOperations,
    setTaggerImagePanelInfo,
    taggerImagePanelInfo
  ]);
  return <>{children}</>;
};
