/* eslint-disable @typescript-eslint/explicit-function-return-type */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { SectionFieldParams } from '../../../models/SectionField';

import SectionProps from '../../../models/SectionProps';
import ComputedFields from '../../../utils/ComputedFields';
import utils from '../../../utils/utils';
import Heading from '../../Heading/Heading';
import StripedTable from '../../StrippedTable/StripedTable';
import classes from './HSNSummary.module.css';

const HSNSummary: React.FC<SectionProps> = ({ data, billData }) => {
  const [headings, setHeadings] = useState<string[]>([]);
  const products = _.get(billData, 'bill.products', []);

  useEffect(() => {
    setHeadings(_.map(data.fields, field => field.label));
  }, [data]);

  function renderFinalRow(field: SectionFieldParams) {
    const getter = field.getter;
    if (!ComputedFields.canShowInHSNFinalRow[getter]) {
      return <td></td>;
    }
    return <td>{utils.getHSNComputedField(field, products)}</td>;
  }

  const hsnData = _.values(ComputedFields.groupByHSN(billData.bill));

  if (_.isEmpty(hsnData) || _.isEmpty(data.fields)) return null;

  return (
    <div className={classes.container}>
      <Heading heading={data.heading || 'HSN Summary'} />
      <StripedTable
        headings={['HSN', ...headings]}
        configFields={data.fields}
        data={hsnData}
        keyGetter={(idx, data): string =>
          _.get(_.head(data), ComputedFields.HSN_KEY, (idx + 1).toString())
        }
        rowTransformer={utils.getHSNComputedField}
      >
        <tr style={{ border: '1px solid black' }} className={classes.finalRow}>
          <td>Total</td>
          {_.map(data.fields, renderFinalRow)}
        </tr>
      </StripedTable>
    </div>
  );
};

export default HSNSummary;
