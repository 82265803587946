import qa from './qa';
import development from './development';
import production from './production';
import ampleproduction from './ampleproduction';
import cromaqa from './cromaqa';
import e2eqa from './e2eqa';
import cromaprod from './cromaprod';

// const development = {
//   ruleServerUrl: 'http://localhost:3004'
// };

// const qa = {
//   ruleServerUrl: 'https://api.casaqa.ajira.tech'
// };
//
// const production = {
//   ruleServerUrl: 'https://api.casa.ajira.tech'
// };

let currentConfig = development.casa;

// There is also a built-in environment variable called NODE_ENV. You can read it from process.env.NODE_ENV. When you run npm start, it is always equal to 'development', when you run npm test it is always equal to 'test', and when you run npm run build to make a production bundle, it is always equal to 'production'. You cannot override NODE_ENV manually. This prevents developers from accidentally deploying a slow development build to production.

switch (process.env.REACT_APP_DEPLOY_ENV) {
  case 'dev':
    currentConfig = development;
    break;
  case 'qa':
    currentConfig = qa;
    break;
  case 'production':
    currentConfig = production;
    break;
  case 'ampleproduction':
    currentConfig = ampleproduction;
    break;
  case 'prod':
    currentConfig = production;
    break;
  case 'cromaqa':
    currentConfig = cromaqa;
    break;
  case 'cromaprod':
    currentConfig = cromaprod;
    break;
  case 'e2eqa':
    currentConfig = e2eqa;
    break;
  default:
    throw new Error(
      `env config missing for ${process.env.REACT_APP_DEPLOY_ENV}`
    );
}

const config = currentConfig;

export default config;
