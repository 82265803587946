/* eslint-disable @typescript-eslint/explicit-function-return-type */
import config from '../config/index';

export async function getFormTemplates() {
  return await fetch(`${config.ruleServerUrl}/users/campaign-forms`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json'
    }
  })
    .then(r => {
      return r.json();
    })
    .catch(err => {
      console.log(err);
    });
}
