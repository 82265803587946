import React, { useState } from 'react';
import classNames from 'classnames';
import { makeStyles, Container, TextField } from '@material-ui/core';
import SelectField from '../SelectField';
import { Button } from '../index';

const styles = makeStyles({
  root: {
    height: 300,
    margin: '0px 50px'
  },
  selectLabelField: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 90
  },
  label: {
    width: '30%'
  }
});

const CreateLabel = ({ id, offset, countOfLabels }) => {
  const [count, setCount] = useState(localStorage.getItem('count') || 1);
  const labelSheet = ['A4'];
  const classes = styles();

  const downloadPdf = () => {
    if (count > 0) {
      const win = window.open(
        `/pdf?payloadData=${id}&offset=${offset}&count=${countOfLabels}`,
        '_blank'
      );
      localStorage.setItem('count', count);
      win.focus();
    }
  };
  return (
    <Container>
      <div className={`${classes.root}`}>
        <div className={classes.selectLabelField}>
          <div className={classes.label}>
            <div>
              <b>Label Count</b>
            </div>
            <TextField
              inputProps={{ style: { height: '12px' } }}
              variant="outlined"
              value={count}
              type="number"
              onChange={event => {
                if (typeof parseInt(event.target.value) === 'number')
                  setCount(event.target.value);
              }}
              fullWidth
            />
            {count < 1 && (
              <span
                className={classNames(
                  // eslint-disable-next-line
                  { ['error-text']: count < 1 }
                )}
              >
                Enter label count.
              </span>
            )}
          </div>
          <div className={classes.label}>
            <div>
              <b>Select Label Sheet</b>
            </div>
            <SelectField
              inputProps={{ style: { height: '12px' } }}
              data={labelSheet}
              defaultValue={'A4'}
              label="Label Sheet"
              disabled={true}
            />
          </div>
        </div>
        <div>
          <div className="center l-mt4" style={{ fontWeight: 'bold' }}>
            <Button
              className="buttonPrimary"
              text="CREATE LABEL"
              style={{ width: '35%', fontSize: 16 }}
              onClick={downloadPdf}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CreateLabel;
