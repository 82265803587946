import React, { FC, useState } from 'react';
import usePagination from '../../../shared/hooks/usePagination';
import KPIReportDownloadTable from './KPIReportTable';
import KPIReportFilter, { KPI_REPORT_TYPE } from './KPIReportFilter';
import { getKpiReportDownload } from '../../../services/TenantReports/KpiReport.service';

const KPIReport: FC = () => {
  const [refreshView, setRefreshView] = useState<string>('');
  const [columnValue, setColumnValue] = useState<string>(
    KPI_REPORT_TYPE.DEFAULT
  );
  const { data, isLoading, page, offset, setPage, setOffset } = usePagination(
    getKpiReportDownload,
    [refreshView],
    columnValue
  );
  return (
    <div style={{ padding: '2rem' }}>
      <div>
        <h4>KPI Report</h4>
      </div>
      <KPIReportFilter
        setRefreshView={setRefreshView}
        columnValue={columnValue}
        setColumnValue={setColumnValue}
      />
      <KPIReportDownloadTable
        reports={data || []}
        isLoading={isLoading}
        page={page}
        offset={offset}
        setPage={setPage}
        setOffset={setOffset}
      />
    </div>
  );
};

export default KPIReport;
