/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { OfferType } from '../constants';

function validateSaveOfferJson(offerJson) {
  offerJson.eligibility = offerJson.eligibility || getOfferType(offerJson);
  if (
    !offerJson.isCustomerSpecific ||
    (offerJson.validity && !offerJson.validity.count)
  ) {
    delete offerJson.validity;
  }
  return offerJson;
}
function validateConditionsAndSetErrors(start, end, values) {
  let block = false;
  console.log(values, 'value');
  // const rowLength = values.length - 1;
  // const isSingleRowCheck = start === end;
  for (let i = start; i <= end; i++) {
    values[i].error = {};

    if (
      (values[i].from.trim() === '' && i === 0) ||
      parseInt(values[i].from) <= parseInt(values[i - 1]?.from) ||
      parseInt(values[i].from) <= parseInt(values[i - 1]?.to) ||
      parseInt(values[i - 1]?.from) <= parseInt(values[i - 2]?.to)
    ) {
      values[i].error.from = true;
    }
    if (
      (values[i].to.trim() === '' && values.length - 1 !== end) ||
      parseInt(values[i].from) >= parseInt(values[i].to) ||
      parseInt(values[i].to) <= parseInt(values[i - 1]?.to) ||
      parseInt(values[i - 1]?.to) <= parseInt(values[i - 2]?.to)
    ) {
      values[i].error.to = true;
    }
    if (
      values[i].value.trim() === '' ||
      (values[i].to.trim() !== '' &&
        parseInt(values[i].to) <= parseInt(values[i].value))
    ) {
      values[i].error.value = true;
    }
    if (
      values[i - 1]?.to.trim() !== '' &&
      parseInt(values[i - 1]?.to) <= parseInt(values[i - 1]?.value)
    ) {
      values[i - 1].error.value = true;
    }
    if (values[i - 1]?.to.trim() === '') {
      values[i - 1].error.to = true;
    }
    block = block || Object.keys(values[i].error).length > 0;
  }
  return !block;
}

function updateRowCriteriaInBillAggregateCondition(offer, rows, discountType) {
  const rowLength = rows.length;
  offer.offerDefinition.billAggregateCondition = {
    joinType: 'OR',
    className: 'JoinCondition',
    conditions: []
  };
  for (let i = 0; i < rowLength; i++) {
    let operator = 'BETWEEN';
    if (i === 0 && rows[i].from.trim() === '') {
      rows[i].from = '0';
    }
    if (i === rowLength - 1 && rows[i].to.trim() === '') {
      operator = '>=';
    }
    const numValue =
      operator === 'BETWEEN' ? rows[i].from + '~' + rows[i].to : rows[i].from;
    const conditionsObj = getBillAggregateConditions(
      numValue,
      operator,
      rows[i].value,
      rows[i].maxDiscount,
      discountType
    );
    offer.offerDefinition.billAggregateCondition.conditions.push(conditionsObj);
  }
  return offer;
}

function getBillAggregateConditions(
  numValue,
  operator,
  billDiscountValue,
  maxDiscount,
  discountType
) {
  const conditionsObj = {
    value: numValue,
    operator: { name: operator, className: 'Operator', operatorType: 'number' },
    className: 'FieldCondition',
    fieldAccessor: 'billAmount',
    billDiscount: { value: billDiscountValue, className: 'Discount' }
  };
  if (discountType === 'percent' && maxDiscount) {
    conditionsObj.billDiscount.maxDiscount = maxDiscount;
  }
  return conditionsObj;
}

function getOfferType(offer) {
  if (
    offer.offerDefinition &&
    offer.offerDefinition.billAggregateCondition &&
    offer.offerDefinition.billAggregateCondition.conditions.length > 0
  ) {
    return OfferType.BILL_VALUE;
  } else if (
    offer.offerDefinition &&
    offer.offerDefinition.bucket &&
    offer.offerDefinition.bucket.buckets.length > 0
  ) {
    return OfferType.PRODUCT_IN_CART;
  }
}
function getRowCriteria(offer) {
  const rows = [];
  const conditions =
    offer.offerDefinition && offer.offerDefinition.billAggregateCondition
      ? offer.offerDefinition.billAggregateCondition.conditions
      : [];
  const condLength = conditions.length;
  const isPercentageType =
    offer.offerDefinition.billDiscount.discountType === 'percent';
  const isOldFlow = conditions.length === 1 && !conditions[0].billDiscount;

  for (let i = 0; i < condLength; i++) {
    let from, to;
    switch (conditions[i].operator.name) {
      case 'BETWEEN':
        from = conditions[i].value.split('~')[0];
        to = conditions[i].value.split('~')[1];
        break;
      case '>=':
        from = conditions[i].value;
        to = '';
        break;
      case '>':
        from = conditions[i].value;
        to = '';
        break;
      case '<=':
        from = '0';
        to = conditions[i].value;
        break;
      case '<':
        from = '0';
        to = conditions[i].value;
        break;
      default:
        from = conditions[i].value;
        to = conditions[i].value;
    }
    const billDiscount = isOldFlow
      ? offer.offerDefinition.billDiscount.value
      : conditions[i].billDiscount.value;
    const maxDiscount = isPercentageType
      ? isOldFlow
        ? offer.offerDefinition.billDiscount.maxDiscount
        : conditions[i].billDiscount.maxDiscount
      : '';
    const rowObj = {
      from: from,
      to: to,
      value: billDiscount,
      maxDiscount: maxDiscount
    };
    rows.push(rowObj);
  }
  return rows;
}

export default {
  validateSaveOfferJson,
  validateConditionsAndSetErrors,
  updateRowCriteriaInBillAggregateCondition,
  getOfferType,
  getRowCriteria
};
