/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import Button from '@material-ui/core/Button';
import { Close } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import FileUploadStyles from './FileUpload.module.css';

const FileUpload = ({ html, setHtml }) => {
  function getUploadButtonStyles() {
    return {
      color: '#F26E5F',
      width: '188px',
      backgroundColor: '#FEF3F2',
      borderColor: '#F26E5F',
      position: 'absolute',
      left: '327px',
      height: '60px'
    };
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginBottom: '20px'
      }}
      onClick={e => e.stopPropagation()}
    >
      <label>Upload the HTML File</label>
      {!html ? (
        <>
          <input
            type="file"
            className={FileUploadStyles.customFileUpload}
            accept="text/html"
            onChange={e => {
              setHtml(e.target.files[0]);
            }}
          />
          <Button variant="outlined" style={getUploadButtonStyles()}>
            Upload
          </Button>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '180px'
          }}
        >
          <p
            style={{
              color: 'lightgray',
              margin: '15px 2% 0 20%'
            }}
          >
            {html.name.replace(
              'https://rules.casaqa.ajira.tech/public-html/fashionfolks/',
              ''
            )}
          </p>
          <IconButton
            onClick={() => {
              setHtml('');
            }}
          >
            <Close style={{ color: 'black' }} />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
