/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useContext, useEffect, useState } from 'react';
import '../../OfferDefinition/QueryBuilder/ProductSection/index.scss';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import _ from 'lodash';
import update from 'immutability-helper';
// import GetAppIcon from '@material-ui/icons/GetApp';
import {
  // BaseCondition,
  FieldCondition,
  JoinBucket,
  Operator,
  ProductBucket
  // ProductDiscount
} from '../../../models/offer/OfferRule.model';
// import { CSVLink } from 'react-csv';
import { BusinessUnitContext } from '../../OfferDefinition/BusinessUnitContext';
import productMasterService from '../../../services/productMaster.service';
import SelectProductHierarchy from '../../OfferDefinition/QueryBuilder/ProductSection/Components/SelectProductHierarchy';
import { ReadOnlyContext } from '../../OfferDetail/ReadOnlyContext';

export interface OptionType {
  label: string;
  value: string;
}

function asOptions(values: string[]): OptionType[] {
  return _.map(values, v => ({ label: v, value: v }));
}

interface ProductSelectionProps {
  bucket: ProductBucket;
  setRootBucket: (root: JoinBucket) => void;
  rootBucket: JoinBucket;
}

export const ProductSection: React.FC<ProductSelectionProps> = ({
  bucket,
  setRootBucket,
  rootBucket
}) => {
  const { readOnly } = useContext(ReadOnlyContext);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isHierarchyBased, setIsHierarchyBased] = useState(true);
  const businessUnitContext = useContext(BusinessUnitContext);
  const [skusList, setSkusList] = useState([]);

  const [productFieldOptions, setProductFieldOptions] = useState<{
    [key: string]: OptionType[];
  }>({});
  const [
    selectedProductFilterOptions,
    setSelectedProductFilterOptions
  ] = useState<{
    [key: string]: OptionType[];
  }>({});
  const toggleSelectionType = () => {
    setSelectedProductFilterOptions({});
    setIsHierarchyBased(!isHierarchyBased);
  };

  // function replaceRootBucket(newBucket: ProductBucket) {
  //   setRootBucket(rootBucket.replace(newBucket));
  // }

  function getFieldConditionPreviewText(condition: FieldCondition): string {
    if (condition.fieldAccessor === 'sku') {
      const myArray = condition?.value.map((str: any, index: number) => ({
        sku: str
      }));
      skusList.length === 0 && setSkusList(myArray);
      return `${_.size(condition.value)} skus`;
    } else {
      return _.join(condition.value, ', ');
    }
  }

  // const updateDiscount = (discount: ProductDiscount) => {
  //   replaceRootBucket(
  //     update(bucket, {
  //       discount: {
  //         $set: discount
  //       }
  //     })
  //   );
  // };

  const businessProductFilter = businessUnitContext.business.productFilter;

  useEffect(() => {
    const productFilter = _.mapValues(selectedProductFilterOptions, options =>
      _.map(options, o => o.value)
    );
    if (_.includes(_.keys(selectedProductFilterOptions), 'sku')) {
      setIsHierarchyBased(false);
    }
    _.each(
      businessProductFilter.hierarchyNew.concat(
        businessProductFilter.productFieldsNew
      ),
      item => {
        if (item) {
          productMasterService
            .filterProductFields(
              businessUnitContext.business,
              item.casaFieldName,
              productFilter
            )
            .then(response => {
              const productFieldOptionsList = asOptions(response.values);
              setProductFieldOptions(o => ({
                ...o,
                [response.productField]: productFieldOptionsList
              }));
              const allowedSelectedOptions = _.filter(
                productFieldOptionsList,
                o =>
                  _.some(
                    selectedProductFilterOptions[item.casaFieldName],
                    so => so.value === o.value
                  )
              );
              if (
                !_.isEqual(
                  allowedSelectedOptions,
                  selectedProductFilterOptions[item.casaFieldName]
                )
              ) {
                setSelectedProductFilterOptions(o => ({
                  ...o,
                  [item.casaFieldName]: allowedSelectedOptions
                }));
              }
            });
        }
      }
    );
  }, [businessUnitContext.business]);

  // const labelValueFor = (val?: string) => ({ label: val, value: val });

  return (
    <div
      // id="section"
      style={
        {
          // padding: '20px 15px 20px 15px',
          // marginBottom: 20,
          // marginTop: 20,
          // border: '1px solid #A8A8A880',
          // borderRadius: 10,
          // boxShadow: '0px 6px 10px #0000001a'
        }
      }
    >
      <div
        style={{
          display: 'flex',
          gap: '20px',
          alignItems: 'Center',
          marginBottom: '1rem'
        }}
      >
        <div style={{ marginTop: 5 }}>
          Select the products for mapping to post:
        </div>
        <Button
          style={{
            border: '1px solid #DEDEDE',
            borderRadius: 4,
            color: '#707070'
          }}
          variant="outlined"
          color="primary"
          onClick={() => setDialogOpen(c => !c)}
        >
          Select Product(s)
        </Button>
      </div>
      {bucket.isDefined && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ fontWeight: 'bold' }}>Selected Product</span>
          <div
            className={'d-flex'}
            style={{
              borderRadius: '5px',
              backgroundColor: 'rgb(245, 245, 245)',
              padding: '15px',
              marginTop: '10px'
            }}
          >
            <div
              className={'col-9 d-flex align-items-center '}
              style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
            >
              <span className="skusListStyle">
                {_(bucket.condition.conditions)
                  .map(c =>
                    c instanceof FieldCondition && _.some(c.value)
                      ? getFieldConditionPreviewText(c)
                      : undefined
                  )
                  .compact()
                  .join(' > ')}
              </span>
              {/* <CSVLink
            data={skusList || []}
            filename={'SKUS_LIST'}
            className={'downloadCsvStyle'}
            enclosingCharacter={``}
          >
            <GetAppIcon style={{ fontSize: 22 }} />
          </CSVLink> */}
            </div>
          </div>
        </div>
      )}
      <Dialog
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isDialogOpen}
        maxWidth="lg"
        scroll="paper"
      >
        <DialogTitle id="alert-dialog-xide-title" className={'text-center'}>
          Select Products
        </DialogTitle>
        <DialogContent
          style={{
            width: `${1000}px`,
            minWidth: 300,
            minHeight: 350,
            paddingBottom: 50
          }}
        >
          <div>
            <RadioGroup
              aria-label="selection type"
              name="selectionType"
              value={isHierarchyBased}
              onChange={toggleSelectionType}
            >
              <FormControlLabel
                value={true}
                control={<Radio disabled={readOnly} />}
                label="Select product(s) by hierarchy"
              />
            </RadioGroup>
          </div>
          <SelectProductHierarchy
            productFilter={businessProductFilter}
            selectedProductFilterOptions={selectedProductFilterOptions}
            setSelectedProductFilterOptions={(v: any) => {
              console.log(
                'v',
                selectedProductFilterOptions,
                productFieldOptions
              );
              setSelectedProductFilterOptions(v);
            }}
            isHierarchyBased={isHierarchyBased}
            productFieldOptions={productFieldOptions}
            menuPlacement="auto"
            maxMenuHeight={200}
          />
          {/* <div className="clearfix d-flex row my-3">
            <div className={'col-5'}>
              <hr />
            </div>
            <div className={'col-2 text-center'}>OR</div>
            <div className={'col-5'}>
              <hr />
            </div>
          </div> 
           <div className="">
            <RadioGroup
              aria-label="selection type"
              name="skuSelectionType"
              value={isHierarchyBased}
              onChange={toggleSelectionType}
            >
              <FormControlLabel
                value={false}
                control={<Radio disabled={readOnly} />}
                label="Select product(s) using sku"
              />
            </RadioGroup>
          </div> */}
        </DialogContent>
        <DialogActions className={'mb-3'}>
          <Button
            onClick={() => {
              setDialogOpen(c => !c);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <div className={'mr-3'}>&nbsp;</div>
          <div className={'pr-3'}>
            <Button
              onClick={() => {
                const productBucket = bucket as ProductBucket;
                const filedConditions = _(selectedProductFilterOptions)
                  .entries()
                  .filter(([fieldName, fieldNameOptions]) =>
                    _.some(fieldNameOptions)
                  )
                  .map(([fieldName, fieldNameOptions]) => {
                    return new FieldCondition(
                      fieldName,
                      _.map(fieldNameOptions, 'value'),
                      Operator.isInOperator
                    );
                  })
                  .value();
                const updatedProductBucket = update(productBucket, {
                  condition: {
                    conditions: {
                      $set: filedConditions
                    }
                  }
                });
                setRootBucket(rootBucket.replace(updatedProductBucket));
                console.log('updatedProductBucket', updatedProductBucket);

                setDialogOpen(c => !c);
              }}
              variant="contained"
              color="primary"
              className={'mr-3'}
            >
              Add
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};
