import React, { FC, useContext } from 'react';
import breadcrumbsArrow from '../../Assets/images/breadcrumbs_arrow.svg';
import SelectWithAutoComplete from '../SelectWithAutoComplete/SelectWithAutoComplete';
import _, { find, get, head, keys, map, toLower } from "lodash";
import { getDefaultOptions, isOptionValueAvailable } from './utility';
import { BusinessUnitContext } from '../../OfferDefinition/BusinessUnitContext';

interface SelectedProductsProps {
  allProducts: any;
  productHierarchyTreeChecked: any;
  setSelectedMatchingProducts: any;
  setSelectedProductsByPath: any;
  selectedProductsByPath: any;
  selectedMatchingProducts: any;
}

const SelectedProducts: FC<SelectedProductsProps> = ({
  allProducts,
  productHierarchyTreeChecked,
  setSelectedMatchingProducts,
  setSelectedProductsByPath,
  selectedProductsByPath,
  selectedMatchingProducts
}) => {
  const businessUnitContext = useContext(BusinessUnitContext);
  const productHierarchy = _.get(
    businessUnitContext,
    'business.productHierarchyNew',
    []
  );
  console.log('selectedMatchingProducts', selectedMatchingProducts);
  console.log('selectedProductsByPath', selectedProductsByPath);
  function getProductOptionsByAbsolutePath(current: string) {
    return _.concat(
      getDefaultOptions(),
      getAllLastHierarchyProductOptionsByAbsolutePath(current)
    );
  }

  const getUpdatedProducts = (
    selectedProducts: any,
    currentPath: any,
    currentSelected: any
  ) => {
    let updatedData = [];
    if (_.isEmpty(_.find(selectedProducts, { path: currentPath }))) {
      updatedData.push({
        path: currentPath,
        selectedProducts: currentSelected
      });
      updatedData = _.concat(selectedProducts, updatedData);
    } else {
      updatedData = _.map(selectedProducts, (v, i) => {
        const updatedSelectedProducts =
          v.path === currentPath ? currentSelected : v.selectedProducts;
        return { path: v.path, selectedProducts: updatedSelectedProducts };
      });
    }
    return updatedData;
  };

  const getSelectedProductsByPath = (path: string) => {
    return _.uniqBy(
      _.get(
        _.find(selectedProductsByPath, { path: path }),
        'selectedProducts',
        []
      ),
      'value'
    );
  };

  const getAllSelectedMatchingProducts = () => {
    return _.flatMap(selectedProductsByPath, v => v?.selectedProducts);
  };

  function getAllLastHierarchyProductOptionsByAbsolutePath(
    absolutePath: string
  ) {
    const checkedProductHierarchy = _.take(
      productHierarchy,
      productHierarchy.length - 1
    );
    const lastHierarchy: any = toLower(get(_.last(productHierarchy), 'displayName'));
    console.log('checkedProductHierarchy', checkedProductHierarchy);
    const mapHierarchyWithAbsolutePath = _.map(
      map(checkedProductHierarchy, 'casaFieldName'),
      (v: string, i: number) => {
        return { [v]: _.split(absolutePath, '/')[i] };
      }
    );
    console.log('absolutePath----', mapHierarchyWithAbsolutePath, absolutePath);
    const mapHierarchyWithAbsolutePathAndDisplayName = map(
      mapHierarchyWithAbsolutePath,
      h => {
        const casaFieldName: any = head(keys(h));
        const fieldMapping = find(
          productHierarchy,
          ph => get(ph, 'casaFieldName') === casaFieldName
        );
        return {
          [get(fieldMapping, 'displayName', casaFieldName)]: get(
            h,
            casaFieldName,
            ''
          )
        };
      }
    );
    const filterConditionObj = _.reduce(
      mapHierarchyWithAbsolutePathAndDisplayName,
      function(acc, current) {
        return _.assign(acc, current);
      },
      {}
    );
    const filteredProductsByAbsolutePath: any = _.filter(
      _.map(allProducts, v => v['extendedData']),
      filterConditionObj
    );
    console.log(
      'filteredProductsByAbsolutePath',
      filteredProductsByAbsolutePath
    );
    console.log('lastHierarchy', lastHierarchy);
    return _.map(filteredProductsByAbsolutePath, v => {
      return {
        label: _.get(v, lastHierarchy, ''),
        id: v.id,
        value: _.get(v, lastHierarchy, '')
      };
    });
  }
  function getUniquProductOptions(current: string) {
    return _.unionBy(getProductOptionsByAbsolutePath(current), 'value');
  }
  const onChangeSelectedProducts = (
    currentSelected: any,
    currentPath: string
  ) => {
    const matchingProducts = _.filter(
      getProductOptionsByAbsolutePath(currentPath),
      v => _.includes(_.map(currentSelected, 'value'), v.value)
    );
    setSelectedProductsByPath(
      getUpdatedProducts(selectedProductsByPath, currentPath, currentSelected)
    );
    setSelectedMatchingProducts(
      getUpdatedProducts(selectedProductsByPath, currentPath, matchingProducts)
    );
  };

  const getUpdatedOptions = (v: any, path: string) => {
    let updatedOptions = v;
    if (isOptionValueAvailable(v, 'Select All')) {
      updatedOptions = getAllLastHierarchyProductOptionsByAbsolutePath(path);
    } else if (isOptionValueAvailable(v, 'Clear All')) {
      updatedOptions = [];
    }
    console.log('updatedOptions', updatedOptions);
    return updatedOptions;
  };
  return (
    <div className={'product-hierarchy-tree-container'}>
      <p className={'product-hierarchy-main-header'}>
        {`Selected Products ( ${getAllSelectedMatchingProducts().length} )`}
      </p>
      <div
        className={
          'product-hierarchy-select mx-5 px-2 product-filter-container'
        }
      >
        {_.map(productHierarchyTreeChecked, (checkedProductPath, i) => {
          const checkedProductPathList = _.split(checkedProductPath, '/');
          return (
            <div key={i} className={'filtered-product-line-item-section'}>
              <div className={'filtered-product-line-item'}>
                <div style={{ display: 'flex' }}>
                  {_.map(checkedProductPathList, (v, i) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        key={i}
                      >
                        <span style={{ color: '#767676', fontSize: 14 }}>
                          {v}
                        </span>
                        {checkedProductPathList.length - 1 !== i && (
                          <span>
                            <img
                              src={breadcrumbsArrow}
                              style={{ width: 8, margin: '.5rem' }}
                              alt={'checkedIcon'}
                            />
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={'px-3 py-2 product-select'}>
                <SelectWithAutoComplete
                  key={i}
                  options={getUniquProductOptions(checkedProductPath)}
                  values={getSelectedProductsByPath(checkedProductPath)}
                  onChange={v =>
                    onChangeSelectedProducts(
                      getUpdatedOptions(v, checkedProductPath),
                      checkedProductPath
                    )
                  }
                  label={get(_.last(productHierarchy), 'displayLabel')}
                  size={'auto'}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectedProducts;
