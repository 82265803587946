/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import classes from './Zoom.module.css';
import ZoomInIcon from '../../Icons/svg/ZoomInIcon';
import ZoomOutIcon from '../../Icons/svg/ZoomOutIcon';

const Zoom = ({ onZoomHandler }) => {
  const [zoomLevel, setZoomLevel] = React.useState(80);

  const zoomIn = () => {
    onZoomHandler(zoomLevel + 10);
    setZoomLevel(zoomLevel + 10);
  };

  const zoomOut = () => {
    onZoomHandler(zoomLevel - 10);
    setZoomLevel(zoomLevel - 10);
  };

  return (
    <div className={classes.container}>
      <div onClick={zoomIn}>
        <ZoomInIcon />
      </div>
      <div onClick={zoomOut}>
        <ZoomOutIcon />
      </div>
    </div>
  );
};

export default Zoom;
