import React from 'react';
import { Grid } from '@material-ui/core';
// import RewardPane from '../RewardPane';
import { translateDateToDifferentFormat } from '../../../utils';
import FacebookService from '../../../services/facebook.service';
import InstagramService from '../../../services/instagram.service';
import ShortText from '../../ShortText';
import { SocialMediaChannels } from '../../../constants';

class RewardConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postInfo: {}
    };
  }

  componentDidMount = async () => {
    const { data } = this.props;
    const { postId, accessToken } = data;
    switch (data.channel) {
      case SocialMediaChannels.FACEBOOK:
        this.setState({
          postInfo: await FacebookService.getInformationFromFbPostId(
            postId,
            accessToken
          )
        });
        break;
      case SocialMediaChannels.INSTAGRAM:
        this.setState({
          postInfo: await InstagramService.getInformationFromInstagramPostId(
            postId,
            accessToken
          )
        });
        break;
      default:
        break;
    }
  };

  render() {
    const { data } = this.props;
    const { postInfo } = this.state;
    const { message, permalinkUrl } = postInfo;
    const { name, channel, createdAt } = data;
    return (
      <div
        style={{
          borderRadius: 'o.4rem',
          marginBottom: 20,
          padding: 20,
          backgroundColor: data.idx === 0 ? '#dedede' : '#f2f2f2',
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
          border: data.idx === 0 ? '1px solid #c7c7c7' : 'none'
        }}
      >
        <div style={{ marginTop: 5, marginLeft: 5 }}>
          <Grid container>
            <Grid xs={2}>Name:</Grid>
            <Grid
              xs={4}
              style={{ color: 'black', fontWeight: 'bold', fontSize: 16 }}
            >
              {name || '-'}
            </Grid>
            <Grid xs={2}>Created At:</Grid>
            <Grid
              xs={4}
              style={{ color: 'black', fontWeight: 'bold', fontSize: 16 }}
            >
              {translateDateToDifferentFormat(
                createdAt,
                'YYYY-MM-DD, hh:mm:ss A'
              )}
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: 5, marginLeft: 5 }}>
          <Grid container>
            <Grid xs={2}>Channel:</Grid>
            <Grid style={{ color: 'black', fontWeight: 'bold', fontSize: 16 }}>
              {channel || '-'}
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: 5, marginLeft: 5 }}>
          <Grid container>
            <Grid xs={2}>Post description:</Grid>
            <Grid
              xs={10}
              style={{ color: 'black', fontWeight: 'bold', fontSize: 16 }}
            >
              <ShortText text={message || '-'} />
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: 5, marginLeft: 5 }}>
          <Grid container>
            <Grid xs={2}>Link to Post:</Grid>
            <Grid
              xs={10}
              style={{
                color: 'black',
                fontWeight: 'bold',
                fontSize: 16,
                whiteSpace: 'nowrap',
                width: '50px',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              <a
                href={permalinkUrl}
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                {permalinkUrl || '-'}
              </a>
            </Grid>
          </Grid>
        </div>
        {/* <hr /> */}
        {/* <div style={{ marginTop: 5, marginLeft: 5 }}>
          <div>Rewards:</div>
          <ul>
            {rewards.length > 0 ? (
              rewards.map(reward => (
                <RewardPane
                  postId={postId}
                  data={reward}
                  key={'config' + id + 'reward' + reward.id}
                  accessToken={accessToken}
                />
              ))
            ) : (
              <div style={{ marginTop: 10 }}>No Rewards configured</div>
            )}
          </ul>
        </div> */}
      </div>
    );
  }
}

export default RewardConfig;
