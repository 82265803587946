/* eslint-disable @typescript-eslint/explicit-function-return-type */
import config from '../../config/index';
import moment from 'moment';
import { Business } from '../../models/User.model';

export const storeHealthDashboardGenerateReport = (
  startDate: string,
  endDate: string,
  stores: [],
  business: Business
) => {
  const storesData = { stores: stores };
  return fetch(
    `${config.ruleServerUrl}/users/reports/download/store_health_report/${
      business.id
    }?startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(
      endDate
    ).format('YYYY-MM-DD')}&businessUnitName=${business.name}
      `,
    {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(storesData)
    }
  ).then(r => r.json());
};

export const getStoreHealthReportDownload = ({
  page,
  offset
}: {
  page: number;
  offset: number;
  filters?: any;
}): Promise<any[]> => {
  return fetch(
    `${config.ruleServerUrl}/users/reports/view/downloads/store_health_report?offset=${offset}&page=${page}`,
    { credentials: 'include' }
  ).then(res => res.json());
};
