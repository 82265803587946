import { CustomerOffersList } from '../../components/CustomerOffersList';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Offer } from '../../models/offer/Offer.model';
import offerService from '../../services/offer.service';
import useCurrentUser from '../../hooks/useCurrentUser';

export const CustomerOffersRoute: FC = () => {
  const history = useHistory();
  const { customerMobile } = useParams() as Record<string, string>;
  const [currentOffers, setCurrentOffers] = useState<Offer[]>([]);
  const user = useCurrentUser();
  useEffect(() => {
    offerService
      .getCurrentOffers(user.firstStore, customerMobile)
      .then(offers => setCurrentOffers(offers));
  }, [customerMobile, user]);

  return (
    <>
      {/* <div
        className={'h3 m-2'}
        style={{ fontWeight: 'bold', fontSize: '22px' }}
      >
        Offers
      </div> */}
      <CustomerOffersList
        displayMode={'tile'}
        className={'p-2'}
        customerMobile={customerMobile as string}
        currentOffers={currentOffers}
        businessUnitId={user.getBusinesses()[0]?.id || -1}
        onOfferClick={(customerMobile, offerCode) =>
          history.push(`/build-cart/${customerMobile}/${offerCode}`)
        }
      />
    </>
  );
};
