import './index.scss';

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { Checkmark } from 'react-checkmark';
import {
  ICSRcsInitialState,
  ICSWhatsAppSmsInitialState,
  IcsSmsInitialState,
  KarixEmailInitialState,
  Mesg91InitialState,
  WonderMailInitialState,
  amazonInitialState,
  ameyoInitialState,
  karixInitialState,
  msg91InitialState,
  oneXtelInitialState,
  stmInitialState,
  valueFirstInitialState,
  watiWhatsAppSmsInitialState
} from '../../constants/configuration';
import useCurrentUser from '../../hooks/useCurrentUser';
import { Business } from '../../models/User.model';
import ConfigService from '../../services/config.service';
import {
  ICSRcsValidationSchema,
  ICSWhatsappValidationSchema,
  IcsSmsModalToSchema,
  KarixEmailModalToSchema,
  KarixSMSModalToSchema,
  KarixWhatsappModalToSchema,
  MSG91SmsModalToSchema,
  Msg91ModalToSchema,
  OneXtelModalToSchema,
  StmModalToSchema,
  WonderMailModalToSchema,
  amazonModalToSchema,
  ameyoValidationSchema,
  baseWhatsappValidationSchema,
  valueFirstModalToSchema
} from '../../validation/channelsModal';
import Amazonlogo from '../Assets/channels/Amazonlogo.png';
import Ameyologo from '../Assets/channels/Ameyologo.png';
import Commcryptlogo from '../Assets/channels/Commcryptlogo.png';
import Email3 from '../Assets/channels/Email3.svg';
import Karixlogo from '../Assets/channels/Karixlogo.png';
import MSG91logo from '../Assets/channels/MSG91logo.png';
import Messaing2 from '../Assets/channels/Messaing2.svg';
import OneXtelLogo from '../Assets/channels/OneXtelLogo.png';
import StmTechLogo from '../Assets/channels/StmTechLogo.png';
import ValueFirstLogo from '../Assets/channels/ValueFirstLogo.png';
import WatiWhatsAppSmsLogo from '../Assets/channels/WatiWhatsAppSms.png';
import WonderMailLogo from '../Assets/channels/Wondermail-logo.png';
import whatsapp1 from '../Assets/channels/whatsapp1.svg';
import ConfigModal from './ConfigModal';
import ContentMode from './ContentMode';

import ICSLogo from '../Assets/channels/ics-logo.png';
import { NotificationSenders } from './NotificationSenders';

const baseInputFields = [
  { name: 'sender', label: 'Sender' },
  { name: 'priorityOrder', label: 'Priority Order' },
  { name: 'promotionalSender', label: 'Sender for Promotions' }
];
const inputFieldsOneXtel = [
  ...baseInputFields,
  { name: 'authkey', label: 'Auth Key' },
  { name: 'authKeyServiceExplicit', label: 'AuthKey Service Explicit' },
  { name: 'authKeyPromotional', label: 'AuthKey Promotional' },
  { name: 'entityId', label: 'Entity Id' }
];

const inputFieldsStm = [
  ...baseInputFields,
  { name: 'authkey', label: 'Auth Key' },
  { name: 'principleEntityId', label: 'Principle Entity Id' },
  { name: 'clientId', label: 'clientId' }
];

const inputFieldsValueFirst = [
  { name: 'username', label: 'User Name' },
  { name: 'password', label: 'Password' },
  ...baseInputFields
];

const inputFieldsMsg91 = [
  ...baseInputFields,
  { name: 'authkey', label: 'Token' }
];

const inputFieldsIcsSms = [
  {
    name: 'priorityOrder',
    label: 'Priority Order'
  },
  {
    name: 'from_transactional',
    label: 'Sender ID (Transactional)'
  },
  {
    name: 'from_promotional',
    label: 'Sender ID (Promo)'
  },
  {
    name: 'from_OTP',
    label: 'Sender ID (OTP)'
  },
  {
    name: 'user_transactional',
    label: 'User Name (Transactional)'
  },
  {
    name: 'user_promotional',
    label: 'User Name(Promo)'
  },
  {
    name: 'user_OTP',
    label: 'User Name(OTP)'
  },
  {
    name: 'pass_transactional',
    label: 'Password (Transactional)'
  },
  {
    name: 'pass_promotional',
    label: 'Password(Promo)'
  },
  {
    name: 'pass_OTP',
    label: 'Password(OTP)'
  },
  {
    name: 'peId',
    label: 'Principle Entity Id'
  }
];

const inputFieldsKarixSms = [
  { name: 'priorityOrder', label: 'Priority Order' },
  { name: 'sender', label: 'Sender For Transactional' },
  { name: 'promotionalSender', label: 'Sender for Promotions' },
  { name: 'otpSender', label: 'Sender for OTP' },
  { name: 'dltEntityId', label: 'Dlt Entity Id' },
  { name: 'from', label: 'Sender Mobile Number' },
  { name: 'authKeyPromotional', label: 'AuthKey For Promotions' },
  { name: 'authKeyTransactional', label: 'AuthKey For Transactional' },
  { name: 'authKeyOtp', label: 'AuthKey For OTP' }
];

const inputFieldsAmazon = [
  { name: 'priorityOrder', label: 'Priority Order' },
  { name: 'sender', label: 'Sender (eg:CASA)' },
  { name: 'awsAccessKeyId', label: 'Aws Access Key Id' },
  { name: 'awsSecretAccessKey', label: 'Aws Secret Key Id' },
  { name: 'awsRegion', label: 'Aws  Region' }
];

const inputFieldsEmailMsg91 = [
  { name: 'priorityOrder', label: 'Priority Order' },
  { name: 'authkey', label: 'Auth key' },
  { name: 'fromName', label: 'fromName' },
  { name: 'domain', label: 'Domain' },
  { name: 'sender', label: 'Sender (eg:CASA)' }
];

const inputFieldsKarixEmail = [
  { name: 'priorityOrder', label: 'Priority Order' },
  { name: 'userName', label: 'User Name' },
  { name: 'password', label: 'Password' },
  { name: 'fromEmail', label: 'Sender Email' },
  { name: 'fromName', label: 'Sender Name' }
];

const inputFieldsWonderMailEmail = [
  { name: 'priorityOrder', label: 'Priority Order' },
  { name: 'authkey', label: 'Auth Key' },
  { name: 'fromEmail', label: 'Sender Email' },
  { name: 'fromName', label: 'Sender Name' }
];

const inputFieldsAmeyo = [
  { name: 'priorityOrder', label: 'Priority Order' },
  { name: 'authkey', label: 'Token' }
];

const inputFieldsKarix = [
  { name: 'priorityOrder', label: 'Priority Order' },
  { name: 'authKeyPromotional', label: 'AuthKey For Promotions' },
  { name: 'authKeyTransactional', label: 'AuthKey For Transactional' },
  { name: 'promotionalSender', label: 'Registered Mobile (Promotional)' },
  { name: 'transactionalSender', label: 'Registered Mobile (Transactional)' }
];

const inputFieldsWatiWhatsAppSms = [
  { name: 'priorityOrder', label: 'Priority Order' },
  { name: 'authkey', label: 'Token' }
];

const inputFieldsIcsWhatsapp = [
  { name: 'priorityOrder', label: 'Priority Order' },
  { name: 'user', label: 'User Name' },
  { name: 'pass', label: 'Password' },
  { name: 'from', label: 'Registered Mobile' }
];

const inputFieldsIcsRcs = [
  { name: 'priorityOrder', label: 'Priority Order' },
  { name: 'user', label: 'User Name' },
  { name: 'pass', label: 'Password' }
];

const mesgDetails = [
  {
    image: Commcryptlogo,
    label: '',
    priorityName: '',
    fields: [],
    initialState: {},
    schema: () => null,
    gridSpace: 12,
    campaignCheck: true,
    disabled: true
  },
  {
    image: Karixlogo,
    label: 'SMS Configuration - Karix',
    priorityName: 'KarixSendV2',
    fields: inputFieldsKarixSms,
    initialState: karixInitialState,
    schema: () => KarixSMSModalToSchema,
    gridSpace: 6,
    campaignCheck: true,
    disabled: false
  },
  {
    image: MSG91logo,
    label: 'SMS Configuration - MSG91',
    priorityName: 'Msg91SendSms',
    fields: inputFieldsMsg91,
    initialState: msg91InitialState,
    schema: MSG91SmsModalToSchema,
    gridSpace: 12,
    campaignCheck: true,
    disabled: false
  },
  {
    image: ICSLogo,
    label: 'SMS Configuration - ICS',
    priorityName: NotificationSenders.ICS_SMS,
    fields: inputFieldsIcsSms,
    initialState: IcsSmsInitialState,
    schema: IcsSmsModalToSchema,
    gridSpace: 6,
    campaignCheck: true,
    disabled: false
  },
  {
    image: ValueFirstLogo,
    label: 'SMS Configuration - Value First',
    priorityName: 'ValueFirstSendSms',
    fields: inputFieldsValueFirst,
    initialState: valueFirstInitialState,
    schema: valueFirstModalToSchema,
    gridSpace: 12,
    campaignCheck: true,
    disabled: false
  },
  {
    image: StmTechLogo,
    label: 'SMS Configuration - StmSendSms',
    priorityName: 'STMSendSms',
    fields: inputFieldsStm,
    initialState: stmInitialState,
    schema: StmModalToSchema,
    gridSpace: 6,
    campaignCheck: true,
    disabled: false
  },
  {
    image: OneXtelLogo,
    label: 'SMS Configuration - OneXtelSendSms',
    priorityName: 'OneXtelSendSms',
    fields: inputFieldsOneXtel,
    initialState: oneXtelInitialState,
    schema: OneXtelModalToSchema,
    gridSpace: 6,
    campaignCheck: true,
    disabled: false
  }
];

const RcsProvidersDetails = [
  {
    image: ICSLogo,
    label: 'SMS Configuration - RCS',
    priorityName: NotificationSenders.ICS_RCS,
    fields: inputFieldsIcsRcs,
    initialState: ICSRcsInitialState,
    schema: ICSRcsValidationSchema,
    gridSpace: 12,
    campaignCheck: true,
    disabled: false
  }
];

const emailDetails = [
  {
    image: Amazonlogo,
    label: 'Email Configuration - Amazon',
    priorityName: 'SesSendEmail',
    fields: inputFieldsAmazon,
    initialState: amazonInitialState,
    schema: amazonModalToSchema,
    gridSpace: 6,
    campaignCheck: true,
    disabled: false
  },
  {
    image: MSG91logo,
    label: 'Email Configuration - MSG91',
    priorityName: 'Msg91Email',
    fields: inputFieldsEmailMsg91,
    initialState: Mesg91InitialState,
    schema: Msg91ModalToSchema,
    gridSpace: 6,
    campaignCheck: true,
    disabled: false
  },
  {
    image: Karixlogo,
    label: 'Email Configuration - Karix',
    priorityName: 'KarixEmail',
    fields: inputFieldsKarixEmail,
    initialState: KarixEmailInitialState,
    schema: KarixEmailModalToSchema,
    gridSpace: 6,
    campaignCheck: true,
    disabled: false
  },
  {
    image: WonderMailLogo,
    label: 'Email Configuration - WonderMail',
    priorityName: 'WonderMail',
    fields: inputFieldsWonderMailEmail,
    initialState: WonderMailInitialState,
    schema: WonderMailModalToSchema,
    gridSpace: 6,
    campaignCheck: true,
    disabled: false
  }
];

const whatsAppDetails = [
  {
    image: Ameyologo,
    label: 'Whatsapp Configuration - Ameyo',
    priorityName: 'AmeyoWhatsAppMsg',
    fields: inputFieldsAmeyo,
    initialState: ameyoInitialState,
    schema: ameyoValidationSchema,
    gridSpace: 12,
    campaignCheck: false,
    disabled: false
  },
  {
    image: Karixlogo,
    label: 'Whatsapp Configuration - Karix',
    priorityName: 'KarixWhatsAppSms',
    fields: inputFieldsKarix,
    initialState: karixInitialState,
    schema: KarixWhatsappModalToSchema,
    gridSpace: 12,
    campaignCheck: false,
    disabled: false
  },
  {
    image: WatiWhatsAppSmsLogo,
    label: 'Whatsapp Configuration - WATI',
    priorityName: 'WatiWhatsAppSms',
    fields: inputFieldsWatiWhatsAppSms,
    initialState: watiWhatsAppSmsInitialState,
    schema: baseWhatsappValidationSchema,
    gridSpace: 12,
    campaignCheck: false,
    disabled: false
  },
  {
    image: ICSLogo,
    label: 'Whatsapp Configuration - ICS',
    priorityName: NotificationSenders.ICS_WHATSAPP,
    fields: inputFieldsIcsWhatsapp,
    initialState: ICSWhatsAppSmsInitialState,
    schema: ICSWhatsappValidationSchema,
    gridSpace: 12,
    campaignCheck: false,
    disabled: false
  }
];

const Channels = () => {
  const [smsData, setSmsData] = React.useState();
  const [whatsappData, setWhatsappData] = React.useState();
  const [mailData, setMailData] = React.useState();
  const [rcsData, setRcsData] = React.useState();
  const [checkerValue, setCheckerValue] = React.useState(0);
  const [getChannels, setGetChannels] = React.useState(0);
  const [createStatus, setCreateStatus] = React.useState(false);
  const [business, setBusiness] = React.useState(new Business());
  const [contentMode, setContentMode] = React.useState(
    ContentMode.TRANSACTIONAL
  );
  const user = useCurrentUser();
  const [configureStatus, setConfigureStatus] = React.useState({
    sms: 0,
    whatsapp: 0,
    mail: 0,
    rcs: 0
  });
  const setChannels = useCallback(() => {
    ConfigService.getChannelConfig(business.name).then(({ channels }) => {
      setGetChannels(channels);
      if (channels) {
        channels.forEach(item => {
          if (item?.version === 'whatsapp') {
            setWhatsappData(item);
            setConfigureStatus({ ...configureStatus, whatsapp: 1 });
          }
          if (item?.version === 'sms') {
            setSmsData(item);
            setConfigureStatus({ ...configureStatus, sms: 1 });
          }
          if (item?.version === 'email') {
            setMailData(item);
            setConfigureStatus({ ...configureStatus, mail: 1 });
          }
          if (item?.version === 'rcs') {
            setRcsData(item);
            setConfigureStatus({ ...configureStatus, rcs: 1 });
          }
        });
      }
    });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  useEffect(() => {
    setChannels();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [createStatus, business]);

  useEffect(() => {
    setCheckerValue(
      configureStatus.whatsapp + configureStatus.sms + configureStatus.mail
    );
  }, [configureStatus]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsBuId = _.parseInt(urlParams.get('bu'));
    const paramsBusiness = _(user.getBusinesses()).find(
      b => b.id === paramsBuId
    );
    if (paramsBusiness) setBusiness(paramsBusiness);
  }, [user]);

  const ConfigModalStyles = {
    width: '10.25rem',
    maxWidth: '10.25rem',
    maxHeight: '4rem',
    height: '3.5rem',
    position: 'relative'
  };

  return (
    <div className="root">
      <div className="channelsContainer">
        <div className="channelsTitle">
          <FormControl variant="outlined" className={'field'} fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">
              Content Mode
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              defaultValue={contentMode}
              value={contentMode}
              onChange={e => setContentMode(e.target.value)}
              label="Content Mode"
              style={{ textAlign: 'right' }}
            >
              {Object.values(ContentMode).map((mode, i) => (
                <MenuItem key={i} value={mode}>
                  {mode}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="checkMarkConfigure">
            <span style={{ marginRight: 10 }}>
              {checkerValue} of 3 Configured
            </span>
            <Checkmark size={16} />
          </div>
        </div>
        <div className="channelsConfiguration">
          <Box className="channelsConfigurationContainer">
            <Box>
              <div className="socialMedia">
                <img src={whatsapp1} alt="whatsappIcon" />
                <div className="description">
                  <div style={{ display: 'flex' }}>
                    <h4 style={{ fontWeight: 600 }}>WhatsApp</h4>
                    <span className="checkmarkStyle">
                      {whatsappData?.handlers.length > 0 && (
                        <Checkmark size={16} />
                      )}
                    </span>
                  </div>
                  <p>
                    Integrate your WhatsApp business number to support customers
                    and offer instant resolutions
                  </p>
                </div>
              </div>
              <div className="configurationImageIcon">
                {whatsAppDetails.map((modalItem, i) => (
                  <ConfigModal
                    imgSrc={modalItem.image}
                    style={{ width: '88%' }}
                    spanStyle={{ marginTop: 5, marginLeft: '-6px' }}
                    version={'whatsapp'}
                    getVersion={whatsappData?.version}
                    handlers={whatsappData?.handlers}
                    priorityName={modalItem.priorityName}
                    campaignCheck={modalItem.campaignCheck}
                    disabled={modalItem.disabled}
                    inputFields={modalItem.fields}
                    initialState={modalItem.initialState}
                    fieldsToSchema={modalItem.schema}
                    getChannels={getChannels}
                    createStatus={createStatus}
                    setCreateStatus={setCreateStatus}
                    business={business}
                    width={ConfigModalStyles}
                    gridSpace={modalItem.gridSpace}
                    label={modalItem.label}
                    contentMode={contentMode}
                    key={i}
                    reset={setChannels}
                    user={user}
                  />
                ))}
              </div>
            </Box>
            <Box>
              <div className="socialMedia">
                <img src={Messaing2} alt="messagingIcon" />
                <div className="description">
                  <div style={{ display: 'flex' }}>
                    <h4 style={{ fontWeight: 600 }}>Messaging-SMS</h4>
                    <span className="checkmarkStyle">
                      {smsData?.handlers.length > 0 && <Checkmark size={16} />}
                    </span>
                  </div>
                  <p>
                    Offer instantaneous support on your website or app with
                    Freshdesk Messaging
                  </p>
                </div>
              </div>
              <div className="configurationImageIcon">
                {mesgDetails.map((modalItem, i) => (
                  <ConfigModal
                    imgSrc={modalItem.image}
                    style={{ width: '88%' }}
                    spanStyle={{ marginTop: 5, marginLeft: '-6px' }}
                    version={'sms'}
                    getVersion={smsData?.version}
                    handlers={smsData?.handlers}
                    priorityName={modalItem.priorityName}
                    campaignCheck={modalItem.campaignCheck}
                    inputFields={modalItem.fields}
                    initialState={modalItem.initialState}
                    fieldsToSchema={modalItem.schema}
                    getChannels={getChannels}
                    createStatus={createStatus}
                    setCreateStatus={setCreateStatus}
                    business={business}
                    disabled={modalItem.disabled}
                    width={ConfigModalStyles}
                    gridSpace={modalItem.gridSpace}
                    label={modalItem.label}
                    contentMode={contentMode}
                    key={i}
                    reset={setChannels}
                    user={user}
                  />
                ))}
              </div>
            </Box>
            <Box>
              <div className="socialMedia">
                <img src={Messaing2} alt="messagingIcon" />
                <div className="description">
                  <div style={{ display: 'flex' }}>
                    <h4 style={{ fontWeight: 600 }}>Messaging-RCS</h4>
                    <span className="checkmarkStyle">
                      {smsData?.handlers.length > 0 && <Checkmark size={16} />}
                    </span>
                  </div>
                  <p>
                    Offer instantaneous support on your website or app with
                    Freshdesk Messaging
                  </p>
                </div>
              </div>
              <div className="configurationImageIcon">
                {RcsProvidersDetails.map((modalItem, i) => (
                  <ConfigModal
                    imgSrc={modalItem.image}
                    style={{ width: '88%' }}
                    spanStyle={{ marginTop: 5, marginLeft: '-6px' }}
                    version={'rcs'}
                    getVersion={rcsData?.version}
                    handlers={rcsData?.handlers}
                    priorityName={modalItem.priorityName}
                    campaignCheck={modalItem.campaignCheck}
                    inputFields={modalItem.fields}
                    initialState={modalItem.initialState}
                    fieldsToSchema={modalItem.schema}
                    getChannels={getChannels}
                    createStatus={createStatus}
                    setCreateStatus={setCreateStatus}
                    business={business}
                    disabled={modalItem.disabled}
                    width={ConfigModalStyles}
                    gridSpace={modalItem.gridSpace}
                    label={modalItem.label}
                    contentMode={contentMode}
                    key={i}
                    reset={setChannels}
                    user={user}
                  />
                ))}
              </div>
            </Box>
            <Box>
              <div className="socialMedia">
                <img src={Email3} alt="emailIcon" />
                <div className="description">
                  <div style={{ display: 'flex' }}>
                    <h4 style={{ fontWeight: 600 }}>Email</h4>
                    <span className="checkmarkStyle">
                      {mailData?.handlers.length > 0 && <Checkmark size={16} />}
                    </span>
                  </div>
                  <p>
                    Integrate support mailboxes, configure DKIM, custom mail
                    servers, Bcc and more
                  </p>
                </div>
              </div>
              <div
                className="configurationImageIcon"
                style={{ justifyContent: 'flex-start' }}
              >
                {emailDetails.map((modalItem, i) => (
                  <ConfigModal
                    imgSrc={modalItem.image}
                    style={{ width: '88%' }}
                    spanStyle={{ marginTop: 5, marginLeft: '-6px' }}
                    version={'email'}
                    getVersion={mailData?.version}
                    handlers={mailData?.handlers}
                    priorityName={modalItem.priorityName}
                    campaignCheck={modalItem.campaignCheck}
                    inputFields={modalItem.fields}
                    initialState={modalItem.initialState}
                    fieldsToSchema={modalItem.schema}
                    getChannels={getChannels}
                    createStatus={createStatus}
                    setCreateStatus={setCreateStatus}
                    business={business}
                    disabled={modalItem.disabled}
                    width={ConfigModalStyles}
                    gridSpace={modalItem.gridSpace}
                    label={modalItem.label}
                    contentMode={contentMode}
                    key={i}
                    reset={setChannels}
                    user={user}
                  />
                ))}
              </div>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Channels;
