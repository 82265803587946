import { Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import useRouteMatch from '../../../hooks/useRouteMatch';

const journifySettings = [
  {
    label: 'Muffler Settings',
    path: '/journify/settings'
  },
  {
    label: 'Email Config',
    path: '/journify/settings/email-config'
  }
];

const JournifySettingsTabs = () => {
  const routeMatch = useRouteMatch(journifySettings.map(({ path }) => path));
  const currentTab = routeMatch.path;

  console.log(currentTab, routeMatch);

  return (
    <Tabs value={currentTab}>
      {journifySettings.map(({ label, path }) => (
        <Tab key={path} to={path} value={path} component={Link} label={label}>
          {label}
        </Tab>
      ))}
    </Tabs>
  );
};
export default JournifySettingsTabs;
