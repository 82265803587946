import React, { useEffect, useState } from 'react';
import { PageTitle } from '../components';
import PrintLabel from '../components/LabelPrinting/PrintLabel';
import CreateLabel from '../components/HelperComponents/CreateLabel';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import queryString from 'query-string';
import { getUiFilterPayloadById } from '../../../services/uiFilter.service';
import { CircularProgress } from '@material-ui/core';
import {
  LABELPRINTING,
  LABELPRINTING_EDIT
} from '../components/utils/Constants';
import Preview from '../components/LabelPrinting/Preview';

const Index = props => {
  const history = useHistory();
  const query = queryString.parse(window.location.search);
  const [payload, setPayload] = useState({});
  const [selectedState, setSelectedState] = useState();
  const [skuDetails, setSkuDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [offset, setOffSet] = useState(0);
  const [isErrorProductsVisible, setIsErrorProductsVisible] = useState(false);
  const [count, setCount] = useState(25);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  useEffect(() => {
    if (_.isEmpty(payload)) {
      const payloadId = query.payload;
      setSelectedState(localStorage.getItem('selected-option-label'));
      getUiFilterPayloadById(payloadId, LABELPRINTING, 0, 0, false).then(
        result => {
          setPayload(result.data);
          setIsErrorProductsVisible(result.data.payload.remove);
          setIsLoading(false);
        }
      );
    }
  }, [payload, query.payload]);
  const handleProductVisibleChange = state => {
    setIsLoading(true);
    const payloadId = query.payload;
    getUiFilterPayloadById(payloadId, LABELPRINTING_EDIT, 0, 0, state).then(
      result => {
        setPayload({ payload: result.data });
        setCurrentPageNumber(1);
        setOffSet(0);
        setCount(25);
        setIsErrorProductsVisible(state);
        setIsLoading(false);
      }
    );
  };
  if (isLoading) {
    return (
      <div
        style={{
          color: '#F2755F',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress color="inherit" />
      </div>
    );
  }
  return (
    <div>
      <div
        style={{
          width: '100%',
          height: '34px',
          borderBottom: '2px solid #F3F3F3'
        }}
      >
        <div
          style={{
            margin: '12px 14px',
            fontSize: '14pt',
            fontWeight: 600,
            color: '#999999'
          }}
        >
          <span>Create Label</span>
          <span style={{ margin: '0px 10px' }}>{'>'}</span>
          <span style={{ color: '#033333' }}>{selectedState}</span>
        </div>
      </div>
      <PageTitle
        title="PRINT LABEL"
        withBack={true}
        onClickBack={() => history.push('/labeling')}
      />
      <>
        <PrintLabel
          payload={payload}
          isErrorProductsVisible={isErrorProductsVisible}
          setIsErrorProductsVisible={handleProductVisibleChange}
          offset={offset}
          setCount={setCount}
          setOffSet={setOffSet}
          count={count}
          skuDetails={skuDetails}
          setSkuDetails={setSkuDetails}
          currentPageNumber={currentPageNumber}
          setCurrentPageNumber={setCurrentPageNumber}
        />
        <Preview skuDetails={skuDetails} />
      </>
      <CreateLabel
        id={_.get(payload, 'payload.data.id', -1)}
        offset={offset}
        countOfLabels={count}
        {...props}
      />
    </div>
  );
};
export default Index;
