/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { css } from '@emotion/react';
import classes from './CanvasWidgetWrapper.module.css';

export const Canvas = props => {
  return <div className={classes.canvas}>{props.children}</div>;
};

export const Expand = css`
  html,
  body,
  #root {
    height: 100%;
  }
`;
export const CanvasWidgetWrapper = props => {
  return (
    <>
      <Canvas
        className={classes.canvasWrapper}
        background={props.background || 'rgb(60, 60, 60)'}
        color={props.color || 'rgba(255,255,255, 0.05)'}
        registerDefaultZoomCanvasAction={false}
      >
        {props.children}
      </Canvas>
    </>
  );
};
