import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import _ from 'lodash';
import config from '../../../config';
export default function RejectModal(props) {
  const {
    openRejection,
    setOpen,
    reason,
    setReason,
    error,
    setError,
    claims,
    fetchClaimData,
    kamClaims,
    setSuccess
  } = props;

  const handleClose = () => {
    setReason('');
    setError('');
    setOpen(false);
  };
  const submit = () => {
    if (!reason.length) {
      setError('Enter a reason');
      return;
    }
    const body = {
      rejectReason: reason
    };
    fetch(
      `${config.casaBeatUrl}/expense/${_.get(claims, 'expense.id')}/reject`,
      {
        method: 'POST',
        headers: {
          cookie:
            'connect.sid=s%253AyhnxmBTIsRgpxYPOr50n2EvdyafIoAz1.WpbcAJFdRHDt8V2zsaaHsHVvM7biWuxJFmAS28qSao0',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }
    )
      .then(response => {
        setReason('');
        setOpen(false);
        setSuccess(true);
        fetchClaimData(kamClaims.fromDate, kamClaims.toDate);
      })
      .catch(err => {
        console.error(err);
      });
  };

  return (
    <div>
      <Dialog
        open={openRejection}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            <strong>Enter a Reject reason to reject the chosen claim</strong>
            <div>
              <strong style={{ display: 'flex', justifyContent: 'center' }}>
                Chosen Claim
              </strong>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Claimed date:</span>
                <div>{_.get(claims, 'expense.submissionDate')}</div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Claimed amount:</span>
                <div>₹{_.get(claims, 'expense.totalClaimAmount')}</div>
              </div>
            </div>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Reject Reason"
            fullWidth
            value={reason || ''}
            onChange={e => {
              if (error.length) {
                setError('');
              }
              setReason(e.target.value);
            }}
            error={error}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={submit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
