import React from 'react';
import { Button } from '@material-ui/core';

const ButtonField = props => {
  const {
    label,
    className,
    onClick,
    type,
    color,
    startIcon,
    variant,
    style,
    disabled
  } = props;
  return (
    <>
      <Button
        disableElevation
        variant={variant}
        className={className}
        onClick={onClick}
        type={type}
        color={color}
        startIcon={startIcon}
        style={style}
        disabled={disabled}
      >
        {label}
      </Button>
    </>
  );
};

export default ButtonField;
