import React from 'react';
import { makeStyles, Grid, TextField } from '@material-ui/core';
import _ from 'lodash';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: '24px 110px',
    textAlign: 'center'
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold'
  }
}));

const PageTitle = ({
  title,
  withBack = false,
  onClickBack,
  skuDetails,
  withStore = false,
  storeDetails,
  setCurrentStore,
  currentStore = ''
}) => {
  const classes = useStyles();
  const getStoreDetails = () => {
    return _.map(storeDetails, storeDetail => storeDetail.store);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Grid container className={classes.paper} justify="center">
          <Grid item xs={2} className="pointer f20">
            {withBack && (
              <div onClick={onClickBack} className={'float-left'}>
                <span>
                  <img
                    className="l-px1"
                    style={{ paddingBottom: '0.1rem', textAlign: 'left' }}
                    src={require('../../assets/arrow-back.svg')}
                    alt={'img'}
                  />
                </span>
                <span>BACK</span>
              </div>
            )}
          </Grid>
          <Grid container item xs={8} justify="center">
            <div className={classes.title}>{title}</div>
          </Grid>
          <Grid item xs={2}>
            {withStore && (
              <Autocomplete
                id="combo-box-demo"
                options={getStoreDetails()}
                size={'small'}
                getOptionLabel={option => option}
                style={{ width: 220, margin: '5px 0px' }}
                defaultValue={currentStore}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Choose Store"
                    variant="outlined"
                    size={'small'}
                  />
                )}
                onChange={(event, value) => {
                  setCurrentStore(value);
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { PageTitle };
