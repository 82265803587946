import { FC } from 'react';
import React from 'react';
interface ImageContainerProps {
  image: string;
}
export const ImageContainer: FC<ImageContainerProps> = ({ image }) => {
  return (
    <img
      src={image}
      alt={'layout'}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0'
      }}
    />
  );
};
