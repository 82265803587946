import * as React from 'react';

function HeaderImage(props) {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 1800 300"
      xmlSpace="preserve"
      width="100%"
      height="300px"
      {...props}
    >
      <style>
        {
          '.prefix__st0{fill:#f9efec}.prefix__st1{opacity:.63}.prefix__st2{fill:#faccc3}.prefix__st3{fill:#ffb0a2}.prefix__st4{opacity:.398}.prefix__st5{opacity:.5}.prefix__st7{fill:#e6e6e6}.prefix__st8{fill:#fff}.prefix__st13{fill:#f07663}.prefix__st14{fill:#e0e0e0}.prefix__st22{fill:#cfd8dc}.prefix__st23{opacity:.1;enable-background:new}.prefix__st27{fill:none;stroke:#3ad29f;stroke-width:2;stroke-miterlimit:10;stroke-dasharray:12}'
        }
      </style>
      <g id="prefix__Component_238_1">
        <g id="prefix__Component_208_4" transform="translate(-2681 -7639.299)">
          <g id="prefix__Group_7938">
            <path
              id="prefix__Path_1257"
              className="prefix__st0"
              d="M4060.2 7925s122.8-33 221.2-57.7c98.4-24.7 199.6 9.9 199.6 9.9l-.7 62.2H3940l120.2-14.4z"
            />
            <g id="prefix__Group_2534" transform="translate(3519.5 7441.329)">
              <g
                id="prefix__Group_2530"
                transform="translate(181.65 128.665)"
                className="prefix__st1"
              >
                <g id="prefix__Group_36" transform="translate(134.558 133.804)">
                  <circle
                    id="prefix__Ellipse_68"
                    className="prefix__st2"
                    cx={494.4}
                    cy={67.2}
                    r={31.7}
                  />
                  <path
                    id="prefix__Path_198"
                    className="prefix__st3"
                    d="M503 113.4l-7.8 4.8V85.6l8.7-5.4c.7-.4 1.2-1.1 1.5-2-.8-.2-1.7 0-2.4.4l-7.8 4.8V70.9c0-2.4-.3-4.9-.9-7.3-.6 2.4-.9 4.8-.9 7.3v20.6l-11.5-7.1c-.9-.6-2-.9-3.1-.8.5 1 1.2 1.8 2.1 2.4l12.4 7.7v70.7h1.8v-44l8.7-5.4c.7-.4 1.2-1.1 1.5-2-.7-.2-1.6 0-2.3.4z"
                  />
                </g>
              </g>
              <g
                id="prefix__Group_2531"
                transform="translate(218.16 160.978)"
                className="prefix__st4"
              >
                <g
                  id="prefix__Group_36-2"
                  transform="translate(134.558 133.804)"
                >
                  <circle
                    id="prefix__Ellipse_68-2"
                    className="prefix__st2"
                    cx={497.2}
                    cy={68.2}
                    r={19.7}
                  />
                  <path
                    id="prefix__Path_198-2"
                    className="prefix__st3"
                    d="M502.5 97.1l-4.9 3V79.7l5.4-3.4c.5-.3.8-.7.9-1.2-.5-.1-1.1 0-1.5.3l-4.9 3v-7.9c0-1.5-.2-3-.6-4.5-.4 1.5-.6 3-.6 4.5v12.9l-7.2-4.4c-.6-.4-1.2-.5-1.9-.5.3.6.7 1.1 1.3 1.5l7.7 4.8v44.1h1.1v-27.4l5.4-3.4c.5-.3.8-.7.9-1.2-.1-.2-.7-.1-1.1.2z"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="prefix__Group_7937">
            <path
              id="prefix__Path_6836"
              className="prefix__st0"
              d="M2957 7926.7s-80.6-29-145.1-50.7c-64.5-21.7-130.9 8.7-130.9 8.7l.4 54.6h354.4l-78.8-12.6z"
            />
            <g id="prefix__Group_2534-2" transform="translate(2385.5 7463.78)">
              <g
                id="prefix__Group_2530-2"
                transform="translate(316.208 262.469)"
                className="prefix__st1"
              >
                <g id="prefix__Group_36-3">
                  <circle
                    id="prefix__Ellipse_68-3"
                    className="prefix__st2"
                    cx={35}
                    cy={71.2}
                    r={26.7}
                  />
                  <path
                    id="prefix__Path_198-3"
                    className="prefix__st3"
                    d="M42.2 110.2l-6.6 4.1V86.7l7.4-4.6c.6-.4 1.1-1 1.2-1.7-.7-.1-1.4 0-2 .4L35.6 85V74.3c0-2.1-.3-4.1-.8-6.1-.5 2-.8 4.1-.8 6.1v17.4l-9.7-6c-.8-.5-1.7-.7-2.6-.7.4.8 1 1.5 1.8 2L34 93.5V153h1.5v-37l7.4-4.6c.6-.4 1.1-1 1.2-1.7-.6-.1-1.3.1-1.9.5z"
                  />
                </g>
              </g>
              <g
                id="prefix__Group_2531-2"
                transform="translate(346.96 289.685)"
                className="prefix__st4"
              >
                <g id="prefix__Group_36-4">
                  <circle
                    id="prefix__Ellipse_68-4"
                    className="prefix__st2"
                    cx={37.2}
                    cy={72.1}
                    r={16.6}
                  />
                  <path
                    id="prefix__Path_198-4"
                    className="prefix__st3"
                    d="M41.7 96.4l-4.1 2.5V81.8l4.6-2.8c.4-.2.7-.6.8-1-.4-.1-.9 0-1.3.2l-4.1 2.5V74c0-1.3-.2-2.6-.5-3.8-.3 1.2-.5 2.5-.5 3.8v10.8l-6-3.7c-.5-.3-1-.5-1.6-.4.2.5.6.9 1.1 1.2l6.5 4V123h.9v-23l4.6-2.8c.4-.2.7-.6.8-1-.3-.2-.8-.1-1.2.2z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="prefix__Group_7965" transform="translate(228)">
          <g
            id="prefix__Group_7947"
            transform="translate(58.138 51.802)"
            className="prefix__st5"
          >
            <g id="prefix__Group_7946" className="prefix__st5">
              <linearGradient
                id="prefix__Rectangle_6726_00000124876833971368134280000010926822680911548346_"
                gradientUnits="userSpaceOnUse"
                x1={274.067}
                y1={553.92}
                x2={274.067}
                y2={556.079}
                gradientTransform="matrix(.0809 -8.0896 -6.7797 -.0678 3929.4 2291.595)"
              >
                <stop offset={0} stopColor="gray" stopOpacity={0.251} />
                <stop offset={0.54} stopColor="gray" stopOpacity={0.122} />
                <stop offset={1} stopColor="gray" stopOpacity={0.102} />
              </linearGradient>
              <path
                id="prefix__Rectangle_6726"
                fill="url(#prefix__Rectangle_6726_00000124876833971368134280000010926822680911548346_)"
                d="M114.7 41.8l.1-11.3 9.5.1-.1 11.3z"
              />
            </g>
            <path
              id="prefix__Rectangle_6727"
              transform="matrix(.009 -1 1 .009 82.189 155.058)"
              className="prefix__st7"
              d="M113.9 31.5h10.9v9.2h-10.9z"
            />
            <circle
              id="prefix__Ellipse_584"
              className="prefix__st8"
              cx={119.3}
              cy={35.8}
              r={1.7}
            />
            <circle
              id="prefix__Ellipse_585"
              className="prefix__st8"
              cx={119.3}
              cy={36.3}
              r={1.5}
            />
            <path
              id="prefix__Rectangle_6728"
              transform="matrix(.009 -1 1 .009 80.357 156.799)"
              className="prefix__st8"
              d="M118.7 37.5h1.2v.7h-1.2z"
            />
            <path
              id="prefix__Path_6837"
              className="prefix__st8"
              d="M120.9 41.5h-3.5v-1.6c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8l-.1 1.6z"
            />
            <circle
              id="prefix__Ellipse_586"
              className="prefix__st8"
              cx={119.3}
              cy={34.1}
              r={0.6}
            />
            <path
              id="prefix__Path_6838"
              className="prefix__st8"
              d="M119.1 33.1c-.3 0-.7 0-1 .2-.3.1-.5.2-.7.4-.4.4-.5 1-.5 1.5s0 1.1-.2 1.6c-.3.5-.8.9-1.5.9.4.2.8.2 1.2 0 .3-.2.6-.4.8-.7.1-.2.2-.5.3-.7.3-.7.6-1.4 1-2 .1-.1.1-.2.2-.3.1-.1.3-.2.4-.2.1 0 .4 0 .4-.1.3-.3-.2-.6-.4-.6z"
            />
          </g>
          <g
            id="prefix__Group_7949"
            transform="translate(145.649 50.317)"
            className="prefix__st5"
          >
            <g id="prefix__Group_7948" className="prefix__st5">
              <linearGradient
                id="prefix__Rectangle_6729_00000054257712626676605370000002414543739373576873_"
                gradientUnits="userSpaceOnUse"
                x1={172.502}
                y1={464.493}
                x2={172.502}
                y2={466.302}
                gradientTransform="matrix(9.117 -.08205 -.0979 -10.878 -1370.335 5180.63)"
              >
                <stop offset={0} stopColor="gray" stopOpacity={0.251} />
                <stop offset={0.54} stopColor="gray" stopOpacity={0.122} />
                <stop offset={1} stopColor="gray" stopOpacity={0.102} />
              </linearGradient>
              <path
                id="prefix__Rectangle_6729"
                fill="url(#prefix__Rectangle_6729_00000054257712626676605370000002414543739373576873_)"
                d="M149.8 30l12.7-.1.2 15.2-12.8.1z"
              />
            </g>
            <path
              id="prefix__Rectangle_6730"
              transform="matrix(1 -.009 .009 1 -.332 1.407)"
              className="prefix__st7"
              d="M150.1 30.2h12.3v14.6h-12.3z"
            />
            <circle
              id="prefix__Ellipse_587"
              className="prefix__st8"
              cx={156.3}
              cy={37.1}
              r={2.3}
            />
            <circle
              id="prefix__Ellipse_588"
              className="prefix__st8"
              cx={156.3}
              cy={37.9}
              r={2.1}
            />
            <path
              id="prefix__Rectangle_6731"
              transform="matrix(1 -.009 .009 1 -.353 1.408)"
              className="prefix__st8"
              d="M155.8 39.1h1v1.7h-1z"
            />
            <path
              id="prefix__Path_6839"
              className="prefix__st8"
              d="M156.4 40.3c1.3 0 2.4 1 2.4 2.4v2.2h-4.7v-2.2c-.1-1.3 1-2.4 2.3-2.4z"
            />
            <circle
              id="prefix__Ellipse_589"
              className="prefix__st8"
              cx={156.2}
              cy={34.8}
              r={0.8}
            />
            <path
              id="prefix__Path_6840"
              className="prefix__st8"
              d="M156.5 33.5c.4 0 .9 0 1.3.2.4.1.7.3 1 .5.5.6.7 1.3.7 2-.1.7 0 1.5.3 2.2.4.7 1.1 1.2 2 1.1-.5.3-1.1.3-1.6.1-.5-.2-.8-.5-1.1-1-.2-.3-.3-.6-.4-.9-.3-.9-.8-1.8-1.4-2.7-.1-.1-.2-.3-.3-.4-.2-.1-.4-.2-.6-.2-.2 0-.5.1-.6-.1-.3-.3.4-.7.7-.8z"
            />
          </g>
          <g
            id="prefix__Group_7951"
            transform="translate(132.779 4.8)"
            className="prefix__st5"
          >
            <g id="prefix__Group_7950" className="prefix__st5">
              <linearGradient
                id="prefix__Rectangle_6732_00000176032244999767356740000003462493583940512643_"
                gradientUnits="userSpaceOnUse"
                x1={203.146}
                y1={522.418}
                x2={203.146}
                y2={524.228}
                gradientTransform="matrix(14.458 -.1301 -.1553 -17.251 -2700.398 9145.96)"
              >
                <stop offset={0} stopColor="gray" stopOpacity={0.251} />
                <stop offset={0.54} stopColor="gray" stopOpacity={0.122} />
                <stop offset={1} stopColor="gray" stopOpacity={0.102} />
              </linearGradient>
              <path
                id="prefix__Rectangle_6732"
                fill="url(#prefix__Rectangle_6732_00000176032244999767356740000003462493583940512643_)"
                d="M144.6 11.8l20.2-.2.3 24.2-20.3.2z"
              />
            </g>
            <path
              id="prefix__Rectangle_6733"
              transform="matrix(1 -.009 .009 1 -.208 1.395)"
              className="prefix__st7"
              d="M145.1 12.2h19.6v23.2h-19.6z"
            />
            <circle
              id="prefix__Ellipse_590"
              className="prefix__st8"
              cx={154.9}
              cy={23.1}
              r={3.6}
            />
            <circle
              id="prefix__Ellipse_591"
              className="prefix__st8"
              cx={154.9}
              cy={24.3}
              r={3.3}
            />
            <path
              id="prefix__Rectangle_6734"
              transform="matrix(1 -.009 .009 1 -.242 1.396)"
              className="prefix__st8"
              d="M154.2 26.3h1.5v2.6h-1.5z"
            />
            <path
              id="prefix__Path_6841"
              className="prefix__st8"
              d="M155.2 27.9c2.1 0 3.8 1.6 3.8 3.7V35l-7.5.1v-3.4c-.1-2.1 1.6-3.7 3.7-3.8z"
            />
            <circle
              id="prefix__Ellipse_592"
              className="prefix__st8"
              cx={154.9}
              cy={19.5}
              r={1.3}
            />
            <path
              id="prefix__Path_6842"
              className="prefix__st8"
              d="M155.3 17.4c.7-.1 1.4 0 2.1.3.6.2 1.1.5 1.6.9.8.9 1.1 2 1 3.2-.1 1.2.1 2.3.5 3.4.6 1.1 1.8 1.8 3.1 1.8-.8.5-1.7.5-2.5.1-.7-.3-1.3-.9-1.7-1.6-.2-.5-.5-1-.6-1.5-.6-1.5-1.3-2.9-2.2-4.2-.1-.2-.3-.4-.5-.6-.3-.2-.6-.3-.9-.4-.3 0-.8.1-.9-.2-.5-.5.6-1.1 1-1.2z"
            />
          </g>
          <g
            id="prefix__Group_7953"
            transform="translate(71.346 4.141)"
            className="prefix__st5"
          >
            <g id="prefix__Group_7952" className="prefix__st5">
              <linearGradient
                id="prefix__Rectangle_6735_00000049915551250114405200000001127246367685469586_"
                gradientUnits="userSpaceOnUse"
                x1={263.825}
                y1={528.418}
                x2={263.825}
                y2={530.186}
                gradientTransform="matrix(14.886 -.134 -.1558 -17.315 -3714.507 9201.232)"
              >
                <stop offset={0} stopColor="gray" stopOpacity={0.251} />
                <stop offset={0.54} stopColor="gray" stopOpacity={0.122} />
                <stop offset={1} stopColor="gray" stopOpacity={0.102} />
              </linearGradient>
              <path
                id="prefix__Rectangle_6735"
                fill="url(#prefix__Rectangle_6735_00000049915551250114405200000001127246367685469586_)"
                d="M120 11.5l20.8-.1.2 24.2-20.8.2z"
              />
            </g>
            <path
              id="prefix__Rectangle_6736"
              transform="matrix(1 -.009 .009 1 -.21 1.176)"
              className="prefix__st7"
              d="M120.5 11.9h20.1v23.8h-20.1z"
            />
            <circle
              id="prefix__Ellipse_593"
              className="prefix__st8"
              cx={130.4}
              cy={23.2}
              r={3.7}
            />
            <circle
              id="prefix__Ellipse_594"
              className="prefix__st8"
              cx={130.4}
              cy={24.4}
              r={3.4}
            />
            <path
              id="prefix__Rectangle_6737"
              transform="matrix(1 -.009 .009 1 -.244 1.175)"
              className="prefix__st8"
              d="M129.6 26.4h1.6v2.7h-1.6z"
            />
            <path
              id="prefix__Path_6843"
              className="prefix__st8"
              d="M130.6 28.3c2.1 0 3.9 1.7 3.9 3.8v3.5l-7.7.1v-3.5c-.1-2.1 1.6-3.9 3.8-3.9z"
            />
            <path
              id="prefix__Path_6844"
              className="prefix__st8"
              d="M127.8 20.5s2.2-1.4 2.7-2.8c.7 1.3 1.6 2.5 2.6 3.6l-5.3-.8z"
            />
          </g>
          <linearGradient
            id="prefix__Rectangle_6738_00000044886055454214371870000007498322547930829986_"
            gradientUnits="userSpaceOnUse"
            x1={358.909}
            y1={540.793}
            x2={358.909}
            y2={542.194}
            gradientTransform="matrix(60.677 0 0 -59.456 -21643.62 32435.14)"
          >
            <stop offset={0} stopColor="gray" stopOpacity={0.251} />
            <stop offset={0.54} stopColor="gray" stopOpacity={0.122} />
            <stop offset={1} stopColor="gray" stopOpacity={0.102} />
          </linearGradient>
          <path
            id="prefix__Rectangle_6738"
            fill="url(#prefix__Rectangle_6738_00000044886055454214371870000007498322547930829986_)"
            d="M91.4 198.5h85v83.3h-85z"
          />
          <path
            id="prefix__Rectangle_6739"
            className="prefix__st8"
            d="M91.8 199.1h84.1v82H91.8z"
          />
          <path
            id="prefix__Rectangle_6740"
            className="prefix__st13"
            d="M100.3 205.9h67.1V258h-67.1z"
          />
          <path
            id="prefix__Rectangle_6741"
            className="prefix__st14"
            d="M104.4 263.5h59.2v2.7h-59.2z"
          />
          <path
            id="prefix__Rectangle_6742"
            className="prefix__st14"
            d="M113.9 269h40.3v2.7h-40.3z"
          />
          <g
            id="prefix__Group_7954"
            transform="translate(98.007 40.33)"
            className="prefix__st5"
          >
            <linearGradient
              id="prefix__Rectangle_6743_00000067924078072375848250000001529502257756864168_"
              gradientUnits="userSpaceOnUse"
              x1={247.534}
              y1={494.546}
              x2={247.534}
              y2={495.946}
              gradientTransform="matrix(21.415 0 0 -24.361 -5155.278 12107.612)"
            >
              <stop offset={0} stopColor="gray" stopOpacity={0.251} />
              <stop offset={0.54} stopColor="gray" stopOpacity={0.122} />
              <stop offset={1} stopColor="gray" stopOpacity={0.102} />
            </linearGradient>
            <path
              id="prefix__Rectangle_6743"
              fill="url(#prefix__Rectangle_6743_00000067924078072375848250000001529502257756864168_)"
              d="M130.7 25.9h30V60h-30z"
            />
          </g>
          <path
            id="prefix__Rectangle_6744"
            className="prefix__st7"
            d="M229.6 66.7H258v33.7h-28.4z"
          />
          <circle
            id="prefix__Ellipse_595"
            className="prefix__st8"
            cx={243.5}
            cy={82.5}
            r={5.2}
          />
          <circle
            id="prefix__Ellipse_596"
            className="prefix__st8"
            cx={243.5}
            cy={84.3}
            r={4.8}
          />
          <path
            id="prefix__Rectangle_6745"
            className="prefix__st8"
            d="M242.4 87.1h2.2v3.8h-2.2z"
          />
          <path
            id="prefix__Path_6845"
            className="prefix__st8"
            d="M243 89.8h1.6c2.6 0 4.7 2.1 4.7 4.7v5.8h-10.9v-5.8c-.1-2.6 2-4.7 4.6-4.7z"
          />
          <path
            id="prefix__Path_6846"
            className="prefix__st8"
            d="M241 77c-.3.2-.7.4-1 .6-.3.1-.5.4-.7.7 0 .1-.1.2-.2.3-.1.2-.4.2-.6.2-.7.2-1.1.9-1.3 1.6-.1.4-.2.8-.3 1.3-.1.3-.1.6 0 .8 0 .1.1.2.1.3.1.4-.2.8-.3 1.3 0 .4 0 .8.2 1.2 0 .1.1.2.2.3l.2.2c.2.2.3.4.4.6.8.1 1.5.3 2.2.6 1.1.6 2.3.9 3.6 1 .3 0 .7-.1 1-.2.6-.2 1.2-.7 1.8-.9l1.8-.3c.3-.1.6-.3.5-.5.3.1.6 0 .9-.2.2-.2.4-.5.5-.7.3-.5.5-1 .6-1.5.2-1 .1-2-.4-2.9-.1-.2-.2-.3-.4-.5-.1-.1-.3-.1-.4-.2-.1-.1-.2-.2-.3-.4-.4-.7-.8-1.3-1.3-1.9-.4-.7-1-1.2-1.6-1.5-1-.4-1.8.2-2.8.3-.4-.1-.9-.1-1.3-.1-.2.1-.7.2-1.1.5z"
          />
          <g
            id="prefix__Group_7955"
            transform="translate(59.126 12.964)"
            className="prefix__st5"
          >
            <linearGradient
              id="prefix__Rectangle_6746_00000160175926439742601250000004132049136623654839_"
              gradientUnits="userSpaceOnUse"
              x1={281.891}
              y1={520.557}
              x2={281.891}
              y2={521.958}
              gradientTransform="matrix(18.31 0 0 -20.832 -5033.518 10888.323)"
            >
              <stop offset={0} stopColor="gray" stopOpacity={0.251} />
              <stop offset={0.54} stopColor="gray" stopOpacity={0.122} />
              <stop offset={1} stopColor="gray" stopOpacity={0.102} />
            </linearGradient>
            <path
              id="prefix__Rectangle_6746"
              fill="url(#prefix__Rectangle_6746_00000160175926439742601250000004132049136623654839_)"
              d="M115.1 14.9h25.6v29.2h-25.6z"
            />
          </g>
          <path
            id="prefix__Rectangle_6747"
            className="prefix__st7"
            d="M175 28.3h24.3v28.8H175z"
          />
          <circle
            id="prefix__Ellipse_597"
            className="prefix__st8"
            cx={186.9}
            cy={41.8}
            r={4.5}
          />
          <circle
            id="prefix__Ellipse_598"
            className="prefix__st8"
            cx={186.9}
            cy={43.3}
            r={4.1}
          />
          <path
            id="prefix__Rectangle_6748"
            className="prefix__st8"
            d="M185.9 45.8h1.9v3.3h-1.9z"
          />
          <path
            id="prefix__Path_6847"
            className="prefix__st8"
            d="M187.1 48.1c2.6 0 4.7 2.1 4.7 4.7V57h-9.4v-4.3c0-2.5 2.1-4.6 4.7-4.6z"
          />
          <path
            id="prefix__Path_6848"
            className="prefix__st8"
            d="M184.8 37.1c-.3.2-.6.3-.8.5-.2.1-.5.3-.6.6 0 .1-.1.2-.1.3-.1.1-.4.1-.5.2-.6.1-.9.8-1.1 1.4-.1.4-.2.7-.2 1.1-.1.2-.1.5 0 .7 0 .1.1.2.1.3.1.4-.2.7-.3 1.1 0 .3 0 .7.2 1 0 .1.1.2.1.3l.2.2c.1.1.2.3.3.5.6.1 1.3.3 1.9.5.9.5 2 .8 3.1.9.3 0 .6-.1.9-.2.5-.2 1-.6 1.5-.7l1.5-.3c.2 0 .5-.2.5-.5.2.1.5 0 .7-.1.2-.2.3-.4.4-.6.2-.4.4-.8.5-1.3.2-.8.1-1.7-.3-2.5-.1-.2-.2-.3-.3-.4-.1-.1-.2-.1-.3-.2-.1-.1-.2-.2-.2-.3-.3-.6-.7-1.1-1.1-1.6-.3-.6-.8-1-1.4-1.3-.9-.4-1.5.2-2.4.2-.4-.1-.7-.1-1.1-.1-.5 0-.9.1-1.2.3z"
          />
          <g
            id="prefix__Group_7956"
            transform="translate(188.511 41.602)"
            className="prefix__st5"
          >
            <linearGradient
              id="prefix__Rectangle_6749_00000057140116507383248970000017871575829862483864_"
              gradientUnits="userSpaceOnUse"
              x1={157.388}
              y1={488.835}
              x2={157.388}
              y2={490.644}
              gradientTransform="matrix(20.198 -.202 -.241 -24.1018 -2878.606 11981.682)"
            >
              <stop offset={0} stopColor="gray" stopOpacity={0.251} />
              <stop offset={0.54} stopColor="gray" stopOpacity={0.122} />
              <stop offset={1} stopColor="gray" stopOpacity={0.102} />
            </linearGradient>
            <path
              id="prefix__Rectangle_6749"
              fill="url(#prefix__Rectangle_6749_00000057140116507383248970000017871575829862483864_)"
              d="M166.9 26.7l28.3-.3.4 33.7-28.3.3z"
            />
          </g>
          <path
            id="prefix__Rectangle_6750"
            transform="matrix(1 -.009 .009 1 -.753 3.33)"
            className="prefix__st7"
            d="M356 69.1h27.3v32.4H356z"
          />
          <circle
            id="prefix__Ellipse_599"
            className="prefix__st8"
            cx={369.8}
            cy={84.4}
            r={5}
          />
          <circle
            id="prefix__Ellipse_600"
            className="prefix__st8"
            cx={369.8}
            cy={86.1}
            r={4.6}
          />
          <path
            id="prefix__Rectangle_6751"
            transform="matrix(1 -.009 .009 1 -.8 3.332)"
            className="prefix__st8"
            d="M368.7 88.8h2.1v3.7h-2.1z"
          />
          <path
            id="prefix__Path_6849"
            className="prefix__st8"
            d="M369.5 91.4h1.2c2.6 0 4.7 2.1 4.7 4.6v5.4l-10.5.1v-5.4c-.1-2.5 2-4.7 4.6-4.7z"
          />
          <circle
            id="prefix__Ellipse_601"
            className="prefix__st8"
            cx={369.7}
            cy={79.3}
            r={1.8}
          />
          <path
            id="prefix__Path_6850"
            className="prefix__st8"
            d="M370.3 76.4c1-.1 2 .1 2.9.5.8.2 1.6.7 2.2 1.2 1.1 1.2 1.6 2.8 1.5 4.5-.1 1.6.1 3.2.7 4.7.8 1.6 2.5 2.6 4.3 2.5-1.1.6-2.4.7-3.6.1-1-.5-1.8-1.2-2.4-2.2-.3-.7-.6-1.3-.9-2-.8-2.1-1.8-4.1-3.1-5.9-.2-.3-.4-.6-.7-.8-.4-.3-.8-.5-1.3-.5-.4-.1-1.1.1-1.3-.3-.4-.8 1.1-1.7 1.7-1.8z"
          />
          <g
            id="prefix__Group_7957"
            transform="translate(31.559 23.766)"
            className="prefix__st5"
          >
            <linearGradient
              id="prefix__Rectangle_6752_00000078046304742105736260000015658638000399637181_"
              gradientUnits="userSpaceOnUse"
              x1={307.091}
              y1={509.987}
              x2={307.091}
              y2={511.796}
              gradientTransform="matrix(17.2701 -.1727 -.2061 -20.608 -5082.06 10604.014)"
            >
              <stop offset={0} stopColor="gray" stopOpacity={0.251} />
              <stop offset={0.54} stopColor="gray" stopOpacity={0.122} />
              <stop offset={1} stopColor="gray" stopOpacity={0.102} />
            </linearGradient>
            <path
              id="prefix__Rectangle_6752"
              fill="url(#prefix__Rectangle_6752_00000078046304742105736260000015658638000399637181_)"
              d="M104 19.5l24.2-.3.3 28.9-24.2.2z"
            />
          </g>
          <path
            id="prefix__Rectangle_6753"
            transform="matrix(1 -.009 .009 1 -.513 1.332)"
            className="prefix__st7"
            d="M136.1 43.8h23.4v27.7h-23.4z"
          />
          <circle
            id="prefix__Ellipse_602"
            className="prefix__st8"
            cx={147.8}
            cy={56.9}
            r={4.3}
          />
          <circle
            id="prefix__Ellipse_603"
            className="prefix__st8"
            cx={147.9}
            cy={58.3}
            r={3.9}
          />
          <path
            id="prefix__Rectangle_6754"
            transform="matrix(1 -.009 .009 1 -.554 1.333)"
            className="prefix__st8"
            d="M147 60.6h1.8v3.1H147z"
          />
          <path
            id="prefix__Path_6851"
            className="prefix__st8"
            d="M148.1 62.9c2.5 0 4.5 2 4.5 4.5v4.1l-9 .1v-4.1c0-2.6 2-4.6 4.5-4.6z"
          />
          <circle
            id="prefix__Ellipse_604"
            className="prefix__st8"
            cx={147.8}
            cy={52.5}
            r={1.6}
          />
          <path
            id="prefix__Path_6852"
            className="prefix__st8"
            d="M148.3 50c.8-.1 1.7 0 2.5.4.7.2 1.3.6 1.9 1 .9 1.1 1.4 2.4 1.3 3.8-.1 1.4.1 2.8.6 4 .7 1.4 2.2 2.2 3.7 2.2-.9.5-2.1.6-3 .1-.9-.4-1.6-1-2.1-1.9-.3-.6-.5-1.1-.7-1.7-.7-1.8-1.5-3.5-2.6-5.1-.2-.3-.4-.5-.6-.7-.3-.3-.7-.4-1.1-.4-.3 0-.9.1-1.1-.2-.6-.7.7-1.4 1.2-1.5z"
          />
          <g
            id="prefix__Group_7958"
            transform="translate(1.1 43.683)"
            className="prefix__st5"
          >
            <linearGradient
              id="prefix__Rectangle_6755_00000173849562278454605030000010235057773978394037_"
              gradientUnits="userSpaceOnUse"
              x1={341.813}
              y1={490.656}
              x2={341.813}
              y2={492.424}
              gradientTransform="matrix(20.796 -.1872 -.2177 -24.19 -6894.698 12008.377)"
            >
              <stop offset={0} stopColor="gray" stopOpacity={0.251} />
              <stop offset={0.54} stopColor="gray" stopOpacity={0.122} />
              <stop offset={1} stopColor="gray" stopOpacity={0.102} />
            </linearGradient>
            <path
              id="prefix__Rectangle_6755"
              fill="url(#prefix__Rectangle_6755_00000173849562278454605030000010235057773978394037_)"
              d="M91.8 27.5l29.1-.3.4 33.9-29.2.3z"
            />
          </g>
          <path
            id="prefix__Rectangle_6756"
            transform="matrix(1 -.009 .009 1 -.79 .973)"
            className="prefix__st7"
            d="M93.7 71.7h28v33.2h-28z"
          />
          <circle
            id="prefix__Ellipse_605"
            className="prefix__st8"
            cx={107.4}
            cy={87.4}
            r={5.2}
          />
          <circle
            id="prefix__Ellipse_606"
            className="prefix__st8"
            cx={107.5}
            cy={89.1}
            r={4.7}
          />
          <path
            id="prefix__Rectangle_6757"
            transform="matrix(1 -.009 .009 1 -.84 .971)"
            className="prefix__st8"
            d="M106.4 91.9h2.2v3.8h-2.2z"
          />
          <path
            id="prefix__Path_6853"
            className="prefix__st8"
            d="M107 94.6h1.4c2.6 0 4.7 2.1 4.7 4.6l.1 5.7-10.8.1-.1-5.6c.1-2.7 2.1-4.8 4.7-4.8z"
          />
          <path
            id="prefix__Path_6854"
            className="prefix__st8"
            d="M103.8 83.6s3.1-1.9 3.7-3.9c.9 1.9 2.2 3.5 3.7 5l-7.4-1.1z"
          />
          <circle
            id="prefix__Ellipse_607"
            className="prefix__st8"
            cx={133.4}
            cy={227.8}
            r={8.9}
          />
          <circle
            id="prefix__Ellipse_608"
            className="prefix__st8"
            cx={133.4}
            cy={230.8}
            r={8.1}
          />
          <path
            id="prefix__Rectangle_6758"
            transform="matrix(1 -.009 .009 1 -2.145 1.211)"
            className="prefix__st8"
            d="M131.6 235.7h3.8v6.5h-3.8z"
          />
          <path
            id="prefix__Path_6855"
            className="prefix__st8"
            d="M133.7 240.3h.6c5 0 9.1 4 9.1 9l.1 8.8-18.7.2-.1-8.8c-.1-5.1 3.9-9.2 9-9.2-.1 0 0 0 0 0z"
          />
          <path
            id="prefix__Path_6856"
            className="prefix__st8"
            d="M127.1 221.4s5.4-3.3 6.4-6.8c1.6 3.2 3.7 6.1 6.3 8.6l-12.7-1.8z"
          />
          <g
            id="prefix__Group_7959"
            transform="translate(141.558 13.81)"
            className="prefix__st5"
          >
            <linearGradient
              id="prefix__Rectangle_6759_00000114043416576427994480000000210055055616215946_"
              gradientUnits="userSpaceOnUse"
              x1={199.771}
              y1={522.186}
              x2={199.771}
              y2={524.031}
              gradientTransform="matrix(17.343 -.1561 -.1783 -19.813 -3211.525 10362.26)"
            >
              <stop offset={0} stopColor="gray" stopOpacity={0.251} />
              <stop offset={0.54} stopColor="gray" stopOpacity={0.122} />
              <stop offset={1} stopColor="gray" stopOpacity={0.102} />
            </linearGradient>
            <path
              id="prefix__Rectangle_6759"
              fill="url(#prefix__Rectangle_6759_00000114043416576427994480000000210055055616215946_)"
              d="M148.1 15.5l24.3-.3.3 27.8-24.3.2z"
            />
          </g>
          <path
            id="prefix__Rectangle_6760"
            transform="matrix(1 -.009 .009 1 -.378 2.72)"
            className="prefix__st7"
            d="M290.3 29.7h23.4v27.2h-23.4z"
          />
          <ellipse
            id="prefix__Ellipse_609"
            transform="matrix(1 -.009 .009 1 -.37 2.718)"
            className="prefix__st8"
            cx={301.8}
            cy={42.5}
            rx={4.3}
            ry={4.2}
          />
          <circle
            id="prefix__Ellipse_610"
            transform="matrix(1 -.009 .009 1 -.383 2.718)"
            className="prefix__st8"
            cx={301.8}
            cy={44}
            r={3.9}
          />
          <path
            id="prefix__Rectangle_6761"
            transform="matrix(.9999 -.01 .01 .9999 -.46 3.022)"
            className="prefix__st8"
            d="M301 46h1.8v3.1H301z"
          />
          <path
            id="prefix__Path_6857"
            className="prefix__st8"
            d="M302 48.4c2.5 0 4.5 2 4.5 4.5v4l-9 .1v-4c0-2.5 2-4.5 4.5-4.6z"
          />
          <path
            id="prefix__Path_6858"
            className="prefix__st8"
            d="M298.7 39.5s2.6-1.6 3.1-3.2c.8 1.5 1.8 2.9 3 4.1l-6.1-.9z"
          />
          <g
            id="prefix__Group_7960"
            transform="translate(91.893 95.527)"
            className="prefix__st5"
          >
            <linearGradient
              id="prefix__Path_6859_00000030487110065668572980000007949267861337434761_"
              gradientUnits="userSpaceOnUse"
              x1={258.368}
              y1={441.409}
              x2={258.368}
              y2={442.809}
              gradientTransform="matrix(27.528 0 0 -32.357 -6964.886 14375.969)"
            >
              <stop offset={0} stopColor="gray" stopOpacity={0.251} />
              <stop offset={0.54} stopColor="gray" stopOpacity={0.122} />
              <stop offset={1} stopColor="gray" stopOpacity={0.102} />
            </linearGradient>
            <path
              id="prefix__Path_6859"
              d="M128.2 48v.7l14.1 24.5v14.6l10.4 5.6V73.2l14-24.5V48h-38.5z"
              fill="url(#prefix__Path_6859_00000030487110065668572980000007949267861337434761_)"
            />
          </g>
          <path
            id="prefix__Rectangle_6762"
            className="prefix__st22"
            d="M220.5 143.8H258v.6h-37.5z"
          />
          <path
            id="prefix__Rectangle_6763"
            className="prefix__st23"
            d="M220.5 143.8H258v.6h-37.5z"
          />
          <path
            id="prefix__Path_6860"
            className="prefix__st22"
            d="M220.5 144.5l13.7 24.2V183l10.1 5.2v-19.5l13.6-24.2h-37.4z"
          />
          <path
            id="prefix__Path_6861"
            d="M234.9 183v-14.4l-13.8-24.2h-.6l13.7 24.2V183h.7z"
            opacity={0.2}
            fill="#fff"
          />
          <path
            id="prefix__Path_6862"
            className="prefix__st22"
            d="M234.3 183l10.1 5.5v-.3l-9.5-5.2h-.6z"
          />
          <path
            id="prefix__Path_6863"
            className="prefix__st23"
            d="M234.3 183l10.1 5.5v-.3l-9.5-5.2h-.6z"
          />
          <linearGradient
            id="prefix__Rectangle_6764_00000016057440370713771260000005083920130281733287_"
            gradientUnits="userSpaceOnUse"
            x1={359.957}
            y1={540.798}
            x2={359.957}
            y2={542.199}
            gradientTransform="matrix(60.677 0 0 -59.456 -21484.926 32434.295)"
          >
            <stop offset={0} stopColor="gray" stopOpacity={0.251} />
            <stop offset={0.54} stopColor="gray" stopOpacity={0.122} />
            <stop offset={1} stopColor="gray" stopOpacity={0.102} />
          </linearGradient>
          <path
            id="prefix__Rectangle_6764"
            fill="url(#prefix__Rectangle_6764_00000016057440370713771260000005083920130281733287_)"
            d="M313.7 197.3h85v83.3h-85z"
          />
          <path
            id="prefix__Rectangle_6765"
            className="prefix__st8"
            d="M314.1 197.9h84.1v82h-84.1z"
          />
          <path
            id="prefix__Rectangle_6766"
            className="prefix__st13"
            d="M322.6 204.7h67.1v52.1h-67.1z"
          />
          <path
            id="prefix__Rectangle_6767"
            className="prefix__st14"
            d="M327.4 264.1h59.2v2.7h-59.2z"
          />
          <path
            id="prefix__Rectangle_6768"
            className="prefix__st14"
            d="M336.8 269.6h40.3v2.7h-40.3z"
          />
          <linearGradient
            id="prefix__Rectangle_6769_00000153705196689832286730000006253139145613666189_"
            gradientUnits="userSpaceOnUse"
            x1={359.486}
            y1={540.837}
            x2={359.486}
            y2={542.238}
            gradientTransform="matrix(61.115 0 0 -59.886 -21725.217 32669.469)"
          >
            <stop offset={0} stopColor="gray" stopOpacity={0.251} />
            <stop offset={0.54} stopColor="gray" stopOpacity={0.122} />
            <stop offset={1} stopColor="gray" stopOpacity={0.102} />
          </linearGradient>
          <path
            id="prefix__Rectangle_6769"
            fill="url(#prefix__Rectangle_6769_00000153705196689832286730000006253139145613666189_)"
            d="M201.9 197h85.6v83.9h-85.6z"
          />
          <path
            id="prefix__Rectangle_6770"
            className="prefix__st8"
            d="M202.4 197.7h84.7v82.6h-84.7z"
          />
          <path
            id="prefix__Rectangle_6771"
            className="prefix__st13"
            d="M211 204.5h67.5v52.4H211z"
          />
          <path
            id="prefix__Rectangle_6772"
            className="prefix__st14"
            d="M213.5 263.2h59.6v2.7h-59.6z"
          />
          <path
            id="prefix__Rectangle_6773"
            className="prefix__st14"
            d="M223 268.7h40.6v2.7H223z"
          />
          <path
            id="prefix__Path_6864"
            className="prefix__st27"
            d="M106.5 111.3s-.4 39.8 34.8 32.3 36.2-19.7 76.4-3.9"
          />
          <path
            id="prefix__Path_6865"
            className="prefix__st27"
            d="M369.6 107.7s.4 39.8-34.8 32.3c-35.1-7.5-36.2-19.7-76.4-3.9"
          />
          <path
            id="prefix__Path_6866"
            className="prefix__st27"
            d="M243.4 107.7s-4.7 11.1 2.2 16.5-.7 16.9-.7 16.9"
          />
          <path
            id="prefix__Path_6867"
            className="prefix__st27"
            d="M147.9 79.5s5.4 26.2 31.5 26.2 49.1 29.4 44.1 33"
          />
          <path
            id="prefix__Path_6868"
            className="prefix__st27"
            d="M185.9 64.8s-6.9 12.7.7 25.6c3.6 6.1 9.5 10.5 16.4 12.3 16.2 4.5 23.4 2.4 33.9 29.1"
          />
          <path
            id="prefix__Path_6869"
            className="prefix__st27"
            d="M301.7 65.5s5.5 6.7-4.8 20.9c-4.1 5.6-9.8 9.8-16.2 12.1-10.4 3.9-20.5 15-20.5 31.5"
          />
          <g
            id="prefix__Group_7962"
            transform="translate(101.526)"
            className="prefix__st5"
          >
            <g id="prefix__Group_7961" className="prefix__st5">
              <linearGradient
                id="prefix__Rectangle_6774_00000022552024791551278320000000575893970203223708_"
                gradientUnits="userSpaceOnUse"
                x1={235.507}
                y1={531.394}
                x2={235.507}
                y2={532.795}
                gradientTransform="matrix(15.329 0 0 -17.438 -3467.287 9300.583)"
              >
                <stop offset={0} stopColor="gray" stopOpacity={0.251} />
                <stop offset={0.54} stopColor="gray" stopOpacity={0.122} />
                <stop offset={1} stopColor="gray" stopOpacity={0.102} />
              </linearGradient>
              <path
                id="prefix__Rectangle_6774"
                fill="url(#prefix__Rectangle_6774_00000022552024791551278320000000575893970203223708_)"
                d="M132.1 9.7h21.5v24.4h-21.5z"
              />
            </g>
            <path
              id="prefix__Rectangle_6775"
              className="prefix__st7"
              d="M132.7 10H153v24.1h-20.3z"
            />
            <circle
              id="prefix__Ellipse_611"
              className="prefix__st8"
              cx={142.7}
              cy={21.4}
              r={3.7}
            />
            <circle
              id="prefix__Ellipse_612"
              className="prefix__st8"
              cx={142.7}
              cy={22.7}
              r={3.4}
            />
            <path
              id="prefix__Rectangle_6776"
              className="prefix__st8"
              d="M141.9 24.7h1.6v2.7h-1.6z"
            />
            <path
              id="prefix__Path_6870"
              className="prefix__st8"
              d="M142.9 26.6c2.2 0 3.9 1.8 3.9 3.9v3.6H139v-3.6c-.1-2.1 1.7-3.9 3.9-3.9z"
            />
            <path
              id="prefix__Path_6871"
              className="prefix__st8"
              d="M140.9 17.4c-.2.1-.5.3-.7.4-.2.1-.4.3-.5.5 0 .1-.1.2-.1.2-.1.1-.3.1-.4.1-.5.1-.8.7-.9 1.2-.1.3-.1.6-.2.9v.6c0 .1.1.2.1.2.1.3-.2.6-.2.9 0 .3 0 .6.1.8 0 .1.1.2.1.2 0 .1.1.1.1.2.1.1.2.3.3.4.5.1 1.1.3 1.6.5.8.4 1.7.7 2.6.7.2 0 .5-.1.7-.1.4-.2.8-.5 1.3-.6.4-.1.8-.2 1.3-.2.2 0 .5-.2.4-.4.2.1.4 0 .6-.1.2-.2.3-.3.4-.5.2-.3.3-.7.4-1.1.2-.7.1-1.5-.3-2.1l-.3-.3c-.1-.1-.2-.1-.3-.2-.1-.1-.1-.2-.2-.3-.3-.5-.6-.9-.9-1.4-.3-.5-.7-.8-1.2-1.1-.7-.3-1.3.2-2 .2-.3 0-.6-.1-.9-.1-.3.2-.6.4-.9.5z"
            />
          </g>
          <g
            id="prefix__Group_7964"
            transform="translate(174.983 18.172)"
            className="prefix__st5"
          >
            <g id="prefix__Group_7963" className="prefix__st5">
              <linearGradient
                id="prefix__Rectangle_6777_00000030458428874075747800000017338503889572102823_"
                gradientUnits="userSpaceOnUse"
                x1={163.971}
                y1={512.805}
                x2={163.971}
                y2={514.206}
                gradientTransform="matrix(15.329 0 0 -17.438 -2341.264 8983.7)"
              >
                <stop offset={0} stopColor="gray" stopOpacity={0.251} />
                <stop offset={0.54} stopColor="gray" stopOpacity={0.122} />
                <stop offset={1} stopColor="gray" stopOpacity={0.102} />
              </linearGradient>
              <path
                id="prefix__Rectangle_6777"
                fill="url(#prefix__Rectangle_6777_00000030458428874075747800000017338503889572102823_)"
                d="M161.5 17H183v24.4h-21.5z"
              />
            </g>
            <path
              id="prefix__Rectangle_6778"
              className="prefix__st7"
              d="M162.2 17.3h20.3v24.1h-20.3z"
            />
            <circle
              id="prefix__Ellipse_613"
              className="prefix__st8"
              cx={172.1}
              cy={28.7}
              r={3.7}
            />
            <circle
              id="prefix__Ellipse_614"
              className="prefix__st8"
              cx={172.1}
              cy={29.9}
              r={3.4}
            />
            <path
              id="prefix__Rectangle_6779"
              className="prefix__st8"
              d="M171.3 32h1.6v2.7h-1.6z"
            />
            <path
              id="prefix__Path_6872"
              className="prefix__st8"
              d="M172.3 33.9c2.2 0 3.9 1.8 3.9 3.9v3.6h-7.8v-3.6c0-2.1 1.7-3.9 3.9-3.9z"
            />
            <path
              id="prefix__Path_6873"
              className="prefix__st8"
              d="M170.4 24.7c-.2.1-.5.3-.7.4-.2.1-.4.3-.5.5 0 .1-.1.2-.1.2-.1.1-.3.1-.4.1-.5.1-.8.7-.9 1.2-.1.3-.1.6-.2.9v.6c0 .1.1.2.1.2.1.3-.2.6-.2.9 0 .3 0 .6.1.8 0 .1.1.2.1.2 0 .1.1.1.1.2.1.1.2.3.3.4.5.1 1.1.3 1.6.5.8.4 1.7.7 2.6.7.2 0 .5-.1.7-.1.4-.2.8-.5 1.3-.6.4-.1.8-.2 1.3-.2.2 0 .5-.2.4-.4.2.1.4 0 .6-.1.2-.2.3-.3.4-.5.2-.3.3-.7.4-1.1.2-.7.1-1.5-.3-2.1l-.3-.3c-.1-.1-.2-.1-.3-.2-.1-.1-.1-.2-.2-.3-.3-.5-.6-.9-.9-1.4-.3-.5-.7-.8-1.2-1.1-.7-.3-1.3.2-2 .2-.3 0-.6-.1-.9-.1-.4.2-.7.3-.9.5z"
            />
          </g>
          <circle
            id="prefix__Ellipse_615"
            className="prefix__st8"
            cx={243.5}
            cy={228.5}
            r={8.5}
          />
          <circle
            id="prefix__Ellipse_616"
            className="prefix__st8"
            cx={243.6}
            cy={231.3}
            r={7.7}
          />
          <path
            id="prefix__Rectangle_6780"
            transform="matrix(1 -.009 .009 1 -2.141 2.202)"
            className="prefix__st8"
            d="M241.8 235.9h3.6v6.2h-3.6z"
          />
          <path
            id="prefix__Path_6874"
            className="prefix__st8"
            d="M244 240.3c4.9 0 8.9 3.9 8.9 8.8l.1 8.1-17.7.2-.1-8.1c0-5 3.9-9 8.8-9z"
          />
          <circle
            id="prefix__Ellipse_617"
            className="prefix__st8"
            cx={243.7}
            cy={219.8}
            r={3.1}
          />
          <path
            id="prefix__Path_6875"
            className="prefix__st8"
            d="M244.5 215c1.7-.2 3.3.1 4.8.8 1.4.4 2.6 1.1 3.7 2 2 1.9 2.4 4.8 2.5 7.5s-.1 5.5 1.1 8c1.4 2.7 4.2 4.4 7.3 4.3-1.8 1.1-4.1 1.1-6 .2-1.7-.8-3.1-2-4-3.7-.6-1.1-1.1-2.3-1.4-3.4-1.3-3.5-3-6.9-5.2-9.9-.3-.5-.7-1-1.1-1.3-.6-.5-1.4-.8-2.2-.9-.7-.1-1.8.2-2.2-.4-1-1.6 1.7-3 2.7-3.2z"
          />
          <circle
            id="prefix__Ellipse_618"
            className="prefix__st8"
            cx={358.1}
            cy={229.2}
            r={8.2}
          />
          <path
            id="prefix__Rectangle_6781"
            className="prefix__st8"
            d="M356.1 234.2h3.8v6.6h-3.8z"
          />
          <path
            id="prefix__Path_6876"
            className="prefix__st8"
            d="M358.1 238.8h.8c5 0 9.1 4.1 9.1 9.1v9h-19v-9c0-5 4.1-9.1 9.1-9.1z"
          />
          <path
            id="prefix__Path_6877"
            className="prefix__st8"
            d="M367.3 237.5c-.4.4-17.2 1.1-18.3 0 .7-3.6 0-12.4 0-12.4 0-5 4.1-9.1 9.1-9.1s9.1 4.1 9.1 9.1c.1 0-1 11 .1 12.4z"
          />
        </g>
      </g>
    </svg>
  );
}

const MemoHeaderImage = React.memo(HeaderImage);
export default MemoHeaderImage;
