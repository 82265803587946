/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { FC, useEffect, useState } from 'react';
import { filterInitialState } from '../../../constants/StoreHealth';
import Filter from './Filter';
import StoreKPIReportDownloadTable from './Table';
import { getStoreKPIReportDownload } from '../../../services/TenantReports/StoreKPIReport.service';

const StoreKPIReport: FC = () => {
  const [filters, setFilters] = useState(filterInitialState);
  const [jobId, setJobId] = useState(0);
  const [reportPage, setReportPage] = useState(0);
  const [reportOffset, setReportOffset] = useState(25);
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [generatedReport, setGeneratedReport] = useState([]);

  useEffect(() => {
    setIsReportLoading(true);
    getStoreKPIReportDownload({
      page: reportPage,
      offset: reportOffset
    })
      .then((data: any) => {
        console.log(data);
        setGeneratedReport(data);
        setIsReportLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsReportLoading(false);
      });
  }, [reportPage, reportOffset, jobId]);

  return (
    <div style={{ padding: '2rem' }}>
      <div>
        <h4>Store KPI Report</h4>
      </div>
      <Filter
        filters={filters}
        setFilters={setFilters}
        setJobId={setJobId}
        isReportLoading={isReportLoading}
        setIsReportLoading={setIsReportLoading}
      />
      <StoreKPIReportDownloadTable
        page={reportPage}
        setPage={setReportPage}
        isLoading={isReportLoading}
        reports={generatedReport}
        offset={reportOffset}
        setOffset={setReportOffset}
      />
    </div>
  );
};

export default StoreKPIReport;
