export const loyaltyList = [
  {
    path: '/loyalty-tiers',
    title: 'Loyalty Tier'
  },
  {
    path: '/loyalty-config',
    title: 'Loyalty Offer'
  },
  {
    path: '/loyalty-exception',
    title: 'Loyalty exclusions'
  },
  {
    path: '/ad-hoc-points',
    title: 'Ad Hoc Points'
  },
  {
    path: '/loyalty-redeem-configuration',
    title: 'Loyalty Redemption',
    className: 'heading activeHeading'
  },
  {
    path: '/referral-configuration',
    title: 'Referral'
  }
];

export const referralConfiguration = [
  {
    path: '/loyalty-tiers',
    title: 'Loyalty Tier'
  },
  {
    path: '/loyalty-config',
    title: 'Loyalty Offer'
  },
  {
    path: '/loyalty-exception',
    title: 'Loyalty exclusions'
  },
  {
    path: '/ad-hoc-points',
    title: 'Ad Hoc Points'
  },
  {
    path: '/loyalty-redeem-configuration',
    title: 'Loyalty Redemption'
  },
  {
    path: '/referral-configuration',
    title: 'Referral',
    className: 'heading activeHeading'
  }
];
