/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import _ from 'lodash';
import React, { useLayoutEffect, useState, useEffect } from 'react';

import useUpdateEffect from '../../customHooks/useUpdateEffect';
import Option from './Option';
import Search from '../Search/Search';
import classes from './MultiSelect.module.css';

const MultiSelect = ({
  placeholder,
  values,
  onChange,
  options,
  width = '360px',
  height = '50px',
  isSearchEnabled = false
}) => {
  const getHashSet = values => {
    const hashSet = {};
    _.forEach(values, value => {
      hashSet[value] = true;
    });
    return hashSet;
  };
  const [searchText, setSearchText] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [selectedHashSet, setSelectedHashSet] = useState(getHashSet(values));
  const [selectedAll, setSelectedAll] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useLayoutEffect(() => {
    if (filteredOptions) {
      const hashSet = {};
      _.forEach(values, value => {
        hashSet[value] = true;
      });

      setSelectedHashSet(getHashSet(values));
    }
  }, []);

  const onSelectHandler = option => {
    const copyHashSet = _.cloneDeep(selectedHashSet);
    copyHashSet[option] = !copyHashSet[option];
    setSelectedHashSet(copyHashSet);
    setSelectedAll(false);
  };

  const onSelectAllHandler = () => {
    setSelectedAll(!selectedAll);
    const copyHashSet = _.cloneDeep(selectedHashSet);
    if (!selectedAll) {
      _.forEach(filteredOptions, option => {
        copyHashSet[option] = true;
      });
    } else {
      _.forEach(filteredOptions, option => {
        copyHashSet[option] = false;
      });
    }
    setSelectedHashSet(copyHashSet);
  };

  useUpdateEffect(() => {
    onChange(_.filter(filteredOptions, option => selectedHashSet[option]));
  }, [selectedHashSet]);

  useEffect(() => {
    setFilteredOptions(
      _.filter(options, option => _.includes(option, searchText))
    );
  }, [searchText]);
  const optionsDropDown = () => {
    return (
      <div>
        <Option selected={selectedAll} onClick={onSelectAllHandler}>
          Select All
        </Option>
        {_.map(filteredOptions, option => (
          <Option
            key={option}
            selected={!!selectedHashSet[option]}
            onClick={() => onSelectHandler(option)}
          >
            {option}
          </Option>
        ))}
      </div>
    );
  };

  const filterOffers = value => {
    setSearchText(value);
  };
  const searchOptions = () => {
    return (
      <div className={classes.dropdownSearch}>
        {isSearchEnabled && <Search width="360px" onSearch={filterOffers} />}
        {optionsDropDown()}
      </div>
    );
  };

  return selectedHashSet ? (
    <div>
      <div
        onClick={() => setShow(!show)}
        className={classes.customDropDownArrow}
        style={{
          width: width,
          height: height
        }}
      >
        {placeholder}
      </div>
      {show && searchOptions()}
    </div>
  ) : (
    <div />
  );
};

export default MultiSelect;
