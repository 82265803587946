import React, { FC } from 'react';
import LayoutTile from '../../../PageGenerators/LayoutTile';
import { loaderElement } from '../../Utiles/FlipbookPagesUtiles';
import { isNumber } from 'lodash';
import { FlipbookLayout } from '../../../../../models/FlipbookV2/FlipbookV2Layout.model';
import { TileType } from '../../../../../models/FlipbookV2/FlipbookV2LayoutTile.model';

interface DoubleLayoutProps {
  editPanelOccupiedKey: string;
  setEditPanelOccupiedKey: (key: string) => void;
  page: FlipbookLayout;
  isEditMode: boolean;
  handleModalOpen: any;
  isCustomerView: boolean;
  hasPermission: (permission: string) => boolean;
}

const DoubleLayout: FC<DoubleLayoutProps> = ({
  editPanelOccupiedKey,
  setEditPanelOccupiedKey,
  page,
  isEditMode,
  handleModalOpen,
  isCustomerView,
  hasPermission
}) => {
  //TODO: Remove below codes

  return (
    <>
      <div className={'height-100-per'}>
        <div className={'height-50-per'} style={{ position: 'relative' }}>
          {isEditMode && (
            <div className={'change-layout-container'}>
              <p
                onClick={() => handleModalOpen()}
                className={
                  'm-0 change-layout-text casa-color flipbook-text-header p-1'
                }
              >
                Change Layout
              </p>
            </div>
          )}
          {
            <div className={'height-100-per'}>
              {!isNumber(page.id) ? (
                loaderElement()
              ) : (
                <LayoutTile
                  editPanelOccupiedKey={editPanelOccupiedKey}
                  setEditPanelOccupiedKey={setEditPanelOccupiedKey}
                  layoutId={page.id}
                  flipbookTileType={TileType.TileOne}
                  page={page}
                  isEditMode={isEditMode}
                  recommendedDimensions={
                    'Image Dimension 466w x 328h (Max size 1mb)'
                  }
                  isCustomerView={isCustomerView}
                  hasPermission={hasPermission}
                />
              )}
            </div>
          }
        </div>
        <div className={'height-50-per'} style={{ position: 'relative' }}>
          {
            <div className={'height-100-per'}>
              {!isNumber(page.id) ? (
                loaderElement()
              ) : (
                <LayoutTile
                  editPanelOccupiedKey={editPanelOccupiedKey}
                  setEditPanelOccupiedKey={setEditPanelOccupiedKey}
                  layoutId={page.id}
                  flipbookTileType={TileType.TileTwo}
                  page={page}
                  isEditMode={isEditMode}
                  recommendedDimensions={
                    'Image Dimension 466w x 328h (Max size 1mb)'
                  }
                  isCustomerView={isCustomerView}
                  hasPermission={hasPermission}
                />
              )}
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default DoubleLayout;
