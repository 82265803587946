const FB_URL = 'https://graph.facebook.com/v15.0';
// function getPageIdFromPostId(postId) {
//   return postId.split('_')[0];
// }

// function getPageAccessToken(postId, userAccessToken) {
//   const pageId = getPageIdFromPostId(postId);
//   return fetch(
//     `${FB_URL}/${pageId}?fields=access_token&access_token=${userAccessToken}`
//   )
//     .then(r => r.json())
//     .then(r => r.access_token);
// }

// function getPostInfoWithUserAccessToken(postId, userAccessToken) {
//   return getPageAccessToken(postId, userAccessToken).then(pageAccessToken =>
//     fetch(
//       `${FB_URL}/v15.0/${postId}?access_token=${pageAccessToken}&fields=message,permalink_url`
//     )
//       .then(r => r.json())
//       .then(r => ({ ...r, permalinkUrl: r.permalink_url }))
//   );
// }

function getInformationFromFbPostId(postId, pageAccessToken) {
  console.log('postId, pageAccessToken', postId, pageAccessToken);
  return fetch(
    `${FB_URL}/${postId}?access_token=${pageAccessToken}&fields=message,permalink_url`
  )
    .then(r => r.json())
    .then(r => ({ ...r, permalinkUrl: r.permalink_url }));
}

export default {
  getInformationFromFbPostId
};
