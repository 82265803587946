/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';

// Credits: Mario Campa - https://stackoverflow.com/questions/55075604/react-hooks-useeffect-only-on-update
const useUpdateEffect = (effect, dependencies = []) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effect();
    }
  }, dependencies);
};
export default useUpdateEffect;
