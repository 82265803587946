import React, { FC } from 'react';

interface ImageTaggerProps {
  mapArea: any[];
  screenType: 'Edit' | 'View';
  onClickArea: (area: any, index: number) => void;
}
export const ImageTagger: FC<ImageTaggerProps> = ({
  mapArea,
  screenType,
  onClickArea
}) => {
  return (
    <>
      {mapArea &&
        mapArea.map((map: any, index: number) => {
          const { top, left, width, height } = map;
          return (
            <span
              className={`crop-item ${screenType === 'Edit' &&
                'crop-item-edit'}`}
              key={index}
              style={{ top, left, width, height }}
              onClick={() => {
                onClickArea(map, index);
              }}
            />
          );
        })}
    </>
  );
};
