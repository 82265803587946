import React from 'react';
import { Product } from '../../models/CartWrapper';
import { ProductBucket } from '../../models/offer/OfferRule.model';
import { CssStyles } from '../CartBucket/CartBucketProps';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import NumberFormat from 'react-number-format';

const cardHalfHeight = '10em';
const imageHeight = `${parseInt(cardHalfHeight) - 2}em`;

const styles: { [key: string]: CssStyles } = {
  cardTopStyle: {
    borderRadius: '8px 8px 0px 0px',
    height: cardHalfHeight,
    maxHeight: cardHalfHeight,
    boxShadow: '0px 1px 4px #0000001F'
  },
  cardBottomStyle: {
    background: '#F8F8F8 0% 0% no-repeat padding-box',
    borderRadius: '0px 0px 8px 8px',
    height: cardHalfHeight,
    maxHeight: cardHalfHeight,
    boxShadow: '0px 1px 4px #0000001F'
  }
};

const buttonStyle: CssStyles = {
  // background: '#FFFFFF 0% 0% no-repeat padding-box',
  // boxShadow: '0px 1px 3px #0000001F',
  borderRadius: '4px'
};
const buttonTextStyle: CssStyles = {
  // color: '#767676',
  fontSize: '14px',
  fontWeight: 'bold',
  textAlign: 'center'
};

const SelectButton = withStyles({
  root: buttonStyle,
  label: buttonTextStyle
})(Button);

const FinalSelectButton = withStyles({
  root: {
    boxShadow: '0px 1px 3px #0000001F',
    borderRadius: '4px'
  },
  label: {
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center'
  }
})(Button);

interface OfferProductCardProps {
  product: Product;
  bucket: ProductBucket;
  isFinalSelect: boolean;
  onClick?: (p: Product, bucket: ProductBucket) => void;
}

export const OfferProductCard: React.FC<OfferProductCardProps> = ({
  product = new Product(),
  isFinalSelect,
  bucket = new ProductBucket(),
  onClick = _.noop
}) => {
  return (
    <div
      className={'d-flex flex-column mb-3 col-12 col-md-6 col-lg-3 p-md-3 px-0'}
    >
      <div
        style={{ border: '1px solid #DEDEDE', borderRadius: '8px' }}
        className={'d-flex flex-column '}
      >
        <div
          className={'d-flex align-items-center justify-content-center'}
          style={styles.cardTopStyle}
        >
          <img
            style={{ maxHeight: imageHeight }}
            src={product.imageUrl}
            alt={'product'}
            className={'img-fluid my-auto object-fit-contain'}
          />
        </div>
        <div
          className={
            'd-flex flex-column align-items-center justify-content-around'
          }
          style={styles.cardBottomStyle}
        >
          <div
            className={'px-2 text-center overflow-hidden'}
            style={{ maxHeight: '70px' }}
          >
            {product.name}
          </div>
          {bucket.discount.hasDiscount && (
            <div style={{ fontSize: '11px', textDecoration: 'line-through' }}>
              <NumberFormat
                prefix={'₹'}
                thousandSeparator={true}
                displayType={'text'}
                value={product.price}
                thousandsGroupStyle={'lakh'}
              />
            </div>
          )}
          <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
            <NumberFormat
              prefix={'₹'}
              thousandSeparator={true}
              displayType={'text'}
              value={_.invoke(product, 'getDiscountedPrice', bucket.discount)}
              thousandsGroupStyle={'lakh'}
            />
          </div>
          <div className={'w-75 mb-2'}>
            {isFinalSelect ? (
              <FinalSelectButton
                onClick={() => onClick(product, bucket)}
                variant="contained"
                color={'primary'}
                size="large"
                fullWidth
              >
                {' '}
                Select{' '}
              </FinalSelectButton>
            ) : (
              <SelectButton
                onClick={() => onClick(product, bucket)}
                variant="contained"
                color={'primary'}
                size="large"
                fullWidth
              >
                Select
              </SelectButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
