import React, { useState } from 'react';
import Search from '../../Search/Search';
import _ from 'lodash';
import { TrayTab } from '../TrayTab/TrayTab';
import { TrayItem } from '../../TrayItem/TrayItem';
import * as TrayIcons from '../../../Icons/svg/TrayIcons';
import classes from './Tray.module.css';
export const Tray = ({ templates, isEnabled, placeholder }) => {
  const [tab, setTab] = useState(Object.keys(templates)[0]);
  const [templateItems, setTemplateItems] = useState(templates[tab]);
  const activeTabStyle = {
    border: '1px solid #EEEEEE',
    backgroundColor: '#ffffff',
    boxShadow: '2px 1px 3px -2px rgba(0, 0, 0, 0.6)'
  };
  const inactiveTabStyle = {
    border: '2px solid #EEEEEE',
    backgroundColor: '#f1f1f1'
  };
  return (
    <div
      className={classes.container}
      style={isEnabled ? {} : { cursor: 'not-allowed', opacity: '0.4' }}
    >
      <div style={isEnabled ? {} : { pointerEvents: 'none' }}>
        <Search
          placeholder={placeholder}
          width="100%"
          onSearch={searchText => {
            setTemplateItems(
              _.filter(templates[tab], template =>
                _.includes(_.toLower(template.name), _.toLower(searchText))
              )
            );
          }}
        />
        <div className={classes.trayHeader}>
          {_.map(Object.keys(templates), category => (
            <TrayTab
              key={category}
              Icon={TrayIcons[category]}
              title={category}
              onClick={() => {
                setTab(category);
                setTemplateItems(templates[category]);
              }}
              style={tab === category ? activeTabStyle : inactiveTabStyle}
            />
          ))}
        </div>
        <div className={classes.tray}>
          {_.map(templateItems, template => (
            <TrayItem
              key={template.name}
              name={template.name}
              enabled={template.enabled}
              model={template}
              IconComponent={TrayIcons[template.icon]}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
