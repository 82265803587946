import Keycloak from 'keycloak-js';
import config from './config';

const keycloak = new Keycloak(config.keycloakConfig);

export const getKeycloakHeaders = () => {
  return {
    Authorization: `Bearer ${keycloak.token}`
  };
};

export default keycloak;
