/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { ActionNodeModel } from './ActionNodeModel';
import NodeWidgetHOC from '../NodeWidgetHOC/NodeWidgetHOC';
import BaseNodeWidget from '../NodeWidgetHOC/BaseNodeWidget';
import NodeTypes from '../BaseNodeModel/NodeTypes';

export class ActionNodeFactory extends AbstractReactFactory {
  constructor() {
    super(NodeTypes.ACTION_NODE);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  generateModel(event) {
    return new ActionNodeModel();
  }

  generateReactWidget(event) {
    const setInputFields = ({ specs, messageTemplateData }) =>
      event.model.setInputFields(specs, messageTemplateData);
    return (
      <NodeWidgetHOC node={event.model}>
        <BaseNodeWidget
          engine={this.engine}
          node={event.model}
          setInputFields={setInputFields}
        />
      </NodeWidgetHOC>
    );
  }
}
