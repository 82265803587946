/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';

import SectionField from '../../../models/SectionField';
import SectionProps from '../../../models/SectionProps';
import utils from '../../../utils/utils';
import classes from './MiscInfo.module.css';
import Heading from '../../Heading/Heading';
import IFrameWrapper from '../../IFrameWrapper/IFrameWrapper';

const MiscInfo: React.FC<SectionProps> = ({ data }) => {
  // console.log('misc-data', data);

  function renderDetail(field: SectionField) {
    return (
      <div className={classes.item} key={`${field.label}`}>
        {field.label && <label>{field.label}</label>}
        <div>{ReactHtmlParser(utils.transformToType(field))}</div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Heading heading={data.heading || 'TERMS AND CONDITIONS'} />
      {_.map(data.fields, renderDetail)}
      <IFrameWrapper iframeURL={data.html} />
    </div>
  );
};

export default MiscInfo;
