import React from 'react';
import RoleAssignStyles from '../RoleAssign.module.scss';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

function RoleActionButtons(props) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
      }}
    >
      <div style={{ width: '60%' }}>
        <h5>Role Management</h5>
      </div>
      <div style={{ display: 'flex' }}>
        <div className={RoleAssignStyles.addItem}>
          <Fab size="small" color="primary" aria-label="add">
            <AddIcon onClick={props.visiblePopUp} />
          </Fab>
          <div className={RoleAssignStyles.addItemText}>Create Role</div>
        </div>

        <div className={RoleAssignStyles.importButton}>
          <div className={RoleAssignStyles.importText}>Import Permission</div>
        </div>
      </div>
    </div>
  );
}

export default RoleActionButtons;
