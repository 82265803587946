import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import withStyles from '@material-ui/core/styles/withStyles';
import ReportCSVLink from './ReportCSVLink';
import _ from 'lodash';

const StyledTableCell = withStyles(theme => ({
  head: {
    borderTop: '1px solid #DDD',
    borderBottom: '1px solid #DDD',
    fontWeight: 'bold',
    fontSize: 16
  },
  body: {
    borderTop: '1px solid #DDD',
    borderBottom: '1px solid #DDD'
  }
}))(TableCell);

const ReportTable = ({ tableData }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell align={'center'} style={{ width: '25%' }}>
              Flow Name
            </StyledTableCell>
            <StyledTableCell align={'center'} style={{ width: '50%' }}>
              Currently Mapped Offers
            </StyledTableCell>
            <StyledTableCell align={'center'} style={{ width: '25%' }}>
              Report
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(tableData, row => (
            <TableRow key={_.uniqueId(_.get(row, 'offerType'))}>
              <StyledTableCell align={'center'}>
                {_.get(row, 'offerType')}
              </StyledTableCell>
              <StyledTableCell align={'center'}>
                {_.join(_.get(row, 'mappedOffers'), ', ') || 'None'}
              </StyledTableCell>
              <StyledTableCell align={'center'}>
                <ReportCSVLink
                  offerType={_.get(row, 'offerType')}
                  mappedOffers={_.get(row, 'mappedOffers')}
                />
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReportTable;
