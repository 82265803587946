import config from '../config/index';
import _ from 'lodash';
import { User } from '../models/User.model';
import queryString from 'query-string';

export function getCurrentUser(): Promise<User> {
  return fetch(`${config.ruleServerUrl}/users`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .then(u => _.extend(new User(), u));
}

export function getCurrentUserByToken(token: string): Promise<User> {
  const qs = queryString.stringify(
    {
      token: token
    },
    { arrayFormat: 'bracket' }
  );
  return fetch(`${config.ruleServerUrl}/tenant/userInfo/token?${qs}`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .then(u => _.extend(new User(), u));
}

export function getAliasByBusiness(body: any) {
  return fetch(`${config.ruleServerUrl}/users/store/alias-map`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(body),
    headers: {
      'Content-type': 'application/json'
    }
  }).then(r => r.json());
}
