import _ from 'lodash';

const isPeriodNotation = term => {
  const upperTerm = _.upperCase(term);
  const firstThreeLetter = _.truncate(upperTerm, { length: 3, omission: '' });
  return ['YEA', 'MON', 'DAY', 'WEE'].includes(firstThreeLetter);
};
export const getWarrantyData = warrantyText => {
  if (_.isEmpty(warrantyText)) {
    return ['', ''];
  }
  const [number, term] = warrantyText.split(' ');
  const OPTIONS_WARRANTY = {
    AS: {
      number: 'APMP', //As Per Manufacturer Policy
      term: ''
    },
    LIFETIME: {
      number: 'LFTM', //Lifetime
      term: ''
    },
    DEFAULT: {
      number: Number(number),
      term: _.toUpper(term)[0] === 'Y' ? 'YRS' : 'MNTS'
    }
  };
  let object = OPTIONS_WARRANTY[number];
  if (_.isEmpty(object) && !isPeriodNotation(term)) {
    return ['', ''];
  }
  object = _.isEmpty(object) ? OPTIONS_WARRANTY['DEFAULT'] : object;
  return [object.number, object.term];
};
