import React from 'react';
import { View, Text } from '@react-pdf/renderer';

import { WarrantyTag } from './WarrantyTag';
import { getWarrantyData } from '../utils/WarrantyTransformer';

const warranty = function({ warranty, styles }) {
  const [number, term] = getWarrantyData(warranty);

  return warranty ? (
    <>
      <WarrantyTag number={number} term={term} externalStyles={styles} />
      <Text style={styles.warrantyText}>Product Warranty</Text>
    </>
  ) : (
    <View />
  );
};

export default warranty;
