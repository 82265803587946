import { FlipbookLayout } from '../../models/FlipbookV2/FlipbookV2Layout.model';
import config from '../../config';

function createFlipbookLayout(flipbookLayout: FlipbookLayout) {
  return fetch(`${config.ruleServerUrl}/users/flipbook-layout-v2/create`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify(flipbookLayout)
  }).then(r => r.json());
}

function updateFlipbookLayout(
  flipbookLayout: FlipbookLayout,
  layoutId: number | undefined
) {
  return fetch(`${config.ruleServerUrl}/users/flipbook-layout-v2/${layoutId}`, {
    credentials: 'include',
    method: 'PUT',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify(flipbookLayout)
  }).then(r => r.json());
}

function getFlipbookLayout(flipbookId: number, layoutId: number) {
  return fetch(
    `${config.ruleServerUrl}/users/flipbook-layout-v2/${flipbookId}/${layoutId}`
  );
}

export default {
  createFlipbookLayout,
  updateFlipbookLayout,
  getFlipbookLayout
};
