import React from 'react';
import { makeStyles } from '@material-ui/core';
import { SkuList } from './SkuList';
import { ErrorList } from './ErrorList';
import { WarningList } from './WarningList';
import _ from 'lodash';

const styles = makeStyles({
  container: {
    marginBottom: 40
  },
  root: {
    marginTop: 30,
    padding: '0px 40px',
    width: '100%'
  },
  status: {
    padding: '0px 110px',
    marginTop: 30
  }
});

const PrintLabel = props => {
  const {
    payload,
    isErrorProductsVisible,
    setIsErrorProductsVisible,
    offset,
    setCount,
    setOffSet,
    count,
    currentPageNumber,
    setCurrentPageNumber,
    skuDetails,
    setSkuDetails
  } = props;
  const classes = styles();
  return (
    <div className={classes.container}>
      <div className={`${classes.root}`}>
        <SkuList
          payloadData={_.get(payload, 'payload.data', { id: -1, length: 0 })}
          offset={offset}
          setCount={setCount}
          setOffSet={setOffSet}
          count={count}
          setSkuDetails={setSkuDetails}
          skuDetails={skuDetails}
          currentPageNumber={currentPageNumber}
          setCurrentPageNumber={setCurrentPageNumber}
        ></SkuList>
      </div>
      <div className={classes.status}>
        <ErrorList
          setIsErrorProductsVisible={setIsErrorProductsVisible}
          isErrorProductsVisible={isErrorProductsVisible}
          payloadError={_.get(payload, 'payload.error', {
            id: -1,
            length: 0
          })}
        />
        <WarningList
          payloadWarning={_.get(payload, 'payload.warning', {
            id: -1,
            length: 0
          })}
        />
      </div>
    </div>
  );
};

export default PrintLabel;
