import { Box, Button, TextField } from '@material-ui/core';
import React, { FC, FormEvent, useRef } from 'react';
import { get } from 'lodash';

interface CalculateRoiFormProps {
  onCalculate: (messageRate: number) => void;
  setMessageCost: (messageCost: number) => void;
}

const CalculateRoiForm: FC<CalculateRoiFormProps> = ({
  onCalculate,
  setMessageCost
}) => {
  const messageRateControlRef = useRef<HTMLInputElement | null>(null);

  const handleCalculateRoi = (event: FormEvent): void => {
    event.preventDefault();

    if (messageRateControlRef.current?.valueAsNumber)
      onCalculate(messageRateControlRef.current.valueAsNumber);
  };
  const handleMessageCostChange = (): void => {
    const messageCost = get(messageRateControlRef, 'current.valueAsNumber');
    setMessageCost(messageCost);
  };

  return (
    <Box component="form" onSubmit={handleCalculateRoi}>
      <TextField
        type="number"
        name="messageRate"
        placeholder="Enter Rate per message to get ROI"
        inputRef={messageRateControlRef}
        onChange={handleMessageCostChange}
        fullWidth
        inputProps={{ step: 0.01, min: 0.01 }}
        required
        autoFocus
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        disableElevation
        style={{ marginTop: '1rem' }}
        type="submit"
      >
        Calculate ROI
      </Button>
    </Box>
  );
};
export default CalculateRoiForm;
