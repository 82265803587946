/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import storeClass from './index.module.scss';
import TenentStoreService from '../../services/tenentStore.service';

export const StoreTagsBulk = props => {
  const {
    setStoreImage,
    StoreDownload1,
    StoreDownload2,
    storeImage,
    business
  } = props;

  function downloadCSV(blob) {
    const data = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style = 'display: none';
    a.href = data;
    a.download = 'Store_template.csv';
    document.body.appendChild(a);
    a.click();

    setTimeout(function() {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  const handleClick = async () => {
    const file = await TenentStoreService.getStoreTemplateConfig(business.id);
    downloadCSV(file);
  };

  return (
    <div>
      <button
        className={storeClass.uploadStoreButton}
        onMouseEnter={() => {
          setStoreImage(StoreDownload1);
        }}
        onMouseOut={() => {
          setStoreImage(StoreDownload2);
        }}
        onClick={handleClick}
      >
        <img
          src={storeImage}
          alt="storeDownload"
          style={{ height: 16, width: 16, marginRight: 10 }}
        />
        Download Store Template
      </button>
    </div>
  );
};
