import config from '../../config/index';
import TransactionWiseReportDownload from '../../models/TenantReports/MemberWiseReport';
import moment from 'moment';

export const getFraudReport = (filters: any) => {
  return fetch(
    `${config.ruleServerUrl}/users/reports/download/fraud_report/${
      filters.businessId
    }?startDate=${moment(filters.startDate).format(
      'YYYY-MMM-DD'
    )}&endDate=${moment(filters.endDate).format(
      'YYYY-MMM-DD'
    )}&type=fraud&billCount=${filters.billCount}&billValue=${
      filters.billValue
    }&pointSpend=${filters.points}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
};

export const getFraudReportDownload = ({
  page,
  offset
}: {
  page: number;
  offset: number;
}): Promise<TransactionWiseReportDownload[]> => {
  return fetch(
    `${config.ruleServerUrl}/users/reports/view/downloads/fraud_report?offset=${offset}&page=${page}`,
    { credentials: 'include' }
  ).then(res => res.json());
};
