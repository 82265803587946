import React from 'react';
import { View, Image, StyleSheet, Text } from '@react-pdf/renderer';
import Warranty from '../../assets/warranty.png';
import _ from 'lodash';

const styles = StyleSheet.create({
  warranty: {
    height: '25mm',
    width: '25mm',
    margin: '4mm 4mm'
  },
  warrantyDuriation: {
    position: 'absolute',
    height: '16mm',
    width: '16mm',
    fontSize: '28pt',
    fontWeight: 'bold',
    margin: '5mm 4mm',
    backgroundColor: '#fff',
    borderRadius: '50',
    alignItems: 'center',
    justifyContent: 'center'
  },
  warratytext: { fontSize: '8pt', top: -8, left: 2 }
});
const WarrantyTag = ({ number, term, externalStyles }) => {
  return (
    <View style={externalStyles.warranty || styles.warranty}>
      <Image src={Warranty} />
      <View
        style={externalStyles.warrantyDuriation || styles.warrantyDuriation}
      >
        {_.isNumber(number) ? (
          <Text style={externalStyles.warrantyValue}>{number}</Text>
        ) : (
          <Text style={{ fontSize: '6pt' }}>{number}</Text>
        )}
        <Text style={externalStyles.warrantyYear || styles.warratytext}>
          {term}
        </Text>
      </View>
    </View>
  );
};

export { WarrantyTag };
