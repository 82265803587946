import React from 'react';
import 'react-table-v6/react-table.css';
import '../../../styles/Table.scss';
import ReactTable from 'react-table-v6';
import KamFilter from '../components/KamFilter';
// import Pagination from '../Pagination';

const Panel = props => {
  const {
    kam,
    handleDate,
    formatDate,
    tableData,
    columns,
    setKam,
    kamInfo
  } = props;
  return (
    <div style={{ background: '#c8c8c830', height: '100vh' }}>
      <div style={{ width: '95%', margin: 'auto 30px' }}>
        <h5 style={{ padding: '30px 0px' }}>
          <strong>Expenses Management</strong>
        </h5>
        <KamFilter
          kam={kam}
          handleDate={handleDate}
          formatDate={formatDate}
          setKam={setKam}
          kamInfo={kamInfo}
        />
      </div>
      <ReactTable
        data={tableData}
        minRows={0}
        showPagination={false}
        NoDataComponent={() => null}
        columns={columns}
      />
      {!tableData.length && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: 20,
            fontWeight: 'bold'
          }}
        >
          No Record Found
        </div>
      )}
      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '95%',
          margin: 'auto',
          marginTop: 30
        }}
      >
        <Pagination />
      </div> */}
    </div>
  );
};

export default Panel;
