import * as React from 'react';

function ZoomOutIcon(props) {
  return (
    <svg width="20px" height="30px" viewBox="0 0 512 512" {...props}>
      <path
        fill="grey"
        d="M43.4 207c-20.6 3-36.6 17.5-41.9 37.7-6.5 24.7 9.2 51.1 35 59 5.7 1.7 15.2 1.8 219.5 1.8s213.8-.1 219.5-1.8c25.8-7.9 41.5-34.3 35-59-4.5-17.3-17-30.5-34.5-36.4-5.3-1.7-14.3-1.8-216.5-1.9-116-.1-213.3.2-216.1.6z"
      />
    </svg>
  );
}

export default ZoomOutIcon;
