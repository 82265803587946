/* eslint-disable @typescript-eslint/explicit-function-return-type */
import _ from 'lodash';
import { BaseNodeModel } from '../BaseNodeModel/BaseNodeModel';
import NodeTypes from '../BaseNodeModel/NodeTypes';
import * as PortModels from '../BaseNodeModel/PortModels';
export class DecisionNodeModel extends BaseNodeModel {
  constructor(options = {}) {
    super({
      type: NodeTypes.DECISION_NODE,
      ...options
    });
    this.specs = [];
    this.nextNodes = [NodeTypes.ACTION_NODE, NodeTypes.STOP_NODE];
    this.templateId = [];
    this.templateName = [];
    this.buildPorts([new PortModels.InPort(), new PortModels.OutPort()]);
  }

  setInputFields(templateName, templateId, specs) {
    this.isDetailsSaved = this.isAllDetailsFilled(specs);
    this.templateName = templateName;
    this.templateId = templateId;
    this.specs = specs;
  }
  getParentActionNodes() {
    const parentActionNodes = [];
    let parentNode = this.getParentNodes()[0];
    const isValidParent = parentNodeType =>
      !_.includes(
        [NodeTypes.TRIGGER_NODE, NodeTypes.TIMED_NODE, NodeTypes.DECISION_NODE],
        parentNodeType
      );
    while (!_.isEmpty(parentNode) && isValidParent(parentNode.type)) {
      if (parentNode.type === NodeTypes.ACTION_NODE) {
        parentActionNodes.push(parentNode);
      }
      parentNode = parentNode.getParentNodes()[0];
    }
    return parentActionNodes;
  }

  serialize() {
    return {
      ...super.serialize(),
      templateId: this.templateId,
      templateName: this.templateName
    };
  }

  deserialize(ob, engine) {
    super.deserialize(ob, engine);
    this.templateId = ob.data.templateId;
    this.templateName = ob.data.templateName;
  }
}
