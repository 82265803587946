import * as React from 'react';
function ZoomInIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="30px"
      viewBox="0 0 512 512"
    >
      <path
        fill="grey"
        d="M245.5 1.4c-8.3 2-14.6 5.6-21.1 12-6.5 6.6-10.5 13.7-12.3 22.4-.8 3.7-1.1 31.6-1.1 90.2v85h-85c-58.6 0-86.5.3-90.2 1.1-8.6 1.8-15.8 5.8-22.4 12.3-17.7 17.8-17.7 45.5 0 63.1C20 294 27.2 298 35.8 299.9c3.7.8 30.7 1.1 90.2 1.1h85v85c0 58.6.3 86.5 1.1 90.2 1.8 8.7 5.8 15.8 12.3 22.3 17.9 17.8 45.5 17.8 63.1.1 6.5-6.6 10.5-13.8 12.4-22.4.8-3.7 1.1-30.7 1.1-90.2v-85h85c58.6 0 86.5-.3 90.2-1.1 8.7-1.8 15.8-5.8 22.3-12.3 17.8-17.9 17.8-45.4.1-63.2-6.6-6.6-14.4-10.8-22.7-12.4-3.1-.6-39-1-90-1H301v-85c0-58.6-.3-86.5-1.1-90.2-1.8-8.6-5.8-15.8-12.3-22.4-11.4-11.4-26.7-15.8-42.1-12z"
      />
    </svg>
  );
}

export default ZoomInIcon;
