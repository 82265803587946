import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import labelService from '../../../../services/label.service';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorSKUList from './ErrorSKUList';
import { FileUpload } from '../Menu/FileUpload';
import _ from 'lodash';
import { CSVLink } from 'react-csv';
import { GoToNext, GoToPrevious } from '../HelperComponents/Button';
import { Autocomplete } from '@material-ui/lab';
import { AVAILABLE_BATCH_COUNT } from '../utils/Constants';

const styles = makeStyles({
  root: {
    background: '#FFF7F7',
    boxShadow: 'none'
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  }
});

const WarningAccordion = ({
  errors,
  offset,
  setCount,
  setOffSet,
  payloadData,
  count,
  currentPageNumber,
  setCurrentPageNumber
}) => {
  const classes = styles();
  const history = useHistory();
  const [state, setState] = useState({ file: null });
  const updateState = (key, value) => {
    setState({ ...state, [key]: value });
    labelService.getPrintLabels(key, value, history);
  };
  const csvHeaders = _.keys(errors[0]);
  const csvData = _.map(errors, (error, key) => {
    return _.values(error);
  });
  const getBatchForType = (pageNumber, newOffSet) => {
    const totalPages = Math.ceil(payloadData.length / count);
    if (pageNumber < 1 || pageNumber > totalPages) {
      return;
    }
    setCurrentPageNumber(pageNumber);
    setOffSet(newOffSet);
  };
  return (
    <div style={{ margin: '1rem 0rem' }}>
      <Accordion classes={{ root: classes.root }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{
                backgroundColor: '#fff',
                borderRadius: '50%',
                height: '2rem',
                width: '2rem',
                fill: '#F43E3E'
              }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="error-text">
            <img
              src={require('../../assets/warning.svg')}
              height={20}
              alt={'img'}
            />
            &nbsp; {`${errors.length} Warning Sku${errors.length ? '(s)' : ''}`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.container }}>
          <ErrorSKUList errors={errors} />
          <div style={{ display: 'flex' }}>
            <div style={{ width: '15%', margin: 'auto' }}>
              <CSVLink
                data={csvData}
                headers={csvHeaders}
                filename={`error_sku.csv`}
                style={{
                  width: 210,
                  color: 'black',
                  backgroundColor: '#ffffff'
                }}
                className="hidden float-right"
              >
                <img src={require('../../assets/down-arrow.svg')} alt={'img'} />
                &nbsp; Download error SKU list
              </CSVLink>
            </div>
            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
                margin: '1rem',
                width: '40%'
              }}
            >
              <span
                onClick={() =>
                  getBatchForType(currentPageNumber - 1, offset - count)
                }
                style={{
                  cursor: `${currentPageNumber > 1 ? 'pointer' : 'not-allowed'}`
                }}
              >
                <GoToPrevious />
              </span>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {`${currentPageNumber}/${Math.ceil(
                  payloadData.length / count
                )}`}
              </div>
              <span
                onClick={() =>
                  getBatchForType(currentPageNumber + 1, offset + count)
                }
                style={{
                  cursor: `${
                    currentPageNumber < Math.ceil(payloadData.length / count)
                      ? 'pointer'
                      : 'not-allowed'
                  }`
                }}
              >
                <GoToNext />
              </span>
            </div>
            <div
              style={{
                textAlign: 'left',
                margin: 'auto',
                fontSize: '14px'
              }}
            >
              <Autocomplete
                id="combo-box-demo"
                options={AVAILABLE_BATCH_COUNT}
                size={'small'}
                getOptionLabel={option => String(option)}
                style={{ width: 120, margin: '5px 0px' }}
                disableClearable={true}
                defaultValue={count}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Count"
                    variant="outlined"
                    size={'small'}
                  />
                )}
                onChange={(event, value) => {
                  setCurrentPageNumber(1);
                  setCount(Number(value));
                }}
              />
            </div>
          </div>
          <div className="flex l-py2">
            <div className="f14 l-py1">Upload corrected SKU list file</div>
            <FileUpload
              file={state.file && state.file[0]}
              updateState={updateState}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default WarningAccordion;
