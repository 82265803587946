import { Chip, TextField } from '@material-ui/core';
import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';

const AutoCompleteChip = props => {
  const {
    className,
    label,
    name,
    setFieldValue,
    onBlur,
    accessor,
    placeholder,
    value,
    style,
    error,
    touched,
    inputValues
  } = props;
  return (
    <>
      <Autocomplete
        className={className}
        onBlur={onBlur}
        value={value || []}
        onChange={(event, newValue) => {
          setFieldValue(name, newValue);
        }}
        style={style}
        multiple
        options={inputValues ? inputValues?.map(option => option.name) : []}
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              value={accessor !== '' ? option[accessor] : option.name}
              {...getTagProps({ index })}
              key={index}
            />
          ))
        }
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            name={name}
            placeholder={placeholder}
            error={error}
            touched={touched}
            helperText={error}
          />
        )}
      />
    </>
  );
};

export default AutoCompleteChip;
