/* eslint-disable @typescript-eslint/explicit-function-return-type */
import _ from 'lodash';
import React from 'react';
import SectionField from '../../../models/SectionField';

import SectionProps from '../../../models/SectionProps';
import DetailsTable from '../../DetailsTable/DetailsTable';
import Heading from '../../Heading/Heading';
import classes from './OrderInfo.module.css';

const OrderInfo: React.FC<SectionProps> = ({ data, billData }) => {
  function getBillType(invoiceType: string): string {
    const invoiceTypeLabelMap: {
      [key: string]: string;
    } = {
      SR: 'SR - Sales Returns',
      IN: 'IN - Invoice Bills',
      VB: 'VB - Void Bills'
    };
    return invoiceTypeLabelMap[invoiceType];
  }

  function getInvoiceBasedFields(fields: SectionField[], billData: any) {
    const invoiceType = _.get(billData, 'bill.invoiceType');
    if (invoiceType === 'IN') {
      return fields;
    }
    // sr and vb both have original bill id
    const originalBillId = {
      type: 'text',
      label: 'Original Bill ID',
      value: _.get(billData, 'bill.originalBillId', '')
    };
    const invoiceTypeField = {
      type: 'text',
      label: 'Invoice Type',
      value: getBillType(invoiceType)
    };

    const processedFields = _.flatten([
      invoiceTypeField,
      originalBillId,
      _.map(fields, field => ({
        ...field,
        ..._.get(field, `meta.${invoiceType}`, {})
      }))
    ]);
    return processedFields;
  }

  return (
    <div className={classes.container}>
      <Heading heading={data.heading || 'Invoice'} />
      <DetailsTable
        fields={getInvoiceBasedFields(data.fields, billData)}
        isValueBold={true}
        isKeyBold={true}
        separator={':'}
        alternateAlign={true}
        cellWidth={{ width: '70%' }}
        data={billData}
      />
    </div>
  );
};

export default OrderInfo;
