import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Offer } from '../../models/offer/Offer.model';
import { OfferCartBuilder } from '../../components/OfferCartBuilder';
import {
  BucketProducts,
  OfferCartBuilderContext
} from '../../components/OfferCartBuilder/OfferCartBuilderContext';
import { Dialog, DialogContent } from '@material-ui/core';
import cartSaved from '../../components/Assets/cart-saved.png';
import Button from '@material-ui/core/Button';
import offerService from '../../services/offer.service';
import * as cartService from '../../services/cart.service';
import productMasterService from '../../services/productMaster.service';
import _ from 'lodash';
import { CartWrapper } from '../../models/CartWrapper';
import * as customerService from '../../services/customer.service';
import { Customer } from '../../models/Customer';
import useCurrentUser from '../../hooks/useCurrentUser';

const defaultEmptyOffer = new Offer();
const emptyBucketProducts = new BucketProducts();
const defaultCustomerInfo = new Customer();

export const OfferCartBuilderRoute: FC = () => {
  const { customerMobile, offerCode } = useParams();
  const history = useHistory();
  const [cartId, setCartId] = useState(0);
  const [bucketProducts, setBucketProducts] = useState(emptyBucketProducts);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [offer, setOffer] = useState<Offer>(defaultEmptyOffer);
  const [customerInfo, setCustomerInfo] = useState(defaultCustomerInfo);
  const currentUser = useCurrentUser();
  const dismiss = () => {
    setIsDialogOpen(false);
    history.push(`/customer-offers/${customerMobile}`);
  };
  const goToCustomerOffers = () => {
    history.push(`/customer-offers/${customerMobile}`);
  };
  useEffect(() => {
    offerService.getCurrentOffer(offerCode).then(c => {
      const currentOffer = c as Offer;
      setOffer(c);
      _.forEach(c.offerDefinition.bucket.getProductBuckets(), pb => {
        productMasterService
          .filterOfferProducts(currentOffer.code!, pb.bucketId)
          .then(products =>
            setBucketProducts(bucket =>
              bucket.setProducts(pb.bucketId, products)
            )
          );
      });
    });
  }, [offerCode, customerMobile]);
  useEffect(() => {
    customerService.getCustomerByMobile(customerMobile).then(info => {
      setCustomerInfo(info);
    });
  }, [offerCode, customerMobile]);
  const saveCart = (cart: CartWrapper) => {
    return cartService.saveCart(cart).then(savedCart => {
      setCartId(savedCart.cartId);
      setIsDialogOpen(!!savedCart.cartId);
    });
  };
  return (
    <OfferCartBuilderContext.Provider
      value={{ offer, customerMobile, bucketProducts }}
    >
      <OfferCartBuilder
        storeCode={currentUser.firstStore}
        onCartSave={saveCart}
        onBackClick={goToCustomerOffers}
        customerInfo={customerInfo}
      />
      <Dialog
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isDialogOpen}
        maxWidth="lg"
        scroll="paper"
      >
        <DialogContent
          style={{ width: `${1000}px`, minWidth: 300, minHeight: 300 }}
        >
          <div className={'d-flex flex-column'}>
            <div
              className={
                'col-12 d-flex justify-content-center align-items-center'
              }
            >
              <img
                src={cartSaved}
                className={'img-fluid my-auto'}
                alt={'cart saved'}
              />
            </div>
            <div
              className={
                'col-12 d-flex flex-column justify-content-center align-items-center'
              }
            >
              <div className={'normaText h2 my2'}>Cart saved successfully!</div>
              <div className={'mt-2'}> Your Cart Id is:</div>
              <div>{cartId}</div>
            </div>
            <div
              className={
                'col-12 d-flex flex-column justify-content-center align-items-center'
              }
            >
              <Button color="primary" onClick={dismiss}>
                DISMISS
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </OfferCartBuilderContext.Provider>
  );
};
