import * as React from 'react';

function DecisionNode(props) {
  return (
    <svg width="40px" height="40px" viewBox="0 0 35.35 35.35" {...props}>
      <g data-name="Component 170 \u2013 1">
        <g
          data-name="Rectangle 1029"
          fill="#fff"
          stroke="#666"
          strokeLinejoin="round"
          strokeWidth={2}
        >
          <path
            stroke="none"
            d="M0 17.366L17.975-.009 35.35 17.966 17.375 35.341z"
          />
          <path
            fill="none"
            d="M1.414 17.39L17.951 1.405l15.985 16.537-16.537 15.985z"
            strokeWidth={1.99998}
          />
        </g>
        <path
          d="M17.321 12.116c-1.021 0-2.884.982-2.943 1.858s.119.93.2.977h.96a1.109 1.109 0 011.188-.779 1.3 1.3 0 011.146 1.66c-.324.777-.625 1.047-1.024 1.887a3.776 3.776 0 00-.008 3.164l1.184.021c-.293-1.506 1.258-2.847 1.875-3.774a3 3 0 00.682-2.047 2.938 2.938 0 00-.84-2.049 3.26 3.26 0 00-2.42-.918zm.059 9.769a1.434 1.434 0 101.434 1.431 1.431 1.431 0 00-1.432-1.431z"
          fill="#666"
        />
      </g>
    </svg>
  );
}

function WaitNode(props) {
  return (
    <svg width="40px" height="40px" viewBox="0 0 25.679 25.685" {...props}>
      <g data-name="Icon ionic-ios-timer" fill="#666">
        <path
          data-name="Path 374"
          d="M12.842 25.685a12.843 12.843 0 01-8.913-22.09 1.031 1.031 0 111.433 1.484 10.778 10.778 0 108.507-2.967v4.02a1.034 1.034 0 11-2.068 0v-5.1a1.033 1.033 0 011.034-1.034 12.843 12.843 0 01.006 25.685z"
        />
        <path
          data-name="Path 375"
          d="M7.698 6.799l6.389 4.591a1.933 1.933 0 11-2.247 3.146 1.867 1.867 0 01-.449-.449L6.8 7.698a.644.644 0 01.9-.9z"
        />
      </g>
    </svg>
  );
}

function ActionNode(props) {
  return (
    <svg width="40px" height="40px" viewBox="0 0 22.031 20.458" {...props}>
      <path
        d="M7.868 20.458a.787.787 0 01-.724-.488l-3.737-8.954H0V9.442h3.934a.787.787 0 01.724.488l3.21 7.6L14.211.511a.779.779 0 011.471.024l2.982 8.907h3.368v1.574h-3.935a.787.787 0 01-.748-.535L14.95 3.147l-6.342 16.8a.787.787 0 01-.74.511z"
        fill="#666"
      />
    </svg>
  );
}

function StopNode(props) {
  return (
    <svg width="40px" height="40px" viewBox="0 0 25.108 25.108" {...props}>
      <g
        data-name="Icon feather-stop-circle"
        fill="none"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path
          data-name="Path 6892"
          d="M24.108 12.554A11.554 11.554 0 1112.554 1a11.554 11.554 0 0111.554 11.554z"
        />
        <path data-name="Path 6893" d="M9.088 9.088h6.932v6.932H9.088z" />
      </g>
    </svg>
  );
}
export { ActionNode, WaitNode, DecisionNode, StopNode };
