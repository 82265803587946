import React, { FC } from 'react';

interface SKUTableProps {
  data?: any;
  error?: any;
}
const SKUTable: FC<SKUTableProps> = props => {
  const { data, error } = props;
  const getLastSyncData = (product: any) => {
    return `Label: ${new Date(product.updatedAt)}\nProduct: ${new Date(
      product.product.updatedAt
    )}`;
  };
  return (
    <table
      style={{
        width: '50%',
        tableLayout: 'fixed'
      }}
    >
      <thead>
        <tr>
          <th style={{ padding: '0px 5px' }}>SKU</th>
          <th style={{ padding: '0px 5px' }}>FORMAT</th>
          <th style={{ padding: '0px 5px' }}>LAYOUT</th>
          <th style={{ padding: '0px 5px' }}>LAST SYNC</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((list: any, index: number) => (
          <tr key={index}>
            <td style={error && { color: '#F43E3E', padding: '0px 5px' }}>
              {list?.sku}
            </td>
            <td style={{ color: '#999999', padding: '0px 5px' }}>
              {list.layout || 'N/A'}
            </td>
            <td style={{ color: '#999999', padding: '0px 5px' }}>
              {list.orientation || 'N/A'}
            </td>
            <td
              title={getLastSyncData(list)}
              style={{
                color: '#999999',
                padding: '0px 5px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              }}
            >
              {getLastSyncData(list) || 'N/A'}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SKUTable;
