/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import _ from 'lodash';
import RuleTemplatesContext from '../../context/ruleTemplatesContext';
import { onDropNode } from '../../BodyWidget/onDropUtils';
import { PortTypes } from '../../BaseNodeModel/PortModels';
import classes from './DragDropArea.module.css';
import NodeTypes from '../../BaseNodeModel/NodeTypes';
import { useEffect } from 'react';

const nodeToKeywords = {
  ActionNode: 'Action',
  TriggerNode: 'Cohort'
};

export const DragDropArea = ({ currentNode = null, nextNode, style }) => {
  const {
    diagramEngine,
    forceUpdate,
    setDragOverContext,
    setIsWaitingForAction
  } = useContext(RuleTemplatesContext);

  const isFirstNode = _.isEmpty(diagramEngine.getModel().getNodes());

  useEffect(() => {
    if (!isFirstNode) {
      setIsWaitingForAction(true);
    } else {
      setIsWaitingForAction(false);
    }
  }, []);

  return (
    <div className={classes.Container}>
      <div
        className={classes.infoArea}
        style={style}
        onDragOver={event => {
          event.preventDefault();
        }}
        onDrop={event => {
          setDragOverContext(false);
          const incomingNode = onDropNode(event, diagramEngine, () =>
            forceUpdate()
          );
          if (_.isEmpty(currentNode)) {
            incomingNode.setPosition(500, 20);
            forceUpdate();
            return;
          }
          const { x, y } = currentNode.position;
          if (currentNode.type === NodeTypes.DECISION_NODE) {
            console.log(_.size(currentNode.templateId));
            incomingNode.setPosition(
              x,
              y + 100 + _.size(currentNode.templateId) * 70
            );
          } else {
            incomingNode.setPosition(x, y + 100);
          }
          if (
            currentNode.getPort(PortTypes.OUT_PORT) &&
            incomingNode.getPort(PortTypes.IN_PORT)
          ) {
            const canLinkToPort = currentNode
              .getPort(PortTypes.OUT_PORT)
              .canLinkToPort(incomingNode.getPort(PortTypes.IN_PORT));

            if (canLinkToPort) {
              const link = currentNode
                .getPort(PortTypes.OUT_PORT)
                .link(incomingNode.getPort(PortTypes.IN_PORT));
              diagramEngine.getModel().addAll(link);
            } else {
              incomingNode.remove();
            }
          } else {
            incomingNode.remove();
          }
          forceUpdate();
          setIsWaitingForAction(false);
        }}
      >
        {!isFirstNode && (
          <svg
            width="30px"
            height="50px"
            viewBox="0 0 30 50"
            style={{
              position: 'relative',
              top: '-12px',
              left: '41%'
            }}
          >
            <path
              d="M15 0 v50 l-6 -6 l6 6 l6 -6"
              stroke="#F07663"
              strokeWidth="2px"
              fill="none"
            ></path>
          </svg>
        )}
        <div className={classes.infoText}>
          Drag & Drop {nodeToKeywords[nextNode]} to build your journey
        </div>
      </div>
    </div>
  );
};
