import React from 'react';
import classNames from 'classnames';
import { Button } from '../index';
import '../../styles/FileUpload.scss';
import { useHistory } from 'react-router';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
const FileUpload = ({ disabled, file, updateState, fileError, ...rest }) => {
  const history = useHistory();
  const CSV_DATA = [];
  const CSV_HEADERS = ['SKU', 'Format', 'Layout', 'Version'];
  const onChooseFile = () => {
    const file = document.getElementById('fileUpload');
    file.click();
  };
  const [errorMessage, setErrorMessage] = React.useState('Few columns missing');
  const [isErrorExist, setIsErrorExist] = React.useState(false);
  const validateFileUpdate = (csvHeader, file) => {
    if (!_.isEqual(CSV_HEADERS, csvHeader)) {
      setIsErrorExist(true);
      setErrorMessage(
        `${_.differenceWith(CSV_HEADERS, csvHeader, _.isEqual)} columns missing`
      );
      updateState(2, { csv: '' });
    } else {
      setIsErrorExist(false);
      file = { csv: file[0] };
      updateState(2, file);
    }
  };
  const handleFileUpdate = file => {
    if (_.isEmpty(file)) {
      return;
    }
    const reader = new FileReader();
    reader.onload = function(e) {
      let csvHeader = reader.result.split('\n')[0].split(',');
      csvHeader = _.map(csvHeader, header => {
        return header.replace(/^\s+|\s+$/g, '');
      });
      validateFileUpdate(csvHeader, file);
    };
    reader.readAsText(file[0]);
  };
  return (
    <div
      className={classNames('fileUpload px3 ellipsis', {
        // eslint-disable-next-line
        ['not-allowed']: disabled
      })}
    >
      <input
        style={{ display: 'none' }}
        id="fileUpload"
        type="file"
        accept=".csv"
        disabled={disabled}
        onChange={e => handleFileUpdate(e.target.files)}
      />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          style={{ backgroundColor: '#fff' }}
          className="buttonSecondary"
          variant="outlined"
          color="secondary"
          text="Choose file"
          onClick={onChooseFile}
          disabled={disabled}
        />
      </div>
      {history.location.pathname === '/labeling' && (
        <>
          <span
            className="secondaryText py1 italic flex justify-center"
            style={{ marginTop: 10, marginLeft: -30 }}
          >
            <span>
              {!file?.name ? `No CSV file has been uploaded` : file?.name}
            </span>
          </span>
          <div className="l-py2" disabled={disabled}>
            <CSVLink
              data={CSV_DATA}
              headers={CSV_HEADERS}
              filename={`template_sku.csv`}
              style={{
                width: 220,
                color: 'black',
                backgroundColor: '#ffffff',
                textAlign: 'right'
              }}
              className="hidden float-right"
            >
              <img src={require('../../assets/down-arrow.svg')} alt={'img'} />
              &nbsp; Download CSV
            </CSVLink>
          </div>
          <div style={{ color: 'red', fontSize: '13px' }}>
            {isErrorExist ? `* ${errorMessage}` : ''}
          </div>
        </>
      )}
    </div>
  );
};

export { FileUpload };
