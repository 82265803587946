import React, { useEffect, useState } from 'react';
import './index.css';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import TabSection from './TabLabelSection';
import ProductsTab from './Tabs/ProductsTab';
import BillValueTab from './Tabs/BillValueTab';
import StoresTab from './Tabs/StoresTab';
import { OfferRule } from '../../../models/offer/OfferRule.model';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { getAliasByBusiness } from '../../../services/user.service';

export default function OfferTabs(props) {
  const [activeTab, setActiveTab] = useState(0);
  const [offer, setOffer] = useState(false);
  const { setDefinition, definition, data } = props;
  const { bucket, billAggregateCondition, billDiscount } = definition;
  const [aliasList, setAliasList] = useState({});
  const user = useCurrentUser();

  useEffect(() => {
    if (user.id) {
      let business = user.tenant.brands[0].businesses;
      business = business.map(item => item.id);
      getAliasByBusiness({ businessUnitIds: business || [] }).then(response =>
        setAliasList(response)
      );
    }
  }, [user]);

  const tabs = [
    <BillValueTab
      key={'bill'}
      data={data}
      billAggregateCondition={billAggregateCondition.conditions}
      billDiscount={billDiscount}
      offer={offer}
      setOffer={setOffer}
      setDefinition={conditions => {
        setDefinition(
          update(definition, {
            billAggregateCondition: { conditions: { $set: conditions } }
          })
        );
      }}
      setDiscountDefinition={discount => {
        setDefinition(
          update(definition, {
            billDiscount: { $set: discount }
          })
        );
      }}
    />,
    <ProductsTab
      key={'product'}
      bucket={bucket}
      setDefinition={updatedBucket => {
        setDefinition(
          update(definition, {
            bucket: { $set: updatedBucket }
          })
        );
      }}
    />,
    <StoresTab
      key={'stores'}
      aliasList={aliasList}
      setBillCondition={billCondition => {
        setDefinition(
          update(definition, {
            billCondition: { $set: billCondition }
          })
        );
      }}
      billCondition={definition.billCondition}
    />
  ];
  return (
    <div className="tabContainer">
      <TabSection activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="tabBody">{tabs[activeTab]}</div>
    </div>
  );
}

OfferTabs.propTypes = {
  definition: PropTypes.instanceOf(OfferRule),
  setDefinition: PropTypes.func.isRequired
};
