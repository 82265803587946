import React, { FC } from 'react';

const Revenue: FC = () => {
  return (
    <svg
      width="86"
      height="86"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.1" cx="42.836" cy="42.836" r="42.836" fill="#F2755F" />
      <path
        d="M50.0833 34.5001V31.6667H34.5V34.5001H39.4583C41.3028 34.5001 42.8597 35.6872 43.4462 37.3334H34.5V40.1667H43.4462C43.1554 40.993 42.616 41.7089 41.9019 42.2163C41.1879 42.7236 40.3343 42.9974 39.4583 43.0001H34.5V46.4199L42.4135 54.3334H46.4198L37.9198 45.8334H39.4583C41.0903 45.8312 42.6716 45.2664 43.9356 44.2341C45.1996 43.2019 46.069 41.7653 46.3972 40.1667H50.0833V37.3334H46.3972C46.18 36.3024 45.7325 35.3338 45.0882 34.5001H50.0833Z"
        fill="#F2755F"
      />
    </svg>
  );
};
export default Revenue;
