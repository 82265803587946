/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { BaseNodeModel } from '../BaseNodeModel/BaseNodeModel';
import NodeTypes from '../BaseNodeModel/NodeTypes';
import * as PortTypes from '../BaseNodeModel/PortModels';
import Specs from '../BaseNodeModel/SpecsModel';

export class TimedNodeModel extends BaseNodeModel {
  constructor(options = {}) {
    super({
      type: NodeTypes.TIMED_NODE,
      ...options
    });
    this.templateId = '';
    this.templateCategoryId = options.templateCategoryId;
    this.nextNodes = [NodeTypes.ACTION_NODE];
    this.timeConstraint = [
      new Specs('time', 'string', 'time'),
      new Specs(
        'frequency',
        'dropdown',
        'Frequency',
        [('days', 'weeks', 'months')],
        [],
        'days'
      )
    ];
    this.buildPorts([new PortTypes.OutPort()]);
  }

  serialize() {
    return {
      ...super.serialize(),
      templateCategoryId: this.templateCategoryId,
      templateId: this.templateId,
      timeConstraint: this.timeConstraint
    };
  }

  deserialize(ob, engine) {
    super.deserialize(ob, engine);
    const data = ob.data;
    this.templateCategoryId = data.templateCategoryId;
    this.timeConstraint = data.timeConstraint;
    this.templateId = data.templateId;
  }

  setInputFields(templateId, specs, timeConstraint) {
    this.templateId = templateId;
    this.specs = specs;
    this.timeConstraint = timeConstraint;
  }
}
