import config from '../config/index';
import queryString from 'query-string';

function getPublicFlipbookById(flipbookIdOrToken: number) {
  return fetch(`${config.ruleServerUrl}/flipbook/id/${flipbookIdOrToken}`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  }).then(r => r.json());
}

function getFlipbookByToken(flipbookIdOrToken: string) {
  const qs = queryString.stringify(
    {
      token: flipbookIdOrToken
    },
    { arrayFormat: 'bracket' }
  );
  return fetch(`${config.ruleServerUrl}/flipbook/token?${qs}`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  }).then(r => r.json());
}

function getFlipbookById(id: number) {
  return fetch(`${config.ruleServerUrl}/users/flipbook/id/${id}`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  }).then(r => r.json());
}

function getFlipbookValidity(product: string) {
  return fetch(
    `${config.ruleServerUrl}/tenant-customers/checkPlanValidity/${product}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
}

function listFlipbooks(storeName: string) {
  const qs = queryString.stringify(
    {
      storeName
    },
    { arrayFormat: 'bracket' }
  );
  return fetch(`${config.ruleServerUrl}/users/flipbook/?${qs}`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  }).then(r => r.json());
}

function updateFlipbookById(
  id: number,
  flipbookData: any,
  flipbookConfig: any
) {
  return fetch(`${config.ruleServerUrl}/users/flipbook/id/${id}`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify({ flipbookConfig, flipbookData })
  }).then(r => r.json());
}

function createFlipbook(
  shortCode: string,
  flipbookConfig: any,
  defaultSettingsConfig: any,
  description?: string
) {
  return fetch(`${config.ruleServerUrl}/users/flipbook/create`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify({
      shortCode,
      description,
      data: flipbookConfig,
      isEmptyPagesHided: defaultSettingsConfig.isEmptyPagesHided,
      checkoutOption: defaultSettingsConfig.checkoutOption
    })
  }).then(r => r.json());
}

function getFlipbookJwtTokenById(
  tenantId: number,
  tenantKey: string,
  id: number,
  businessUnitId: number
) {
  const testData = {
    tenantId: tenantId,
    tenantKey: tenantKey,
    flipbookId: id,
    businessUnitId: businessUnitId
  };
  return fetch(`${config.ruleServerUrl}/users/jwt/flipbook`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify(testData)
  }).then(r => r.json());
}

const formData = (fileData: any) => {
  const formData = new FormData();
  formData.append('file', fileData);
  return formData;
};

function getProductsByCSV(data: any, businessId: number) {
  return fetch(
    `${config.ruleServerUrl}/users/casa-product-master/flipbook-products-csv/${businessId}`,
    {
      credentials: 'include',
      method: 'POST',
      body: formData(data)
    }
  ).then(r => r.json());
}

export default {
  getPublicFlipbookById,
  getFlipbookByToken,
  getFlipbookById,
  listFlipbooks,
  updateFlipbookById,
  createFlipbook,
  getFlipbookJwtTokenById,
  getProductsByCSV,
  getFlipbookValidity
};
