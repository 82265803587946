function downloadCSV(blob: any, date: string) {
  const data = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = data;
  a.download = `UNBLOCK_POINTS_REPORT_${date}.csv`;
  document.body.appendChild(a);
  a.click();

  setTimeout(function() {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(data);
  }, 100);
}

async function getJSONFromCSV(link: any) {
  try {
    return await fetch(link, {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }).then(r => r.blob());
  } catch (err) {
    console.log(err);
  }
}

export const downloadCSVHandler = async (link: any, date: string) => {
  const data = await getJSONFromCSV(link);
  downloadCSV(data, date);
};
