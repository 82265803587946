import React, { FC } from 'react';
import _, { isEqual } from 'lodash';
import SingleLayout from '../../SharedComponents/LayoutType/SingleLayout';
import DoubleLayout from '../../SharedComponents/LayoutType/DoubleLayout';
import {
  FlipbookLayout,
  LayoutType
} from '../../../../../models/FlipbookV2/FlipbookV2Layout.model';

interface MobileViewProps {
  editPanelOccupiedKey: string;
  setEditPanelOccupiedKey: (key: string) => void;
  page: FlipbookLayout;
  isEditMode: boolean;
  isCustomerView: boolean;
  handleModalOpen: (groupId: string) => void;
  hasPermission: (permission: string) => boolean;
}

const MobileView: FC<MobileViewProps> = ({
  editPanelOccupiedKey,
  setEditPanelOccupiedKey,
  page,
  isEditMode,
  isCustomerView,
  handleModalOpen,
  hasPermission
}) => {
  function isBothTilesHaveBgImg() {
    const tile = _.get(page, 'flipbookLayoutTile', []);
    // Check whether two tiles exist or not
    return isEqual(tile.length, 2);
  }
  // TODO : Split based on page type
  // TODO: Remove handle modal open for mobile view or make it responsive
  return (
    <>
      {page.layoutType === LayoutType.DOUBLE && isBothTilesHaveBgImg() && (
        <DoubleLayout
          editPanelOccupiedKey={editPanelOccupiedKey}
          setEditPanelOccupiedKey={setEditPanelOccupiedKey}
          page={page}
          isEditMode={isEditMode}
          handleModalOpen={handleModalOpen}
          isCustomerView={isCustomerView}
          hasPermission={hasPermission}
        />
      )}
      {((page.layoutType === LayoutType.DOUBLE && !isBothTilesHaveBgImg()) ||
        page.layoutType === LayoutType.SINGLE) && (
        <SingleLayout
          editPanelOccupiedKey={editPanelOccupiedKey}
          setEditPanelOccupiedKey={setEditPanelOccupiedKey}
          page={page}
          isEditMode={isEditMode}
          handleModalOpen={handleModalOpen}
          isCustomerView={isCustomerView}
          hasPermission={hasPermission}
        />
      )}
    </>
  );
};

export default MobileView;
