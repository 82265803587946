import React, { FC, useState } from 'react';

import TierReportDownload from '../../../models/TenantReports/TierReportDownload';
import { getTierDowngradeReportDownload } from '../../../services/TenantReports/TierDowngradeReport.service';
import usePagination from '../../../shared/hooks/usePagination';
import TierDowngradeFilter from './Filter';
import TierDowngradeReportDownloadTable from './List';

const TierDowngradeTenantReport: FC = () => {
  const [refreshView, setRefreshView] = useState<string>('');
  const { data, isLoading, page, offset, setPage, setOffset } = usePagination<
    TierReportDownload[]
  >(getTierDowngradeReportDownload, [refreshView]);
  return (
    <div style={{ padding: '2rem' }}>
      <div>
        <h4>Tier Downgrade Report</h4>
      </div>
      <TierDowngradeFilter
        setRefreshView={setRefreshView}
        page={page}
        offset={offset}
      />
      <TierDowngradeReportDownloadTable
        reports={data || []}
        isLoading={isLoading}
        page={page}
        offset={offset}
        setPage={setPage}
        setOffset={setOffset}
      />
    </div>
  );
};

export default TierDowngradeTenantReport;
