import React from 'react';
import * as SuggestionsTrayIcons from '../../../Icons/svg/SuggestionsTrayIcons';
import classes from './SuggestionsTrayItem.module.css';

export const SuggestionsTrayItem = ({ onClick, nextNode }) => {
  const NextNodeIcon = SuggestionsTrayIcons[nextNode];
  return (
    <div onClick={onClick} className={classes.container}>
      <NextNodeIcon />
      <div className={classes.nodeLabel}>{nextNode.split(/(?=[A-Z])/)[0]}</div>
    </div>
  );
};
