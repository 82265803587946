export enum CheckoutOption {
  Store = 'Store',
  FlipbookCheckout = 'FlipbookCheckout',
  Ecommerce = 'Ecommerce'
}

export enum Themes {
  DARK = 'DARK',
  LIGHT = 'LIGHT'
}

export class Flipbook {
  public tenantId?: number;
  public isEmptyPagesHided: boolean;
  public checkoutOption: CheckoutOption;
  public name: string;
  public tenantStoreId?: string | null;
  public tenantBrandBusinessId?: number;
  public theme: Themes;
  public data?: any;
  public oddBackgroundUrl?: string = '';
  public evenBackgroundUrl?: string = '';
  public isUserStoreSelectionAvailable?: boolean = false;
  public isActive?: boolean = true;
  public groupingHierarchy?: string[] = [];
  public coverImageUrl?: string = '';
  public shortCode: string;
  public description: string;

  constructor(
    isEmptyPagesHided: boolean,
    checkoutOption: CheckoutOption,
    name: string,
    theme: Themes,
    shortCode: string,
    description: string,
    data?: any,
    oddBackgroundUrl?: string,
    evenBackgroundUrl?: string,
    isUserStoreSelectionAvailable?: boolean,
    isActive?: boolean,
    groupingHierarchy?: string[],
    tenantId?: number,
    tenantStoreId?: string | null,
    tenantBrandBusinessId?: number,
    coverImageUrl?: string
  ) {
    this.tenantId = tenantId;
    this.isEmptyPagesHided = isEmptyPagesHided;
    this.checkoutOption = checkoutOption;
    this.name = name;
    this.tenantStoreId = tenantStoreId;
    this.tenantBrandBusinessId = tenantBrandBusinessId;
    this.theme = theme;
    this.oddBackgroundUrl = oddBackgroundUrl;
    this.evenBackgroundUrl = evenBackgroundUrl;
    this.isUserStoreSelectionAvailable = isUserStoreSelectionAvailable;
    this.isActive = isActive;
    this.groupingHierarchy = groupingHierarchy;
    this.coverImageUrl = coverImageUrl;
    this.shortCode = shortCode;
    this.description = description;
    this.data = data;
  }
}
