import React from 'react';
import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import RupeeFit from '../../assets/rupeeFit.png';
import { GetBarCode } from '../utils';
import Disclimer from '../../assets/disclimer.png';
import moment from 'moment';
import Warranty from './warranty';
import {
  getSplitDescription,
  truncatedNumber,
  truncateString
} from '../../../../utils/index';
import _ from 'lodash';

const styles = StyleSheet.create({
  card: {
    fontFamily: 'OpenSans',
    margin: '3px',
    height: '145.3mm',
    width: '206.2mm',
    border: '1 solid #000000',
    marginBottom: '2mm',
    marginLeft: '2mm'
  },
  qrSection: {
    height: 178.5,
    borderBottom: '1 solid #000000',
    display: 'flex',
    flexDirection: 'row'
  },
  descriptionSection: {
    height: 134.48,
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid #000000'
  },
  amountSection: {
    height: 124.44,
    borderBottom: '1 solid #000000',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  qrcode: {
    width: '59mm',
    height: '59mm',
    margin: '1mm 2mm'
  },
  about: {
    // borderLeft: '1 solid #000000',
    fontSize: '14pt',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    width: 400.24,
    wordWrap: 'break-word',
    paddingLeft: '2mm',
    paddingTop: '3mm'
  },
  pricewithRupeeIcon: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent:'center'
    width: '132mm'
  },
  rupeeicon: {
    fontSize: '30pt',
    width: '15mm',
    paddingTop: '8mm',
    paddingLeft: '5mm'
  },
  price: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold',
    fontSize: '90pt',
    // textAlign: 'left',
    color: 'black',
    // paddingLeft: '10mm',
    top: -8,
    display: 'flex',
    flexDirection: 'column'
    // lineHeight: 1
  },
  rupeeSmall: {
    width: '5mm',
    height: '5mm'
  },
  warrantyWithBarcode: {
    width: '100%',
    marginLeft: '3mm',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  warranty: {
    height: '18.7mm',
    width: '18.7mm',
    margin: '1mm',
    marginRight: 10,
    marginLeft: 10
  },
  warrantyDuriation: {
    position: 'absolute',
    height: '10.5mm',
    width: '10.5mm',
    fontWeight: 'bold',
    margin: '4mm',
    backgroundColor: '#fff',
    borderRadius: '50',
    alignItems: 'center',
    justifyContent: 'center'
  },
  warrantyValue: { fontSize: '20.12pt', left: 0, top: 1 },
  warrantyYear: { fontSize: '5.64pt', top: -5, left: 1 },
  warrantyText: {
    fontSize: '9.64pt',
    color: '#1A1818',
    opacity: '1'
  },

  footer: {
    padding: '1mm ',
    top: 7
  },
  dateDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 9,
    marginTop: 12
  },
  itemCode: {
    top: -15,
    paddingTop: '10mm',
    fontSize: '8pt',
    opacity: '1',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  barcodeBlock: {
    // width: '190mm',
    textAlign: 'right',
    justifyContent: 'center',
    alignItems: 'right',
    paddingBottom: '8mm',
    paddingRight: '5mm',
    top: 10
  },
  barcode: {
    top: 2,
    width: '40mm'
    // padding: '1mm 1mm 0mm 0mm'
  },
  lineHeight: { paddingLeft: '4mm', height: '10mm' },
  disclimer: {
    width: '3.75mm',
    height: '3.75mm',
    left: 4
  }
});

const A5Portrait = ({ title = '', data, count, ...rest }) => {
  return (
    <Page size="A4" orientation="portrait">
      {count &&
        count.map(id =>
          data?.map(item => (
            <View style={styles.card} key={item?.id}>
              <View style={styles.qrSection}>
                <View style={styles?.qrcode}>
                  <Image src={item.image} />
                </View>
                <View style={styles.about}>
                  <Text>
                    {truncateString(item?.product?.masterData?.name, 130)}
                  </Text>
                  {/*<Text>
                    {truncateString(
                      item?.description ||
                        item?.product?.masterData?.description ||
                        '',
                      130
                    )}
                  </Text>*/}
                  <View
                    style={{
                      width: '100%',
                      fontSize: '12pt',
                      paddingTop: '3mm',
                      lineHeight: 1.6
                    }}
                  >
                    {/* <Text>
                      {item?.description ||
                        item?.product?.masterData?.description ||
                        ''}
                    </Text> */}

                    {/* placeHolder Content */}
                    <View>
                      {getSplitDescription(item.description, 4).map(
                        (line, index) => (
                          <Text key={index}>{line}</Text>
                        )
                      )}
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.amountSection}>
                <View style={styles.pricewithRupeeIcon}>
                  <View style={styles.rupeeicon}>
                    <Image src={RupeeFit} />
                  </View>
                  <View style={{ position: 'relative' }}>
                    <Text style={styles.price}>
                      {truncatedNumber(item?.product?.mop, 6)}
                    </Text>
                    <Text
                      style={{
                        position: 'absolute',
                        fontSize: '8pt',
                        right: 0,
                        bottom: 20
                      }}
                    >
                      *Incl. Tax
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    width: '80mm',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    height: '40mm'
                  }}
                >
                  {truncatedNumber(item?.product?.masterData, 6) > 0 ? (
                    <Text style={{ display: 'flex', marginTop: '12mm' }}>
                      <Text style={{ fontWeight: 'bold' }}>MRP: </Text>
                      <Image style={styles?.rupeeSmall} src={RupeeFit} />
                      <Text>{truncatedNumber(item?.product?.mrp, 6)}</Text>
                    </Text>
                  ) : (
                    <Text />
                  )}
                </View>
              </View>
              <View style={styles?.footer}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <View style={styles.warrantyWithBarcode}>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <Warranty warranty={item.warranty} styles={styles} />
                    </View>
                    <View style={styles.barcodeBlock}>
                      <View style={styles.barcode}>
                        {_.isEmpty(item.barcode) ? (
                          <></>
                        ) : (
                          <Image src={GetBarCode(item?.barcode)} />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
                <View>
                  <View
                    style={
                      item?.warranty
                        ? styles?.dateDetails
                        : { ...styles?.dateDetails, top: -80 }
                    }
                  >
                    <View style={styles.itemCode}>
                      <Text>
                        {`${item?.sku}  |  ${item.class}  |  A  | ${moment(
                          item?.date
                        ).format('DD.MM.YYYY')}  |  ${moment().format(
                          'DD.MM.YYYY'
                        )}`}
                      </Text>
                      <View>
                        <Image style={styles.disclimer} src={Disclimer} />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          ))
        )}
      {/* {title} */}
    </Page>
  );
};
export { A5Portrait };
