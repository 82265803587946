/* eslint-disable @typescript-eslint/explicit-function-return-type */
import '../GeneralSetting/index.scss';

import { Box, Grid, Modal, Typography } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as AddIcon } from '../../components/Assets/images/icons/add_icon_round.svg';
import { createUserInitialState } from '../../constants/createuser';
import ButtonField from '../../reusable/ButtonField';
import { TextFieldComponent } from '../../reusable/TextFieldComponent';
import TenentStoreService from '../../services/tenentStore.service';
import { toastMessageAction } from '../../store/action';
import { createUserValidation } from '../../validation/createUser';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import { styled } from '@material-ui/styles';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

const StyledAppTextField = styled(TextField)({
  '& .MuiInputLabel-outlined': {
    color: '#A3A3A3 !important',
    marginRight: 15
  },
  '& .MuiFormLabel-filled': {
    color: '#f3755f !important',
    fontSize: '16px !important'
  },
  '& .MuiOutlinedInput-input': {
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 100px #fff inset',
      WebkitTextFillColor: '#A3A3A3'
    },
    color: '#464343'
  },
  '& input::placeholder': {
    fontSize: '13px'
  },
  '& .MuiInputBase-input': {
    paddingLeft: 10
  }
});

const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  outline: 'none',
  boxShadow: 24,
  height: '80%',
  overflowY: 'scroll',
  fontFamily: 'nunito'
};
const filter = createFilterOptions();

export default function CreateStore(props) {
  const { storeStatus, setStoreStatus, storeTagsOption, business } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [additionalInfo, setAdditionalInfo] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const [formValues, setFormValues] = useState([
    { type: '', value: '', tagsError: '' }
  ]);
  const [storeTagsError, setStoreTagsError] = useState(false);
  const businessCheck = ['business', 'bu', 'businessunit', 'business unit'];
  const onSubmit = (data, { resetForm }) => {
    let businessUnitAdded = false;
    let tags = formValues
      .filter(element => element.type !== 'businessUnit')
      ?.map(item => {
        if (businessCheck.includes(item.type.toLowerCase())) {
          businessUnitAdded = true;
        }
        return item;
      });
    if (!businessUnitAdded) {
      tags = [...tags, { type: 'businessUnit', value: business.name }];
    }
    const startDate = moment(data.startDate).format('YYYY-MM-DD');
    const store = {
      ...data,
      tags: tags,
      tenantBrandBusinessId: business.id,
      startDate: startDate
    };
    if (!storeTagsError && !businessUnitAdded) {
      TenentStoreService.saveTenentStoreConfig(business.id, [store]).then(
        res => {
          const severity = res?.status === 'failure' ? 'error' : 'success';
          const message =
            res?.status === 'failure'
              ? res?.errors[0]?.message
              : 'Create store add successfully !';
          resetForm();
          setOpen(false);
          setStoreStatus(!storeStatus);
          dispatch(
            toastMessageAction({
              severity: severity,
              message: message,
              messageStatus: true
            })
          );
        }
      );
    }
  };

  //   to handle formik data
  const formik = useFormik({
    initialValues: createUserInitialState,
    validationSchema: createUserValidation,
    onSubmit
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    setFieldValue
  } = formik;
  const handleClose = () => {
    setOpen(false);
    resetForm();
    setFormValues(storeTagsOption);
  };

  const formHandleChange = (i, e) => {
    const newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  const addFormFields = () => {
    setFormValues([...formValues, { type: '', value: '', tagsError: '' }]);
  };

  const removeFormFields = i => {
    const newFormValues = [...formValues];
    newFormValues[i]?.tagsError && setStoreTagsError(false);
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  useEffect(() => {
    setFormValues(storeTagsOption);
  }, [storeTagsOption]);

  const handleDateChange = date => {
    setFieldValue('startDate', date);
  };
  return (
    <div>
      <div className={'createStoreAlign'} onClick={handleOpen}>
        <AddIcon />
        <span>Create Store</span>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000
        }}
      >
        <Box sx={boxStyle} style={{ padding: '10px 12px' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
          </div>
          <div className="modalContainer">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h6"
              className="createStoreTitle"
            >
              Create Store
            </Typography>
            <form onSubmit={formik.handleSubmit} className="formContainer">
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={'Store*'}
                    name={'store'}
                    onChange={handleChange}
                    value={values.store}
                    error={touched.store && errors.store}
                    className="custom-input"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={'Store Name Alias*'}
                    name={'storeNameAlias'}
                    onChange={handleChange}
                    value={values.storeNameAlias}
                    error={touched.storeNameAlias && errors.storeNameAlias}
                    className="custom-input"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={'Digital Store Name'}
                    type={'text'}
                    name={'digitalStoreName'}
                    onChange={handleChange}
                    value={values.digitalStoreName}
                    className="custom-input"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={'Store Manager Email'}
                    type={'text'}
                    name={'storeManagerEmail'}
                    onChange={handleChange}
                    value={values.storeManagerEmail}
                    className="custom-input"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={'Mobile*'}
                    type={'tel'}
                    name={'mobile'}
                    onChange={event =>
                      setFieldValue(
                        'mobile',
                        event.target.value.replace(/[^0-9^ ]/gi, '')
                      )
                    }
                    value={values.mobile}
                    error={touched.mobile && errors.mobile}
                    className="custom-input"
                  />
                </Grid>
                {/* <Grid item xs={6}>
                  <TextFieldComponent
                    label={'City'}
                    name={'city'}
                    onChange={handleChange}
                    value={values.city}
                    className="custom-input"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={'Location'}
                    name={'location'}
                    onChange={handleChange}
                    value={values.location}
                    className="custom-input"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={'Longitude'}
                    type={'number'}
                    name={'longitude'}
                    onChange={handleChange}
                    value={values.longitude}
                    error={touched.longitude && errors.longitude}
                    className="custom-input"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldComponent
                    label={'Latitude'}
                    type={'number'}
                    name={'latitude'}
                    onChange={handleChange}
                    value={values.latitude}
                    error={touched.latitude && errors.latitude}
                    className="custom-input"
                  />
                </Grid> */}
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      label="Start Date*"
                      value={values.startDate}
                      onChange={handleDateChange}
                      error={
                        touched.startDate &&
                        errors.startDate &&
                        errors.startDate !== ''
                      }
                      helperText={touched.startDate && errors.startDate}
                      fullWidth
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '20px' }}>
                  {!additionalInfo && (
                    <ButtonField
                      variant="contained"
                      label={'Additional Information'}
                      type="button"
                      onClick={() => setAdditionalInfo(true)}
                      style={{
                        background: '#F07663',
                        color: 'white',
                        height: '40px'
                      }}
                    />
                  )}
                  {additionalInfo && (
                    <ButtonField
                      variant="contained"
                      label={'Remove Additional Information'}
                      type="button"
                      onClick={() => setAdditionalInfo(false)}
                      style={{ marginRight: 10, height: '40px' }}
                    />
                  )}
                </Grid>
                {additionalInfo && (
                  <>
                    <Grid item xs={4}>
                      <TextFieldComponent
                        label={'Address Line 1'}
                        name={'addressLineOne'}
                        onChange={handleChange}
                        value={values?.addressLineOne}
                        className="custom-input"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextFieldComponent
                        label={'Address Line 2'}
                        name={'addressLineTwo'}
                        onChange={handleChange}
                        value={values?.addressLineTwo}
                        className="custom-input"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextFieldComponent
                        label={'City'}
                        name={'city'}
                        onChange={handleChange}
                        value={values?.city}
                        className="custom-input"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextFieldComponent
                        label={'State'}
                        name={'state'}
                        onChange={handleChange}
                        value={values?.state}
                        className="custom-input"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextFieldComponent
                        label={'Pincode'}
                        type={'number'}
                        name={'pincode'}
                        onChange={handleChange}
                        value={values?.pincode}
                        className="custom-input"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextFieldComponent
                        label={'GST Number'}
                        name={'gstin'}
                        onChange={handleChange}
                        value={values?.gstin}
                        className="custom-input"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextFieldComponent
                        label={'Store Landline'}
                        type={'tel'}
                        name={'landline'}
                        onChange={handleChange}
                        value={values?.landline}
                        error={touched?.landline && errors?.landline}
                        className="custom-input"
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h6"
                    className="createStoreTagsTitle"
                  >
                    Create Store Tags
                  </Typography>
                  <Grid container spacing={3} style={{ marginTop: '20px' }}>
                    <Grid item xs={4}>
                      <StyledAppTextField
                        required
                        label={'TYPE'}
                        value={'Business Unit'}
                        variant="outlined"
                        disabled={true}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <StyledAppTextField
                        required
                        label={'Value'}
                        value={business.name}
                        variant="outlined"
                        disabled={true}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {!formValues.length && (
                        <ButtonField
                          variant="contained"
                          label={'Add'}
                          type="button"
                          onClick={() => addFormFields()}
                          style={{
                            background: '#F07663',
                            color: 'white',
                            height: '40px'
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                  {formValues.map((element, index) => {
                    if (element.type !== 'businessUnit') {
                      return (
                        <Grid
                          container
                          spacing={3}
                          key={index}
                          style={{ marginTop: '20px' }}
                        >
                          <Grid item xs={4}>
                            <Autocomplete
                              value={element.type || ''}
                              onChange={(event, newValue) => {
                                const getAllStoreTags = formValues.map(
                                  tagsType => {
                                    return tagsType?.type.toLowerCase();
                                  }
                                );

                                const newFormValues = [...formValues];
                                const checkStoreTagsType = _.includes(
                                  getAllStoreTags,
                                  newValue?.type.toLowerCase()
                                );
                                setStoreTagsError(checkStoreTagsType);
                                if (newValue && newValue.inputValue) {
                                  checkStoreTagsType
                                    ? (newFormValues[index]['tagsError'] =
                                        'Already Exists')
                                    : (newFormValues[index]['tagsError'] = '');
                                  newFormValues[index]['type'] =
                                    newValue.inputValue;

                                  setFormValues(newFormValues);
                                } else if (newValue) {
                                  checkStoreTagsType
                                    ? (newFormValues[index]['tagsError'] =
                                        'Already Exists')
                                    : (newFormValues[index]['tagsError'] = '');
                                  newFormValues[index]['type'] = newValue.type;
                                  newFormValues[index]['value'] =
                                    newValue.value;
                                  setFormValues(newFormValues);
                                }
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                // Suggest the creation of a new value
                                if (params.inputValue !== '') {
                                  filtered.push({
                                    value: params.inputValue,
                                    type: params.inputValue
                                  });
                                }

                                return filtered;
                              }}
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              options={storeTagsOption.filter(
                                option => option.type !== 'businessUnit'
                              )}
                              getOptionLabel={option => {
                                if (typeof option === 'string') {
                                  return option;
                                }
                                if (option.inputValue) {
                                  return option.inputValue;
                                }
                                return option.type;
                              }}
                              renderOption={option => option.type}
                              freeSolo
                              renderInput={params => (
                                <StyledAppTextField
                                  required
                                  {...params}
                                  label="Type"
                                  name={'type'}
                                  variant="outlined"
                                  // error={element.tagsError}
                                  helperText={
                                    element.tagsError ||
                                    (businessCheck.includes(
                                      params.inputProps.value.toLowerCase()
                                    ) &&
                                      `${params.inputProps.value} cannot be added tag`)
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <StyledAppTextField
                              required
                              label={'Value'}
                              name={'value'}
                              onChange={e => formHandleChange(index, e)}
                              value={element.value || ''}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {formValues.length > 1 ? (
                              <ButtonField
                                variant="contained"
                                type="button"
                                label={'Remove'}
                                onClick={() => removeFormFields(index)}
                                style={{ marginRight: 10, height: '40px' }}
                              />
                            ) : null}
                            {!storeTagsError &&
                              index === formValues.length - 1 && (
                                <ButtonField
                                  variant="contained"
                                  label={'Add'}
                                  type="button"
                                  onClick={() => addFormFields()}
                                  style={{
                                    background: '#F07663',
                                    color: 'white',
                                    height: '40px'
                                  }}
                                />
                              )}
                          </Grid>
                        </Grid>
                      );
                    }
                    return '';
                  })}
                </Grid>
                <Grid item xs={12}>
                  {formValues.length > 0 && (
                    <Typography style={{ fontWeight: '700' }}>
                      Created Tags
                    </Typography>
                  )}
                  <div className="storeTagsListRoot">
                    {formValues.map((item, index) => {
                      if (
                        item?.type &&
                        item?.value &&
                        item.type !== 'businessUnit'
                      ) {
                        return (
                          <div
                            key={index}
                            style={{ margin: '20px -4px 20px 0px' }}
                          >
                            <span className="storeTagsList">{`${item.type} > ${item.value}`}</span>
                            {formValues.length > 1 && (
                              <CloseIcon
                                className="storeTagsCloseButton"
                                onClick={() => removeFormFields(index)}
                              />
                            )}
                          </div>
                        );
                      }
                      return '';
                    })}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {storeTagsError && (
                    <Typography style={{ fontWeight: '700', color: 'red' }}>
                      Duplicate Store Tags not allowed, please remove. *
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <div className="submitButton" style={{ marginTop: 35 }}>
                <ButtonField
                  variant="contained"
                  label={'CANCEL'}
                  type="button"
                  onClick={handleClose}
                  style={{
                    color: '#F07663',
                    background: 'white',
                    marginRight: 15
                  }}
                />
                <ButtonField
                  variant="contained"
                  label={'Create'}
                  type="submit"
                  style={{ background: '#F07663', color: 'white' }}
                />
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
