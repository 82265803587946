import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import { Typography } from '@material-ui/core';
import { SectionType } from '../../../../DynamicInvoiceGenerator/InvoiceGenerator';
import { InvoiceContext } from '../../../context/InvoiceContext';
import InputField from '../../InputField/InputField';
import classes from './BusinessDetails.module.scss';
import sectionUtils from '../../../utils/sectionUtils';
import SectionField from '../../../../DynamicInvoiceGenerator/models/SectionField';
import AntSwitch from '../../Switch/AntSwitch';
import { fileToDataUri } from '../../../shared/file.service';
import useDebounce from '../../../shared/use-debounce';

enum Field {
  label = 'label',
  req = 'req',
  value = 'value'
}
const type = SectionType.TENANT_INFO;

function getAlterableField(getter: string): string {
  const map = {
    logo: Field.value,
    tenantName: Field.value,
    'store.address': Field.label,
    'store.gstin': Field.label
  } as { [key: string]: string };

  const type = map[getter] ?? Field.label;
  return type;
}

function getDefaultFieldLabels(getter: string): string {
  const getterLabel = {
    tenantName: 'Enter Businness Name',
    logo: 'Upload Business Logo',
    'bill.storeAddress': 'Enter Business Address',
    'bill.gstin': 'Enter GSTIN'
  } as { [key: string]: string };

  return getterLabel[getter] || 'Enter Field Name';
}

const BusinessDetails: React.FC = () => {
  const { getConfig, setConfig } = useContext(InvoiceContext);
  const { heading, fields } = getConfig(type);

  const [inputFields, setInputFields] = useState(
    sectionUtils.getInitialValuesByIndex(fields)
  );
  const debouncedFields = useDebounce(inputFields, 1000);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { name, value, files } = e.target;

    const alterField = getAlterableField(name);
    if (!files) {
      return setInputFields({
        ...inputFields,
        [name]: { ...inputFields[name], [alterField]: value, req: true }
      });
    }
    let file = _.head(e.target.files) as File;
    fileToDataUri(file).then(dataUri =>
      setInputFields({
        ...inputFields,
        [name]: {
          ...inputFields[name],
          label: file.name,
          [alterField]: dataUri as string,
          req: true
        }
      })
    );
  };

  useEffect(
    () => setConfig(type, inputFields),
    // eslint-disable-next-line
    [debouncedFields]
  );

  function renderField({ label, type, value, id }: SectionField, idx: number) {
    const key = id!;
    const defaultLabel = getDefaultFieldLabels(key);
    return (
      <div key={idx} className={classes.fields}>
        <div className={classes.switch}>
          <AntSwitch
            checked={inputFields[key].req ?? false}
            onChange={e => {
              setInputFields(prev => ({
                ...prev,
                [key]: { ...prev[key], req: e.target.checked }
              }));
            }}
            name={key}
          />
          <p>Show the field in the invoice</p>
        </div>
        <InputField
          type={type}
          onChange={handleChange}
          name={key}
          placeholder={defaultLabel}
          label={`Default Field Name`}
          disabled={true}
        />
        <InputField
          type={type}
          onChange={handleChange}
          name={key}
          placeholder={value || label}
          label={defaultLabel}
        />
      </div>
    );
  }

  function renderForm() {
    return (
      <form className={classes.form}>
        {fields.map(({ value, getter, type, label, id }, idx) => {
          const key = id!;
          const defaultLabel = getDefaultFieldLabels(key);
          if (getAlterableField(key) === 'value')
            return (
              <InputField
                key={idx}
                fullWidth={true}
                type={type}
                onChange={handleChange}
                name={key}
                label={defaultLabel}
                placeholder={value || label}
              />
            );
          return renderField({ value, getter, type, label, id }, idx);
        })}
      </form>
    );
  }
  return (
    <div className={classes.container}>
      <Typography variant="h6" className={classes.heading}>
        {heading}
      </Typography>
      {renderForm()}
    </div>
  );
};
export default BusinessDetails;
