import React, { FC, useState } from 'react';
import LeadReportTable from './LeadReportTable';
import LeadReportFilter from './LeadReportFilter';
import usePagination from '../../../shared/hooks/usePagination';
import { capitalize } from 'lodash';
import { getLeadReportDownload } from '../../../services/TenantReports/LeadReport.service';

interface LeadReportInterface {
  type: string;
}
const LeadReport: FC<LeadReportInterface> = ({ type }) => {
  const [refreshView, setRefreshView] = useState<string>('');
  const { data, isLoading, page, offset, setPage, setOffset } = usePagination(
    getLeadReportDownload,
    [refreshView],
    type
  );

  const getHeaderFromType = () => {
    return type
      .split('_')
      .map((word: string) => {
        return capitalize(word);
      })
      .join(' ');
  };
  return (
    <div style={{ padding: '2rem' }}>
      <div>
        <h4>{getHeaderFromType()}</h4>
      </div>
      <LeadReportFilter setRefreshView={setRefreshView} type={type} />
      <LeadReportTable
        reports={data || []}
        isLoading={isLoading}
        page={page}
        offset={offset}
        setPage={setPage}
        setOffset={setOffset}
      />
    </div>
  );
};

export default LeadReport;
