/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { TimedNodeModel } from './TimedNodeModel';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import NodeWidgetHOC from '../NodeWidgetHOC/NodeWidgetHOC';
import BaseNodeWidget from '../NodeWidgetHOC/BaseNodeWidget';
import NodeTypes from '../BaseNodeModel/NodeTypes';

export class TimedNodeFactory extends AbstractReactFactory {
  constructor() {
    super(NodeTypes.TIMED_NODE);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  generateModel(event) {
    return new TimedNodeModel();
  }

  generateReactWidget(event) {
    const setInputFields = ({ templateId, specs, timeConstraint }) =>
      event.model.setInputFields(templateId, specs, timeConstraint);

    return (
      <NodeWidgetHOC node={event.model}>
        <BaseNodeWidget
          engine={this.engine}
          node={event.model}
          setInputFields={setInputFields}
        />
      </NodeWidgetHOC>
    );
  }
}
