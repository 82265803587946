/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import MenuCardStyles from './MenuCard.module.css';
const MenuCard = ({
  menuName,
  isSelected,
  isEdit,
  isCustom,
  onClick,
  onEdit
}) => {
  const getStyleAttributes = () => {
    return {
      padding: '8px 30px 8px 30px',
      borderRadius: '5px',
      fontSize: '13px',
      marginRight: '10px',
      display: 'flex',
      cursor: isEdit ? 'default' : 'pointer',
      marginBottom: '10px'
    };
  };
  return (
    <>
      <div
        onClick={
          !isEdit
            ? e => {
                e.target['name'] = menuName;
                e.persist();
                onClick(e);
              }
            : undefined
        }
        className={`${
          isSelected ? MenuCardStyles.selected : MenuCardStyles.deselected
        }`}
        style={getStyleAttributes()}
      >
        {menuName}
        {isCustom ? (
          <div
            style={{ marginLeft: '10px' }}
            onClick={
              !isEdit
                ? e => {
                    e.stopPropagation();
                    onEdit(menuName);
                  }
                : undefined
            }
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_8907_23567)">
                <path
                  d="M9.17187 3.82812H3.33854C2.89651 3.82813 2.47259 4.00372 2.16003 4.31628C1.84747 4.62884 1.67188 5.05276 1.67188 5.49479V17.1615C1.67188 17.6035 1.84747 18.0274 2.16003 18.34C2.47259 18.6525 2.89651 18.8281 3.33854 18.8281H15.0052C15.4472 18.8281 15.8712 18.6525 16.1837 18.34C16.4963 18.0274 16.6719 17.6035 16.6719 17.1615V11.3281"
                  stroke={isSelected ? '#B43021' : '#9D9D9D'}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.4219 2.58027C15.7534 2.24875 16.203 2.0625 16.6719 2.0625C17.1407 2.0625 17.5904 2.24875 17.9219 2.58027C18.2534 2.91179 18.4396 3.36143 18.4396 3.83027C18.4396 4.29911 18.2534 4.74875 17.9219 5.08027L10.0052 12.9969L6.67188 13.8303L7.50521 10.4969L15.4219 2.58027Z"
                  stroke={isSelected ? '#B43021' : '#9D9D9D'}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_8907_23567">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.0078125 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        ) : (
          undefined
        )}
      </div>
    </>
  );
};

export default MenuCard;
