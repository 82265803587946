import { Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { FC } from 'react';

import {
  CampaignOverallReport,
  CampaignReportResponse
} from '../../../../models/campaign/CampaignReport';
import { getCampaignOverallReports } from '../../../../services/Campaign/campaign-report.service';
import usePagination from '../../../../shared/hooks/usePagination';
import CampaignReportHeader from './CampaignReportHeader/CampaignReportHeader';
import CampaignReportTable from './CampaignReportTable/CampaignReportTable';
import Loader from '../../shared/components/Loader/Loader';

const CampaignOverallReports: FC = () => {
  const {
    data,
    isLoading,
    error,
    page,
    offset,
    setPage,
    setOffset
  } = usePagination<CampaignReportResponse<CampaignOverallReport>>(
    ({ page, offset, filters }) => getCampaignOverallReports({ page, offset }),
    []
  );

  const {
    rows: reports,
    count
  }: CampaignReportResponse<CampaignOverallReport> = data || ({} as any);

  if (error)
    return (
      <Alert severity="error" style={{ margin: '2rem' }}>
        {error}
      </Alert>
    );
  if (isLoading) {
    return <Loader />;
  }

  console.log(reports);
  return (
    <Container maxWidth="lg" style={{ marginBlock: '2rem' }}>
      <CampaignReportHeader />
      <CampaignReportTable
        reports={reports}
        isLoading={isLoading}
        page={page}
        rowsPerPage={offset}
        count={count}
        setPage={setPage}
        setRowsPerPage={setOffset}
      />
    </Container>
  );
};
export default CampaignOverallReports;
