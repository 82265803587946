import { FlipbookPage } from './common';
import React, { FC, useContext } from 'react';
import _ from 'lodash';
import UploadImage from '../UploadImage/UploadImage';
import FlipbookPageGradient from '../Utiles/FlipbookPageGradient';
import FlipbookPageDimensions from '../Utiles/FlipbookPageDimensions';
import Collections from '../Collections/Collections';
import { Themes } from '../../../models/FlipbookV2/flipbookV2.model';
import { FlipbookContext } from '../Hooks/FlipbookContext';
import FlipbookV2Service from '../../../services/Flipbook/flipbookV2.service';
import { FlipbookLayout } from '../../../models/FlipbookV2/FlipbookV2Layout.model';

export const FlipbookCoverPage: FC<{
  flipbookPages: FlipbookLayout[];
  isEditMode: boolean;
  goToPageNumber: (number: number) => void;
  flipbookIdOrToken: string;
  isCustomerView: boolean;
}> = ({
  isEditMode,
  goToPageNumber,
  flipbookIdOrToken,
  isCustomerView,
  flipbookPages
}) => {
  const flipbookContext = useContext(FlipbookContext);
  const flipbookData = flipbookContext.flipbookContext;
  const coverImageURL = flipbookData?.coverImageUrl;
  function onClickCoverImageUpload(event: any, type: string) {
    const coverImageURL = _.get(event, 'coverImage', '');
    if (flipbookData) {
      flipbookData.coverImageUrl = coverImageURL;
      FlipbookV2Service.updateFlipbook(flipbookData, flipbookData.id).then(
        res => {
          flipbookContext.updateFlipbook();
        }
      );
    }
  }

  return (
    <FlipbookPage
      width={FlipbookPageDimensions.getFlipbookWidth()}
      height={FlipbookPageDimensions.getFlipbookHeight()}
      style={{
        backgroundColor: flipbookData?.theme === Themes.DARK ? 'black' : 'white'
      }}
    >
      {
        <div className={'cover-page-container'}>
          <div className={'cover-page'}>
            <div className={'collections-group-container'}>
              <div className={'collections-group'}>
                <table className={'table-collections-group'}>
                  <tbody>
                    <tr>
                      {!_.isEmpty(flipbookPages) && (
                        <Collections
                          flipbookPages={flipbookPages}
                          isEditMode={isEditMode}
                          goToPageNumber={goToPageNumber}
                          isCollectionSection={false}
                          flipbookIdOrToken={flipbookIdOrToken}
                          isCustomerView={isCustomerView}
                        />
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className={'cover-img-container'}>
              <div
                className={
                  _.isEmpty(coverImageURL) && isEditMode
                    ? 'add-cover-img cover-img'
                    : 'cover-img-active cover-img'
                }
                style={{
                  backgroundImage: `url(${coverImageURL})`
                }}
              >
                <div
                  className={`add-img-icon ${isEditMode ? '' : 'display-none'}`}
                >
                  <UploadImage
                    imageUploadType={'coverImage'}
                    setImageUploadData={e =>
                      onClickCoverImageUpload(e, 'coverImage')
                    }
                    isActive={!_.isEmpty(coverImageURL)}
                    iconStyle={{}}
                  />
                  <div
                    className={_.isEmpty(coverImageURL) ? '' : 'display-none'}
                  >
                    <h3 className={'flipbook-text-header'}>
                      Click to add your Cover image
                    </h3>
                    <p className={'flipbook-text-label'}>
                      Image Dimension 466w x 560h (Max size 1mb)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={'page-effect'}
            style={{
              backgroundImage: FlipbookPageGradient.getGradientForPage(1)
            }}
          />
        </div>
      }
    </FlipbookPage>
  );
};

export default FlipbookCoverPage;
