import config from '../config/index';

export function getQR(text) {
  return fetch(`${config.ruleServerUrl}/users/qr-generation`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      url: text,
      size: 200,
      logoFileName: '',
      fileType: 'png',
      color1: '#000000',
      color2: '#000000'
    })
  })
    .then(response => response.blob())
    .then(image => {
      return Promise.resolve(URL.createObjectURL(image));
    })
    .catch(err => {
      return Promise.resolve('');
    });
}

export function getQRs(textArray) {
  return Promise.all(
    textArray.map(text =>
      getQR(text).then(url => Promise.resolve({ text, url }))
    )
  );
}
