const styles = {
  nextButton: {
    textAlign: 'center',
    font: 'Medium 14px/18px Montserrat',
    letterSpacing: 0,
    color: '#ffffff',
    opacity: 1
  },
  facebookButton: {
    borderWidth: 0,
    borderRadius: 5,
    padding: 10,
    font: 'Bold 14px/18px Arial',
    backgroundColor: '#1877F2',
    color: '#fff',
    outline: 'none',
    display: 'inline-block'
  },
  logoutButton: {
    borderWidth: 0,
    borderRadius: 5,
    padding: 10,
    font: 'Bold 14px/18px Arial',
    backgroundColor: '#1877F2',
    color: '#fff',
    outline: 'none',
    display: 'inline-block',
    flex: 1,
    height: 47,
    margin: 20
  },
  instagramButton: {
    borderRadius: 5,
    padding: 10,
    font: 'Bold 14px/18px Arial',
    backgroundImage:
      'linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)',
    color: '#fff',
    outline: 'none',
    border: 'none',
    display: 'inline-block'
  },
  radio: {
    '&$checked': {
      color: '#F2755F'
    }
  },
  checked: {}
};

export default styles;
