import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { FC } from 'react';

interface TableBodyLoaderProps {
  rowsPerPage: number;
  colSpan: number;
  height?: number | string;
  width?: number | string;
}

const TableBodyLoader: FC<TableBodyLoaderProps> = ({
  rowsPerPage,
  colSpan,
  height = 'auto',
  width = '100%'
}) => {
  return (
    <TableBody>
      {Array.from({ length: rowsPerPage }).map((_, i) => (
        <TableRow key={i}>
          {Array.from({ length: colSpan }).map((_, i) => (
            <TableCell key={i}>
              <Skeleton width={width} height={height} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};
export default TableBodyLoader;
