import config from '../config';

export const faliedMigrateCustomer = () => {
  return fetch(`${config.ruleServerUrl}/users/migrate-customers`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  }).then(r => r.json());
};
