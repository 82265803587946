import config from '../../../config';
import SectionField from '../../DynamicInvoiceGenerator/models/SectionField';

export const dataURItoBlob = (dataURI: string) => {
  let byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  let mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

export const fileToDataUri = (file: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      resolve(event.target!.result);
    };
    reader.readAsDataURL(file);
  });

const formData = (fileData: any, fileName = 'TenantLogo') => {
  const formData = new FormData();
  formData.append('uploadFile', fileData, fileName);
  return formData;
};

const isValidHttpUrl = (value: string) => {
  let url;
  try {
    url = new URL(value);
  } catch (err) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

const uploadImage = (data: FormData) => {
  return fetch(`${config.ruleServerUrl}/users/tenant-configs/fileLoad`, {
    credentials: 'include',
    method: 'POST',
    body: data
  }).then(r => r.json());
};

export const getImageUrl = async (field: SectionField): Promise<string> => {
  if (isValidHttpUrl(field.value)) {
    return field.value;
  }
  const blob = dataURItoBlob(field.value);
  const fd = formData(blob, field.label);
  const { fileUrl = '' } = await uploadImage(fd);
  return fileUrl;
};
