import React from 'react';
import { TextField } from '@material-ui/core';
import { ProductDiscount } from '../../../../models/offer/OfferRule.model';
import update from 'immutability-helper';

export const applicationMethodValueFields = (
  discount: ProductDiscount,
  updateDiscount: any
) => {
  switch (discount.applicationMethod.method) {
    case 'multiple': {
      return (
        <div className="d-flex align-items-center py-2">
          Offer applied to every
          <TextField
            type="number"
            className={'col-3 mx-3'}
            style={{ textAlign: 'right' }}
            value={discount.applicationMethod.value}
            onChange={event => {
              const updatedDiscount = update(discount, {
                applicationMethod: {
                  value: {
                    $set: parseInt(event.target.value)
                  }
                }
              });
              event.target.value !== '0' && updateDiscount(updatedDiscount);
            }}
          />
          quantity
        </div>
      );
    }
    default: {
      return <React.Fragment />;
    }
  }
};
