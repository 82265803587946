import _, { get, pick } from 'lodash';
import { PRIORITY_BY_MODE_KEY } from './constants';
import { NotificationSenders } from './NotificationSenders';
import ContentMode from './ContentMode';

export const dataFormator = (priorityName, data) => {
  const apiStructureData = {
    name: priorityName,
    priorityOrder: data.priorityOrder,
    authkey: data.authkey,
    showCampaignNameInReport: data.showCampaignNameInReport
  };
  if (priorityName === 'OneXtelSendSms') {
    return {
      ...apiStructureData,
      additionalParams: {
        entityId: data.entityId,
        authKeyServiceExplicit: data.authKeyServiceExplicit,
        authKeyPromotional: data.authKeyPromotional
      }
    };
  }
  if (priorityName === 'STMSendSms') {
    return {
      ...apiStructureData,
      additionalParams: {
        clientId: data?.clientId,
        principleEntityId: data.principleEntityId
      }
    };
  }
  if (priorityName === 'ValueFirstSendSms') {
    return {
      name: priorityName,
      priorityOrder: data.priorityOrder,
      username: data.username,
      password: data.password,
      showCampaignNameInReport: data.showCampaignNameInReport
    };
  }
  if (['Msg91SendSms', 'AmeyoWhatsAppMsg'].includes(priorityName)) {
    return apiStructureData;
  }
  if (priorityName === 'KarixSendV2') {
    return {
      ...apiStructureData,
      ...pick(data, [
        'authKeyOtp',
        'authKeyPromotional',
        'authKeyTransactional',
        'otpSender',
        'promotionalSender',
        'dltEntityId',
        'from'
      ])
    };
  }
  if (priorityName === 'SesSendEmail') {
    return {
      ...apiStructureData,
      additionalParams: {
        awsAccessKeyId: data.awsAccessKeyId,
        awsSecretAccessKey: data.awsSecretAccessKey,
        awsRegion: data.awsRegion
      }
    };
  }
  if (priorityName === 'Msg91Email') {
    return {
      name: 'Msg91Email',
      priorityOrder: data.priorityOrder,
      authkey: data.authkey,
      fromName: data.fromName,
      domain: data.domain,
      showCampaignNameInReport: data.showCampaignNameInReport
    };
  }
  if (priorityName === 'KarixWhatsAppSms') {
    return {
      ...apiStructureData,
      ..._.pick(data, [
        'authKeyPromotional',
        'authKeyTransactional',
        'promotionalSender',
        'transactionalSender'
      ])
    };
  }

  if (priorityName === 'WatiWhatsAppSms') return apiStructureData;

  if (priorityName === 'KarixEmail') {
    return {
      name: 'KarixEmail',
      priorityOrder: data.priorityOrder,
      showCampaignNameInReport: data.showCampaignNameInReport,
      userName: data.userName,
      password: data.password,
      fromEmail: data.fromEmail,
      fromName: data.fromName
    };
  }

  if (priorityName === 'WonderMail') {
    return {
      name: 'WonderMail',
      priorityOrder: data.priorityOrder,
      showCampaignNameInReport: data.showCampaignNameInReport,
      fromEmail: data.fromEmail,
      fromName: data.fromName,
      authkey: data.authkey
    };
  }

  if (priorityName === NotificationSenders.ICS_WHATSAPP) {
    return {
      name: NotificationSenders.ICS_WHATSAPP,
      priorityOrder: data.priorityOrder,
      showCampaignNameInReport: data.showCampaignNameInReport,
      from: data.from,
      user: data.user,
      pass: data.pass
    };
  }

  if (priorityName === NotificationSenders.ICS_RCS) {
    return {
      name: NotificationSenders.ICS_RCS,
      priorityOrder: data.priorityOrder,
      showCampaignNameInReport: data.showCampaignNameInReport,
      user: data.user,
      pass: data.pass
    };
  }

  if (priorityName === NotificationSenders.ICS_SMS) {
    return {
      name: NotificationSenders.ICS_SMS,
      ...pick(data, ['priorityOrder', 'showCampaignNameInReport', 'peId']),
      [ContentMode.OTP]: {
        from: data.from_OTP,
        user: data.user_OTP,
        pass: data.pass_OTP
      },
      [ContentMode.TRANSACTIONAL]: {
        from: data.from_transactional,
        user: data.user_transactional,
        pass: data.pass_transactional
      },
      [ContentMode.PROMOTIONAL]: {
        from: data.from_promotional,
        user: data.user_promotional,
        pass: data.pass_promotional
      }
    };
  }

  return {};
};

export const getPriorityList = (list, business, priorityName, contentMode) => {
  let max = 1;
  list.forEach(item => {
    if (
      business === item.businessUnit &&
      get(item, `${PRIORITY_BY_MODE_KEY}.${contentMode}`) > max
    )
      max = get(item, `${PRIORITY_BY_MODE_KEY}.${contentMode}`);
  });
  const priority = [];
  for (let i = 1; i <= max; i++) {
    priority.push(i);
  }
  return priority;
};
