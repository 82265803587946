import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { ParentLink } from '../../../utils/ParentLink';

function BreadCrumbs() {
  const src = `/general-setting${window.location.search || ''}`;

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <ParentLink key="1" color="inherit" to={src}>
          General Settings
        </ParentLink>
        ,
        <Typography key="2" color="textPrimary">
          Roles
        </Typography>
      </Breadcrumbs>
    </>
  );
}

export default BreadCrumbs;
