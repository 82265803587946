import * as _ from 'lodash';
import { allModels as Model } from './index';

function getClass(jsonData: any) {
  try {
    // @ts-ignore
    return Model[jsonData && jsonData.className];
  } catch (e) {
    return undefined;
  }
}

export class ObjectifyService {
  public objectify(jsonData: any): any {
    const ClassReference = getClass(jsonData);
    if (ClassReference) {
      const obj = new ClassReference();
      for (const field in jsonData) {
        const fieldValue = jsonData[field];
        if (_.isArray(fieldValue)) {
          obj[field] = fieldValue.map(f => this.objectify(f));
        } else if (fieldValue instanceof Object) {
          obj[field] = this.objectify(fieldValue);
        } else if (fieldValue !== null && fieldValue !== undefined) {
          obj[field] = fieldValue;
        }
      }
      return obj;
    } else {
      return jsonData;
    }
  }
}
