/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';

import classes from './MultiSelect.module.css';

const Option = props => {
  const { selected, onClick } = props;
  return (
    <div onClick={onClick} className={classes.option}>
      <span className={selected ? classes.checked : classes.unchecked}>
        <span>&#10003;</span>
      </span>
      <input type="checkbox" checked={selected} readOnly />
      <label>{props.children}</label>
    </div>
  );
};

export default Option;
