import React from 'react';
import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import RupeeFit from '../../assets/rupeeFit.png';
import Warranty from './warranty';
import { GetBarCode } from '../utils';
import Disclimer from '../../assets/disclimer.png';
import moment from 'moment';
import {
  getSplitDescription,
  truncatedNumberFloat,
  truncateString
} from '../../../../utils/index';
import _ from 'lodash';
import RupeeWhite from '../../assets/rupeeWhite.png';
import { getSavePrice } from '../utils/PriceCalculation';
import cross from '../../assets/cross.png';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'row'
  },
  card: {
    fontFamily: 'OpenSans',
    margin: '3px',
    width: '145.3mm',
    height: '207.8mm',
    border: '1 solid #000000'
  },
  qrSection: {
    height: '185px',
    borderBottom: '1 solid #000000',
    display: 'flex',
    flexDirection: 'row'
  },
  descriptionSection: {
    height: '101px',
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1 solid #000000'
  },
  amountSection: {
    height: '139px',
    borderBottom: '1 solid #000000',
    width: '100%'
  },
  qrcode: {
    width: '183px',
    height: '185px',
    borderRight: '1 solid #000000'
  },
  about: {
    fontSize: '14pt',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '212px',
    margin: '12.8px 8px',
    wordWrap: 'break-word',
    textAlign: 'justify'
  },
  pricewithRupeeIcon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
    // width: '200mm'
  },
  rupeeicon: {
    fontSize: '7pt',
    width: '7mm',
    paddingTop: '12mm'
  },
  mopSection: {
    display: 'flex',
    flexDirection: 'column'
  },
  price: {
    fontWeight: 'bold',
    fontSize: '90.1pt',
    textAlign: 'left',
    color: 'black',
    lineHeight: 1.1
  },
  inclTax: {
    fontSize: '19.6pt',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row'
  },
  rupeeSmall: {
    width: '5.8mm',
    height: '7mm',
    top: 7.88
  },
  //footer

  footer: {
    padding: '2mm 1mm'
  },
  dateDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    top: 7
  },
  itemCode: {
    top: -19,
    paddingTop: '10mm',
    left: -4,
    fontSize: '8pt',
    opacity: '1',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  barcodeBlock: {
    marginRight: '2mm',
    justifyContent: 'center'
  },
  barcode: {
    top: -15,
    width: '50mm',
    padding: '1mm 1mm 1mm 0mm'
  },
  lineHeight: { paddingLeft: '4mm', height: '10mm' },
  disclimer: {
    width: '3.75mm',
    height: '3.75mm',
    left: 20
  },
  mrpDealBlock: {
    height: '38px',
    borderBottom: '1 solid #000000'
  },
  deals: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  savetext: {
    width: '205px',
    height: '35px',
    backgroundColor: '#818386',
    borderBottom: '1 solid #000000'
  },

  saveblock: {
    width: '205px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderLeft: '1 solid #000000'
  },
  saveprice: {
    width: '205px',
    height: '66px',
    backgroundColor: '#1A1818',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  warranty: {
    height: '20mm',
    width: '20mm',
    margin: '2mm 4mm'
  },
  warrantyDuriation: {
    position: 'absolute',
    height: '14mm',
    width: '14mm',
    fontSize: '38pt',
    fontWeight: 'bold',
    margin: '3mm 3mm',
    backgroundColor: '#fff',
    borderRadius: '50',
    alignItems: 'center',
    justifyContent: 'center'
  },
  warrantyValue: { fontSize: '26.12pt', left: -2 },
  warrantyYear: { fontSize: '6.64pt', top: -7 },
  warrantyText: {
    fontSize: '9.64pt',
    color: '#1A1818',
    opacity: '1',
    left: 6
  }
});

const A5PortraitV2 = ({ title = '', data, count, ...rest }) => {
  return (
    count &&
    count.map(id => (
      <Page style={styles.page} orientation="landscape" key={id}>
        <View
          key={id}
          style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
        >
          {data?.map(item => (
            <View style={styles.card} key={item?.id}>
              <View style={styles.qrSection}>
                <View style={styles?.qrcode}>
                  <Image src={item.image} style={{ margin: '3mm' }} />
                </View>
                <View style={styles.about}>
                  <Text>
                    {truncateString(item?.product?.masterData?.name, 130)}
                  </Text>
                </View>
              </View>
              <View style={styles.mrpDealBlock}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '38px',
                    fontSize: '26pt'
                  }}
                >
                  <View style={styles.deals}>
                    <Text style={{ fontWeight: 'bold' }}>
                      {`${item?.PriceTag}: `}
                    </Text>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <Image style={styles?.rupeeSmall} src={RupeeFit} />
                      <View>
                        <View
                          style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        >
                          <Image
                            src={cross}
                            style={{
                              width: '110%',
                              height: '80%',
                              top: 1
                            }}
                          />
                        </View>
                        <Text style={{ fontSize: '26pt' }}>
                          {truncatedNumberFloat(item?.product?.mrp, 6)}
                        </Text>
                      </View>
                    </View>
                    <Text
                      style={{
                        fontSize: '12pt',
                        top: 2
                      }}
                    >
                      *
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.amountSection}>
                <View style={styles.pricewithRupeeIcon}>
                  <View style={styles.rupeeicon}>
                    <Image src={RupeeFit} />
                  </View>
                  <View style={styles.mopSection}>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <Text style={styles.price}>
                        {truncatedNumberFloat(item?.product?.mop, 6)}
                      </Text>
                      <Text
                        style={{
                          fontSize: '16.8pt',
                          top: 23,
                          fontWeight: 'bold'
                        }}
                      >
                        *
                      </Text>
                    </View>
                    <View style={{ alignItems: 'flex-end', display: 'flex' }}>
                      <View style={styles.inclTax}>
                        <Text
                          style={{
                            fontSize: '16.8pt',
                            fontWeight: 'bold',
                            top: 2
                          }}
                        >
                          *
                        </Text>
                        <Text style={{ fontSize: '27pt' }}>(Incl. Taxes)</Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.descriptionSection}>
                <View
                  style={{
                    width: '205px',
                    fontSize: '13pt',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingLeft: '3mm',
                    borderRight: '1 solid #000000'
                  }}
                >
                  <View
                    style={{
                      paddingTop: '2mm',
                      fontSize: '10pt',
                      lineHeight: 2
                    }}
                  >
                    {getSplitDescription(item.description, 4).map(
                      (line, index) => (
                        <Text key={index}>+ {truncateString(line, 35)}</Text>
                      )
                    )}
                  </View>
                </View>
                <View style={styles.saveblock}>
                  <View style={styles.savetext}>
                    <Text
                      style={{
                        color: 'white',
                        fontSize: '24.8pt',
                        textAlign: 'center',
                        fontWeight: 'bold'
                      }}
                    >
                      SAVE
                    </Text>
                  </View>
                  <View style={styles.saveprice}>
                    <>
                      <Image
                        src={RupeeWhite}
                        style={{
                          top: -8,
                          width: '16px',
                          height: '30px'
                        }}
                      />
                      <Text
                        style={{
                          fontSize: '41pt',
                          textAlign: 'left',
                          height: '58px',
                          color: 'white',
                          fontWeight: 'bold'
                        }}
                      >
                        {getSavePrice(item?.product?.mrp, item?.product?.mop)}
                      </Text>
                    </>
                  </View>
                </View>
              </View>
              <View style={styles?.footer}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                  }}
                >
                  <Warranty warranty={item.warranty} styles={styles} />
                </View>
                <View
                  style={
                    item?.warranty
                      ? styles?.dateDetails
                      : { ...styles?.dateDetails, top: 84 }
                  }
                >
                  <View style={styles.itemCode}>
                    <Text style={{ paddingLeft: '10px' }}>
                      {`${item?.sku} | ${item.class} | A | ${moment(
                        item?.date
                      ).format('DD.MM.YYYY')} | ${moment().format(
                        'DD.MM.YYYY'
                      )}`}
                    </Text>
                    <View style={{ left: -15 }}>
                      <Image style={styles.disclimer} src={Disclimer} />
                    </View>
                  </View>
                  <View style={styles?.barcodeBlock}>
                    <View style={styles.barcode}>
                      {_.isEmpty(item.barcode) ? (
                        <></>
                      ) : (
                        <Image src={GetBarCode(item?.barcode)} />
                      )}
                    </View>
                  </View>
                </View>
              </View>
            </View>
          ))}
        </View>
      </Page>
    ))
  );
};
export { A5PortraitV2 };
