import React, { useState } from 'react';
import { ProductDiscount } from '../../../../models/offer/OfferRule.model';
import DiscountSelectProducts from './DiscountSelectProducts';
import { Button } from '@material-ui/core';

const conditionTypeTexts = {
  all: 'All Products',
  productSkus: 'Product Skus',
  productHierarchy: 'Product Hierarchy'
};

const DiscountProducts = (props: {
  discount: ProductDiscount;
  readOnly: boolean;
  updateDiscount: any;
  productFieldOptions: any;
}) => {
  let { discount, readOnly, updateDiscount, productFieldOptions } = props;
  let [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      Apply On
      <Button
        style={{
          border: '1px solid #DEDEDE',
          borderRadius: 4,
          color: '#707070',
          width: '160px'
        }}
        variant="outlined"
        color="primary"
        className="ms-2"
        onClick={() => setIsOpen(true)}
      >
        {conditionTypeTexts[discount.conditionType]}
      </Button>
      <DiscountSelectProducts
        readOnly={readOnly}
        productFieldOptions={productFieldOptions}
        discount={discount}
        updateDiscount={updateDiscount}
        isDialogOpen={isOpen}
        setDialogOpen={setIsOpen}
      />
    </div>
  );
};

export default DiscountProducts;
