import React from 'react';
import moment from 'moment';
import _, { range } from 'lodash';
import { Page, View, Text, Image, StyleSheet, Font } from '@react-pdf/renderer';
import Disclimer from '../../assets/disclimer.png';
import fonts from '../../assets/Fonts';
import { truncatedNumberFloat, truncateString } from '../../../../utils';
import RupeeIcon from '../../assets/peghook-rupee.png';
import { GetBarCode } from '../utils';

Font.register(fonts);

const styles = StyleSheet.create({
  card: {
    fontFamily: 'OpenSans',
    height: '108px',
    width: '289px',
    border: '1 solid #000000',
    margin: '1mm 17mm'
  },
  inlineCard: { display: 'flex', flexDirection: 'row' },
  qrPriceBlock: {
    display: 'flex',
    flexDirection: 'row',
    height: '71px'
  },
  qrcode: {
    width: '54px',
    height: '54px',
    margin: '1mm 3mm'
  },
  priceBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '47px'
  },
  rupeeicon: {
    fontSize: '26.8pt',
    top: -13,
    width: '7px'
  },
  price: {
    fontFamily: 'OpenSans',
    fontSize: '28pt',
    top: 8,
    height: '55px',
    fontWeight: 'bold',
    color: 'black'
  },
  inclTax: {
    fontSize: '10.98pt',
    fontWeight: 'bold',
    display: 'flex',
    top: -14,
    alignItems: 'flex-end'
  },
  mrp: {
    fontSize: '8.43pt',
    top: -10,
    left: 11,
    width: '50%'
    // fontWeight: 'bold'
  },
  mrpText: {
    fontWeight: 'bold',
    color: 'black'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 37,
    padding: '1mm 0mm 1mm 0mm'
  },
  itemDescription: {
    fontFamily: 'OpenSans',
    fontSize: '6pt',
    padding: '0px 4px',
    height: '15px',
    lineHeight: 1.2,
    top: -2
  },
  itemCode: {
    fontSize: '6pt',
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '4px',
    paddingTop: '3mm'
  },
  discBlock: {
    left: 3,
    bottom: -6
  },
  disclimer: {
    width: '3.45mm',
    height: '3.45mm',
    top: -7
  },
  deals: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: '14pt'
  },
  qrcodeBlock: {
    width: '78px',
    height: '71px',
    padding: '2mm 1mm',
    borderRight: '1 solid #000000',
    borderBottom: '1 solid #000000'
  },
  barcode: {
    width: '24.7mm',
    height: '20.2mm',
    paddingRight: '4px',
    top: 0
  },
  rupeeSmall: {
    width: '3mm',
    height: '3.6mm',
    top: 5
  }
});
const LabelView = ({ item }) => (
  <View style={styles?.card}>
    <View style={styles?.qrPriceBlock}>
      <View style={styles?.qrcodeBlock}>
        <Image src={item.image} style={styles.qrcode} />
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '210px',
          height: '71px',
          borderBottom: '1 solid #000000'
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '24px',
            borderBottom: '1 solid #000000'
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '148px',
              height: '24px',
              borderRight: '1 solid #000000'
            }}
          >
            <View style={styles.deals}></View>
          </View>
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '62px',
              height: '24px',
              backgroundColor: '#818386'
            }}
          ></View>
        </View>
        <View style={styles?.priceBlock}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '148px',
              height: '47px',
              borderRight: '1 solid #000000'
            }}
          >
            <View style={styles?.rupeeicon}>
              <Image src={RupeeIcon} />
            </View>
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={styles.price}>
                  {truncatedNumberFloat(item?.product?.mop, 6)}
                </Text>
                <Text
                  style={{ fontWeight: 'bold', fontSize: '6.98pt', top: 14 }}
                >
                  *
                </Text>
              </View>
              <View style={styles?.inclTax}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <Text
                    style={{ fontWeight: 'bold', fontSize: '6.98pt', top: -1 }}
                  >
                    *
                  </Text>
                  <Text style={{ fontSize: '9.6pt' }}>(Incl. Taxes)</Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '62px',
              height: '47px'
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: '#1A1818',
                height: '24px',
                width: '62px'
              }}
            ></View>
          </View>
        </View>
      </View>
    </View>
    <View style={styles.itemContainer}>
      <Text style={styles.itemDescription}>
        {truncateString(item?.product?.masterData?.name, 130)}
      </Text>
      <View
        style={{
          display: 'flex',
          width: '289px',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <View style={styles.itemCode}>
          <Text>
            {item?.sku} | {item.class} | A |{' '}
            {moment(item?.date).format('DD.MM.YYYY')} |{' '}
            {moment().format('DD.MM.YYYY')}
          </Text>
          <View style={styles.discBlock}>
            <Image style={styles.disclimer} src={Disclimer} />
          </View>
        </View>
        <View style={styles.barcode}>
          {_.isEmpty(item.barcode) ? (
            <></>
          ) : (
            <Image src={GetBarCode(item?.barcode)} />
          )}
        </View>
      </View>
    </View>
  </View>
);
const WireBasketH1 = ({ data, count, ...rest }) => {
  const labelsPerRow = 2;
  const rowsPerSku = count.length / labelsPerRow;
  const rowSets = range(rowsPerSku).map(ind =>
    count.slice(labelsPerRow * ind, labelsPerRow * (ind + 1))
  );
  const splattedArray = _.chunk(data, 10);
  return (
    <Page orientation="landscape" style={{ padding: '5mm' }}>
      <View>
        {count &&
          _.map(splattedArray, (chunkedData, key) => {
            const chunkedRowArray = _.chunk(chunkedData, 5);
            const firstHalf = chunkedRowArray[0]?.map((labelData, i) =>
              rowSets?.map(rowCount => (
                <View key={i}>
                  <View style={styles.inlineCard}>
                    {rowCount.map(id => (
                      <LabelView item={labelData} key={id} />
                    ))}
                  </View>
                </View>
              ))
            );
            const secondHalf = chunkedRowArray[1]?.map((labelData, i) =>
              rowSets?.map(rowCount => (
                <View key={i}>
                  <View style={styles.inlineCard}>
                    {rowCount.map(id => (
                      <LabelView item={labelData} key={id} />
                    ))}
                  </View>
                </View>
              ))
            );
            return (
              <View
                style={{ flexDirection: 'row', flexWrap: 'wrap' }}
                key={key}
              >
                <View>{firstHalf}</View>
                <View>{secondHalf}</View>
              </View>
            );
          })}
      </View>
    </Page>
  );
};
export { WireBasketH1 };
