import React, { FC, useEffect, useMemo, useState } from 'react';
import { debounce, get, map } from 'lodash';
import { getSurveys } from '../../services/Survey/survey';
import { FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface MultiSelectFilterProps {
  onChange: (values: SurveyOptions | null) => void;
  defaultValue: SurveyOptions | null;
  disabled: boolean;
  selectedBusinessId: number | undefined;
}
interface SurveyFilters {
  page: number;
  pageSize: number;
  businessUnitId: number;
  name: string;
}

interface SurveyOptions {
  id: string;
  name: string;
}
export const SurveyFormSelect: FC<MultiSelectFilterProps> = props => {
  const [surveyForms, setSurveyForms] = useState<SurveyOptions[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isSurveysLoading, setIsSurveysLoading] = useState(false);
  const getDebounceOffers = useMemo(
    () =>
      debounce((surveyFilter: SurveyFilters) => {
        setIsSurveysLoading(true);
        setSurveyForms([]);
        getSurveys(
          surveyFilter.page,
          surveyFilter.pageSize,
          surveyFilter.businessUnitId,
          surveyFilter.name
        ).then(surveyResponse => {
          const surveys = get(surveyResponse, 'data.surveys', []);
          const surveyNames = map(surveys, s => {
            return {
              id: s.id,
              name: get(s, 'title'),
              token: get(s, 'encodedToken')
            };
          });
          setSurveyForms(surveyNames);
          setIsSurveysLoading(false);
        });
      }, 400),
    []
  );

  useEffect(() => {
    if (!props.selectedBusinessId) {
      return undefined;
    }
    const surveyFilter: SurveyFilters = {
      businessUnitId: props.selectedBusinessId,
      page: 1,
      pageSize: 20,
      name: inputValue
    };
    getDebounceOffers(surveyFilter);
  }, [inputValue, getDebounceOffers, props.selectedBusinessId]);
  return (
    <FormControl key={'survey-form'} disabled={props.disabled}>
      <Autocomplete
        filterOptions={x => x}
        autoComplete
        filterSelectedOptions
        options={surveyForms}
        id="survey-form-name"
        value={props.defaultValue}
        disabled={props.disabled}
        loading={isSurveysLoading}
        style={{ width: '32rem' }}
        ListboxProps={{ style: { height: 150 } }}
        onChange={(event, newValue) => {
          props.onChange(newValue);
        }}
        getOptionLabel={option => option.name}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={params => (
          <TextField
            {...params}
            label="Type to search survey forms"
            fullWidth
            size={'small'}
          />
        )}
      />
    </FormControl>
  );
};
