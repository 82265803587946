import React, { FC, useEffect, useState } from 'react';
import _, { isEmpty } from 'lodash';

import { getUiFilterPayloadById } from '../../../../services/uiFilter.service';
import { LABELPRINTING_WARNING } from '../utils/Constants';
import WarningAccordion from '../Error/WarningAccordion';

interface WarningListProps {
  payloadWarning: any;
}
const INVALID_ID = ['', null, undefined, -1, 0];
export const WarningList: FC<WarningListProps> = ({ payloadWarning }) => {
  const [warnings, setWarnings] = useState([]);
  const [offset, setOffSet] = useState(0);
  const [count, setCount] = useState(25);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  useEffect(() => {
    if (!INVALID_ID.includes(payloadWarning.id))
      getUiFilterPayloadById(
        payloadWarning.id,
        LABELPRINTING_WARNING,
        offset,
        count
      ).then(result => {
        const skuErrors = _.get(result, 'data.payload', []);
        setWarnings(skuErrors);
      });
  }, [count, offset, payloadWarning.id]);
  return (
    <>
      {!isEmpty(warnings) && (
        <WarningAccordion
          errors={warnings}
          offset={offset}
          setCount={setCount}
          setOffSet={setOffSet}
          count={count}
          payloadData={payloadWarning}
          currentPageNumber={currentPageNumber}
          setCurrentPageNumber={setCurrentPageNumber}
        />
      )}
    </>
  );
};
