import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/styles';

const textStyles = makeStyles(theme => ({
  mainCategory: {
    'div & .MuiOutlinedInput-input': {
      padding: '10px'
    },

    '&  fieldset': {
      top: 0,
      borderColor: '#f07663'
    }
  },

  subCategory: {
    'div & .MuiOutlinedInput-input': {
      padding: '10px',
      fontSize: '16px',
      backgroundColor: '#e1e0e94a'
    },

    '& .MuiSelect-iconOutlined': {
      top: '7px'
    },

    '&  fieldset': {
      top: 0
    }
  }
}));

function SelectCategory(props) {
  const styles = textStyles();

  return (
    <div style={{ width: '150px' }}>
      <FormControl variant="outlined" fullWidth>
        <Select
          inputProps={{ 'aria-label': 'Without label' }}
          className={styles[props.type]}
          value={props.selectedValue ? props.selectedValue : ''}
          onChange={e => {
            props.changeCurrentModule(e);
          }}
          disabled={
            props.type === 'mainCategory' || props.access ? true : false
          }
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
        >
          {props.items.map((menuItems, index) => (
            <MenuItem value={menuItems} key={index}>
              {menuItems}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default SelectCategory;
