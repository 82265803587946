import React from 'react';
import { TextField, Chip } from '@material-ui/core';
import { Autocomplete as MaterialAutoComplete } from '@material-ui/lab';
import _ from 'lodash';

const AutoComplete = ({
  items = [],
  value = {},
  updateState,
  placeholder = 'Search',
  disabled
}) => {
  const removeDuplicatesFromSkuList = skuList => {
    skuList = _.uniq(skuList);
    return _.remove(skuList, function(element) {
      return element !== '' && !value.fieldValue.includes(element);
    });
  };
  const restrictSkuByThreshold = (skuList, threshold) => {
    return _.chunk(skuList, threshold - value.fieldValue.length)[0];
  };
  const updateSKU = (skuList, version) => {
    skuList = { fieldValue: skuList, version: version };
    skuList = { sku: skuList };
    updateState(3, skuList);
  };
  const handleUpdateState = lastValue => {
    if (_.isEmpty(lastValue) || value.fieldValue.length >= 3) {
      return;
    }
    lastValue = lastValue.split(',');
    lastValue = removeDuplicatesFromSkuList(lastValue);
    lastValue = restrictSkuByThreshold(lastValue, 3);
    if (_.isEmpty(lastValue)) {
      return;
    }
    lastValue = value.fieldValue.concat(lastValue);
    updateSKU(lastValue, value.version);
  };
  const handleOnchange = (e, newValue) => {
    const lastValue = _.get(newValue, value.fieldValue.length, undefined);
    if (
      _.isEmpty(e.target.value) &&
      newValue.length > value.fieldValue.length
    ) {
      return;
    } else if (newValue.length <= value.fieldValue.length) {
      updateSKU(newValue, value.version);
      return;
    }
    handleUpdateState(lastValue);
  };
  return (
    <>
      <MaterialAutoComplete
        multiple
        id="tags-standard"
        options={items}
        getOptionLabel={option => option.title}
        value={value.fieldValue}
        disabled={disabled}
        freeSolo
        onChange={handleOnchange}
        onBlur={e => handleUpdateState(e.target.value)}
        renderInput={params => (
          <TextField
            {...params}
            className="autoComplete"
            variant="standard"
            placeholder={placeholder}
            style={{ marginLeft: 30 }}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={option}
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
      />
      <span
        className="secondaryText py1 italic flex justify-center"
        style={{ marginTop: 10, marginLeft: -50 }}
      >
        <span>{`Maximum of 3 SKUs could be entered`}</span>
      </span>
    </>
  );
};
export { AutoComplete };
