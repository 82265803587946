import React from 'react';
import * as classes from './JourneyCreate.module.css';
import { useHistory } from 'react-router-dom';

function CreateIcon({ onClick }) {
  return (
    <svg viewBox="0 0 50 50" width="43px" height="43px" onClick={onClick}>
      <circle cx={25} cy={25} r={25} fill="#F07663" />
      <path
        fill="none"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M25 13v25M37.5 25h-25"
      />
    </svg>
  );
}
export default function JourneyCreate() {
  const history = useHistory();
  const onClickHandler = () => {
    history.push('create');
  };
  return (
    <div className={classes.container}>
      <CreateIcon onClick={onClickHandler} />
      <div className={classes.text} onClick={onClickHandler}>
        Create Journey
      </div>
    </div>
  );
}
