import config from '../config/index';
import _ from 'lodash';
import { CartWrapper } from '../models/CartWrapper';

export function saveCart(cart: CartWrapper): Promise<CartWrapper> {
  return fetch(`${config.ruleServerUrl}/users/carts/save`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify(cart)
  })
    .then(r => r.json())
    .then(r =>
      _.extend(cart, {
        cartId: (r as any).cartId
      })
    );
}
