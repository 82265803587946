import { TileData } from './TileData.model';
import { get } from 'lodash';

export enum ImageType {
  PLAIN = 'PLAIN',
  TAGGER = 'TAGGER'
}
interface ImageData {
  URL: string;
}
class PlainImageData implements ImageData {
  constructor(URL: string) {
    this.URL = URL;
  }
  public URL: string;
}
export enum ActionState {
  URL = 'URL',
  SKU = 'SKU'
}
interface MapArea {
  name: string;
  left: string;
  top: string;
  width: string;
  height: string;
  actionState: ActionState;
  actionItem: string;
}
class TaggerImageData implements ImageData {
  public URL: string;
  public mapArea: MapArea[];

  constructor(URL: string, mapArea: MapArea[]) {
    this.URL = URL;
    this.mapArea = mapArea;
  }
}
export class TileDataImage extends TileData {
  public imageType: ImageType;
  public imageData: ImageData;

  constructor(
    imageType: ImageType,
    imageData: TaggerImageData | PlainImageData
  ) {
    super();
    this.imageType = imageType;
    this.imageData = this.setImageData(imageType, imageData);
  }
  private setImageData(
    imageType: ImageType,
    imageData: TaggerImageData | PlainImageData
  ) {
    switch (imageType) {
      case ImageType.PLAIN:
        return new PlainImageData(imageData.URL);
      case ImageType.TAGGER:
        return new TaggerImageData(
          imageData.URL,
          get(imageData, 'mapArea', [])
        );
    }
  }
}
