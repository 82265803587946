import { Switch } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TenantConfigTypes } from '../../constants/tenantConfigTypes';
import ButtonField from '../../reusable/ButtonField';
import configService from '../../services/config.service';
import { toastMessageAction } from '../../store/action';
import { otpConfigToSchema } from '../../validation/redemptionOtpToSchema';
import '../LoyaltyConfig/index.scss';

const OtherOtpConfig = props => {
  const { title, businessUnit } = props;
  const dispatch = useDispatch();
  const [templateError, setTemplateError] = useState(false);

  const onSubmit = async data => {
    setTemplateError(false);
    return configService
      .saveConfigDetails(
        TenantConfigTypes.Otp,
        { businessUnitId: businessUnit?.id },
        data
      )
      .then(() => {
        dispatch(
          toastMessageAction({
            severity: 'success',
            message: `${title} updated successfully !`,
            messageStatus: true
          })
        );
      });
  };

  //to handle submit form
  const formik = useFormik({
    initialValues: {
      emailEnabled: false
    },
    validationSchema: otpConfigToSchema,
    onSubmit
  });
  const {
    values,
    errors,
    handleChange,
    touched,
    resetForm,
    setFieldValue
  } = formik;

  useEffect(() => {
    if (businessUnit?.id > 0)
      configService
        .getConfigDetails(TenantConfigTypes.Otp, {
          businessUnitId: businessUnit?.id
        })
        .then(res => {
          setFieldValue('emailEnabled', res?.emailEnabled ?? false);
        });
  }, [businessUnit, setFieldValue]);

  return (
    <>
      <div className={'adHocPointsContainer'} style={{ marginTop: 20 }}>
        <h4 className="adHocPointsTitle">{`${title}`}</h4>
        <form className="formSubmitation">
          <div className="switchContainer">
            <h4 className="adHocPointsTitle">Enable Email Otp</h4>
            <Switch
              color={'primary'}
              // value={values.emailEnabled}
              error={touched.emailEnabled && errors.emailEnabled}
              checked={values.emailEnabled}
              onChange={handleChange}
              name="emailEnabled"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>
          {templateError && (
            <div style={{ color: 'red' }}>{'Please Enter Valid Details'} </div>
          )}
          <div className={'submitButtonContainer'} style={{ marginTop: 30 }}>
            <ButtonField
              variant="contained"
              label="Clear"
              type="button"
              onClick={() => resetForm()}
              className="clearButton"
            />

            <ButtonField
              variant="contained"
              label="Save"
              className="saveButton"
              onClick={() => onSubmit(values)}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default OtherOtpConfig;
