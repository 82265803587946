import React from 'react';
import { Page, View, StyleSheet } from '@react-pdf/renderer';
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'row'
  },
  card: {
    margin: '3px',
    height: '145.3',
    width: '206.2mm',
    border: '1 solid #000000'
  }
});

const A5 = ({ title = '', data, count, ...rest }) => {
  return (
    <Page style={styles.page}>
      {count &&
        count.map(id => (
          <View key={id} style={{ display: 'flex' }}>
            {data?.map(item => (
              <View style={styles.card} key={item?.id}></View>
            ))}
          </View>
        ))}
      {title}
    </Page>
  );
};
export { A5 };
