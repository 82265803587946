import queryString from 'query-string';
import config from '../../config';
import { getCasaEcomHeaders } from '../../components/Flipbook/Utiles/FlipbookUtiles';

const defaultHeader = { 'Content-type': 'application/json' };

function postFlipbookLandingEvent(eventData: any, queryParamsJSON: any) {
  const queryParams = queryString.stringify(queryParamsJSON, {
    arrayFormat: 'bracket'
  });
  return fetch(`${config.casaEcomUrl}/api/events/landing?${queryParams}`, {
    credentials: 'include',
    method: 'POST',
    headers: { ...getCasaEcomHeaders(), ...defaultHeader },
    body: JSON.stringify(eventData)
  }).then(r => r.json());
}

function postFlipbookProductClickEvent(eventData: any, queryParamsJSON: any) {
  const queryParams = queryString.stringify(queryParamsJSON, {
    arrayFormat: 'bracket'
  });
  return fetch(`${config.casaEcomUrl}/api/events/view_product?${queryParams}`, {
    credentials: 'include',
    method: 'POST',
    headers: { ...getCasaEcomHeaders(), ...defaultHeader },
    body: JSON.stringify(eventData)
  }).then(r => r.json());
}

export default { postFlipbookLandingEvent, postFlipbookProductClickEvent };
