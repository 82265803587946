import React, { FC } from 'react';
import { IconButton, Popover } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import SearchProducts from '../SearchProducts/SearchProducts';
import { anchorOriginObj, getColor, transformOriginObj } from './utiles';

interface SearchPopoverProps {
  allProducts: any;
  flipbookEl: any;
}

export const SearchPopover: FC<SearchPopoverProps> = ({
  allProducts,
  flipbookEl
}) => {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const searchPopoverId = isOpen ? 'simple-popover' : undefined;

  const onClickOpen = (value: any) => {
    setIsOpen(true);
    setAnchorEl(value);
  };

  return (
    <div>
      <div className={'flipbook-bar-icon'}>
        <IconButton
          style={{ color: getColor(isOpen) }}
          className={'outline-none'}
          aria-label="helpOutlineIcon"
          onClick={e => onClickOpen(e.currentTarget)}
        >
          <SearchIcon />
        </IconButton>
      </div>

      <Popover
        id={searchPopoverId}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={e => setIsOpen(false)}
        anchorOrigin={anchorOriginObj}
        transformOrigin={transformOriginObj}
      >
        <div>
          <SearchProducts
            setPopUpOpen={setIsOpen}
            flipbookEl={flipbookEl}
            allProducts={allProducts}
          />
        </div>
      </Popover>
    </div>
  );
};

export default SearchPopover;
