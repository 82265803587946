import { Box } from '@material-ui/core';
import React, { FC } from 'react';

import CampaignFilters from '../../../../../models/campaign/CampaignFilters';
import { getCampaignOverview } from '../../../../../services/Campaign/campaign-details.service';
import useFetch from '../../../../../shared/hooks/useFetch';
import CampaignCard from '../../../shared/components/CampaignCard/CampaignCard';
import Conversion from '../../../shared/components/Icons/Conversion';
import People from '../../../shared/components/Icons/People';
import Revenue from '../../../shared/components/Icons/Revenue';
import Target from '../../../shared/components/Icons/Target';
import formatNumber from '../../../shared/utils/formatNumber';
import styles from './CampaignOverview.module.scss';
import { isEqual } from 'lodash';

interface CampaignOverviewProps {
  filters: CampaignFilters;
}

const getLocaleToFormatNumber = (number: number): string => {
  return number >= 100000 ? 'en-IN' : 'en-US';
};

const CampaignOverview: FC<CampaignOverviewProps> = ({ filters }) => {
  const { data: campaignOverview } = useFetch(
    () => getCampaignOverview(filters),
    [filters],
    isEqual(filters.buId, -1)
  );

  const {
    totalTargeted = 0,
    totalConverted = 0,
    totalRevenue = 0,
    totalConversionRevenue = 0
  } = campaignOverview || {};

  const aggregationCards = [
    {
      label: 'Total Targeted',
      value: formatNumber(
        totalTargeted,
        {
          // @ts-ignore
          notation: 'compact'
        },
        getLocaleToFormatNumber(totalTargeted)
      ),
      icon: <Target />
    },
    {
      label: 'Total People Converted',
      value: formatNumber(
        totalConverted,
        {
          // @ts-ignore
          notation: 'compact'
        },
        getLocaleToFormatNumber(totalConverted)
      ),
      icon: <People />
    },
    {
      label: 'Total Revenue',
      value: formatNumber(
        totalRevenue,
        {
          // @ts-ignore
          notation: 'compact'
        },
        getLocaleToFormatNumber(totalRevenue)
      ),
      icon: <Revenue />
    },
    {
      label: 'Total Conversion Revenue',
      value: formatNumber(
        totalConversionRevenue,
        {
          // @ts-ignore
          notation: 'compact'
        },
        getLocaleToFormatNumber(totalConversionRevenue)
      ),
      icon: <Conversion />
    }
  ];

  return (
    <Box className={styles.campaignOverview}>
      {aggregationCards.map(aggregation => (
        <CampaignCard
          key={aggregation.label}
          {...aggregation}
          valueTypographyVariant="h5"
        />
      ))}
    </Box>
  );
};
export default CampaignOverview;
