import React from 'react';
import { Button, MenuItem, Select } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import _ from 'lodash';
import classes from './PaginationBar.module.css';

export const PaginationBar = props => {
  const {
    handleOnClickNext,
    handleOnClickPrevious,
    handleOnChangeRowsPerPage,
    currentPage,
    totalPages,
    rowsPerPage,
    rowsPerPageOptions
  } = props;

  return (
    <div className={classes.paginationBar}>
      <div className={classes.alignCenter}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleOnClickPrevious}
          disabled={currentPage === 0}
        >
          <KeyboardArrowLeftIcon />
          <span>Prev</span>
        </Button>
        <div style={{ margin: '0 15px' }}>
          {totalPages > 0 ? currentPage + 1 : 0}{' '}
          <span style={{ color: '#666' }}>of {totalPages}</span>
        </div>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleOnClickNext}
          disabled={currentPage >= totalPages - 1}
        >
          <span>Next</span>
          <KeyboardArrowRightIcon />
        </Button>
      </div>
      <div className={classes.alignCenter}>
        <div id="rowsPerPageLabel" className={classes.rowsPerPageLabel}>
          SHOW PER PAGE:{' '}
        </div>
        <Select
          labelId="businessLabel"
          value={rowsPerPage}
          onChange={handleOnChangeRowsPerPage}
          className={classes.rowsPerPageSelect}
        >
          {_.map(rowsPerPageOptions, option => (
            <MenuItem value={option} key={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};
