/* eslint-disable @typescript-eslint/explicit-function-return-type */
import moment from 'moment';
import config from '../../config/index';
import { Business } from '../../models/User.model';

export const storeKPIGenerateReport = (
  monthCount: number,
  endDate: string,
  business: Business,
  storeName: string
) => {
  console.log(storeName);
  return fetch(
    `${config.ruleServerUrl}/users/reports/download/store_kpi_report/${
      business.id
    }?endDate=${moment(endDate).format(
      'YYYY-MM-DD'
    )}&monthCount=${monthCount}&storeName=${storeName}&businessUnitName=${
      business.name
    }`,
    {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
};

export const getStoreKPIReportDownload = ({
  page,
  offset
}: {
  page: number;
  offset: number;
  filters?: any;
}): Promise<any[]> => {
  return fetch(
    `${config.ruleServerUrl}/users/reports/view/downloads/store_kpi_report?offset=${offset}&page=${page}`,
    { credentials: 'include' }
  ).then(res => res.json());
};
