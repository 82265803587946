/* eslint-disable @typescript-eslint/explicit-function-return-type */
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import moment from 'moment';
import React, { FC, useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import useCurrentUser from '../../../hooks/useCurrentUser';
import _, { get, head } from 'lodash';
import { Business } from '../../../models/User.model';
import styles from '../../CasaFields/components/TenantFields.module.scss';
import { getRfmReport } from '../../../services/TenantReports/RfmReport.service';

export interface RFMReportInterface {
  startDate: string;
  endDate: string;
  businesses: Business[];
}

const RFMReportInitialState: RFMReportInterface = {
  startDate: moment()
    .subtract(1, 'day')
    .subtract(3, 'year')
    .format('YYYY-MM-DD'),
  endDate: moment()
    .subtract(1, 'day')
    .format('YYYY-MM-DD'),
  businesses: []
};

interface ReportActionsProps {
  setRefreshView: (id: string) => void;
}

const RFMReportFilter: FC<ReportActionsProps> = ({ setRefreshView }) => {
  const user = useCurrentUser();
  const businesses = user.getBusinesses();

  const [selectedBusiness, setSelectedBusiness] = useState<
    Business[] | undefined
  >([]);

  const [filters, setFilters] = useState<RFMReportInterface>({
    ...RFMReportInitialState,
    businesses: businesses
  });

  function handleChange(value: any, name: string): void {
    setFilters({ ...filters, [name]: value });
  }

  const handleGenerateReport = (): void => {
    const headBusiness = head(businesses);
    const defaultBusinessName = get(headBusiness, 'name', 0);
    getRfmReport(filters, defaultBusinessName).then(res => {
      const jobId = get(res, 'jobId', '');
      setRefreshView(jobId);
    });
  };

  return (
    <>
      <div
        style={{ margin: 20, display: 'flex', justifyContent: 'space-between' }}
      >
        <div style={{ display: 'flex', padding: '2px' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              views={['year']}
              label="Start Year (From Apr 01)"
              value={filters.startDate}
              onChange={e => handleChange(e, 'startDate')}
              variant="inline"
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              views={['year', 'month', 'date']}
              label="End Date"
              value={filters.endDate}
              onChange={e => handleChange(e, 'endDate')}
              variant="inline"
              format="yyyy-MMM-dd"
            />
          </MuiPickersUtilsProvider>
          <FormControl
            className={styles.formControl}
            style={{ width: '200px' }}
          >
            <InputLabel id="businessLabel">Business units</InputLabel>
            <Select
              multiple
              defaultValue=""
              labelId="businessLabel"
              value={selectedBusiness}
              onChange={(event: any) => {
                handleChange(event.target.value, 'businesses');
                setSelectedBusiness(event.target.value);
              }}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                }
              }}
            >
              {_.map(businesses, b => (
                <MenuItem
                  selected={
                    !selectedBusiness?.find(sb => sb.id === b.id) === undefined
                  }
                  value={b.name}
                  key={b.id}
                >
                  {b.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerateReport}
        >
          Generate Report
        </Button>
      </div>
    </>
  );
};

export default RFMReportFilter;
