import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import config from './config';
import keycloak, { getKeycloakHeaders } from './keycloak';
import * as serviceWorker from './serviceWorker';
import store from './store/store.js';

const compareUrls = [config.casaEcomUrl];

const isRestrictedUrl = resource => {
  return _.includes(
    compareUrls.map(r => new URL(r).origin),
    new URL(resource).origin
  );
};

const realFetch = window.fetch;
// https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch#syntax
window.fetch = async (resource, init) => {
  const credentials = _.get(init, 'credentials');

  // todo: Remove isRestrictedUrl check once casa-ecom is integrated with keycloak
  if (isRestrictedUrl(resource)) {
    return realFetch(resource, init);
  }

  if (_.get(init, 'ignoreMonkeyPatch')) {
    return realFetch(resource, init);
  }

  if (_.lowerCase(credentials) === 'include' && keycloak.authenticated) {
    const headers = _.get(init, 'headers');

    try {
      // updateToken if it is expiring within the next 30 seconds
      await keycloak.updateToken(30);
      return realFetch(resource, {
        ...init,
        credentials: 'omit',
        headers: { ...getKeycloakHeaders(), ...headers }
      });
    } catch (e) {
      console.error('Update token failed', new Date().toISOString(), e);
      keycloak.login();
    }
  }
  return realFetch(resource, init);
};

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();
