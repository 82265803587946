import '../LoyaltyConfig/index.scss';

import { FormControlLabel, Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { redemptionInitialState } from '../../constants/adhocPoints';
import useCurrentUser from '../../hooks/useCurrentUser';
import ButtonField from '../../reusable/ButtonField';
import { TextFieldComponent } from '../../reusable/TextFieldComponent';
import {
  getTenantConfig,
  saveTenantConfig
} from '../../services/tenantConfigs.service';
import { redemptionOtpToSchema } from '../../validation/redemptionOtpToSchema';
import AdHocPoints from '../Assets/adHocPoints.png';
import SettingsBreadCrumbs from '../SettingsBreadCrumbs/SettingsBreadCrumbs';

const AntSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    marginLeft: 16
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  checked: {}
}))(Switch);

const Offerio = () => {
  const [otpConfig, setOtpConfig] = useState({
    hasOtpForGeneric: true,
    hasOtpForExclusive: true
  });
  const user = useCurrentUser();
  useEffect(() => {
    const tenantEsKey = user.tenant?.esKey || null;
    if (tenantEsKey) {
      getTenantConfig(tenantEsKey, 'offerOtp').then(loyaltyConfig => {
        setOtpConfig(loyaltyConfig.configDetails);
      });
    }
  }, [user]);

  const handleOnFieldChange = field => event => {
    setOtpConfig(optConfigs => ({
      ...otpConfig,
      [field]: event.target.checked
    }));
  };

  const handleUpdateConfig = data => {
    const tenantEsKey = user.tenant?.esKey || null;
    if (tenantEsKey) {
      saveTenantConfig(otpConfig, 'offerOtp', tenantEsKey)
        .then(() => {
          toast.success('Configured Sucessfully');
        })
        .catch(() => toast.error('Something Went Wrong'));
    }
  };

  //to handle submit form
  const formik = useFormik({
    initialValues: redemptionInitialState,
    validationSchema: redemptionOtpToSchema,
    onSubmit: handleUpdateConfig
  });

  const { values, errors, handleChange, touched, resetForm } = formik;
  return (
    <>
      <SettingsBreadCrumbs
        currentModuleName="Offer Redemption OTP"
        fromModuleName="Module Settings"
        isModule={true}
      />
      <div className={'adHocPointsContainer'}>
        <img src={AdHocPoints} alt="AdHocPoints" className="otpImage" />
        <h4 className="adHocPointsTitle">Offer Redemption OTP</h4>
        <form onSubmit={formik.handleSubmit} className="formSubmitation">
          <TextFieldComponent
            label={'Template ID'}
            variant="outlined"
            onChange={handleChange}
            name={'templateId'}
            value={values.templateId}
            error={touched.templateId && errors.templateId}
            className="textFieldStyle"
          />
          <TextFieldComponent
            label={'SMS Content '}
            variant="outlined"
            onChange={handleChange}
            name={'smsContent'}
            value={values.smsContent}
            error={touched.smsContent && errors.smsContent}
            className="textFieldStyle"
          />
          <div className={'mt-2 mb-4 px-2'}>
            <FormControlLabel
              control={
                <AntSwitch
                  checked={otpConfig?.hasOtpForGeneric || false}
                  onChange={handleOnFieldChange('hasOtpForGeneric')}
                  name="hasOtpForGeneric"
                />
              }
              label="Would an OTP Validation for generic offers required?  "
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <AntSwitch
                  checked={otpConfig?.hasOtpForExclusive || false}
                  onChange={handleOnFieldChange('hasOtpForExclusive')}
                  name="hasOtpForExclusive"
                />
              }
              label="Would an OTP Validation for specific offers required?  "
              labelPlacement="start"
            />
          </div>
          <div className={'submitButtonContainer'}>
            <ButtonField
              variant="contained"
              label="Clear"
              type="button"
              onClick={() => resetForm()}
              className="clearButton"
            />

            <ButtonField
              variant="contained"
              label="Save"
              type="sumbit"
              className="saveButton"
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default Offerio;
