import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

function StepIcon({ active }) {
  return active ? <span className="completed-dot" /> : <span className="dot" />;
}

StepIcon.propTypes = {
  active: PropTypes.bool.isRequired
};

export default StepIcon;
