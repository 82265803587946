import { Container, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import React, { FC, useEffect, useMemo, useState } from 'react';

import {
  getCampaignAvailableStatus,
  getCampaigns
} from '../../../../services/Campaign/campaign-details.service';
import usePagination from '../../../../shared/hooks/usePagination';
import CampaignFilters from './CampaignFilters/CampaignFilters';
import CampaignOverview from './CampaignOverview/CampaignOverview';
import CampaignTable from './CampaignTable/CampaignTable';
import { CampaignFilterContext } from './Context/CampaignFilterContext';
import { get, isEqual, isNumber, map } from 'lodash';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import qs from 'query-string';

const initialFilterState = {
  search: '',
  startDate: moment()
    .subtract(1, 'day')
    .toDate(),
  endDate: moment()
    .add(1, 'day')
    .toDate(),
  campaignType: 'all',
  campaignStatus: 'all',
  sort: '',
  buId: -1
};

const CampaignDetails: FC = () => {
  const [partialFilters, setPartialFilters] = useState(initialFilterState);

  const {
    data: campaignsResponse,
    isLoading,
    error,
    page,
    offset,
    setPage,
    setOffset
  } = usePagination(
    ({ page, offset }) => getCampaigns({ ...partialFilters, page, offset }),
    [partialFilters],
    undefined,
    isEqual(partialFilters.buId, -1)
  );
  const url = new URL(window.location.href);
  const querySearchParams = qs.parse(url.searchParams.toString());
  const user = useCurrentUser();
  const [isCampaignStatusLoaded, setIsCampaignStatusLoaded] = useState(false);
  const [campaignAvailableStatus, setCampaignAvailableStatus] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (isEqual(partialFilters.buId, -1) && user.isLoggedIn) {
      let id = user.getDefaultBusinessId();
      const buIds = map(user.getBusinesses(), bu => bu.id);
      const buId = parseInt(get(querySearchParams, 'bu', -1) as any);
      if (isNumber(buId) && buIds.includes(buId)) {
        id = buId;
      }
      setPartialFilters({ ...partialFilters, buId: id });
    }
  }, [partialFilters, querySearchParams, user]);

  useEffect(() => {
    if (!isCampaignStatusLoaded) {
      getCampaignAvailableStatus().then(res => {
        setCampaignAvailableStatus(res);
        setIsCampaignStatusLoaded(true);
      });
    }
  }, [isCampaignStatusLoaded]);
  const filters = useMemo(() => ({ ...partialFilters, page, offset }), [
    partialFilters,
    page,
    offset
  ]);

  if (error)
    return (
      <Alert severity="error" style={{ margin: '2rem' }}>
        {error}
      </Alert>
    );

  const { campaigns, totalRecords = 0 } = campaignsResponse || {};

  const handleFilterChange = <T,>(filter: { [key: string]: T }): void => {
    setPartialFilters(filters => {
      setPage(0);
      return { ...filters, ...filter };
    });
  };

  return (
    <Container maxWidth="xl" style={{ marginBlock: '3rem' }}>
      <CampaignFilterContext.Provider
        value={{
          startDate: partialFilters.startDate.toString(),
          endDate: partialFilters.endDate.toString(),
          buId: partialFilters.buId
        }}
      >
        <CampaignOverview filters={filters} />
        <CampaignFilters
          filters={filters}
          campaignAvailableStatus={campaignAvailableStatus}
          onFilterChange={handleFilterChange}
        />

        {!campaigns?.length && !isLoading && (
          <Typography align="center" variant="h5" style={{ marginTop: '6rem' }}>
            No Campaigns Found.
          </Typography>
        )}

        {(campaigns?.length || isLoading) && (
          <CampaignTable
            campaigns={campaigns || []}
            isLoading={isLoading}
            count={totalRecords}
            page={page}
            offset={offset}
            setPage={setPage}
            setOffset={setOffset}
          />
        )}
      </CampaignFilterContext.Provider>
    </Container>
  );
};

export default CampaignDetails;
