import React, { useEffect, useState } from 'react';
import 'react-table-v6/react-table.css';
import '../../styles/Table.scss';
import { withRouter } from 'react-router-dom';
import { getCurrentUser } from '../../services/user.service';

const SubscriptionView = props => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const result = await getCurrentUser();
      setUrl(
        result.tenant.casa_product_plans[0]?.casa_tenant_plans.subscriptionUrl
      );
    };
    fetchData().then(r => r);
  }, []);

  return (
    <iframe
      title={'razorpay subscription'}
      src={url}
      style={{
        border: 'none',
        height: 'calc(100vh - 92px)',
        width: '100%'
      }}
    />
  );
};

export default withRouter(SubscriptionView);
