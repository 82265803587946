import React, { FC } from 'react';
import _ from 'lodash';
import { getTotalCartAmount } from '../Utiles/FlipbookUtiles';

interface OrderSummaryProps {
  cartItems: any;
  allProducts: any;
}

export const OrderSummary: FC<OrderSummaryProps> = ({
  cartItems,
  allProducts
}) => {
  const getProductBySku = (sku: string) => {
    return _.find(allProducts, p => p.extendedData.sku === sku);
  };
  const getPriceInfo = () => {
    return (
      <div className={'summary-price-info'}>
        <div className={'cart-summary-product'}>
          <div className={'product-total-summary'}>
            <div></div>
            <div className={'product-total-header'}>{'Product total'}</div>
            <div className={'product-total-value'}>{`₹ ${getTotalCartAmount(
              cartItems,
              allProducts
            )}`}</div>
          </div>
          <div className={'product-total-summary'}>
            <div></div>
            <div className={'product-total-header'}>{'Discount'}</div>
            <div className={'product-total-value'}>{'₹ 0'}</div>
          </div>
        </div>
        <hr
          style={{
            color: 'white',
            backgroundColor: 'white',
            height: 5,
            borderTop: '2px solid rgba(0,0,0,0.1)'
          }}
        />
        <div className={'product-total-summary'}>
          <div></div>
          <div className={'product-total-header'}>{'Order total'}</div>
          <div className={'product-total-value'}>{`₹ ${getTotalCartAmount(
            cartItems,
            allProducts
          )}`}</div>
        </div>
        <hr
          style={{
            color: 'white',
            backgroundColor: 'white',
            height: 5,
            borderTop: '2px solid rgba(0,0,0,0.1)'
          }}
        />
      </div>
    );
  };
  const listProducts = () => {
    return (
      <div>
        <div className={'cart-listing-summary'}>
          {_.map(cartItems, (item, i) => {
            const product = getProductBySku(item.sku);
            const quantity = _.get(item, 'quantity', '');
            return (
              <div key={i}>
                <div key={i} className={'cart-listing-container-summary'}>
                  <div className="cart-item-img">
                    <img
                      className={'cart-listing-img-summary'}
                      src={_.first(_.get(product, 'extendedData.images'))}
                      alt={'product img'}
                    />
                  </div>
                  <div className={'center-align-content-name'}>
                    <p key={i} className={'m-0 cart-listing-name-dotted'}>
                      {_.get(product, 'extendedData.name', '')}
                    </p>
                    <div
                      style={{ textAlign: 'left', color: '#666666' }}
                    >{`Qty ${quantity}`}</div>
                  </div>
                  <div className={'center-align-content'}>
                    <p className={'m-0 mx-2 cart-listing-item-price'}>
                      {`₹${item.quantity * _.get(product, 'mrp')}`}
                    </p>
                  </div>
                </div>
                <hr
                  style={{
                    color: 'white',
                    backgroundColor: 'white',
                    height: 5
                  }}
                />
              </div>
            );
          })}
        </div>
        {getPriceInfo()}
      </div>
    );
  };
  return (
    <div className={'product-listing-container'}>
      <div className={'shipment-user-information-header'}>
        {'Order Summary'}
      </div>
      {listProducts()}
    </div>
  );
};
