/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { customerGroupContents } from '../../../constants/CustomerGroup/createCustomerGroup';
import { TextField } from '@material-ui/core';
import CustomIOSSwitch from '../CustomIOSSwitch';
import customerGroupClass from '../index.module.scss';
import ButtonField from '../../../reusable/ButtonField';
import { useParams } from 'react-router';
import configService from '../../../services/customerGroup.service';
import { toastMessageAction } from '../../../store/action';
import { useDispatch } from 'react-redux';

const Form = props => {
  const { business, status, setStatus } = props;
  const { customerGroupId } = useParams();
  const {
    customerTitle,
    customerBulkTitle,
    tagConfigurationTitle,
    typoGraphyVariant
  } = customerGroupContents;
  const hiddenFileInput = React.useRef(null);
  const [mobileNumber, setMobileNumber] = useState('');
  const [customerUploadFile, setCustomerUploadFile] = useState('');
  const [blockLoyalty, setBlockLoyalty] = useState(false);
  const [blockSms, setBlockSms] = useState(false);
  const dispatch = useDispatch();
  const handleImportUsers = () => {
    hiddenFileInput.current.click();
  };
  const handleInputChange = event => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded?.type === 'text/csv') {
      setCustomerUploadFile(fileUploaded);
    }
  };

  const handleBlockLoyalty = evt => {
    setBlockLoyalty(evt.target.checked);
    const updateData = { blockLoyalty: evt.target.checked, blockSms };
    configService
      .updateCustomerGroupById(updateData, business?.name, customerGroupId)
      .then(() => {
        setStatus(!status);
        dispatch(
          toastMessageAction({
            severity: 'success',
            message: `Block Loyalty updated successfully`,
            messageStatus: true
          })
        );
      });
  };

  const handleBlockSms = evt => {
    const value = evt.target.checked;
    setBlockSms(value);
    const updateData = { blockLoyalty, blockSms: value };
    configService
      .updateCustomerGroupById(updateData, business?.name, customerGroupId)
      .then(() => {
        setStatus(!status);
        dispatch(
          toastMessageAction({
            severity: 'success',
            message: `Block Sms updated successfully`,
            messageStatus: true
          })
        );
      });
  };

  const handleCustomerButton = () => {
    const data = {
      customerGroupId: customerGroupId,
      mobileNumber: mobileNumber
    };
    configService.createAssignedGroupConfig(data, business.name).then(res => {
      if (res?.status === 'success') {
        setStatus(!status);
        setMobileNumber('');
        dispatch(
          toastMessageAction({
            severity: 'success',
            message: `Add customer mobile number successfully`,
            messageStatus: true
          })
        );
      } else {
        dispatch(
          toastMessageAction({
            severity: 'error',
            message: `${res.errors[0].message}`,
            messageStatus: true
          })
        );
      }
    });
  };

  const handleUploadButton = () => {
    const formData = {
      customerGroupId: customerGroupId,
      file: customerUploadFile
    };
    configService
      .bulkCreateCustomersConfig(formData, business.name)
      .then(res => {
        if (res.status === 'failure') {
          dispatch(
            toastMessageAction({
              severity: 'error',
              message: `Please check customer bulk upload file`,
              messageStatus: true
            })
          );
        } else {
          dispatch(
            toastMessageAction({
              severity: 'success',
              message: `Customer mobile number bulk upload successfully`,
              messageStatus: true
            })
          );
        }
        setStatus(!status);
      });
  };

  useEffect(() => {
    if (business.name !== '0') {
      configService
        .getCustomerGroupById(business.name, customerGroupId)
        .then(({ data }) => {
          setBlockLoyalty(data?.blockLoyalty || false);
          setBlockSms(data?.blockSms || false);
        });
    }
  }, [business.name, customerGroupId]);

  return (
    <div style={{ width: '35%' }}>
      <div
        className={customerGroupClass.editFormContainer}
        style={{ background: '#fff' }}
      >
        <Typography
          variant={typoGraphyVariant}
          className={customerGroupClass.customerTitle}
        >
          {customerTitle}
        </Typography>
        <div className={customerGroupClass.customerNumber}>
          <TextField
            placeholder="Enter Customer Number"
            variant="standard"
            className={customerGroupClass.groupName}
            type="number"
            onInput={e => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 10);
              e.target.value.length > 0
                ? setMobileNumber(e.target.value)
                : setMobileNumber('');
            }}
            name="mobileNumber"
            value={mobileNumber}
          />
          <ButtonField
            variant="contained"
            label={'Add Customer'}
            type="button"
            disabled={mobileNumber.length !== 10}
            style={
              mobileNumber.length === 10
                ? { background: '#F07663', color: 'white' }
                : { background: '#ddd', color: 'black' }
            }
            onClick={handleCustomerButton}
            className={customerGroupClass.addcustomerButton}
          />
        </div>
        <div className={customerGroupClass.betweenStyle}>
          <span></span>
          <Typography
            variant={typoGraphyVariant}
            className={customerGroupClass.customerTitle}
          >
            {'OR'}
          </Typography>
          <span></span>
        </div>
        <div style={{ margin: '15px auto' }}>
          <Typography
            variant={typoGraphyVariant}
            className={customerGroupClass.customerTitle}
          >
            {customerBulkTitle}
          </Typography>
          <button
            className={customerGroupClass.uploadStoreButton}
            style={{ display: 'none' }}
            onClick={handleImportUsers}
          ></button>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleInputChange}
            className={customerGroupClass.fileUploadInput}
          />
        </div>
        <div className={customerGroupClass.submitButton}>
          <ButtonField
            variant="contained"
            label={'Upload'}
            onClick={handleUploadButton}
            type="submit"
            style={{ background: '#F07663', color: 'white' }}
          />
        </div>
      </div>
      <div
        className={customerGroupClass.editFormContainer}
        style={{ background: '#fff', marginTop: 25 }}
      >
        <Typography
          variant={typoGraphyVariant}
          className={customerGroupClass.customerTitle}
        >
          {tagConfigurationTitle}
        </Typography>
        <CustomIOSSwitch
          label="Block Loyalty"
          name="blockLoyalty"
          handleChange={handleBlockLoyalty}
          checkedValue={blockLoyalty}
          disabled
        />
        <CustomIOSSwitch
          label="Block SMS"
          name="blockSms"
          handleChange={handleBlockSms}
          checkedValue={blockSms}
          disabled
        />
      </div>
    </div>
  );
};

export default Form;
