import { FlipbookPage } from './common';
import React, { FC, useContext } from 'react';
import FlipbookPageGradient from '../Utiles/FlipbookPageGradient';
import FlipbookPageDimensions from '../Utiles/FlipbookPageDimensions';
import AddCircle from '@material-ui/icons/AddCircle';
import { IconButton } from '@material-ui/core';
import { LayoutSelectionModal } from './LayoutSelectionModal';
import flipbookV2LayoutService from '../../../services/Flipbook/flipbookV2Layout.service';
import {
  FlipbookLayout,
  LayoutType
} from '../../../models/FlipbookV2/FlipbookV2Layout.model';
import { FlipbookPagesContext } from '../Hooks/FlipbookPagesContext';

interface AddLayoutProps {
  pageNumber: number;
  flipbookId: number;
}

export const LAYOUT_SINGLE = 'layoutSingle';
export const LAYOUT_DOUBLE = 'layoutDouble';

export const AddLayout: FC<AddLayoutProps> = ({ pageNumber, flipbookId }) => {
  const [openModel, setModalOpen] = React.useState(false);
  const flipbookPagesContext = useContext(FlipbookPagesContext);
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const createFlipbookLayout = (layoutType: LayoutType) => {
    const flipbookLayout: FlipbookLayout = new FlipbookLayout(
      layoutType,
      flipbookId,
      1,
      true
    );
    flipbookV2LayoutService.createFlipbookLayout(flipbookLayout).then(res => {
      // flipbookPagesContext.setIsPageUpdatedContext(true);
      flipbookPagesContext.updateFlipbookPages();
    });
  };
  return (
    <FlipbookPage
      key={'Add-Layout'}
      width={FlipbookPageDimensions.getFlipbookWidth()}
      height={FlipbookPageDimensions.getFlipbookHeight()}
    >
      {
        <div>
          <div className={'text-center my-4'}>
            <label>
              <IconButton
                color="primary"
                aria-label="Add Layout"
                component="span"
                style={{ padding: 5 }}
                onClick={handleModalOpen}
              >
                <AddCircle />
              </IconButton>
              <p className={'flipbook-text-header'}>Add Layout</p>
            </label>
            <LayoutSelectionModal
              isEditLayoutMode={false}
              isModalOpen={openModel}
              setModalClose={e => setModalOpen(e)}
              onClickLayoutAddition={(e: LayoutType) => createFlipbookLayout(e)}
            />
          </div>
          <div
            className={'page-effect'}
            style={{
              backgroundImage: FlipbookPageGradient.getGradientForPage(
                pageNumber
              )
            }}
          ></div>
        </div>
      }
    </FlipbookPage>
  );
};

export default AddLayout;
