export const createUserInitialState = {
  store: '',
  name: '',
  storeNameAlias: '',
  mobile: '',
  shortCode: '',
  latitude: '',
  longitude: '',
  manager: '',
  digitalStoreName: '',
  storeManagerEmail: '',
  startDate: new Date(),
  addressLineOne: '',
  addressLineTwo: '',
  city: '',
  state: '',
  pincode: '',
  gstin: '',
  landline: ''
};

export const editUserInitialState = {
  storeNameAlias: '',
  store: '',
  name: '',
  shortCode: '',
  latitude: '',
  longitude: '',
  mobile: '',
  addressLineOne: '',
  addressLineTwo: '',
  city: '',
  state: '',
  pincode: '',
  location: '',
  manager: '',
  digitalStoreName: '',
  storeManagerEmail: '',
  startDate: new Date(),
  gstin: ''
};
