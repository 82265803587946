module.exports = {
  dashboardUrl: 'https://dashboard-classic.casaretail.in',
  ruleServerUrl: 'https://api.casa.ajira.tech',
  casaWebAppUrl: 'https://web.casa.ajira.tech',
  apiServerUrl: 'https://api.customerstudio.ajira.tech',
  casaEcomUrl: 'https://ecom.casa.ajira.tech',
  casaBeatUrl: 'https://beat-api.casa.ajira.tech',
  pwaUrl: 'https://shoppersdelight.casa.ajira.tech',
  dashboard: 'https://dashboard.casaretail.in',
  ruleUiUrl: 'https://rules.casa.ajira.tech',
  commerceConnect: 'https://commerce-connect-up.casa.ajira.tech',
  turnedOffFeatures: [],
  keycloakConfig: {
    url: `https://auth.casa.ajira.tech/auth`,
    realm: `casa`,
    clientId: `casa_dashboard`,
    bearerOnly: true
  }
};
