/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-key */
import './index.scss';

import {
  Button,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import InfoIcon from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as RefreshIcon } from '../../../src/components/Assets/images/icons/refresh.svg';
import { ReactComponent as AddIcon } from '../../components/Assets/images/icons/add_icon_round.svg';
import useCurrentUser from '../../hooks/useCurrentUser';
import { Business } from '../../models/User.model';
import offerService from '../../services/offer.service';
import TableBodyLoader from '../../shared/components/TableBodyLoader/TableBodyLoader';
import { ParentLink } from '../../utils/ParentLink';
import { ReactComponent as FalseIcon } from '../Assets/Offers/Close.svg';
import { ReactComponent as TrueIcon } from '../Assets/Offers/True.svg';
import { primaryColor } from '../OfferDefinition/theme';

const CustomBool = props => {
  return <div>{props.value ? <TrueIcon /> : <FalseIcon />}</div>;
};

const OfferTable = ({ data = [], loading, rowsPerPage }) => {
  const transformDateRow = row => {
    const date = row.value;
    if (!date) return '-';
    return moment(date)
      .format('DD-MM-YYYY')
      .toString();
  };

  const columns = React.useMemo(
    () => [
      { accessor: 'mobile', Header: 'Mobile' },
      { accessor: 'code', Header: 'Offer Code' },
      { accessor: 'name', Header: 'Name' },
      {
        accessor: 'description',
        Header: 'Description',
        style: {
          maxWidth: '150px'
        }
      },
      {
        accessor: 'startDate',
        Header: 'Start Date',
        Cell: row => transformDateRow(row)
      },
      {
        accessor: 'endDate',
        Header: 'End Date',
        Cell: row => transformDateRow(row)
      },
      {
        accessor: 'expiresOn',
        Header: 'Expiry Date',
        Cell: ({ row }) => {
          const date = row.original;
          return !date.expiresOn
            ? moment(date.endDate)
                .format('DD-MM-YYYY')
                .toString()
            : moment(date.expiresOn)
                .format('DD-MM-YYYY')
                .toString();
        }
      },
      {
        accessor: 'isRunning',
        Header: 'Running',
        Cell: row => <CustomBool value={row.value} />
      },
      {
        accessor: 'activated',
        Header: 'Activated',
        Cell: row => <CustomBool value={row.value} />
      },
      {
        accessor: 'redeemed',
        Header: 'Redeemed',
        Cell: row => <CustomBool value={row.value} />
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  });
  const tableHeadTootlip = {
    startDate:
      'Date at which Offer redemption is possible for the Customer in a specific cohort',
    endDate:
      'Date at which Offer redemption ends for the Customer in a specific cohort',
    expiresOn: 'Date at which Offer Validity expires for all cohorts'
  };
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      // id={styles.transactionReport}
    >
      <ReactTooltip place="up" effect="float" className="offerTooltip" />

      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                return (
                  <TableCell
                    {...column.getHeaderProps()}
                    style={{ fontWeight: 'bold', fontSize: 17 }}
                  >
                    <span>{column.render('Header')}</span>
                    {tableHeadTootlip[`${column.id}`] && (
                      <InfoIcon
                        data-tip={tableHeadTootlip[`${column.id}`]}
                        style={{ marginLeft: 8, cursor: 'pointer' }}
                      />
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>
        {loading ? (
          <TableBodyLoader colSpan={10} rowsPerPage={rowsPerPage + 1} />
        ) : (
          <TableBody {...getTableBodyProps()}>
            {data.length > 0 ? (
              rows.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          style={{ maxWidth: '250px' }}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <div style={{ textAlign: 'center', padding: '20px' }}>
                {'OOPS No Offers Found!'}
              </div>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

const OfferSearch = props => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [business, setBusiness] = useState(new Business());
  const [mobile, setMobile] = useState('');
  const [offerCode, setOfferCode] = useState('');
  const defaultFromDate = moment()
    .subtract(2, 'w')
    .format('YYYY-MM-DD')
    .toString();
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const defaultToDate = moment().format('YYYY-MMM-DD');
  const [toDate, setToDate] = useState(defaultToDate);

  const [pageNum, setPageNum] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [status, setStatus] = useState('');
  const [offerCount, setOfferCount] = useState();
  const [maxPageCount, setMaxPageCount] = useState(0);
  const user = useCurrentUser();
  const [firstLoad, setFirstLoad] = useState(true);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsBuId = _.parseInt(urlParams.get('bu'));
    const paramsBusiness = _(user.getBusinesses()).find(
      b => b.id === paramsBuId
    );
    if (paramsBusiness) setBusiness(paramsBusiness);
  }, [user]);

  const getOfferSearchCountDebounce = React.useCallback(
    _.debounce(
      ({
        business,
        mobile,
        offerCode,
        fromDate,
        toDate,
        status,
        rowsPerPage
      }) =>
        offerService
          .getOfferSearchCount(
            business?.id,
            mobile,
            offerCode,
            fromDate,
            toDate,
            status
          )
          .then(({ count }) => {
            setOfferCount(count);
            const maxpage =
              count % rowsPerPage === 0
                ? count / rowsPerPage
                : Math.floor(parseInt(count / rowsPerPage)) + 1;
            setMaxPageCount(maxpage);
          }),
      1000
    ),
    []
  );

  const getOfferSearchListDebounce = React.useCallback(
    _.debounce(
      ({
        mobile,
        offerCode,
        business,
        rowsPerPage,
        pageNum,
        fromDate,
        toDate,
        status
      }) =>
        offerService
          .getOfferSearchList(
            business.id,
            mobile,
            offerCode,
            rowsPerPage * (pageNum - 1),
            rowsPerPage,
            fromDate,
            toDate,
            status
          )
          .then(offerList => {
            setOffers(offerList);
            setLoading(false);
            setFirstLoad(false);
            setReset(false);
          }),
      1000
    ),
    []
  );

  const getOfferList = async ({
    mobile,
    offerCode,
    business,
    rowsPerPage,
    pageNum,
    fromDate,
    toDate,
    status
  }) => {
    if (business?.id !== 0) {
      getOfferSearchCountDebounce({
        business,
        mobile,
        offerCode,
        fromDate,
        toDate,
        status,
        rowsPerPage
      });

      return getOfferSearchListDebounce({
        mobile,
        offerCode,
        business,
        rowsPerPage,
        pageNum,
        fromDate,
        toDate,
        status
      });
    }
    return [];
  };

  const offerGetter = async data => {
    setLoading(true);
    await getOfferList(data);
  };

  //handle reset button
  const handleReset = async () => {
    setReset(true);
    setMobile('');
    setOfferCode('');
    setFromDate('');
    setToDate('');
    setStatus('');
  };

  useEffect(() => {
    if (firstLoad) {
      offerGetter({
        mobile,
        offerCode,
        business,
        rowsPerPage,
        pageNum,
        fromDate,
        toDate,
        status
      });
    }
  }, [
    mobile,
    offerCode,
    business,
    rowsPerPage,
    pageNum,
    fromDate,
    toDate,
    status,
    firstLoad
  ]);

  useEffect(() => {
    offerGetter({
      mobile,
      offerCode,
      business,
      rowsPerPage,
      pageNum,
      fromDate,
      toDate,
      status
    });
  }, [rowsPerPage, pageNum, reset]);

  const options = [
    { value: '', label: 'All' },
    { value: 'ready', label: 'Available' },
    { value: 'activated', label: 'Activated' },
    { value: 'redeemed', label: 'Redeemed' }
  ];

  const pageChangeHandler = value => {
    if (pageNum + value > 0) {
      setPageNum(pageNum + value);
    }
  };

  const onDateChangeHandler = (setter, value) => {
    setter(
      moment(value)
        .format('YYYY-MMM-DD')
        .toString()
    );
  };

  // source: https://gist.github.com/bierik/0baa0de30cc4ee6d3fbf8485c4d12bb8
  function downloadCSV(blob) {
    const data = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style = 'display: none';
    a.href = data;
    a.download = 'offers.csv';
    document.body.appendChild(a);
    a.click();

    setTimeout(function() {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  const exportCSVHandler = async () => {
    const file = await offerService.exportOfferSearchListAsCSV(
      business.id,
      mobile,
      offerCode,
      fromDate,
      toDate,
      status
    );
    downloadCSV(file);
  };

  return (
    <div>
      <div className="offerContainer">
        <h3 className="offerRoot">Customer Offer Search</h3>
      </div>
      <div className="tableRoot">
        <div className="createOffer">
          <ParentLink className={'createOfferLink'} to={'/offers/create'}>
            <AddIcon />
            <span>Create Offer</span>
          </ParentLink>
        </div>
        <div className="tableContainer">
          <div className="createOffer">
            <div
              style={{
                fontSize: 17,
                fontWeight: 'bold'
              }}
            >
              TOTAL : {offerCount}
            </div>
          </div>
          <div className="filterType">
            <span style={{ fontSize: 17, width: 123 }}>Filter by:</span>
            <div className="filterTypeRoot">
              <label>Mobile</label>
              <div className="searchValue">
                <input
                  type="text"
                  value={mobile}
                  onChange={e => setMobile(e.target.value)}
                  placeholder="Search by mobile..."
                  className="inputValue"
                />
                <SearchIcon />
              </div>
            </div>
            <div className="filterTypeRoot">
              <label>Offer Code</label>
              <div className="searchValue">
                <input
                  type="text"
                  value={offerCode}
                  onChange={e => setOfferCode(e.target.value)}
                  placeholder="Search by offer code..."
                  className="offerInputValue"
                />
                <SearchIcon />
              </div>
            </div>
            <div className="startDateRoot">
              <label>Start Date</label>
              <input
                type="date"
                value={moment(fromDate).format('YYYY-MM-DD')}
                name="fromDate"
                onChange={evt =>
                  onDateChangeHandler(setFromDate, evt.target.value)
                }
                placeholder="Date Filter - From"
                className="dateFormatStyle"
              />
            </div>
            <div className="startDateRoot">
              <label>End Date</label>
              <input
                type="date"
                value={moment(toDate).format('YYYY-MM-DD')}
                onChange={evt =>
                  onDateChangeHandler(setToDate, evt.target.value)
                }
                placeholder="Date Filter - To"
                className="dateFormatStyle"
              />
            </div>
            <div className="filterTypeRoot">
              <label>Status</label>
              <select
                value={status.value}
                onChange={evt => setStatus(evt.target.value)}
                className="dateFormatStyle"
              >
                {options.map(option => (
                  <option value={option.value} key={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0px 40px'
            }}
          >
            <div className="resetContainer">
              <div className="resetButton">
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  onClick={() =>
                    offerGetter({
                      mobile,
                      offerCode,
                      business,
                      rowsPerPage,
                      pageNum,
                      fromDate,
                      toDate,
                      status
                    })
                  }
                  className="resetType"
                >
                  Apply Filters
                </Button>
              </div>
              <div className="resetButton">
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  onClick={handleReset}
                  className="resetType"
                >
                  <RefreshIcon style={{ marginRight: 8 }} /> Reset
                </Button>
              </div>
            </div>
            <button className="exportCsv" onClick={exportCSVHandler}>
              <ArrowDownwardIcon style={{ marginRight: 8 }} />
              <span>Export CSV</span>
            </button>
          </div>
          <OfferTable
            data={offers}
            loading={loading}
            rowsPerPage={rowsPerPage}
          />
          <div className="paginationContainer">
            <div className="paginationList">
              <button
                onClick={() => pageChangeHandler(-1)}
                disabled={pageNum === 1}
                className="previousPageStyle"
              >
                <ArrowBackIosIcon
                  style={
                    pageNum === 1
                      ? { color: '#a8a8a8', fontSize: 17 }
                      : { color: primaryColor, fontSize: 17 }
                  }
                />
                <span
                  className="previousButtonText"
                  style={
                    pageNum === 1
                      ? { color: '#a8a8a8' }
                      : { color: primaryColor }
                  }
                >
                  PREV
                </span>
              </button>
              <span className="pageNumber">{`${pageNum} of ${
                maxPageCount === 0 ? 1 : maxPageCount
              }`}</span>

              <button
                onClick={() => pageChangeHandler(1)}
                disabled={pageNum === maxPageCount}
                className="nextPageStyle"
              >
                <span
                  className="nextButtonText"
                  style={
                    rowsPerPage > _.size(offers)
                      ? { color: '#a8a8a8' }
                      : { color: primaryColor }
                  }
                >
                  Next
                </span>
                <ArrowForwardIosIcon
                  style={
                    rowsPerPage > _.size(offers)
                      ? { color: '#a8a8a8', fontSize: 17 }
                      : { color: primaryColor, fontSize: 17 }
                  }
                />
              </button>
            </div>
            <div>
              <span className="showPerPage">Show per page</span>
              <Select
                defaultValue=""
                value={rowsPerPage}
                onChange={evt => {
                  setRowsPerPage(evt.target.value);
                  setPageNum(1);
                }}
                style={{ padding: 3 }}
              >
                {_.map([10, 25, 50, 100], el => (
                  <option
                    value={el}
                    key={el}
                    style={{ padding: '10px 20px', cursor: 'pointer' }}
                  >
                    {el}
                  </option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferSearch;
