/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  DefaultPortModel,
  DefaultLinkFactory,
  DefaultLinkModel,
  DefaultLinkWidget,
  LinkWidget,
  RightAngleLinkWidget
} from '@projectstorm/react-diagrams';
import _ from 'lodash';
import * as React from 'react';
import NodeTypes from '../BaseNodeModel/NodeTypes';
import { AbstractModelFactory } from '@projectstorm/react-canvas-core';

export class AdvancedLinkModel extends DefaultLinkModel {
  constructor() {
    super({
      type: 'advanced',
      width: 2
    });
    this.lastHoverIndexOfPath = 0;
    this._lastPathXdirection = false;
    this._firstPathXdirection = true;
  }

  setFirstAndLastPathsDirection() {
    const points = this.getPoints();
    for (let i = 1; i < points.length; i += points.length - 2) {
      const dx = Math.abs(points[i].getX() - points[i - 1].getX());
      const dy = Math.abs(points[i].getY() - points[i - 1].getY());
      if (i - 1 === 0) {
        this._firstPathXdirection = dx > dy;
      } else {
        this._lastPathXdirection = dx > dy;
      }
    }
  }
  addPoint(pointModel, index = 1) {
    super.addPoint(pointModel, index);
    this.setFirstAndLastPathsDirection();
    this._firstPathXdirection = true;
    this._lastPathXdirection = false;
    return pointModel;
  }
  serialize() {
    return {
      ...super.serialize(),
      lastHoverIndexOfPath: this.lastHoverIndexOfPath,
      _lastPathXdirection: this._lastPathXdirection,
      _firstPathXdirection: this._firstPathXdirection
    };
  }

  deserialize(ob, engine) {
    super.deserialize(ob, engine);
    const data = ob.data;
    this.setFirstAndLastPathsDirection = data.setFirstAndLastPathsDirection;
    this.lastHoverIndexOfPath = data.lastHoverIndexOfPath;
    this._lastPathXdirection = data._lastPathXdirection;
    this._firstPathXdirection = data._firstPathXdirection;
  }
  setManuallyFirstAndLastPathsDirection(first, last) {
    this._firstPathXdirection = first;
    this._lastPathXdirection = last;
  }
  getLastPathXdirection() {
    return this._lastPathXdirection;
  }
  getFirstPathXdirection() {
    return this._firstPathXdirection;
  }
  setWidth(width) {
    this.options.width = width;
    this.fireEvent({ width }, 'widthChanged');
  }
  setColor(color) {
    this.options.color = color;
    this.fireEvent({ color }, 'colorChanged');
  }
}

export class AdvancedPortModel extends DefaultPortModel {
  constructor(options = {}) {
    super({
      type: 'advanced',
      in: options.in,
      name: options.name
    });
    this.nextNodes = options.nextNodes;
    this.maximumLinks = options.maximumLinks;
  }
  createLinkModel() {
    if (_.isFinite(this.maximumLinks)) {
      const numberOfLinks = _.size(this.links);
      if (numberOfLinks < this.maximumLinks) {
        return new AdvancedLinkModel();
      }
    }
    return null;
  }

  canLinkToPort(targetPortModel) {
    if (_.isFinite(targetPortModel.maximumLinks)) {
      const numberOfLinks = _.size(Object.keys(targetPortModel.links));
      if (numberOfLinks >= targetPortModel.maximumLinks) {
        return false;
      }
    }
    if (_.isFinite(this.maximumLinks)) {
      const linksWithTargetPort = _.filter(
        Object.values(this.links),
        link => !_.isEmpty(link.targetPort)
      );
      const numberOfLinks = _.size(linksWithTargetPort);
      if (numberOfLinks >= this.maximumLinks) {
        return false;
      }
    }
    if (!targetPortModel.options.in) {
      return false;
    }
    if (targetPortModel.options.in && this.options.in) {
      return false;
    }

    if (!this.parent.nextNodes.includes(targetPortModel.parent.type)) {
      return false;
    }
    return true;
  }

  serialize() {
    return {
      ...super.serialize(),
      nextNodes: this.nextNodes,
      maximumLinks: this.maximumLinks
    };
  }

  deserialize(ob, engine) {
    super.deserialize(ob, engine);
    const data = ob.data;
    this.maximumLinks = data.maximumLinks;
    this.nextNodes = data.nextNodes;
  }
}

export class SimplePortFactory extends AbstractModelFactory {
  constructor(type) {
    super('advanced');
  }
  generateModel(event) {
    return new AdvancedPortModel(event.initialConfig);
  }
}

const CustomLinkArrowWidget = props => {
  const { point, previousPoint } = props;

  const angle =
    90 +
    (Math.atan2(
      point.getPosition().y - previousPoint.getPosition().y,
      point.getPosition().x - previousPoint.getPosition().x
    ) *
      180) /
      Math.PI;
  // translate(50, -10),
  const x = point.getPosition().x + 13;
  const y = point.getPosition().y + 10;
  return (
    <g className="arrow" transform={'translate(' + x + ', ' + y + ')'}>
      <g style={{ transform: 'rotate(' + angle + 'deg)' }}>
        <g transform={'translate(0, -3)'}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="2em"
            height="2em"
            fill="none"
            stroke="#F07663"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="18 15 12 9 6 15"></polyline>
          </svg>
        </g>
      </g>
    </g>
  );
};

export class AdvancedLinkWidget extends DefaultLinkWidget {
  generateArrow(point, previousPoint) {
    return (
      <CustomLinkArrowWidget
        key={point.getID()}
        point={point}
        previousPoint={previousPoint}
        colorSelected={this.props.link.getOptions().selectedColor}
        color={this.props.link.getOptions().color}
      />
    );
  }

  render() {
    //ensure id is present for all points on the path
    const points = this.props.link.getPoints();
    const paths = [];
    this.refPaths = [];

    //draw the multiple anchors and complex line instead
    for (let j = 0; j < points.length - 1; j++) {
      paths.push(
        this.generateLink(
          LinkWidget.generateLinePath(points[j], points[j + 1]),
          {
            'data-linkid': this.props.link.getID(),
            'data-point': j,
            onMouseDown: event => {
              this.addPointToLink(event, j + 1);
            }
          },
          j
        )
      );
    }

    //render the circles
    for (let i = 1; i < points.length - 1; i++) {
      paths.push(this.generatePoint(points[i]));
    }

    if (this.props.link.getTargetPort() !== null) {
      paths.push(
        this.generateArrow(points[points.length - 1], points[points.length - 2])
      );
    } else {
      paths.push(this.generatePoint(points[points.length - 1]));
    }

    return (
      <g data-default-link-test={this.props.link.getOptions().testName}>
        {paths}
      </g>
    );
  }
}

export class AdvancedLinkFactory extends DefaultLinkFactory {
  constructor() {
    super('advanced');
  }

  generateModel() {
    return new AdvancedLinkModel();
  }

  generateReactWidget(event) {
    if (event.model.sourcePort.parent.type === NodeTypes.WAIT_NODE) {
      return (
        <RightAngleLinkWidget
          diagramEngine={this.engine}
          link={event.model}
          factory={this}
          smooth={true}
          width="1"
        />
      );
    }
    return (
      <AdvancedLinkWidget link={event.model} diagramEngine={this.engine} />
    );
  }
}
