import OpenSans from './OpenSans-Regular.ttf';
import OpenSansBold from './OpenSans-Bold.ttf';
import OpenSansItalic from './OpenSans-Italic.ttf';
import OpenSansBoldItalic from './OpenSans-BoldItalic.ttf';

const fonts = {
  family: 'OpenSans',
  fonts: [
    { src: OpenSans, fontStyle: 'normal', fontWeight: '400' }, // font-style: normal, font-weight: normal
    { src: OpenSansBold, fontWeight: 'bold' },
    { src: OpenSansItalic, fontStyle: 'italic' },
    { src: OpenSansBoldItalic, fontStyle: 'italic', fontWeight: 'bold' }
  ]
};

export default fonts;
