import React, { useRef } from 'react';
import { CSVLink } from 'react-csv';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';

export const CSVTemplate = props => {
  const { imgUrl, title, headers, data, downloadIcon, bgColor } = props;

  const csvLink = useRef(null);

  const handleClick = () => {
    csvLink.current.link.click();
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClick}
        style={{ background: `${bgColor}` }}
      >
        {downloadIcon && <GetAppIcon style={{ marginRight: 15 }} />}
        {imgUrl && (
          <img
            src={imgUrl}
            alt={imgUrl}
            style={{ height: 16, width: 16, marginRight: 10 }}
          />
        )}
        {title}
      </Button>
      <CSVLink
        enclosingCharacter={``}
        data={data || []}
        headers={headers}
        filename={`${title}.csv`}
        style={{ display: 'none' }}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </div>
  );
};
