import * as yup from 'yup';

export const liveReportToSchema = () => {
  const messages = {
    to: ['Receiver mail is Required', 'Not a proper email']
  };

  return yup.object().shape({
    to: yup
      .string()
      .email(messages.to[1])
      .trim()
      .required(messages.to[0]),
    cc: yup.array().of(
      yup
        .string()
        .email(messages.to[1])
        .trim()
    )
  });
};
