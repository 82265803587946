import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import _ from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
  select: {
    fontWeight: 'bold',
    '&:focus': {
      background: 'none'
    }
  }
});

export const OfferSortBar = props => {
  const {
    allSortOptions,
    selectedSortKey,
    onSelectedSortKeyChange,
    sortAscending,
    onSortOrderToggle
  } = props;
  const classes = useStyles();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '5px 15px',
        justifyContent: 'flex-end'
      }}
    >
      <div style={{ marginRight: 10, color: '#A8A8A8' }}>Sort By:</div>
      <FormControl
        style={{ minWidth: 70, padding: '0px 5px', fontWeight: 'bold' }}
      >
        <Select
          disableUnderline
          value={selectedSortKey}
          onChange={onSelectedSortKeyChange}
          classes={{
            select: classes.select
          }}
        >
          {_.map(allSortOptions, option => (
            <MenuItem key={option.key} value={option.key}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Tooltip title={sortAscending ? 'Ascending' : 'Descending'}>
        <IconButton onClick={onSortOrderToggle} style={{ padding: 2 }}>
          {sortAscending ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
        </IconButton>
      </Tooltip>
    </div>
  );
};
