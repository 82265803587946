import React from 'react';
import Loader from '../Campaign/shared/components/Loader/Loader';
import classes from './InvoiceCreator.module.scss';
import ButtonField from './components/ButtonField/ButtonField';
import Header from './components/Header/Header';
import SectionStepper from './components/SectionStepper/Stepper';
import SectionSwitcher from './components/Switcher/SectionSwitcher';
import InvoiceContextProvider, {
  InvoiceContext
} from './context/InvoiceContext';
import { PREVIEW_INDEX } from './shared/constants';

const InvoiceCreator: React.FC = props => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const switchPreview = () => setActiveStep(PREVIEW_INDEX);

  function renderButtonGroup() {
    const finalStep = activeStep >= PREVIEW_INDEX;
    const isNextPreview = activeStep === PREVIEW_INDEX - 1;
    const nextLabel = finalStep ? 'Submit' : isNextPreview ? 'Preview' : 'Next';
    return (
      <div className={classes.btns}>
        <ButtonField
          label="Back"
          disabled={activeStep === 0}
          onClick={handleBack}
          variant="outlined"
          color="primary"
          styles={{
            marginRight: '10px',
            marginTop: '10px'
          }}
        />
        <ButtonField
          label={nextLabel}
          color="primary"
          onClick={handleNext}
          styles={{
            marginRight: '10px',
            marginTop: '10px',
            backgroundColor: '#F26E5F !important'
          }}
          disabled={finalStep}
        />
      </div>
    );
  }

  const Main = () => {
    const { loading } = React.useContext(InvoiceContext);
    if (loading) return <Loader />;

    return (
      <main className={classes.main}>
        <div className={classes.stepperContainer}>
          <SectionStepper
            activeStep={activeStep}
            setStep={(idx: number) => setActiveStep(idx)}
          />
          {renderButtonGroup()}
        </div>
        <SectionSwitcher activeStep={activeStep} />
      </main>
    );
  };

  return (
    <InvoiceContextProvider>
      <div className={classes.container}>
        <Header switchPreview={switchPreview} />
        <Main />
      </div>
    </InvoiceContextProvider>
  );
};

export default InvoiceCreator;
