export const getColor = (isTrue: boolean) => {
  return isTrue ? '#f2755f' : '#707070';
};

export const anchorOriginObj: any = {
  vertical: 'bottom',
  horizontal: 'left'
};

export const transformOriginObj: any = {
  vertical: 'top',
  horizontal: 'center'
};
