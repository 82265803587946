import { isEmpty } from 'lodash';
import moment from 'moment';
import { BillReport } from '../../components/TenantReports/MemberReport/BillWise/Filter';
import { AdditionalField } from '../../components/TenantReports/Shared/AdditionalFields/AdditionalFields';
import config from '../../config/index';
import TransactionWiseReportDownload from '../../models/TenantReports/TransactionWiseReportDownload';

export const _getMemberBillReport = async (filters: BillReport) => {
  return fetch(
    `${config.ruleServerUrl}/users/reports/download/member_bill_report/${
      filters.businessId
    }?startDate=${moment(filters.startDate).format(
      'YYYY-MMM-DD'
    )}&endDate=${moment(filters.endDate).format('YYYY-MMM-DD')}&type=${
      filters.type
    }
    `,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
};

const getProcessedFilters = (filters: BillReport) => {
  const { additionalFields = [] } = filters;
  type additionalFields = Record<string, string[]>;

  const reducer = (
    prev: additionalFields,
    c: AdditionalField
  ): additionalFields => {
    (prev[c.level] || (prev[c.level] = [])).push(c.fieldName);
    return prev;
  };

  const processedAdditionalFields = additionalFields.reduce(
    reducer,
    {} as additionalFields
  );

  return { additionalFields: processedAdditionalFields };
};

export const getMemberBillReportViaPOST = async (filters: BillReport) => {
  return fetch(
    `${config.ruleServerUrl}/users/reports/download/member_bill_report/${
      filters.businessId
    }?startDate=${moment(filters.startDate).format(
      'YYYY-MMM-DD'
    )}&endDate=${moment(filters.endDate).format('YYYY-MMM-DD')}&type=${
      filters.type
    }
    `,
    {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify(getProcessedFilters(filters)),
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
};

export const getMemberBillReport = (filters: BillReport): any => {
  if (isEmpty(filters.additionalFields)) return _getMemberBillReport(filters);
  return getMemberBillReportViaPOST(filters);
};

export const getMemberBillReportDownload = async ({
  page,
  offset
}: {
  page: number;
  offset: number;
}): Promise<TransactionWiseReportDownload[]> => {
  return fetch(
    `${config.ruleServerUrl}/users/reports/view/downloads/member_bill_report?offset=${offset}&page=${page}`,
    { credentials: 'include' }
  ).then(res => res.json());
};
