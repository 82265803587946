import config from '../../config/index';
import moment from 'moment';
import RFMReportDownloadInterface from '../../models/TenantReports/RFMReportDownload';

export const getRfmReport = (filters: any, bName: any) => {
  let query = `${
    config.ruleServerUrl
  }/users/reports/download/rfm_report/${bName}?date=${moment(
    filters.reportFilterDate
  ).format('YYYY-MM-DD')}&startDate=${moment(
    `${moment(filters.startDate).year()}-04-01`
  ).format('YYYY-MM-DD')}&endDate=${moment(filters.endDate).format(
    'YYYY-MM-DD'
  )}`;
  filters?.businesses?.map((b: string) => (query += `&business=${b}`));
  console.log(query);
  return fetch(query, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  }).then(r => r.json());
};

export const getRfmReportDownload = ({
  page,
  offset,
  filters
}: {
  page: number;
  offset: number;
  filters?: any;
}): Promise<RFMReportDownloadInterface[]> => {
  return fetch(
    `${config.ruleServerUrl}/users/reports/view/downloads/rfm_report?offset=${offset}&page=${page}`,
    { credentials: 'include' }
  ).then(res => res.json());
};
