/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useContext } from 'react';
import _ from 'lodash';
// import { onDropNode } from '../BodyWidget/onDropUtils';
import RuleTemplatesContext from '../context/ruleTemplatesContext';
// import { PortTypes } from '../BaseNodeModel/PortModels';

const NodeWidgetHOC = props => {
  // const [dragOverStyle, setDragOverStyle] = useState({ zIndex: 10 });
  const getInPorts = ports => _.filter(ports, p => p.options.in);
  const getOutPorts = ports => _.filter(ports, p => !p.options.in);
  const getPorts = ports => [getInPorts(ports), getOutPorts(ports)];
  const { setNodeDetails } = useContext(RuleTemplatesContext);
  const removeNode = (e, node) => {
    node.remove();
    e.stopPropagation();
    setNodeDetails({
      node: node,
      type: node.type,
      timeConstraint: {},
      ruleTemplates: [],
      templateId: node.templateId,
      specs: []
    });
  };

  const showDetails = (node, setInputFields = null, ruleTemplates = []) => {
    setNodeDetails({
      node: node,
      ruleTemplates: ruleTemplates,
      type: node.type,
      timeConstraint: node.timeConstraint || {},
      templateId: node.templateId,
      specs: node.specs || [],
      setInputFields: setInputFields,
      delete: removeNode,
      isDetailsSaved: node.isDetailsSaved
    });
  };

  return (
    <div>
      {React.cloneElement(props.children, {
        getPorts,
        showDetails,
        removeNode
      })}
    </div>
  );
};

export default NodeWidgetHOC;
