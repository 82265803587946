import _ from 'lodash';
import { CircularProgress } from '@material-ui/core';
import React from 'react';
export const loaderElement = () => {
  return (
    <div className={'flipbook-loader-tile'}>
      <CircularProgress
        color="inherit"
        variant="indeterminate"
        size={60}
        thickness={5}
      />
    </div>
  );
};
function getAllGroupedProducts(pageGenerators: any) {
  return _.get(
    _.find(pageGenerators, v => {
      return v.type === 'tiles';
    }),
    'groupedProducts',
    []
  );
}

const getGroupedProductsByGroupId = (groupId: number, pageGenerators: any) => {
  return _.find(
    getAllGroupedProducts(pageGenerators),
    (d: any) => _.get(d, 'group.groupId') === groupId
  );
};

const isLayoutHasImage = (
  layoutTileType: string,
  groupId: number,
  layout: any,
  pageGenerators: any
) => {
  return !_.isEmpty(
    getBackgroundImageUrlByLayoutTile(groupId, layoutTileType, pageGenerators)
  );
};

const getBackgroundImageUrlByLayoutTile = (
  groupId: number,
  layoutTile: string,
  pageGenerators: any
) => {
  const selectedGroup = getGroupedProductsByGroupId(groupId, pageGenerators);
  return _.get(selectedGroup, `group.layout.${layoutTile}.backgroundImage`);
};

const isLayoutHasVideo = (
  layoutTileType: string,
  groupId: number,
  layout: any,
  pageGenerators: any
) => {
  return !_.isEmpty(
    _.get(
      getVideoDataByLayoutTile(groupId, layoutTileType, pageGenerators),
      'URL'
    )
  );
};

const getVideoDataByLayoutTile = (
  groupId: number,
  layoutTile: string,
  pageGenerators: any
) => {
  const selectedGroup = getGroupedProductsByGroupId(groupId, pageGenerators);
  return _.get(selectedGroup, `group.layout.${layoutTile}.VideoData`);
};

const getAllProductsLayoutByTileType = (tileType: string, layout: any) => {
  return _.flatMap(_.get(layout, `${tileType}.products`), 'selectedProducts');
};

const isLayoutHasValue = (
  layoutTileType: string,
  groupId: number,
  layout: any,
  pageGenerators: any
) => {
  return (
    !_.isEmpty(
      getBackgroundImageUrlByLayoutTile(groupId, layoutTileType, pageGenerators)
    ) ||
    !_.isEmpty(getAllProductsLayoutByTileType(layoutTileType, layout)) ||
    isLayoutHasVideo(layoutTileType, groupId, layout, pageGenerators)
  );
};

export {
  getAllGroupedProducts,
  getGroupedProductsByGroupId,
  isLayoutHasImage,
  isLayoutHasVideo,
  getVideoDataByLayoutTile,
  isLayoutHasValue
};
