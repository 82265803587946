import React, { FC } from 'react';
import SingleLayout from '../SharedComponents/LayoutType/SingleLayout';
import { FlipbookLayout } from '../../../../models/FlipbookV2/FlipbookV2Layout.model';

interface OverFlowPageProps {
  page: FlipbookLayout;
  editPanelOccupiedKey: string;
  setEditPanelOccupiedKey: (key: string) => void;
  isCustomerView: boolean;
  isEditMode: boolean;
  hasPermission: (permission: string) => boolean;
}

const OverFlowPage: FC<OverFlowPageProps> = ({
  page,
  editPanelOccupiedKey,
  setEditPanelOccupiedKey,
  isEditMode,
  isCustomerView,
  hasPermission
}) => {
  return (
    <SingleLayout
      editPanelOccupiedKey={editPanelOccupiedKey}
      setEditPanelOccupiedKey={setEditPanelOccupiedKey}
      page={page}
      isEditMode={isEditMode}
      isCustomerView={isCustomerView}
      hasPermission={hasPermission}
    />
  );
};

export default OverFlowPage;
