import React, { FC } from 'react';
import { Checkbox, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import _ from 'lodash';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface SelectWithAutoCompleteProps {
  onChange: (data: any) => void;
  options: any;
  values: any;
  label: any;
  size: string;
  autoCompleteSize?: 'small' | 'medium';
  isMultiple?: boolean;
  disabled?: boolean;
}

export const SelectWithAutoComplete: FC<SelectWithAutoCompleteProps> = ({
  onChange,
  options,
  values,
  label,
  size,
  autoCompleteSize = 'medium',
  isMultiple = true,
  disabled = false
}) => {
  const updatedOptions = _.filter(
    options,
    o => o.value !== null && o.label !== null
  );
  return (
    <Autocomplete
      disabled={disabled}
      filterSelectedOptions={false}
      // disablePortal={true}
      multiple={isMultiple}
      limitTags={1}
      id={`${label}-checkboxes-tags-demo`}
      options={updatedOptions}
      disableCloseOnSelect={isMultiple}
      getOptionLabel={option => option.value}
      getOptionSelected={(option: any, value: any) =>
        option.value === value.value
      }
      size={autoCompleteSize}
      value={values}
      onChange={(e, v) => onChange(v)}
      renderOption={(option, { selected }) => {
        return (
          <React.Fragment>
            {option.value !== 'Select All' &&
              option.value !== 'Clear All' &&
              isMultiple && (
                <Checkbox
                  color={'primary'}
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
              )}
            {option.label}
          </React.Fragment>
        );
      }}
      style={{ width: `${size}` }}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={`Select ${_.startCase(label)}`}
          placeholder={_.startCase(label)}
        />
      )}
    />
  );
};

export default SelectWithAutoComplete;
