import { Button, createStyles, Modal } from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';
import { ProductsHierarchyModal } from './ProductHierarchy';
import { makeStyles } from '@material-ui/core/styles';
import { CSVLink } from 'react-csv';
import _ from 'lodash';

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
);
const CSV_HEADERS = ['SKU'];
const CategoryFilter = ({
  allProducts,
  productHierarchy,
  disabled,
  skus,
  updateState
}) => {
  const classes = useStyles();
  const [isAddCollectionOpen, setIsAddCollectionOpen] = React.useState(false);
  const [
    productsFilteredByFirstHierarchy,
    setProductsFilteredByFirstHierarchy
  ] = React.useState([]);
  const [
    productHierarchyTreeExpanded,
    setProductHierarchyTreeExpanded
  ] = React.useState([]);
  const [
    productHierarchyTreeChecked,
    setProductHierarchyTreeChecked
  ] = React.useState([]);
  const [selectedProductsByPath, setSelectedProductsByPath] = React.useState(
    []
  );
  const handleAddCollectionModalClose = () => {
    return setIsAddCollectionOpen(false);
  };
  const handleAddCollectionModalOpen = () => {
    setIsAddCollectionOpen(true);
  };
  const getSelectedProducts = () => {
    return selectedProductsByPath.length === 0
      ? 'No selected Products'
      : `Selected SKU's count: ${selectedProductsByPath.length}`;
  };
  return (
    <div>
      <Modal
        open={isAddCollectionOpen}
        onClose={() => handleAddCollectionModalClose()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        <ProductsHierarchyModal
          allProducts={allProducts}
          productHierarchy={productHierarchy}
          selectedProductsByPath={selectedProductsByPath}
          setSelectedProductsByPath={setSelectedProductsByPath}
          setIsAddCollectionOpen={setIsAddCollectionOpen}
          productsFilteredByFirstHierarchy={productsFilteredByFirstHierarchy}
          setProductHierarchyTreeExpanded={setProductHierarchyTreeExpanded}
          productHierarchyTreeExpanded={productHierarchyTreeExpanded}
          productHierarchyTreeChecked={productHierarchyTreeChecked}
          setProductsFilteredByFirstHierarchy={
            setProductsFilteredByFirstHierarchy
          }
          setProductHierarchyTreeChecked={setProductHierarchyTreeChecked}
          skus={skus}
          updateState={updateState}
        />
      </Modal>
      <div
        className={classNames({
          // eslint-disable-next-line
          ['not-allowed']: disabled
        })}
      >
        <div
          style={{
            marginLeft: '30px',
            width: '274px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'baseline'
          }}
        >
          <div style={{ float: 'left' }}>
            <Button
              variant="outlined"
              color="primary"
              disabled={disabled}
              onClick={() => handleAddCollectionModalOpen()}
              style={{
                width: '162px',
                fontSize: '10px',
                height: '40px',
                alignItems: 'left'
              }}
            >
              {selectedProductsByPath.length === 0
                ? 'Add Products'
                : 'Edit Products'}
            </Button>
          </div>
          <div
            style={{
              margin: '10px 0px',
              fontSize: '0.75rem',
              color: '#777777',
              fontStyle: 'italic'
            }}
          >
            {getSelectedProducts()}
          </div>
          <div disabled={disabled}>
            <CSVLink
              data={_.map(skus.fieldValue, ele => [ele])}
              headers={CSV_HEADERS}
              filename={`selected_sku.csv`}
              style={{
                color: 'black',
                backgroundColor: '#ffffff',
                float: 'right',
                textAlign: 'right',
                width: '220px'
              }}
              className="hidden"
            >
              <img src={require('../../assets/down-arrow.svg')} alt={'img'} />
              &nbsp; Download SKU
            </CSVLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CategoryFilter };
