import config from '../../config';

export const getSurveys = (
  page: number,
  pageSize: number,
  businessUnitId: number,
  name = ''
) => {
  return fetch(
    `${config.apiServerUrl}/users/survey/business/${businessUnitId}/list?page=${page}&pageSize=${pageSize}&name=${name}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(res => {
    return res.json();
  });
};
