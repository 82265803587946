import React from 'react';
import moment from 'moment';
import _, { range } from 'lodash';
import { Page, View, Text, Image, StyleSheet, Font } from '@react-pdf/renderer';
import RupeeIcon from '../../assets/peghook-rupee.png';
import Disclimer from '../../assets/disclimer.png';
import fonts from '../../assets/Fonts';
import { truncatedNumberFloat, truncateString } from '../../../../utils/index';
import { GetBarCode } from '../utils';

Font.register(fonts);

const styles = StyleSheet.create({
  card: {
    fontFamily: 'OpenSans',
    height: '108px',
    width: '207px',
    border: '1 solid #000000',
    margin: '1mm 11mm'
  },
  inlineCard: { display: 'flex', flexDirection: 'row' },
  qrPriceBlock: {
    display: 'flex',
    flexDirection: 'row'
  },
  qrcode: {
    width: '54px',
    height: '54px'
  },
  qrcodeBlock: {
    width: '60px',
    height: '66px',
    padding: '2mm 1mm',
    borderRight: '1 solid #000000',
    borderBottom: '1 solid #000000'
  },
  priceBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '45px'
  },
  rupeeicon: {
    fontSize: '22pt',
    top: -11.5,
    width: '6px'
  },
  price: {
    fontFamily: 'OpenSans',
    fontSize: '22.8pt',
    height: '36px',
    fontWeight: 'bold',
    color: 'black'
  },
  inclTax: {
    fontSize: '7.98pt',
    fontWeight: 'bold',
    display: 'flex',
    top: -8,
    marginRight: '2px',
    alignItems: 'flex-end'
  },
  productName: { fontSize: '7pt', position: 'absolute', padding: '1mm 1mm' },
  itemCode: {
    fontSize: '5pt',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '11mm',
    paddingLeft: '1mm'
  },
  deals: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: '12pt'
  },
  discBlock: {
    left: '1px'
  },
  rupeeSmall: {
    width: '2.4mm',
    height: '3.1mm',
    top: 4
  },
  barcode: {
    width: '20.7mm',
    height: '21.2mm',
    padding: '24px 1mm 1mm 0mm'
  },

  disclimer: {
    width: '2.75mm',
    height: '2.75mm'
  }
});
const LabelView = ({ item }) => (
  <View style={styles?.card}>
    <View style={styles?.qrPriceBlock}>
      <View style={styles?.qrcodeBlock}>
        <Image src={item.image} style={styles.qrcode} />
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '146px',
          height: '66px',
          borderBottom: '1 solid #000000'
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '21px',
            borderBottom: '1 solid #000000'
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '91px',
              height: '21px',
              borderRight: '1 solid #000000'
            }}
          >
            <View style={styles.deals}></View>
          </View>
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '55px',
              height: '21px',
              backgroundColor: '#818386'
            }}
          ></View>
        </View>
        <View style={styles?.priceBlock}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '91px',
              height: '45px',
              borderRight: '1 solid #000000'
            }}
          >
            <View style={styles?.rupeeicon}>
              <Image src={RupeeIcon} />
            </View>
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={styles.price}>
                  {truncatedNumberFloat(item?.product?.mop, 6)}
                </Text>
                <Text style={{ fontWeight: 'bold', fontSize: '8pt', top: 4 }}>
                  *
                </Text>
              </View>
              <View style={styles?.inclTax}>
                <Text>*(Incl. Taxes)</Text>
              </View>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '55px',
              height: '45px'
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: '#1A1818',
                height: '21px',
                width: '55px'
              }}
            ></View>
          </View>
        </View>
      </View>
    </View>
    <View>
      <Text style={styles.productName}>
        {truncateString(item?.product?.masterData?.name, 130)}
      </Text>
      <View
        style={{
          display: 'flex',
          width: '207px',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <View style={styles.itemCode}>
          <Text>
            {item?.sku} | {item.class} | A |{' '}
            {moment(item?.date).format('DD.MM.YYYY')} |{' '}
            {moment().format('DD.MM.YYYY')}
          </Text>
          <View style={styles.discBlock}>
            <Image style={styles.disclimer} src={Disclimer} />
          </View>
        </View>
        <View style={styles.barcode}>
          {_.isEmpty(item.barcode) ? (
            <></>
          ) : (
            <Image src={GetBarCode(item?.barcode)} />
          )}
        </View>
      </View>
    </View>
  </View>
);
const getPegHookLayout = (data, rowSets) => {
  return data?.map((labelData, i) => {
    return rowSets?.map(rowCount => (
      <View style={styles.inlineCard} key={i}>
        {rowCount.map(id => (
          <LabelView item={labelData} key={id} />
        ))}
      </View>
    ));
  });
};
const PegHookH1 = ({ data, count, ...rest }) => {
  const labelsPerRow = 3;
  const rowsPerSku = count.length / labelsPerRow;
  const rowSets = range(rowsPerSku).map(ind =>
    count.slice(labelsPerRow * ind, labelsPerRow * (ind + 1))
  );
  const splattedArray = _.chunk(data, 10);
  return (
    <Page size="A4" orientation="landscape" style={{ padding: '5mm' }}>
      <View>
        {count &&
          _.map(splattedArray, (chunkedData, key) => {
            const chunkedRowArray = _.chunk(chunkedData, 5);
            const firstHalf = getPegHookLayout(chunkedRowArray[0], rowSets);
            const secondHalf = getPegHookLayout(chunkedRowArray[1], rowSets);
            return (
              <View
                style={{ flexDirection: 'row', flexWrap: 'wrap' }}
                key={key}
              >
                <View>{firstHalf}</View>
                <View>{secondHalf}</View>
              </View>
            );
          })}
      </View>
    </Page>
  );
};
export { PegHookH1 };
