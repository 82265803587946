import { Box, Button, Dialog, Divider, Typography } from '@material-ui/core';
import styles from './CampaignVariantModal.module.scss';
import React, { FC } from 'react';
import CampaignVariantDetails from '../../../../../models/campaign/CampaignVariantDetails';
import useFetch from '../../../../../shared/hooks/useFetch';
import { getCampaignVariantAnalysis } from '../../../../../services/Campaign/campaign-details.service';
import _ from 'lodash';
import CampaignVariantCard from '../CampaignTable/CampaignVariantCard/CampaignVariantCard';

interface CampaignVariantModalProps {
  campaignId: string;
  open: boolean;
  setOpen: (arg0: boolean) => void;
}

const CampaignVariantModal: FC<CampaignVariantModalProps> = ({
  campaignId,
  open,
  setOpen
}) => {
  const { data: variants } = useFetch<CampaignVariantDetails[]>(() =>
    getCampaignVariantAnalysis(campaignId)
  );

  const closeModal = (): void => setOpen(false);

  const campaignName = _.get(_.get(variants, '[0]', {}), 'Campaign Name', '');
  return (
    <Dialog
      maxWidth={'lg'}
      open={open}
      fullWidth
      className={styles.dialog}
      onClose={closeModal}
    >
      <Box className={styles.modal}>
        <Typography variant="h5">{campaignName}</Typography>
        <Divider style={{ opacity: 0.9 }} />
        <Box className={styles.variantList}>
          {_.map(variants, variant => (
            <CampaignVariantCard variant={variant} />
          ))}
        </Box>
        <Divider style={{ opacity: 0.9 }} />
        <Button
          variant="outlined"
          color="default"
          onClick={closeModal}
          className={styles.close}
        >
          Close
        </Button>
      </Box>
    </Dialog>
  );
};

export default CampaignVariantModal;
