import config from '../config';

function addOfferOfferTags(tenantBrandBusinessId, offerTagIds, offerCIds) {
  return fetch(`${config.ruleServerUrl}/users/offer-offer-tags/add`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({
      tenantBrandBusinessId,
      offerTagIds,
      offerCIds
    }),
    headers: {
      'content-type': 'application/json'
    }
  }).then(r => {
    if (!r.ok) {
      throw new Error('Something went wrong');
    }
    return r.json();
  });
}

function removeOfferOfferTags(tenantBrandBusinessId, offerTagIds, offerCIds) {
  return fetch(`${config.ruleServerUrl}/users/offer-offer-tags/remove`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({
      tenantBrandBusinessId,
      offerTagIds,
      offerCIds
    }),
    headers: {
      'content-type': 'application/json'
    }
  }).then(r => {
    if (!r.ok) {
      throw new Error('Something went wrong');
    }
    return r.json();
  });
}

export default {
  addOfferOfferTags,
  removeOfferOfferTags
};
