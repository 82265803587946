import moment from 'moment';

import config from '../../config';
import Campaign from '../../models/campaign/Campaign';
import CampaignDetail from '../../models/campaign/CampaignDetail';
import CampaignFilters from '../../models/campaign/CampaignFilters';
import CampaignOverview from '../../models/campaign/CampaignOverview';
import CampaignVariantDetails from '../../models/campaign/CampaignVariantDetails';
import CampaignDefinition from '../../models/campaign/CampaignDefinition';

interface CampaignsResponse {
  totalRecords: number;
  campaigns: Campaign[];
}

const fetchCampaignsDetail = async <T>(
  url: string,
  filters: CampaignFilters
): Promise<T> => {
  const {
    startDate,
    endDate,
    campaignType,
    campaignStatus,
    search,
    page,
    offset,
    sort,
    buId
  } = filters;

  const campaignFilters = {
    filters: {
      date: {
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD')
      },
      name: search,
      attributes: {
        type: campaignType ? [campaignType] : [],
        status: campaignStatus ? [campaignStatus] : []
      }
    },
    sortOrder: sort,
    pagination: {
      page,
      offset
    }
  };

  const response = await fetch(
    `${config.ruleServerUrl}/users/campaigns-analytics/${url}/${buId}`,
    {
      method: 'POST',
      body: JSON.stringify(campaignFilters),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  if (!response.ok) {
    throw new Error('Something went wrong');
  }

  return response.json();
};

export const getCampaigns = async (
  filters: CampaignFilters
): Promise<CampaignsResponse> => {
  return fetchCampaignsDetail<CampaignsResponse>('list', filters);
};

export const getCampaignOverview = async (
  filters: CampaignFilters
): Promise<CampaignOverview> => {
  return fetchCampaignsDetail<CampaignOverview>('kpi', filters);
};

export const getCampaignDetail = async (
  campaignId: string,
  buId: string
): Promise<CampaignDetail> => {
  const response = await fetch(
    `${config.ruleServerUrl}/users/campaigns-analytics/list/${buId}/${campaignId}`,
    {
      credentials: 'include'
    }
  );

  if (!response.ok) {
    throw new Error('Something went wrong!');
  }

  return response.json();
};

export const getCampaignDefinition = async (
  campaignId: string
): Promise<CampaignDefinition> => {
  const response = await fetch(
    `${config.ruleServerUrl}/users/campaigns/${campaignId}`,
    {
      credentials: 'include'
    }
  );

  if (!response.ok) {
    throw new Error('Something went wrong!');
  }

  return response.json().then(res => res);
};

export const getCampaignAvailableStatus = async (): Promise<string[]> => {
  const response = await fetch(
    `${config.ruleServerUrl}/users/campaigns-analytics/list-status`,
    {
      credentials: 'include'
    }
  );

  if (!response.ok) {
    throw new Error('Something went wrong!');
  }

  return response.json();
};

export const getCampaignSmsStatus = async (
  campaignId: string
): Promise<{ targetCount: number; sentCount: number }> => {
  const response = await fetch(
    `${config.ruleServerUrl}/users/published-campaigns/campaign-status/${campaignId}/sms`,
    {
      credentials: 'include'
    }
  );

  if (!response.ok) {
    throw new Error('Something went wrong!');
  }

  const data = await response.json();

  const statusSms = data.aggregations.dates.buckets.sort(
    (a: any, b: any) =>
      new Date(b.key_as_string).getTime() - new Date(a.key_as_string).getTime()
  )[0];

  return {
    sentCount: statusSms.nested.message_delivered_count.doc_count || 0,
    targetCount: statusSms.nested.message_sent_count.doc_count || 0
  };
};

export async function getCampaignVariantAnalysis(
  campaignId: string
): Promise<CampaignVariantDetails[]> {
  const url = `${config.ruleServerUrl}/users/campaigns-analytics/${campaignId}/variant-report`;
  const response = await fetch(url, { credentials: 'include' });
  if (!response.ok) {
    throw new Error('Something went wrong!');
  }

  const data: CampaignVariantDetails[] = await response.json();
  return data;
}
