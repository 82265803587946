import React, { useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import offerCreated from '../Assets/Offers/offersImage.png';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router';
import { ParentNavigate } from '../../utils/ParentNavigate';

const useStyles = makeStyles({
  dialogTitle: {
    textAlign: 'center'
  },
  headingText: {
    textAlign: 'center',
    font: 'Bold 29px Nunito',
    letterSpacing: 0,
    color: '#404040',
    opacity: 1
  },
  paragraphText: {
    textAlign: 'center',
    font: '17px/29px Nunito',
    letterSpacing: 0,
    color: '#404040',
    opacity: 1
  },
  dialogHintParagraph: {
    color: '#A8A8A8',
    marginBottom: 0
  },
  dialogPaper: {
    padding: '50px 25px',
    alignItems: 'center',
    height: '100%',
    maxHeight: '500px',
    justifyContent: 'space-between'
  },
  closeButton: {
    position: 'absolute',
    top: '8px',
    right: '8px'
  }
});

export const AfterSaveDialog = props => {
  const { isSaveSuccess, isDraftSave, redirectCountdown } = props;
  const [triggerRedirect, setTriggerRedirect] = useState(false);
  const history = useHistory();

  const [timeLeft, setTimeLeft] = useState(redirectCountdown);

  useEffect(() => {
    if (!timeLeft || timeLeft <= 0) {
      setTriggerRedirect(true);
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [history, timeLeft]);

  const classes = useStyles();
  return (
    <Dialog
      open={true}
      classes={{
        paper: classes.dialogPaper
      }}
      fullWidth
    >
      <div>
        <img
          src={offerCreated}
          alt="Offer Creation"
          height="200px"
          style={{ display: 'block', margin: 'auto' }}
        />
      </div>
      <div className={classes.dialogTitle}>
        <h2 className={classes.headingText}>
          {isSaveSuccess
            ? isDraftSave
              ? 'Offer has been successfully drafted'
              : 'Offer has been successfully created'
            : 'Offer Creation Failed'}
        </h2>
        <p className={classes.paragraphText}>
          {isSaveSuccess
            ? 'You can now attach this offer with a campaign!'
            : 'Something went wrong!'}
        </p>
        <p className={classes.paragraphText}>
          You will automatically be redirected to the offers page in {timeLeft}{' '}
          seconds.
        </p>
        <Button
          color={'primary'}
          variant={'contained'}
          onClick={() => setTriggerRedirect(true)}
        >
          Redirect Instantly
        </Button>
        {triggerRedirect && <ParentNavigate to={'/offers/'} />}
      </div>
    </Dialog>
  );
};

AfterSaveDialog.propTypes = {
  isSaveSuccess: PropTypes.bool.isRequired,
  redirectCountdown: PropTypes.number
};

AfterSaveDialog.defaultProps = {
  redirectCountdown: 10
};
