/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';

import classes from './Header.module.css';

const Toggle = ({ style, on, setOn }) => {
  const toggleContainer = [
    classes.toggleContainer,
    on ? classes.toggleOn : ''
  ].join(' ');

  const toggleBtn = [classes.toggleBtn, on ? classes.isToggled : ''].join(' ');
  const status = on ? 'RUNNING' : 'GO LIVE';

  return (
    <div style={style}>
      <label style={{ fontWeight: 'bolder', color: '#F07663' }}>{status}</label>
      <div className={toggleContainer} onClick={setOn}>
        <div className={toggleBtn}></div>
      </div>
    </div>
  );
};

export default Toggle;
