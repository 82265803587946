// add peghook 4 digit constants
const LAYOUT = {
  PEGHOOKFOURDIGIT: 'PEGHOOK4POINT',
  PEGHOOK: 'PEGHOOK',
  WIREBASKETFOURDIGIT: 'WIREBASKET4POINT',
  WIREBASKET: 'WIREBASKET',
  INTEGRATEDSHELF: 'INTEGRATEDSHELF',
  A6: 'A6',
  A5: 'A5',
  A4: 'A4'
};
const LATEST_VERSION = {
  PEGHOOK4POINT: 'V1',
  PEGHOOK: 'H1',
  WIREBASKET4POINT: 'V1',
  WIREBASKET: 'H1',
  INTEGRATEDSHELF: 'H1',
  A6: 'H1',
  A5: 'H1',
  A4: 'H1'
};
const ALLOWED_VERSION = {
  PEGHOOK4POINT: ['V1'],
  PEGHOOK: ['V1', 'V2', 'H1'],
  WIREBASKET4POINT: ['V1'],
  WIREBASKET: ['V1', 'V2', 'H1'],
  INTEGRATEDSHELF: ['V1', 'V2', 'H1'],
  A6: ['V1', 'V2', 'V3', 'H1'],
  A5: ['V1', 'V2', 'H1'],
  A4: ['V1', 'V2', 'V3', 'H1']
};
const ORINTATION = {
  LANDSCAPE: 'LANDSCAPE',
  PORTRAIT: 'PORTRAIT'
};

const PAGETILE = {
  [LAYOUT.PEGHOOKFOURDIGIT]: 'PEG HOOK (4 DIGIT PRICE-POINT) | 73mm X 38mm',
  [LAYOUT.PEGHOOK]: 'PEG HOOK | 73mm X 38mm',
  [LAYOUT.WIREBASKET]: 'WIRE BASKET | 108mm X 38mm',
  [LAYOUT.WIREBASKETFOURDIGIT]:
    'WIRE BASKET (4 DIGIT PRICE-POINT) | 92mm X 38mm',
  [LAYOUT.INTEGRATEDSHELF]: 'INTEGRATED SHELF + BEAM | 175mm X 38mm',
  [LAYOUT.A6]: 'A6 | 102.66 mm X 144.7 mm',
  [LAYOUT.A5]: 'A5 | 145.3 mm X 206.2 mm',
  [LAYOUT.A4]: 'A4 | 297 mm X 210 mm'
};

const UNITS = {
  rft: 'Per RFT ',
  psf: 'Per SQFT '
};

export { ORINTATION, LAYOUT, PAGETILE, LATEST_VERSION, ALLOWED_VERSION, UNITS };
