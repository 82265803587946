import { TableCell, TableHead, TableRow } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';
import styles from './TableHeader.module.scss';

interface TableHeaderProps {
  headings: string[];
  className?: string;
  align?: 'start' | 'center' | 'end';
}

const TableHeader: FC<TableHeaderProps> = ({
  headings,
  className,
  align = 'start'
}) => {
  return (
    <TableHead className={clsx(className)} id={styles.tableHeader}>
      <TableRow className={styles.tableHeaderRow}>
        {headings.map(heading => (
          <TableCell
            className={clsx(styles.tableHeaderCell, `text-${align}`)}
            key={heading}
          >
            {heading}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
