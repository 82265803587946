import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';

const CustomAutoComplete = props => {
  return (
    <Autocomplete
      multiple
      limitTags={props.limitTags || 1}
      id="tags-filled"
      options={props.options}
      value={props.value}
      onChange={props.onChange}
      fullWidth
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option}
            key={index}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={params => (
        <TextField {...params} variant="filled" label={props.label} />
      )}
    />
  );
};

export default CustomAutoComplete;
