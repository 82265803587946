import * as yup from 'yup';

const baseSchema = {
  priorityOrder: ['Prority Order is Required'],
  sender: ['Sender is Required'],
  promotionalSender: ['Promotional Sender is Optional']
};

export const smsModalToSchema = () => {
  const messages = {
    authkey: ['Token is Required'],
    dltEntityId: ['Dlt Entity Id is required'],
    from: ['Sender Mobile Number is required'],
    ...baseSchema
  };

  return yup.object().shape({
    priorityOrder: yup
      .string()
      .trim()
      .required(messages.priorityOrder[0]),
    sender: yup
      .string()
      .trim()
      .required(messages.sender[0]),
    authkey: yup
      .string()
      .required(messages.authkey[0])
      .trim(),
    dltEntityId: yup
      .string()
      .required(messages.dltEntityId[0])
      .trim(),
    from: yup
      .string()
      .required(messages.from[0])
      .trim()
  });
};

export const MSG91SmsModalToSchema = () => {
  const messages = {
    authkey: ['Token is Required'],
    ...baseSchema
  };

  return yup.object().shape({
    priorityOrder: yup
      .string()
      .trim()
      .required(messages.priorityOrder[0]),
    sender: yup
      .string()
      .trim()
      .required(messages.sender[0]),
    authkey: yup
      .string()
      .required(messages.authkey[0])
      .trim()
  });
};

export const IcsSmsModalToSchema = () => {
  return yup.object().shape({
    priorityOrder: yup.string().trim(),
    [`from_promotional`]: yup.string().trim(),
    [`pass_promotional`]: yup.string().trim(),
    [`user_promotional`]: yup.string().trim(),
    [`from_transactional`]: yup.string().trim(),
    [`pass_transactional`]: yup.string().trim(),
    [`user_transactional`]: yup.string().trim(),
    [`from_OTP`]: yup.string().trim(),
    [`pass_OTP`]: yup.string().trim(),
    [`user_OTP`]: yup.string().trim(),
    peId: yup.string().trim()
  });
};

export const valueFirstModalToSchema = () => {
  const messages = {
    username: ['User Name is Required'],
    password: ['Password is Required'],
    ...baseSchema
  };

  return yup.object().shape({
    priorityOrder: yup
      .string()
      .trim()
      .required(messages.priorityOrder[0]),
    sender: yup
      .string()
      .trim()
      .required(messages.sender[0]),
    username: yup
      .string()
      .trim()
      .required(messages.username[0]),
    password: yup
      .string()
      .trim()
      .required(messages.password[0])
  });
};

export const StmModalToSchema = () => {
  const messages = {
    authkey: ['Authkey is required'],
    ...baseSchema,
    principleEntityId: ['Principle entity id is required'],
    clientId: ['Client id is required']
  };

  return yup.object().shape({
    priorityOrder: yup
      .string()
      .trim()
      .required(messages.priorityOrder[0]),
    sender: yup
      .string()
      .trim()
      .required(messages.sender[0]),
    authkey: yup
      .string()
      .trim()
      .required(messages.authkey[0]),
    principleEntityId: yup
      .string()
      .trim()
      .required(messages.principleEntityId[0]),
    clientId: yup
      .string()
      .trim()
      .required(messages.clientId[0])
  });
};

export const OneXtelModalToSchema = () => {
  const messages = {
    ...baseSchema,
    authkey: ['Authkey is required'],
    authKeyServiceExplicit: ['AuthKey Service Explicit is required'],
    authKeyPromotional: ['AuthKey Promotional is required'],
    entityId: ['Entity id is required']
  };

  return yup.object().shape({
    priorityOrder: yup
      .string()
      .trim()
      .required(messages.priorityOrder[0]),
    sender: yup
      .string()
      .trim()
      .required(messages.sender[0]),
    authkey: yup
      .string()
      .trim()
      .required(messages.authkey[0]),
    authKeyServiceExplicit: yup
      .string()
      .trim()
      .required(messages.authKeyServiceExplicit[0]),
    authKeyPromotional: yup
      .string()
      .trim()
      .required(messages.authKeyPromotional[0]),
    entityId: yup
      .string()
      .trim()
      .required(messages.entityId[0])
  });
};

export const KarixSMSModalToSchema = () => {
  const messages = {
    ...baseSchema,
    authKeyPromotional: ['AuthKey Promotional is required'],
    authKeyTransactional: ['AuthKey Transactional is required'],
    authKeyOtp: ['AuthKey Otp is required'],
    dltEntityId: ['Entity id is required']
  };

  return yup.object().shape({
    priorityOrder: yup
      .string()
      .trim()
      .required(messages.priorityOrder[0]),
    sender: yup
      .string()
      .trim()
      .required(messages.sender[0]),
    authKeyPromotional: yup
      .string()
      .trim()
      .required(messages.authKeyPromotional[0]),
    authKeyTransactional: yup
      .string()
      .trim()
      .required(messages.authKeyTransactional[0]),
    authKeyOtp: yup
      .string()
      .trim()
      .required(messages.authKeyOtp[0]),
    dltEntityId: yup.string().trim(),
    from: yup.string().trim(),
    promotionalSender: yup
      .string()
      .required(messages.promotionalSender[0])
      .trim()
  });
};

export const KarixWhatsappModalToSchema = () => {
  const messages = {
    ...baseSchema,
    transactionalSender: ['Sender For Transactional is required'],
    authKeyPromotional: ['AuthKey Promotional is required'],
    authKeyTransactional: ['AuthKey Transactional is required']
  };

  return yup.object().shape({
    priorityOrder: yup
      .string()
      .trim()
      .required(messages.priorityOrder[0]),
    transactionalSender: yup
      .string()
      .trim()
      .required(messages.transactionalSender[0]),
    authKeyPromotional: yup
      .string()
      .trim()
      .required(messages.authKeyPromotional[0]),
    authKeyTransactional: yup
      .string()
      .trim()
      .required(messages.authKeyTransactional[0]),
    promotionalSender: yup
      .string()
      .required(messages.promotionalSender[0])
      .trim()
  });
};

export const Msg91ModalToSchema = () => {
  const messages = {
    authkey: ['Auth key is Required'],
    fromName: ['From Name is Required'],
    ...baseSchema,
    domain: ['Domain is Required']
  };

  return yup.object().shape({
    priorityOrder: yup
      .string()
      .trim()
      .required(messages.priorityOrder[0]),
    authkey: yup
      .string()
      .trim()
      .required(messages.authkey[0]),
    fromName: yup
      .string()
      .trim()
      .required(messages.fromName[0]),
    sender: yup
      .string()
      .trim()
      .required(messages.sender[0]),
    domain: yup
      .string()
      .trim()
      .required(messages.domain[0])
  });
};

export const KarixEmailModalToSchema = () => {
  const messages = {
    ...baseSchema,
    fromEmail: ['Sender Email is Required'],
    userName: ['userName is Required'],
    password: ['password is Required'],
    fromName: ['sender Name is Required']
  };

  return yup.object().shape({
    priorityOrder: yup
      .string()
      .trim()
      .required(messages.priorityOrder[0]),
    userName: yup
      .string()
      .trim()
      .required(messages.userName[0]),
    password: yup
      .string()
      .trim()
      .required(messages.password[0]),
    fromEmail: yup
      .string()
      .trim()
      .required(messages.fromEmail[0])
  });
};

export const WonderMailModalToSchema = () => {
  const messages = {
    ...baseSchema,
    authkey: ['Auth key is Required'],
    fromName: ['From Name is Required'],
    fromEmail: ['From Email is Required']
  };

  return yup.object().shape({
    priorityOrder: yup
      .string()
      .trim()
      .required(messages.priorityOrder[0]),
    fromName: yup
      .string()
      .trim()
      .required(messages.fromName[0]),
    authkey: yup
      .string()
      .trim()
      .required(messages.authkey[0]),
    fromEmail: yup
      .string()
      .trim()
      .required(messages.fromEmail[0])
  });
};

export const baseWhatsappValidationSchema = () => {
  const messages = {
    priorityOrder: ['Prority Order is Required'],
    authkey: ['Token is Required']
  };

  return yup.object().shape({
    priorityOrder: yup
      .string()
      .trim()
      .required(messages.priorityOrder[0]),

    authkey: yup
      .string()
      .required(messages.authkey[0])
      .trim()
  });
};

export const ICSWhatsappValidationSchema = () => {
  const messages = {
    priorityOrder: ['Prority Order is Required'],
    user: ['User Name is Required'],
    pass: ['PassWord is Required'],
    from: ['Registered Mobile No. is Required']
  };

  return yup.object().shape({
    priorityOrder: yup
      .string()
      .trim()
      .required(messages.priorityOrder[0]),
    user: yup.string().required(messages.user[0]),
    pass: yup.string().required(messages.pass[0]),
    from: yup.string().required(messages.from[0])
  });
};

export const ICSRcsValidationSchema = () => {
  const messages = {
    priorityOrder: ['Prority Order is Required'],
    user: ['User Name is Required'],
    pass: ['PassWord is Required']
  };

  return yup.object().shape({
    priorityOrder: yup
      .string()
      .trim()
      .required(messages.priorityOrder[0]),
    user: yup.string().required(messages.user[0]),
    pass: yup.string().required(messages.pass[0])
  });
};

export const ameyoValidationSchema = () => {
  const messages = {
    priorityOrder: ['Prority Order is Required'],
    authkey: ['Token is Required']
  };

  return yup.object().shape({
    priorityOrder: yup
      .string()
      .trim()
      .required(messages.priorityOrder[0]),

    authkey: yup
      .string()
      .required(messages.authkey[0])
      .trim()
  });
};

export const karixWhatsappValidation = () => {
  const messages = {
    priorityOrder: ['Prority Order is Required'],
    authkey: ['Token is Required'],
    from: ['Registered mobile no is required'],
    sender: ['Sender is required']
  };

  return yup.object().shape({
    priorityOrder: yup
      .string()
      .trim()
      .required(messages.priorityOrder[0]),

    authkey: yup
      .string()
      .required(messages.authkey[0])
      .trim(),
    from: yup
      .string()
      .trim()
      .required(messages.from[0]),

    sender: yup
      .string()
      .required(messages.sender[0])
      .trim()
  });
};
export const amazonModalToSchema = () => {
  const messages = {
    priorityOrder: ['Prority Order is Required'],
    sender: ['Sender is Required'],
    awsAccessKeyId: ['AWS AccessKeyId is Required'],
    awsSecretAccessKey: ['AWS SecretAccessKey is Required'],
    awsRegion: ['AWS Region is Required']
  };

  return yup.object().shape({
    priorityOrder: yup
      .string()
      .trim()
      .required(messages.priorityOrder[0]),
    sender: yup
      .string()
      .trim()
      .required(messages.sender[0]),
    awsAccessKeyId: yup
      .string()
      .trim()
      .required(messages.awsAccessKeyId[0]),
    awsSecretAccessKey: yup
      .string()
      .trim()
      .required(messages.awsSecretAccessKey[0]),
    awsRegion: yup
      .string()
      .required(messages.awsRegion[0])
      .trim()
  });
};

export const commcryptValidation = () => {
  const messages = {
    priorityOrder: ['Prority Order is Required'],
    user: ['User is Required'],
    password: ['Password is Required'],
    sender: ['Sender is Required'],
    country: ['Country is Required'],
    entityId: ['Entity Id is Required'],
    clientsmsid: ['Client SMS ID is Required']
  };

  return yup.object().shape({
    priorityOrder: yup
      .string()
      .trim()
      .required(messages.priorityOrder[0]),
    user: yup
      .string()
      .trim()
      .required(messages.user[0]),
    password: yup
      .string()
      .trim()
      .required(messages.password[0]),
    sender: yup
      .string()
      .trim()
      .required(messages.sender[0]),
    country: yup
      .string()
      .required(messages.country[0])
      .trim(),
    clientsmsid: yup
      .string()
      .trim()
      .required(messages.clientsmsid[0]),
    entityId: yup
      .string()
      .required(messages.entityId[0])
      .trim()
  });
};
