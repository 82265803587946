import React, { FC, useContext, useState } from 'react';
import {
  FlipbookLayout,
  PageType
} from '../../../../models/FlipbookV2/FlipbookV2Layout.model';
import { FlipbookPage } from '../../PageGenerators/common';
import FlipbookPageDimensions from '../../Utiles/FlipbookPageDimensions';
import FlipbookPageGradient from '../../Utiles/FlipbookPageGradient';
import MainPage from './MainPage';
import OverFlowPage from './OverFlowPage';
import { FlipbookContext } from '../../Hooks/FlipbookContext';
import isEmpty from 'lodash/isEmpty';

interface FlipbookPagesProps {
  isEditMode: boolean;
  isCustomerView: boolean;
  flipbookPages: FlipbookLayout[];
  hasPermission: (permission: string) => boolean;
}

const FlipbookPages: FC<FlipbookPagesProps> = ({
  isEditMode,
  isCustomerView,
  flipbookPages,
  hasPermission
}) => {
  // TODO: Change it fetch from flipbook context
  const [editPanelOccupiedKey, setEditPanelOccupiedKey] = useState('');
  const flipbookContext = useContext(FlipbookContext);
  const backgroundImageURL = {
    odd: flipbookContext.flipbookContext?.oddBackgroundUrl,
    even: flipbookContext.flipbookContext?.evenBackgroundUrl
  };
  const isEmptyPagesHided = flipbookContext.flipbookContext?.isEmptyPagesHided;
  const isEmptyPage = (page: FlipbookLayout) => {
    return isEmpty(page.flipbookLayoutTile);
  };
  return (
    <>
      {flipbookPages.map((page: FlipbookLayout, index: number) => {
        if (!isEditMode && isEmptyPagesHided && isEmptyPage(page)) {
          return null;
        }
        return (
          <FlipbookPage
            key={index}
            width={FlipbookPageDimensions.getFlipbookWidth()}
            height={FlipbookPageDimensions.getFlipbookHeight()}
            style={{
              backgroundImage: `url("${FlipbookPageGradient.getBackgroundForPage(
                index + 2,
                backgroundImageURL
              )}")`
            }}
          >
            {page.pageType === PageType.MAIN && (
              <MainPage
                editPanelOccupiedKey={editPanelOccupiedKey}
                setEditPanelOccupiedKey={setEditPanelOccupiedKey}
                page={page}
                isEditMode={isEditMode}
                isCustomerView={isCustomerView}
                hasPermission={hasPermission}
              />
            )}
            {page.pageType === PageType.OVERFLOW && (
              <OverFlowPage
                page={page}
                isEditMode={isEditMode}
                setEditPanelOccupiedKey={setEditPanelOccupiedKey}
                editPanelOccupiedKey={editPanelOccupiedKey}
                isCustomerView={isCustomerView}
                hasPermission={hasPermission}
              />
            )}
            <div
              className={'page-effect'}
              style={{
                backgroundImage: FlipbookPageGradient.getGradientForPage(
                  index + 2
                )
              }}
            />
          </FlipbookPage>
        );
      })}
    </>
  );
};

export default FlipbookPages;
