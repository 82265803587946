import React, { FC, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import FlipbookContainer from './FlipbookContainer';
import flipbookV2Service from '../../services/Flipbook/flipbookV2.service';
import { getStores, getStoresByToken } from '../../services/store.service';

import { CircularProgress } from '@material-ui/core';
import { BusinessUnitContext } from '../OfferDefinition/BusinessUnitContext';
import { UserContext } from '../../hooks/UserContext';
import { FlipbookConfigData, FlipbookContext } from './Hooks/FlipbookContext';
import _, { find, isEmpty, isEqual, isNull, isUndefined } from 'lodash';
// import {flipbook_permissions} from './permission.js';
import { Business, User } from '../../models/User.model';
import useCurrentUser from '../../hooks/useCurrentUser';
import { getCurrentUserByToken } from '../../services/user.service';
import queryString from 'query-string';
import Snackbar from '@material-ui/core/Snackbar';
import get from 'lodash/get';
import { FlipbookStateContext } from './Hooks/FlipbookStateContext';
import jwt from 'jsonwebtoken';
import { FlipbookProductsContext } from './Hooks/FlipbookProductsContext';
import {
  getFlipbookTrackingId,
  isEcomUserLoggedIn,
  setFlipbookTrackingId
} from './Utiles/FlipbookUtiles';
import flipbookV2EcomService from '../../services/Flipbook/flipbookV2Ecom.service';
import moment from 'moment';
import {
  FlipbookEventContext,
  ProductClickEvent
} from './Hooks/FlipbookEventContext';
import UAParser from 'ua-parser-js';

const FlipbookView: FC<{
  flipbookIdOrToken: any;
  isCustomerView: boolean;
  isEditMode: boolean;
  storeDetails: any;
  setCurrentStore: any;
  currentStore: any;
  hasPermission: (permission: string) => boolean;
}> = ({
  flipbookIdOrToken,
  isCustomerView,
  storeDetails,
  setCurrentStore,
  currentStore,
  hasPermission
}) => (
  <FlipbookContainer
    flipbookIdOrToken={flipbookIdOrToken}
    isCustomerView={isCustomerView}
    isEditMode={false}
    storeDetails={storeDetails}
    setCurrentStore={setCurrentStore}
    currentStore={currentStore}
    hasPermission={hasPermission}
  />
);

const FlipbookEdit: FC<{
  flipbookIdOrToken: any;
  storeDetails: any;
  setCurrentStore: any;
  currentStore: any;
  hasPermission: (permission: string) => void;
}> = ({
  flipbookIdOrToken,
  storeDetails,
  setCurrentStore,
  currentStore,
  hasPermission
}) => {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const handleClose = () => {
    setOpenSnackbar(false);
  };
  return (
    <div style={{ height: '100%' }}>
      <FlipbookContainer
        flipbookIdOrToken={flipbookIdOrToken}
        isCustomerView={false}
        isEditMode={true}
        storeDetails={storeDetails}
        setCurrentStore={setCurrentStore}
        currentStore={currentStore}
        hasPermission={hasPermission}
      />
      <Snackbar
        open={openSnackbar}
        onClose={handleClose}
        message={snackbarMessage}
        autoHideDuration={1500}
      />
    </div>
  );
};

export const FlipbookDemo: FC = () => {
  const { mode, flipbookIdOrToken } = useParams();
  const user = useCurrentUser();
  const [flipbookContext, setFlipbookContext] = useState<
    FlipbookConfigData | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [storeDetails, setStoreDetails] = useState([]);
  const [userInfo, setUserInfo] = useState<User>(new User());
  const [isStoreLoadedFromQuery, setIsStoreLoadedFromQuery] = useState<boolean>(
    false
  );
  const [isLandingEventTriggered, setIsLandingEventTriggered] = useState<
    boolean
  >(false);
  const [productClickEvents, setProductClickEvents] = useState<
    ProductClickEvent[]
  >([]);
  const isEditMode = mode === 'edit';
  const isCustomerView = mode === 'customer-view';
  const ComponentToView = isEditMode ? FlipbookEdit : FlipbookView;
  const [currentStore, setCurrentStore] = useState<{} | undefined>();
  const userStore = _.head(_.get(user, 'tenant_stores'));
  const [currentUserPosition, setCurrentUserPosition] = useState<any>({
    latitude: null,
    longitude: null
  });
  const query: any = queryString.parse(window.location.search);
  const [business, setBusiness] = useState(new Business());
  const [selectedBusinessId, setSelectedBusinessId] = useState<
    undefined | number
  >(undefined);
  const [skus, setSkus] = useState<string[]>([]);
  const [products, setProducts] = useState<any>([]);
  const [flipbookPages, setFlipbookPages] = useState<any>([]);
  const [deviceInfo, setDeviceInfo] = useState({
    os: '',
    browser: '',
    device: '',
    isDefined: false
  });

  useEffect(() => {
    if (deviceInfo.isDefined) {
      return;
    }
    const parser = new UAParser(navigator.userAgent);
    const result = parser.getResult();
    setDeviceInfo({
      os: result.os.name || 'DEFAULT',
      browser: result.browser.name || 'DEFAULT',
      device: result.device.type || 'Desktop',
      isDefined: true
    });
    // eslint-disable-next-line
  }, [deviceInfo.isDefined]);

  useEffect(() => {
    if (isCustomerView) {
      const token = window.location.pathname.split('/')[3];
      const tokenBu = get(jwt.decode(token), 'businessUnitId', 0);
      setSelectedBusinessId(tokenBu);
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const paramsBuId = Number(urlParams.get('bu') || 0);
      setSelectedBusinessId(paramsBuId);
    }
  }, [isCustomerView]);
  const permissionCheckToEnable = (permission: string) => {
    const tenant = _.get(user, 'tenant');
    const isLegacy = _.isEmpty(_.get(tenant, 'casa_product_plans'));
    const productPlans = _.get(tenant, 'casa_product_plans', []);
    const planPermissions = _.flatMap(productPlans, 'features');

    return isLegacy || _.includes(planPermissions, permission);
  };

  const postLandingEvent = useCallback(
    (latitude: string, longitude: string, store: string) => {
      const trackingIdPayload = getFlipbookTrackingId();
      if (
        trackingIdPayload.isLandingEventAvailable &&
        trackingIdPayload.trackingId
      ) {
        setIsLandingEventTriggered(true);
        const businessName = get(business, 'name', '');
        const queryJson = {
          bu: selectedBusinessId,
          storeCode: store,
          // eslint-disable-next-line
          bu_name: businessName,
          vendorName: _.get(user, 'tenant.esKey')
        };
        let payload: any = {
          trackingId: trackingIdPayload.trackingId,
          latitude: latitude,
          longitude: longitude,
          time: moment(),
          storeName: store,
          productSku: '',
          isDirectSku: false,
          browser: deviceInfo.browser,
          device: deviceInfo.device,
          operatingSystem: deviceInfo.os
        };
        if (!isEcomUserLoggedIn()) {
          const accessToken = get(query, 'dataToken', '');
          const contactNumber = get(
            jwt.decode(accessToken),
            'contactNumber',
            undefined
          );
          if (!isUndefined(contactNumber)) {
            payload = {
              ...payload,
              mobile: contactNumber
            };
          }
        }
        flipbookV2EcomService
          .postFlipbookLandingEvent(payload, queryJson)
          .then(res => {
            setFlipbookTrackingId(trackingIdPayload.trackingId);
          })
          .catch(err => {
            setIsLandingEventTriggered(false);
          });
      }
    },
    [
      business,
      selectedBusinessId,
      user,
      deviceInfo.browser,
      deviceInfo.device,
      deviceInfo.os,
      query
    ]
  );
  const addProductClickEvent = useCallback(
    (event: ProductClickEvent) => {
      productClickEvents.push(event);
      setProductClickEvents(productClickEvents);
    },
    [productClickEvents]
  );
  const hasAlreadyProductClickTriggered = useCallback(
    (event: ProductClickEvent): boolean => {
      const existingEvent = find(productClickEvents, pce => {
        return (
          pce.trackingId === event?.trackingId &&
          pce.productSku === event.productSku &&
          pce.clickInteraction.interactionCreatedTime ===
            event.clickInteraction.interactionCreatedTime
        );
      });
      return !isUndefined(existingEvent);
    },
    [productClickEvents]
  );

  const postProductClickEvent = useCallback(
    (payload: any) => {
      const trackingIdPayload = getFlipbookTrackingId();
      if (
        !trackingIdPayload.isLandingEventAvailable &&
        trackingIdPayload.trackingId
      ) {
        const currentStoreCode = get(currentStore, 'store', '');
        const businessName = get(business, 'name', '');
        const queryJson = {
          bu: selectedBusinessId,
          storeCode: currentStoreCode.toLowerCase(),
          // eslint-disable-next-line
          bu_name: businessName,
          vendorName: _.get(user, 'tenant.esKey')
        };
        let eventPayload: any = {
          trackingId: trackingIdPayload.trackingId,
          storeName: currentStoreCode.toLowerCase(),
          productSku: get(payload, 'productSku'),
          isDirectSku: true,
          productFieldName: '',
          productFieldValue: '',
          previousProductScrollCount: 0,
          scrollDepth: 0,
          pageContext: get(payload, 'pageContext'),
          searchTerm: get(payload, 'searchTerm'),
          discountAmount: get(payload, 'discountAmount'),
          price: get(payload, 'price'),
          netAmount: get(payload, 'netAmount'),
          campaign: '',
          browser: deviceInfo.browser,
          device: deviceInfo.device,
          operatingSystem: deviceInfo.os,
          clickInteraction: {
            interactionElementType: get(payload, 'elementType'),
            interactionElementValue: get(payload, 'elementValue'),
            interactionCreatedTime: get(payload, 'interactionTime')
          }
        };
        if (hasAlreadyProductClickTriggered(eventPayload)) {
          return;
        }
        addProductClickEvent(eventPayload);
        if (!isEcomUserLoggedIn()) {
          const accessToken = get(query, 'dataToken', '');
          const contactNumber = get(
            jwt.decode(accessToken),
            'contactNumber',
            undefined
          );
          if (!isUndefined(contactNumber)) {
            eventPayload = {
              ...eventPayload,
              mobile: contactNumber
            };
          }
        }
        flipbookV2EcomService
          .postFlipbookProductClickEvent(eventPayload, queryJson)
          .then(res => {
            console.log('res');
          })
          .catch(err => {
            console.log('err');
          });
      }
    },
    [
      addProductClickEvent,
      business,
      currentStore,
      deviceInfo.browser,
      deviceInfo.device,
      deviceInfo.os,
      hasAlreadyProductClickTriggered,
      query,
      selectedBusinessId,
      user
    ]
  );

  useEffect(() => {
    if (
      !isNull(currentUserPosition.latitude) ||
      !isNull(currentUserPosition.longitude)
    ) {
      return;
    }
    navigator.geolocation.getCurrentPosition(
      function(position) {
        const { latitude, longitude } = position.coords;
        setCurrentUserPosition({
          latitude: latitude.toString(),
          longitude: longitude.toString()
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {
        setCurrentUserPosition({
          latitude: '',
          longitude: ''
        });
      },
      { enableHighAccuracy: true }
    );
  }, [currentUserPosition.latitude, currentUserPosition.longitude]);

  useEffect(() => {
    if (!isUndefined(flipbookContext)) {
      return;
    }
    if (isCustomerView) {
      flipbookV2Service
        .getFlipbookByToken(flipbookIdOrToken)
        .then((response: any) => {
          const status: string = get(response, 'status', '');
          if (status === 'success') {
            const flipbookData: FlipbookConfigData = response.data;
            flipbookData.isCustomerView = isCustomerView;
            if (isCustomerView) {
              flipbookData.flipbookToken = flipbookIdOrToken;
            }
            setFlipbookContext(flipbookData);
          }
        });
    } else {
      flipbookV2Service
        .getFlipbookById(flipbookIdOrToken)
        .then((response: any) => {
          const status: string = get(response, 'status', '');
          if (status === 'success') {
            const flipbookData: FlipbookConfigData = response.data;
            flipbookData.isCustomerView = isCustomerView;
            if (isCustomerView) {
              flipbookData.flipbookToken = flipbookIdOrToken;
            }
            setFlipbookContext(flipbookData);
          }
        });
    }
  }, [flipbookIdOrToken, flipbookContext, isCustomerView, postLandingEvent]);
  const updateFlipbook = useCallback(() => {
    setFlipbookContext(undefined);
  }, []);
  useEffect(() => {
    if (!isCustomerView && !isEmpty(user.getBusinesses())) {
      setBusiness(
        _(user.getBusinesses()).find(bu => bu.id === selectedBusinessId) ||
          new Business()
      );
      setUserInfo(user);
    } else if (isCustomerView) {
      getCurrentUserByToken(flipbookIdOrToken).then(user => {
        const brand = _.head(_.get(user, 'brands', []));
        const businesses = _.get(brand, 'businesses', []);
        const token = window.location.pathname.split('/')[3];
        const tokenBu = get(jwt.decode(token), 'businessUnitId', 0);
        setUserInfo(user);
        setBusiness(
          find(businesses, bu => bu.id === tokenBu) || new Business()
        );
      });
    }
  }, [flipbookIdOrToken, isCustomerView, user, selectedBusinessId]);

  useEffect(() => {
    const trackingIdPayload = getFlipbookTrackingId();
    if (
      isCustomerView &&
      !_.isEmpty(flipbookContext) &&
      !_.isEmpty(currentStore) &&
      trackingIdPayload.isLandingEventAvailable &&
      !_.isEqual(user.id, 0) &&
      !isLandingEventTriggered &&
      !isNull(currentUserPosition.latitude) &&
      !isNull(currentUserPosition.longitude) &&
      deviceInfo.isDefined &&
      !isEqual(business.name, '0')
    ) {
      const currentStoreCode = get(currentStore, 'store', '');
      postLandingEvent(
        currentUserPosition.latitude,
        currentUserPosition.longitude,
        currentStoreCode.toLowerCase()
      );
    }
  }, [
    currentStore,
    flipbookContext,
    isCustomerView,
    postLandingEvent,
    user,
    user.id,
    isLandingEventTriggered,
    currentUserPosition.latitude,
    currentUserPosition.longitude,
    deviceInfo.isDefined,
    business.name
  ]);

  useEffect(() => {
    if (isCustomerView && !_.isEmpty(flipbookContext)) {
      getStoresByToken(flipbookIdOrToken).then((storeDetails: any) => {
        setStoreDetails(storeDetails);
        const storeFromQueryParams = query.store || '';
        let updatedStore = _.find(storeDetails, {
          id: _.get(flipbookContext, 'tenantStoreId')
        });
        if (!_.isEmpty(storeFromQueryParams)) {
          updatedStore = _.find(storeDetails, store => {
            return (
              store.store.toLowerCase() === storeFromQueryParams.toLowerCase()
            );
          });
        }
        setCurrentStore(updatedStore);
        setIsStoreLoadedFromQuery(true);
        setIsLoading(false);
      });
    }
  }, [flipbookContext, flipbookIdOrToken, isCustomerView, query.store]);

  useEffect(() => {
    const storeId =
      _.get(flipbookContext, 'tenantStoreId') || _.get(userStore, 'id', '');
    if (_.get(business, 'id') !== 0 && !isCustomerView) {
      getStores(business).then((storeDetails: any) => {
        setStoreDetails(storeDetails);
        const updatedStore = _.find(storeDetails, {
          id: storeId
        });
        setCurrentStore(updatedStore);
        setIsLoading(false);
      });
    }
    setIsLoading(false);
  }, [business, flipbookContext, isCustomerView, userStore]);

  return (
    <BusinessUnitContext.Provider value={{ business, setBusiness }}>
      <UserContext.Provider value={{ userInfo, setUserInfo }}>
        <FlipbookEventContext.Provider
          value={{
            isLandingEventTriggered,
            setIsLandingEventTriggered,
            productClickEvents,
            addProductClickEvent,
            postProductClickEvent,
            hasAlreadyProductClickTriggered
          }}
        >
          <FlipbookContext.Provider
            value={{
              flipbookContext,
              setFlipbookContext,
              updateFlipbook
            }}
          >
            <FlipbookStateContext.Provider value={{ isStoreLoadedFromQuery }}>
              <FlipbookProductsContext.Provider
                value={{
                  skus: skus,
                  setSkus: setSkus,
                  products: products,
                  setProducts: setProducts,
                  flipbookPages: flipbookPages,
                  setFlipbookPages: setFlipbookPages
                }}
              >
                <div className={'flipbook-main-container'}>
                  {isLoading && (
                    <div className={'flipbook-loader'}>
                      <CircularProgress
                        color="inherit"
                        variant="indeterminate"
                        size={60}
                        thickness={5}
                      />
                    </div>
                  )}
                  {!isLoading && !isEmpty(flipbookContext) && (
                    <ComponentToView
                      flipbookIdOrToken={flipbookIdOrToken}
                      isCustomerView={isCustomerView}
                      isEditMode={isEditMode}
                      storeDetails={storeDetails}
                      setCurrentStore={setCurrentStore}
                      currentStore={currentStore}
                      hasPermission={permissionCheckToEnable}
                    />
                  )}
                </div>
              </FlipbookProductsContext.Provider>
            </FlipbookStateContext.Provider>
          </FlipbookContext.Provider>
        </FlipbookEventContext.Provider>
      </UserContext.Provider>
    </BusinessUnitContext.Provider>
  );
};

export default FlipbookDemo;
