import React from 'react';

import SectionProps from '../../../models/SectionProps';
import DetailsTable from '../../DetailsTable/DetailsTable';
import Heading from '../../Heading/Heading';
import classes from './CustomerInfo.module.css';

const CustomerInfo: React.FC<SectionProps> = ({ data, billData }) => {
  return (
    <div className={classes.container}>
      <Heading heading={data.heading || 'Customer Details'} />
      <DetailsTable
        fields={data.fields}
        data={billData}
        isKeyBold={true}
        isValueBold={false}
        alternateAlign={false}
      />
    </div>
  );
};
export default CustomerInfo;
