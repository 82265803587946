/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-key */
import './index.scss';

import { Grid } from '@material-ui/core';
import * as React from 'react';

import { generalSetting } from '../../constants/generalSetting';
import { ParentLink } from '../../utils/ParentLink';

const GeneralSetting = () => {
  const addUrlParams = link => {
    const urlParams = new URLSearchParams(window.location.search);
    return `${link}?bu=${urlParams.get('bu')}`;
  };
  return (
    <Grid container spacing={2} style={{ paddingTop: 20 }}>
      {generalSetting.map(data => {
        return (
          <Grid key={data.id} item xs={4} lg={4} className={'gridClass'}>
            <img src={data.imgSrc} alt="icon" />
            <div className="options">
              {data.external ? (
                <a
                  href={addUrlParams(`${data.pathName}`)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={'createOfferLink'}
                >
                  {data.title}
                </a>
              ) : (
                <ParentLink
                  className={'createOfferLink'}
                  to={addUrlParams(`${data.pathName}`)}
                >
                  {data.title}
                </ParentLink>
              )}
              <p className={'listParagaraph'}>{data.subtitle}</p>
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default GeneralSetting;
