export class Contact {
  public mobile = '';
  public email = '';
}

export class Customer {
  public id = '';
  public concat: Contact = new Contact();
  public address = {};
}
