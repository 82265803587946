import { FlipbookLayoutTile } from '../../models/FlipbookV2/FlipbookV2LayoutTile.model';
import config from '../../config';

function createFlipbookLayoutTile(flipbookLayoutTile: FlipbookLayoutTile) {
  return fetch(`${config.ruleServerUrl}/users/flipbook-layout-tile-v2/create`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify(flipbookLayoutTile)
  }).then(r => r.json());
}

function updateFlipbookLayoutTile(flipbookLayoutTile: FlipbookLayoutTile) {
  return fetch(
    `${config.ruleServerUrl}/users/flipbook-layout-tile-v2/${flipbookLayoutTile.id}`,
    {
      credentials: 'include',
      method: 'PUT',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(flipbookLayoutTile)
    }
  ).then(r => r.json());
}

function getFlipbookLayoutTile(layoutId: number, tileId: number) {
  return fetch(
    `${config.ruleServerUrl}/users/flipbook-layout-tile-v2/${layoutId}/${tileId}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
}

function deleteFlipbookLayoutTile(layoutId: number) {
  return fetch(
    `${config.ruleServerUrl}/users/flipbook-layout-tile-v2/all-layout-tiles/${layoutId}`,
    {
      credentials: 'include',
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
}

function deleteFlipbookLayoutTileByTileId(tileId: number) {
  return fetch(
    `${config.ruleServerUrl}/users/flipbook-layout-tile-v2/${tileId}`,
    {
      credentials: 'include',
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
}

export default {
  createFlipbookLayoutTile,
  updateFlipbookLayoutTile,
  deleteFlipbookLayoutTile,
  getFlipbookLayoutTile,
  deleteFlipbookLayoutTileByTileId
};
