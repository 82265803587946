import * as yup from 'yup';

export const adHocPointsToSchema = () => {
  const messages = {
    newPassword: ['New password is Required'],
    confirmNewPassword: ['Confirm new password is Required']
  };

  return yup.object().shape({
    newPassword: yup
      .string()
      .trim()
      .min(5, 'More than 5 Characters')
      .required(messages.newPassword[0]),

    confirmNewPassword: yup
      .string()
      .trim()
      .min(5, 'More than 5 Characters')
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
      .required(messages.confirmNewPassword[0])
  });
};
