import moment from 'moment-timezone';
import _ from 'lodash';
import { LAYOUT } from '../components/Labeling/constants';

const getAsStandardLengths = lengthValue =>
  typeof lengthValue !== 'number' &&
  (lengthValue.endsWith('%') || lengthValue.endsWith('px'))
    ? lengthValue
    : `${lengthValue}px`;

export const formatDateFromTimestamp = (value, format = 'D MMM YYYY, LT') => {
  return moment(value).format(format);
};

export const translateDateToDifferentFormat = (value, format) =>
  moment(value)
    .tz('Asia/Kolkata')
    .format(format);

export const formatReadableQuantity = value => {
  const nf = Intl.NumberFormat();
  return nf.format(value);
};
export const truncatedNumber = function(number, digits) {
  number = Math.ceil(parseFloat(number)).toString();
  if (number.length > digits) return 'NA';
  return formatReadableQuantity(number);
};

export const truncatedNumberFloat = function(number, digits) {
  number = number.toString();
  if (number.length > digits) return 'NA';
  return formatReadableQuantity(number);
};
export const truncateString = function(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};
/*export const getWarrantyData = function(warrantyText) {
  if (warrantyText) {
    const [number, term] = warrantyText.split(' ');
    return [number, term.toUpperCase()[0] === 'Y' ? 'YRS' : 'MNTS'];
  }
  return ['', ''];
};*/

export const roundOffEffectivePrice = function(effectivePrice) {
  if (
    effectivePrice != null &&
    effectivePrice.length > 0 &&
    !isNaN(parseFloat(effectivePrice))
  ) {
    const number = parseFloat(effectivePrice);
    if (number > 100 && number <= 9999999) {
      return Math.ceil(number).toString();
    } else if (number > 9999999) {
      return '9999999';
    } else if (number > 0 && number < 100) {
      return number.toFixed(2).toString();
    }
  }
  return '';
};

export const getSplitDescription = function(description, lineCount) {
  if (_.isEmpty(description)) {
    return [];
  }
  return description.split('|').splice(0, lineCount);
};
export const transformLayoutBasedOnPrice = dataWithImages => {
  return _.map(dataWithImages, labelData => {
    if (_.isEqual(_.get(labelData, 'layout'), LAYOUT.WIREBASKET)) {
      return _.get(labelData, 'product.mop').length > 4 ||
        !_.isEqual(_.get(labelData, 'version'), 'V1')
        ? {
            ...labelData,
            layout: LAYOUT.WIREBASKET
          }
        : {
            ...labelData,
            layout: LAYOUT.WIREBASKETFOURDIGIT
          };
    } else if (_.isEqual(_.get(labelData, 'layout'), LAYOUT.PEGHOOK)) {
      return _.get(labelData, 'product.mop').length < 3 ||
        !_.isEqual(_.get(labelData, 'version'), 'V1')
        ? {
            ...labelData,
            layout: LAYOUT.PEGHOOK
          }
        : {
            ...labelData,
            layout: LAYOUT.PEGHOOKFOURDIGIT
          };
    } else {
      return labelData;
    }
  });
};

export default getAsStandardLengths;
