import {
  Box,
  capitalize,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  withStyles
} from '@material-ui/core';
import { values } from 'lodash';
import React, { FC, useLayoutEffect, useState } from 'react';

import LoadingButton from '../../../../shared/components/LoadingButton/LoadingButton';
import TableBodyLoader from '../../../../shared/components/TableBodyLoader/TableBodyLoader';
import TableHeader from '../../../Campaign/shared/components/TableHeader/TableHeader';
import TenantField from '../../models/TenantField';
import styles from './TenantFieldForm.module.scss';

const CustomSwitch = withStyles({
  switchBase: {
    color: '#A8A8A8',
    '&$checked': {
      color: '#F58974'
    },
    '&$checked + $track': {
      backgroundColor: '#f5aa9b'
    }
  },
  checked: {},
  track: {}
})(Switch);

interface TenantFieldFormProps {
  tenantFields: TenantField[];
  totalTenantFields: number;
  limit: number;
  offset: number;
  onSubmit: (fields: TenantField[]) => Promise<void>;
  onOffsetChange: (offset: number) => void;
  onLimitChange: (limit: number) => void;
  loading: boolean;
}

const TenantFieldForm: FC<TenantFieldFormProps> = props => {
  const {
    tenantFields,
    onSubmit,
    limit,
    offset,
    onLimitChange,
    onOffsetChange,
    totalTenantFields,
    loading: isFetching
  } = props;

  const [tenantFieldMap, setTenantFieldMap] = useState<{
    [key: string]: TenantField;
  }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useLayoutEffect(() => {
    setTenantFieldMap(
      tenantFields.reduce((acc, field) => {
        acc[field.casaFieldName] = field;
        return acc;
      }, {} as { [key: string]: TenantField })
    );
  }, [tenantFields]);

  if (!isFetching && tenantFields.length === 0) return null;

  const handleFieldChange = (
    casaFieldName: string,
    fieldName: keyof TenantField
  ) => (value: any): void => {
    setTenantFieldMap(tenantFieldMap => ({
      ...tenantFieldMap,
      [casaFieldName]: { ...tenantFieldMap[casaFieldName], [fieldName]: value }
    }));
  };

  const handleOnSubmit = (event: any): void => {
    event.preventDefault();
    setIsSubmitting(true);
    onSubmit(values(tenantFieldMap)).finally(() => setIsSubmitting(false));
  };

  return (
    <Box component="form" onSubmit={handleOnSubmit} id={styles.tenantFieldForm}>
      <TableContainer>
        <Table>
          <TableHeader
            headers={[
              'Field Name',
              'Display Name',
              'Show / Hide (In Dashboard filters)'
            ]}
          />
          {isFetching ? (
            <TableBodyLoader rowsPerPage={limit} colSpan={3} />
          ) : (
            <TableBody>
              {values(tenantFieldMap).map((tenantField, index) => (
                <TableRow key={tenantField.id || index}>
                  <TableCell>{capitalize(tenantField.casaFieldName)}</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      fullWidth
                      style={{ maxWidth: 350 }}
                      label="Display Name"
                      value={tenantField.displayLabel}
                      onChange={({ target }): void =>
                        handleFieldChange(
                          tenantField.casaFieldName,
                          'displayLabel'
                        )(target.value)
                      }
                      className={styles.formControl}
                      required
                    />
                  </TableCell>
                  <TableCell width={300}>
                    <CustomSwitch
                      checked={tenantField.show}
                      onChange={({ target }): void =>
                        handleFieldChange(
                          tenantField.casaFieldName,
                          'show'
                        )(target.checked)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {!isFetching && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalTenantFields}
          rowsPerPage={limit}
          page={offset}
          onPageChange={(_, page): void => onOffsetChange(page)}
          onRowsPerPageChange={(event): void =>
            onLimitChange(+event.target.value)
          }
        />
      )}
      {!isFetching && (
        <LoadingButton
          color="primary"
          disableElevation
          loading={isSubmitting}
          variant="contained"
          type="submit"
        >
          Submit
        </LoadingButton>
      )}
    </Box>
  );
};
export default TenantFieldForm;
