import {
  Box,
  Card,
  CardContent,
  Typography,
  TypographyVariant
} from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';

import styles from './CampaignCard.module.scss';

interface CampaignCardProps {
  label: string;
  value: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  cardContentClassName?: string;
  labelTypographyVariant?: TypographyVariant;
  valueTypographyVariant?: TypographyVariant;
}

const CampaignCard: FC<CampaignCardProps> = ({
  label,
  value,
  className,
  icon,
  cardContentClassName,
  labelTypographyVariant = 'body2',
  valueTypographyVariant = 'h4'
}) => {
  return (
    <Card id={styles.card} className={clsx(className)}>
      <CardContent className={clsx(styles.cardContent, cardContentClassName)}>
        {icon && icon}
        <Box>
          <Typography
            className={clsx(styles.label)}
            variant={labelTypographyVariant}
          >
            {label}
          </Typography>
          <Typography
            variant={valueTypographyVariant}
            className={clsx(styles.value)}
          >
            {value}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CampaignCard;
