import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  CircularProgress,
  createStyles,
  Dialog,
  Snackbar,
  TextField
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import Alert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  dialogTitle: {
    textAlign: 'center'
  },
  dialogHintParagraph: {
    color: '#A8A8A8',
    marginBottom: 0
  },
  selectBar: {
    background: '#FFF',
    minHeight: 50,
    paddingTop: 0,
    paddingBottom: 0
  },
  dialogPaper: {
    padding: '50px 25px',
    alignItems: 'center',
    height: '100%',
    maxHeight: '500px',
    justifyContent: 'space-between'
  },
  closeButton: {
    position: 'absolute',
    top: '8px',
    right: '8px'
  }
});

const useBackdropStyles = makeStyles(theme =>
  createStyles({
    root: {
      color: '#fff',
      position: 'absolute',
      zIndex: theme.zIndex.drawer - 1,
      backgroundColor: 'rgba(222, 222, 222, 0.5)',
      opacity: 0.5
    }
  })
);

const filter = createFilterOptions();

export const AddTagDialog = props => {
  const {
    open,
    onClose,
    numSelectedOffers,
    availableTags,
    selectedTags,
    onSelectedTagsChange,
    onCreateTagClick,
    onSubmit,
    loading,
    errorMessage = '',
    resetErrorMessage
  } = props;
  const classes = useStyles();

  const snackbarOpen = !_.isEmpty(errorMessage);

  const handleSelectChange = (event, newValue) => {
    const uncreatedTag = _.find(newValue, o => !_.isEmpty(o.inputValue));
    if (!_.isUndefined(uncreatedTag)) {
      const tagName = uncreatedTag.inputValue;
      onCreateTagClick(tagName);
    } else {
      onSelectedTagsChange(event, newValue);
    }
  };

  const backdropClasses = useBackdropStyles();

  // uses MUI Autocomplete creatable - https://material-ui.com/components/autocomplete/#creatable
  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper
      }}
      fullWidth
      open={open}
      onClose={onClose}
    >
      <Backdrop
        open={loading}
        style={{ position: 'absolute' }}
        className={backdropClasses.root}
      >
        <CircularProgress />
      </Backdrop>
      <div className={classes.dialogTitle}>
        <h4>Add Tags</h4>
        <p className={classes.dialogHintParagraph}>
          Tags will be added to the {numSelectedOffers} selected offer
          {numSelectedOffers > 1 ? 's' : ''}
        </p>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div style={{ width: '75%' }}>
        <FormControl fullWidth>
          <Autocomplete
            classes={{
              root: classes.root,
              inputRoot: classes.selectBar
            }}
            multiple
            disableCloseOnSelect
            renderInput={params => (
              <TextField
                {...params}
                variant={'outlined'}
                placeholder={'Select tags'}
                size={'small'}
                fullWidth
              />
            )}
            filterSelectedOptions
            options={availableTags}
            getOptionLabel={option => option.name || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const filteredTagNames = _.map(filtered, t => t.name);
              const inputValue = params.inputValue;
              if (
                inputValue !== '' &&
                !_.includes(filteredTagNames, inputValue)
              )
                return [
                  { inputValue, name: `Create "${inputValue}"` },
                  ...filtered
                ];
              return filtered;
            }}
            renderOption={option => option.name}
            value={selectedTags}
            onChange={handleSelectChange}
            limitTags={2}
          />
        </FormControl>
      </div>
      <Button
        color={'primary'}
        variant={'contained'}
        onClick={onSubmit}
        disabled={_.isEmpty(selectedTags)}
      >
        Submit
      </Button>
      <Snackbar
        open={snackbarOpen}
        onClose={resetErrorMessage}
        autoHideDuration={10000}
      >
        <Alert severity={'error'} onClose={resetErrorMessage}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

AddTagDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  numSelectedOffers: PropTypes.number.isRequired,
  availableTags: PropTypes.array.isRequired,
  selectedTags: PropTypes.array.isRequired,
  onSelectedTagsChange: PropTypes.func.isRequired,
  onCreateTagClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  resetErrorMessage: PropTypes.func.isRequired
};
