import config from '../config/index';

const getOfferCategories = () => {
  return fetch(`${config.ruleServerUrl}/users/offer-category`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(res => res.json())
    .then(res => res.data);
};

const createOfferCategory = offerCategory => {
  return fetch(`${config.ruleServerUrl}/users/offer-category`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({ offerCategory }),
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(res => res.json())
    .then(({ data }) => data);
};

export default {
  getOfferCategories,
  createOfferCategory
};
