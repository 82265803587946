import ChannelIcon from '../components/Assets/generalSetting/Channel_icon.svg';
import OtpIcon from '../components/Assets/generalSetting/otpconfig.svg';
import ReportIcon from '../components/Assets/generalSetting/ReportIcon.svg';
import RoleIcon from '../components/Assets/generalSetting/Role_Icon.svg';
import StoreIcon from '../components/Assets/generalSetting/Storeicon.svg';
import UserIcon from '../components/Assets/generalSetting/User_Man_icon.svg';

export const generalSetting = [
  {
    id: 1,
    imgSrc: UserIcon,
    title: 'User Management',
    subtitle: 'Bring in customer queries from various sources',
    pathName: '/user-list'
  },
  {
    id: 2,
    imgSrc: RoleIcon,
    title: 'Role Management',
    subtitle: 'Bring in customer queries from various sources',
    pathName: '/role-config-v1'
  },
  {
    id: 3,
    imgSrc: StoreIcon,
    title: 'Store Management',
    subtitle: 'Bring in customer queries from various sources',
    pathName: '/store-management'
  },
  {
    id: 4,
    imgSrc: ChannelIcon,
    title: 'Channels',
    subtitle: 'Bring in customer queries from various sources',
    pathName: '/channels'
  },
  {
    id: 5,
    imgSrc: OtpIcon,
    title: 'OTP Configuration',
    subtitle: 'Bring in customer queries from various sources',
    pathName: '/otp-configuration'
  },
  {
    id: 7,
    imgSrc: ReportIcon,
    title: 'Live Reports',
    subtitle: 'Bring in customer queries from various sources',
    pathName: '/live-reports'
  },
  {
    id: 8,
    imgSrc: StoreIcon,
    title: 'Customer Migrate',
    subtitle: 'Bring in customer queries from various sources',
    pathName: '/migrate-customers'
  },
  {
    id: 9,
    imgSrc: StoreIcon,
    title: 'Blacklist Management',
    subtitle: 'Bring in customer queries from various sources',
    pathName: '/create-customer-group'
  }
];

export const settingContent = {
  title: 'Settings',
  generalTitle: 'General Settings',
  generalParagraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
  moduleTitle: 'Module Settings',
  moduleParagraph: ' Set up your ticket routing and resolution process'
};

export const moduleSetup = {
  bannerTitle: 'Need to Explore? Check out the Product Guide.',
  bannerSubTitle: 'Get step-by-step instructions to make CASA work for you.',
  moduleTitle: 'Module Setting',
  generalTitle: 'Module Settings'
};
