import React from 'react';

class Post extends React.Component {
  render() {
    const { post } = this.props;
    const { permalink } = post;
    return (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            borderRight: '1px solid black',
            paddingRight: 10,
            minWidth: 120
          }}
        >
          <div>
            <span style={{ fontWeight: 900 }}>Date:</span>
            <div>{post.date}</div>
          </div>
          <div>
            <span style={{ fontWeight: 900 }}>Time:</span>
            <div>{post.time}</div>
          </div>
        </div>
        <div style={{ paddingLeft: 10 }}>
          {post.message}
          <div>
            {permalink && (
              <a href={permalink} target="_blank" rel="noopener noreferrer">
                {permalink}
              </a>
            )}
          </div>
        </div>
      </span>
    );
  }
}

export default Post;
