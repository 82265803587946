import React, { FC, useState } from 'react';
import OfferPromoFilter, { GrouperColumn } from './Filter';
import OfferDownloadTable from './List';
import usePagination from '../../../shared/hooks/usePagination';
import { getOfferPromoReportDownload } from '../../../services/TenantReports/OfferPromoReport.service';
import useCurrentUser from '../../../hooks/useCurrentUser';
import Disabled from './Disabled';
import moment from 'moment';
import { OfferPromoReportFilter } from './FilterType';

const offerInitialState: OfferPromoReportFilter = {
  startDate: moment()
    .subtract(1, 'month')
    .subtract(1, 'day')
    .format('YYYY-MM-DD'),
  endDate: moment()
    .subtract(1, 'day')
    .format('YYYY-MM-DD'),
  grouperColumn: GrouperColumn.Offer,
  businessId: undefined
};

const OfferPromoTenantReport: FC = () => {
  const [refreshView, setRefreshView] = useState<string>('');
  const [filters, setFilters] = useState<OfferPromoReportFilter>(
    offerInitialState
  );
  const user = useCurrentUser();
  const { data, isLoading, page, offset, setPage, setOffset } = usePagination(
    getOfferPromoReportDownload,
    [refreshView],
    filters
  );
  if (!user.hasPermission('offer-promo-tenant-report') && user.isLoggedIn) {
    return <Disabled />;
  }
  return (
    <div>
      <OfferPromoFilter
        setRefreshView={setRefreshView}
        filters={filters}
        setFilters={setFilters}
      />
      <OfferDownloadTable
        reports={data || []}
        isLoading={isLoading}
        page={page}
        offset={offset}
        setPage={setPage}
        setOffset={setOffset}
      />
    </div>
  );
};

export default OfferPromoTenantReport;
