import config from '../config';

const saveMigrateCustomers = (data, businessUnitId) => {
  const url = new URL(
    `${config.ruleServerUrl}/users/migrate-customers/${businessUnitId}`
  );

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({ ...data }),
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(r => r.json())
    .catch(err => {
      return {};
    });
};

export default { saveMigrateCustomers };
