import React, { useState, useEffect } from 'react';
import { range } from 'lodash';
import { CircularProgress } from '@material-ui/core';
import {
  ALLOWED_VERSION,
  LATEST_VERSION,
  LAYOUT,
  PAGETILE
} from '../constants';
import { Text, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import {
  PegHook,
  PegHookV2,
  PegHookH1,
  PegHookFourDigit,
  WireBasket,
  A6Portrait,
  A6PortraitV2,
  A6PortraitH1,
  A6Landscape,
  A6LandscapeV2,
  A6LandscapeV3,
  A6LandscapeH1,
  A4Portrait,
  A4PortraitV2,
  A4PortraitV3,
  A4PortraitH1,
  A4Landscape,
  A4LandscapeV2,
  A4LandscapeH1,
  IntegratedSelf,
  A5Portrait,
  A5PortraitV2,
  A5PortraitH1,
  A5Landscape,
  A5LandscapeV2,
  A5LandscapeH1,
  WireBasketFourDigit,
  WireBasketV2,
  WireBasketH1
} from './layouts';
import { getQRs } from '../../../services/qr.service';
import _ from 'lodash';
import { transformLayoutBasedOnPrice } from '../../../utils';
import { serializePrice } from './utils/PriceCalculation';
import { getUiFilterPayloadById } from '../../../services/uiFilter.service';
import { LABELPRINTING_DATA } from './utils/Constants';
import queryString from 'query-string';

// Create styles
const styles = StyleSheet.create({
  card: {
    top: '5px',
    margin: '6px 50px',
    height: '38mm',
    width: '175mm',
    flexDirection: 'row',
    border: '1 solid #1A1818'
  },
  qrcode: {
    borderRight: '1 solid #1A1818',
    height: '34.9mm',
    width: '34.9mm',
    justifyContent: 'center',
    textAlign: 'center'
  },
  priceDetails: {
    flexDirection: 'row'
  },
  deals: {
    width: '33mm'
  },
  hippoDeals: {
    height: '17mm',
    width: '17mm'
  },
  mrpText: {
    fontSize: '12.58pt',
    fontWeight: 'bold',
    color: 'black'
  },
  mrp: { fontSize: '12', textAlign: 'center', lineHeight: '12px' },
  priceSection: {
    width: '250px',
    borderRight: '1 solid #1A1818'
  },
  amount: {
    flexDirection: 'row'
  },
  rupee: {
    fontSize: '33.9pt',
    width: '20px'
  },
  price: {
    fontSize: '68.8pt',
    fontWeight: 'bold',
    color: 'black',
    top: 0
  },
  descsection: {
    width: '389px',
    flexDirection: 'row',
    borderTop: '1 solid #1A1818'
  },
  inclTax: {
    fontSize: '12',
    textAlign: 'right'
  },
  descSection: {
    borderRight: '1 solid #1A1818',
    width: '290px'
  },
  desc: { fontSize: '7', padding: '2' },
  details: { fontSize: '7', padding: '2' },
  barcode: {
    width: '150px'
  },
  pageTitle: {
    position: 'absolute',
    fontSize: 7,
    top: 2,
    left: 50
  }
});
const INVALID_ID = ['', null, undefined, -1, 0];
const PdfDocument = props => {
  const [count] = useState(localStorage.getItem('count') || 1);
  const rangedCount = range(count);
  const [filteredData, setFilteredData] = useState();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const query = queryString.parse(window.location.search);
  useEffect(() => {
    const id = query.payloadData;
    const offset = query.offset;
    const count = query.count;
    if (!INVALID_ID.includes(id))
      getUiFilterPayloadById(id, LABELPRINTING_DATA, offset, count).then(
        result => {
          const skuData = _.get(result, 'data.payload.LabelSkuList', []);
          setData(skuData);
          setIsLoading(false);
        }
      );
  }, [query.count, query.payloadData, query.offset]);
  const maskVersionsOfLayout = (array, layout) => {
    const allowedVersions = ALLOWED_VERSION[layout];
    const defaultVersion = LATEST_VERSION[layout];
    return _.map(array, element => {
      if (!allowedVersions.includes(element.version)) {
        element.version = defaultVersion;
      }
      return element;
    });
  };
  useEffect(() => {
    if (isLoading) {
      return;
    }
    getQRs(data.map(lab => lab.qrText)).then(result => {
      const urlHash = result.reduce((rv, cv) => {
        rv[cv.text] = cv.url;
        return rv;
      }, {});
      let dataWithImages = data.map(label => {
        return {
          ...label,
          image: urlHash[label.qrText]
        };
      });
      dataWithImages = transformLayoutBasedOnPrice(dataWithImages);
      setFilteredData(_.groupBy(dataWithImages, 'layout'));
    });
  }, [data, isLoading]);

  const getPageTitle = layout => {
    return <Text style={styles.pageTitle}>{PAGETILE[layout]}</Text>;
  };

  if (!filteredData)
    return (
      <div
        style={{
          color: '#F2755F',
          width: '100%',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress color="inherit" />
        <div style={{ paddingTop: '2rem', fontSize: '24px' }}>
          Your labels are getting rendered, please wait...
        </div>
      </div>
    );
  const groupByVersions = (filteredData, layout) => {
    filteredData = maskVersionsOfLayout(filteredData, layout);
    return _.mapValues(_.groupBy(filteredData, 'version'), versions =>
      versions.map(version => _.omit(version, 'version'))
    );
  };
  const getLayoutFromPicker = (labelData, layout, version, format) => {
    const layoutPicker = {
      [LAYOUT.INTEGRATEDSHELF]: {
        NA: {
          V1: (
            <IntegratedSelf
              title={getPageTitle(LAYOUT.INTEGRATEDSHELF)}
              data={labelData}
              version={'V1'}
              count={count}
            />
          ),
          V2: (
            <IntegratedSelf
              title={getPageTitle(LAYOUT.INTEGRATEDSHELF)}
              data={labelData}
              version={'V2'}
              count={count}
            />
          ),
          H1: (
            <IntegratedSelf
              title={getPageTitle(LAYOUT.INTEGRATEDSHELF)}
              data={labelData}
              version={'H1'}
              count={count}
            />
          )
        }
      },
      [LAYOUT.PEGHOOK]: {
        NA: {
          V1: <PegHook data={labelData} count={rangedCount} />,
          V2: <PegHookV2 data={labelData} count={rangedCount} />,
          H1: <PegHookH1 data={labelData} count={rangedCount} />
        }
      },
      [LAYOUT.PEGHOOKFOURDIGIT]: {
        NA: {
          V1: <PegHookFourDigit data={labelData} count={rangedCount} />
        }
      },
      [LAYOUT.WIREBASKETFOURDIGIT]: {
        NA: {
          V1: <WireBasketFourDigit data={labelData} count={rangedCount} />
        }
      },
      [LAYOUT.WIREBASKET]: {
        NA: {
          V1: <WireBasket data={labelData} count={rangedCount} />,
          V2: <WireBasketV2 data={labelData} count={rangedCount} />,
          H1: <WireBasketH1 data={labelData} count={rangedCount} />
        }
      },
      [LAYOUT.A5]: {
        LANDSCAPE: {
          V1: (
            <A5Landscape
              title={getPageTitle(LAYOUT.A5)}
              data={labelData.filter(item => item.orientation === 'LANDSCAPE')}
              count={rangedCount}
            />
          ),
          V2: (
            <A5LandscapeV2
              title={getPageTitle(LAYOUT.A5)}
              data={labelData.filter(item => item.orientation === 'LANDSCAPE')}
              count={rangedCount}
            />
          ),
          H1: (
            <A5LandscapeH1
              title={getPageTitle(LAYOUT.A5)}
              data={labelData.filter(item => item.orientation === 'LANDSCAPE')}
              count={rangedCount}
            />
          )
        },
        PORTRAIT: {
          V1: (
            <A5Portrait
              title={getPageTitle(LAYOUT.A5)}
              data={labelData.filter(item => item.orientation === 'PORTRAIT')}
              count={rangedCount}
            />
          ),
          V2: (
            <A5PortraitV2
              title={getPageTitle(LAYOUT.A5)}
              data={labelData.filter(item => item.orientation === 'PORTRAIT')}
              count={rangedCount}
            />
          ),
          H1: (
            <A5PortraitH1
              title={getPageTitle(LAYOUT.A5)}
              data={labelData.filter(item => item.orientation === 'PORTRAIT')}
              count={rangedCount}
            />
          )
        }
      },
      [LAYOUT.A6]: {
        LANDSCAPE: {
          V1: (
            <A6Landscape
              data={labelData.filter(item => item.orientation === 'LANDSCAPE')}
              count={rangedCount}
            />
          ),
          V2: (
            <A6LandscapeV2
              data={labelData.filter(item => item.orientation === 'LANDSCAPE')}
              count={rangedCount}
            />
          ),
          V3: (
            <A6LandscapeV3
              data={labelData.filter(item => item.orientation === 'LANDSCAPE')}
              count={rangedCount}
            />
          ),
          H1: (
            <A6LandscapeH1
              data={labelData.filter(item => item.orientation === 'LANDSCAPE')}
              count={rangedCount}
            />
          )
        },
        PORTRAIT: {
          V1: (
            <A6Portrait
              data={labelData.filter(item => item.orientation === 'PORTRAIT')}
              count={rangedCount}
            />
          ),
          V2: (
            <A6PortraitV2
              data={labelData.filter(item => item.orientation === 'PORTRAIT')}
              count={rangedCount}
            />
          ),
          H1: (
            <A6PortraitH1
              data={labelData.filter(item => item.orientation === 'PORTRAIT')}
              count={rangedCount}
            />
          )
        }
      },
      [LAYOUT.A4]: {
        LANDSCAPE: {
          V1: (
            <A4Landscape
              data={labelData.filter(item => item.orientation === 'LANDSCAPE')}
              count={rangedCount}
            />
          ),
          V2: (
            <A4LandscapeV2
              data={labelData.filter(item => item.orientation === 'LANDSCAPE')}
              count={rangedCount}
            />
          ),
          H1: (
            <A4LandscapeH1
              data={labelData.filter(item => item.orientation === 'LANDSCAPE')}
              count={rangedCount}
            />
          )
        },
        PORTRAIT: {
          V1: (
            <A4Portrait
              data={labelData.filter(item => item.orientation === 'PORTRAIT')}
              count={rangedCount}
            />
          ),
          V2: (
            <A4PortraitV2
              data={labelData.filter(item => item.orientation === 'PORTRAIT')}
              count={rangedCount}
            />
          ),
          V3: (
            <A4PortraitV3
              data={labelData.filter(item => item.orientation === 'PORTRAIT')}
              count={rangedCount}
            />
          ),
          H1: (
            <A4PortraitH1
              data={labelData.filter(item => item.orientation === 'PORTRAIT')}
              count={rangedCount}
            />
          )
        }
      }
    };
    return layoutPicker[layout][format][version];
  };
  const getVersionSpecificLayout = (filteredData, layout, format) => {
    filteredData = groupByVersions(filteredData, layout);
    return _.map(filteredData, (value, key) => {
      value = serializePrice(value);
      return getLayoutFromPicker(value, layout, key, format);
    });
  };
  return (
    <PDFViewer width="100%" style={{ height: '100vh' }}>
      <Document>
        {_.map(filteredData, (value, key) => {
          if (['A4', 'A5', 'A6'].includes(key)) {
            value = _.groupBy(value, 'orientation');
            return _.map(value, (labelProduct, orientation) => {
              return getVersionSpecificLayout(labelProduct, key, orientation);
            });
          } else {
            return getVersionSpecificLayout(value, key, 'NA');
          }
        })}
      </Document>
    </PDFViewer>
  );
};
export default PdfDocument;
