import config from '../../config';
import formatDate from '../../shared/utils/formatDate';
import OfferReportDownload from '../../models/TenantReports/OfferReportDownload';

export const createOfferReport = ({
  businessId,
  startDate,
  endDate,
  segment
}: any): Promise<{ message: string; jobId: string }> => {
  return fetch(
    `${
      config.ruleServerUrl
    }/users/reports/download/offer_report/${businessId}?startDate=${formatDate(
      startDate,
      'YYYY-MM-DD'
    )}&endDate=${formatDate(endDate, 'YYYY-MM-DD')}&segment=${segment}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
};

export const getOfferReportDownload = ({
  page,
  offset,
  filters
}: {
  page: number;
  offset: number;
  filters?: any;
}): Promise<OfferReportDownload[]> => {
  console.log({ filters });
  return fetch(
    `${config.ruleServerUrl}/users/reports/view/downloads/offer_report?offset=${offset}&page=${page}&columnName=segment&columnValue=${filters}`,
    { credentials: 'include' }
  ).then(res => res.json());
};
