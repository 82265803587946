import React from 'react';
import { Button as MaterialButton, CircularProgress } from '@material-ui/core';
import '../../styles/Button.scss';

const Button = ({ leftIcon, text, onClick, loading, ...rest }) => {
  return (
    <MaterialButton onClick={onClick} {...rest} disabled={loading}>
      {loading ? (
        <>
          <CircularProgress
            color="white"
            style={{ width: '25px', height: '25px' }}
          />
          <span
            style={{
              color: 'white',
              fontWeight: 'bold',
              marginLeft: '12px',
              fontSize: 'large',
              marginTop: '2px'
            }}
          >
            Loading
          </span>
        </>
      ) : (
        <>
          <span className="l-pr1">{leftIcon}</span>
          {text}
        </>
      )}
    </MaterialButton>
  );
};
const GoToPrevious = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="35"
    viewBox="0 0 24 24"
  >
    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

const GoToNext = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="35"
    viewBox="0 0 24 24"
  >
    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);
export { Button, GoToPrevious, GoToNext };
