import React from 'react';
import classes from './JourneyFilters.module.css';

function ResetIcon(props) {
  return (
    <svg
      width="15px"
      height="15px"
      style={{ margin: '3px 0px 0px 5px' }}
      viewBox="0 0 40.5 42.17"
      {...props}
    >
      <path
        d="M39.07 26.66l-3.82-1.15a2 2 0 00-2.43 1 13 13 0 11-6.13-17.03l-2.28 2.28a2 2 0 001.41 3.42H37a2 2 0 002-2V2A2 2 0 0035.58.59l-3 3.05A21 21 0 1021 42.17a20.95 20.95 0 0019.35-12.85 2 2 0 00-1.28-2.66z"
        fill="#f2755f"
      />
    </svg>
  );
}
export default function Reset({ onReset }) {
  return (
    <div className={classes.reset} onClick={onReset}>
      {ResetIcon()}
      <div className={classes.resetText}>Reset</div>
    </div>
  );
}
