import React from 'react';
import { CSVLink } from 'react-csv';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

function ExportActionButton(props) {
  const {
    featureAccess,
    savePermissions,
    exportCsv,
    csvData,
    csvLink,
    value
  } = props;

  return (
    <>
      <div style={{ marginRight: '20px' }}>
        <Button
          size="small"
          color="primary"
          variant="contained"
          disabled={featureAccess.saveButtonAccess}
          onClick={savePermissions}
        >
          Save Permissions
        </Button>
      </div>

      <label>
        <Button
          size="small"
          startIcon={<ArrowDownwardIcon />}
          color="primary"
          variant="contained"
          onClick={exportCsv}
          disabled={featureAccess.otherAccess}
        >
          Export CSV
        </Button>
        <CSVLink
          data={csvData}
          filename={`${value.currentModule}-RoleAssign.csv`}
          className="hidden"
          ref={csvLink}
          target="_blank"
        />
      </label>
    </>
  );
}

export default ExportActionButton;
