/* eslint-disable @typescript-eslint/explicit-function-return-type */
import _ from 'lodash';
import NodeTypes from '../BaseNodeModel/NodeTypes';
import { BaseNodeModel } from '../BaseNodeModel/BaseNodeModel';
import * as PortModels from '../BaseNodeModel/PortModels';

export class ActionNodeModel extends BaseNodeModel {
  constructor(options = {}) {
    super({
      type: NodeTypes.ACTION_NODE,
      ...options
    });
    this.responses = options.responses || [];
    this.unchosenResponses = _.clone(options.responses) || [];
    this.recipient = options.recipient;
    this.rewardType = options.rewardType;
    this.linkType = options.linkType;
    this.messageTemplateData = this.messageTemplateData || [];
    this.nextNodes = [
      NodeTypes.ACTION_NODE,
      NodeTypes.WAIT_NODE,
      NodeTypes.STOP_NODE
    ];
    this.maximumLinks = _.isEmpty(options.responses)
      ? 1
      : options.responses.length;
    this.buildPorts([new PortModels.InPort(), new PortModels.OutPort()]);
  }

  // remove() {
  //   _.forEach(this.ports, port => {
  //     _.forEach(port.getLinks(), link => {
  //       if (link.targetPort.parent.type === NodeTypes.WAIT_NODE) {
  //         link.targetPort.parent.remove();
  //       }
  //
  //       link.remove();
  //     });
  //   });
  //   super.remove();
  // }

  setInputFields(specs, messageTemplateData) {
    this.isDetailsSaved = this.isAllDetailsFilled(specs);
    this.specs = specs;
    this.messageTemplateData = messageTemplateData;
  }

  serialize() {
    return {
      ...super.serialize(),
      responses: this.responses,
      unchosenResponses: this.unchosenResponses,
      recipient: this.recipient,
      rewardType: this.rewardType,
      messageTemplateData: this.messageTemplateData,
      linkType: this.linkType
    };
  }

  deserialize(ob, engine) {
    super.deserialize(ob, engine);
    this.responses = ob.data.responses;
    this.unchosenResponses = ob.data.unchosenResponses;
    this.recipient = ob.data.recipient;
    this.rewardType = ob.data.rewardType;
    this.messageTemplateData = ob.data.messageTemplateData;
    this.linkType = ob.data.linkType;
  }

  updateUnchosenResponses(value, previousValue) {
    if (_.includes(this.unchosenResponses, value)) {
      _.remove(this.unchosenResponses, element => element === value);
    } else if (_.includes(this.responses, value)) {
      this.unchosenResponses.push(value);
    }
    if (
      _.includes(_.flatMap(this.responses), previousValue) &&
      !_.includes(this.unchosenResponses, previousValue)
    ) {
      this.unchosenResponses.push(previousValue);
    }
  }
}
