/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FileUpload from '../FileUpload/FileUpload';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import { Zoom } from '@material-ui/core';
import CustomMenuFormStyles from './CustomMenuForm.module.css';
import { onEditAllowed } from '../../../validation/customMenuName';
import _ from 'lodash';
import Alert from '@material-ui/lab/Alert';

function CustomMenuForm({
  open,
  togglePopUp,
  menuName,
  setMenuName,
  html,
  setHtml,
  fieldName,
  handleCreate,
  handleOnClose,
  isEditing,
  renderDeleteField,
  editMenuName,
  defaultMenuName,
  menu,
  customHtmlMenu,
  convertToMenuName
}) {
  const [isDefaultMenu, setIsDefaultMenu] = useState(false);
  const [isDuplicateMenu, setIsDuplicateMenu] = useState(false);

  const getCloseButtonStyles = () => {
    return {
      color: 'black',
      marginRight: '10px',
      width: '150px'
    };
  };

  const getSaveButtonStyles = () => {
    return {
      backgroundColor: '#F2755F',
      color: 'white',
      marginLeft: '10px',
      width: '150px'
    };
  };

  const handleOnChange = e => {
    setMenuName(onEditAllowed('menuName', e.target.value, menuName));
  };

  const checkIfDuplicatet = e => {
    if (_.includes(_.keys(menu), convertToMenuName(e.target.value))) {
      menu[convertToMenuName(e.target.value)].module === 'HTML'
        ? setIsDuplicateMenu(true)
        : setIsDefaultMenu(true);
    } else {
      setIsDuplicateMenu(false);
      setIsDefaultMenu(false);
    }
  };

  return (
    <Modal open={open} onClose={togglePopUp}>
      <div className={CustomMenuFormStyles.mainContainer}>
        <div className={CustomMenuFormStyles.innerContainer}>
          <h4>
            <b>Add Menu</b>
          </h4>
          <div className={CustomMenuFormStyles.form}>
            <div
              className={CustomMenuFormStyles.line}
              style={{ marginBottom: '40px' }}
            ></div>
            <div className={CustomMenuFormStyles.nameField}>
              <label style={{ marginTop: '10px' }}>Enter Menu Name</label>
              <TextField
                name="menuName"
                type="text"
                className={CustomMenuFormStyles.textField}
                label="Menu Name"
                variant="outlined"
                value={menuName}
                onChange={e => {
                  handleOnChange(e);
                  checkIfDuplicatet(e);
                }}
              />
            </div>
            <FileUpload html={html} setHtml={setHtml} />
          </div>
          {isDefaultMenu && (
            <Alert severity="error" style={{ width: '85%' }}>
              Default menu cannot be recreated
            </Alert>
          )}
          {isDuplicateMenu && (
            <Alert severity="warning" style={{ width: '85%' }}>
              Duplicate menu will be overwritten
            </Alert>
          )}
          <div
            className={CustomMenuFormStyles.line}
            style={{
              marginTop: '20px'
            }}
          ></div>
          <div
            style={{
              justifyContent: isEditing ? 'space-between' : 'end'
            }}
            className={CustomMenuFormStyles.bottomContainer}
          >
            {isEditing && (
              <Tooltip TransitionComponent={Zoom} title="Delete Menu">
                {renderDeleteField(editMenuName)}
              </Tooltip>
            )}
            <div>
              <Button
                variant="outlined"
                style={getCloseButtonStyles()}
                onClick={() => {
                  setIsDefaultMenu(false);
                  setIsDuplicateMenu(false);
                  handleOnClose();
                }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                style={getSaveButtonStyles()}
                onClick={() => {
                  handleCreate(defaultMenuName);
                  setIsDefaultMenu(false);
                  setIsDuplicateMenu(false);
                }}
                disabled={!menuName || !html || isDefaultMenu}
              >
                {fieldName}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CustomMenuForm;
