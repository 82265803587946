import { Offer } from '../../models/offer/Offer.model';
import React, { FC } from 'react';

interface OfferExpiryProps {
  offer: Offer;
  className: string;
}

interface DaysInfo {
  style: { [key: string]: string };
  label: (days: number) => string;
}

const dayLeftInfoMap: { [key: string]: DaysInfo } = {
  today: {
    style: {
      fontWeight: 'bold',
      fontSize: '13px',
      color: '#D64539'
    },
    label: (days: number) => 'EXPIRES TODAY'
  },
  tomorrow: {
    style: {
      fontWeight: 'bold',
      fontSize: '13px',
      color: '#D64539'
    },
    label: (days: number) => 'EXPIRES TOMORROW'
  },
  week: {
    style: {
      fontWeight: 'bold',
      fontSize: '13px',
      color: '#F57C00'
    },
    label: (days: number) => `EXPIRES IN ${days} DAYS`
  },
  future: {
    style: {
      fontWeight: 'bold',
      fontSize: '13px',
      color: '#579F18'
    },
    label: (days: number) => 'ACTIVE'
  }
};

function getDaysLeftInfo(daysLeft: number): DaysInfo {
  let mapKey = 'future';
  if (daysLeft === 0) {
    mapKey = 'today';
  } else if (daysLeft === 1) {
    mapKey = 'tomorrow';
  } else if (daysLeft <= 7) {
    mapKey = 'week';
  }
  return dayLeftInfoMap[mapKey];
}

export const OfferExpiry: FC<OfferExpiryProps> = ({
  offer,
  className = ''
}) => {
  const daysLeft = offer.daysLeft;
  const daysLeftInfo = getDaysLeftInfo(daysLeft);
  return (
    <div className={className} style={daysLeftInfo.style}>
      {daysLeftInfo.label(daysLeft)}
    </div>
  );
};
