import * as React from 'react';

const DeleteIcon = props => (
  <svg
    width="32px"
    height="32px"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: 'pointer', position: 'relative', top: '6px' }}
    {...props}
  >
    <path
      d="M10.75 3.5h4.125a.687.687 0 0 1 0 1.375h-.76l-1.034 9.317a3.438 3.438 0 0 1-3.417 3.058H6.336a3.438 3.438 0 0 1-3.417-3.058L1.884 4.875h-.759a.688.688 0 1 1 0-1.375H5.25a2.75 2.75 0 1 1 5.5 0ZM8 2.125A1.375 1.375 0 0 0 6.625 3.5h2.75A1.375 1.375 0 0 0 8 2.125Zm-4.731 2.75 1.017 9.166a2.062 2.062 0 0 0 2.05 1.834h3.328a2.062 2.062 0 0 0 2.049-1.834l1.02-9.166H3.269Z"
      fill="#F07663"
    />
  </svg>
);

export default DeleteIcon;
