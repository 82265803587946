module.exports = {
  dashboardUrl: 'https://dashboard-classic.casaqa.ajira.tech',
  ruleServerUrl: 'https://api.casaqa.ajira.tech',
  casaWebAppUrl: 'https://web.casaqa.ajira.tech',
  apiServerUrl: 'https://api.customerstudioqa.ajira.tech',
  casaEcomUrl: 'https://ecom.casaqa.ajira.tech',
  casaBeatUrl: 'https://beat-api.casaqa.ajira.tech',
  pwaUrl: 'https://shoppersdelight.casaqa.ajira.tech',
  dashboard: 'https://dashboard.casaqa.ajira.tech',
  ruleUiUrl: 'https://rules.casaqa.ajira.tech',
  commerceConnect: 'http://commerce-connect.casaqa.ajira.tech',
  turnedOffFeatures: [],
  keycloakConfig: {
    url: `https://auth.casaqa.ajira.tech/auth`,
    realm: `casa`,
    clientId: `casa_web_app`
  }
};
