import { Typography } from '@material-ui/core';
import React, { useContext } from 'react';

import { InvoiceContext } from '../../context/InvoiceContext';
import ButtonField from '../ButtonField/ButtonField';
import classes from './Header.module.scss';

const Header: React.FC<{
  switchPreview: () => void;
}> = ({ switchPreview }) => {
  const { saveConfig } = useContext(InvoiceContext);

  return (
    <div className={classes.container}>
      <Typography variant="h6">E-BILL Template Configuration</Typography>

      <div className={classes.btns}>
        <ButtonField
          label="Preview"
          variant="outlined"
          color="primary"
          onClick={switchPreview}
        />
        <ButtonField label="Save" color="primary" onClick={saveConfig} />
      </div>
    </div>
  );
};
export default Header;
