import { createMuiTheme } from '@material-ui/core';

export const primaryColor = '#F2755F';

const theme = createMuiTheme({
  // shadows: ['none'],
  typography: {
    fontFamily: '"Camphor","camphor","Nunito"',
    button: {
      fontWeight: 'bold',
      fontFamily: '"Camphor","camphor","Nunito"',
      textTransform: 'none',
      letterSpacing: '.075em'
    }
  },
  palette: {
    primary: {
      light: primaryColor,
      main: primaryColor,
      dark: primaryColor,
      contrastText: '#FFF'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        '&:focus': {
          outline: 'none'
        }
      }
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottomWidth: 1,
          borderBottomColor: primaryColor,
          outline: 0
        },
        '&&&&:hover:before': {
          borderBottom: 0,
          backgroundColor: '#A8A8A8',
          height: 1
        },
        '&:before': {
          borderBottomColor: '#A8A8A8',
          outline: 0
        }
      }
    },
    MuiTable: {
      root: {
        borderRadius: '.5rem',
        overflow: 'hidden'
      }
    }
  }
});

export default {
  theme
};
