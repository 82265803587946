import config from '../../config';
import formatDate from '../../shared/utils/formatDate';
import TierReportDownload from '../../models/TenantReports/TierReportDownload';

export const createTierDowngradeReport = ({
  businessId,
  offset,
  page,
  startDate
}: any): Promise<{ message: string; jobId: string }> => {
  return fetch(
    `${
      config.ruleServerUrl
    }/users/reports/download/tier_downgrade_report/${businessId}?offset=${offset}&page=${page}&startDate=${formatDate(
      startDate,
      'YYYY-MMM-DD'
    )}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
};

export const getTierDowngradeReportDownload = ({
  page,
  offset
}: {
  page: number;
  offset: number;
}): Promise<TierReportDownload[]> => {
  return fetch(
    `${config.ruleServerUrl}/users/reports/view/downloads/tier_downgrade_report?offset=${offset}&page=${page}`,
    { credentials: 'include' }
  ).then(res => res.json());
};
