import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as RefreshIcon } from '../../../../src/components/Assets/images/icons/refresh.svg';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  filterBar: {
    background: '#F9F9F9',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px 25px 25px'
  },
  resetButton: {
    height: 38,
    background: '#FFF',
    borderRadius: 35,
    padding: '5px 20px',
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: 16,
    borderColor: '#DDD',
    boxShadow: '0px 3px 6px #00000029'
  },
  searchBar: {
    background: '#FFF',
    borderRadius: 35,
    paddingLeft: 14,
    height: 38
  },
  selectBar: {
    background: '#FFF',
    minHeight: 50,
    paddingTop: 0,
    paddingBottom: 0
  },
  selectBody: {
    '&:focus': {
      background: 'none'
    }
  },
  selectBarSelect: {
    paddingTop: 0,
    paddingBottom: 0
  },
  filterLabel: {
    fontWeight: 'bold',
    marginBottom: 5
  },
  hiddenFilterLabel: {
    marginBottom: 5,
    visibility: 'hidden'
  },
  justifyColumnStart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start'
  },
  justifyColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  gridToolbarStyles: {
    padding: '4px 13px 0 !important'
  }
});

const RoleFilters = props => {
  const classes = useStyles();
  const {
    filter,
    setFilter,
    setGlobalFilter,
    useAsyncDebounce,
    setAllFilters,
    setShowFilterReset,
    defaultFilters,
    getUserData
  } = props;

  const handleSearchChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div>
      <div className={classes.filterBar}>
        <div className={classes.justifyColumnStart}>
          <TextField
            variant={'outlined'}
            placeholder={'Search'}
            value={filter?.search || ''}
            onClick={() => {
              setAllFilters(defaultFilters);
              setShowFilterReset(true);
              getUserData();
            }}
            onChange={e => {
              setFilter({ ...filter, search: e.target.value.trim() });
              handleSearchChange(e.target.value);
            }}
            InputProps={{
              classes: {
                root: classes.searchBar
              },
              endAdornment: (
                <InputAdornment style={{ marginBottom: 2 }}>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className={classes.justifyColumnStart}>
          <div className={classes.hiddenFilterLabel}>Reset</div>
          <div>
            <Button
              variant={'outlined'}
              color={'primary'}
              onClick={() => {
                setAllFilters(defaultFilters);
                setShowFilterReset(true);
                setFilter({});
                setGlobalFilter();
                getUserData();
              }}
              classes={{
                root: classes.resetButton
              }}
            >
              <RefreshIcon style={{ marginRight: 8 }} /> Reset
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleFilters;
