import React from 'react';

const ErrorSKUTable = props => {
  const { data, error } = props;
  return (
    <table style={{ width: '30%', tableLayout: 'fixed' }}>
      <thead>
        <tr>
          <th>SKU</th>
          <th>ERROR</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((list, index) => (
          <tr key={index}>
            <td style={error && { color: '#F43E3E' }}>{list?.sku}</td>
            <td
              style={{
                color: '#999999',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              }}
              title={list?.error || 'N/A'}
            >
              {list?.error || 'N/A'}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ErrorSKUTable;
