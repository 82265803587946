/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import ReactQuill from 'react-quill';
import EditorStyles from './Editor.module.css';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import './QuillEditor.css';
import { VALID_TEXT } from '../../../../constants/additionalText';
import { Alert } from '@material-ui/lab';
function Editor({
  page,
  setAdditionalText,
  additionalText,
  setPage,
  isValidText,
  setIsValidText
}) {
  const modules = {
    toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'bullet' }]]
  };
  const formats = [
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet'
  ];

  const convertToMenuName = name => {
    return name
      .trim()
      .toUpperCase()
      .replaceAll(' ', '_');
  };

  const convertToRawText = text => {
    return text.replace(/<(.|\n)*?>/g, '');
  };

  const checkIfValidText = content => {
    !VALID_TEXT.test(convertToRawText(content))
      ? setIsValidText(true)
      : setIsValidText(false);
  };

  const handleProcedureContentChange = (content, delta, source, editor) => {
    checkIfValidText(content);
    setAdditionalText({
      ...additionalText,
      [convertToMenuName(page)]: { html: content }
    });
  };

  const handleChange = e => {
    setPage(e.target.value);
  };

  const renderEditor = () => {
    return (
      <div>
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          value={
            additionalText
              ? additionalText[convertToMenuName(page)]?.html ?? ''
              : ''
          }
          onChange={handleProcedureContentChange}
          className={EditorStyles.editor}
          placeholder="Type here..."
        />
        <FormControl
          style={{
            width: '120px',
            position: 'absolute',
            top: '31px',
            left: '35%'
          }}
          size="small"
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={page}
            onChange={handleChange}
            variant="outlined"
            label="Select page"
          >
            <MenuItem value={'All Pages'}>All Pages</MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  };
  return (
    <div style={{ position: 'relative' }}>
      {renderEditor()}
      {!isValidText && (
        <Alert severity="error" style={{ width: '50%' }}>
          Special character are not allowed. Only alphabets, numbers,
          Mathematical symbols and * are allowed
        </Alert>
      )}
    </div>
  );
}

export default Editor;
