/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import { Typography, TextareaAutosize } from '@material-ui/core';
import {
  customerGroupContents,
  csvTemplateHeaders,
  initialValues
} from '../../../constants/CustomerGroup/createCustomerGroup';
import { createCustomerGroupToSchema } from '../../../validation/customerGroupToSchema';
import { TextFieldComponent } from '../../../reusable/TextFieldComponent';
import CustomIOSSwitch from '../CustomIOSSwitch';
import { CSVTemplate } from '../../../reusable/CSVTemplate';
import customerGroupClass from '../index.module.scss';
import { useFormik } from 'formik';
import configService from '../../../services/customerGroup.service';
import _ from 'lodash';
import { toastMessageAction } from '../../../store/action';
import { useDispatch } from 'react-redux';
import LoadingButton from '../../../shared/components/LoadingButton/LoadingButton';
import { isValidCsv } from '../../../utils/csv';

const Form = props => {
  const dispatch = useDispatch();
  const { business, status, setStatus } = props;
  const {
    title,
    groupName,
    groupDescription,
    groupConfiguration,
    addBulk,
    csvTitle,
    typoGraphyVariant
  } = customerGroupContents;
  const hiddenFileInput = React.useRef(null);
  const [customerUploadFile, setCustomerUploadFile] = useState('');

  const handleInputChange = event => {
    const fileUploaded = event.target.files[0];

    isValidCsv(['mobileNumber'], fileUploaded)
      .then(() => setCustomerUploadFile(fileUploaded))
      .catch(error => {
        hiddenFileInput.current.value = '';
        dispatch(
          toastMessageAction({
            severity: 'error',
            message: error.message,
            messageStatus: true
          })
        );
      });
  };

  const onSubmit = async (data, { resetForm }) => {
    const workData = {
      ...data,
      name: _.capitalize(data.name),
      description: _.capitalize(data.description)
    };

    if (!customerUploadFile) {
      dispatch(
        toastMessageAction({
          severity: 'error',
          message: `Not File has Selected`,
          messageStatus: true
        })
      );
      return false;
    }

    const createGroup = window.confirm(`Create a new customer`);

    return createGroup
      ? await configService
          .createCustomerGroupConfig(workData, business.name)
          .then(
            res => {
              if (res.status === 'failure') {
                dispatch(
                  toastMessageAction({
                    severity: 'error',
                    message: `${res.errors[0].message}`,
                    messageStatus: true
                  })
                );
                return false;
              } else {
                const formData = {
                  customerGroupId: res?.data?.id,
                  file: customerUploadFile
                };
                if (res?.data?.id && customerUploadFile !== '') {
                  return configService
                    .bulkCreateCustomersConfig(formData, business.name)
                    .then(res => {
                      if (res.status === 'failure') {
                        dispatch(
                          toastMessageAction({
                            severity: 'error',
                            message: `Please check customer bulk upload file`,
                            messageStatus: true
                          })
                        );
                        return false;
                      }
                      dispatch(
                        toastMessageAction({
                          severity: 'success',
                          message: `Customer Group create successfully`,
                          messageStatus: true
                        })
                      );
                      resetForm();
                      setCustomerUploadFile('');
                      hiddenFileInput.current.value = '';
                      setStatus(!status);
                      return true;
                    });
                } else if (res?.data?.id && customerUploadFile === '') {
                  dispatch(
                    toastMessageAction({
                      severity: 'error',
                      message: `Not File has Selected`,
                      messageStatus: true
                    })
                  );
                  return false;
                }
              }
            },
            ({ response }) => {
              console.log(response, 'new');
            }
          )
      : false;
  };
  //   to handle formik data
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: createCustomerGroupToSchema,
    onSubmit
  });

  const { values, handleChange, touched, errors, isSubmitting } = formik;
  return (
    <form
      onSubmit={formik.handleSubmit}
      className={customerGroupClass.formContainer}
      style={{
        position: 'sticky',
        top: '70px'
      }}
    >
      <Typography
        variant={typoGraphyVariant}
        className={customerGroupClass.title}
      >
        {title}
      </Typography>
      <Typography
        variant={typoGraphyVariant}
        className={customerGroupClass.subTitle}
      >
        {groupName}
      </Typography>
      <TextFieldComponent
        placeholder="Blacklist Customer Tag"
        variant="standard"
        className={customerGroupClass.groupName}
        onChange={handleChange}
        name="name"
        value={values.name}
        error={touched.name && errors.name}
      />
      <Typography
        variant={typoGraphyVariant}
        className={customerGroupClass.subTitle}
      >
        {groupDescription}
      </Typography>
      <TextareaAutosize
        minRows={3}
        placeholder="Enter Purpose of this Group"
        className={customerGroupClass.groupName}
        onChange={handleChange}
        name="description"
        value={values.description}
      />
      <Typography
        variant={typoGraphyVariant}
        className={customerGroupClass.subTitle}
      >
        {groupConfiguration}
      </Typography>
      {/* <CustomIOSSwitch label="Block SMS" /> */}
      <CustomIOSSwitch
        label="Block Loyalty"
        name="blockLoyalty"
        handleChange={handleChange}
        checkedValue={values.blockLoyalty}
      />
      <CustomIOSSwitch
        label="Block Sms"
        name="blockSms"
        handleChange={handleChange}
        checkedValue={values.blockSms}
      />
      <div className={customerGroupClass.addBulk}>
        <Typography
          variant={typoGraphyVariant}
          className={customerGroupClass.subTitle}
        >
          {addBulk}
        </Typography>
        <CSVTemplate
          title={csvTitle}
          headers={csvTemplateHeaders}
          data={[]}
          downloadIcon={true}
          bgColor="#fff"
        />
      </div>
      <div style={{ margin: '15px 0' }}>
        <input type="file" ref={hiddenFileInput} onChange={handleInputChange} />
      </div>
      <div className={customerGroupClass.submitButton}>
        <LoadingButton
          variant="contained"
          disableElevation
          color="primary"
          loading={isSubmitting}
          type="submit"
        >
          Add Customer Group
        </LoadingButton>
      </div>
    </form>
  );
};

export default Form;
