import React, { FC } from 'react';
const PermissionDisabledComponent: FC = () => {
  return (
    <div
      style={{
        display: 'grid',
        placeItems: 'center',
        minHeight: '100vh',
        fontSize: 'xx-large',
        color: '#F2755F',
        fontWeight: 'bold',
        width: '100%'
      }}
    >
      {'Permission disabled, Please contact admin'}
    </div>
  );
};
export default PermissionDisabledComponent;
