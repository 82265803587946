import { FlipbookLayoutTile } from './FlipbookV2LayoutTile.model';

export enum LayoutType {
  SINGLE = 'SINGLE',
  DOUBLE = 'DOUBLE'
}

export enum PageType {
  MAIN = 'MAIN',
  OVERFLOW = 'OVERFLOW'
}

export class FlipbookLayout {
  public layoutType: LayoutType;
  public flipbookId: number;
  public position: number;
  public isLayoutActive?: boolean = true;
  public id?: number;
  public flipbookLayoutTile?: FlipbookLayoutTile[];
  public pageType: PageType;

  constructor(
    layoutType: LayoutType,
    flipbookId: number,
    position: number,
    isLayoutActive: boolean,
    id?: number
  ) {
    this.layoutType = layoutType;
    this.flipbookId = flipbookId;
    this.position = position;
    this.isLayoutActive = isLayoutActive;
    this.id = id;
  }
}
