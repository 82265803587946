import React, { useEffect, useState } from 'react';
import Form from './Form';
import customerGroupClass from '../index.module.scss';
import List from './List';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { Business } from '../../../models/User.model';
import _ from 'lodash';
import SettingsBreadCrumbs from '../../SettingsBreadCrumbs/SettingsBreadCrumbs';

const CreateCustomerGroup = () => {
  const [business, setBusiness] = useState(new Business());
  const [status, setStatus] = useState(false);
  const user = useCurrentUser();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsBuId = _.parseInt(urlParams.get('bu'));
    const paramsBusiness = _(user.getBusinesses()).find(
      b => b.id === paramsBuId
    );
    if (paramsBusiness) setBusiness(paramsBusiness);
  }, [user]);

  return (
    <div className={customerGroupClass.container}>
      <SettingsBreadCrumbs
        currentModuleName="Customer Group"
        fromModuleName="General Settings"
      />
      <div className={customerGroupClass.subContainer}>
        <Form business={business} status={status} setStatus={setStatus} />
        <List business={business} status={status} />
      </div>
    </div>
  );
};

export default CreateCustomerGroup;
