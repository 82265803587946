export const csvHeaders = [
  {
    label: 'Id',
    key: 'id'
  },
  {
    label: 'Tenant Id',
    key: 'tenantId'
  },
  {
    label: 'Store Code',
    key: 'store'
  },
  {
    label: 'Store Name',
    key: 'storeNameAlias'
  },
  { label: 'Store Contact', key: 'mobile' },
  {
    label: 'Address line 1',
    key: 'addressLineOne'
  },
  {
    label: 'Address line 2',
    key: 'addressLineTwo'
  },
  {
    label: 'City',
    key: 'city'
  },
  {
    label: 'State',
    key: 'state'
  },
  {
    label: 'Pincode',
    key: 'pincode'
  },
  {
    label: 'Location',
    key: 'location'
  },
  {
    label: 'Latitude',
    key: 'latitude'
  },
  {
    label: 'Longitude',
    key: 'longitude'
  }
];

export const keyMapping = {
  'Store Name* (eg:store name)': 'storeNameAlias',
  'Store Code* (eg: store123)': 'store',
  'Store Short Code* (eg:store)': 'shortCode',
  'Mobile* (eg:1234567890)': 'mobile',
  'Location (eg:location)': 'location',
  'Latitude (eg:13.082680)': 'latitude',
  'Longitude (eg:80.270721)': 'longitude',
  'Store Name': 'storeNameAlias',
  'Store Code': 'store',
  'Store Short Code': 'shortCode',
  Mobile: 'mobile',
  Location: 'location',
  Latitude: 'latitude',
  Longitude: 'longitude',
  'Address line 1': 'addressLineOne',
  'Address line 2': 'addressLineTwo',
  City: 'city',
  State: 'state',
  Pincode: 'pincode'
};

export const storeTagsKeyMapping = {
  storeCode: 'storeCode',
  tagName: 'tagName',
  tagValue: 'tagValue'
};
