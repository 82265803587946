import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { IconButton, Popover } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import _, { find, isUndefined } from 'lodash';
import UploadImage from '../UploadImage/UploadImage';
import close from '../../Assets/images/close.svg';
import editIcon from '../../Assets/images/edit_icon.png';
import { BusinessUnitContext } from '../../OfferDefinition/BusinessUnitContext';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import FlipbookLayoutTileServiceV2 from '../../../services/Flipbook/flipbookV2LayoutTile.service';
import { isFeatureEnabled } from '../../../services/feature-check.service';
import {
  FlipbookImageTagger,
  FlipbookVideoUpload
} from '../Utiles/FeatureConstants';
import { VideoUploadModal } from '../VideoLayout/VideoUploadModal';
import { ImageLayout } from '../ImageLayout/ImageLayout';
import { VideoLayout } from '../VideoLayout/VideoLayout';
import {
  FlipbookLayoutTile,
  TileDataType,
  TileType
} from '../../../models/FlipbookV2/FlipbookV2LayoutTile.model';
import { isTileHasValue } from '../Components/Utiles/TileValidator';
import {
  ImageType,
  TileDataImage
} from '../../../models/FlipbookV2/TileData/TileDataImage.model';
import get from 'lodash/get';
import { TileDataVideo } from '../../../models/FlipbookV2/TileData/TileDataVideo.model';
import { FlipbookPagesContext } from '../Hooks/FlipbookPagesContext';
import { AddCollectionForm } from '../Layouts/CollectionLayout/AddCollectionForm';
import {
  FlipbookLayout,
  PageType
} from '../../../models/FlipbookV2/FlipbookV2Layout.model';
import { ProductCards } from '../ProductCard';
import { PersonalisedCollectionForm } from '../Layouts/PersonalisedLayout/PersonalisedCollectionForm';

export const PRODUCT_TEMPLATE_FIELD_TITLE = 'productFieldTitle';
export const PRODUCT_TEMPLATE_FIELD_DESCRIPTION = 'productFieldDescription';

interface LayoutTileProps {
  page: FlipbookLayout;
  editPanelOccupiedKey: string;
  setEditPanelOccupiedKey: (key: string) => void;
  layoutId: number;
  flipbookTileType: TileType;
  recommendedDimensions: string;
  isEditMode: boolean;
  isCustomerView: boolean;
  hasPermission: (permission: string) => boolean;
}

export const LayoutTile: FC<LayoutTileProps> = ({
  page,
  editPanelOccupiedKey,
  setEditPanelOccupiedKey,
  layoutId,
  flipbookTileType,
  recommendedDimensions,
  isEditMode,
  isCustomerView,
  hasPermission
}) => {
  const [flipbookLayoutTile, setFlipbookLayoutTile] = useState<
    FlipbookLayoutTile
  >();

  const getCurrentFlipbookTileFromPage = useCallback(() => {
    const currentPageTiles = get(page, 'flipbookLayoutTile', []);
    return find(
      currentPageTiles,
      (tile: FlipbookLayoutTile) => tile.tileType === flipbookTileType
    );
  }, [flipbookTileType, page]);

  useEffect(() => {
    const currentTile = getCurrentFlipbookTileFromPage();
    if (isUndefined(currentTile) || !isUndefined(flipbookLayoutTile)) {
      return;
    }
    setFlipbookLayoutTile(currentTile);
  }, [flipbookLayoutTile, getCurrentFlipbookTileFromPage, page]);

  const [anchorEl, setAnchorEl] = useState({});
  const [isVideoUploadModalOpen, setIsVideoUploadModalOpen] = React.useState(
    false
  );

  const flipbookPagesContext = useContext(FlipbookPagesContext);

  const [isAddCollectionOpen, setIsAddCollectionOpen] = React.useState(false);
  const [
    isPersonalisedCollectionOpen,
    setIsPersonalisedCollectionOpen
  ] = React.useState(false);

  const businessUnitContext = useContext(BusinessUnitContext);
  const productHierarchy = _.get(
    businessUnitContext,
    'business.productHierarchy',
    []
  );

  function handleLayoutTypePopoverClick(event: any) {
    setAnchorEl({ [`layout_${layoutId}`]: event.currentTarget });
  }

  const handleLayoutTypePopoverClose = () => {
    setAnchorEl({ [`layout_${layoutId}`]: null });
  };

  const isLayoutTypePopoverOpen = () => {
    return Boolean(_.get(anchorEl, `layout_${layoutId}`));
  };

  const onClickRemoveTile = () => {
    if (flipbookLayoutTile?.id) {
      FlipbookLayoutTileServiceV2.deleteFlipbookLayoutTileByTileId(
        flipbookLayoutTile?.id
      ).then(r => {
        flipbookPagesContext.updateFlipbookPages();
      });
    }
  };

  const handleUploadImage = (
    imageType: ImageType,
    e: any,
    uploadType: string,
    flipbookTile: FlipbookLayoutTile
  ) => {
    handleLayoutTypePopoverClose();
    const tileDataImage = new TileDataImage(imageType, {
      URL: get(e, uploadType, ''),
      mapArea: []
    });
    flipbookTile.tileData = tileDataImage;
    flipbookTile.id = flipbookLayoutTile?.id;
    if (isUndefined(flipbookLayoutTile?.id)) {
      FlipbookLayoutTileServiceV2.createFlipbookLayoutTile(flipbookTile).then(
        r => {
          flipbookPagesContext.updateFlipbookPages();
        }
      );
    } else {
      FlipbookLayoutTileServiceV2.updateFlipbookLayoutTile(flipbookTile).then(
        r => {
          flipbookPagesContext.updateFlipbookPages();
        }
      );
    }
  };

  const handleUploadVideo = (
    videoData: TileDataVideo,
    flipbookTile: FlipbookLayoutTile
  ) => {
    flipbookTile.tileData = videoData;
    flipbookTile.id = flipbookLayoutTile?.id;
    handleLayoutTypePopoverClose();
    setIsVideoUploadModalOpen(false);
    if (isUndefined(flipbookLayoutTile?.id)) {
      FlipbookLayoutTileServiceV2.createFlipbookLayoutTile(flipbookTile).then(
        r => {
          flipbookPagesContext.updateFlipbookPages();
        }
      );
    } else {
      FlipbookLayoutTileServiceV2.updateFlipbookLayoutTile(flipbookTile).then(
        r => {
          flipbookPagesContext.updateFlipbookPages();
        }
      );
    }
  };
  const handleAddCollectionModalClose = () => {
    const currentPageTile = getCurrentFlipbookTileFromPage();
    setFlipbookLayoutTile(currentPageTile);
    setIsAddCollectionOpen(false);
  };
  const handlePersonalisedCollectionModalClose = () => {
    const currentPageTile = getCurrentFlipbookTileFromPage();
    setFlipbookLayoutTile(currentPageTile);
    setIsPersonalisedCollectionOpen(false);
  };
  const handleVideoModalClose = () => {
    const currentPageTile = getCurrentFlipbookTileFromPage();
    setFlipbookLayoutTile(currentPageTile);
    setIsVideoUploadModalOpen(false);
  };

  const handleLayoutTileActionChange = (
    type: TileDataType,
    ctaFunction: (flipbookTile: FlipbookLayoutTile) => void
  ) => {
    handleLayoutTypePopoverClose();
    const flipbookTile = new FlipbookLayoutTile(
      flipbookTileType,
      type,
      flipbookLayoutTile?.tileData,
      layoutId,
      flipbookLayoutTile?.id
    );
    setFlipbookLayoutTile(flipbookTile);
    ctaFunction(flipbookTile);
  };
  const isLayoutHasSpecificTile = (tileDataType: TileDataType) => {
    return get(flipbookLayoutTile, 'tileDataType', undefined) === tileDataType;
  };
  return (
    <div className={'height-100-per'}>
      {isLayoutHasSpecificTile(TileDataType.IMAGE) &&
        !isUndefined(flipbookLayoutTile) && (
          <div className={`flipbook-image-container elevate`}>
            <ImageLayout
              flipbookLayoutTile={flipbookLayoutTile}
              isEditMode={isEditMode}
              editPanelOccupiedKey={editPanelOccupiedKey}
              setEditPanelOccupiedKey={setEditPanelOccupiedKey}
            />
          </div>
        )}
      {isLayoutHasSpecificTile(TileDataType.VIDEO) && (
        <VideoLayout
          VideoData={flipbookLayoutTile?.tileData}
          classNames={'single-tile-video'}
        />
      )}
      <div className={'px-4 py-4 height-100-per'}>
        <div
          className={`page-layout ${
            !isTileHasValue(flipbookLayoutTile) && isEditMode
              ? 'page-layout-border'
              : ''
          } height-100-per `}
        >
          {isTileHasValue(flipbookLayoutTile) &&
            page.pageType === PageType.MAIN &&
            isEditMode && (
              <div className={'edit-delete-icons-layout-tile'}>
                <div>
                  <label style={{ margin: 0 }}>
                    <IconButton
                      color="primary"
                      aria-label="Add Layout"
                      component="span"
                      style={{ padding: 5 }}
                      onClick={(e: any) => handleLayoutTypePopoverClick(e)}
                    >
                      <img src={editIcon} alt={'edit'} />
                    </IconButton>
                  </label>
                </div>
                <div>
                  <label style={{ margin: 0 }}>
                    <IconButton
                      color="primary"
                      aria-label="Add Layout"
                      component="span"
                      style={{ padding: 5 }}
                      onClick={onClickRemoveTile}
                    >
                      <img src={close} alt={'close'} />
                    </IconButton>
                  </label>
                </div>
              </div>
            )}
          {/*{isShowTitleInFlipbook && (*/}
          {/*  <div className={'collection-title-container'}>*/}
          {/*    <h3*/}
          {/*      className={`collection-title ${*/}
          {/*        layoutType === LAYOUT_SINGLE ? 'm-2' : ''*/}
          {/*      }`}*/}
          {/*    >*/}
          {/*      {_.startCase(collectionTitle)}*/}
          {/*    </h3>*/}
          {/*  </div>*/}
          {/*)}*/}
          {isTileHasValue(flipbookLayoutTile) &&
            !isUndefined(flipbookLayoutTile) &&
            isLayoutHasSpecificTile(TileDataType.COLLECTION) && (
              <ProductCards
                isCustomerView={isCustomerView}
                flipbookLayoutTile={flipbookLayoutTile}
                productHierarchy={productHierarchy}
                layoutType={page.layoutType}
              />
            )}
          {isTileHasValue(flipbookLayoutTile) &&
            !isUndefined(flipbookLayoutTile) &&
            isLayoutHasSpecificTile(TileDataType.PERSONALISED) && (
              <ProductCards
                isCustomerView={isCustomerView}
                flipbookLayoutTile={flipbookLayoutTile}
                productHierarchy={productHierarchy}
                layoutType={page.layoutType}
              />
            )}
          <div
            className={`page-layout-tile ${
              isTileHasValue(flipbookLayoutTile) ? '' : 'elevate'
            }`}
          >
            {!isTileHasValue(flipbookLayoutTile) && isEditMode && (
              <label>
                <IconButton
                  color="primary"
                  aria-label="Add Layout"
                  component="span"
                  style={{ padding: 5 }}
                  onClick={(e: any) => handleLayoutTypePopoverClick(e)}
                >
                  <AddCircle />
                </IconButton>
                <p className={'flipbook-text-header m-0'}>
                  {'Click to add image or collections'}
                </p>
                <p className={'flipbook-text-label m-0'}>
                  {recommendedDimensions}
                </p>
              </label>
            )}
            <Popover
              id={isLayoutTypePopoverOpen() ? 'simple-popover' : undefined}
              open={isLayoutTypePopoverOpen()}
              anchorEl={_.get(anchorEl, `layout_${layoutId}`)}
              onClose={() => handleLayoutTypePopoverClose()}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <div className={'loyout-type-popover-container'}>
                <div
                  style={{ display: 'flex', paddingBottom: 5 }}
                  className={'loyout-type-popover-line-item border-bottom-1'}
                >
                  <div>
                    <UploadImage
                      isActive={true}
                      imageUploadType={`tileImage_${layoutId}`}
                      setImageUploadData={e =>
                        handleLayoutTileActionChange(
                          TileDataType.IMAGE,
                          (flipbookTile: FlipbookLayoutTile) => {
                            handleUploadImage(
                              ImageType.PLAIN,
                              e,
                              `tileImage_${layoutId}`,
                              flipbookTile
                            );
                          }
                        )
                      }
                      iconStyle={{ padding: '0px 5px' }}
                      label="Upload Image"
                    />
                  </div>
                </div>
                <div
                  className={'loyout-type-popover-line-item'}
                  onClick={() =>
                    handleLayoutTileActionChange(
                      TileDataType.COLLECTION,
                      (flipbookTile: FlipbookLayoutTile) =>
                        setIsAddCollectionOpen(true)
                    )
                  }
                >
                  <p className={'m-0'}>{'Add Collections'}</p>
                </div>
                <div
                  className={'loyout-type-popover-line-item border-top-1'}
                  onClick={() =>
                    handleLayoutTileActionChange(
                      TileDataType.PERSONALISED,
                      (flipbookTile: FlipbookLayoutTile) =>
                        setIsPersonalisedCollectionOpen(true)
                    )
                  }
                >
                  <p className={'m-0'}>{'Add Personalised Collections'}</p>
                </div>
                {isFeatureEnabled(FlipbookImageTagger) && (
                  <div
                    style={{ display: 'flex', paddingBottom: 5 }}
                    className={'loyout-type-popover-line-item border-top-1'}
                  >
                    <div>
                      <UploadImage
                        isActive={true}
                        imageUploadType={`tileImage_Tagger_${layoutId}`}
                        setImageUploadData={e =>
                          handleLayoutTileActionChange(
                            TileDataType.IMAGE,
                            flipbookTile => {
                              handleUploadImage(
                                ImageType.TAGGER,
                                e,
                                `tileImage_Tagger_${layoutId}`,
                                flipbookTile
                              );
                            }
                          )
                        }
                        iconStyle={{ padding: '0px 5px' }}
                        label="Upload Image Tagger"
                      />
                    </div>
                  </div>
                )}
                {isFeatureEnabled(FlipbookVideoUpload) && (
                  <div
                    className={'loyout-type-popover-line-item border-top-1'}
                    onClick={() =>
                      handleLayoutTileActionChange(
                        TileDataType.VIDEO,
                        (flipbookTile: FlipbookLayoutTile) =>
                          setIsVideoUploadModalOpen(true)
                      )
                    }
                  >
                    <p className={'m-0'}>{'Upload Video'}</p>
                  </div>
                )}
              </div>
            </Popover>
            {!isUndefined(flipbookLayoutTile) && isAddCollectionOpen && (
              <AddCollectionForm
                flipbookLayoutTile={flipbookLayoutTile}
                handleAddCollectionModalClose={handleAddCollectionModalClose}
                isAddCollectionOpen={isAddCollectionOpen}
                flipbookTileType={flipbookTileType}
                layoutType={page.layoutType}
              />
            )}
            {!isUndefined(flipbookLayoutTile) &&
              isPersonalisedCollectionOpen && (
                <PersonalisedCollectionForm
                  flipbookLayoutTile={flipbookLayoutTile}
                  handlePersonalisedCollectionModalClose={
                    handlePersonalisedCollectionModalClose
                  }
                  isPersonalisedCollectionOpen={isPersonalisedCollectionOpen}
                  flipbookTileType={flipbookTileType}
                  layoutType={page.layoutType}
                />
              )}
            {!isUndefined(flipbookLayoutTile) && isVideoUploadModalOpen && (
              <VideoUploadModal
                isVideoUploadModalOpen={isVideoUploadModalOpen}
                handleVideoModalClose={handleVideoModalClose}
                flipbookLayoutTile={flipbookLayoutTile}
                saveVideo={videoData =>
                  handleLayoutTileActionChange(
                    TileDataType.VIDEO,
                    flipbookTile => {
                      handleUploadVideo(videoData, flipbookTile);
                    }
                  )
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutTile;
