import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { FC, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { generateReport } from '../../../../../services/Campaign/campaign-report.service';
import useMutation from '../../../../../shared/hooks/useMutation';
import LoadingButton from '../../../shared/components/LoadingButton/LoadingButton';
import styles from './CampaignReportHeader.module.scss';
import { get, isUndefined, map } from 'lodash';
import { CampaignReportType } from '../../../shared/utils/contants';
import CampaignDefinition from '../../../../../models/campaign/CampaignDefinition';

interface CampaignReportHeaderProps {
  campaignId: string;
  campaignName: string;
  isFetching: boolean;
  campaign: CampaignDefinition | null;
  reportType: CampaignReportType;
  roiCost: number;
  setReportType: (type: CampaignReportType) => void;
  page: number;
  refetch: () => void;
  defaultBusinessId: string;
  goToFirstPage: () => void;
}

const CampaignReportHeader: FC<CampaignReportHeaderProps> = props => {
  const {
    campaignId,
    campaignName,
    isFetching,
    campaign,
    reportType,
    roiCost,
    defaultBusinessId,
    setReportType,
    refetch
  } = props;
  const messageRateControlRef = useRef<HTMLInputElement | null>(null);
  const [showAlert, setShowAlert] = useState(true);
  const [messageCost, setMessageCost] = useState<any>();
  const { mutate: onGenerateReport, isLoading: isGenerating } = useMutation(
    generateReport.bind(
      null,
      campaignId,
      defaultBusinessId,
      reportType,
      messageCost
    )
  );
  useEffect(() => {
    if (isUndefined(messageCost)) {
      setMessageCost(roiCost);
    }
  }, [messageCost, roiCost]);

  const handleMessageCostChange = (): void => {
    const messageCost = get(messageRateControlRef, 'current.valueAsNumber');
    setMessageCost(messageCost);
  };
  const getOptions = (): string[] => {
    return Object.values(CampaignReportType);
  };
  const isDisabled = (key: any) => {
    const disableOptionAvailable = [
      CampaignReportType.VARIANT_TIER,
      CampaignReportType.VARIANT_RFM,
      CampaignReportType.CAMPAIGN_TIER,
      CampaignReportType.CAMPAIGN_RFM
    ];
    return (
      disableOptionAvailable.includes(key) &&
      !get(campaign, 'trackConversion', true)
    );
  };
  const handleChangeReportType = (value: any) => {
    const type: CampaignReportType = value as CampaignReportType;
    setReportType(type);
  };

  const handleGenerateReport = (): void => {
    onGenerateReport()
      .then(() => {
        toast.success('Your report generation is started successfully.');
        refetch();
      })
      .catch(() => toast.error('Something went wrong.'));
  };

  const isRoiDisabled = () => {
    if (messageCost === null) {
      return true;
    }
    return !(messageCost > 0);
  };

  function isRoiAvailable() {
    const roiAvailableReports = [
      CampaignReportType.VARIANT_TIER,
      CampaignReportType.VARIANT_RFM,
      CampaignReportType.CAMPAIGN_TIER,
      CampaignReportType.CAMPAIGN_RFM
    ];
    return roiAvailableReports.includes(reportType);
  }

  function getRoiCTA() {
    return (
      <Box className={styles.campaignROIContainer}>
        <TextField
          type="number"
          name="messageRate"
          placeholder="Enter Rate per message to get ROI"
          inputRef={messageRateControlRef}
          value={messageCost}
          className={styles.roiField}
          onChange={handleMessageCostChange}
          fullWidth
          inputProps={{ step: 0.01, min: 0.01 }}
          required
          autoFocus
        />
        <LoadingButton
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleGenerateReport}
          className={styles.roiButton}
          loading={isGenerating}
          disabled={isFetching || isRoiDisabled()}
        >
          Generate ROI report
        </LoadingButton>
      </Box>
    );
  }
  return (
    <Box component="header" className={styles.campaignReportHeader}>
      <Typography
        variant="h4"
        component="h1"
        color="primary"
        className={styles.campaignReportTitle}
      >
        Download your reports
      </Typography>
      {showAlert && (
        <Alert
          className={styles.campaignReportAlert}
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={setShowAlert.bind(null, false)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle className={styles.campaignReportAlertTitle}>
            Please note
          </AlertTitle>
          Reports later than six months from current date would not be
          available.
        </Alert>
      )}
      <Box className={styles.campaignReportInfo}>
        <Typography className={styles.campaignTitle} variant="h6">
          {campaignName}
        </Typography>
        <FormControl style={{ minWidth: 180, marginRight: '10px' }}>
          <InputLabel id="campaign-report-select-helper-label">
            Report Type
          </InputLabel>
          <Select
            labelId="select-campaign-report-type-label"
            id="select-campaign-report-type"
            value={reportType}
            label="Report Type"
            onChange={e => handleChangeReportType(e.target.value)}
          >
            {map(getOptions(), (value, key) => {
              return (
                <MenuItem
                  value={value}
                  disabled={isDisabled(value)}
                  key={key}
                  style={{ textTransform: 'capitalize' }}
                >
                  {value.replace('_', ' ')}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {isRoiAvailable() ? (
          getRoiCTA()
        ) : (
          <LoadingButton
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleGenerateReport}
            loading={isGenerating}
            disabled={isFetching}
          >
            Generate report
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
};
export default CampaignReportHeader;
