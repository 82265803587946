import config from '../config';
import _ from 'lodash';

export const getUserRoles = () => {
  return fetch(`${config.ruleServerUrl}/users/roles/currentroleconfig`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(resp => resp.json())
    .catch(err => console.error(err));
};

export const updateSwitchToggle = rolePermissions => {
  _.forEach(rolePermissions, rolePermission => {
    rolePermission.permissions = _.flatMap(
      _.map(Object.values(rolePermission.permissions), perm => perm.enabled)
    );
  });

  return fetch(`${config.ruleServerUrl}/users/roles/save-role-config`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(rolePermissions),
    headers: {
      'Content-type': 'application/json'
    }
  })
    .then(resp => resp.json())
    .catch(err => console.error(err));
};
