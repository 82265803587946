import _ from 'lodash';
const isArray = Array.isArray;
const cond = _.cond;
const isNil = _.isNil;
const toPairs = _.toPairs;
const isObject = _.isObject;
const noop = () => null;
const isFile = f => f instanceof File;

function isEmpty(v) {
  return isArray(v) && v.length === 0;
}

function anyPass(predFns) {
  return (...args) => predFns.some(predFn => predFn(...args));
}

function allPass(predFns) {
  return (...args) => predFns.every(predFn => predFn(...args));
}

function not(predFn) {
  return (...args) => !predFn(...args);
}

const isValue = anyPass([isFile, isArray, not(isObject)]);

function formDataPath([first, ...rest]) {
  return isEmpty(rest) ? first : `${first}[${rest.join('][')}]`;
}
const formDataAppend = cond([
  [allPass([isArray, isEmpty]), noop],
  [isNil, noop],
  [
    isArray,
    (arr, formData, path) =>
      arr.forEach(item => formDataAppend(item, formData, [...path, '']))
  ],
  [
    () => true,
    (datum, formData, path) => formData.append(formDataPath(path), datum)
  ]
]);
function transformToFormData(data, FormDataCtor = FormData) {
  const formData = new FormDataCtor();

  const iter = (datum, path = []) =>
    isValue(datum)
      ? formDataAppend(datum, formData, path)
      : toPairs(datum).forEach(([key, value]) => iter(value, [...path, key]));

  iter(data);

  return formData;
}

export default transformToFormData;
