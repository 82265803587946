import config from '../config/index';
import { EcomUpdateCart } from '../models/flipbook/ecomUpdateCart.model';
import queryString from 'query-string';
import {
  getCasaEcomHeaders,
  getFlipbookTrackingId
} from '../components/Flipbook/Utiles/FlipbookUtiles';

const defaultQueryParams = queryString.stringify(
  {
    include: 'line_items,variants.sku'
  },
  { arrayFormat: 'bracket' }
);

const defaultHeader = { 'Content-type': 'application/json' };
const getTrackingIdQueryParams = () => {
  const trackingIdPayload = getFlipbookTrackingId();
  let trackingIdQS = '';
  if (!trackingIdPayload.isLandingEventAvailable) {
    trackingIdQS = `&trackingId=${trackingIdPayload.trackingId}`;
  }
  return trackingIdQS;
};
export function isSessionValid(
  tenantName: string,
  businessId: number,
  checkoutType: string,
  tokenType: string
) {
  const queryParams = queryString.stringify(
    {
      vendorName: tenantName,
      checkoutType: checkoutType,
      sessionType: tokenType,
      bu: businessId
    },
    { arrayFormat: 'bracket' }
  );
  return fetch(`${config.casaEcomUrl}/api/session?${queryParams}`, {
    credentials: 'include',
    method: 'GET',
    headers: { ...getCasaEcomHeaders(), ...defaultHeader }
  }).then(r => r.json());
}

export function updateCart(
  cart: EcomUpdateCart,
  businessId: number,
  businessName: string,
  store: string
) {
  return fetch(
    `${
      config.casaEcomUrl
    }/api/v2/storefront/cart/add_item?${defaultQueryParams}&bu=${businessId}&store=${store}&bu_name=${businessName}${getTrackingIdQueryParams()}`,
    {
      credentials: 'include',
      method: 'POST',
      headers: { ...getCasaEcomHeaders(), ...defaultHeader },
      body: JSON.stringify(cart)
    }
  ).then(r => r.json());
}

export function updateLineItemQty(
  lineItemInfo: any,
  businessId: number,
  businessName: string
) {
  return fetch(
    `${
      config.casaEcomUrl
    }/api/v2/storefront/cart/set_quantity?${defaultQueryParams}&bu=${businessId}&bu_name=${businessName}${getTrackingIdQueryParams()}`,
    {
      credentials: 'include',
      method: 'PATCH',
      headers: { ...getCasaEcomHeaders(), ...defaultHeader },
      body: JSON.stringify(lineItemInfo)
    }
  ).then(r => r.json());
}

export function getEcomCart(bu: number) {
  return fetch(
    `${config.casaEcomUrl}/api/v2/storefront/cart?${defaultQueryParams}&bu=${bu}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: getCasaEcomHeaders()
    }
  ).then(r => r.json());
}

export function removeCartLineItem(
  lineItemId: number,
  businessId: number,
  businessName: string
) {
  return fetch(
    `${
      config.casaEcomUrl
    }/api/v2/storefront/cart/remove_line_item/${lineItemId}?${defaultQueryParams}&bu=${businessId}&bu_name=${businessName}${getTrackingIdQueryParams()}`,
    {
      credentials: 'include',
      method: 'DELETE',
      headers: getCasaEcomHeaders()
    }
  ).then(r => r.json());
}

export function createCart(
  tenantName: string,
  storeCode: string,
  checkoutType: string,
  businessId: number
) {
  const data = {
    storeCode: storeCode,
    vendorName: tenantName,
    checkoutType: checkoutType
  };
  return fetch(
    `${config.casaEcomUrl}/api/v2/storefront/cart?bu=${businessId}&${defaultQueryParams}`,
    {
      credentials: 'include',
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(data)
    }
  ).then(r => r.json());
}
export function getNameFromNumber(mobileNumber: any, tenantName: string) {
  const queryParams = queryString.stringify(
    {
      vendorName: tenantName,
      // eslint-disable-next-line @typescript-eslint/camelcase
      phone_number: mobileNumber
    },
    { arrayFormat: 'bracket' }
  );
  return fetch(`${config.casaEcomUrl}/api/name?${queryParams}`, {
    credentials: 'include',
    method: 'GET',
    headers: {}
  }).then(r => r.json());
}
export function generateOtp(
  mobileNumber: any,
  tenantName: string,
  businessId: number
) {
  const queryParams = queryString.stringify(
    {
      vendorName: tenantName,
      // eslint-disable-next-line @typescript-eslint/camelcase
      phone_number: mobileNumber,
      bu: businessId
    },
    { arrayFormat: 'bracket' }
  );
  return fetch(`${config.casaEcomUrl}/api/login_with_phone?${queryParams}`, {
    credentials: 'include',
    method: 'POST',
    headers: {}
  }).then(r => r.json());
}
export function loginWithoutOtp(
  mobileNumber: any,
  tenantName: string,
  storeCode: string,
  name: string
) {
  let query: any = {
    vendorName: tenantName,
    // eslint-disable-next-line @typescript-eslint/camelcase
    phone_number: mobileNumber,
    storeCode: storeCode,
    name: name
  };
  const trackingIdPayload = getFlipbookTrackingId();
  if (!trackingIdPayload.isLandingEventAvailable) {
    query = {
      ...query,
      trackingId: trackingIdPayload.trackingId
    };
  }
  const queryParams = queryString.stringify(query, { arrayFormat: 'bracket' });
  return fetch(`${config.casaEcomUrl}/api/login/no_otp?${queryParams}`, {
    credentials: 'include',
    method: 'POST',
    headers: { ...getCasaEcomHeaders(), ...defaultHeader }
  }).then(r => r.json());
}

export function storeCheckoutProcess(
  storeCode: string,
  businessId: number,
  businessName: string,
  tenantName: string
) {
  let query: any = {
    vendorName: tenantName,
    bu: businessId,
    // eslint-disable-next-line @typescript-eslint/camelcase
    bu_name: businessName,
    storeCode: storeCode
  };
  const trackingIdPayload = getFlipbookTrackingId();
  if (!trackingIdPayload.isLandingEventAvailable) {
    query = {
      ...query,
      trackingId: trackingIdPayload.trackingId
    };
  }
  const queryParams = queryString.stringify(query, { arrayFormat: 'bracket' });
  return fetch(`${config.casaEcomUrl}/api/events/checkout?${queryParams}`, {
    credentials: 'include',
    method: 'POST',
    headers: { ...getCasaEcomHeaders(), ...defaultHeader }
  }).then(r => r.json());
}

export function validateOtp(
  mobileNumber: string,
  otp: string,
  tenantName: string,
  businessId: number,
  storeCode: string
) {
  let query: any = {
    vendorName: tenantName,
    // eslint-disable-next-line @typescript-eslint/camelcase
    phone_number: mobileNumber,
    otp: otp,
    storeCode: storeCode,
    bu: businessId
  };
  const trackingIdPayload = getFlipbookTrackingId();
  if (!trackingIdPayload.isLandingEventAvailable) {
    query = {
      ...query,
      trackingId: trackingIdPayload.trackingId
    };
  }
  const queryParams = queryString.stringify(query, { arrayFormat: 'bracket' });
  const token = localStorage.getItem('token') || '';
  return fetch(`${config.casaEcomUrl}/api/verify_otp?${queryParams}`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'X-Spree-Order-Token': token
    }
  }).then(r => r.json());
}

export function getAllCountries() {
  const queryParams = queryString.stringify(
    {
      // eslint-disable-next-line @typescript-eslint/camelcase
      per_page: 500
    },
    { arrayFormat: 'bracket' }
  );
  return fetch(`${config.casaEcomUrl}/api/v1/countries?${queryParams}`, {
    credentials: 'include',
    method: 'GET',
    headers: {}
  }).then(r => r.json());
}

export function getAllStatesByCountryCode(countryCode: string) {
  const queryParams = queryString.stringify(
    {
      include: 'states'
    },
    { arrayFormat: 'bracket' }
  );
  return fetch(
    `${config.casaEcomUrl}/api/v2/storefront/countries/${countryCode}?${queryParams}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {}
    }
  ).then(r => r.json());
}

export function getAddresses(businessId: number) {
  return fetch(
    `${config.casaEcomUrl}/api/v2/storefront/account/addresses/?bu=${businessId}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: getCasaEcomHeaders()
    }
  ).then(r => r.json());
}

export function createAddress(address: any, businessId: number) {
  return fetch(
    `${config.casaEcomUrl}/api/v2/storefront/account/addresses/?bu=${businessId}`,
    {
      credentials: 'include',
      method: 'POST',
      headers: { ...getCasaEcomHeaders(), ...defaultHeader },
      body: JSON.stringify(address)
    }
  ).then(r => r.json());
}

export function updateAddressWithOrder(
  address: any,
  businessId: number,
  businessName: string
) {
  return fetch(
    `${
      config.casaEcomUrl
    }/api/v2/storefront/checkout?bu=${businessId}&bu_name=${businessName}${getTrackingIdQueryParams()}`,
    {
      credentials: 'include',
      method: 'PATCH',
      headers: { ...getCasaEcomHeaders(), ...defaultHeader },
      body: JSON.stringify(address)
    }
  ).then(r => r.json());
}

export function createPaymentOrder(tenantName: string, businessId: number) {
  const queryParams = queryString.stringify(
    {
      vendorName: tenantName,
      bu: businessId
    },
    { arrayFormat: 'bracket' }
  );
  return fetch(`${config.casaEcomUrl}/api/razorpay/order?${queryParams}`, {
    credentials: 'include',
    method: 'POST',
    headers: { ...getCasaEcomHeaders(), ...defaultHeader }
  }).then(r => r.json());
}

export function paymentSuccess(
  tenantName: string,
  data: any,
  businessId: number,
  businessName: string
) {
  let query: any = {
    vendorName: tenantName,
    bu: businessId,
    // eslint-disable-next-line @typescript-eslint/camelcase
    bu_name: businessName
  };
  const trackingIdPayload = getFlipbookTrackingId();
  if (!trackingIdPayload.isLandingEventAvailable) {
    query = {
      ...query,
      trackingId: trackingIdPayload.trackingId
    };
  }
  const queryParams = queryString.stringify(query, { arrayFormat: 'bracket' });
  return fetch(
    `${config.casaEcomUrl}/api/razorpay/payment/success?${queryParams}`,
    {
      credentials: 'include',
      method: 'POST',
      headers: { ...getCasaEcomHeaders(), ...defaultHeader },
      body: JSON.stringify(data)
    }
  ).then(r => r.json());
}

export function getAccountInfo(businessId: number) {
  return fetch(
    `${config.casaEcomUrl}/api/v2/storefront/account/?bu=${businessId}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: { ...getCasaEcomHeaders(), ...defaultHeader }
    }
  ).then(r => r.json());
}

export function getStockItems(
  tenantName: string,
  businessId: number,
  storeName: string,
  skus: any
) {
  const queryParams = queryString.stringify(
    {
      vendorName: tenantName,
      storeCode: storeName,
      bu: businessId
    },
    { arrayFormat: 'bracket' }
  );
  return fetch(`${config.casaEcomUrl}/api/stock_items?${queryParams}`, {
    credentials: 'include',
    method: 'POST',
    headers: { ...getCasaEcomHeaders(), ...defaultHeader },
    body: JSON.stringify({ skus: skus })
  }).then(r => r.json());
}

export function clearCart() {
  return fetch(`${config.casaEcomUrl}/api/v2/storefront/cart/empty`, {
    credentials: 'include',
    method: 'PATCH',
    headers: { ...getCasaEcomHeaders(), ...defaultHeader }
  }).then(r => r.json());
}
