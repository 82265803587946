export const initialState = {
  newPassword: '',
  confirmNewPassword: ''
};

export const redemptionInitialState = {
  permitRedeemption: 1,
  minimumRedeemptionLimit: '',
  maximumRedeemptionLimit: ''
};

const labels = {
  permitRedeemption: 'Permit Redemption when available points are...',
  minimumRedeemptionLimit: 'Minimum Points',
  maximumRedeemptionLimit: 'Maximum Points'
};
export const formGroupRedemption = {
  permitRedeemption: {
    label: labels.permitRedeemption,
    variant: 'outlined',
    type: 'number',
    className: 'textFieldStyle',
    name: 'permitRedeemption'
    // placeholder: 'Permit Redemption when available points are...'
  },
  minimumRedeemptionLimit: {
    label: labels.minimumRedeemptionLimit,
    variant: 'outlined',
    type: 'number',
    className: 'textFieldStyle',
    name: 'minimumRedeemptionLimit',
    placeholder: 'Minimum Points which can be Redeemed in any bill'
  },
  maximumRedeemptionLimit: {
    label: labels.maximumRedeemptionLimit,
    variant: 'outlined',
    type: 'number',
    className: 'textFieldStyle',
    name: 'maximumRedeemptionLimit',
    placeholder: 'Maximum Points which can be Redeemed in any bill'
  },
  submitButton: {
    variant: 'contained',
    label: 'Save',
    type: 'sumbit',
    className: 'saveButton'
  },
  cancelButton: {
    variant: 'contained',
    label: 'Clear',
    type: 'button',
    className: 'clearButton'
  }
};
