export const configurationInitialState = {
  priorityOrder: '',
  authkey: '',
  sender: '',
  from: '',
  showCampaignNameInReport: false
};

export const karixInitialState = {
  priorityOrder: '',
  sender: '',
  promotionalSender: '',
  otpSender: '',
  from: '',
  dltEntityId: '',
  authKeyPromotional: '',
  authKeyTransactional: '',
  authKeyOtp: '',
  transactionalSender: ''
};

export const watiWhatsAppSmsInitialState = {
  priorityOrder: '',
  authkey: '',
  sender: ''
};

export const ICSWhatsAppSmsInitialState = {
  priorityOrder: '',
  user: '',
  pass: '',
  from: ''
};

export const ICSRcsInitialState = {
  priorityOrder: '',
  user: '',
  pass: ''
};

export const msg91InitialState = {
  priorityOrder: '',
  authkey: '',
  sender: '',
  showCampaignNameInReport: false,
  promotionalSender: ''
};

export const IcsSmsInitialState = {
  priorityOrder: '',
  [`pass_promotional`]: '',
  [`from_promotional`]: '',
  [`user_promotional`]: '',
  [`pass_transactional`]: '',
  [`from_transactional`]: '',
  [`user_transactional`]: '',
  [`pass_OTP`]: '',
  [`from_OTP`]: '',
  [`user_OTP`]: '',
  peId: ''
};

export const valueFirstInitialState = {
  priorityOrder: '',
  username: '',
  password: '',
  sender: '',
  showCampaignNameInReport: false
};

export const stmInitialState = {
  priorityOrder: '',
  authkey: '',
  sender: '',
  principleEntityId: '',
  clientId: '',
  showCampaignNameInReport: false
};

export const oneXtelInitialState = {
  priorityOrder: '',
  authkey: '',
  sender: '',
  promotionalSender: '',
  entityId: '',
  authKeyServiceExplicit: '',
  authKeyPromotional: '',
  showCampaignNameInReport: false
};

export const Mesg91InitialState = {
  priorityOrder: '',
  authkey: '',
  fromName: '',
  domain: '',
  sender: '',
  showCampaignNameInReport: false
};

export const WonderMailInitialState = {
  priorityOrder: '',
  authkey: '',
  fromName: '',
  fromEmail: '',
  showCampaignNameInReport: false
};

export const KarixEmailInitialState = {
  priorityOrder: '',
  userName: '',
  password: '',
  fromEmail: '',
  fromName: '',
  showCampaignNameInReport: false
};

export const commcryptInitialState = {
  priorityOrder: '',
  user: '',
  password: '',
  sender: '',
  country: '',
  clientsmsid: '',
  entityId: ''
};

export const reportInitialState = {
  to: '',
  cc: []
};

export const amazonInitialState = {
  priorityOrder: '',
  sender: '',
  awsAccessKeyId: '',
  awsSecretAccessKey: '',
  awsRegion: ''
};

export const ameyoInitialState = {
  priorityOrder: '',
  authkey: ''
};
