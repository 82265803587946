import { useCallback, useEffect, useRef, useState } from 'react';
import { QueryFn } from './types';

interface UseMutation<T> {
  data: T | null;
  isLoading: boolean;
  error: null | string;
  mutate: () => Promise<T>;
}

const useMutation = <T>(queryFn: QueryFn<T>): UseMutation<T> => {
  const queryFnRef = useRef(queryFn);
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    queryFnRef.current = queryFn;
  }, [queryFn]);

  const mutate = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await queryFnRef.current();
      setData(response);
      return response;
    } catch (error) {
      setError('Something went wrong!');
      throw new Error('Something went wrong');
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { data, isLoading, error, mutate };
};

export default useMutation;
