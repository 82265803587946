/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import AdditionalTextStyles from './AdditionalText.module.css';
import 'react-quill/dist/quill.snow.css';
import Editor from './Editor/Editor';
const AdditionalText = ({
  page,
  setPage,
  additionalText,
  setAdditionalText,
  isEdit,
  isValidText,
  setIsValidText
}) => {
  const renderEditor = () => {
    return (
      <Editor
        page={page}
        setPage={setPage}
        additionalText={additionalText}
        setAdditionalText={setAdditionalText}
        isValidText={isValidText}
        setIsValidText={setIsValidText}
      />
    );
  };
  return (
    <div
      className={AdditionalTextStyles.container}
      style={isEdit ? { pointerEvents: 'none' } : {}}
    >
      <b>Add Additional Text</b>
      <span className={AdditionalTextStyles.description}>
        This text will appear only above the footer part
      </span>
      {renderEditor(isEdit)}
    </div>
  );
};

export default AdditionalText;
