import React from 'react';
import * as styles from '../../../styles/TableElements.module.scss';
import { Button } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import _ from 'lodash';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import qs from 'query-string';
function ColumnData(history, kam) {
  return [
    {
      id: 'userName',
      Header: function userName() {
        return (
          <div className={styles.headerTitle}>
            <span className={styles.centerAlign}>KAM Name</span>
          </div>
        );
      },
      accessor: d => d.userName,
      show: true
    },
    {
      id: 'kamType',
      Header: function kamType() {
        return (
          <div className={styles.headerTitle}>
            <span className={styles.centerAlign}>KAM Type</span>
          </div>
        );
      },
      accessor: d => d.kamUserType,
      show: true
    },
    {
      id: 'cluster',
      Header: function cluster() {
        return (
          <div className={styles.headerTitle}>
            <span className={styles.centerAlign}>Cluster</span>
          </div>
        );
      },
      accessor: d => d.cluster,
      show: true
    },
    {
      id: 'distanceTravelled',
      Header: function distanceTravelled() {
        return (
          <div className={styles.headerTitle}>
            <span className={styles.centerAlign}>Total distance travel</span>
          </div>
        );
      },
      accessor: d => d.distanceTravelled,
      show: true
    },
    {
      id: 'totalDaysClaimed',
      Header: function totalDaysClaimed() {
        return (
          <div className={styles.headerTitle}>
            <span className={styles.centerAlign}>Total claimed</span>
          </div>
        );
      },
      accessor: d => d.totalDaysClaimed,
      show: true
    },
    {
      id: 'totalDaysUnclaimed',
      Header: function totalDaysUnclaimed() {
        return (
          <div className={styles.headerTitle}>
            <span className={styles.centerAlign}>Total unclaimed</span>
          </div>
        );
      },
      accessor: d => d.totalDaysUnclaimed,
      show: true
    },
    {
      id: 'action',
      Header: function action() {
        return (
          <div className={styles.headerTitle}>
            <span className={styles.centerAlign}> Action</span>
          </div>
        );
      },
      accessor: d => d.isActionNeeded,
      Cell: function isActionNeeded(d) {
        return (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {_.get(d, 'original.isActionNeeded') ? (
              <>
                <NotificationsIcon style={{ fill: '#FF3434' }} />{' '}
                <span style={{ marginLeft: 5 }}>Need Action</span>
              </>
            ) : (
              <>
                <CheckCircleIcon color="primary" style={{ fill: '#74C325' }} />
                <span style={{ marginLeft: 5 }}>No Action</span>
              </>
            )}
            {d.original?.action}
          </span>
        );
      },
      show: true
    },
    {
      accessor: 'checkClaims',
      resizable: false,
      Cell: function checkClaims(d) {
        return (
          <Button
            style={{
              background: '#F47E20',
              color: 'white',
              textTransform: 'capitalize'
            }}
            onClick={e => {
              e.preventDefault();
              history.push(
                `/kam-expense/claims?kam=${_.get(
                  d,
                  'original.userName'
                )}&toDate=${kam.toDate}&fromDate=${kam.fromDate}&id=${
                  qs.parse(history.location.search).id
                }`
              );
            }}
          >
            Check claims
          </Button>
        );
      },
      show:
        _.get(history, 'location.pathname') !== '/kam-expense/claims'
          ? true
          : false
    }
  ];
}

ColumnData.displayName = 'ColumnData';

export default ColumnData;
