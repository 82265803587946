import React from 'react';
import moment from 'moment';
import { Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import fonts from '../../assets/Fonts';
import Warranty from './warranty';
import { GetBarCode } from '../utils';
import Disclimer from '../../assets/disclimer.png';
import RupeeFit from '../../assets/rupeeFit.png';
import {
  getSplitDescription,
  truncatedNumber,
  truncateString
} from '../../../../utils';
import _ from 'lodash';

Font.register(fonts);

const styles = StyleSheet.create({
  container: {
    fontFamily: 'OpenSans',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  card: {
    margin: '2mm 1mm',
    height: '144.5mm',
    width: '102.66mm',
    border: '1 solid #000000',
    display: 'flex'
  },
  qrwithname: {
    display: 'flex',
    flexDirection: 'row',
    height: '48.8mm',
    borderBottom: '1 solid #000000'
  },
  qrcode: {
    width: '48.8mm',
    height: '48.8mm',
    padding: '2mm 1mm'
  },
  brandName: {
    fontWeight: 'bold'
  },
  productname: {
    fontSize: '10.76pt',
    color: 'black',
    width: '153px',
    padding: '5mm 3mm 0 3mm',
    borderLeft: '1 solid #000000',
    display: 'flex',
    flexDirection: 'column'
  },
  descriptionblock: {
    fontWeight: 'normal',
    height: '97px',
    width: '102mm',
    display: 'flex',
    fontSize: '8.78pt',
    flexDirection: 'row',
    borderBottom: '1 solid #000000',
    justifyContent: 'space-between'
  },
  description: {
    padding: '3mm 2mm',
    width: '65mm'
  },
  deals: {
    justifyContent: 'flex-end',
    paddingBottom: '4mm',
    paddingLeft: '4mm',
    fontSize: '12.69pt',
    width: '35mm'
  },
  mrpText: {
    fontWeight: 'bold',
    color: 'black'
  },
  rupeeSmall: {
    width: '3.5mm',
    height: '3.5mm'
  },
  lineHeight: { height: '5.5mm' },
  priceBlock: {
    borderBottom: '1 solid #000000',
    display: 'flex',
    flexDirection: 'row'
  },
  rupeeicon: {
    fontSize: '17.3pt',
    width: '4mm',
    paddingTop: '2mm'
  },
  price: {
    fontSize: '80.1',
    textAlign: 'left',
    fontWeight: 'bold',
    color: 'black',
    paddingLeft: '1mm',
    top: -20,
    lineHeight: 1
  },
  inclTax: {
    paddingTop: '24mm',
    left: -25,
    fontSize: '4.6pt'
  },
  // Footer
  footer: {
    padding: '2mm'
  },
  warrantyWithBarcode: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  warrantyText: {
    fontSize: '6.64pt',
    color: '#1A1818',
    opacity: '1'
  },
  itemDetails: {
    fontSize: '6.84pt',
    top: 16
  },
  itemCode: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  disclimerIcon: {
    width: '3.75mm',
    height: '3.75mm',
    left: 5
  },
  warrantyContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  warranty: {
    height: '14.7mm',
    width: '14.7mm',
    margin: '1mm'
  },
  warrantyDuriation: {
    position: 'absolute',
    height: '9.5mm',
    width: '9.5mm',
    fontWeight: 'bold',
    margin: '3mm',
    backgroundColor: '#fff',
    borderRadius: '50',
    alignItems: 'center',
    justifyContent: 'center'
  },
  warrantyValue: { fontSize: '19.12pt', left: -2 },
  warrantyYear: { fontSize: '4.64pt', top: -5, left: -1 },
  barcode: {
    width: '34.7mm',
    height: '14.2mm',
    padding: '1mm 0mm 0mm 0mm',
    left: 2,
    top: 6
  }
});

const getA6Layout = (count, data) => {
  return count.map(id => (
    <View key={id}>
      {data?.map(item => (
        <View style={styles.card} key={item?.id}>
          <View style={styles.qrwithname}>
            <View style={styles.qrcode}>
              <Image src={item.image} />
            </View>
            <View style={styles.productname}>
              <Text>
                {truncateString(item?.product?.masterData?.name, 130)}
              </Text>
              {/*<Text style={styles.discList}>
                          {truncateString(
                            item?.description ||
                              item?.product?.masterData?.description ||
                              '',
                            130
                          )}
                        </Text>*/}
            </View>
          </View>
          <View style={styles.descriptionblock}>
            <View style={styles.description}>
              {/* <Text style={styles.lineHeight}>
                          {item?.description ||
                            item?.product?.masterData?.description ||
                            ''}
                        </Text> */}
              <View style={{ fontSize: '8pt', lineHeight: 2 }}>
                {getSplitDescription(item.description, 5).map((line, index) => (
                  <Text key={index}>+ {line}</Text>
                ))}
              </View>
            </View>
            <View style={styles.deals}>
              {truncatedNumber(item?.product?.mrp, 6) > 0 ? (
                <Text style={{ ...styles.mrp }}>
                  <Text style={styles.mrpText}>MRP: </Text>
                  <Image style={styles?.rupeeSmall} src={RupeeFit} />
                  {truncatedNumber(item?.product?.mrp, 6)}
                </Text>
              ) : (
                <Text />
              )}
            </View>
          </View>
          <View style={styles.priceBlock}>
            <Text style={styles.rupeeicon}>
              <Image src={RupeeFit} />
            </Text>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={styles.price}>
                {truncatedNumber(item?.product?.mop, 6)}
              </Text>
              <Text style={styles.inclTax}>*Incl. Tax</Text>
            </View>
          </View>
          <View style={styles?.footer}>
            <View style={styles.warrantyWithBarcode}>
              <View style={styles.warrantyContainer}>
                <Warranty warranty={item.warranty} styles={styles} />
              </View>
              <View>
                <View style={styles.barcode}>
                  {_.isEmpty(item.barcode) ? (
                    <></>
                  ) : (
                    <Image src={GetBarCode(item?.barcode)} />
                  )}
                </View>
              </View>
            </View>
            <View
              style={
                item?.warranty
                  ? styles?.itemDetails
                  : { ...styles?.itemDetails, top: 30 }
              }
            >
              <View style={styles.itemCode}>
                <Text>
                  {`${item?.sku}  |  ${item.class}  |  A  |  ${moment(
                    item?.date
                  ).format('DD.MM.YYYY')}  |  ${moment().format('DD.MM.YYYY')}`}
                </Text>
                <Image style={styles.disclimerIcon} src={Disclimer} />
              </View>
            </View>
          </View>
        </View>
      ))}
    </View>
  ));
};

const A6Portrait = ({ data, count, ...rest }) => {
  const splattedArray = _.chunk(data, 4);
  return (
    <Page>
      <View style={styles.container}>
        {count &&
          _.map(splattedArray, (chunkedData, key) => {
            const chunkedRowArray = _.chunk(chunkedData, 2);
            const firstHalf = getA6Layout(count, chunkedRowArray[0]);
            const secondHalf = getA6Layout(count, chunkedRowArray[1]);
            return (
              <View
                style={{ flexDirection: 'row', flexWrap: 'wrap' }}
                key={key}
              >
                <View>{firstHalf}</View>
                <View>{secondHalf}</View>
              </View>
            );
          })}
      </View>
    </Page>
  );
};
export { A6Portrait };
