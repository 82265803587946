import React, { useState } from 'react';
import { makeStyles, Paper, Button, Snackbar } from '@material-ui/core';
import _ from 'lodash';
import config from '../../../config';
import RejectModal from './RejectModal';
import saveAs from 'file-saver';
import MuiAlert from '@material-ui/lab/Alert';

const styles = makeStyles({
  container: {
    height: 'auto',
    width: '100%'
  },
  card: {
    borderLeft: props => `4px solid ${props.borderColor}`,
    height: '100%',
    padding: 20
  },
  pending: {
    width: '122px',
    height: '46px',
    background: '#FFEF96 0% 0% no-repeat padding-box',
    border: '1px solid #FFFFFF',
    borderRadius: '23px',
    opacity: 1,
    color: 'black',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#FFEF96 0% 0% no-repeat padding-box'
    }
  },
  billSection: {
    margin: '20px 0px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  expense: {
    width: '100%',
    height: 'auto',
    background: '#f1f1ec85 0% 0% no-repeat padding-box'
  }
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ClaimCard = props => {
  const { claims, fetchClaimData, kamClaims } = props;
  const [openRejection, setRejection] = useState(false);
  const [reason, setReason] = useState('');
  const [error, setError] = useState('');
  const classes = styles(props);
  const status = [
    { title: 'Travelled Date', value: claims?.travelledDate },
    {
      title: 'Travelled KM for the day',
      value: `${claims.distanceTravelled} Km`
    },
    {
      title: 'Claim amount',
      value:
        (_.get(claims, 'expense.totalClaimAmount') &&
          `₹${_.get(claims, 'expense.totalClaimAmount')}`) ||
        'Expense Not available'
    },
    { title: 'Claimed Date', value: _.get(claims, 'expense.submissionDate') }
  ];
  const expenses = [
    {
      name: 'TA Expenses',
      price:
        (_.get(claims, 'expense.travelAllowance') &&
          `₹${_.get(claims, 'expense.travelAllowance')}`) ||
        'Expense Not Available'
    },
    {
      name:
        'Misc.Expenses(Note: Total Misc. expenses 300. Bill limit is 200 only)',
      price:
        (_.get(claims, 'expense.miscellaneousAllowance') &&
          `₹${_.get(claims, 'expense.miscellaneousAllowance')}`) ||
        'Expense not Available'
    },
    {
      name: 'Daily allowance',
      price:
        (_.get(claims, 'expense.dailyAllowance') &&
          `₹${_.get(claims, 'expense.dailyAllowance')}`) ||
        'Expense not Available'
    }
  ];

  const [isSuccess, setSuccess] = useState(false);

  const handleClick = () => {
    setSuccess(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccess(false);
  };

  const style = {
    buttonStyles: {
      width: '122px',
      height: '46px',
      background: '#FFEF96 0% 0% no-repeat padding-box',
      border: '1px solid #FFFFFF',
      borderRadius: '23px',
      opacity: 1,
      color: 'black',
      textTransform: 'capitalize',
      '&:hover': {
        background: '#FFEF96 0% 0% no-repeat padding-box'
      },
      marginTop: 20
    }
  };

  const approveClaim = id => {
    fetch(`${config.casaBeatUrl}/expense/${id}/approve`, {
      method: 'POST'
    })
      .then(async response => {
        const body = await response.json();
        if (_.get(body, 'data[0].approvalStatus') === 'APPROVED') {
          fetchClaimData(kamClaims.fromDate, kamClaims.toDate);
        }
        handleClick();
      })
      .catch(err => {
        console.error(err);
      });
  };

  const renderApprovalStatus = () => {
    if (_.get(claims, 'expense.approvalStatus') === 'APPROVED') {
      return (
        <Button
          style={{
            ...style.buttonStyles,
            background: '#7EF8B7 0% 0% no-repeat padding-box',
            pointerEvents: 'none',
            cursor: 'none'
          }}
        >
          Approved
        </Button>
      );
    } else if (_.get(claims, 'expense.approvalStatus') === 'REJECTED') {
      return (
        <Button
          style={{
            ...style.buttonStyles,
            background: '#FF9393',
            pointerEvents: 'none',
            cursor: 'none'
          }}
        >
          Rejected
        </Button>
      );
    } else {
      return (
        <Button
          style={{
            ...style.buttonStyles,
            background: '#FFEF96',
            pointerEvents: 'none',
            cursor: 'none'
          }}
        >
          Pending
        </Button>
      );
    }
  };

  return (
    <Paper classes={{ root: classes.container }} elevation={3}>
      <div className={classes.card}>
        <div className={classes.status}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {status.map((item, i) => (
              <div key={i}>
                <div style={{ fontWeight: 'bold', marginBottom: 10 }}>
                  {item.title}
                </div>
                <div
                  style={{ height: 46, display: 'flex', alignItems: 'center' }}
                >
                  {item.value}
                </div>
              </div>
            ))}
            <div>
              <div style={{ fontWeight: 'bold' }}>Approval Status</div>
              <div
                style={{ height: 46, display: 'flex', alignItems: 'center' }}
              >
                {renderApprovalStatus()}
              </div>
            </div>
          </div>
          <div className={classes.billSection}>
            <div>
              <div style={{ fontWeight: 'bold', marginBottom: 15 }}>
                Bills attached
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {_.get(claims, 'expense.uploadedReceipts').map((path, i) => (
                  <a key={i} href={path}>
                    <div
                      style={{
                        color: '#FC8B00',
                        marginRight: '20px',
                        display: 'flex'
                      }}
                    >
                      <img
                        src={path}
                        style={{ objectFit: 'cover', height: 50, width: 50 }}
                        alt={`image_${path}`}
                      />
                    </div>
                  </a>
                ))}
                <div
                  style={{
                    color: '#FC8B00',
                    marginRight: '18px',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    _.get(claims, 'expense.uploadedReceipts').map((path, i) =>
                      saveAs(path, `image_${path}.jpg`)
                    );
                  }}
                >
                  Download Bill
                </div>
              </div>
            </div>
            {_.get(claims, 'expense.approvalStatus') === 'REJECTED' && (
              <div style={{ marginRight: 20 }}>
                <div style={{ fontWeight: 'bold' }}>Rejected for</div>
                <div style={{ display: 'flex' }}>
                  {_.get(claims, 'expense.rejectReason')}
                </div>
              </div>
            )}
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            open={isSuccess}
            autoHideDuration={4000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="success">
              Your response has been submitted
            </Alert>
          </Snackbar>
          <div className={classes.expense}>
            <div style={{ padding: 20, marginBottom: 5 }}>
              {expenses.map((item, i) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '5px 0px'
                  }}
                  key={i}
                >
                  <div style={{ fontWeight: 'bold' }}>{item.name}</div>
                  <div>{item.price}</div>
                </div>
              ))}
              <div
                style={{
                  borderTop: '2px solid #8080803d',
                  borderBottom: '2px solid #8080803d',
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '10px 0px'
                }}
              >
                <div style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                  Total Claim amount
                </div>
                <div>
                  <strong>
                    {(_.get(claims, 'expense.totalClaimAmount') &&
                      `₹${_.get(claims, 'expense.totalClaimAmount')}`) ||
                      'Expense Not Available'}
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <RejectModal
            openRejection={openRejection}
            setOpen={setRejection}
            reason={reason}
            setReason={setReason}
            error={error}
            claims={claims}
            setError={setError}
            fetchClaimData={fetchClaimData}
            kamClaims={kamClaims}
            setSuccess={setSuccess}
          />
          {claims?.actionNeeded && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                style={{
                  ...style.buttonStyles,
                  background: '#7EF8B7 0% 0% no-repeat padding-box',
                  marginRight: 20
                }}
                onClick={() => approveClaim(_.get(claims, 'expense.id'))}
              >
                Approve
              </Button>
              <Button
                style={{
                  ...style.buttonStyles,
                  background: '#FF9393 0% 0% no-repeat padding-box'
                }}
                onClick={() => setRejection(true)}
              >
                Reject
              </Button>
            </div>
          )}
        </div>
      </div>
    </Paper>
  );
};

export default ClaimCard;
