import React from 'react';
import * as journeyListItem from '../JourneyListItem/JourneyListItem.module.css';

export default function HeadRow({ selected }) {
  return (
    <div className={journeyListItem.container}>
      <div
        className={journeyListItem.item}
        style={{
          color: 'black',
          fontWeight: 'bold',
          minWidth: '200px',
          maxWidth: '200px'
        }}
      >
        Journey Name
      </div>
      <div
        className={journeyListItem.item}
        style={{ color: 'black', fontWeight: 'bold' }}
      >
        Start Date
      </div>
      <div
        className={journeyListItem.item}
        style={{ color: 'black', fontWeight: 'bold' }}
      >
        End Date
      </div>
      <div
        className={journeyListItem.item}
        style={{ color: 'black', fontWeight: 'bold' }}
      >
        No. of people completed journey
      </div>
      <div
        className={journeyListItem.item}
        style={{ color: 'black', fontWeight: 'bold' }}
      >
        No. of people in journey
      </div>
      <div
        className={journeyListItem.item}
        style={{ color: 'black', fontWeight: 'bold' }}
      >
        % of positive outcome
      </div>
      <div
        className={journeyListItem.item}
        style={{
          color: 'black',
          fontWeight: 'bold',
          minWidth: '200px',
          maxWidth: '200px'
        }}
      >
        Created by
      </div>
      <div
        className={journeyListItem.item}
        style={{
          color: 'black',
          fontWeight: 'bold',
          minWidth: '200px',
          maxWidth: '200px'
        }}
      >
        Status
      </div>
      <div
        className={journeyListItem.item}
        style={{ color: 'black', fontWeight: 'bold' }}
      ></div>
    </div>
  );
}
