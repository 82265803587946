import NodeTypes from '../BaseNodeModel/NodeTypes';
import _ from 'lodash';
import { getTreeFromModel } from './JourneyTreeUtils';

const getCampaignTree = journeyComponentTree => {
  const queue = [journeyComponentTree];
  const journeyComponents = [];
  while (!_.isEmpty(queue)) {
    const journeyComponentObj = queue.shift();

    journeyComponents.push(_.omit(journeyComponentObj, 'children'));

    _.forEach(journeyComponentObj.children, child => {
      queue.push(child);
    });
  }
  return journeyComponents;
};
const JourneyComponent = class {
  constructor() {
    this.ruleNode = '';
    this.actionNodes = [];
    this.waitNode = '';
    this.children = [];
    this.parentNodeId = '';
    this.journeyId = '';
  }
  setWaitNode(waitNode) {
    this.waitNode = _.omit(waitNode, 'children');
  }
  setRuleNode(ruleNode) {
    this.ruleNode = _.omit(ruleNode, 'children');
  }
  setActionNode(actionNode) {
    this.actionNodes.push(_.omit(actionNode, 'children'));
  }
  setChildren(child) {
    this.children.push(child);
  }
};
const getJourneyComponents = (journeyTree, journeyId) => {
  const journeyComponentTree = new JourneyComponent();
  journeyComponentTree.setRuleNode(journeyTree);
  journeyComponentTree.journeyId = journeyId;
  const queue = [[journeyTree, journeyComponentTree]];

  while (!_.isEmpty(queue)) {
    const [node, parent] = queue.shift();
    _.forEach(node.children, child => {
      switch (child.data.className) {
        case NodeTypes.WAIT_NODE: {
          _.map(child.children, children => {
            const journeyComponentObj = new JourneyComponent();
            journeyComponentObj.setWaitNode(child);
            journeyComponentObj.journeyId = journeyId;
            parent.setChildren(journeyComponentObj);
            journeyComponentObj.parentNodeId = parent.ruleNode.id;
            journeyComponentObj.setRuleNode(children);
            queue.push([children, journeyComponentObj]);
          });

          break;
        }
        case NodeTypes.ACTION_NODE: {
          parent.setActionNode(child);
          queue.push([child, parent]);
          break;
        }
        case NodeTypes.DECISION_NODE: {
          parent.setRuleNode(child);
          queue.push([child, parent]);
          break;
        }
        default: {
          parent.setActionNode(child);
          queue.push([child, parent]);
          break;
        }
      }
    });
  }
  return journeyComponentTree;
};
export const getCampaigns = (model, journeyId) => {
  const journeyTree = getTreeFromModel(model);
  return getCampaignTree(getJourneyComponents(journeyTree, journeyId));
};
