import React, { Context } from 'react';
import {
  CheckoutOption,
  Themes
} from '../../../models/FlipbookV2/flipbookV2.model';
import { noop } from 'lodash';

export enum FlipbookMode {
  EDIT,
  CUSTOMER,
  PREVIEW
}
export enum DeviceType {
  MOBILE = 1,
  DESKTOP = 2
}

export type FlipbookConfigData = {
  id: number;
  flipbookToken?: string;
  flipbookMode: FlipbookMode;
  isCustomerView?: boolean;
  deviceType: DeviceType;
  tenantId: number;
  isEmptyPagesHided: boolean;
  checkoutOption: CheckoutOption;
  name: string;
  tenantStoreId?: string;
  tenantBrandBusinessId?: number;
  theme: Themes;
  oddBackgroundUrl?: string;
  evenBackgroundUrl?: string;
  isUserStoreSelectionAvailable: boolean;
  isActive: boolean;
  groupingHierarchy: string[];
  coverImageUrl: string;
  shortCode: string;
  description: string;
};
type FlipbookContext = {
  flipbookContext: FlipbookConfigData | undefined;
  setFlipbookContext: (updatedContext: FlipbookConfigData | undefined) => void;
  updateFlipbook: () => void;
};
export const FlipbookContext: Context<FlipbookContext> = React.createContext<
  FlipbookContext
>({
  flipbookContext: undefined,
  setFlipbookContext: noop,
  updateFlipbook: noop
});
