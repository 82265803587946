/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import _ from 'lodash';
import React, { useEffect } from 'react';

import classes from './InputRow.module.css';

const CustomSelect = ({ detail, onSelect }) => {
  useEffect(() => {
    if (_.isEmpty(detail.value)) {
      onSelect(detail.options[0]);
    }
  }, [detail.options]);
  return (
    <div key={detail.name} className={classes.container}>
      <span className={classes.title}>{detail.label || detail.name}</span>
      <select
        className={[classes.customInput, classes.customDropdownArrow].join(' ')}
        value={detail.value}
        onChange={event => onSelect(event.target.value)}
      >
        {_.map(detail.options, option => (
          <option className={classes.option} key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CustomSelect;
