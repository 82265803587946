import React, { FC } from 'react';
import { Button, Card, Modal } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import layoutSingle from '../../Assets/images/layout_single.svg';
import layoutDouble from '../../Assets/images/layout_double.svg';
import layoutSingleActive from '../../Assets/images/layout_single_active.svg';
import layoutDoubleActive from '../../Assets/images/layout_double_active.svg';
import cancelIcon from '../../Assets/images/icons/cancel_icon.svg';
import { LayoutType } from '../../../models/FlipbookV2/FlipbookV2Layout.model';

interface LayoutSelectionModalProp {
  isModalOpen: boolean;
  setModalClose: (data: boolean) => void;
  onClickLayoutAddition: (data: LayoutType) => void;
  isEditLayoutMode: boolean;
  previousSelection?: LayoutType;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    }
  })
);

export const LayoutSelectionModal: FC<LayoutSelectionModalProp> = ({
  isModalOpen,
  setModalClose,
  onClickLayoutAddition,
  isEditLayoutMode,
  previousSelection
}) => {
  const classes = useStyles();
  const [selectedLayout, setSelectedLayout] = React.useState(
    previousSelection || LayoutType.SINGLE
  );

  const handleModalClose = () => {
    setModalClose(false);
  };

  const onClickLayoutCard = (layoutType: LayoutType) => {
    setSelectedLayout(layoutType);
  };

  const isLayoutSelected = (layoutType: LayoutType) => {
    return selectedLayout === layoutType;
  };

  function layoutSelection() {
    return (
      <div
        className={`modal-container ${
          isEditLayoutMode ? 'modal-box-shadow' : 'modal-add-layout-box-shadow'
        }`}
      >
        <div className={'popup-header-section'}>
          <h2 className={'mail-header text-center'}>
            {'Pick Your Page Layout'}
          </h2>
          <img
            className={'popup-close-section'}
            src={cancelIcon}
            alt={'close'}
            onClick={handleModalClose}
          />
        </div>
        <div className={'layout-selection'}>
          <Card
            className={`layout-selection-single-card ${
              isLayoutSelected(LayoutType.SINGLE) ? 'primary-color-border' : ''
            }`}
            onClick={() => onClickLayoutCard(LayoutType.SINGLE)}
          >
            <img
              src={
                isLayoutSelected(LayoutType.SINGLE)
                  ? layoutSingleActive
                  : layoutSingle
              }
              alt={'single'}
            />
            <p className={'flipbook-text'}>Single Tile</p>
          </Card>
          <Card
            className={`layout-selection-double-card ${
              isLayoutSelected(LayoutType.DOUBLE) ? 'primary-color-border' : ''
            }`}
            onClick={() => onClickLayoutCard(LayoutType.DOUBLE)}
          >
            <img
              src={
                isLayoutSelected(LayoutType.DOUBLE)
                  ? layoutDoubleActive
                  : layoutDouble
              }
              alt={'double'}
            />
            <p className={'flipbook-text'}>Two Tiles</p>
          </Card>
        </div>
        <div className={'text-center'}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onClickLayoutAddition(selectedLayout)}
            style={{ marginTop: '2%' }}
          >
            {isEditLayoutMode ? 'Edit Layout' : 'Add Layout'}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Modal
        // hideBackdrop={true}
        // keepMounted={true}
        open={isModalOpen}
        onClose={handleModalClose}
        onBackdropClick={handleModalClose}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
      >
        {layoutSelection()}
      </Modal>
    </div>
  );
};
