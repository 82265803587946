import React, { useState } from 'react';
import _ from 'lodash';
import { Button } from '@material-ui/core';
import CSVReader from 'react-csv-reader';
import fileDownload from 'js-file-download';

const SelectProductSkus = (props: {
  isExclusion: any;
  setSelectedProductFilterOptions: any;
  setSelectedExclusionProductFilterOptions: any;
}) => {
  let {
    isExclusion,
    setSelectedProductFilterOptions,
    setSelectedExclusionProductFilterOptions
  } = props;
  let [parsedSkus, setParsedSkus] = useState<string[]>([]);

  const addSkuConditions = (data: Array<any>) => {
    const skus = _.compact(_.map(data, 'sku'));
    setParsedSkus(skus);
    isExclusion
      ? setSelectedExclusionProductFilterOptions((ss: any) => ({
          ...ss,
          sku: _.map(skus, k => ({ label: k, value: k }))
        }))
      : setSelectedProductFilterOptions((ss: any) => ({
          ...ss,
          sku: _.map(skus, k => ({ label: k, value: k }))
        }));
  };

  function triggerTemplateDownload() {
    fileDownload('sku', 'product-sku-template-with-header.csv', 'text/x-csv');
  }

  return (
    <div className="clearfix row justify-content-center">
      <div className={'col-12 d-flex justify-content-center'}>
        <Button variant="outlined" onClick={triggerTemplateDownload}>
          Download template file
        </Button>
      </div>
      <div className={'col-12 d-flex justify-content-center my-2'}>
        <CSVReader
          key={`product_file`}
          parserOptions={{ header: true, skipEmptyLines: 'greedy' }}
          cssInputClass={'mx-2 border rounded p-1'}
          label={'Upload sku list'}
          onFileLoaded={addSkuConditions}
        />
      </div>
      {_.some(parsedSkus) && (
        <div className={'col-12 d-flex justify-content-center'}>
          {' '}
          {_.size(parsedSkus)} skus
        </div>
      )}
    </div>
  );
};

export default SelectProductSkus;
