import config from '../../config';
import CampaignReport, {
  CampaignOverallReport,
  CampaignReportResponse
} from '../../models/campaign/CampaignReport';
import { has, map, set } from 'lodash';
import { CampaignReportType } from '../../components/Campaign/shared/utils/contants';
import moment from 'moment';

export const generateReport = async (
  campaignId: string,
  buId: string,
  type: string,
  roiCost = 0.2
): Promise<{ scheduleDate: string }> => {
  const key = getReportKey(type);
  const res = await fetch(
    `${config.ruleServerUrl}/users/reports/download/${key}/${buId}?id=${campaignId}&smsCost=${roiCost}`,
    { credentials: 'include' }
  );

  if (!res.ok) {
    throw new Error('Something went wrong!');
  }

  return res.json();
};

const formatDateForReport = (date: string) => {
  const format = 'YYYY-MM-DD';
  return moment(date).format(format);
};

export const generateOverallCampaignReport = async (
  businessId: any,
  startDate: any,
  endDate: any,
  name: string,
  status: string,
  type: string
): Promise<{ scheduleDate: string }> => {
  startDate = formatDateForReport(startDate);
  endDate = formatDateForReport(endDate);
  const res = await fetch(
    `${config.ruleServerUrl}/users/reports/download/overall_campaign_report/${businessId}?type=${type}&status=${status}&name=${name}&startDate=${startDate}&endDate=${endDate}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  );

  if (!res.ok) {
    throw new Error('Something went wrong!');
  }

  return res.json();
};

interface GetCampaignReports {
  campaignId: string;
  page: number;
  offset: number;
}

export const getCampaignOverallReports = async (data: {
  page: number;
  offset: number;
}): Promise<CampaignReportResponse<CampaignOverallReport>> => {
  const response = await fetch(
    `${config.ruleServerUrl}/users/reports/view/downloads/overall_campaign_report?offset=${data.offset}&page=${data.page}`,
    { credentials: 'include' }
  );
  if (!response.ok) {
    throw new Error('Something went wrong!');
  }
  const modifyResponse = (result: any) => {
    return result.then((res: any) => {
      return {
        count: result.length,
        rows: res
      };
    });
  };
  const result: any = response.json();
  return modifyResponse(result);
};

const getCampaignReportByType = async (
  data: GetCampaignReports,
  type: string
): Promise<CampaignReportResponse<CampaignReport>> => {
  const response = await fetch(
    `${config.ruleServerUrl}/users/reports/view/downloads/${type}?offset=${data.offset}&page=${data.page}&columnName=campaignId&columnValue=${data.campaignId}`,
    { credentials: 'include' }
  );
  if (!response.ok) {
    throw new Error('Something went wrong!');
  }

  //Dead code - Just to avail backward compatibility
  const modifyResponse = (result: any) => {
    return result.then((res: any) => {
      return {
        count: result.length,
        rows: map(res, r => {
          set(r, 'data.campaignId', data.campaignId);
          if (!has(r, 'data.campaignName')) {
            set(r, 'data.campaignName', '');
          }
          return r;
        })
      };
    });
  };
  const result: any = response.json();
  return modifyResponse(result);
};

export const getReportKey = (type: string) => {
  switch (type) {
    case CampaignReportType.DEFAULT:
      return 'individualized_campaign_report';
    case CampaignReportType.VARIANT:
      return 'campaign_variant_report';
    case CampaignReportType.CAMPAIGN_TIER:
      return 'tier_split_campaign_report';
    case CampaignReportType.CAMPAIGN_RFM:
      return 'rfm_split_campaign_report';
    case CampaignReportType.VARIANT_TIER:
      return 'tier_split_variant_report';
    case CampaignReportType.VARIANT_RFM:
      return 'rfm_split_variant_report';
    default:
      return 'individualized_campaign_report';
  }
};

export const getHistory = (data: GetCampaignReports, type: string) => {
  const key = getReportKey(type);
  return getCampaignReportByType(data, key);
};
