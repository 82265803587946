import React from 'react';
import { IconButton, Popover } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import SampleFlipbookBgTemplate from '../../Assets/images/sample_template_flipbook_bg.png';
import { anchorOriginObj, getColor, transformOriginObj } from './utiles';

export const HelpPopover = () => {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const helpPopoverId = isOpen ? 'simple-popover' : undefined;

  const onClickOpen = (value: any) => {
    setIsOpen(true);
    setAnchorEl(value);
  };

  function helpPopoverComponent() {
    return (
      <div className={'flipbook-settings-popover-container'}>
        <div className={'my-4'}>
          <h3 className={'flipbook-popover-text-header uppercase'}>
            Additional Info
          </h3>
        </div>
        <div className={'my-4'}>
          <h3 className={'flipbook-popover-text-sub-header'}>
            Guidelines to{' '}
            <span className={'highlight-text'}>Upload background image</span>
          </h3>
        </div>
        <div className={'my-4'}>
          <p className={'flipbook-popover-text-paragraph'}>
            - Download template from{' '}
            <a
              href={SampleFlipbookBgTemplate}
              download={'sample_flipbook_bg_template.png'}
              className={'hyperlink-primary'}
            >
              here.
            </a>
          </p>
          <p className={'flipbook-popover-text-paragraph'}>
            - Create a <span className={'highlight-text'}>white</span>{' '}
            background image with dimensions{' '}
            <span className={'highlight-text'}>466w x 656h.</span>
          </p>
          <p className={'flipbook-popover-text-paragraph'}>
            - Place images (logo, years of service, etc) and texts of your
            choice in the dotted area defined in the{' '}
            <a
              href={SampleFlipbookBgTemplate}
              download={'sample_flipbook_bg_template.png'}
              className={'hyperlink-primary'}
            >
              template
            </a>{' '}
            and leave the rest blank.
          </p>
          <p className={'flipbook-popover-text-paragraph'}>
            - The background image size should not exceed 1 MB.
          </p>
          <p className={'flipbook-popover-text-paragraph'}>
            - Upload the image as background for odd or even page of the
            flipbook.
          </p>
          <div className={'my-4'}>
            <h3 className={'flipbook-popover-text-sub-header'}>
              To Set background navigate to:
            </h3>
          </div>
          <p className={'flipbook-popover-text-paragraph'}>
            <span className={'highlight-text'}>Settings </span> {'>'}{' '}
            <span className={'highlight-text'}> Upload Background image </span>{' '}
            {'>'} <span className={'highlight-text'}> click Add icon </span>
          </p>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className={'flipbook-bar-icon'}>
        <IconButton
          style={{ color: getColor(isOpen) }}
          className={'outline-none'}
          aria-label="helpOutlineIcon"
          onClick={e => onClickOpen(e.currentTarget)}
        >
          <HelpIcon />
        </IconButton>
      </div>
      <Popover
        id={helpPopoverId}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={e => setIsOpen(false)}
        anchorOrigin={anchorOriginObj}
        transformOrigin={transformOriginObj}
      >
        {helpPopoverComponent()}
      </Popover>
    </div>
  );
};

export default HelpPopover;
