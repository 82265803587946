import { Chip, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';

interface Props {
  items: Array<any>;
  handleItemsChange: (p: any) => void;
  selectedItems: Array<any>;
  limitTags: number;
}

const useStyles = makeStyles(() => ({
  chipLabel: {
    maxWidth: '100px', // Set the desired width before truncating
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));

const SelectGrouped: React.FC<Props> = ({
  items,
  handleItemsChange,
  selectedItems,
  limitTags = 10
}) => {
  const classes = useStyles();
  const renderTags = (_: any, _getTagProps: any) => {
    if (selectedItems.length > limitTags) {
      return (
        <div>
          {selectedItems.slice(0, limitTags).map((tag, index) => (
            <Chip
              classes={{ label: classes.chipLabel }}
              key={tag.reportName}
              label={tag.reportName}
            />
          ))}
          <Chip
            classes={{ label: classes.chipLabel }}
            label={`+${selectedItems.length - limitTags}`}
          />
        </div>
      );
    }

    return (
      <div>
        {selectedItems.map((tag, index) => (
          <Chip
            classes={{ label: classes.chipLabel }}
            key={tag.reportName}
            label={tag.reportName}
          />
        ))}
      </div>
    );
  };

  return (
    <Autocomplete
      id="grouped-demo"
      multiple={true}
      options={items.sort(
        (a: any, b: any) => -b.reportName.localeCompare(a.reportName)
      )}
      groupBy={(option: any) => option.level}
      getOptionLabel={option => option.reportName}
      style={{ width: 300 }}
      limitTags={limitTags}
      renderTags={renderTags}
      selectOnFocus={false}
      renderInput={params => (
        <TextField {...params} label="Additional Fields" variant="outlined" />
      )}
      onChange={(_, value) => handleItemsChange(value as any)} // prints the selected value
    />
  );
};

export default SelectGrouped;
