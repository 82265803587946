import {
  get,
  assign,
  sortBy,
  flatten,
  reduce,
  concat,
  filter,
  map,
  omit,
  values,
  find,
  cloneDeep,
  isEmpty
} from 'lodash';
import { SectionType } from '../../DynamicInvoiceGenerator/InvoiceGenerator';
import Section from '../../DynamicInvoiceGenerator/models/Section';
import SectionField from '../../DynamicInvoiceGenerator/models/SectionField';
import { AdditionalConfigFields } from '../utils/configs';
import { getImageUrl } from './file.service';

type configType = { [key: string]: Section };
const getRequiredFields: (
  fields: SectionField[],
  fieldMapper?: (field: SectionField) => boolean
) => SectionField[] = (
  fields,
  fieldMapper = field => get(field, 'req', false)
) => map(filter(fields, fieldMapper), field => omit(field, 'req'));

const concatFields = (fields: SectionField[], extraFields: SectionField[]) => {
  const reducedFieldsByGetter = reduce(
    concat(extraFields, fields),
    (acc, f) => {
      acc[f.getter] = get(acc, f.getter) ? assign({}, acc[f.getter], f) : f;
      return acc;
    },
    {} as { [key: string]: SectionField }
  );
  return values(reducedFieldsByGetter);
};

const getProcessedTenantConfigFields = (tenantConfig: Section) => {
  const processedFields = reduce(
    tenantConfig.fields,
    (acc, f) => {
      if (f.id === 'logo') {
        acc = flatten([
          {
            ...f,
            getter: '100x40',
            label: ''
          },
          acc
        ]);
      } else acc.push(f);
      return acc;
    },
    [] as SectionField[]
  );
  return processedFields;
};

const sectionConfigProcessors = (
  key: string,
  config: Section,
  extraFields: AdditionalConfigFields
) => {
  const processors: {
    [key: string]: (config: Section) => Section & { canShowSection?: boolean };
  } = {
    [SectionType.TENANT_INFO]: config => {
      const tenantFields = getProcessedTenantConfigFields(config);
      const requiredConfigFields = getRequiredFields(tenantFields);
      return { ...config, fields: requiredConfigFields };
    },
    [SectionType.ORDER_LIST]: config => {
      const additionalFields = get(extraFields, key, []);
      const allFields = concatFields(config.fields, additionalFields);
      const requiredConfigFields = getRequiredFields(
        sortBy(allFields, f => parseInt(f.value!))
      );
      return {
        ...config,
        fields: requiredConfigFields
      };
    },
    [SectionType.MISC_INFO]: config => {
      const requiredConfigFields = getRequiredFields(
        config.fields,
        field =>
          get(field, 'req', false) && !isEmpty(get(field, 'value', false))
      );

      return {
        ...config,
        fields: requiredConfigFields,
        canShowSection: requiredConfigFields.length > 0
      };
    },
    [SectionType.HSN_SUMMARY]: config => ({
      ...config,
      fields: map(config.fields, field => omit(field, 'req'))
    }),
    DEFAULT: config => {
      const { fields } = config;
      const additionalFields = get(extraFields, key, []);
      const allFields = concatFields(fields, additionalFields);
      const requiredConfigFields = getRequiredFields(allFields);
      return {
        ...config,
        fields: requiredConfigFields
      };
    }
  };
  return get(processors, key, processors.DEFAULT)(config);
};

export async function getProcessedTenantInfoConfig(configs: configType) {
  const tenantFields = configs[SectionType.TENANT_INFO].fields;
  const logoField = find(tenantFields, { id: 'logo' })!;
  if (!get(logoField, 'value')) {
    return configs;
  }
  const processedConfigs = cloneDeep(configs);
  const imageUrl = await getImageUrl(logoField);
  processedConfigs[SectionType.TENANT_INFO].fields = reduce(
    tenantFields,
    (acc, f) => {
      if (f.id === 'logo') {
        acc = flatten([
          {
            ...f,
            getter: '100x40',
            label: '',
            value: imageUrl
          },
          ...acc
        ]);
      } else acc.push(f);
      return acc;
    },
    [] as SectionField[]
  );

  return processedConfigs;
}

export const getProcessedConfigs = (
  configs: configType,
  extraFields: AdditionalConfigFields
) => {
  const processedConfigs: configType = {};
  Object.entries(configs).forEach(([key, config]) => {
    const { canShowSection, ...processedSection } = sectionConfigProcessors(
      key,
      config,
      extraFields
    );
    if (config.fields.length > 0 && (canShowSection ?? true))
      processedConfigs[key] = processedSection;
  });
  return processedConfigs;
};
