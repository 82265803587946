import { Flipbook } from '../../models/FlipbookV2/flipbookV2.model';
import config from '../../config';
import { DeviceType } from '../../components/Flipbook/Hooks/FlipbookContext';
import FlipbookPageGradient from '../../components/Flipbook/Utiles/FlipbookPageGradient';
import queryString from 'query-string';

function getToken(token: string) {
  return queryString.stringify(
    {
      token: token
    },
    { arrayFormat: 'bracket' }
  );
}

function getTokenForPages(token: string, type?: string, dt?: string) {
  const payload: any = {
    token: token
  };
  if (type) {
    payload['userAccessType'] = type;
  }

  if (dt) {
    payload['dataToken'] = dt;
  }

  return queryString.stringify(payload, { arrayFormat: 'bracket' });
}

function createFlipbook(flipbookProps: Flipbook) {
  return fetch(`${config.ruleServerUrl}/users/flipbook-v2/create`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify(flipbookProps)
  }).then(r => r.json());
}

function updateFlipbook(flipbookProps: Flipbook, flipbookId: number) {
  return fetch(`${config.ruleServerUrl}/users/flipbook-v2/${flipbookId}`, {
    credentials: 'include',
    method: 'PUT',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify(flipbookProps)
  }).then(r => r.json());
}

function getFlipbookById(flipbookId: string) {
  return fetch(`${config.ruleServerUrl}/users/flipbook-v2/${flipbookId}`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  }).then(r => r.json());
}

function getFlipbookByToken(token: string) {
  return fetch(`${config.ruleServerUrl}/flipbook-v2/token?${getToken(token)}`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  }).then(r => r.json());
}
function getFlipbookPages(flipbookId: string, mode: string) {
  const deviceType = FlipbookPageGradient.isMobileView()
    ? DeviceType.MOBILE
    : DeviceType.DESKTOP;
  return fetch(
    `${config.ruleServerUrl}/users/flipbook-v2/pages/${flipbookId}/${deviceType}/${mode}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
}
function getFlipbookPagesByToken(token: string, type?: string, dt?: string) {
  const deviceType = FlipbookPageGradient.isMobileView()
    ? DeviceType.MOBILE
    : DeviceType.DESKTOP;
  return fetch(
    `${
      config.ruleServerUrl
    }/flipbook-v2/pages/${deviceType}/token?${getTokenForPages(
      token,
      type,
      dt
    )}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
}
export default {
  createFlipbook,
  updateFlipbook,
  getFlipbookById,
  getFlipbookPages,
  getFlipbookPagesByToken,
  getFlipbookByToken
};
