export interface SectionFieldParams {
  id?: string;
  value: string;
  type?: string;
  label: string;
  getter: string;
  req?: boolean;
}

export default class SectionField {
  id: string;
  value: string;
  type: string;
  label: string;
  getter: string;
  req?: boolean;

  constructor(obj: SectionFieldParams) {
    const {
      value = '',
      type = 'text',
      label = '',
      getter = '',
      req = true,
      id = ''
    } = obj;
    this.value = value;
    this.type = type;
    this.label = label;
    this.getter = getter;
    this.req = req;
    this.id = id || getter;
  }
}
