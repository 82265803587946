export const userData = [
  {
    id: 1,
    firstname: 'mark',
    lastname: 'otto',
    email: 'mdo@gmail.com',
    phoneNumber: '1234567890',
    role: 'supervisor',
    store: 'T-Nagar',
    status: 'active'
  },
  {
    id: 2,
    firstname: 'lucifer',
    lastname: 'max',
    email: 'max@gmail.com',
    phoneNumber: '1234567890',
    role: 'supervisor',
    store: 'T-Nagar',
    status: 'inactive'
  },
  {
    id: 3,
    firstname: 'Tyson',
    lastname: 'kai',
    email: 'tykai@gmail.com',
    phoneNumber: '1234567890',
    role: 'supervisor',
    store: 'T-Nagar',
    status: 'active'
  },
  {
    id: 4,
    firstname: 'Flip',
    lastname: 'kart',
    email: 'flipy@gmail.com',
    phoneNumber: '1234567890',
    role: 'supervisor',
    store: 'Mambalam',
    status: 'inactive'
  },
  {
    id: 5,
    firstname: 'snap',
    lastname: 'deal',
    email: 'snappy@gmail.com',
    phoneNumber: '1234567890',
    role: 'supervisor',
    store: 'T-Nagar',
    status: 'active'
  },
  {
    id: 6,
    firstname: 'elon',
    lastname: 'musk',
    email: 'muskmelon@gmail.com',
    phoneNumber: '1234567890',
    role: 'supervisor',
    store: 'T-Nagar',
    status: 'inactive'
  },
  {
    id: 7,
    firstname: 'warren',
    lastname: 'buffet',
    email: 'buffy@gmail.com',
    phoneNumber: '1234567890',
    role: 'supervisor',
    store: 'T-Nagar',
    status: 'active'
  },
  {
    id: 8,
    firstname: 'ragav',
    lastname: 'krishna',
    email: 'raga@gmail.com',
    phoneNumber: '1234567890',
    role: 'supervisor',
    store: 'T-Nagar',
    status: 'inactive'
  },
  {
    id: 9,
    firstname: 'arvind',
    lastname: 'suriya',
    email: 'asuriya@gmail.com',
    phoneNumber: '1234567890',
    role: 'supervisor',
    store: 'T-Nagar',
    status: 'active'
  },
  {
    id: 10,
    firstname: 'aravind',
    lastname: 'mr',
    email: 'amr@gmail.com',
    phoneNumber: '1234567890',
    role: 'supervisor',
    store: 'T-Nagar',
    status: 'inactive'
  },
  {
    id: 11,
    firstname: 'sinthiya',
    lastname: 'sinthiya',
    email: 'sin@gmail.com',
    phoneNumber: '1234567890',
    role: 'Manager',
    store: 'Mylapore',
    status: 'active'
  },
  {
    id: 12,
    firstname: 'melanie',
    lastname: 'ackerman',
    email: 'mel@gmail.com',
    phoneNumber: '1234567890',
    role: 'Manager',
    store: 'Mylapore',
    status: 'inactive'
  },
  {
    id: 13,
    firstname: 'Jim',
    lastname: 'carrey',
    email: 'Jim@gmail.com',
    phoneNumber: '1234567890',
    role: 'Manager',
    store: 'Mylapore',
    status: 'active'
  },
  {
    id: 14,
    firstname: 'robin',
    lastname: 'williams',
    email: 'rob@gmail.com',
    phoneNumber: '1234567890',
    role: 'Manager',
    store: 'Mylapore',
    status: 'inactive'
  },
  {
    id: 15,
    firstname: 'abdul',
    lastname: 'kalam',
    email: 'kalam@gmail.com',
    phoneNumber: '1234567890',
    role: 'Manager',
    store: 'Mylapore',
    status: 'active'
  },
  {
    id: 16,
    firstname: 'narendra',
    lastname: 'modi',
    email: 'modi@gmail.com',
    phoneNumber: '1234567890',
    role: 'Manager',
    store: 'Mylapore',
    status: 'active'
  },
  {
    id: 17,
    firstname: 'ravindra',
    lastname: 'jadeja',
    email: 'jaddu@gmail.com',
    phoneNumber: '1234567890',
    role: 'Manager',
    store: 'Mylapore',
    status: 'active'
  },
  {
    id: 18,
    firstname: 'lokesh',
    lastname: 'Rahul',
    email: 'klr@gmail.com',
    phoneNumber: '1234567890',
    role: 'Manager',
    store: 'Mylapore',
    status: 'active'
  },
  {
    id: 19,
    firstname: 'neeraj',
    lastname: 'chopra',
    email: 'neer@gmail.com',
    phoneNumber: '1234567890',
    role: 'Manager',
    store: 'Mylapore',
    status: 'active'
  },
  {
    id: 20,
    firstname: 'sunil',
    lastname: 'chhetri',
    email: 'sun@gmail.com',
    phoneNumber: '1234567890',
    role: 'Manager',
    store: 'Mylapore',
    status: 'active'
  }
];

export const materialColumns = [
  {
    field: 'id',
    headerName: '#',
    width: 150
  },
  {
    field: 'firstname',
    headerName: 'First Name',
    width: 150
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    width: 150
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 150
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    width: 150
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 150
  },
  {
    field: 'store',
    headerName: 'store',
    width: 150
  },
  {
    field: 'status',
    headerName: 'status',
    width: 150
  }
];

export const statusFilterOptions = [
  { name: 'All', value: '' },
  { name: 'Active', value: true },
  { name: 'Inactive', value: false }
];
