import NodeTypes from '../BaseNodeModel/NodeTypes';
import { PortTypes } from '../BaseNodeModel/PortModels';
import _ from 'lodash';

let i = 1;
function* counter() {
  while (true) yield i++;
}
const iterator = counter();

export const TreeNode = class {
  constructor(id, nodeModel) {
    this.id = id;
    this.className = 'TreeNode';
    this.data = nodeModel;
    this.data.className = this.data.type;
    this.children = [];
    delete this.data.type;
    delete this.data.ports;
    delete this.data.x;
    delete this.data.y;
    delete this.data.color;
    delete this.data.extras;
    delete this.data.selected;
    delete this.data.locked;
  }
  setChildren(child) {
    this.children.push(child);
  }
};
const getChildPorts = (port, node) => {
  return _.map(Object.values(port.links), link => [link.targetPort, node]);
};

export const getTreeFromModel = model => {
  const nodes = model.getNodes();
  //startNode will be either trigger/timed node
  i = 1; //set iterator value to 1
  const startNode = _.filter(nodes, node =>
    _.includes([NodeTypes.TRIGGER_NODE, NodeTypes.TIMED_NODE], node.type)
  )[0];
  //rootNode is the root node of the tree
  const rootNode = new TreeNode(iterator.next().value, startNode.serialize());
  let queue = getChildPorts(startNode.getPort(PortTypes.OUT_PORT), rootNode);

  while (!_.isEmpty(queue)) {
    let tempQ = [];
    _.forEach(queue, element => {
      const [port, parent] = element;
      const outPort = port.parent.getPort(PortTypes.OUT_PORT);
      const node = new TreeNode(iterator.next().value, port.parent.serialize());
      parent.setChildren(node);
      if (!outPort) {
        return;
      }
      const childPorts = getChildPorts(outPort, node);
      tempQ = _.concat(tempQ, childPorts);
    });
    queue = tempQ;
  }
  return rootNode;
};
