import React from 'react';
import config from '../config';

// Use this component for navigating the parent app (casa-dashboard)
export const ParentLink = props => {
  const { to, ...otherProps } = props;
  const toWithSlash = to.startsWith('/') ? to : `/${to}`;
  const parentTo = config.dashboardUrl + toWithSlash;
  const src = new URL(parentTo);
  const params = new URLSearchParams(window.location.search);
  params.forEach(
    (value, key) =>
      (key === 'bu' || (props.includeSearchParam || []).includes(key)) &&
      !src.searchParams.has(key) &&
      src.searchParams.append(key, value)
  );
  src.searchParams.append('ref', 'v1');

  const handleOnClick = e => {
    e.preventDefault();
    const messageData = {
      navigateTo: `${src.pathname}${src.search}`
    };
    window.parent.postMessage(messageData, config.dashboardUrl);
  };

  return (
    <a
      href={src.href}
      target={'_parent'}
      {...otherProps}
      onClick={handleOnClick}
    >
      {props.children}
    </a>
  );
};
