import React, { Context } from 'react';
import noop from 'lodash/noop';

export interface ProductClickEvent {
  trackingId: string;
  storeName: string;
  productSku: string;
  productFieldName: string;
  productFieldValue: string;
  isDirectSku: true;
  previousProductScrollCount: number;
  scrollDepth: number;
  pageContext: string;
  searchTerm: string;
  device: string;
  browser: string;
  operatingSystem: string;
  discountAmount: number;
  price: number;
  netAmount: number;
  campaign: string;
  clickInteraction: {
    interactionElementType: string;
    interactionElementValue: string;
    interactionCreatedTime: string;
  };
}

export interface ProductClickPayload {
  productSku: string;
  pageContext: string;
  searchTerm: string;
  discountAmount: string | number;
  price: string | number;
  netAmount: string | number;
  elementType: string;
  elementValue: string;
  interactionTime: string;
}

type FlipbookEventContext = {
  isLandingEventTriggered: boolean;
  setIsLandingEventTriggered: (state: boolean) => void;
  productClickEvents: ProductClickEvent[];
  addProductClickEvent: (event: ProductClickEvent) => void;
  postProductClickEvent: (event: ProductClickPayload) => void;
  hasAlreadyProductClickTriggered: (event: ProductClickEvent) => boolean;
};

export const FlipbookEventContext: Context<FlipbookEventContext> = React.createContext<
  FlipbookEventContext
>({
  isLandingEventTriggered: false,
  setIsLandingEventTriggered: noop,
  productClickEvents: [],
  addProductClickEvent: noop,
  postProductClickEvent: noop,
  hasAlreadyProductClickTriggered: (event: ProductClickEvent) => {
    return false;
  }
});
