import { Business } from '../User.model';
import { OfferRule } from './OfferRule.model';
import moment from 'moment';

export class OfferTag {
  id = 0;
  name = '';
}

export class Offer {
  public code?: string;
  public tenantId?: number;
  public tenantBrandBusinessId = new Business().id || undefined;
  public name?: string;
  public description?: string;
  public categoryId?: string;
  public sortOrder = 0;
  public redemptionLimit = 0;
  public redemptionLimitPerCustomer = 0;
  public startDate = new Date();
  public endDate = new Date();
  public type = 'Offer';
  public tags: OfferTag[] = [];
  public isCustomerSpecific = false;
  public loyaltyVendorInfo: any = {
    isInternal: false,
    allowRedemptionOnPromoItem: false,
    ginesysProductOffer: false
  };
  public amount = 0;
  public offerDefinition: OfferRule = new OfferRule();
  public maxLimit = -1;
  public imageUrl?: string;
  public bannerImageUrl?: string;
  public metaFields?: OfferMetaField[] = [];
  public status = 0;
  public validity: any = { unit: 'days', count: '' };
  public get isDefined(): boolean {
    return !!(
      this.code &&
      this.tenantBrandBusinessId &&
      this.name &&
      this.description &&
      this.startDate &&
      this.endDate &&
      // this.imageUrl &&
      (this.offerDefinition.isDefined || this.status)
    );
  }

  public get daysLeft(): number {
    return moment(this.endDate).diff(moment().endOf('day'), 'days');
  }

  // https://mauricereigman.medium.com/immutable-typescript-classes-with-object-copywith-typesafe-a84fff3971dc
  public copyWith(modifyObject: { [P in keyof Offer]?: Offer[P] }): Offer {
    return Object.assign(Object.create(Offer.prototype), {
      ...this,
      ...modifyObject
    });
  }
}

export class OfferMetaField {
  public field: string;
  public value: string;

  constructor(field = '', value = '') {
    this.field = field;
    this.value = value;
  }
}
