import React from 'react';
import InvoiceGenerator from '../../../../DynamicInvoiceGenerator/InvoiceGenerator';
import { InvoiceContext } from '../../../context/InvoiceContext';
import { Heading } from '../../../shared/Heading';

const Preview: React.FC = () => {
  const { getPreviewConfig } = React.useContext(InvoiceContext);
  let previewDetails = getPreviewConfig();

  return (
    <div>
      <Heading
        title="Preview"
        styles={{
          textAlign: 'center'
        }}
        titleWeight="bold"
      />
      <div
        style={{
          backgroundColor: '#fff',
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
          borderRadius: '4px'
        }}
      >
        <InvoiceGenerator previewDetails={previewDetails} />
      </div>
    </div>
  );
};

export default Preview;
