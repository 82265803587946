/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import '../LoyaltyConfig/index.scss';
import { useFormik } from 'formik';
import { TextFieldComponent } from '../../reusable/TextFieldComponent';
import ButtonField from '../../reusable/ButtonField';
import { otpInitialState } from '../../constants/otpConfiguration';
import { otpConfigToSchema } from '../../validation/redemptionOtpToSchema';
import configService from '../../services/config.service';
import { toastMessageAction } from '../../store/action';
import { useDispatch } from 'react-redux';
import { MentionsInput, Mention } from 'react-mentions';
import defaultStyle from './defaultStyle';
import defaultMentionStyle from './defaultMentionStyle';

const LoyaltyOtp = props => {
  const { title, keyName, providerName, businessUnit, type } = props;
  const dispatch = useDispatch();
  const [autoSearch, setAutoSearch] = useState('');
  const data = [{ id: 1, display: '<%= otp %>' }];
  const [templateError, setTemplateError] = useState(false);

  //handle submit function
  const onSubmit = data => {
    if (autoSearch.search('otp') === -1) {
      setTemplateError(true);
    } else if (providerName !== undefined) {
      setTemplateError(false);
      const workData = {
        ...data,
        businessUnit: businessUnit?.name,
        key: keyName,
        providerName: providerName,
        template: autoSearch.replace('@[<%= otp %>](1)', '<%= otp %>')
      };
      configService.userOTPConfig(workData).then(response => {
        if (response.status === 'failure') {
          let message = `Server Error, Please try later!`;
          if (
            response.errors
              .map(e => e.message)
              .join()
              .includes('ChannelConfigNotFound')
          )
            message = 'Setup Channel Config First!';
          dispatch(
            toastMessageAction({
              severity: 'error',
              message,
              messageStatus: true
            })
          );
        } else {
          dispatch(
            toastMessageAction({
              severity: 'success',
              message: `${title} otp add successfully !`,
              messageStatus: true
            })
          );
        }
      });
    } else {
      dispatch(
        toastMessageAction({
          severity: 'error',
          message: `Please select a provider`,
          messageStatus: true
        })
      );
    }
  };

  //to handle submit form
  const formik = useFormik({
    initialValues: otpInitialState,
    validationSchema: otpConfigToSchema,
    onSubmit
  });
  const {
    values,
    errors,
    handleChange,
    touched,
    resetForm,
    setFieldValue
  } = formik;

  const handleSearch = evt => {
    setAutoSearch(evt.target.value);
  };
  useEffect(() => {
    if (businessUnit?.id > 0)
      configService.getUserOTPConfig(businessUnit?.id).then(res => {
        const data = res?.find(item => {
          if (keyName === item.key) {
            return item;
          }
          return null;
        });
        if (data) {
          setFieldValue('templateId', data?.templateId);
          setAutoSearch(
            data?.template.replace('<%= otp %>', '@[<%= otp %>](1)')
          );
        } else {
          setFieldValue('templateId', '');
          setAutoSearch('');
        }
      });
  }, [businessUnit, keyName, setFieldValue, type]);

  return (
    <>
      <div className={'adHocPointsContainer'} style={{ marginTop: 20 }}>
        <h4 className="adHocPointsTitle">{`${title} Redemption OTP`}</h4>
        <form onSubmit={formik.handleSubmit} className="formSubmitation">
          <TextFieldComponent
            label={'Template ID or DLT'}
            variant="outlined"
            onChange={handleChange}
            name={'templateId'}
            value={values.templateId}
            error={touched.templateId && errors.templateId}
            className="textFieldStyle"
            disabled={providerName === undefined}
          />

          <MentionsInput
            value={autoSearch}
            onChange={evt => handleSearch(evt)}
            style={defaultStyle}
            placeholder="To insert OTP or Company name in content, use '@' followed by O"
            disabled={providerName === undefined}
          >
            <Mention data={data} style={defaultMentionStyle} />
          </MentionsInput>
          {templateError && (
            <div style={{ color: 'red' }}>
              {"Please should be include 'otp'"}{' '}
            </div>
          )}
          <div className={'submitButtonContainer'} style={{ marginTop: 30 }}>
            <ButtonField
              variant="contained"
              label="Clear"
              type="button"
              onClick={() => resetForm()}
              className="clearButton"
              disabled={providerName === undefined}
            />

            <ButtonField
              variant="contained"
              label="Save"
              type="sumbit"
              className="saveButton"
              disabled={providerName === undefined}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default LoyaltyOtp;
