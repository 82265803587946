/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';

import SettingsIcon from '../../../Icons/svg/Settings.svg';
import classes from './Settings.module.css';
import moment from 'moment';
import { getCurrentUser } from '../../../../../services/user.service';
import _ from 'lodash';
import { getTemplatesByCategoryId } from '../../../../../services/templateConfigs.service';

const Settings = ({
  journeyName,
  setJourneyName,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  businessUnit,
  setBusinessUnit,
  disable,
  disableEntire,
  hiddenNode,
  setHiddenNode
}) => {
  const [show, setShow] = React.useState(false);
  const [businessUnits, setBusinessUnits] = React.useState();
  const [ruleTemplates, setRuleTemplates] = React.useState();
  const [templateOptions, setTemplateOptions] = React.useState();
  const [hiddenTemplate, setHiddenTemplate] = React.useState();
  const [spec, setSpec] = React.useState();
  const nodeData = {
    ruleNode: {
      id: 'PRODUCT_PURCHASED',
      className: 'TreeNode',
      data: {
        id: '4f2df461-cc1c-4080-9ffe-81549bf40cc1',
        name: 'Hidden Node',
        specs: [],
        templateCategoryId: 'hidden_responses',
        templateId: [],
        className: 'DecisionNode'
      }
    },
    actionNodes: [],
    waitNode: '',
    parentNodeId: '',
    journeyId: ''
  };

  const ref = useRef();

  useEffect(() => {
    const template = _.filter(ruleTemplates, ruleTemplate => {
      if (ruleTemplate.name === hiddenTemplate) {
        return ruleTemplate;
      }
    });

    const neededSpec = _.filter(template[0]?.specs, spec => {
      if (spec.name !== 'journeyId') {
        return template;
      }
    });

    setSpec(neededSpec[0]);
  }, [hiddenTemplate]);

  useEffect(() => {
    const template = _.filter(ruleTemplates, ruleTemplate => {
      if (ruleTemplate.name === hiddenTemplate) {
        return ruleTemplate;
      }
    });

    nodeData.ruleNode.data.templateId = [template[0]?.id];
    nodeData.ruleNode.data.specs = [spec];
    setHiddenNode(nodeData);
  }, [spec]);

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (show && ref.current && !ref.current.contains(e.target)) {
        setShow(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [show]);

  useEffect(() => {
    getCurrentUser().then(res => {
      setBusinessUnits(_.map(res.tenant.brands, brand => brand.name));
      setBusinessUnit(res.tenant?.brands[0].name);
    });
    getTemplatesByCategoryId('hidden_responses').then(res => {
      setRuleTemplates(res);
      setTemplateOptions(_.map(res, response => response.name));
      if (_.size(res) > 0) {
        if (!_.isEmpty(hiddenNode)) {
          const template = _.filter(res, template => {
            if (template.id === hiddenNode.ruleNode.data.templateId[0]) {
              return template;
            }
          });
          setHiddenTemplate(template.name);
          setSpec(hiddenNode.ruleNode.data.specs[0]);
        } else {
          setHiddenTemplate(res[0].name);
          setSpec(res[0].specs[0]);
        }
      }
    });
  }, []);

  return (
    <div
      ref={ref}
      style={
        disableEntire
          ? { opacity: '0.4', cursor: 'not-allowed', pointerEvents: 'none' }
          : { cursor: 'pointer' }
      }
    >
      <div className={classes.icon} onClick={() => setShow(!show)}>
        <img src={SettingsIcon} alt="Settings" />
        <div className={classes.settingText}>{show ? 'CLOSE' : 'SETTINGS'}</div>
      </div>
      {show && (
        <div className={classes.settings}>
          <div>SETTINGS</div>
          <div className={classes.heading}>Title</div>
          <input
            className={classes.input}
            style={disable ? { color: 'grey' } : {}}
            disabled={disable}
            type="text"
            value={journeyName}
            placeholder="Add to cart Journey*"
            onChange={e => {
              setJourneyName(e.target.value);
            }}
          />
          <div className={classes.heading}>Business Unit</div>
          <select
            className={classes.select}
            id="businessUnitSelect"
            disabled={disable}
            value={businessUnit}
            label="Business Unit"
            onChange={e => setBusinessUnit(e.target.value)}
          >
            {_.map(businessUnits, unit => (
              <option value={unit}>{unit}</option>
            ))}
          </select>
          <div className={classes.heading}>When to run my campaign?</div>
          <div className={classes.dateContainer}>
            <input
              type="datetime-local"
              className={classes.input}
              value={startDate}
              min={moment().format('YYYY-MM-DD')}
              onChange={e => {
                setStartDate(e.target.value);
              }}
            />
            <span className={classes.to}>-</span>
            <input
              type="date"
              className={classes.input}
              value={endDate}
              min={moment().format('YYYY-MM-DD')}
              onChange={e => {
                setEndDate(e.target.value);
              }}
            />
          </div>
          <div className={classes.heading}>
            What will you be considered as success for the Journey
          </div>
          <div>
            <select
              className={classes.select}
              id="hiddenresponseSelect"
              value={hiddenTemplate}
              label="Journey Success Template"
              onChange={e => setHiddenTemplate(e.target.value)}
            >
              {_.map(templateOptions, templateOption => (
                <option value={templateOption}>{templateOption}</option>
              ))}
            </select>
          </div>
          {!_.isEmpty(spec) ? (
            <>
              <div className={classes.heading}>{spec.name}</div>
              <input
                type={spec.type}
                className={classes.input}
                value={spec.value}
                onChange={e => {
                  spec.value = e.target.value;
                }}
              />
            </>
          ) : (
            <></>
          )}

          {/* <div>settings content here</div> */}
        </div>
      )}
    </div>
  );
};

export default Settings;
