import React, { useEffect, useState } from 'react';
import { Product } from '../../models/CartWrapper';
import { ProductBucket } from '../../models/offer/OfferRule.model';
import { CssStyles } from '../CartBucket/CartBucketProps';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import bytes from 'bytes';

const imageHeight = '20em';

const buttonStyle: CssStyles = {
  borderRadius: '4px'
};
const buttonTextStyle: CssStyles = {
  fontSize: '14px',
  fontWeight: 'bold',
  textAlign: 'center'
};

const variantOptionsMap: { [masterCategory: string]: string[] } = {
  ipad: ['color', 'storage'],
  iphone: ['color', 'storage'],
  mac: ['color', 'storage'],
  watch: ['size'],
  tv: ['storage'],
  accessories: ['sku']
};

const SelectButton = withStyles({
  root: buttonStyle,
  label: buttonTextStyle
})(Button);

interface OfferProductsGroupCardProps {
  products: Product[];
  bucket: ProductBucket;
  onClick?: (p: Product, bucket: ProductBucket) => void;
}

export const OfferProductsGroupCard: React.FC<OfferProductsGroupCardProps> = ({
  products = [new Product()],
  bucket = new ProductBucket(),
  onClick = _.noop
}) => {
  const [selectedProduct, setSelectedProduct] = useState<Product>(
    _.head(products)!
  );
  const [firstProduct] = useState<Product>(_.head(products)!);
  const [variantOptions, setVariantOptions] = useState<string[]>([]);
  const [variantOptionValues, setVariantOptionValues] = useState<{
    [variantOption: string]: string[];
  }>({});
  const [selectedVariantOptionValue, setSelectedVariantOptionValue] = useState<{
    [variantOption: string]: string;
  }>({});
  useEffect(() => {
    const matchedProduct = _.find(products, selectedVariantOptionValue);
    // @ts-ignore
    setSelectedProduct(matchedProduct);
  }, [products, selectedVariantOptionValue]);
  useEffect(() => {
    const p = _.head(products);
    const variantOptionNames =
      variantOptionsMap[_.get(p, 'variantOptionsType') || ''] || [];
    setVariantOptions(variantOptionNames);
    _.each(variantOptionNames, varaintOptionName => {
      const uniqueVariantOptionValues =
        _.sortBy(
          _.uniq(_.compact(_.map(products, varaintOptionName))),
          v => bytes.parse(v) || v
        ) || [];
      setVariantOptionValues(values => ({
        ...values,
        [varaintOptionName]: uniqueVariantOptionValues
      }));
      setSelectedVariantOptionValue(values => ({
        ...values,
        [varaintOptionName]: _.head(uniqueVariantOptionValues)
      }));
    });
  }, [products]);
  return (
    <div className={'d-flex flex-column mb-3 col-12'}>
      <div className={'d-flex flex-column'}>
        <div className={'row d-md-none'}>
          <span className={'col-12 h5 text-center'}>
            {_.get(selectedProduct, 'name')}
          </span>
        </div>
        <div className={'row'}>
          <div className={'col-12 col-md-6 d-flex justify-content-center'}>
            <img
              style={{ maxHeight: imageHeight }}
              className={'img-fluid my-auto object-fit-contain'}
              src={_.get(selectedProduct, 'imageUrl', firstProduct.imageUrl)}
              alt={'product'}
            />
          </div>
          <div
            className={
              'col-12 col-md-6 d-flex flex-column justify-content-around mt-2 mt-md-0'
            }
          >
            <div className={'d-none d-md-block h5'}>
              {_.get(selectedProduct, 'name')}
            </div>
            {variantOptions.map((vo, index) => (
              <div className={'d-flex my-2 flex-column'} key={vo}>
                <div style={{ color: '#404040' }}>{_.capitalize(vo)}:</div>
                <div className={'d-flex flex-wrap'}>
                  {variantOptionValues[vo].map(v => (
                    <div key={v} className={'mx-2 w-25'}>
                      <Button
                        title={v}
                        className={'outlinedSecondary'}
                        disabled={
                          !_.some(products, {
                            ..._.pick(
                              selectedVariantOptionValue,
                              _.take(variantOptions, index + 1)
                            ),
                            [vo]: v
                          })
                        }
                        variant={
                          v === selectedVariantOptionValue[vo]
                            ? 'outlined'
                            : 'text'
                        }
                        onClick={() => {
                          setSelectedVariantOptionValue(values => ({
                            ...values,
                            [vo]: v
                          }));
                        }}
                      >
                        {vo === 'color' ? (
                          <img
                            src={_.get(
                              _.find(products, { color: v }) || firstProduct,
                              'imageUrl'
                            )}
                            style={{
                              maxHeight: '4em'
                            }}
                            alt={v}
                          />
                        ) : (
                          v
                        )}
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div
              className={
                'my-1 d-flex flex-column justify-content-center justify-content-md-start align-items-center align-items-md-start'
              }
            >
              {bucket.discount.hasDiscount && (
                <div
                  style={{ fontSize: '12px', textDecoration: 'line-through' }}
                >
                  <NumberFormat
                    prefix={'₹'}
                    thousandSeparator={true}
                    displayType={'text'}
                    value={_.get(selectedProduct, 'price')}
                    thousandsGroupStyle={'lakh'}
                  />
                </div>
              )}
              <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                <NumberFormat
                  prefix={'₹'}
                  thousandSeparator={true}
                  displayType={'text'}
                  value={_.invoke(
                    selectedProduct,
                    'getDiscountedPrice',
                    bucket.discount
                  )}
                  thousandsGroupStyle={'lakh'}
                />
              </div>
            </div>
            <div className={'w-100 w-md-75 mb-2 text-center'}>
              <SelectButton
                onClick={() => onClick(selectedProduct, bucket)}
                variant="contained"
                disabled={!selectedProduct}
                color={'primary'}
                size="large"
                className={'px-5'}
              >
                {selectedProduct ? 'Select' : 'Not Avaialble'}
              </SelectButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
