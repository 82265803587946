import React from 'react';
import { range } from 'lodash';
import { IntegratedSelfPage } from './iShelfPage';
import { IntegratedSelfPageV2 } from './iShelfPageV2';
import { IntegratedSelfPageH1 } from './iShelfPageH1';

const IntegratedSelf = ({ title, data, count, version, ...rest }) => {
  const versionHandler = (subCount, i) => {
    //TODO: Change V2 component
    const versions = {
      V1: <IntegratedSelfPage key={i} data={data} count={subCount} />,
      V2: <IntegratedSelfPageV2 key={i} data={data} count={subCount} />,
      H1: <IntegratedSelfPageH1 key={i} data={data} count={subCount} />
    };
    return versions[version];
  };
  return (
    <>
      {count &&
        range(Math.ceil(count / 7)).map(i => {
          const subCount = range(Math.min(count - i * 7, 7));
          return versionHandler(subCount, i);
        })}
    </>
  );
};
export { IntegratedSelf };
