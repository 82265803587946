import config from '../../config';
import CampaignReportDownload from '../../models/TenantReports/OfferPromoReportDownload';
import formatDate from '../../shared/utils/formatDate';

export const createOfferPromoReport = ({
  businessId,
  offset,
  page,
  startDate,
  endDate,
  grouperColumn
}: any): Promise<{ message: string; jobId: string }> => {
  return fetch(
    `${
      config.ruleServerUrl
    }/users/reports/download/offer_promo_report/${businessId}?offset=${offset}&page=${page}&startDate=${formatDate(
      startDate,
      'YYYY-MMM-DD'
    )}&endDate=${formatDate(
      endDate,
      'YYYY-MMM-DD'
    )}&grouperColumn=${grouperColumn}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(r => r.json());
};

export const getOfferPromoReportDownload = ({
  page,
  offset,
  filters
}: {
  page: number;
  offset: number;
  filters?: any;
}): Promise<CampaignReportDownload[]> => {
  const grouperColumn = filters.grouperColumn;
  return fetch(
    `${config.ruleServerUrl}/users/reports/view/downloads/offer_promo_report?offset=${offset}&page=${page}&columnName=grouperColumn&columnValue=${grouperColumn}`,
    { credentials: 'include' }
  ).then(res => res.json());
};
