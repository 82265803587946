import { LayoutType } from '../../../../models/FlipbookV2/FlipbookV2Layout.model';
import {
  FlipbookLayoutTile,
  TileType
} from '../../../../models/FlipbookV2/FlipbookV2LayoutTile.model';
import React, { FC, useContext, useEffect } from 'react';
import {
  Button,
  Card,
  createStyles,
  FormControl,
  FormControlLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Theme
} from '@material-ui/core';
import './PersonalisedCollectionForm.css';
import { makeStyles } from '@material-ui/core/styles';
import productTemplateIcon from '../../../Assets/images/product_template_icon.svg';
import _, { find, get, isUndefined, map } from 'lodash';
import { BusinessUnitContext } from '../../../OfferDefinition/BusinessUnitContext';
import Checkbox from '@material-ui/core/Checkbox';
import FlipbookLayoutTileServiceV2 from '../../../../services/Flipbook/flipbookV2LayoutTile.service';
import SelectWithAutoComplete from '../../SelectWithAutoComplete/SelectWithAutoComplete';
import BulkUpload from '../../PageGenerators/BulkUpload';
import { FlipbookPagesContext } from '../../Hooks/FlipbookPagesContext';
import isEmpty from 'lodash/isEmpty';
import { TileData } from '../../../../models/FlipbookV2/TileData/TileData.model';
import ProductRecommendationService from '../../../../services/Recommendation/product-recommendation.service';
import ProductMasterService from '../../../../services/productMaster.service';

interface PersonalisedCollectionForm {
  layoutType: LayoutType;
  flipbookLayoutTile: FlipbookLayoutTile;
  handlePersonalisedCollectionModalClose: () => void;
  isPersonalisedCollectionOpen: boolean;
  flipbookTileType: TileType;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '30ch'
      }
    }
  })
);

export const CHOOSE_PRODUCTS = 'chooseProducts';
export const CHOOSE_HIERARCHY = 'chooseHierarchy';

export const PersonalisedCollectionForm: FC<PersonalisedCollectionForm> = ({
  layoutType,
  flipbookLayoutTile,
  handlePersonalisedCollectionModalClose,
  isPersonalisedCollectionOpen,
  flipbookTileType
}) => {
  const classes = useStyles();
  const isTileOneRestrict =
    flipbookTileType === TileType.TileOne && layoutType === LayoutType.DOUBLE;
  const [isWarningEnabled, setIsWarningEnabled] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [selectProductType, setSelectProductType] = React.useState({
    title: '',
    subTitle: ''
  });
  const [selectedTabType, setSelectedTabType] = React.useState(CHOOSE_PRODUCTS);
  const [isAllCategorySelected, setIsAllCategorySelected] = React.useState(
    isEmpty(get(flipbookLayoutTile.tileData, 'products', []))
  );
  const [tileData, setTileData] = React.useState<any>(
    flipbookLayoutTile.tileData
  );
  const businessUnitContext = useContext(BusinessUnitContext);
  const productHierarchy = _.get(
    businessUnitContext,
    'business.productHierarchyNew',
    []
  );
  const [hierarchyOptions, setHierarchyOptions] = React.useState<any>([]);

  const sortFields = [
    {
      label: 'Product View Count',
      value: 'score'
    },
    {
      label: 'Product Price',
      value: 'price'
    }
  ];

  const sortOrder = [
    {
      label: 'asc',
      value: 'asc'
    },
    {
      label: 'desc',
      value: 'desc'
    }
  ];

  useEffect(() => {
    ProductRecommendationService.getProductRecommendation(
      businessUnitContext.business.id
    ).then(recommendationConfig => {
      const hierarchy = get(recommendationConfig, 'hierarchy');
      ProductMasterService.filterProductFields(
        businessUnitContext.business,
        hierarchy,
        {}
      ).then(res => {
        const hierarchyValues = get(res, 'values', []);
        const modifiedHierarchyValues = map(hierarchyValues, hv => {
          return {
            label: hv,
            value: hv
          };
        });
        setHierarchyOptions(modifiedHierarchyValues);
      });
    });
  }, [businessUnitContext.business]);

  const getSelectOption = (type: string, value: string) => {
    return _.find(productHierarchy, {
      [type]: value
    });
  };
  const flipbookPagesContext = useContext(FlipbookPagesContext);

  const createOrUpdateLayout = () => {
    const updatedFlipbookLayoutTile: FlipbookLayoutTile = flipbookLayoutTile;
    updatedFlipbookLayoutTile.tileData = tileData;
    if (isUndefined(flipbookLayoutTile?.id)) {
      FlipbookLayoutTileServiceV2.createFlipbookLayoutTile(
        updatedFlipbookLayoutTile
      ).then(r => {
        flipbookPagesContext.updateFlipbookPages();
      });
    } else {
      FlipbookLayoutTileServiceV2.updateFlipbookLayoutTile(
        updatedFlipbookLayoutTile
      ).then(r => {
        flipbookPagesContext.updateFlipbookPages();
      });
    }
  };

  const selectAllCategoryForRecommendation = () => {
    if (!isAllCategorySelected) {
      handleChangeTileData('products', []);
    }
    setIsAllCategorySelected(!isAllCategorySelected);
  };

  const handleAddPersonalisedCollectionSave = (isRestricted: boolean) => {
    const defaultValues = get(tileData, 'defaultValues', {});
    const productSize = get(tileData, 'defaultValues.defaultProductSize');
    const type = get(defaultValues, 'type', 'sku');
    const values = get(defaultValues, 'values', []);
    if (type === 'category' && isRestricted) {
      setIsWarningEnabled(true);
      setErrorMessage('*Category cannot be selected for restricted tile');
    } else if (parseInt(productSize) > 2 && isRestricted) {
      setIsWarningEnabled(true);
      setErrorMessage('*Max 2 products can be rendered');
    } else if (parseInt(productSize) > 200) {
      setIsWarningEnabled(true);
      setErrorMessage('*Max 200 products can be rendered');
    } else if (isRestricted && type === 'sku' && values.length > 2) {
      setIsWarningEnabled(true);
      setErrorMessage('*Please select only 2 products');
    } else if (_.isEmpty(values)) {
      setIsWarningEnabled(true);
      setErrorMessage('*Please select default products');
    } else {
      handlePersonalisedCollectionModalClose();
      createOrUpdateLayout();
    }
  };
  const handleChangeTileData = (type: string, value: any) => {
    const updatedTileData = { ...tileData, [type]: value };
    setTileData(updatedTileData);
  };

  const handleChange = (e: any, type: string): void => {
    const option = getSelectOption('displayName', e.target.value);
    handleChangeTileData('productCardTemplate', {
      ...tileData.productCardTemplate,
      ...{
        [type]: option.casaFieldName
      }
    });

    setSelectProductType({ ...selectProductType, [type]: e.target.value });
  };

  const handleAddCategoriesForRecommendation = (categories: any) => {
    const products = map(categories, 'value');
    handleChangeTileData('products', products);
  };

  const handleSelectSortFieldForRecommendation = (sortField: any) => {
    let sort = get(tileData, 'sort', {});
    sort = {
      ...sort,
      field: get(sortField, 'value')
    };
    handleChangeTileData('sort', sort);
  };

  const handleSelectSortOrderForRecommendation = (sortOrder: any) => {
    let sort = get(tileData, 'sort', {});
    sort = {
      ...sort,
      order: get(sortOrder, 'value')
    };
    handleChangeTileData('sort', sort);
  };

  const getSelectedCategoriesForRecommendation = () => {
    const values = get(tileData, 'products', []);
    return map(values, v => {
      return { label: v, value: v };
    });
  };

  const getSelectedSortFieldsForRecommendation = () => {
    const value = get(tileData, 'sort.field', '');
    return find(sortFields, sf => sf.value === value);
  };

  const getSelectedSortOrderForRecommendation = () => {
    const value = get(tileData, 'sort.order', '');
    return find(sortOrder, sf => sf.value === value);
  };

  const getSelectedDefaultCategoriesForRecommendation = () => {
    const defaultValues = get(tileData, 'defaultValues', {});
    const type = get(defaultValues, 'type', 'sku');
    if (type === 'sku') {
      return [];
    }
    const values = get(defaultValues, 'values', []);
    return map(values, v => {
      return { label: v, value: v };
    });
  };
  const handleDefaultForRecommendation = (type: string, values: string[]) => {
    let defaultValues = {};
    defaultValues = {
      type,
      values,
      sortOrder: 'desc'
    };
    handleChangeTileData('defaultValues', defaultValues);
  };

  const handleDefaultProductSizeForRecommendation = (productSize: string) => {
    let defaultValues = get(tileData, 'defaultValues', {});
    const ps = isEmpty(productSize) ? 200 : productSize;
    defaultValues = {
      ...defaultValues,
      defaultProductSize: ps
    };
    handleChangeTileData('defaultValues', defaultValues);
  };

  const getDefaultProductCountSizeForRecommendation = () => {
    return get(tileData, 'defaultValues.defaultProductSize', 200);
  };

  const bulkUploadSku = (tileData: TileData) => {
    const defaultValues = get(tileData, 'defaultValues', {});
    setTileData({
      ...tileData,
      skus: [],
      defaultValues: {
        ...defaultValues,
        type: 'sku',
        values: get(tileData, 'skus')
      }
    });
  };

  const getSelectedDefaultSkus = () => {
    const defaultValues = get(tileData, 'defaultValues', {});
    const type = get(defaultValues, 'type', 'category');
    if (type === 'category') {
      return [];
    }
    return get(defaultValues, 'values', []);
  };
  return (
    <Modal
      open={isPersonalisedCollectionOpen}
      onClose={() => handlePersonalisedCollectionModalClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <div className={'add-personalised-collection-modal-container'}>
        <div className={'personalised-collection-header'}>
          {'Customize Personalised Collections'}
        </div>
        <div className={'border-top-1'} />
        <div className={'personalised-collection-content'}>
          <div className={'recommendation-settings'}>
            <div className={'recommendation-settings-record'}>
              <div className={'recommendation-settings-record-value'}>
                <FormControlLabel
                  className={'m-0 flipbook-text-label'}
                  control={
                    <Checkbox
                      checked={isAllCategorySelected}
                      onChange={() => selectAllCategoryForRecommendation()}
                      name="checkedA"
                      color="primary"
                    />
                  }
                  label="Select All Categories for Recommendation"
                />
              </div>
              <div className={'recommendation-settings-record-value'}>
                <h2 className={'flipbook-text-header m-2 my-4'}>
                  {'Choose Product Categories for Recommendation'}
                </h2>
                <FormControl
                  key={'product-recommendation-form'}
                  disabled={isAllCategorySelected}
                  className={'m-2'}
                >
                  <SelectWithAutoComplete
                    onChange={value => {
                      handleAddCategoriesForRecommendation(value);
                    }}
                    options={hierarchyOptions}
                    values={getSelectedCategoriesForRecommendation()}
                    label={'Categories'}
                    size={'15rem'}
                    disabled={isAllCategorySelected}
                  />
                </FormControl>
              </div>
              <div className={'recommendation-settings-record-value'}>
                <h2 className={'flipbook-text-header m-2 my-4'}>
                  {'Choose the sort field and order for displaying products.'}
                </h2>
                <FormControl
                  key={'product-recommendation-form'}
                  disabled={isAllCategorySelected}
                  className={'m-2'}
                >
                  <SelectWithAutoComplete
                    onChange={value => {
                      handleSelectSortFieldForRecommendation(value);
                    }}
                    options={sortFields}
                    values={getSelectedSortFieldsForRecommendation()}
                    label={'Sort Field'}
                    isMultiple={false}
                    size={'15rem'}
                  />
                  <div className={'m-2'} />
                </FormControl>
                <FormControl
                  key={'product-recommendation-form'}
                  disabled={isAllCategorySelected}
                  className={'m-2'}
                >
                  <SelectWithAutoComplete
                    onChange={value => {
                      handleSelectSortOrderForRecommendation(value);
                    }}
                    options={sortOrder}
                    values={getSelectedSortOrderForRecommendation()}
                    label={'Sort Order'}
                    isMultiple={false}
                    size={'15rem'}
                  />
                </FormControl>
              </div>
              <div className={'recommendation-settings-record-value'}>
                <h2 className={'flipbook-text-header m-2 my-4'}>
                  {'Choose the maximum number of products to display'}
                </h2>
                <div className={'m-2'}>
                  <TextField
                    id="standard-basic"
                    label="Products Count"
                    type={'number'}
                    value={getDefaultProductCountSizeForRecommendation()}
                    onChange={event =>
                      handleDefaultProductSizeForRecommendation(
                        event.target.value
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2 className={'flipbook-text-header m-2 my-4'}>
              {'Choose Product detail template (optional)'}
            </h2>
            <Card className={'add-collection-modal-card mx-2'}>
              <div>
                <div>
                  <img
                    src={productTemplateIcon}
                    alt={'product_temp'}
                    style={{ width: '8rem', height: '8rem' }}
                  />
                </div>
                <div className={'m-1 mx-2'} style={{ textAlign: 'left' }}>
                  <Select
                    disableUnderline
                    className={'product-template-select'}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectProductType.title}
                    onChange={e => handleChange(e, 'title')}
                  >
                    {_.map(productHierarchy, (p, i) => (
                      <MenuItem key={i} value={p.displayName}>
                        {p.displayName}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className={'m-1 mx-2'} style={{ textAlign: 'left' }}>
                  <Select
                    disableUnderline
                    className={'product-template-select'}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectProductType.subTitle}
                    onChange={e => handleChange(e, 'subTitle')}
                  >
                    {_.map(productHierarchy, (p, i) => (
                      <MenuItem key={i} value={p.displayName}>
                        {p.displayName}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div>
                  <div className={'m-1'} style={{ float: 'left' }}>
                    <p
                      className={
                        'm-0 px-2 text-primary-color product-template-text'
                      }
                    >
                      {'[Price]'}
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <div>
            <h2 className={'flipbook-text-header m-2 my-4'}>
              {'Choose Default Products for User'}
            </h2>
            <Card className={'product-selection-card-container m-2 my-4'}>
              <div className={'product-selection-tab'}>
                <div
                  className={`custom-tab-button ${
                    selectedTabType === CHOOSE_PRODUCTS ? 'active' : ''
                  }`}
                  onClick={() => setSelectedTabType(CHOOSE_PRODUCTS)}
                >
                  Bulk Upload
                </div>
                <div
                  className={`custom-tab-button ${
                    selectedTabType === CHOOSE_HIERARCHY ? 'active' : ''
                  }`}
                  onClick={() => setSelectedTabType(CHOOSE_HIERARCHY)}
                >
                  Choose Categories
                </div>
              </div>
              <div className={'text-center'}>
                {selectedTabType === CHOOSE_PRODUCTS && (
                  <BulkUpload
                    productHierarchy={productHierarchy}
                    tileData={tileData}
                    setTileData={bulkUploadSku}
                    selectedMatchingProducts={getSelectedDefaultSkus()}
                  />
                )}
                {selectedTabType === CHOOSE_HIERARCHY && (
                  <div style={{ margin: '2rem 0.5rem' }}>
                    <FormControl key={'product-recommendation-form'}>
                      <SelectWithAutoComplete
                        onChange={value => {
                          const categories = map(value, 'value');
                          handleDefaultForRecommendation(
                            'category',
                            categories
                          );
                        }}
                        options={hierarchyOptions}
                        values={getSelectedDefaultCategoriesForRecommendation()}
                        label={'Categories'}
                        size={'15rem'}
                      />
                    </FormControl>
                  </div>
                )}
                {isWarningEnabled && (
                  <p className={'selected-products-label'}>
                    <span className={'products-label-warning'}>
                      {errorMessage}
                    </span>
                  </p>
                )}
              </div>
            </Card>
          </div>
        </div>
        <div className={'border-top-1'} />
        <div className={'text-center m-2'}>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              handleAddPersonalisedCollectionSave(isTileOneRestrict)
            }
            style={{ padding: '.5rem 3rem' }}
          >
            {'save'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
