import { Route, Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import React from 'react';
import { getCurrentUser } from '../services/user.service';

export const PrivateRoute = props => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { component: Component, ...rest } = props;
  useEffect(() => {
    const fetchData = async () => {
      const result = await getCurrentUser();
      if (!result.isLoggedIn) {
        window.parent.postMessage('SESSION_EXPIRED', '*');
      }
      setIsAuthenticated(result.isLoggedIn);
      setLoading(false);
    };
    fetchData().then(r => r);
  }, []);
  return (
    <Route
      {...rest}
      render={() =>
        isAuthenticated ? (
          <Component {...props} />
        ) : loading ? (
          <div></div>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};
