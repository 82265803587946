import React, { useContext, useEffect, useState } from 'react';
import CustomCheckbox from '../../../CheckBox';
import { BusinessUnitContext } from '../../../BusinessUnitContext';
import { getStores } from '../../../../../services/store.service';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import _ from 'lodash';
import {
  FieldCondition,
  Operator
} from '../../../../../models/offer/OfferRule.model';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ReadOnlyContext } from '../../../../OfferDetail/ReadOnlyContext';
import { styled } from '@material-ui/styles';

const CustomizedFormControlLabel = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    fontSize: '16px !important'
  }
});
class CityOption {
  name = '';
  storeOptions = [];
  isChecked = false;

  constructor(storeOptions, isChecked = false) {
    this.name = _.get(_.head(storeOptions), 'city');
    this.storeOptions = storeOptions;
    this.isChecked = isChecked;
  }

  deSelectAll() {
    return this.setStateTo(false);
  }

  toggleAll() {
    const toggledState = !this.isChecked;
    return this.setStateTo(toggledState);
  }

  setStateTo(toggledState) {
    return update(this, {
      storeOptions: {
        $set: _.map(this.storeOptions, so =>
          update(so, { isChecked: { $set: toggledState } })
        )
      },
      isChecked: {
        $set: toggledState
      }
    });
  }

  getSelectedStores() {
    return _(this.storeOptions)
      .filter(so => so.isChecked)
      .map('store')
      .value();
  }

  toggleStore(storeName) {
    const storeOptions = _.map(this.storeOptions, so =>
      so.store === storeName
        ? update(so, { isChecked: { $set: !so.isChecked } })
        : so
    );
    const isChecked = _.every(storeOptions, so => so.isChecked);
    return new CityOption(storeOptions, isChecked);
  }
}

function CityCheckboxRenderer({
  cityOption,
  setCityOption,
  disabled,
  aliasList
}) {
  return (
    <div
      className={'col-4 m-4 p-4'}
      style={{
        borderRadius: 10,
        backgroundColor: '#F5F5F5'
      }}
    >
      <FormControlLabel
        control={
          <CustomCheckbox
            checked={!!cityOption.isChecked}
            onChange={() => {
              setCityOption(cityOption.toggleAll());
            }}
            disabled={disabled}
          />
        }
        label={cityOption.name}
      />
      <div>
        {cityOption.storeOptions.map(store => (
          <div key={store.store} style={{ marginLeft: 30 }}>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={!!store.isChecked}
                  onChange={() => {
                    setCityOption(cityOption.toggleStore(store.store));
                  }}
                  disabled={disabled}
                />
              }
              label={aliasList[store.store] || store.store}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

function hasStoreCondition(billCondition) {
  return (
    _.filter(
      billCondition.conditions || [],
      c => c.operator.name !== 'isNotIn' && c.fieldAccessor === 'storeName'
    ).length > 0
  );
}

export default function StoresTab({
  setBillCondition,
  billCondition,
  aliasList
}) {
  const businessUnitContext = useContext(BusinessUnitContext);
  const { readOnly } = useContext(ReadOnlyContext);
  const [cityOptions, setCityOptions] = useState([]);
  const [storesFetched, setStoresFetched] = useState(false);
  const [isRestricted, setIsRestricted] = useState(
    hasStoreCondition(billCondition)
  );
  const toggleRestriction = () => {
    setIsRestricted(!isRestricted);
    setBillCondition(
      update(billCondition, {
        conditions: {
          $set: _.filter(
            billCondition.conditions || [],
            c =>
              c.fieldAccessor !== 'storeName' && c.operator.name === 'isNotIn'
          )
        }
      })
    );
  };

  useEffect(() => {
    const setStoreOptions = () => {
      const storeCondition = _.find(
        billCondition.conditions,
        c => c.operator.name !== 'isNotIn' && c.fieldAccessor === 'storeName'
      );
      const billStores = _.get(storeCondition, 'value', []);
      setCityOptions(currentCityOptions => {
        const cityOptionStores = _(currentCityOptions)
          .flatMap(co => co.getSelectedStores())
          .value();
        if (!_.isEqual(billStores, cityOptionStores)) {
          const newCityOptions = _.map(
            currentCityOptions,
            co => co && co.deSelectAll()
          );
          const updatedCityOptions = _.reduce(
            billStores,
            (acc, bs) => _.map(acc, co => co.toggleStore(bs)),
            newCityOptions
          );
          return updatedCityOptions;
        } else {
          return currentCityOptions;
        }
      });
    };
    if (!storesFetched) {
      getStores(businessUnitContext.business)
        .then(stores => {
          const allCityStores = _.values(_.groupBy(stores, 'city'));
          const cityValueOptions = _(allCityStores)
            .map(x => new CityOption(_.filter(x, i => i.store)))
            // .filter(co => !!co.name)
            .orderBy([co => _.size(_.get(co, 'storeOptions'))], ['asc'])
            .value();
          setCityOptions(cityValueOptions);
          setStoresFetched(true);
        })
        .then(setStoreOptions);
    } else setStoreOptions();
  }, [businessUnitContext.business, billCondition, storesFetched]);
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      />
      <div>
        <CustomizedFormControlLabel
          control={
            <CustomCheckbox
              checked={isRestricted}
              onChange={toggleRestriction}
              disabled={readOnly}
            />
          }
          label={'Allow Offer redemption on Stores'}
        />
      </div>
      <div className={`${isRestricted ? 'd-flex flex-wrap' : 'd-none'}`}>
        {cityOptions.map(cityOption => (
          <CityCheckboxRenderer
            aliasList={aliasList}
            key={cityOption.name}
            disabled={readOnly}
            cityOption={cityOption}
            setCityOption={selectedCityOption => {
              const selectedStores = _(
                _.map(cityOptions, co =>
                  co.name === selectedCityOption.name ? selectedCityOption : co
                )
              )
                .flatMap(co => _.get(co, 'storeOptions', []))
                .filter(so => so.isChecked)
                .map(so => so.store)
                .compact()
                .value();
              const nonStoreConditions = _.filter(
                billCondition.conditions,
                c => c.fieldAccessor !== 'storeName'
              );
              let storeCondition = [
                new FieldCondition(
                  'storeName',
                  selectedStores,
                  Operator.isInOperator
                )
              ];
              if (_.isEmpty(selectedStores)) {
                storeCondition = [];
              }
              return setBillCondition(
                update(billCondition, {
                  conditions: {
                    $set: [...nonStoreConditions, ...storeCondition]
                  }
                })
              );
            }}
          />
        ))}
      </div>
    </div>
  );
}

StoresTab.propTypes = {
  billCondition: PropTypes.object.isRequired,
  setBillCondition: PropTypes.func.isRequired
};

CityCheckboxRenderer.propTypes = {
  cityOption: PropTypes.object.isRequired,
  setCityOption: PropTypes.func.isRequired
};
