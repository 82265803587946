import React, { useEffect, useState } from 'react';
import './index.scss';
import ReportMail from '../Assets/ReportMail.png';
import { TextFieldComponent } from '../../reusable/TextFieldComponent';
import ButtonField from '../../reusable/ButtonField';
import { useFormik } from 'formik';
import { reportInitialState } from '../../constants/configuration';
import useCurrentUser from '../../hooks/useCurrentUser';
import { Business } from '../../models/User.model';
import _ from 'lodash';
import LiveReportService from '../../services/liveReport';
import { liveReportToSchema } from '../../validation/liveReportToSchema';
import { useDispatch } from 'react-redux';
import { toastMessageAction } from '../../store/action';
import AutoCompleteChip from '../../reusable/AutoCompleteChip';
import SettingsBreadCrumbs from '../SettingsBreadCrumbs/SettingsBreadCrumbs';

const LiveReports = () => {
  const user = useCurrentUser();
  const [business, setBusiness] = useState(new Business());
  const dispatch = useDispatch();

  const onSubmit = data => {
    LiveReportService.saveLiveReportConfig(business?.id, data).then(() => {
      dispatch(
        toastMessageAction({
          severity: 'success',
          message: `Live report send successfully !!!`,
          messageStatus: true
        })
      );
    });
  };

  const formik = useFormik({
    initialValues: reportInitialState,
    validationSchema: liveReportToSchema,
    onSubmit
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    setValues
  } = formik;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsBuId = _.parseInt(urlParams.get('bu'));
    const paramsBusiness = _(user.getBusinesses()).find(
      b => b.id === paramsBuId
    );
    if (paramsBusiness) setBusiness(paramsBusiness);
  }, [user]);

  useEffect(() => {
    if (business?.id) {
      LiveReportService.getLiveReportConfig(business?.id).then(res => {
        setValues(res[0]);
      });
    }
  }, [business, setValues]);
  return (
    <div>
      <SettingsBreadCrumbs
        currentModuleName="Live Reports"
        fromModuleName="General Settings"
      />
      <div className="reportContainer">
        <img
          src={ReportMail}
          alt={'report mail'}
          style={{ width: '150px', height: '150px' }}
        />
        <h6 className="reportTitle">Engage with our exciting daily reports!</h6>
        <div className="reportParagraph">
          Sign up with your email address to receive detailed report insights
          right now.
        </div>
        <form onSubmit={formik.handleSubmit} className="formContainer">
          <TextFieldComponent
            label={'Receiver Mail'}
            name={'to'}
            onChange={handleChange}
            value={values.to}
            error={touched.to && errors.to}
            style={{ marginBottom: '30px', width: '375px' }}
            className="custom-input"
          />

          <AutoCompleteChip
            label={'Add One or More CC'}
            placeholder="mail cc"
            name="cc"
            setFieldValue={setFieldValue}
            onChange={handleChange}
            value={values.cc}
            style={{ marginBottom: '30px', width: '375px' }}
            error={errors.cc}
            touched={touched.cc}
          />
          <div className="submitButton">
            <ButtonField
              variant="contained"
              label={'Done'}
              type="submit"
              style={{ background: '#F07663', color: 'white' }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default LiveReports;
