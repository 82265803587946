import React, { FC, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { AVAILABLE_CHECKOUTS } from '../Utiles/FlipbookConfig';
import CheckoutForm from '../CheckoutForm/CheckoutForm';
import CheckoutFormChatAndBuy from '../CheckoutForm/CheckoutFormChatAndBuy';
import Modal from '@material-ui/core/Modal';
import { Drawer } from '@material-ui/core';
import { CheckoutOption } from '../../../models/FlipbookV2/flipbookV2.model';

interface FlipbookCheckoutPopupProps {
  isCheckoutFormOpen: boolean;
  handleProductPopUpClose: () => void;
  checkoutOption: CheckoutOption;
  setCartItems: any;
  cartItems: any;
  allProducts: any;
  storeDetails: any;
  CheckoutFormSteps: any;
  currentStore: string;
  whatsAppUrl: string;
  setIsCustomerAuthenticated: (state: boolean) => void;
  name: string;
  setName: (name: string) => void;
}
export const FlipbookCheckoutPopup: FC<FlipbookCheckoutPopupProps> = ({
  isCheckoutFormOpen,
  handleProductPopUpClose,
  CheckoutFormSteps,
  checkoutOption,
  allProducts,
  storeDetails,
  setCartItems,
  cartItems,
  currentStore,
  setIsCustomerAuthenticated,
  whatsAppUrl,
  name,
  setName
}) => {
  const [isMobileScreen, setIsMobileScreen] = React.useState<boolean>();
  useEffect(() => {
    if (isEmpty(isMobileScreen)) {
      const isMobileView = window.screen.width <= 600;
      setIsMobileScreen(isMobileView);
    }
  }, [isMobileScreen]);
  const getCheckoutPopup = () => {
    return (
      <div>
        {checkoutOption !== AVAILABLE_CHECKOUTS.Store.key && (
          <CheckoutForm
            setCartItems={setCartItems}
            handleProductPopUpClose={handleProductPopUpClose}
            cartItems={cartItems}
            allProducts={allProducts}
            storeDetails={storeDetails}
            checkOutSteps={CheckoutFormSteps()}
            currentStore={currentStore}
          />
        )}
        {checkoutOption === AVAILABLE_CHECKOUTS.Store.key && (
          <CheckoutFormChatAndBuy
            currentStore={currentStore}
            setCartItems={setCartItems}
            whatsAppUrl={whatsAppUrl}
            handleProductPopUpClose={handleProductPopUpClose}
            setIsCustomerAuthenticated={setIsCustomerAuthenticated}
            name={name}
            setName={setName}
          />
        )}
      </div>
    );
  };
  return (
    <>
      {isMobileScreen ? (
        <React.Fragment key={'bottom'}>
          <Drawer
            anchor={'bottom'}
            open={isCheckoutFormOpen}
            onClose={() => handleProductPopUpClose()}
          >
            {getCheckoutPopup()}
          </Drawer>
        </React.Fragment>
      ) : (
        <Modal
          open={isCheckoutFormOpen}
          aria-labelledby="simple-modal-title"
          onClose={() => handleProductPopUpClose()}
          aria-describedby="simple-modal-description"
          className={'checkout-form-model-container'}
        >
          {getCheckoutPopup()}
        </Modal>
      )}
    </>
  );
};
