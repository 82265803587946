import CustomerCube from '../components/Assets/moduleSetup/CustomerCube.svg';
import Intelliclose from '../components/Assets/moduleSetup/Intelliclose.svg';
import Offerio from '../components/Assets/moduleSetup/Offerio.svg';
import Personify from '../components/Assets/moduleSetup/Personify.svg';
import Pimkart from '../components/Assets/moduleSetup/Pimkart.svg';
import Retensee from '../components/Assets/moduleSetup/Retensee.svg';
import Journify from '../components/Assets/moduleSetup/Retensee.svg';
import config from '../config';

const { commerceConnect } = config;

export const moduleSetting = [
  {
    id: 1,
    imgSrc: Retensee,
    title: 'Retensee',
    subtitle: 'Bring in customer queries from various sources',
    pathName: '/loyalty-tiers',
    permission: ['loyalty']
  },
  {
    id: 2,
    imgSrc: Offerio,
    title: 'Offerio',
    subtitle: 'Bring in customer queries from various sources',
    pathName: '/offerio-setup',
    permission: ['Offers-management']
  },
  {
    id: 3,
    imgSrc: Intelliclose,
    title: 'Intelliclose',
    subtitle: 'Bring in customer queries from various sources',
    pathName: '/lms-configs',
    permission: ['lead_manage']
  },
  {
    id: 3,
    imgSrc: Pimkart,
    title: 'Commerce Connect',
    subtitle: 'Bring in customer queries from various sources',
    pathName: `${commerceConnect}/admin/products`,
    permission: ['commerce-connect'],
    external: true
  },
  {
    id: 4,
    imgSrc: Personify,
    title: 'Personify',
    subtitle: 'Bring in customer queries from various sources',
    pathName: '/personify',
    permission: ['manage-shoppersDelight-pwa']
  },
  {
    id: 5,
    imgSrc: CustomerCube,
    title: 'Customer Cube',
    subtitle: 'Bring in customer queries from various sources',
    pathName: `${config.dashboard}/customer-cube/settings`,
    external: true
  },
  {
    id: 6,
    imgSrc: Journify,
    title: 'Campaign Delight',
    subtitle: 'Bring in customer queries from various sources',
    pathName: '/campaign-delight/settings'
  }
];
