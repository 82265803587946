/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { FC, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import moment from 'moment';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { get, head, isEqual, isUndefined, map } from 'lodash';
import { createOfferPromoReport } from '../../../services/TenantReports/OfferPromoReport.service';
import { OfferPromoReportFilter } from './FilterType';

export const GrouperColumn = {
  Offer: 'offerCode',
  BillPromo: 'billsPromoCode',
  ProductPromo: 'productsPromoCode'
};

interface ReportActionsProps {
  filters: OfferPromoReportFilter;
  setFilters: (filter: OfferPromoReportFilter) => void;
  setRefreshView: (id: string) => void;
}

const OfferPromoReport: FC<ReportActionsProps> = ({
  setRefreshView,
  filters,
  setFilters
}) => {
  const user = useCurrentUser();

  const handleDateChange = (date: any, name: string) => {
    setFilters({ ...filters, [name]: date });
  };
  const handleColumnValueChange = (value: any) => {
    setFilters({ ...filters, grouperColumn: value });
  };

  useEffect(() => {
    if (isUndefined(filters.businessId) && !isEqual(user.id, 0)) {
      const business = user.getBusinesses();
      const headBusiness = head(business);
      const defaultBusinessId = get(headBusiness, 'id', 0);
      setFilters({ ...filters, businessId: defaultBusinessId });
    }
  }, [filters, setFilters, user]);
  const handleGenerateReport = (): void => {
    createOfferPromoReport(filters).then((res: any) => {
      const jobId = get(res, 'jobId', '');
      setRefreshView(jobId);
    });
  };
  const maxDate = moment(new Date())
    .subtract('days', 1)
    .toDate();
  const currentDate = moment(new Date()).toDate();
  return (
    <div
      style={{
        margin: '30px 0',
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            label="Date"
            maxDate={maxDate}
            value={filters.startDate}
            onChange={e => handleDateChange(e, 'startDate')}
            variant="inline"
            format="yyyy-MM-dd"
          />
          <KeyboardDatePicker
            label="End Date"
            maxDate={currentDate}
            value={filters.endDate}
            onChange={e => handleDateChange(e, 'endDate')}
            variant="inline"
            format="yyyy-MM-dd"
            style={{ marginLeft: '20px' }}
          />
        </MuiPickersUtilsProvider>
        <FormControl style={{ minWidth: 180, marginRight: '10px' }}>
          <InputLabel id="grouper-column-select-helper-label">
            Grouping Column
          </InputLabel>
          <Select
            labelId="select-grouper-column-label"
            id="select-grouper-column"
            value={filters.grouperColumn}
            label="Grouper Column"
            onChange={e => handleColumnValueChange(e.target.value)}
          >
            {map(GrouperColumn, (value, key) => {
              return (
                <MenuItem
                  value={value}
                  key={key}
                  style={{ textTransform: 'capitalize' }}
                >
                  {key}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={handleGenerateReport}
      >
        Generate Report
      </Button>
    </div>
  );
};

export default OfferPromoReport;
