/* eslint-disable @typescript-eslint/explicit-function-return-type */
import './index.scss';

import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { providerHeading } from '../../constants/otpConfiguration';
import useCurrentUser from '../../hooks/useCurrentUser';
import { Business } from '../../models/User.model';
import { StyledTabs } from '../../reusable/StyledTabs';
import configService from '../../services/config.service';
import CommcryptImage from '../Assets/channels/commcrypt.png';
import KarixImage from '../Assets/channels/Karixlogo.png';
import MSG91Image from '../Assets/channels/MSG91logo.png';
import OneXtelImage from '../Assets/channels/OneXtelLogo.png';
import SettingsBreadCrumbs from '../SettingsBreadCrumbs/SettingsBreadCrumbs';
import ConfirmationModal from './ConfirmationModal';
import LoyaltyOtp from './LoyaltyOtp';
import OtherOtpConfig from './OtherOtpConfig';
import ICSLogo from '../Assets/channels/ics-logo.png';
import Providers from '../GeneralSetting/Providers';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>;
}

const OTPConfiguration = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }
  const [imgPriority, setImgPriority] = useState();
  const [business, setBusiness] = useState(new Business());
  const user = useCurrentUser();
  const [smsData, setSmsData] = React.useState();
  const [priorityData, setPriorityData] = useState([]);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsBuId = _.parseInt(urlParams.get('bu'));
    const paramsBusiness = _(user.getBusinesses()).find(
      b => b.id === paramsBuId
    );
    if (paramsBusiness) setBusiness(paramsBusiness);
  }, [user]);

  useEffect(() => {
    configService.getChannelConfig().then(({ channels }) => {
      if (channels) {
        channels.map(item => {
          if (item?.version === 'sms') {
            setSmsData(item);
            setPriorityData(
              item?.handlers?.find(item => item?.priorityOrder === 1)
            );
          }
          return null;
        });
      }
    });
  }, []);

  useEffect(() => {
    priorityData?.name === 'Msg91SendSms' && setImgPriority('msg91');
    priorityData?.name === 'KarixSendV2' && setImgPriority('karix');
    priorityData?.name === 'CommcryptSendSms' && setImgPriority('commcrypt');
    priorityData?.name === 'OneXtelImage' && setImgPriority('onextel');
  }, [priorityData]);

  return (
    <>
      <SettingsBreadCrumbs
        currentModuleName="OTP Configuration"
        fromModuleName="General Settings"
      />
      <div className="otpContainer">
        <h6 className="otpTitle">Select a Provider</h6>
        <div>
          <img
            src={CommcryptImage}
            alt="CommcryptImage"
            className="providerLogo"
            name="commcrypt"
            style={{
              border: imgPriority === 'commcrypt' && '2px solid #f26e5f'
            }}
            onClick={e => setImgPriority(e.target.name)}
          />
          <img
            src={KarixImage}
            alt="KarixImage"
            className="providerLogo"
            name="karix"
            style={{
              border: imgPriority === 'karix' && '2px solid #f26e5f'
            }}
            onClick={e => setImgPriority(e.target.name)}
          />
          <img
            src={MSG91Image}
            alt="MSG91Image"
            className="providerLogo"
            name="msg91"
            style={{
              border: imgPriority === 'msg91' && '2px solid #f26e5f'
            }}
            onClick={e => setImgPriority(e.target.name)}
          />
          <img
            src={OneXtelImage}
            alt="OneXtelImage"
            className="providerLogo"
            name="onextel"
            style={{
              border: imgPriority === 'onextel' && '2px solid #f26e5f'
            }}
            onClick={e => setImgPriority(e.target.name)}
          />
          <img
            src={ICSLogo}
            alt="IcsImage"
            className="providerLogo"
            name={Providers.ICS}
            style={{
              border: imgPriority === Providers.ICS && '2px solid #f26e5f'
            }}
            onClick={e => setImgPriority(e.target.name)}
          />
        </div>
        <StyledTabs value={value} onChange={handleChange}>
          {providerHeading.map(({ id, label }, index) => {
            return <Tab key={index} label={label} {...a11yProps(id)} />;
          })}
        </StyledTabs>
        <TabPanel value={value} index={0}>
          <LoyaltyOtp
            title="Loyalty"
            providerName={imgPriority}
            keyName={'sms-loyalty-otp'}
            businessUnit={business}
            type="loylaty"
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <LoyaltyOtp
            title="Offerio"
            providerName={imgPriority}
            keyName={'sms-offer-otp'}
            businessUnit={business}
            type="offerio"
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <LoyaltyOtp
            title="One Flow"
            providerName={imgPriority}
            keyName={'sms-custom-otp-auth'}
            businessUnit={business}
            type="loyalty"
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <LoyaltyOtp
            title="Flipsell"
            providerName={imgPriority}
            keyName={'sms-flipsell-otp'}
            businessUnit={business}
            type="flipsell"
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <OtherOtpConfig title="Other Otp Configs" businessUnit={business} />
        </TabPanel>
        {smsData?.handlers?.length === 0 && <ConfirmationModal />}
      </div>
    </>
  );
};

export default OTPConfiguration;
