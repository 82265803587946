import React from 'react';
import config from '../config';

export const ParentNavigate = props => {
  const { to } = props;
  const toWithSlash = to.startsWith('/') ? to : `/${to}`;
  const url = `${toWithSlash}${window.location.search || ''}`;

  const messageData = {
    navigateTo: url
  };

  window.parent.postMessage(messageData, config.dashboardUrl);

  return <></>;
};
