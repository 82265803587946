import './style.css';

import { Paper, Snackbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ReactComponent as AddIcon } from '../../components/Assets/images/icons/add_icon_round.svg';
import { ReactComponent as DisabledAddIcon } from '../../components/Assets/images/icons/add_icon_round_disabled.svg';
import LeftHill from '../../components/Assets/images/left_hill.svg';
import { ReactComponent as HeaderImage } from '../../components/Assets/images/people_with_giftbox.svg';
import RightHill from '../../components/Assets/images/right_hill.svg';
import { PaginationBar } from '../../components/PaginationBar/PaginationBar';
import useCurrentUser from '../../hooks/useCurrentUser';
import { Business } from '../../models/User.model';
import offerService from '../../services/offer.service';
import offerOfferTagService from '../../services/offerOfferTag.service';
import offerTagService from '../../services/offerTags.service';
import { ParentLink } from '../../utils/ParentLink';
import { AddTagDialog } from './AddTagDialog';
import { ChangeDateDialog } from './ChangeDateDialog';
import { ExportOffersButton } from './ExportOffersButton';
import { OfferFilterBar } from './OfferFilterBar';
import classes from './OfferMaster.module.css';
import { OfferSortBar } from './OfferSortBar';
import { OfferTable } from './OfferTable';
import { RemoveTagDialog } from './RemoveTagDialog';

export const OfferMaster = props => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const totalPages = Math.ceil(totalCount / limit);
  const [searchTerm, setSearchTerm] = useState('');
  const [offers, setOffers] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [isOffersLoading, setIsOffersLoading] = useState(false);
  const [allOfferTags, setAllOfferTags] = useState([]);
  const [selectedFilterTags, setSelectedFilterTags] = useState([]);
  const [selectedStatusKey, setSelectedStatusKey] = useState('ALL');
  const [selectedSortKey, setSelectedSortKey] = useState('DATE');
  const [sortAscending, setSortAscending] = useState(false);
  const [changeDateDialogOpen, setChangeDateDialogOpen] = useState(false);
  const [removeTagDialogOpen, setRemoveTagDialogOpen] = useState(false);
  const [addTagDialogOpen, setAddTagDialogOpen] = useState(false);
  const [selectedAddTags, setSelectedAddTags] = useState([]);
  const [selectedRemoveTags, setSelectedRemoveTags] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(
    moment().startOf('day')
  );
  const [selectedEndDate, setSelectedEndDate] = useState(moment().endOf('day'));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertMessage, setAlertMessage] = useState('');
  const [addTagDialogLoading, setTagAddDialogLoading] = useState(false);
  const [addTagDialogErrorMessage, setTagAddDialogErrorMessage] = useState('');

  const topRef = useRef(null);

  const limitOptions = [10, 25, 50, 100];
  const statusOptions = [
    { key: 'RUNNING', name: 'Running' },
    { key: 'STOPPED', name: 'Stopped' },
    { key: 'DRAFTED', name: 'Drafted' },
    { key: 'ALL', name: 'All' }
  ];

  const sortOptions = [
    { key: 'OFFER_ID', name: 'Offer Code', defaultSortAscending: true },
    { key: 'DATE', name: 'Updated At', defaultSortAscending: false }
  ];

  const [userPermissions, setUserPermissions] = useState([]);
  const [business, setBusiness] = useState(new Business());
  const user = useCurrentUser();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsBuId = _.parseInt(urlParams.get('bu'));
    const paramsBusiness = _(user.getBusinesses()).find(
      b => b.id === paramsBuId
    );
    if (paramsBusiness) setBusiness(paramsBusiness);
    setUserPermissions(
      _.find(_.get(user, 'tenant_role_groups'), role => {
        return user.tenantId === role.tenantId;
      })?.permissions
    );
  }, [user]);

  console.log(userPermissions);

  const getFilter = () => {
    return {
      page: page,
      limit: limit,
      businessUnitId: business.id,
      tagIds: _.map(selectedFilterTags, offerTag => offerTag.id),
      searchTerm: searchTerm,
      status: selectedStatusKey,
      sortBy: selectedSortKey,
      order: sortAscending ? 'ASC' : 'DESC'
    };
  };

  const getOfferMaster = filter => {
    return offerService.getOfferMaster(filter).then(paginatedResult => {
      const { totalCount, results } = paginatedResult;
      setOffers(results);
      setTotalCount(totalCount);
      setIsOffersLoading(false);
    });
  };

  const getOfferMasterDebounced = useCallback(
    _.debounce(getOfferMaster, 1000, { leading: true }),
    []
  );

  useEffect(() => {
    if (business.id === 0) {
      return;
    }
    offerTagService.getOfferTags(business.id).then(offerTags => {
      setAllOfferTags(offerTags);
    });
  }, [business.id]);

  useEffect(() => {
    if (business.id === 0) {
      setOffers([]);
      setTotalCount(0);
      return;
    }
    const filter = getFilter();
    setIsOffersLoading(true);
    getOfferMaster(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, business.id]);

  useEffect(() => {
    if (business.id === 0) {
      setOffers([]);
      setTotalCount(0);
      return;
    }
    const filter = getFilter();
    setIsOffersLoading(true);
    getOfferMasterDebounced(filter);
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    business.id,
    getOfferMasterDebounced,
    limit,
    searchTerm,
    selectedStatusKey,
    selectedFilterTags,
    selectedSortKey,
    sortAscending
  ]);

  const handleSelectAllOffersClick = event => {
    const filter = getFilter();
    return offerService
      .getOfferMaster({ ...filter, page: 0, limit: totalCount + 1 })
      .then(paginatedResult => {
        const { results } = paginatedResult;
        setSelectedOffers(results);
      });
  };

  const handleOnClickNextTable = event => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const handleOnClickPreviousTable = event => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleOnLimitChange = event => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handleOnSearchTermChange = event => {
    setSearchTerm(event.target.value);
  };

  const handleOnSelectedFilterTagsChange = (event, value) => {
    setSelectedFilterTags(value);
  };

  const handleOnSelectedStatusChange = event => {
    setSelectedStatusKey(event.target.value);
  };

  const handleOnSelectedSortChange = event => {
    setSelectedSortKey(event.target.value);
    const selectedOption = _.find(
      sortOptions,
      o => o.key === event.target.value
    );
    setSortAscending(selectedOption.defaultSortAscending);
  };

  const handleOnSortOrderToggle = event => {
    setSortAscending(prevState => !prevState);
  };

  const handleOnSelectedOffersChange = selectedOffers => {
    setSelectedOffers(selectedOffers);
  };

  const handleOnResetClick = event => {
    setPage(0);
    setSelectedFilterTags([]);
    setSelectedStatusKey('ALL');
    setSearchTerm('');
  };

  const handleOnSelectedRemoveTagsChange = (event, value) => {
    setSelectedRemoveTags(value);
  };

  const handleOnSelectedAddTagsChange = (event, value) => {
    setSelectedAddTags(value);
  };

  const handleOnCreateTagClick = async tagName => {
    setTagAddDialogLoading(true);
    try {
      await offerTagService.createOfferTag(business.id, tagName);
      const offerTags = await offerTagService.getOfferTags(business.id);
      setAllOfferTags(offerTags);
      const newTag = _.find(offerTags, o => o.name === tagName);
      setSelectedAddTags([...selectedAddTags, newTag]);
    } catch (e) {
      setTagAddDialogErrorMessage(
        `Something went wrong while creating the tag ${tagName}`
      );
      console.error(e);
    } finally {
      setTagAddDialogLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleAddTagDialogClose = event => {
    setAddTagDialogOpen(false);
    setSelectedAddTags([]);
  };

  const handleRemoveTagDialogClose = event => {
    setRemoveTagDialogOpen(false);
    setSelectedRemoveTags([]);
  };

  const handleChangeDateDialogClose = event => {
    setChangeDateDialogOpen(false);
    setSelectedStartDate(moment().startOf('day'));
    setSelectedEndDate(moment().endOf('day'));
  };

  const handleOnAddTagsSubmit = async event => {
    try {
      await offerOfferTagService.addOfferOfferTags(
        business.id,
        _.map(selectedAddTags, t => t.id),
        _.map(selectedOffers, o => o.cId)
      );

      setAlertMessage(
        `Tags have been successfully added to the ${selectedOffers.length} selected offers!`
      );
      setAlertSeverity('success');
    } catch (e) {
      setAlertMessage(
        'Something went wrong while adding tags to selected offers!'
      );
      setAlertSeverity('error');
    } finally {
      setAddTagDialogOpen(false);
      handleAddTagDialogClose();
      setSnackbarOpen(true);
      handleOnResetClick();
    }
  };

  const handleOnRemoveTagsSubmit = async event => {
    try {
      await offerOfferTagService.removeOfferOfferTags(
        business.id,
        _.map(selectedRemoveTags, t => t.id),
        _.map(selectedOffers, o => o.cId)
      );
      setAlertMessage(
        `Tags have been successfully removed from the ${selectedOffers.length} selected offers!`
      );
      setAlertSeverity('success');
    } catch (e) {
      setAlertMessage(
        'Something went wrong while removing tags from selected offers!'
      );
      setAlertSeverity('error');
    } finally {
      setRemoveTagDialogOpen(false);
      handleRemoveTagDialogClose();
      setSnackbarOpen(true);
      handleOnResetClick();
    }
  };

  const handleOnChangeDateSubmit = async () => {
    if (
      !moment(selectedStartDate).isValid() ||
      !moment(selectedEndDate).isValid()
    )
      return;
    try {
      await offerService.changeDate(
        business.id,
        _.map(selectedOffers, o => o.code),
        selectedStartDate,
        selectedEndDate
      );
      setAlertMessage(
        `Dates have been changed successfully for the ${selectedOffers.length} selected offers!`
      );
      setAlertSeverity('success');
    } catch (e) {
      setAlertMessage(
        'Something went wrong while changing dates for the selected offers'
      );
      setAlertSeverity('error');
    } finally {
      setChangeDateDialogOpen(false);
      handleChangeDateDialogClose();
      setSnackbarOpen(true);
      handleOnResetClick();
    }
  };
  return (
    <div>
      <div
        id={'header'}
        className={classes.header}
        style={{
          background: `url(${LeftHill}) no-repeat left bottom, url(${RightHill}) no-repeat right bottom`
        }}
      >
        <HeaderImage />
        <div style={{ fontSize: 24, fontWeight: 'bold' }}>Offer Master</div>
        <div />
      </div>
      <div className={classes.offerMasterContainer}>
        <div>
          <div className={classes.topBox} ref={topRef}>
            <div>
              <ParentLink
                className={classes.createOfferLink}
                to={'/offers/create'}
                style={
                  _.includes(userPermissions, 'create-offer')
                    ? {}
                    : { pointerEvents: 'none', color: '#A8A8A8' }
                }
              >
                {_.includes(userPermissions, 'create-offer') ? (
                  <AddIcon />
                ) : (
                  <DisabledAddIcon />
                )}
                <span>Create Offer</span>
              </ParentLink>
            </div>
          </div>
          <Paper elevation={6}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: 25
              }}
            >
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                TOTAL OFFERS {totalCount}
              </div>
              <div style={{ display: 'flex', gap: '20px' }}>
                <ExportOffersButton offers={selectedOffers} />

                <Button
                  disabled={selectedOffers.length === 0}
                  variant={'contained'}
                  color={'primary'}
                  onClick={() => {
                    setChangeDateDialogOpen(true);
                  }}
                >
                  Change Date
                </Button>
                <Button
                  disabled={selectedOffers.length === 0}
                  variant={'contained'}
                  color={'primary'}
                  onClick={() => {
                    setRemoveTagDialogOpen(true);
                  }}
                >
                  Remove Tags
                </Button>
                <Button
                  disabled={selectedOffers.length === 0}
                  variant={'contained'}
                  color={'primary'}
                  onClick={() => {
                    setAddTagDialogOpen(true);
                  }}
                >
                  Add Tags
                </Button>
              </div>
              <AddTagDialog
                open={addTagDialogOpen}
                onClose={handleAddTagDialogClose}
                numSelectedOffers={selectedOffers.length}
                availableTags={allOfferTags}
                selectedTags={selectedAddTags}
                onSelectedTagsChange={handleOnSelectedAddTagsChange}
                onCreateTagClick={handleOnCreateTagClick}
                onSubmit={handleOnAddTagsSubmit}
                loading={addTagDialogLoading}
                errorMessage={addTagDialogErrorMessage}
                resetErrorMessage={() => setTagAddDialogErrorMessage('')}
              />
              <RemoveTagDialog
                open={removeTagDialogOpen}
                onClose={handleRemoveTagDialogClose}
                numSelectedOffers={selectedOffers.length}
                availableTags={_.filter(allOfferTags, ot =>
                  _.includes(
                    _.flatMap(selectedOffers, o => _.map(o.tags, t => t.id)),
                    ot.id
                  )
                )}
                selectedTags={selectedRemoveTags}
                onSelectedTagsChange={handleOnSelectedRemoveTagsChange}
                onSubmit={handleOnRemoveTagsSubmit}
              />
              <ChangeDateDialog
                open={changeDateDialogOpen}
                onClose={handleChangeDateDialogClose}
                numSelectedOffers={selectedOffers.length}
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                onStartDateChange={date => setSelectedStartDate(date)}
                onEndDateChange={date => setSelectedEndDate(date)}
                onSubmit={handleOnChangeDateSubmit}
              />
            </div>
            <OfferFilterBar
              searchTerm={searchTerm}
              onSearchTermChange={handleOnSearchTermChange}
              selectedTags={selectedFilterTags}
              onSelectedTagsChange={handleOnSelectedFilterTagsChange}
              allTags={allOfferTags}
              selectedStatus={selectedStatusKey}
              onSelectedStatusChange={handleOnSelectedStatusChange}
              allStatuses={statusOptions}
              onResetClick={handleOnResetClick}
            />
            <OfferSortBar
              selectedSortKey={selectedSortKey}
              allSortOptions={sortOptions}
              onSelectedSortKeyChange={handleOnSelectedSortChange}
              sortAscending={sortAscending}
              onSortOrderToggle={handleOnSortOrderToggle}
            />
            {selectedOffers.length === offers.length && totalPages > 1 && (
              <div
                style={{
                  background: '#FFF7F5',
                  textAlign: 'center',
                  padding: 10
                }}
              >
                All {selectedOffers.length} offers in this page are selected.
                <Button
                  style={{ margin: '0 10px' }}
                  color={'primary'}
                  onClick={handleSelectAllOffersClick}
                  disabled={!_.includes(userPermissions, 'create-offer')}
                >
                  Select all {totalCount} matching offers
                </Button>
              </div>
            )}
            {selectedOffers.length > offers.length && totalPages > 1 && (
              <div
                style={{
                  background: '#FFF7F5',
                  textAlign: 'center',
                  padding: 10
                }}
              >
                All {selectedOffers.length} matching offers are selected.
                <Button
                  style={{ margin: '0 10px' }}
                  color={'primary'}
                  onClick={() => {
                    setSelectedOffers([]);
                  }}
                >
                  Clear Selection
                </Button>
              </div>
            )}
            <OfferTable
              topRef={topRef}
              offers={offers}
              selectedOffers={selectedOffers}
              onSelectedOffersChange={handleOnSelectedOffersChange}
              isLoading={isOffersLoading}
              canPerformActions={_.includes(userPermissions, 'create-offer')}
              canViewOffers={_.includes(userPermissions, 'view-offer')}
            />
            <PaginationBar
              currentPage={page}
              totalPages={totalPages}
              handleOnClickNext={handleOnClickNextTable}
              handleOnClickPrevious={handleOnClickPreviousTable}
              rowsPerPage={limit}
              rowsPerPageOptions={limitOptions}
              handleOnChangeRowsPerPage={handleOnLimitChange}
            />
          </Paper>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        autoHideDuration={10000}
      >
        <Alert severity={alertSeverity} onClose={handleSnackbarClose}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
