/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { isFeatureEnabled } from '../../services/feature-check.service';
import { BodyWidget } from './BodyWidget/BodyWidget';
import AppContext from './context/AppContext';
import { getJourneyTemplate } from '../../services/journey-template.service';
import _ from 'lodash';

const JourneyBuilder = props => {
  const [journeyTemplate, setJourneyTemplate] = useState();

  useEffect(() => {
    async function getTemplate() {
      setJourneyTemplate(JSON.parse(await getJourneyTemplate()));
    }
    getTemplate();
  }, []);

  const app = React.useContext(AppContext);
  return isFeatureEnabled('journey-builder') && !_.isEmpty(journeyTemplate) ? (
    <BodyWidget app={app} templates={journeyTemplate} />
  ) : (
    <div></div>
  );
};
export default JourneyBuilder;
