import React, { Context } from 'react';
import _ from 'lodash';

type TaggerImagePanelContext = {
  taggerImagePanelInfo: {};
  setTaggerImagePanelInfo: (data: any) => void;
};
export const TaggerImagePanelContext: Context<TaggerImagePanelContext> = React.createContext<
  TaggerImagePanelContext
>({
  taggerImagePanelInfo: {},
  setTaggerImagePanelInfo: _.noop
});
