import { TileData } from './TileData.model';

export enum ProductCardTemplateType {
  Title = 'title',
  SubTitle = 'subTitle'
}

export interface ProductCardTemplate {
  title: string;
  subTitle: string;
}
interface CurrentPageSkusTemplate {
  path: string;
  sku: string[];
}

interface DefaultValue {
  type: 'sku' | 'category';
  value: string[];
}

interface Sort {
  field: string;
  order: string;
}

export class TileDataPersonalised extends TileData {
  constructor(
    title: string,
    skus: string[],
    defaultValues: DefaultValue,
    products: string[],
    isShowTitleOnFlipbook: boolean,
    productHierarchyTreeChecked: string[],
    productHierarchyTreeExpanded: string[],
    productsFilteredByFirstHierarchy: string[],
    productCardTemplate: ProductCardTemplate,
    currentPageSKUS: CurrentPageSkusTemplate[],
    sort: Sort
  ) {
    super();
    this.title = title;
    this.skus = skus;
    this.defaultValues = defaultValues;
    this.products = products;
    this.isShowTitleOnFlipbook = isShowTitleOnFlipbook;
    this.productHierarchyTreeChecked = productHierarchyTreeChecked;
    this.productHierarchyTreeExpanded = productHierarchyTreeExpanded;
    this.productsFilteredByFirstHierarchy = productsFilteredByFirstHierarchy;
    this.productCardTemplate = productCardTemplate;
    this.currentPageSKUS = currentPageSKUS;
    this.sort = sort
  }

  public title: string;
  public skus: string[];
  public defaultValues: DefaultValue;
  public products: string[];
  public isShowTitleOnFlipbook: boolean;
  public productHierarchyTreeChecked: string[];
  public productHierarchyTreeExpanded: string[];
  public productsFilteredByFirstHierarchy: string[];
  public productCardTemplate: ProductCardTemplate;
  public currentPageSKUS?: CurrentPageSkusTemplate[];
  public sort: Sort;
}
