/*eslint-disable no-template-curly-in-string */
export const tags = [
  {
    title: 'Link',
    value: '<@promo_url>'
  },
  {
    title: 'Activated Promos',
    value: '${data.jpString("entities.user.activatedPromos")}',
    id: 0
  },
  {
    title: 'Address',
    value: '${data.jpString("entities.user.address")}',
    id: 1
  },
  {
    title: 'Anniversary',
    value: '${data.jpString("entities.user.anniversary")}',
    id: 2
  },
  {
    title: 'Available Promos',
    value: '${data.jpString("entities.user.availablePromos")}',
    id: 3
  },
  {
    title: 'Bill Type',
    value: '${data.jpString("entities.user.billType")}',
    id: 4
  },
  {
    title: 'Bills',
    value: '${data.jpString("entities.user.bills")}',
    id: 5
  },
  {
    title: 'Bills Count',
    value: '${data.jpString("entities.user.billsCount")}',
    id: 6
  },
  {
    title: 'Channel',
    value: '${data.jpString("entities.user.channel")}',
    id: 7
  },
  {
    title: 'Chatbot Interests',
    value: '${data.jpString("entities.user.chatbotInterests")}',
    id: 8
  },
  {
    title: 'Contact',
    value: '${data.jpString("entities.user.contact")}',
    id: 9
  },
  {
    title: 'Days Since Last Bill',
    value: '${data.jpString("entities.user.daysSinceLastBill")}',
    id: 10
  },
  {
    title: 'Dob',
    value: '${data.jpString("entities.user.dob")}',
    id: 11
  },
  {
    title: 'Endless Aisle Interests',
    value: '${data.jpString("entities.user.endlessAisleInterests")}',
    id: 12
  },
  {
    title: 'External Customer Id',
    value: '${data.jpString("entities.user.externalCustomerId")}',
    id: 13
  },
  {
    title: 'Facebook Leads',
    value: '${data.jpString("entities.user.facebookLeads")}',
    id: 14
  },
  {
    title: 'Id',
    value: '${data.jpString("entities.user.id")}',
    id: 15
  },
  {
    title: 'Invoice Type',
    value: '${data.jpString("entities.user.invoiceType")}',
    id: 16
  },
  {
    title: 'Journey Events',
    value: '${data.jpString("entities.user.journeyEvents")}',
    id: 17
  },
  {
    title: 'Last Bill Date',
    value: '${data.jpString("entities.user.lastBillDate")}',
    id: 18
  },
  {
    title: 'Loyalty',
    value: '${data.jpString("entities.user.loyalty")}',
    id: 19
  },
  {
    title: 'Name',
    value: '${data.jpString("entities.user.name")}',
    id: 20
  },
  {
    title: 'Notification Channel Stats',
    value: '${data.jpString("entities.user.notificationChannelStats")}',
    id: 21
  },
  {
    title: 'Order Captures',
    value: '${data.jpString("entities.user.orderCaptures")}',
    id: 22
  },
  {
    title: 'Order Items',
    value: '${data.jpString("entities.user.orderItems")}',
    id: 23
  },
  {
    title: 'Predicted Cluster Id',
    value: '${data.jpString("entities.user.predictedClusterId")}',
    id: 24
  },
  {
    title: 'Product Frequencies',
    value: '${data.jpString("entities.user.productFrequencies")}',
    id: 25
  },
  {
    title: 'Query',
    value: '${data.jpString("entities.user.query")}',
    id: 26
  },
  {
    title: 'Redeemed Promos',
    value: '${data.jpString("entities.user.redeemedPromos")}',
    id: 27
  },
  {
    title: 'Referred Users',
    value: '${data.jpString("entities.user.referredUsers")}',
    id: 28
  },
  {
    title: 'Returned Bills',
    value: '${data.jpString("entities.user.returnedBills")}',
    id: 29
  },
  {
    title: 'Source Type',
    value: '${data.jpString("entities.user.sourceType")}',
    id: 30
  },
  {
    title: 'Tags',
    value: '${data.jpString("entities.user.tags")}',
    id: 31
  },
  {
    title: 'Updated Version',
    value: '${data.jpString("entities.user.updatedVersion")}',
    id: 32
  },
  {
    title: 'Vendor Coupon',
    value: '${data.jpString("entities.user.vendorCoupon")}',
    id: 33
  },
  {
    title: 'Walk In Interests',
    value: '${data.jpString("entities.user.walkInInterests")}',
    id: 34
  },
  {
    title: 'Bill Balance Amount',
    value: '${data.jpString("entities.user.bills[*].balanceAmount")}',
    id: 0
  },
  {
    title: 'Bill Balance Due Date',
    value: '${data.jpString("entities.user.bills[*].balanceDueDate")}',
    id: 1
  },
  {
    title: 'Bill Bill Amount',
    value: '${data.jpString("entities.user.bills[*].billAmount")}',
    id: 2
  },
  {
    title: 'Bill Bill Discount Amount',
    value: '${data.jpString("entities.user.bills[*].billDiscountAmount")}',
    id: 3
  },
  {
    title: 'Bill Bill No',
    value: '${data.jpString("entities.user.bills[*].billNo")}',
    id: 4
  },
  {
    title: 'Bill Bill Tax Amount',
    value: '${data.jpString("entities.user.bills[*].billTaxAmount")}',
    id: 5
  },
  {
    title: 'Bill Bill Type',
    value: '${data.jpString("entities.user.bills[*].billType")}',
    id: 6
  },
  {
    title: 'Bill Channel',
    value: '${data.jpString("entities.user.bills[*].channel")}',
    id: 7
  },
  {
    title: 'Bill Date',
    value: '${data.jpString("entities.user.bills[*].date")}',
    id: 8
  },
  {
    title: 'Bill Days Since Last Bill',
    value: '${data.jpString("entities.user.bills[*].daysSinceLastBill")}',
    id: 9
  },
  {
    title: 'Bill Facebook Ad Name',
    value: '${data.jpString("entities.user.bills[*].facebookAdName")}',
    id: 10
  },
  {
    title: 'Bill Facebook Campaign Id',
    value: '${data.jpString("entities.user.bills[*].facebookCampaignId")}',
    id: 11
  },
  {
    title: 'Bill Generated Bill Id',
    value: '${data.jpString("entities.user.bills[*].generatedBillId")}',
    id: 12
  },
  {
    title: 'Bill Invoice Type',
    value: '${data.jpString("entities.user.bills[*].invoiceType")}',
    id: 13
  },
  {
    title: 'Bill Naminrctyr',
    value: '${data.jpString("entities.user.bills[*].naminrctyr")}',
    id: 14
  },
  {
    title: 'Bill Nps Response',
    value: '${data.jpString("entities.user.bills[*].npsResponse")}',
    id: 15
  },
  {
    title: 'Bill Offer Code',
    value: '${data.jpString("entities.user.bills[*].offerCode")}',
    id: 16
  },
  {
    title: 'Bill Order States',
    value: '${data.jpString("entities.user.bills[*].orderStates")}',
    id: 17
  },
  {
    title: 'Bill Paid By Card',
    value: '${data.jpString("entities.user.bills[*].paidByCard")}',
    id: 18
  },
  {
    title: 'Bill Paid By Cash',
    value: '${data.jpString("entities.user.bills[*].paidByCash")}',
    id: 19
  },
  {
    title: 'Bill Payment Splits',
    value: '${data.jpString("entities.user.bills[*].paymentSplits")}',
    id: 20
  },
  {
    title: 'Bill Products',
    value: '${data.jpString("entities.user.bills[*].products")}',
    id: 21
  },
  {
    title: 'Bill Sales Person Code',
    value: '${data.jpString("entities.user.bills[*].salesPersonCode")}',
    id: 22
  },
  {
    title: 'Bill Sales Person Email',
    value: '${data.jpString("entities.user.bills[*].salesPersonEmail")}',
    id: 23
  },
  {
    title: 'Bill Store Name',
    value: '${data.jpString("entities.user.bills[*].storeName")}',
    id: 24
  },
  {
    title: 'Bill Store User Email',
    value: '${data.jpString("entities.user.bills[*].storeUserEmail")}',
    id: 25
  },
  {
    title: 'Bill Total Amount',
    value: '${data.jpString("entities.user.bills[*].totalAmount")}',
    id: 26
  },
  {
    title: 'Bill Total Discount Amount',
    value: '${data.jpString("entities.user.bills[*].totalDiscountAmount")}',
    id: 27
  },
  {
    title: 'Bill Total Tax Amount',
    value: '${data.jpString("entities.user.bills[*].totalTaxAmount")}',
    id: 28
  },
  {
    title: 'Bill Version',
    value: '${data.jpString("entities.user.bills[*].version")}',
    id: 29
  },
  {
    title: 'Product Barcode',
    value: '${data.jpString("entities.user.bills[*].products[*].barcode")}',
    id: 0
  },
  {
    title: 'Product Brand',
    value: '${data.jpString("entities.user.bills[*].products[*].brand")}',
    id: 1
  },
  {
    title: 'Product Bucket Id',
    value: '${data.jpString("entities.user.bills[*].products[*].bucketId")}',
    id: 2
  },
  {
    title: 'Product Category',
    value: '${data.jpString("entities.user.bills[*].products[*].category")}',
    id: 3
  },
  {
    title: 'Product Closure',
    value: '${data.jpString("entities.user.bills[*].products[*].closure")}',
    id: 4
  },
  {
    title: 'Product Collar',
    value: '${data.jpString("entities.user.bills[*].products[*].collar")}',
    id: 5
  },
  {
    title: 'Product Color',
    value: '${data.jpString("entities.user.bills[*].products[*].color")}',
    id: 6
  },
  {
    title: 'Product Colour Base',
    value: '${data.jpString("entities.user.bills[*].products[*].colourBase")}',
    id: 7
  },
  {
    title: 'Product Days Since Last Purchase',
    value:
      '${data.jpString("entities.user.bills[*].products[*].daysSinceLastPurchase")}',
    id: 8
  },
  {
    title: 'Product Design Code',
    value: '${data.jpString("entities.user.bills[*].products[*].designCode")}',
    id: 9
  },
  {
    title: 'Product Discount',
    value: '${data.jpString("entities.user.bills[*].products[*].discount")}',
    id: 10
  },
  {
    title: 'Product Discount Amount',
    value:
      '${data.jpString("entities.user.bills[*].products[*].discountAmount")}',
    id: 11
  },
  {
    title: 'Product Distress For Jeans',
    value:
      '${data.jpString("entities.user.bills[*].products[*].distressForJeans")}',
    id: 12
  },
  {
    title: 'Product Fabric',
    value: '${data.jpString("entities.user.bills[*].products[*].fabric")}',
    id: 13
  },
  {
    title: 'Product Group',
    value: '${data.jpString("entities.user.bills[*].products[*].group")}',
    id: 14
  },
  {
    title: 'Product Id',
    value: '${data.jpString("entities.user.bills[*].products[*].id")}',
    id: 15
  },
  {
    title: 'Product Images',
    value: '${data.jpString("entities.user.bills[*].products[*].images")}',
    id: 16
  },
  {
    title: 'Product Is Hierarchy Failed',
    value:
      '${data.jpString("entities.user.bills[*].products[*].isHierarchyFailed")}',
    id: 17
  },
  {
    title: 'Product Item Discount',
    value:
      '${data.jpString("entities.user.bills[*].products[*].itemDiscount")}',
    id: 18
  },
  {
    title: 'Product Item Id',
    value: '${data.jpString("entities.user.bills[*].products[*].itemId")}',
    id: 19
  },
  {
    title: 'Product Item Name',
    value: '${data.jpString("entities.user.bills[*].products[*].itemName")}',
    id: 20
  },
  {
    title: 'Product Item Tax',
    value: '${data.jpString("entities.user.bills[*].products[*].itemTax")}',
    id: 21
  },
  {
    title: 'Product Master Category',
    value:
      '${data.jpString("entities.user.bills[*].products[*].masterCategory")}',
    id: 22
  },
  {
    title: 'Product Mrp',
    value: '${data.jpString("entities.user.bills[*].products[*].mrp")}',
    id: 23
  },
  {
    title: 'Product Name',
    value: '${data.jpString("entities.user.bills[*].products[*].name")}',
    id: 24
  },
  {
    title: 'Product Naminrctid',
    value: '${data.jpString("entities.user.bills[*].products[*].naminrctid")}',
    id: 25
  },
  {
    title: 'Product Namivinvid',
    value: '${data.jpString("entities.user.bills[*].products[*].namivinvid")}',
    id: 26
  },
  {
    title: 'Product Offer Code',
    value: '${data.jpString("entities.user.bills[*].products[*].offerCode")}',
    id: 27
  },
  {
    title: 'Product Pocket',
    value: '${data.jpString("entities.user.bills[*].products[*].pocket")}',
    id: 28
  },
  {
    title: 'Product Price',
    value: '${data.jpString("entities.user.bills[*].products[*].price")}',
    id: 29
  },
  {
    title: 'Product Product Id',
    value: '${data.jpString("entities.user.bills[*].products[*].productId")}',
    id: 30
  },
  {
    title: 'Product Quantity',
    value: '${data.jpString("entities.user.bills[*].products[*].quantity")}',
    id: 31
  },
  {
    title: 'Product Serial No',
    value: '${data.jpString("entities.user.bills[*].products[*].serialNo")}',
    id: 32
  },
  {
    title: 'Product Size',
    value: '${data.jpString("entities.user.bills[*].products[*].size")}',
    id: 33
  },
  {
    title: 'Product Sku',
    value: '${data.jpString("entities.user.bills[*].products[*].sku")}',
    id: 34
  },
  {
    title: 'Product Sku Code',
    value: '${data.jpString("entities.user.bills[*].products[*].skuCode")}',
    id: 35
  },
  {
    title: 'Product Sleeve',
    value: '${data.jpString("entities.user.bills[*].products[*].sleeve")}',
    id: 36
  },
  {
    title: 'Product Status',
    value: '${data.jpString("entities.user.bills[*].products[*].status")}',
    id: 37
  },
  {
    title: 'Product Storage',
    value: '${data.jpString("entities.user.bills[*].products[*].storage")}',
    id: 38
  },
  {
    title: 'Product Stretchable',
    value: '${data.jpString("entities.user.bills[*].products[*].stretchable")}',
    id: 39
  },
  {
    title: 'Product Style',
    value: '${data.jpString("entities.user.bills[*].products[*].style")}',
    id: 40
  },
  {
    title: 'Product Sub Category',
    value: '${data.jpString("entities.user.bills[*].products[*].subCategory")}',
    id: 41
  },
  {
    title: 'Product Subcategory',
    value: '${data.jpString("entities.user.bills[*].products[*].subcategory")}',
    id: 42
  },
  {
    title: 'Product Total',
    value: '${data.jpString("entities.user.bills[*].products[*].total")}',
    id: 43
  },
  {
    title: 'Product Wash Type',
    value: '${data.jpString("entities.user.bills[*].products[*].washType")}',
    id: 44
  },
  {
    title: 'Contact Address',
    value: '${data.jpString("entities.user.contact.address")}',
    id: 0
  },
  {
    title: 'Contact Alternate Emails',
    value: '${data.jpString("entities.user.contact.alternateEmails")}',
    id: 1
  },
  {
    title: 'Contact Email',
    value: '${data.jpString("entities.user.contact.email")}',
    id: 2
  },
  {
    title: 'Contact Facebook',
    value: '${data.jpString("entities.user.contact.facebook")}',
    id: 3
  },
  {
    title: 'Contact Fcm Tokens',
    value: '${data.jpString("entities.user.contact.fcmTokens")}',
    id: 4
  },
  {
    title: 'Contact Mobile',
    value: '${data.jpString("entities.user.contact.mobile")}',
    id: 5
  },
  {
    title: 'Contact Name',
    value: '${data.jpString("entities.user.contact.name")}',
    id: 6
  }
];
