import { useEffect, useState } from 'react';
import { getFormTemplates } from '../../../../../services/forms.service';
import _ from 'lodash';
import CustomSelect from '../../NodeDetails/InputRow/CustomSelect';
export const FormTemplates = ({ detail, setDetail }) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    async function getFormTemplate() {
      const formResponse = await getFormTemplates();
      setOptions(
        _.map(formResponse.forms, formTemplate => {
          return formTemplate.formName;
        })
      );
    }

    getFormTemplate();
  }, []);
  const onChange = value => {
    setDetail(value);
  };

  detail.options = options;
  return CustomSelect({ detail, onSelect: onChange });
};
