import React from 'react';
import moment from 'moment';
import _, { range } from 'lodash';
import { Page, View, Text, Image, StyleSheet, Font } from '@react-pdf/renderer';
import RupeeIcon from '../../assets/peghook-rupee.png';
import Disclimer from '../../assets/disclimer.png';
import fonts from '../../assets/Fonts';
import { truncatedNumber, truncateString } from '../../../../utils/index';

Font.register(fonts);

const styles = StyleSheet.create({
  card: {
    height: '38mm',
    width: '75mm',
    border: '1 solid #000000',
    margin: '1mm 11mm',
    position: 'relative'
  },
  inlineCard: { display: 'flex', flexDirection: 'row' },
  qrPriceBlock: {
    display: 'flex',
    flexDirection: 'row'
  },
  qrcode: {
    width: '25mm',
    height: '25mm'
  },
  priceBlock: {
    display: 'flex',
    flexDirection: 'row',
    height: '20mm',
    right: 8
  },
  rupeeicon: {
    fontSize: '24.6pt',
    top: -3,
    width: '8px'
  },
  price: {
    fontFamily: 'OpenSans',
    fontWeight: 'bold',
    fontSize: '51.5pt',
    color: 'black',
    position: 'relative',
    top: -14,
    left: -1,
    paddingLeft: '3mm',
    lineHeight: 1
  },

  inclTax: {
    paddingTop: '20mm',
    fontSize: '6pt',
    width: '120px',
    textAlign: 'right'
  },
  inclTaxContainer: {
    position: 'absolute',
    right: 3,
    top: -10
  },
  productName: { fontSize: '7pt', position: 'absolute', padding: '0 1mm' },
  itemCode: {
    fontSize: '5pt',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '9mm',
    paddingLeft: '1mm'
  },
  discBlock: {
    right: '1mm',
    bottom: '0mm',
    position: 'absolute'
  },
  disclimer: {
    width: '3.75mm',
    height: '3.75mm'
  }
});
const LabelView = ({ item }) => (
  <View style={styles?.card}>
    <View style={styles?.qrPriceBlock}>
      <View style={styles?.qrcode}>
        <Image src={item.image} />
      </View>
      <View style={styles?.priceBlock}>
        <Text style={styles?.rupeeicon}>
          <Image src={RupeeIcon} />
        </Text>
        <View>
          <Text style={styles?.price}>
            {truncatedNumber(item?.product?.mop, 4)}
          </Text>
          <View style={styles.inclTaxContainer}>
            <Text style={styles?.inclTax}>*Incl. Tax</Text>
          </View>
        </View>
      </View>
    </View>
    <View>
      <Text style={styles.productName}>
        {truncateString(item?.product?.masterData?.name, 130)}
      </Text>
      <View style={styles.itemCode}>
        <Text>
          {item?.sku} | {item.class} | A |{' '}
          {moment(item?.date).format('DD.MM.YYYY')} |{' '}
          {moment().format('DD.MM.YYYY')}
        </Text>
        <View style={styles.discBlock}>
          <Image style={styles.disclimer} src={Disclimer} />
        </View>
      </View>
    </View>
  </View>
);
const getPegHookLayout = (data, rowSets) => {
  return data?.map((labelData, i) => {
    return rowSets?.map(rowCount => (
      <View style={styles.inlineCard} key={i}>
        {rowCount.map(id => (
          <LabelView item={labelData} key={id} />
        ))}
      </View>
    ));
  });
};
const PegHookFourDigit = ({ data, count, ...rest }) => {
  const labelsPerRow = 3;
  const rowsPerSku = count.length / labelsPerRow;
  const rowSets = range(rowsPerSku).map(ind =>
    count.slice(labelsPerRow * ind, labelsPerRow * (ind + 1))
  );
  const splattedArray = _.chunk(data, 10);
  return (
    <Page size="A4" orientation="landscape" style={{ padding: '5mm' }}>
      <View>
        {count &&
          _.map(splattedArray, (chunkedData, key) => {
            const chunkedRowArray = _.chunk(chunkedData, 5);
            const firstHalf = getPegHookLayout(chunkedRowArray[0], rowSets);
            const secondHalf = getPegHookLayout(chunkedRowArray[1], rowSets);
            return (
              <View
                style={{ flexDirection: 'row', flexWrap: 'wrap' }}
                key={key}
              >
                <View>{firstHalf}</View>
                <View>{secondHalf}</View>
              </View>
            );
          })}
      </View>
    </Page>
  );
};
export { PegHookFourDigit };
