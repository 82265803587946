import React, { Context } from 'react';
import { noop } from 'lodash';

type FlipbookPagesContext = {
  updateFlipbookPages: () => void;
};

export const FlipbookPagesContext: Context<FlipbookPagesContext> = React.createContext<
  FlipbookPagesContext
>({
  updateFlipbookPages: noop
});
