import { Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import CampaignReport, {
  CampaignReportResponse
} from '../../../../models/campaign/CampaignReport';
import { getHistory } from '../../../../services/Campaign/campaign-report.service';
import usePagination from '../../../../shared/hooks/usePagination';
import CampaignReportHeader from './CampaignReportHeader/CampaignReportHeader';
import CampaignReportTable from './CampaignReportTable/CampaignReportTable';
import { CampaignReportType } from '../../shared/utils/contants';
import { get, isEmpty, isNumber, isUndefined, map } from 'lodash';
import qs from 'query-string';
import Loader from '../../shared/components/Loader/Loader';
import useFetch from '../../../../shared/hooks/useFetch';
import { getCampaignDefinition } from '../../../../services/Campaign/campaign-details.service';
import useCurrentUser from '../../../../hooks/useCurrentUser';

const CampaignReports: FC = () => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const url = new URL(window.location.href);
  const querySearchParams = qs.parse(url.searchParams.toString());
  const { data: campaign } = useFetch(() => getCampaignDefinition(campaignId));
  const user = useCurrentUser();
  const availableOptions = () => {
    if (!get(campaign, 'trackConversion', true)) {
      return [CampaignReportType.VARIANT, CampaignReportType.DEFAULT];
    }
    return Object.values(CampaignReportType);
  };
  const getReportCost = (searchParams: any) => {
    let cost: any = get(querySearchParams, 'roiCost', 0);
    cost = parseFloat(cost);
    if (isNaN(cost)) {
      return 0;
    }
    return cost;
  };
  const getReportType = (searchParams: any) => {
    const reportType = get(searchParams, 'reportType');
    if (availableOptions().includes(reportType)) {
      return reportType;
    }
    return CampaignReportType.DEFAULT;
  };
  const getDefaultBuId = () => {
    if (user.isLoggedIn) {
      let id = user.getDefaultBusinessId();
      const buIds = map(user.getBusinesses(), bu => bu.id);
      const buId = parseInt(get(querySearchParams, 'bu', -1) as any);
      if (isNumber(buId) && buIds.includes(buId)) {
        id = buId;
      }
      return String(id);
    }
    return '';
  };
  const campaignReportType = getReportType(querySearchParams);
  const roiCost = getReportCost(querySearchParams);
  const defaultBuId = getDefaultBuId();
  const [reportType, setReportType] = useState<any>();
  useEffect(() => {
    if (isEmpty(campaign)) {
      return;
    }
    if (isUndefined(reportType)) {
      setReportType(campaignReportType);
    }
  }, [campaignReportType, reportType, campaign]);
  const {
    data,
    isLoading,
    error,
    page,
    offset,
    setPage,
    setOffset,
    refetch
  } = usePagination<CampaignReportResponse<CampaignReport>>(
    ({ page, offset, filters }) =>
      getHistory({ campaignId, page, offset }, filters),
    [reportType],
    reportType,
    isUndefined(reportType)
  );

  const { rows: reports, count }: CampaignReportResponse<CampaignReport> =
    data || ({} as any);

  if (error)
    return (
      <Alert severity="error" style={{ margin: '2rem' }}>
        {error}
      </Alert>
    );
  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container maxWidth="lg" style={{ marginBlock: '2rem' }}>
      <CampaignReportHeader
        {...(reports?.[0] || { data: {} })?.data}
        isFetching={isLoading}
        page={page}
        reportType={reportType}
        campaignId={campaignId}
        campaign={campaign}
        setReportType={setReportType}
        roiCost={roiCost}
        refetch={refetch}
        defaultBusinessId={defaultBuId}
        goToFirstPage={setPage.bind(null, 0)}
      />
      <CampaignReportTable
        reports={reports}
        isLoading={isLoading}
        page={page}
        rowsPerPage={offset}
        count={count}
        setPage={setPage}
        setRowsPerPage={setOffset}
      />
    </Container>
  );
};
export default CampaignReports;
