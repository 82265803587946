/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { useTable } from 'react-table';

import {
  getUserRoles,
  updateSwitchToggle
} from '../../services/roleAssign.service';
import SettingsBreadCrumbs from '../SettingsBreadCrumbs/SettingsBreadCrumbs';
import ExportActionButton from './Component/ExportActionButton';
import Popup from './Component/Popup';
import RoleActionButtons from './Component/RoleActionButtons';
import SelectCategory from './Component/SelectCategory';
import { manualHeaderId, totalColumns } from './constant';
import RoleAssignStyles from './RoleAssign.module.scss';
import RoleAssignTable from './Table/RoleAssignTable';
import { useDispatch } from 'react-redux';
import { toastMessageAction } from '../../store/action';

function RoleAssign() {
  const [showPopUp, setShowPopUp] = React.useState(false);
  const [csvData, setCsvData] = React.useState([]);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState({
    moduleList: [],
    currentModule: ''
  });
  const [featureAccess, setFeatureAccess] = React.useState({
    saveButtonAccess: true,
    otherAccess: false
  });
  const [roleHeaderName, setRoleHeaderName] = React.useState({
    roleName: []
  });
  const [featureListItem, setFeatureListItem] = React.useState({ feature: [] });
  const [permissionData, setPermissionData] = React.useState({
    permissionDataList: []
  });

  const [emptyList, setEmptyList] = React.useState({ emptyListData: [] });
  const [newRole, setNewRole] = React.useState({
    role: '',
    error: ''
  });

  const makeSort = data => {
    data.sort((a, b) => {
      return a.id - b.id;
    });

    return data;
  };

  const updateHeader = data => {
    const roleName = data.map(value => {
      return { id: value.id, name: value.name };
    });
    const sortedRoleName = makeSort([...roleName]);
    const headerItem = [
      { id: manualHeaderId, Header: 'Feature Roles', accessor: 'feature' },
      ...sortedRoleName
    ];

    setRoleHeaderName({
      roleName: headerItem
    });
  };

  const updatePermissionData = res => {
    const permissionList = res.map(value => {
      return {
        id: value.id,
        roleName: value.name,
        permissions: value.permissions
      };
    });

    const clonedPermissionList = JSON.parse(JSON.stringify(permissionList));

    setPermissionData({
      permissionDataList: clonedPermissionList
    });
  };

  useBeforeunload(event => {
    if (!featureAccess.saveButtonAccess) {
      event.preventDefault();
    }
  });

  useEffect(() => {
    getUserRoles().then(res => {
      // moduleList array contains the list of module list for the select component
      const moduleList = Object.keys(res[0].permissions);
      // const currentModule = moduleList[0];
      const sortedRes = makeSort([...res]);
      updateHeader(res);
      updatePermissionData(sortedRes);
      setValue({
        moduleList: [...moduleList],
        currentModule: moduleList[0]
      });
    });
  }, []);

  useEffect(() => {
    if (value.currentModule) {
      const columns =
        permissionData.permissionDataList[0].permissions[value.currentModule];
      const enabledFeatureColumn = columns.enabled.map(value => {
        return { feature: value };
      });

      const disabledfeaturedColumn = columns.disabled.map(value => {
        return { feature: value };
      });

      const feature = [...enabledFeatureColumn, ...disabledfeaturedColumn];

      // condition to render the empty cell in the table
      if (roleHeaderName.roleName.length < totalColumns) {
        const empty = [];
        for (let i = roleHeaderName.roleName.length; i < totalColumns; i++) {
          empty.push({ Header: 'empty' });
        }

        setEmptyList({
          emptyListData: [...empty]
        });
      }

      setFeatureListItem({
        feature: [...feature]
      });
    }
  }, [value.currentModule]);

  const csvLink = useRef();

  const columns = useMemo(() => roleHeaderName.roleName, [
    roleHeaderName.roleName
  ]);
  const data = useMemo(() => featureListItem.feature, [
    featureListItem.feature
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  });

  const visiblePopUp = () => {
    setShowPopUp(true);
  };

  const hidePopUp = () => {
    setShowPopUp(false);
  };

  // function to download csv file
  const exportCsv = async () => {
    const sortedRes = makeSort([...permissionData.permissionDataList]);
    const name = sortedRes.map(value => {
      return value.roleName;
    });

    const csvHeader = ['Feature / Role', ...name];
    const csvBody = featureListItem.feature.map(featureName => {
      const status = name.map((role, index) => {
        return sortedRes[index].permissions[
          value.currentModule
        ].enabled.includes(featureName.feature)
          ? 'Active'
          : 'Inactive';
      });

      return [featureName.feature, ...status];
    });

    const data = [...[csvHeader], ...csvBody];
    await setCsvData(data);
    csvLink.current.link.click();
  };

  const changeCurrentModule = e => {
    setValue({
      ...value,
      currentModule: e.target.value
    });
  };

  const changeSwitchState = (role, feature) => {
    const clonedData = permissionData.permissionDataList.map(value => {
      return value;
    });

    const userIndex = clonedData.findIndex(value => {
      return value.roleName === role;
    });

    const permissionList =
      clonedData[userIndex].permissions[value.currentModule];
    const switchState = permissionList.enabled.includes(feature);
    const deleteFeature = switchState ? 'enabled' : 'disabled';
    const addFeature = switchState ? 'disabled' : 'enabled';
    const index = permissionList[deleteFeature].findIndex(value => {
      return value === feature;
    });
    permissionList[deleteFeature].splice(index, 1);
    permissionList[addFeature].push(feature);

    setFeatureAccess({
      saveButtonAccess: false,
      otherAccess: true
    });

    setPermissionData({
      permissionDataList: [...clonedData]
    });
  };

  const handleChange = newRoleName => {
    setNewRole({
      error: '',
      role: newRoleName
    });
  };

  const validation = () => {
    const newRoleName = newRole.role;

    if (newRoleName === '') {
      setNewRole({
        role: '',
        error: 'Role Name cannot be empty'
      });
      return false;
    }

    const checkRole = roleHeaderName.roleName.some(value => {
      return value.name === newRoleName && value.id !== manualHeaderId;
    });

    if (checkRole) {
      setNewRole({
        role: '',
        error: 'Role Name is already available'
      });
      return false;
    }

    return true;
  };

  const savePermissions = () => {
    updateSwitchToggle(permissionData.permissionDataList)
      .then(() => {
        getUserRoles().then(res => {
          const sortedRes = makeSort([...res]);
          updatePermissionData(sortedRes);
          setFeatureAccess({
            saveButtonAccess: true,
            otherAccess: false
          });
          dispatch(
            toastMessageAction({
              severity: 'success',
              message: 'Permission Saved',
              messageStatus: true
            })
          );
        });
      })
      .catch(() => {
        dispatch(
          toastMessageAction({
            severity: 'error',
            message: 'Something went wrong',
            messageStatus: true
          })
        );
      });
  };

  const settingPermissionListValue = permissionList => {
    const permissions = permissionList[0].permissions;

    value.moduleList.forEach(value => {
      permissions[value].disabled = [
        ...permissions[value].enabled,
        ...permissions[value].disabled
      ];
      permissions[value].enabled = [];
    });

    return [{ roleName: newRole.role, permissions: permissions }];
  };

  const createNewRole = () => {
    const isValid = validation();
    if (isValid) {
      const permissionListValue = settingPermissionListValue(
        permissionData.permissionDataList
      );

      updateSwitchToggle(permissionListValue).then(() => {
        getUserRoles().then(res => {
          const sortedRes = makeSort([...res]);
          updatePermissionData(sortedRes);
          hidePopUp();
          updateHeader(sortedRes);
        });
      });
    }
  };

  return (
    <>
      <SettingsBreadCrumbs
        currentModuleName="Role Management"
        fromModuleName="General Settings"
      />

      <div className={RoleAssignStyles.mainContainer}>
        <div className={RoleAssignStyles.roleActionButtonContainer}>
          <RoleActionButtons visiblePopUp={visiblePopUp} />
        </div>
        <div className={RoleAssignStyles.roleAssignTableContainer}>
          <div className={RoleAssignStyles.roleAssignTableButton}>
            <SelectCategory
              type="subCategory"
              items={value.moduleList}
              changeCurrentModule={changeCurrentModule}
              selectedValue={value.currentModule}
              access={featureAccess.otherAccess}
            />
            <div style={{ display: 'flex' }}>
              <ExportActionButton
                featureAccess={featureAccess}
                savePermissions={savePermissions}
                exportCsv={exportCsv}
                csvData={csvData}
                csvLink={csvLink}
                value={value}
              />
            </div>
          </div>
          <div className={RoleAssignStyles.tableContainerOverflow}>
            <RoleAssignTable
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              rows={rows}
              prepareRow={prepareRow}
              emptyList={emptyList}
              permissionData={permissionData}
              value={value}
              changeSwitchState={changeSwitchState}
            />
          </div>
        </div>
      </div>

      {showPopUp && (
        <Popup
          hidePopUp={hidePopUp}
          popUp={showPopUp}
          currentModule={value.currentModule}
          data={newRole.role}
          error={newRole.error}
          handleChange={handleChange}
          createNewRole={createNewRole}
        />
      )}
    </>
  );
}

export default RoleAssign;
