export const customerGroupContents = {
  title: 'Create Customer Group',
  groupName: 'Customer Group Name',
  groupDescription: 'Group Description (Optional)',
  addBulk: 'Add Customer in Bulk',
  groupConfiguration: 'Group Configuration',
  csvTitle: 'CSV Template',
  submitButtonTitle: 'Add Customer Group',
  typoGraphyVariant: 'h6',
  noGroup: 'No Group are Created',
  customerTitle: 'Add Customer Manually',
  tagConfigurationTitle: 'Tag Configuration',
  customerBulkTitle: 'Add Customer in Bulk'
};
export const csvTemplateHeaders = [
  { key: 'mobileNumber', label: 'mobileNumber' }
];

export const keyMapping = {
  'Mobile Number': 'mobileNumber'
};

export const initialValues = {
  name: '',
  description: '',
  blockLoyalty: false,
  blockSms: false
};

export const formGroupCustomer = {
  name: ''
};
