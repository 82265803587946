import { TextField, MenuItem } from '@material-ui/core';
import React from 'react';
import { styled } from '@material-ui/styles';

const StyledAppTextField = styled(TextField)({
  '& .MuiInputLabel-outlined': {
    color: '#A3A3A3 !important',
    marginTop: '-3px !important'
  },
  '& .MuiFormLabel-filled': {
    color: '#f3755f !important',
    marginTop: '-3px !important'
  },
  '& .MuiOutlinedInput-input': {
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 100px #fff inset',
      WebkitTextFillColor: '#A3A3A3'
    },
    color: '#A3A3A3'
  },
  '& .MuiInputBase-input': {
    padding: '15.5px 14px'
  }
});

const DropDownField = props => {
  const {
    label,
    inputValues,
    name,
    onChange,
    value,
    className,
    error,
    style,
    onBlur,
    setFieldValue
  } = props;

  return (
    <>
      <StyledAppTextField
        select
        value={value}
        fullWidth
        label={label}
        onChange={onChange}
        variant={'outlined'}
        name={name}
        setFieldValue={setFieldValue}
        error={error}
        helperText={error}
        className={className}
        style={style}
        onBlur={onBlur}
      >
        {inputValues.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </StyledAppTextField>
    </>
  );
};
export default DropDownField;
