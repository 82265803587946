import React, { FC } from 'react';
import LayoutTile from '../../../PageGenerators/LayoutTile';
import { get, isNumber } from "lodash";
import { loaderElement } from '../../Utiles/FlipbookPagesUtiles';
import {
  FlipbookLayout,
  PageType
} from '../../../../../models/FlipbookV2/FlipbookV2Layout.model';
import { TileType } from '../../../../../models/FlipbookV2/FlipbookV2LayoutTile.model';

interface SingleLayoutProps {
  editPanelOccupiedKey: string;
  setEditPanelOccupiedKey: (key: string) => void;
  page: FlipbookLayout;
  isEditMode: boolean;
  handleModalOpen?: any;
  isCustomerView: boolean;
  hasPermission: (permission: string) => boolean;
}

const SingleLayout: FC<SingleLayoutProps> = ({
  editPanelOccupiedKey,
  setEditPanelOccupiedKey,
  page,
  isEditMode,
  handleModalOpen,
  isCustomerView,
  hasPermission
}) => {
  const tileType = get(page, 'flipbookLayoutTile.0.tileType', TileType.TileOne);
  return (
    <div className={'height-100-per'} style={{ position: 'relative' }}>
      {isEditMode && page.pageType === PageType.MAIN && (
        <div className={'change-layout-container'}>
          <p
            onClick={() => handleModalOpen()}
            className={
              'm-0 change-layout-text casa-color flipbook-text-header p-1'
            }
          >
            Change Layout
          </p>
        </div>
      )}
      {
        <div className={'height-100-per'}>
          {!isNumber(page.id) ? (
            loaderElement()
          ) : (
            <LayoutTile
              editPanelOccupiedKey={editPanelOccupiedKey}
              setEditPanelOccupiedKey={setEditPanelOccupiedKey}
              layoutId={page.id}
              page={page}
              flipbookTileType={tileType}
              isEditMode={isEditMode}
              recommendedDimensions={
                'Image Dimension 466w x 656h (Max size 1mb)'
              }
              isCustomerView={isCustomerView}
              hasPermission={hasPermission}
            />
          )}
        </div>
      }
    </div>
  );
};

export default SingleLayout;
