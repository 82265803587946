import React, { FC, useEffect, useState } from 'react';
import flipbookV2Service from '../../services/Flipbook/flipbookV2.service';
import { TextField } from '@material-ui/core';
import useCurrentUser from '../../hooks/useCurrentUser';
import _ from 'lodash';
import { getFlipbookConfiguration } from './Utiles/FlipbookConfig';
import {
  CheckoutOption,
  Flipbook,
  Themes
} from '../../models/FlipbookV2/flipbookV2.model';
import { Business } from '../../models/User.model';

const defaultFlipbookData = {
  pageWidth: 468,
  pageHeight: 657,
  pageDisplay: 'double',
  pageGenerators: [
    {
      id: 1,
      type: 'tiles',
      bannerImageURL: '',
      groupedProducts: []
    }
  ],
  backgroundImageURL: {
    odd: '',
    even: ''
  },
  isRedirectToEcom: false,
  isDarkTheme: false,
  storeName: '',
  isUserStoreSelectionActive: false
};

const defaultSettingsConfig = {
  isEmptyPagesHided: true,
  checkoutOption: CheckoutOption.FlipbookCheckout,
  theme: Themes.LIGHT,
  tenantBrandBusinessId: 0
};
export const FlipbookCreate: FC<{
  redirectToEdit: (flipbookId: number) => void;
}> = ({ redirectToEdit }) => {
  const [shortCode, setShortCode] = useState<string>('');
  const [isFlipbookCreationActive, setIsFlipbookCreationActive] = useState(
    false
  );
  const [description, setDescription] = useState<string>('');
  const user = useCurrentUser();
  const flipbookUserSpecificConfig = getFlipbookConfiguration(user);
  const stores = _.get(user, 'tenant_stores', []);
  const brandBusiness =
    _(user.getBusinesses())
      .orderBy(['id'])
      .first() || new Business();

  useEffect(() => {
    defaultSettingsConfig.checkoutOption =
      flipbookUserSpecificConfig.defaultCheckout;
  }, [description, stores, flipbookUserSpecificConfig]);

  const createFlipbook = () => {
    const flipbook: Flipbook = new Flipbook(
      defaultSettingsConfig.isEmptyPagesHided,
      defaultSettingsConfig.checkoutOption,
      description,
      defaultSettingsConfig.theme,
      shortCode,
      description,
      defaultFlipbookData,
      '',
      '',
      false,
      true,
      [],
      _.get(user, 'tenantId', 0),
      null,
      brandBusiness.id,
      ''
    );

    return flipbookV2Service.createFlipbook(flipbook).then((res: any) => {
      setIsFlipbookCreationActive(false);
      if (res.status === 'success') {
        redirectToEdit(res.data.id);
      } else {
        console.log('error message');
      }
    });
  };

  const onClickCreateFlipbook = () => {
    setIsFlipbookCreationActive(true);
    createFlipbook();
  };
  return (
    <div className={'flipbook-create-section'}>
      <div className="flipbookCreateContainer">
        <h3 className="flipbookCreateTitle">Create New Flipbook</h3>
        <div className="flipbookCreateContenat">
          <div className="mb-5">
            <div className="mb-4">
              <div className="flipbookTextFieldLabel">{'Name'}</div>
              <TextField
                fullWidth
                value={description}
                onChange={event => setDescription(event.target.value)}
              />
            </div>
            <div className="mb-4">
              <div className="flipbookTextFieldLabel">{'Short Code'}</div>
              <TextField
                fullWidth
                value={shortCode}
                onChange={event => setShortCode(event.target.value)}
              />
            </div>
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <div
              onClick={onClickCreateFlipbook}
              className={
                isFlipbookCreationActive
                  ? 'flpbk-action-btn flpbk-action-btn-inprogress mx-4'
                  : 'flpbk-action-btn mx-4'
              }
            >
              Create
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlipbookCreate;
