/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import _, { Dictionary } from 'lodash';

import fieldFormatters from './FieldFormatters';

const ComputedFields = {
  TOTAL: 'total',
  DISCOUNT: 'discount',
  DISCOUNT_USING_MEMO_LP: 'discountUsingMemoLp',
  TAX: 'tax',
  TOTAL_WITHOUT_DISCOUNT: 'totalWithoutDiscount',
  TOTAL_WITHOUT_TAX: 'totalWithoutTax',
  TOTAL_ORDER_VALUE: 'totalOrderValue',
  PRODUCT_TAX: 'productTax',
  PRODUCT_CGST: 'productCGST',
  PRODUCT_SGST: 'productSGST',
  PRODUCT_IGST: 'productIGST',
  PRODUCT_CESS: 'productCESS',
  PRODUCT_DISCOUNT: 'productDiscount',
  GST_PERCENT: 'GSTPercent',
  PRODUCT_TAXABLE_AMT: 'productTaxableAmt'
};

const canShowInHSNFinalRow = {
  [ComputedFields.PRODUCT_TAX]: true,
  [ComputedFields.PRODUCT_CESS]: true,
  [ComputedFields.PRODUCT_IGST]: true,
  [ComputedFields.PRODUCT_CGST]: true,
  [ComputedFields.PRODUCT_SGST]: true
};

function getProductsFromBill(bill: any) {
  return _.get(bill, 'products', []);
}

function getAndParse(data: any, key: string, defaultVal = 0): number {
  const val = _.get(data, key, defaultVal);
  if (!val) return defaultVal;
  return parseFloat(val);
}

function convertToCurrency(num: number): string {
  const roundedNum = _.round(num, 4);
  return fieldFormatters.formatCurrency(roundedNum.toString());
}

function absolute(num: number): number {
  const positiveNum = Math.abs(num);
  return positiveNum;
}

function convertToPercentage(num: number): string {
  return `${num}%`;
}

function mapAndSum(iterable: any[], mapper: (product: any) => number): number {
  const mapResult = _.map(iterable, mapper);
  const sum = _.sum(mapResult);
  return _.round(sum, 4);
}

function getProductLevelTotal(product: any): number {
  const pricePerQty = getAndParse(product, 'price');
  const qty = getAndParse(product, 'quantity', 1);
  return pricePerQty * absolute(qty);
}

function getSubTotal(bill: any): number {
  const products = getProductsFromBill(bill);
  return mapAndSum(products, getProductLevelTotal);
}

function getProductLevelTotalDiscount(product: any): number {
  const productDiscount = getAndParse(product, 'productDiscount', 0);
  const billDiscount = getAndParse(product, 'billDiscount', 0);
  const loyaltyDiscount = getAndParse(product, 'loyaltyDiscount', 0);
  return productDiscount + billDiscount + loyaltyDiscount;
}

function getBillLevelTotalDiscount(bill: any): number {
  const products = getProductsFromBill(bill);
  return absolute(mapAndSum(products, getProductLevelTotalDiscount));
}

function getProductLevelTotalDiscountUsingMemoLp(product: any): number {
  const memoDiscountAmount = getAndParse(product, 'memoDiscountAmount', 0);
  const lpDiscountAmount = getAndParse(product, 'lpDiscountAmount', 0);
  return memoDiscountAmount + lpDiscountAmount;
}

function getBillLevelTotalDiscountUsingMemoLp(bill: any): number {
  const products = getProductsFromBill(bill);
  return mapAndSum(products, getProductLevelTotalDiscountUsingMemoLp);
}

function getProductLevelCGSTAmount(product: any) {
  return getAndParse(product, 'CGSTAmt', getAndParse(product, 'cgstAmount'));
}

function getProductLevelIGSTAmount(product: any) {
  return getAndParse(product, 'IGSTAmt', getAndParse(product, 'igstAmount'));
}

function getProductLevelSGSTAmount(product: any) {
  return getAndParse(product, 'SGSTAmt', getAndParse(product, 'sgstAmount'));
}

function getProductLevelCESSAmount(product: any) {
  return getAndParse(product, 'CESSAmt', getAndParse(product, 'cessAmount'));
}

function getProductLevelTotalTax(product: any): number {
  const CESSAmt = getProductLevelCESSAmount(product);
  const SGSTAmt = getProductLevelSGSTAmount(product);
  const IGSTAmt = getProductLevelIGSTAmount(product);
  const CGSTAmt = getProductLevelCGSTAmount(product);
  return CESSAmt + SGSTAmt + IGSTAmt + CGSTAmt;
}

function getBillLevelTotalTax(bill: any): number {
  const products = getProductsFromBill(bill);
  return absolute(mapAndSum(products, getProductLevelTotalTax));
}

function getProductLevelTaxableAmount(product: any): number {
  return getProductLevelTotal(product) - getProductLevelTotalTax(product);
}

function getTotalPriceWithoutDiscount(bill: any): number {
  return (
    absolute(getSubTotal(bill)) - absolute(getBillLevelTotalDiscount(bill))
  );
}

function getTotalPriceBeforeTax(bill: any): number {
  return absolute(getSubTotal(bill)) - getBillLevelTotalTax(bill);
}

function getTotalOrderValue(bill: any): number {
  return absolute(
    getAndParse(
      bill,
      'billNetAmount',
      getAndParse(bill, 'totalAmount', getTotalPriceBeforeTax(bill))
    )
  );
}

function groupByHSN(bill: any): Dictionary<any[]> {
  const products = getProductsFromBill(bill);
  const products_having_hsn = _.filter(products, product => product[HSN_KEY]);
  return _.groupBy(products_having_hsn, HSN_KEY);
}

function getCESSAmountByHSN(products: any): number {
  return absolute(mapAndSum(products, getProductLevelCESSAmount));
}

function getCGSTAmountByHSN(products: any): number {
  return absolute(mapAndSum(products, getProductLevelCGSTAmount));
}

function getIGSTAmountByHSN(products: any): number {
  return absolute(mapAndSum(products, getProductLevelIGSTAmount));
}

function getSGSTAmountByHSN(products: any): number {
  return absolute(mapAndSum(products, getProductLevelSGSTAmount));
}

function getTaxByHSN(products: any): number {
  return absolute(mapAndSum(products, getProductLevelTotalTax));
}

function getProductLevelGSTPercent(product: any): number {
  const CGSTRate = getAndParse(product, 'CGSTRate');
  const SGSTRate = getAndParse(product, 'SGSTRate');
  return absolute(CGSTRate + SGSTRate);
}

function getGSTPercentage(products: any): number {
  return absolute(getProductLevelGSTPercent(_.head(products)));
}

function formatValueBasedOnbillType(bill: any, value: number): number {
  const invoiceType = getInvoiceType(bill);
  if (invoiceType === 'SR') {
    return value * -1;
  }
  return value;
}

function getInvoiceType(bill: any): string {
  return _.get(bill, 'invoiceType', 'IN');
}

const computedFieldGetterMap: { [key: string]: (data: any) => string } = {
  [ComputedFields.TOTAL]: bill => convertToCurrency(getSubTotal(bill)),
  [ComputedFields.DISCOUNT]: bill =>
    convertToCurrency(getBillLevelTotalDiscount(bill)),
  [ComputedFields.DISCOUNT_USING_MEMO_LP]: bill =>
    convertToCurrency(getBillLevelTotalDiscountUsingMemoLp(bill)),
  [ComputedFields.TAX]: bill =>
    convertToCurrency(
      formatValueBasedOnbillType(bill, getBillLevelTotalTax(bill))
    ),
  [ComputedFields.TOTAL_WITHOUT_DISCOUNT]: bill =>
    convertToCurrency(getTotalPriceWithoutDiscount(bill)),
  [ComputedFields.TOTAL_WITHOUT_TAX]: bill =>
    convertToCurrency(
      formatValueBasedOnbillType(bill, getTotalPriceBeforeTax(bill))
    ),
  [ComputedFields.TOTAL_ORDER_VALUE]: bill =>
    convertToCurrency(
      formatValueBasedOnbillType(bill, getTotalOrderValue(bill))
    ),
  [ComputedFields.PRODUCT_DISCOUNT]: product =>
    convertToCurrency(absolute(getProductLevelTotalDiscount(product))),
  [ComputedFields.PRODUCT_CESS]: product =>
    convertToCurrency(getProductLevelCESSAmount(product)),
  [ComputedFields.PRODUCT_CGST]: product =>
    convertToCurrency(getProductLevelCGSTAmount(product)),
  [ComputedFields.PRODUCT_SGST]: product =>
    convertToCurrency(getProductLevelSGSTAmount(product)),
  [ComputedFields.PRODUCT_IGST]: product =>
    convertToCurrency(getProductLevelIGSTAmount(product)),
  [ComputedFields.PRODUCT_TAX]: product =>
    convertToCurrency(getProductLevelTotalTax(product)),
  [ComputedFields.PRODUCT_TAXABLE_AMT]: product =>
    convertToCurrency(getProductLevelTaxableAmount(product))
};

const hsnFieldGetterMap: { [key: string]: (data: any) => string } = {
  [ComputedFields.PRODUCT_CESS]: products =>
    convertToCurrency(getCESSAmountByHSN(products)),
  [ComputedFields.PRODUCT_CGST]: products =>
    convertToCurrency(getCGSTAmountByHSN(products)),
  [ComputedFields.PRODUCT_SGST]: products =>
    convertToCurrency(getSGSTAmountByHSN(products)),
  [ComputedFields.PRODUCT_IGST]: products =>
    convertToCurrency(getIGSTAmountByHSN(products)),
  [ComputedFields.PRODUCT_TAX]: products =>
    convertToCurrency(getTaxByHSN(products)),
  [ComputedFields.GST_PERCENT]: products =>
    convertToPercentage(getGSTPercentage(products))
};

const HSN_KEY = 'hsnSacCode';

export default {
  computedFieldGetterMap,
  groupByHSN,
  hsnFieldGetterMap,
  HSN_KEY,
  canShowInHSNFinalRow
};
