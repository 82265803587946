import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Dialog, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  dialogTitle: {
    textAlign: 'center'
  },
  dialogHintParagraph: {
    color: '#A8A8A8',
    marginBottom: 0
  },
  selectBar: {
    background: '#FFF',
    minHeight: 50,
    paddingTop: 0,
    paddingBottom: 0
  },
  dialogPaper: {
    padding: '50px 25px',
    alignItems: 'center',
    height: '100%',
    maxHeight: '500px',
    justifyContent: 'space-between'
  },
  closeButton: {
    position: 'absolute',
    top: '8px',
    right: '8px'
  }
});

export const RemoveTagDialog = props => {
  const {
    open,
    onClose,
    numSelectedOffers,
    availableTags,
    selectedTags,
    onSelectedTagsChange,
    onSubmit
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper
      }}
      fullWidth
      open={open}
      onClose={onClose}
    >
      <div className={classes.dialogTitle}>
        <h4>Remove Tags</h4>
        <p className={classes.dialogHintParagraph}>
          Tags will be removed from the {numSelectedOffers} selected offer
          {numSelectedOffers > 1 ? 's' : ''}
        </p>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div style={{ width: '75%' }}>
        <FormControl fullWidth>
          <Autocomplete
            classes={{
              root: classes.root,
              inputRoot: classes.selectBar
            }}
            multiple
            disableCloseOnSelect
            renderInput={params => (
              <TextField
                {...params}
                variant={'outlined'}
                placeholder={'Select tags'}
                size={'small'}
                fullWidth
              />
            )}
            filterSelectedOptions
            options={availableTags}
            getOptionLabel={option => option.name || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            value={selectedTags}
            onChange={onSelectedTagsChange}
            limitTags={2}
          />
        </FormControl>
      </div>
      <Button
        color={'primary'}
        variant={'contained'}
        onClick={onSubmit}
        disabled={_.isEmpty(selectedTags)}
      >
        Submit
      </Button>
    </Dialog>
  );
};

RemoveTagDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  numSelectedOffers: PropTypes.number.isRequired,
  availableTags: PropTypes.array.isRequired,
  selectedTags: PropTypes.array.isRequired,
  onSelectedTagsChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};
