import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import { CSVLink } from 'react-csv';
import moment from 'moment';

export const ExportOffersButton = props => {
  const { offers } = props;

  const csvLink = useRef(null);

  const headers = [
    { key: 'code', label: 'Offer Code' },
    { key: 'name', label: 'Offer Name' },
    { key: 'description', label: 'Description' },
    { key: 'startDate', label: 'Start Date' },
    { key: 'endDate', label: 'End Date' },
    { key: 'tags', label: 'Mapped Tags' },
    { key: 'stores', label: 'Stores' }
  ];

  const data = _.map(offers, o => ({
    code: o.code,
    name: o.name,
    description: o.description,
    startDate: o.startDate,
    endDate: o.endDate,
    tags: _.map(o.tags, t => t.name).toString(),
    stores: o.stores.toString()
  }));

  const handleClick = () => {
    csvLink.current.link.click();
  };

  const timestamp = moment().format('YYYY_MM_DD_HHmm');

  return (
    <div>
      <Button
        disabled={offers.length === 0}
        variant={'contained'}
        onClick={handleClick}
        color={'primary'}
      >
        Export CSV
      </Button>
      <CSVLink
        data={data}
        headers={headers}
        filename={`OffersExport_${timestamp}.csv`}
        style={{ display: 'none' }}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </div>
  );
};

ExportOffersButton.propTypes = {
  offers: PropTypes.array.isRequired
};
