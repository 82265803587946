import React from 'react';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import './Maps.css';

const mapStyle = {
  width: '100%',
  height: '50%',
  position: 'relative',
  border: '2px solid #DEDEDE'
};

function MapContainer(props) {
  const {
    isDirectionMapEnabled,
    storeGeolocation,
    currentGeolocation,
    googleMapsApiKey
  } = props;
  const { currentLatitude, currentLongitude } = currentGeolocation;
  const { storeLatitude, storeLongitude } = storeGeolocation;
  return (
    <div className={'height-100-per'}>
      {isDirectionMapEnabled ? (
        <div className={'map-container'}>
          <iframe
            title={'google maps'}
            src={`https://www.google.com/maps/embed/v1/directions?key=${googleMapsApiKey}&origin=${currentLatitude},${currentLongitude}&destination=${storeLatitude},${storeLongitude}&avoid=tolls|highways&mode=driving`}
            height="100%"
            width="100%"
            frameBorder="o"
            className={'map-container-border'}
          />
        </div>
      ) : (
        <div>
          <Map
            google={props.google}
            zoom={8}
            style={mapStyle}
            className={'map map-container'}
            initialCenter={{ lat: storeLatitude, lng: storeLongitude }}
            center={{ lat: storeLatitude, lng: storeLongitude }}
          >
            <Marker
              name={''}
              position={{ lat: storeLatitude, lng: storeLongitude }}
            />
          </Map>
        </div>
      )}
    </div>
  );
}

export default GoogleApiWrapper(props => ({
  apiKey: props.googleMapsApiKey
}))(MapContainer);
