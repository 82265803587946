import React from 'react';
import { styled } from '@material-ui/styles';
import Switch from '@material-ui/core/Switch';

const IOSSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  height: 23,
  width: 37,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor:
          theme.palette.mode === 'dark' ? '#2ECA45' : '#F2755FD9',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    marginTop: '1px',
    boxSizing: 'border-box',
    height: 17,
    width: 16
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#E1E0DF',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

function TableSwitch(props) {
  const {
    role,
    feature,
    currentData,
    changeSwitchState,
    currentModule
  } = props;

  const checkSwitchStatus = (role, feature) => {
    const index = currentData.findIndex(value => {
      return value.roleName === role;
    });

    return currentData[index].permissions[currentModule].enabled.includes(
      feature
    );
  };

  return (
    <IOSSwitch
      sx={{ m: 1 }}
      checked={checkSwitchStatus(role, feature)}
      onChange={() => {
        changeSwitchState(role, feature);
      }}
    />
  );
}

export default TableSwitch;
