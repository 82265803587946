import React, { FC, useEffect } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  TextField
} from '@material-ui/core';
import cancelIcon from '../../Assets/images/icons/cancel_icon.svg';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReactPlayer from 'react-player';
import CircularProgress from '@material-ui/core/CircularProgress';
import CancelIcon from '@material-ui/icons/Cancel';
import { FlipbookLayoutTile } from '../../../models/FlipbookV2/FlipbookV2LayoutTile.model';

interface VideoUploadModalProps {
  isVideoUploadModalOpen: boolean;
  handleVideoModalClose: () => void;
  flipbookLayoutTile: FlipbookLayoutTile;
  saveVideo: (videoData: any) => void;
}

const DefaultVideoData = {
  URL: '',
  isMuted: true,
  isAutoPlay: true,
  isControlsEnabled: true
};
enum VideoUrlStatus {
  Invalid = 'Invalid',
  Loading = 'Loading',
  Playable = 'Playable',
  Empty = ''
}
export const VideoUploadModal: FC<VideoUploadModalProps> = ({
  isVideoUploadModalOpen,
  handleVideoModalClose,
  flipbookLayoutTile,
  saveVideo
}) => {
  const [videoData, setVideoData] = React.useState<any>();
  const [videoUrlStatus, setVideoUrlStatus] = React.useState<VideoUrlStatus>();
  const [isSaveDisabled, setIsSaveDisabled] = React.useState<boolean>();
  const setStatusOfVideo = (url: string) => {
    setVideoUrlStatus(VideoUrlStatus.Loading);
    let status = ReactPlayer.canPlay(url)
      ? VideoUrlStatus.Playable
      : VideoUrlStatus.Invalid;
    if (isEmpty(url)) {
      status = VideoUrlStatus.Empty;
    }
    setVideoUrlStatus(status);
  };
  useEffect(() => {
    let currentVideoData = get(flipbookLayoutTile, `tileData`, {});
    currentVideoData = isEmpty(currentVideoData)
      ? DefaultVideoData
      : currentVideoData;
    if (isEmpty(videoData)) {
      if (isEmpty(get(currentVideoData, 'URL', undefined))) {
        setIsSaveDisabled(true);
        setVideoData(currentVideoData);
        setStatusOfVideo(get(currentVideoData, 'URL', ''));
        return;
      }
      setStatusOfVideo(get(currentVideoData, 'URL', ''));
      setIsSaveDisabled(false);
      setVideoData(currentVideoData);
    }
  }, [flipbookLayoutTile, videoData]);

  const getVideoData = (node: string) => {
    return get(videoData, node, '');
  };
  const onChangeVideoData = (node: string, value: any) => {
    if (node === 'URL') {
      setStatusOfVideo(value);
      if (!isEmpty(value)) {
        setIsSaveDisabled(false);
      } else {
        setIsSaveDisabled(true);
      }
    }
    const updatedVideoData: any = { ...videoData, [node]: value };
    setVideoData(updatedVideoData);
  };
  const handleVideoDataSave = () => {
    saveVideo(videoData);
  };
  const loadingComponent = () => {
    return (
      <CircularProgress
        color="inherit"
        style={{ height: 20, width: 20, marginLeft: 3, color: '#f2755f' }}
      />
    );
  };
  const validComponent = () => {
    return (
      <CheckCircleIcon
        color="inherit"
        style={{ height: 20, width: 20, marginLeft: 3, color: '#28a745' }}
      />
    );
  };
  const InvalidComponent = () => {
    return (
      <CancelIcon
        color="inherit"
        style={{ height: 20, width: 20, marginLeft: 3, color: 'red' }}
      />
    );
  };
  const getStatusComponent = () => {
    switch (videoUrlStatus) {
      case VideoUrlStatus.Playable:
        return validComponent();
      case VideoUrlStatus.Invalid:
        return InvalidComponent();
      case VideoUrlStatus.Loading:
        return loadingComponent();
      default:
        return '';
    }
  };

  return (
    <Modal
      open={isVideoUploadModalOpen}
      onClose={handleVideoModalClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={'flipbook-video-layout-modal'}
    >
      <div className={'flipbook-video-layout-modal-container'}>
        <div className={'flipbook-video-layout-header'}>
          <div className={'flipbook-video-layout-title'}>{'Upload Video'}</div>
          <img
            style={{
              margin: '0px 12px',
              cursor: 'pointer',
              position: 'absolute',
              right: 0
            }}
            src={cancelIcon}
            alt={'close'}
            onClick={handleVideoModalClose}
          />
        </div>
        <div className={'video-upload-modal-content'}>
          <form
            noValidate
            autoComplete="off"
            className={'video-upload-modal-form'}
          >
            <div className={'video-upload-url-container'}>
              <TextField
                id="standard-basic"
                label="URL"
                value={getVideoData('URL')}
                style={{ width: '90%' }}
                onChange={event => onChangeVideoData('URL', event.target.value)}
              />
              <div className={'flipbook-video-url-status-container'}>
                {getStatusComponent()}
              </div>
            </div>
            <h2 className={'flipbook-text-header my-4'}>
              {'Choose Video Play Settings (optional)'}
            </h2>
            <FormControlLabel
              className={'m-0 video-upload-settings-label'}
              control={
                <Checkbox
                  checked={getVideoData('isMuted')}
                  onChange={() =>
                    onChangeVideoData('isMuted', !videoData?.isMuted)
                  }
                  name="checkedA"
                  color="primary"
                />
              }
              label="Muted"
            />
            <FormControlLabel
              className={'m-0 video-upload-settings-label'}
              control={
                <Checkbox
                  checked={getVideoData('isAutoPlay')}
                  onChange={() =>
                    onChangeVideoData('isAutoPlay', !videoData?.isAutoPlay)
                  }
                  name="checkedA"
                  color="primary"
                />
              }
              label="Auto Play"
            />
            <FormControlLabel
              className={'m-0 video-upload-settings-label'}
              control={
                <Checkbox
                  checked={getVideoData('isControlsEnabled')}
                  onChange={() =>
                    onChangeVideoData(
                      'isControlsEnabled',
                      !videoData?.isControlsEnabled
                    )
                  }
                  name="checkedA"
                  color="primary"
                />
              }
              label="Controls"
            />
          </form>
          {videoUrlStatus === VideoUrlStatus.Playable && (
            <div style={{ paddingBottom: '10px' }}>
              <h2 className={'flipbook-text-header my-4'}>
                {'Preview Screen'}
              </h2>
              <div className={'flipbook-upload-video-preview-screen'}>
                <ReactPlayer
                  url={get(videoData, 'URL', '')}
                  light={true}
                  width={'240px'}
                  height={'160px'}
                />
              </div>
            </div>
          )}
          <div className={'text-center mb-5'}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSaveDisabled}
              onClick={() => handleVideoDataSave()}
              style={{ padding: '.5rem 3rem' }}
            >
              {'save'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
