import React from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  IconButton,
  withStyles
} from '@material-ui/core';
import { RewardTypes, SocialMediaPlatformActions } from '../../../constants';
import styles from '../styles';
import CloseIcon from '@material-ui/icons/Close';

function RewardDefinition(props) {
  const { channel, reward, onChange, remove } = props;
  reward.rewardType = RewardTypes.LOYALTY;

  return (
    <div
      style={{
        borderRadius: 5,
        backgroundColor: '#F5F5F5',
        padding: 15,
        marginTop: 25
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '10px'
        }}
      >
        <div style={{ marginTop: 5 }}>Select the action to engage:</div>
        <FormControl style={{ minWidth: 150, marginLeft: 15, flex: 5 }}>
          <Select
            value={reward.actionType}
            onChange={event =>
              onChange({ ...reward, actionType: event.target.value })
            }
          >
            {Object.values(SocialMediaPlatformActions[channel] || []).map(
              actionType => (
                <MenuItem value={actionType} key={actionType}>
                  {actionType}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
        <div style={{ flex: 9, textAlign: 'right', paddingRight: 5 }}>
          {reward.id !== 0 && (
            <IconButton onClick={() => remove(reward.id)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </div>
      </div>
      {/*<div style={{ marginTop: 15 }}>*/}
      {/*  Select Reward Type:*/}
      {/*  <RadioGroup*/}
      {/*    name="Post"*/}
      {/*    value={reward.rewardType}*/}
      {/*    onChange={event =>*/}
      {/*      onChange({ ...reward, rewardType: event.target.value })*/}
      {/*    }*/}
      {/*  >*/}
      {/*    {Object.values(RewardTypes).map(rewardTypeValue => (*/}
      {/*      <div key={rewardTypeValue} style={{ marginLeft: 30 }}>*/}
      {/*        <FormControlLabel*/}
      {/*          value={rewardTypeValue}*/}
      {/*          key={rewardTypeValue}*/}
      {/*          label={rewardTypeValue}*/}
      {/*          control={*/}
      {/*            <Radio*/}
      {/*              classes={{*/}
      {/*                root: classes.radio,*/}
      {/*                checked: classes.checked*/}
      {/*              }}*/}
      {/*            />*/}
      {/*          }*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    ))}*/}
      {/*  </RadioGroup>*/}
      {/*</div>*/}
      {/* <div style={{ marginTop: 15 }}> */}
      {/*{reward.rewardType === RewardTypes.LOYALTY && (*/}
      {/* <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ flex: 3 }}>No. of Points to be rewarded:</span>
          <TextField
            value={reward.value}
            onChange={event =>
              onChange({ ...reward, value: parseInt(event.target.value) })
            }
            style={{ width: '50%', marginLeft: 15, flex: 5 }}
            type="number"
          />
          <div style={{ flex: 9 }} />
        </div> */}
      {/*)}*/}
      {/* </div> */}
    </div>
  );
}

export default withStyles(styles)(RewardDefinition);
