import React, { FC } from 'react';
import MyCart from '../MyCart/MyCart';
import FlipbookPageDimensions from '../Utiles/FlipbookPageDimensions';
import { FlipbookPage } from './common';
import FlipbookPageGradient from '../Utiles/FlipbookPageGradient';

interface MyCartPageProps {
  pageNumber: number;
  storeDetails: any;
  flipbookIdOrToken: any;
  currentStore: any;
}

export const MyCartPage: FC<MyCartPageProps> = ({
  pageNumber,
  storeDetails,
  flipbookIdOrToken,
  currentStore
}) => {
  return (
    <FlipbookPage
      width={FlipbookPageDimensions.getFlipbookWidth()}
      height={FlipbookPageDimensions.getFlipbookHeight()}
    >
      <div>
        <MyCart
          storeDetails={storeDetails}
          flipbookIdOrToken={flipbookIdOrToken}
          currentStore={currentStore}
        />
      </div>
      <div
        className={'page-effect'}
        style={{
          backgroundImage: FlipbookPageGradient.getGradientForPage(pageNumber)
        }}
      ></div>
    </FlipbookPage>
  );
};

export default MyCartPage;
