import _, { findIndex, get, isNumber } from 'lodash';
import { formatReadableQuantity } from '../../../../utils';
const RESTRICTED_VALUES = [
  '',
  0,
  undefined,
  null,
  10000000,
  '10000000',
  '0',
  '0.0',
  'N/A'
];
function getMrpOrRp(labelData) {
  labelData.product.mrp = Number(labelData.product.mrp);
  labelData.rp = Number(labelData.rp);
  if (RESTRICTED_VALUES.includes(labelData.product.mrp)) {
    labelData['PriceTag'] = 'RP';
    labelData.product.mrp = RESTRICTED_VALUES.includes(labelData.rp)
      ? 'N/A'
      : labelData.rp;
    return labelData;
  }
  labelData['PriceTag'] = 'MRP';
  return labelData;
}
function getNAOrMop(mop) {
  mop = Number(mop);
  if (RESTRICTED_VALUES.includes(mop)) {
    return 'N/A';
  }
  return mop;
}
function rasterPrice(value) {
  if (Number.isInteger(value) || RESTRICTED_VALUES.includes(value)) {
    return value;
  }
  return value >= 100 ? Math.ceil(value) : Math.floor(value * 100) / 100;
}
function roundOffPrice(labelData) {
  labelData.product.mrp = rasterPrice(labelData.product.mrp);
  labelData.product.mop = rasterPrice(labelData.product.mop);
  labelData.rp = rasterPrice(labelData.rp);
  return labelData;
}
function pickStoreLevelPriceOrMOP(labelData) {
  if (isNumber(labelData.tenantStoreId)) {
    const tenantStorePrice = get(labelData, 'product.productStorePrice', []);
    const currentProductStorePriceIndex = findIndex(tenantStorePrice, sp => {
      return sp.tenantStoreId === labelData.tenantStoreId;
    });
    if (currentProductStorePriceIndex !== -1) {
      const currentProductStorePrice = get(
        tenantStorePrice,
        currentProductStorePriceIndex
      );
      return get(currentProductStorePrice, 'price', '');
    }
  }
  return labelData.product.mop;
}
function serializePrice(formatLabelData) {
  return _.map(formatLabelData, value => {
    value.product.mop = pickStoreLevelPriceOrMOP(value);
    value = roundOffPrice(value);
    value = getMrpOrRp(value);
    value.product.mop = getNAOrMop(value.product.mop);
    return value;
  });
}

function getSavePrice(mrp, mop) {
  if (mrp < mop) {
    return 0;
  }
  if (RESTRICTED_VALUES.includes(mrp) || RESTRICTED_VALUES.includes(mop)) {
    return 0;
  }
  const roundedPrice = rasterPrice(mrp - mop);
  return formatReadableQuantity(roundedPrice);
}
export { getSavePrice, serializePrice };
