import React, { FC, useContext, useEffect } from 'react';
import CollectionsPopover from './CollectionsPopover';
import SettingsPopover from './SettingsPopover';
import HelpPopover from './HelpPopover';
import SharePopover from './SharePopover';
import SearchPopover from './SearchPopover';
import EditIcon from '@material-ui/icons/Edit';
import { getColor } from './utiles';
import { IconButton } from '@material-ui/core';
import { FlipbookLayout } from '../../../models/FlipbookV2/FlipbookV2Layout.model';
import { StoreLocator } from './StoreLocator';
import { ProductHierarchyNew, TenantStore } from '../../../models/User.model';
import { LoginComponent } from './LoginComponent';
import isEmpty from 'lodash/isEmpty';
import { CheckoutOption } from '../../../models/FlipbookV2/flipbookV2.model';
import { FlipbookContext } from '../Hooks/FlipbookContext';

interface FlipbookAppBarProps {
  flipbookPages: FlipbookLayout[];
  allProducts: any;
  isEditMode: any;
  goToPageNumber: (number: any) => void;
  productHierarchy: ProductHierarchyNew[];
  isEditPanelVisible: boolean;
  isCustomerView: boolean;
  isEditPanelOpen: boolean;
  setIsEditPanelOpen: (state: boolean) => void;
  flipbookIdOrToken: any;
  currentStore: any;
  setCurrentStore: (data: any) => void;
  storeDetails: TenantStore[];
  flipbookEl: any;
}
export const FlipbookAppBar: FC<FlipbookAppBarProps> = ({
  flipbookPages,
  allProducts,
  isEditMode,
  isEditPanelVisible,
  goToPageNumber,
  isEditPanelOpen,
  setIsEditPanelOpen,
  productHierarchy,
  isCustomerView,
  flipbookIdOrToken,
  currentStore,
  setCurrentStore,
  storeDetails,
  flipbookEl
}) => {
  const [isMobileScreen, setIsMobileScreen] = React.useState<boolean>();
  const flipbookContext = useContext(FlipbookContext);
  const currentFlipbookData = flipbookContext.flipbookContext;
  useEffect(() => {
    if (isEmpty(isMobileScreen)) {
      const isMobileView = window.screen.width <= 600;
      setIsMobileScreen(isMobileView);
    }
  }, [isMobileScreen]);

  return (
    <div className={'flipbook-bar'}>
      {isMobileScreen &&
        (currentFlipbookData?.checkoutOption ||
          CheckoutOption.FlipbookCheckout) ===
          CheckoutOption.FlipbookCheckout &&
        !isEditMode && (
          <div style={{ position: 'absolute', left: 0 }}>
            <LoginComponent
              currentStore={currentStore}
              setCurrentStore={setCurrentStore}
            />
          </div>
        )}
      {
        <CollectionsPopover
          flipbookPages={flipbookPages}
          goToPageNumber={goToPageNumber}
          isEditMode={isEditMode}
          flipbookIdOrToken={flipbookIdOrToken}
          isCustomerView={isCustomerView}
        />
      }
      {isEditMode && <SettingsPopover productHierarchy={productHierarchy} />}
      {isEditMode && <HelpPopover />}
      {
        <SharePopover
          isCustomerView={isCustomerView}
          flipbookIdOrToken={flipbookIdOrToken}
          currentStore={currentStore}
        />
      }
      {<SearchPopover flipbookEl={flipbookEl} allProducts={allProducts} />}
      {isEditMode && (
        <div className={'flipbook-bar-icon'}>
          <IconButton
            style={{ color: getColor(isEditPanelOpen) }}
            className={'outline-none'}
            aria-label="helpOutlineIcon"
            disabled={!isEditPanelVisible}
            onClick={() => {
              setIsEditPanelOpen(!isEditPanelOpen);
            }}
          >
            <EditIcon />
          </IconButton>
        </div>
      )}
      {isCustomerView && (
        <div
          className={'user-cta-component'}
          style={{
            gridTemplateColumns:
              (currentFlipbookData?.checkoutOption ||
                CheckoutOption.FlipbookCheckout) ===
              CheckoutOption.FlipbookCheckout
                ? '90% 10%'
                : '100%',
            marginRight:
              (currentFlipbookData?.checkoutOption ||
                CheckoutOption.FlipbookCheckout) ===
              CheckoutOption.FlipbookCheckout
                ? '30px'
                : 0
          }}
        >
          <StoreLocator
            currentStore={currentStore}
            setCurrentStore={setCurrentStore}
            storeDetails={storeDetails}
            isCustomerView={isCustomerView}
          />
          {!isEditMode &&
            !isMobileScreen &&
            (currentFlipbookData?.checkoutOption ||
              CheckoutOption.FlipbookCheckout) ===
              CheckoutOption.FlipbookCheckout && (
              <LoginComponent
                currentStore={currentStore}
                setCurrentStore={setCurrentStore}
              />
            )}
        </div>
      )}
    </div>
  );
};

export default FlipbookAppBar;
