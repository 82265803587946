import React from 'react';
import HeadingProps from '../../models/HeadingProps';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const Heading: React.FC<HeadingProps> = ({
  heading,
  width = '100%',
  fontSize = '18px',
  style = {}
}) => {
  const headingStyles = {
    fontWeight: 600,
    fontSize: fontSize,
    textTransform: 'uppercase',
    marginBottom: '10px',
    width: width
  };

  return <div style={{ ...headingStyles, ...style }}>{heading}</div>;
};
export default Heading;
