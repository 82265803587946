import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import { SectionType } from '../../../../DynamicInvoiceGenerator/InvoiceGenerator';
import type SectionField from '../../../../DynamicInvoiceGenerator/models/SectionField';
import { InvoiceContext } from '../../../context/InvoiceContext';
import { Heading } from '../../../shared/Heading';
import sectionUtils from '../../../utils/sectionUtils';
import InputField from '../../InputField/InputField';
import AntSwitch from '../../Switch/AntSwitch';
import classes from './InvoiceDetails.module.scss';
import useDebounce from '../../../shared/use-debounce';

const type = SectionType.ORDER_INFO;
const InvoiceDetails: React.FC = () => {
  const invoiceContext = useContext(InvoiceContext);
  const { heading, fields } = invoiceContext.getConfig(type);

  const initialState = sectionUtils.getInitialValuesByIndex(fields)
  const defaultLabels = sectionUtils.getDefaultFieldLabels(type, "id");
  
  const [inputFields, setInputFields] = useState(initialState);
  const debouncedFields = useDebounce(inputFields, 1000);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { name, value } = e.target;
    const targetInput = inputFields[name];
    setInputFields(prev => ({
      ...prev,
      [name]: { ...targetInput, label: value }
    }));
  };
  
  useEffect(() => {
    invoiceContext.setConfig(type, inputFields);
    // eslint-disable-next-line
  }, [debouncedFields]);

  function renderField({ label, getter, type ,id }: SectionField, idx: number) {
    const key = id!
    return (
      <div key={idx} className={classes.fields}>
        <InputField
          type={type}
          onChange={handleChange}
          name={key}
          placeholder={defaultLabels[key]}
          label={`${idx + 1}.Default Field Name`}
          disabled={true}
        />
        <InputField
          type={type}
          onChange={handleChange}
          name={key}
          placeholder={label}
          label={defaultLabels[key]}
        />
        <div className={classes.switch}>
          <AntSwitch
            checked={inputFields[key].req ?? false}
            onChange={e => {
              setInputFields(prev => ({
                ...prev,
                [key]: { ...prev[key], req: e.target.checked }
              }));
            }}
            name={key}
          />
          <p>Show the field in the invoice</p>
        </div>
      </div>
    );
  }

  function renderForm() {
    return <form className={classes.form}>{_.map(fields, renderField)}</form>;
  }

  return (
    <div className={classes.container}>
      <Heading title={heading} subtitle={"* If change field is empty the default field name will be displayed"}/>
      <div style={{ padding: '0.5em' }}></div>
      {renderForm()}
    </div>
  );
};
export default InvoiceDetails;
