import React, { useEffect, useState } from 'react';
import ReportTable from './ReportTable';
import { getB2BOfferTypesWithMappedOffers } from '../../services/b2bOffersReport.service';
import _ from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { InputAdornment } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

const B2BOffersReport = () => {
  const [tableData, setTableData] = useState([]);
  const [flowNameSelectValue, setFlowNameSelectValue] = useState('');
  const [flowNameInputValue, setFlowNameInputValue] = useState('');

  useEffect(() => {
    getB2BOfferTypesWithMappedOffers().then(res => {
      setTableData(res);
    });
  }, []);

  const getFilteredTableData = () => {
    return _.filter(tableData, row => {
      return _.includes(
        _.toLower(_.get(row, 'offerType')),
        _.toLower(flowNameInputValue)
      );
    });
  };

  const filteredTableData = getFilteredTableData();

  return (
    <div style={{ padding: 50 }}>
      <div
        style={{
          display: 'flex',
          marginBottom: 15,
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div style={{ padding: 20 }}>
          <div style={{ fontSize: 24, fontWeight: 'bold' }}>
            B2B OFFERS REPORT
          </div>
          <div style={{ marginTop: 10 }}>Count: {filteredTableData.length}</div>
        </div>
        <div style={{ minWidth: 300 }}>
          <Autocomplete
            freeSolo
            value={flowNameSelectValue}
            onChange={(event, newValue) => {
              setFlowNameSelectValue(newValue);
            }}
            inputValue={flowNameInputValue}
            onInputChange={(event, newValue) => {
              setFlowNameInputValue(newValue);
            }}
            options={_.map(tableData, option => _.get(option, 'offerType'))}
            renderInput={params => (
              <TextField
                {...params}
                label="Search flow name"
                margin="normal"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
        </div>
      </div>
      <ReportTable tableData={filteredTableData} />
    </div>
  );
};

export default B2BOffersReport;
