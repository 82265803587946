import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect
} from '@material-ui/core';
import React, { FC } from 'react';

import styles from './Select.module.scss';

interface Option {
  label: string;
  value: string;
}

interface SelectProps {
  label: string;
  value: string;
  options: Option[];
  name: string;
  onChange: (value: { [key: string]: string }) => void;
  empty?: { label: string; value: string } | null;
}

const Select: FC<SelectProps> = ({
  label,
  options,
  value,
  name,
  empty,
  onChange
}) => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    onChange({ [name]: event.target.value as string });
  };

  return (
    <FormControl id={styles.formControl} margin="none">
      <InputLabel shrink className={styles.inputLabel}>
        {label}
      </InputLabel>
      <MuiSelect
        displayEmpty
        id={styles.select}
        value={value}
        onChange={handleChange}
      >
        {empty && (
          <MenuItem value={empty.value}>
            <em>{empty.label}</em>
          </MenuItem>
        )}
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};
export default Select;
