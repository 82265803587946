import clsx from 'clsx';
import React, { FC } from 'react';

import styles from './Chip.module.scss';

interface ChipProps {
  label: React.ReactNode;
  color?: 'primary' | 'error' | 'success' | 'primary-light';
  variant?: 'contained' | 'outlined';
  rounded?: boolean;
}

const Chip: FC<ChipProps> = ({
  label,
  color = 'primary',
  variant = 'contained',
  rounded = false
}) => {
  return (
    <div
      className={clsx(styles.chip, styles[variant], styles[color], {
        [styles.rounded]: rounded
      })}
    >
      {label}
    </div>
  );
};
export default Chip;
