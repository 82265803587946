import React from 'react';
import classNames from 'classnames';
import { Grid, makeStyles, TextField } from '@material-ui/core';
import { RadioButton, FileUpload, AutoComplete } from '.';
import { DateFilter } from './Menu/DateFilter';
import { CategoryFilter } from './Menu/CategoryFilter';
import { Autocomplete } from '@material-ui/lab';
import { getMaxVersion } from './utils/VersionHandler';
import _ from 'lodash';
const useStyles = makeStyles(theme => ({
  paper: {
    width: 340,
    height: 300,
    backgroundColor: '#fff',
    boxShadow: '0px 5px 10px #00000029',
    margin: 'auto'
  }
}));
const SelectItem = ({
  selectedOption,
  setSelectedOption,
  state,
  productsHierarchy,
  allProducts,
  updateState,
  ...rest
}) => {
  const classes = useStyles();
  const options = [
    {
      id: 1,
      label: 'Search Product Category',
      content: (
        <CategoryFilter
          disabled={selectedOption?.id !== 1}
          productHierarchy={productsHierarchy}
          allProducts={allProducts}
          skus={state[1].sku}
          updateState={updateState}
          {...rest}
        />
      ),
      name: 'sku',
      error: 'No Category has been selected'
    },
    {
      id: 2,
      label: 'Upload SKU list file',
      content: (
        <FileUpload
          disabled={selectedOption?.id !== 2}
          file={state[2]?.csv}
          updateState={updateState}
          {...rest}
        />
      ),
      name: 'csv',
      error: 'No CSV has been uploaded'
    },
    {
      id: 3,
      label: 'SKU name',
      content: (
        <>
          <AutoComplete
            placeholder="Enter SKU name"
            disabled={selectedOption?.id !== 3}
            value={state[3].sku}
            updateState={updateState}
            {...rest}
          />
        </>
      ),
      name: 'sku',
      error: 'No SKU has been entered'
    },
    {
      id: 4,
      label: 'Price Date',
      content: (
        <DateFilter
          fields={state[4].fields}
          disabled={selectedOption?.id !== 4}
          updateState={updateState}
          {...rest}
        />
      ),
      name: 'fields',
      error: 'No Date has been selected'
    }
  ];
  const getStateKey = state => {
    return _.head(_.keys(state));
  };
  const getStateValues = state => {
    return _.head(_.values(state));
  };
  const handleVersionChanges = version => {
    const currentStateId = _.get(state, 'selectedOption.id', '');
    const currentState = state[currentStateId];
    updateState(currentStateId, {
      [getStateKey(currentState)]: {
        ...getStateValues(currentState),
        version: version
      }
    });
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justify="center">
          {options?.map(data => (
            <Grid
              /* className={classNames({
                // eslint-disable-next-line
                ['not-allowed']: data.id === 1
              })}*/
              key={data?.label}
              item
              xs={3}
            >
              <div
                className={classNames(`${classes.paper}`, {
                  // eslint-disable-next-line
                  // ['disabled-field']: data.id === 1
                })}
              >
                <div style={{ padding: 36, margin: 'auto', height: '210px' }}>
                  <RadioButton
                    label={data?.label}
                    content={data?.content}
                    checked={selectedOption?.label === data?.label}
                    onCheck={() => setSelectedOption('selectedOption', data)}
                  />
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '90px',
                    backgroundColor: '#F9F9F9',
                    padding: '5px 0rem'
                  }}
                >
                  {data?.id !== 2 && (
                    <div
                      style={{
                        width: '80%',
                        textAlign: 'left',
                        margin: 'auto',
                        marginTop: '5px',
                        fontSize: '14px'
                      }}
                    >
                      <div>Choose label template</div>
                      <Autocomplete
                        id="combo-box-demo"
                        options={getMaxVersion()}
                        size={'small'}
                        getOptionLabel={option => option}
                        style={{ width: 120, margin: '5px 0px' }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Version"
                            variant="outlined"
                            size={'small'}
                          />
                        )}
                        disabled={selectedOption?.label !== data?.label}
                        onChange={(event, value) => {
                          handleVersionChanges(value);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export { SelectItem };
