import React, { FC, useState } from 'react';
import LoyaltyFilter from './Filter';
import LoyaltyDownloadTable from './List';
import usePagination from '../../../../shared/hooks/usePagination';
import { getMemberLoyaltyReportDownload } from '../../../../services/TenantReports/MemberLoyalty.service';

const MemberLoyaltyReport: FC = () => {
  const [refreshView, setRefreshView] = useState<string>('');
  const {
    data,
    isLoading,
    page,
    offset,
    setPage,
    setOffset
  } = usePagination(getMemberLoyaltyReportDownload, [refreshView]);
  return (
    <div style={{ padding: '2rem' }}>
      <div>
        <h4>Loyalty Summary Report</h4>
      </div>
      <LoyaltyFilter setRefreshView={setRefreshView} />
      <LoyaltyDownloadTable
        reports={data || []}
        isLoading={isLoading}
        page={page}
        offset={offset}
        setPage={setPage}
        setOffset={setOffset}
      />
    </div>
  );
};

export default MemberLoyaltyReport;
