import { Box } from '@material-ui/core';
import React from 'react';
import JournifySettingsTabs from './JournifySettingsTabs/JournifySettingsTabs';

const JournifySettings = () => {
  return (
    <Box>
      <JournifySettingsTabs />
    </Box>
  );
};
export default JournifySettings;
