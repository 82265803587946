import React, { Context } from 'react';

type FlipbookStateContextProps = {
  isStoreLoadedFromQuery: boolean;
};

export const FlipbookStateContext: Context<FlipbookStateContextProps> = React.createContext<
  FlipbookStateContextProps
>({
  isStoreLoadedFromQuery: false
});
