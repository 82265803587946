import {
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import _, { capitalize, debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { Business } from '../../../models/User.model';

import TenantField, { EntityType } from '../models/TenantField';
import { getTenantFields, updateTenantFields } from '../services';
import TenantFieldForm from './TenantFieldForm/TenantFieldForm';
import styles from './TenantFields.module.scss';

const entities: EntityType[] = ['product', 'bill'];

const CasaFields = (): JSX.Element => {
  const [selectedBusiness, setSelectedBusiness] = useState<
    Business | undefined
  >(new Business());
  const [tenantFields, setTenantFields] = useState<{
    count: number;
    rows: TenantField[];
  } | null>(null);
  const [tenantFieldLoading, setTenantFieldLoading] = useState<boolean>(true);
  const [selectedEntityType, setSelectedEntityType] = useState(entities[0]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState<string | null>(null);
  const user = useCurrentUser();

  useEffect(() => {
    if (selectedBusiness) {
      (async (): Promise<void> => {
        setTenantFieldLoading(true);
        try {
          const tenantFields = await getTenantFields(
            selectedBusiness.id,
            selectedEntityType,
            offset,
            limit,
            searchQuery || null
          );
          setTenantFields(tenantFields);
        } catch (error) {
          setError((error as Error).message || 'Something went wrong');
        } finally {
          setTenantFieldLoading(false);
        }
      })();
    }
  }, [selectedBusiness, selectedEntityType, offset, limit, searchQuery]);

  useEffect(() => {
    setSelectedBusiness(
      _.chain(user.getBusinesses())
        .orderBy(['id'])
        .first()
        .value() || new Business()
    );
  }, [user]);

  const handleBusinessChange = (event: any): void => {
    const selectedBusiness = _.find(user.getBusinesses(), {
      id: event.target.value
    });
    setSelectedBusiness(selectedBusiness);
  };

  const handleUpdateTenantField = async (
    tenantFields: TenantField[]
  ): Promise<void> => {
    if (selectedBusiness)
      await toast.promise(
        updateTenantFields(
          selectedBusiness.id,
          tenantFields,
          selectedEntityType
        ),
        {
          pending: 'Updating Fields...',
          success: 'Fields Updated!',
          error: 'Could Not Save Fields'
        }
      );
  };

  const handleEntityChange = (event: any): void => {
    setSelectedEntityType(event.target.value as EntityType);
  };

  const handleDebounceSearch = useMemo(
    () =>
      debounce((searchQuery: string) => {
        setOffset(0);
        setLimit(5);
        setSearchQuery(searchQuery);
      }, 750),
    []
  );

  const handleSearch = (event: any): void => {
    handleDebounceSearch(event.target.value as string);
  };

  return (
    <Container maxWidth="xl" style={{ marginBlock: '1rem' }}>
      <Box id={styles.tenantFieldHeader}>
        <Typography variant="h5">Tenant Fields</Typography>
      </Box>
      <Box id={styles.tenantFieldFilters}>
        <Box>
          <TextField
            label="Search"
            className={styles.tenantFieldSearch}
            onChange={handleSearch}
            defaultValue={searchQuery}
          />
          <FormControl className={styles.entitySelect}>
            <InputLabel>Entity Type</InputLabel>
            <Select
              value={selectedEntityType}
              label="Entity Type"
              onChange={handleEntityChange}
            >
              {entities.map(entity => (
                <MenuItem key={entity} value={entity}>
                  {capitalize(entity)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <FormControl className={styles.formControl} style={{ width: '200px' }}>
          <InputLabel id="businessLabel">Business unit</InputLabel>
          <Select
            defaultValue=""
            labelId="businessLabel"
            value={selectedBusiness?.id}
            onChange={handleBusinessChange}
          >
            {_.map(user.getBusinesses(), b => (
              <MenuItem value={b.id} key={b.id}>
                {b.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {(!error || tenantFieldLoading) && (
        <TenantFieldForm
          tenantFields={tenantFields?.rows || []}
          onSubmit={handleUpdateTenantField}
          limit={limit}
          offset={offset}
          onOffsetChange={setOffset}
          onLimitChange={setLimit}
          totalTenantFields={tenantFields?.count || 0}
          loading={tenantFieldLoading}
        />
      )}
      {tenantFields?.rows.length === 0 && !tenantFieldLoading && searchQuery && (
        <Alert severity="info">
          No Field found for &quot;<strong>{searchQuery}</strong>&quot;
        </Alert>
      )}
      {tenantFields?.rows.length === 0 && !tenantFieldLoading && !searchQuery && (
        <Alert severity="info">
          No Field found for &quot;
          <strong>{capitalize(selectedEntityType)}</strong>&quot;
        </Alert>
      )}
      {error && !tenantFieldLoading && <Alert severity="error">{error}</Alert>}
    </Container>
  );
};

export default CasaFields;
