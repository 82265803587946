import React, { useState, useContext } from 'react';
import _ from 'lodash';
import { SuggestionsTrayItem } from './SuggestionsTrayItem/SuggestionsTrayItem';
import classes from './SuggestionsTray.module.css';
import NodeTypes from '../../BaseNodeModel/NodeTypes';
import { DragDropArea } from '../DragDropArea/DragDropArea';
import { TriggerNodeModel } from '../../TriggerNode/TriggerNodeModel';
import { ActionNodeModel } from '../../ActionNode/ActionNodeModel';
import { TimedNodeModel } from '../../TimedNode/TimedNodeModel';
import { DecisionNodeModel } from '../../DecisionNode/DecisionNodeModel';
import { StopNodeModel } from '../../StopNode/StopNodeModel';
import { WaitNodeModel } from '../../WaitNode/WaitNodeModel';
import RuleTemplatesContext from '../../context/ruleTemplatesContext';
import { PortTypes } from '../../BaseNodeModel/PortModels';

const typeToNodeMapper = {
  [NodeTypes.TRIGGER_NODE]: TriggerNodeModel,
  [NodeTypes.TIMED_NODE]: TimedNodeModel,
  [NodeTypes.DECISION_NODE]: DecisionNodeModel,
  [NodeTypes.STOP_NODE]: StopNodeModel,
  [NodeTypes.WAIT_NODE]: WaitNodeModel,
  [NodeTypes.ACTION_NODE]: ActionNodeModel
};

const createNode = node => {
  return new typeToNodeMapper[node]({
    name: node.split(/(?=[A-Z])/).join(' '),
    type: node,
    icon: node,
    description: `This is a ${node.split(/(?=[A-Z])/).join(' ')}`
  });
};
const ConnectNode = ({ currentNode, nextNode }) => {
  const { diagramEngine, forceUpdate } = useContext(RuleTemplatesContext);

  if (_.includes([NodeTypes.ACTION_NODE], nextNode)) {
    return (
      <DragDropArea
        className={classes.dragDropArea}
        currentNode={currentNode}
        nextNode={nextNode}
      />
    );
  } else {
    const incomingNode = createNode(nextNode);
    incomingNode.setPosition(
      currentNode.getPort(PortTypes.OUT_PORT).position.x - 35,
      currentNode.getPort(PortTypes.OUT_PORT).position.y + 50
    );

    const link = currentNode
      .getPort(PortTypes.OUT_PORT)
      .link(incomingNode.getPort(PortTypes.IN_PORT));
    diagramEngine.getModel().addAll(incomingNode, link);
    forceUpdate();
    return <div></div>;
  }
};

export const SuggestionsTray = ({ currentNode }) => {
  const nextNodes = currentNode.nextNodes;
  const [selectedNextNode, setSelectedNextNode] = useState(
    nextNodes.length === 1 ? nextNodes[0] : null
  );
  const marginLeft = _.size(nextNodes) === 3 ? '-100px' : '-60px';
  return (
    <div>
      {_.isEmpty(selectedNextNode) && (
        <div className={classes.container}>
          {_.size(nextNodes) > 1 && (
            <div
              className={classes.nodesContainer}
              style={{ marginLeft: marginLeft }}
            >
              {_.map(nextNodes, nextNode => (
                <SuggestionsTrayItem
                  onClick={() => setSelectedNextNode(nextNode)}
                  key={nextNode}
                  currentNode={currentNode}
                  nextNode={nextNode}
                />
              ))}
            </div>
          )}
        </div>
      )}
      {!_.isEmpty(selectedNextNode) && (
        <ConnectNode currentNode={currentNode} nextNode={selectedNextNode} />
      )}
    </div>
  );
};
