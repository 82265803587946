import React, { FC, useContext, useState } from 'react';
import { Button, IconButton, TextField } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import _, { isUndefined } from 'lodash';
import './SelectionAccordion.css';
import { ActionItems } from './Constant';
import { TOOL_EDIT_SECTION } from './EditorPanel';
import casaProductMaster from '../../../../services/productMaster.service';
import { ActionState } from '../../../../models/FlipbookV2/TileData/TileDataImage.model';
import { BusinessUnitContext } from '../../../OfferDefinition/BusinessUnitContext';

interface SelectionAccordionProps {
  selectionState: any;
  saveSelection: () => void;
  cancelSelection: () => void;
  deleteSelection: () => void;
  selectedTool: string;
  setSelectionState: (newSelection: any) => void;
}
export const SelectionAccordion: FC<SelectionAccordionProps> = ({
  selectionState,
  saveSelection,
  cancelSelection,
  deleteSelection,
  selectedTool,
  setSelectionState
}) => {
  const [showError, setShowError] = useState(false);
  const { business } = useContext(BusinessUnitContext);
  const selectedBusinessId = business.id;
  if (_.isEmpty(selectionState)) {
    return <></>;
  }
  console.log('selectionState', selectionState);
  const changeValueOfSelectionState = (value: any, type: string) => {
    let updatedSectionDetails = { ...selectionState, [type]: value };
    if (type === 'actionState' && selectionState.actionItem !== value) {
      updatedSectionDetails = {
        ...selectionState,
        [type]: value,
        actionItem: ''
      };
    }
    setSelectionState(updatedSectionDetails);
  };
  const getSpinnerForNumber = (inputValue: number, type: string) => {
    return inputValue.toFixed(2);
  };
  const getValueOfSelectionState = (searchKey: string, defaultValue: any) => {
    return _.get(selectionState, searchKey, defaultValue);
  };

  const saveSelectionData = () => {
    saveSelection();
  };

  const validateSkuAndSave = () => {
    return casaProductMaster
      .listProductsBySku([selectionState.actionItem], selectedBusinessId)
      .then(
        res => {
          const isValid = _.isEmpty(res) ? false : _.get(res[0], 'isActive');
          isValid ? saveSelectionData() : setShowError(true);
        },
        err => setShowError(true)
      );
  };

  const validateUrlAndSave = () => {
    try {
      new URL(selectionState.actionItem);
      saveSelectionData();
    } catch (_) {
      console.log('invalid URL');
      setShowError(true);
    }
  };

  const validateActionItemAndSave = () => {
    if (isUndefined(selectedBusinessId)) return;
    switch (selectionState.actionState) {
      case ActionState.SKU:
        return validateSkuAndSave();
      case ActionState.URL:
        return validateUrlAndSave();
    }
  };

  const isSaveBtnDisabled = () => {
    return _.isEmpty(_.get(selectionState, 'actionState', ''));
  };

  return (
    <div>
      <div className={'side-panel-selection-container'}>
        <div className={'side-panel-info-container'}>
          {selectedTool === TOOL_EDIT_SECTION && (
            <div style={{ position: 'absolute', right: '1%' }}>
              <IconButton
                aria-label="CancelIcon"
                color="primary"
                onClick={deleteSelection}
                style={{ padding: 0 }}
              >
                <CancelIcon style={{ color: '#a7a7a7' }} />
              </IconButton>
            </div>
          )}
          <div>
            <div className={'side-panel-action-header'}>Select Tag Type</div>
            <div style={{ display: 'flex' }}>
              {_.map(ActionItems, (item, k) => (
                <div className={'side-panel-action-items-header'}>
                  <span
                    className={`side-panel-action-tag-btn ${
                      item.label ===
                      getValueOfSelectionState(
                        'actionState',
                        selectionState.actionState
                      )
                        ? 'active'
                        : ''
                    }`}
                    onClick={() =>
                      changeValueOfSelectionState(item.label, 'actionState')
                    }
                  >
                    {_.startCase(item.item)}
                  </span>
                </div>
              ))}
            </div>
            {!_.isEmpty(selectionState.actionState) && (
              <div>
                <div className={'side-panel-action-items'}>
                  <div className={'side-panel-action-item'}>
                    <TextField
                      id="standard-basic"
                      variant="outlined"
                      label={getValueOfSelectionState(
                        'actionState',
                        selectionState.actionState
                      )}
                      style={{ width: '100%' }}
                      value={selectionState.actionItem}
                      onChange={e =>
                        changeValueOfSelectionState(
                          e.target.value,
                          'actionItem'
                        )
                      }
                      defaultValue={selectionState.actionItem}
                      error={showError}
                      helperText={showError && `Invalid Data`}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={'side-panel-crop-info-container'}>
            <div className={'side-panel-crop-info-header'}>{'Info'}</div>
            <div className={'side-panel-crop-info-content'}>
              <div className={'side-panel-selection-spinner-container'}>
                <div>{`W:`}</div>
                <div className={'px-1'}>
                  {`${getSpinnerForNumber(selectionState.width, 'wdith')} px`}
                </div>
              </div>
              <div className={'side-panel-selection-spinner-container'}>
                <div>{`H:`}</div>
                <div className={'px-1'}>
                  <div>
                    {`${getSpinnerForNumber(
                      selectionState.height,
                      'height'
                    )} px`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'side-panel-crud-action'}>
        <div className={'side-panel-crud-save-cancel'}>
          <Button
            className={'side-panel-action-btn'}
            variant={'outlined'}
            onClick={cancelSelection}
          >
            Discard
          </Button>
          <Button
            disabled={isSaveBtnDisabled()}
            className={`side-panel-action-btn ${
              isSaveBtnDisabled() ? 'disabled' : ''
            } `}
            variant={'outlined'}
            onClick={validateActionItemAndSave}
            style={{ backgroundColor: '#F4907E', color: 'white' }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
