/* eslint-disable @typescript-eslint/explicit-function-return-type */
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import {
  getKpiReport,
  getProcessingKPIReports
} from '../../../services/TenantReports/KpiReport.service';
import {
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from '@material-ui/core';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { get, map, random, upperCase } from 'lodash';
import moment from 'moment';
import { RefreshOutlined, Settings } from '@material-ui/icons';
import React, { FC, useEffect, useState } from 'react';
import { KPILoaderDialog } from './KPILoaderDialog';
import { kpiQuerySegmentOptions } from './Constants/QuerySegmentFilterOptions';
import config from '../../../config';

export const KPI_REPORT_TYPE = {
  ANNUAL: 'Annual',
  QUARTERLY: 'Quarterly',
  DEFAULT: 'Default'
};

const REPORT_FILTER = {
  ANNUAL: ['year'],
  QUARTERLY: ['year', 'month'],
  DEFAULT: ['year', 'month']
};

export interface KPIReportInterface {
  reportFilterDate: string;
  columnName: string;
  businessId?: number;
}

const kpiReportInitialState: KPIReportInterface = {
  reportFilterDate: moment()
    .subtract(1, 'month')
    .endOf('month')
    .format('YYYY-MM-DD'),
  columnName: 'type',
  businessId: undefined
};

interface ReportActionsProps {
  setRefreshView: (id: string) => void;
  setColumnValue: (value: string) => void;
  columnValue: string;
}

const KpiReport: FC<ReportActionsProps> = ({
  setRefreshView,
  setColumnValue,
  columnValue
}) => {
  const [filters, setFilters] = useState<KPIReportInterface>(
    kpiReportInitialState
  );
  const [shouldOpenLoader, setShouldOpenLoader] = useState(false);
  const [loaderConfig, setLoaderConfig] = useState({
    type: 'LOADER',
    name: "Grab a snack! We're crunching the numbers for you"
  });
  const [canDownloadReport, setCanDownloadReport] = useState(true);
  const [selectedBusinesses, setSelectedBusinesses] = useState<string[]>([]);
  const [querySegment, setQuerySegment] = useState<any[]>([]);
  const user = useCurrentUser();
  const businesses = user.getBusinesses().map(b => b.name);

  const handleChange = (value: any, name: string) => {
    setFilters({ ...filters, [name]: value });
  };

  const handleColumnValueChange = (value: any) => {
    setColumnValue(value);
  };
  const getOptions = () => {
    return map(KPI_REPORT_TYPE, value => {
      return value;
    });
  };

  const handleQuerySegmentChange = (event: any) => {
    const {
      target: { value }
    } = event;

    let duplicateRemoved: any[] = [];
    value.forEach((item: any) => {
      if (duplicateRemoved.findIndex(o => o.key === item.key) >= 0) {
        duplicateRemoved = duplicateRemoved.filter(x => x.key === item.key);
      } else {
        duplicateRemoved.push(item);
      }
    });

    setQuerySegment(duplicateRemoved);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsBuId = +urlParams.get('bu')! || 0;

    setFilters(filters => ({ ...filters, businessId: paramsBuId }));
  }, [user]);

  const handleGenerateReport = (): void => {
    setCanDownloadReport(false);
    getProcessingKPIReports().then(pc => {
      if (pc === 0) {
        getKpiReport(
          { ...filters, querySegment: map(querySegment, qs => qs.key) },
          columnValue
        ).then(res => {
          const jobId = get(res, 'jobId', '');
          setRefreshView(jobId);
          setCanDownloadReport(true);
        });
        setShouldOpenLoader(true);
        setLoaderConfig({
          type: 'LOADER',
          name: "Grab a snack! We're crunching the numbers for you"
        });
      } else {
        setShouldOpenLoader(true);
        setLoaderConfig({
          type: 'PROCESSING',
          name: 'Please wait! already a report is processing...'
        });
        setCanDownloadReport(true);
      }
    });
  };
  return (
    <>
      <KPILoaderDialog
        shouldOpenLoader={shouldOpenLoader}
        setShouldOpenLoader={setShouldOpenLoader}
        type={loaderConfig.type}
        name={loaderConfig.name}
      />
      <div
        style={{ margin: 20, display: 'flex', justifyContent: 'space-between' }}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            handleGenerateReport();
          }}
          style={{ display: 'flex', padding: '2px' }}
        >
          <FormControl style={{ minWidth: 180, marginRight: '10px' }}>
            <InputLabel id="kpi-report-select-helper-label">
              KPI Report Type
            </InputLabel>
            <Select
              labelId="select-kpi-report-type-label"
              id="select-kpi-report-type"
              value={columnValue}
              label="Report Type"
              onChange={e => handleColumnValueChange(e.target.value)}
            >
              {map(getOptions(), (value, key) => {
                return (
                  <MenuItem
                    value={value}
                    key={key}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl style={{ minWidth: 180, marginRight: '10px' }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                views={get(
                  REPORT_FILTER,
                  upperCase(columnValue),
                  REPORT_FILTER.DEFAULT
                )}
                label="Select to Date"
                value={filters.reportFilterDate}
                onChange={e => handleChange(e, 'reportFilterDate')}
                variant="inline"
                maxDate={moment()
                  .subtract(1, 'month')
                  .endOf('month')
                  .format('YYYY-MM-DD')}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          {businesses && (
            <FormControl style={{ width: '200px', marginRight: '10px' }}>
              <InputLabel id="businessLabel">Business units</InputLabel>
              <Select
                multiple
                defaultValue=""
                labelId="businessLabel"
                value={selectedBusinesses}
                onChange={(event: any) => {
                  handleChange(event.target.value, 'businesses');
                  setSelectedBusinesses(event.target.value);
                }}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  }
                }}
                renderValue={(selectedBus: any) => selectedBus.join(', ')}
              >
                {map(businesses, b => (
                  <MenuItem
                    selected={selectedBusinesses.includes(b)}
                    value={b}
                    key={b}
                  >
                    <Checkbox
                      style={{ color: 'rgb(242, 117, 95)' }}
                      checked={selectedBusinesses.includes(b)}
                    />
                    {b}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl style={{ marginRight: '10px', width: '250px' }}>
            <InputLabel id="query-segment-checkbox-label">
              Select Data to Generate *
            </InputLabel>
            <Select
              labelId="query-segment-checkbox-label"
              id="query-segment-checkbox"
              multiple
              required
              value={querySegment}
              onChange={handleQuerySegmentChange}
              renderValue={(selected: any) =>
                map(selected, qs => qs.name).join(', ')
              }
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                }
              }}
            >
              {kpiQuerySegmentOptions.map((qs, key) => (
                <MenuItem key={key} value={qs as any}>
                  <Checkbox
                    color="primary"
                    checked={
                      querySegment.findIndex(item => item.key === qs.key) !== -1
                    }
                  />
                  <ListItemText primary={qs.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!canDownloadReport}
            disableElevation
          >
            Generate Report
          </Button>
        </form>
        <Button
          onClick={() => setRefreshView(`${random(10000)}`)}
          color="primary"
        >
          <RefreshOutlined
            style={{ marginRight: '5px' }}
            color="primary"
            fontSize="medium"
          />
          Refresh
        </Button>
        <IconButton
          component="a"
          href={`${config.dashboard}/settings/module/customer-cube/customer-lifecycle`}
          target="_blank"
        >
          <Settings />
        </IconButton>
      </div>
    </>
  );
};

export default KpiReport;
