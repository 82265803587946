import React from 'react';
import { TextField, Button } from '@material-ui/core';
import './styles.css';
import image from '../Assets/croma-priveleges.png';
import { sendSMS } from '../../services/promo.service';

export default class SendCampaignMessagePage extends React.Component {
  constructor(props) {
    super(props);
    const url = new URL(window.location.href);
    const tenant = url.searchParams.get('tenant');
    this.state = {
      mobile: null,
      tenant: tenant,
      campaignUrl: null
    };
  }

  postMessageTo = async () => {
    const { mobile, tenant } = this.state;
    const campaignInfo = await sendSMS(mobile, tenant);
    this.setState({ campaignUrl: campaignInfo['shortUrl'] });
  };

  render() {
    const { mobile, campaignUrl } = this.state;
    return (
      <div className="flex">
        <img src={image} style={{ width: '70%' }} alt="croma logo" />
        <div style={{ textAlign: 'left', marginTop: 20 }}>
          Enter the number to send message:
        </div>
        <div style={{ textAlign: 'center', width: '50%' }}>
          <TextField
            value={mobile}
            variant="outlined"
            placeholder="Number to send message"
            onChange={event => this.setState({ mobile: event.target.value })}
            style={{ width: '100%', marginTop: 10 }}
            inputProps={{
              min: 0,
              style: { textAlign: 'center', fontSize: 20 }
            }}
            onInput={e => {
              e.target.value = e.target.value.toString().slice(0, 10);
            }}
          />
        </div>
        <div>
          <Button
            style={{
              backgroundColor: '#F58974',
              color: '#FFFFFF',
              marginTop: 20
            }}
            onClick={() => this.postMessageTo()}
          >
            Send Message
          </Button>
        </div>
        {campaignUrl && (
          <div style={{ textAlign: 'center', marginTop: 25 }}>
            <div>Message Sent to {mobile} Successfully.</div>
            <div style={{ marginTop: 10 }}>
              The link sent to the {mobile} is{' '}
              <a href={campaignUrl} target="_blank" rel="noopener noreferrer">
                {campaignUrl}
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}
