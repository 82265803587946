import config from '../config/index';

export function getB2BOfferTypesWithMappedOffers() {
  return fetch(`${config.ruleServerUrl}/users/direct-offers/`, {
    method: 'GET',
    credentials: 'include'
  })
    .then(response => response.json())
    .catch(err => {
      console.error(err);
      return Promise.resolve([]);
    });
}

export function getReportForOfferType(offerType) {
  return fetch(
    `${config.ruleServerUrl}/users/direct-offers/report-for-offer-type`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        offerType: offerType
      })
    }
  )
    .then(response => response.json())
    .catch(err => {
      console.error(err);
      return Promise.resolve([]);
    });
}
