import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  closeIconContainerStyles: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  formTitleStyles: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: 'unset',
    marginBottom: 10
  },
  field: {
    marginBottom: 20
  },
  applyBtn: {
    width: '50%'
  },
  cancelBtn: {
    width: '50%'
  }
}));

const UserForm = props => {
  const { popUp, hidePopUp, error, handleChange, createNewRole } = props;
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={popUp}
        onClose={hidePopUp}
        aria-labelledby="form-dialog-title"
      >
        <div
          style={{
            width: '65%',
            margin: '0px auto 20px auto',
            paddingTop: '30px'
          }}
        >
          <DialogTitle
            id="form-dialog-title"
            className={classes.formTitleStyles}
          >
            create Role
          </DialogTitle>
          <DialogContent>
            <strong>Role Name: </strong>
            <TextField
              className={classes.field}
              margin="dense"
              variant="standard"
              fullWidth
              error={error}
              helperText={error}
              onChange={e => {
                handleChange(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button
              onClick={hidePopUp}
              color="primary"
              variant="outlined"
              className={classes.cancelBtn}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={classes.applyBtn}
              onClick={createNewRole}
            >
              Create
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default UserForm;
