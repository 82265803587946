/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { FC, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { getLiabilitySummaryReport } from '../../../services/TenantReports/LiabilitySummaryReport.service';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { get, map } from 'lodash';
import moment from 'moment';
import { LiabilitySummaryReportTypes } from '../../../models/TenantReports/LiabilitySummaryReportDownload';
import { LLSRefreshFilters } from './index';

export interface LLSReportInterface {
  startDate: string;
  endDate: string;
}

const LLSReportInitialState: LLSReportInterface = {
  startDate: moment()
    .subtract(1, 'month')
    .startOf('month')
    .format('YYYY-MM-DD'),
  endDate: moment()
    .endOf('month')
    .format('YYYY-MM-DD')
};

interface ReportActionsProps {
  setRefreshView: (id: string) => void;
  refreshFilters: LLSRefreshFilters;
  setRefreshFilters: (filter: LLSRefreshFilters) => void;
}

const LLSReport: FC<ReportActionsProps> = ({
  setRefreshView,
  refreshFilters,
  setRefreshFilters
}) => {
  const [filters, setFilters] = useState<LLSReportInterface>(
    LLSReportInitialState
  );

  const user = useCurrentUser();

  const handleStartDateChange = (value: any) => {
    const date = moment(value)
      .startOf('month')
      .format('YYYY-MM-DD');
    setFilters({ ...filters, startDate: date });
  };
  const handleEndDateChange = (value: any) => {
    const date = moment(value)
      .endOf('month')
      .format('YYYY-MM-DD');
    setFilters({ ...filters, endDate: date });
  };

  const handleReportTypeChange = (value: any) => {
    setRefreshFilters({ ...refreshFilters, type: value });
  };
  const getReportOptions = () => {
    return Object.values(LiabilitySummaryReportTypes);
  };
  const handleBusinessChange = (event: any) => {
    setRefreshFilters({
      ...refreshFilters,
      businessUnitId: event.target.value
    });
  };

  const handleGenerateReport = (): void => {
    getLiabilitySummaryReport({ ...filters, ...refreshFilters }).then(res => {
      const jobId = get(res, 'jobId', '');
      setRefreshView(jobId);
    });
  };
  return (
    <div
      style={{ margin: 20, display: 'flex', justifyContent: 'space-between' }}
    >
      <div style={{ display: 'flex', padding: '2px' }}>
        <FormControl style={{ minWidth: 180, marginRight: '10px' }}>
          <InputLabel id="lls-report-select-helper-label">
            Report Type
          </InputLabel>
          <Select
            labelId="select-lls-report-type-label"
            id="select-lls-report-type"
            value={refreshFilters.type}
            label="Report Type"
            onChange={e => handleReportTypeChange(e.target.value)}
          >
            {map(getReportOptions(), (value, key) => {
              return (
                <MenuItem
                  value={value}
                  key={key}
                  style={{ textTransform: 'capitalize' }}
                >
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            views={['year', 'month']}
            label="Start Date"
            value={filters.startDate}
            format={'yyyy-MM'}
            onChange={e => handleStartDateChange(e)}
            variant="inline"
            maxDate={filters.endDate}
          />
          <KeyboardDatePicker
            views={['year', 'month']}
            label="End Date"
            value={filters.endDate}
            format={'yyyy-MM'}
            onChange={e => handleEndDateChange(e)}
            variant="inline"
            minDate={filters.startDate}
          />
        </MuiPickersUtilsProvider>
        <FormControl>
          <InputLabel id="businessLabel">Business unit</InputLabel>
          <Select
            defaultValue=""
            labelId="businessLabel"
            value={refreshFilters.businessUnitId}
            onChange={handleBusinessChange}
            style={{ width: '200px' }}
          >
            {map(user.getBusinesses(), b => (
              <MenuItem value={b.id} key={b.id}>
                {b.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={handleGenerateReport}
      >
        Generate Report
      </Button>
    </div>
  );
};

export default LLSReport;
