import React, { FC, useContext } from 'react';
import { LayoutSelectionModal } from '../../PageGenerators/LayoutSelectionModal';
import FlipbookLayoutTileV2Service from '../../../../services/Flipbook/flipbookV2LayoutTile.service';
import FlipbookLayoutV2Service from '../../../../services/Flipbook/flipbookV2Layout.service';
import DesktopView from './PageByDeviceType/DesktopView';
import FlipbookPageGradient from '../../Utiles/FlipbookPageGradient';
import MobileView from './PageByDeviceType/MobileView';
import {
  FlipbookLayout,
  LayoutType
} from '../../../../models/FlipbookV2/FlipbookV2Layout.model';
import { isUndefined } from 'lodash';
import { FlipbookPagesContext } from '../../Hooks/FlipbookPagesContext';

interface MainPageProps {
  editPanelOccupiedKey: string;
  setEditPanelOccupiedKey: (key: string) => void;
  page: FlipbookLayout;
  isEditMode: boolean;
  isCustomerView: boolean;
  hasPermission: (permission: string) => boolean;
}

const MainPage: FC<MainPageProps> = ({
  editPanelOccupiedKey,
  setEditPanelOccupiedKey,
  page,
  isEditMode,
  isCustomerView,
  hasPermission
}) => {
  const [openModel, setModalOpen] = React.useState(false);
  const isMobileView = FlipbookPageGradient.isMobileView();
  const flipbookPagesContext = useContext(FlipbookPagesContext);

  const handleLayoutChange = (selectedLayout: LayoutType) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if (isUndefined(page.id)) {
      return;
    }
    const newLayout = new FlipbookLayout(
      selectedLayout,
      page.flipbookId,
      page.position,
      true,
      page.id
    );
    FlipbookLayoutTileV2Service.deleteFlipbookLayoutTile(page.id).then(r => {
      FlipbookLayoutV2Service.updateFlipbookLayout(newLayout, page.id).then(
        r => {
          setModalOpen(false);
          flipbookPagesContext.updateFlipbookPages();
        }
      );
    });
  };
  const handleModalOpen = () => {
    setModalOpen(true);
  };

  return (
    <div className={'height-100-per'}>
      <LayoutSelectionModal
        isEditLayoutMode={true}
        isModalOpen={openModel}
        setModalClose={e => setModalOpen(e)}
        onClickLayoutAddition={e => handleLayoutChange(e)}
        previousSelection={page.layoutType}
      />
      {isMobileView ? (
        <MobileView
          editPanelOccupiedKey={editPanelOccupiedKey}
          setEditPanelOccupiedKey={setEditPanelOccupiedKey}
          page={page}
          isEditMode={isEditMode}
          handleModalOpen={handleModalOpen}
          isCustomerView={isCustomerView}
          hasPermission={hasPermission}
        />
      ) : (
        <DesktopView
          editPanelOccupiedKey={editPanelOccupiedKey}
          setEditPanelOccupiedKey={setEditPanelOccupiedKey}
          page={page}
          isEditMode={isEditMode}
          handleModalOpen={handleModalOpen}
          isCustomerView={isCustomerView}
          hasPermission={hasPermission}
        />
      )}
    </div>
  );
};

export default MainPage;
